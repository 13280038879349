import React,{useContext,useEffect,useState} from 'react';
import _ from "lodash"
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


import { Button, Container, Row, Col } from "reactstrap";

import FooterMessageModal from "../Common/FooterMessageModal"

//import NotificationContext from "../Context/NotificationContext"

//Word limit
const textDisplayThresholdForDesktop = 20
const textDisplayThresholdForMobile = 10


const FooterMessageBar = props=>{
   //const notificationContext  = useContext(NotificationContext)
   //console.log(notificationContext)
   //console.log("test");
   const [modalDisplayFlag,setModalDisplayFlag] = useState(false)

   const {
        currentMessageNumber,
        currentNotificationId,
        handleMoveToNextMessage,
        handleMoveToPreviousMessage,
        handleNotificationMarkedAsRead,
        notifications,
    } = props
    
    const displayNotificationBarStatus = notifications.length > 0 ? true:false;
    const displayNotificationBar = displayNotificationBarStatus === true ? "":"d-none";
    
    let currentNotification = null
    if(displayNotificationBarStatus === true){
        currentNotification = _.find(notifications,{"notificationId":currentNotificationId})
    }

    //Hide modal if there are no unread messages available
    useEffect(()=>{
        if(displayNotificationBarStatus === false){
            setModalDisplayFlag(false)
        }
    },[displayNotificationBarStatus])

    const getMessageBodyForDisplay = (message,threshold)=>{
        
        let [formattedText,result,wordCount] = ["","",0]
        result = message.replace(/&nbsp;/g,' ');
        result  = message.replace(/(?:\r\n|\r|\n)/g, " ")
        //result  = message.replace(/[<br\/>,<br>]/g, " ")
        
        var resultArray = result.split(" ");
        wordCount = resultArray.length
        if(resultArray.length > threshold){
          resultArray = resultArray.slice(0, threshold);
          result = resultArray.join(" ") + "…";
        }
        
        formattedText = result
        return {formattedText,wordCount};
    }

    let [wordCount,textDisplayForMobile,textDisplayForDesktop] = [0,"",""]
    if(currentNotification && currentNotification.messageBody){
        ({formattedText:textDisplayForMobile,wordCount} = getMessageBodyForDisplay(currentNotification.messageBody,textDisplayThresholdForMobile));
        ({formattedText:textDisplayForDesktop} = getMessageBodyForDisplay(currentNotification.messageBody,textDisplayThresholdForDesktop));
    }
    //console.log(wordCount)

    return(
        displayNotificationBarStatus &&
        <div className={displayNotificationBar}>
            <div className="d-none d-md-block">
                <footer className="fixed-bottom message-bar rounded-top border animated fadeInUp d-flex justify-content-between align-items-center">
                    <div className="message-navigator ml-2 mx-lg-5 text-center">
                        <i  
                            className="fa fa-chevron-left fa-9 mr-lg-3 cursor" 
                            onClick={handleMoveToPreviousMessage}
                        >
                        </i>
                        <i 
                            className="fa fa-chevron-right fa-9 cursor" 
                            onClick={handleMoveToNextMessage}
                        >
                        </i>
                        <p className="text-center">
                            {currentMessageNumber} of {notifications.length} messages
                        </p>
                    </div>
                    <div className="message-body-area p-2">
                        <Row>
                            <Col>
                                <strong className="text-danger">Alert:</strong> {currentNotification && ReactHtmlParser(textDisplayForDesktop)}
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col className="text-center">
                                
                                {
                                        wordCount < textDisplayThresholdForDesktop ? 
                                        <Button
                                            color="danger"
                                            outline
                                            size="sm"
                                            type="button"
                                            className="mr-1"
                                            onClick={()=>handleNotificationMarkedAsRead(currentNotification)}
                                        >
                                            <i className="fa fa-calendar" aria-hidden="true"></i>
                                            Mark as Read
                                        </Button>
                                        :
                                        <Button
                                            color="danger"
                                            outline
                                            size="sm"
                                            type="button"
                                            className="mr-1"
                                            onClick={()=>setModalDisplayFlag(true)}
                                        >
                                            <i className="fa fa-calendar" aria-hidden="true"></i>
                                            Read More
                                        </Button>
                                }
                            </Col>
                        </Row>
                    </div>
                    <div className="message-action-area p-2 ml-1 mx-lg-5">
                        <div className="d-flex align-items-start flex-column">
                            <Button
                                    color="info"
                                    outline
                                    size="sm"
                                    type="button"
                                    className="mr-1"
                                    onClick={()=>setModalDisplayFlag(true)}
                                    
                            >
                                <i className="fa fa-calendar" aria-hidden="true"></i>
                            View all messages
                            </Button>
                        </div>
                    

                    </div>
                </footer>
            </div>
            <div className="d-md-none">
                <footer className="fixed-bottom message-bar rounded-top border animated fadeInUp d-flex justify-content-between align-items-center">
                    <div className="message-navigator text-center mx-1">
                        <a className="color-default" onClick={handleMoveToPreviousMessage}>
                            <i className="fa fa-chevron-left fa-9"></i>
                        </a>
                    </div>
                    <div className="message-body-area-xs  px-2">
                        <Row>
                            <Col>
                                <strong className="text-danger">Alert: </strong> 
                                {currentNotification && ReactHtmlParser(textDisplayForMobile) } 
                            </Col>
                        </Row>
                        {
                            currentNotification && 
                            <Row className="mt-2">
                                <Col className="text-center">
                                    {
                                        wordCount < textDisplayThresholdForMobile ? 
                                        <Button
                                            color="danger"
                                            outline
                                            size="sm"
                                            type="button"
                                            className="mr-1"
                                            onClick={()=>handleNotificationMarkedAsRead(currentNotification)}
                                        >
                                            <i className="fa fa-calendar" aria-hidden="true"></i>
                                            Mark as Read
                                        </Button>
                                        :
                                        <Button
                                            color="danger"
                                            outline
                                            size="sm"
                                            type="button"
                                            className="mr-1"
                                            onClick={()=>setModalDisplayFlag(true)}
                                        >
                                            <i className="fa fa-calendar" aria-hidden="true"></i>
                                            Read More
                                        </Button>
                                    }
                                    
                                    
                                </Col>
                            </Row>
                        }
                    </div>
                    <div className="message-navigator text-center mx-1">
                        <a className="color-default" onClick={handleMoveToNextMessage}>
                            <i className="fa fa-chevron-right fa-9"></i>
                        </a>
                    </div>
                </footer>
            </div>
            <FooterMessageModal 
                modalDisplayFlag={modalDisplayFlag}
                handleModalDisplayFlag = {flag=>setModalDisplayFlag(flag)}
                {...props}
            />
        </div>
   )

}

export default FooterMessageBar