import React from "react";

import {
    Media,
    
} from "reactstrap";

const studentData = [
    {
        heading:"When should I schedule my storage delivery?",
        body:<p>
            All storage deliveries should be scheduled before August 1st. 
        </p>
    },
    {
        heading:"What if I schedule my storage delivery after August 1st?",
        body:<p>
        If you schedule your storage delivery after August 1st, you must schedule your delivery five days in advance of your desired drop off date. In addition, an automatic, late scheduling fee of $35 will be charged to your credit card. 
        </p>,
    },
    {
        heading:"What is the time frame for storage delivery?",
        body:<p>
         We give the customer a three-hour window for storage pickups and deliveries.  The window is necessary for unforeseen issues that tend to occur during moving time such as, blocked vans, traffic, closed roads, or broken elevators. 
         </p>,
    },
    {
        heading:"What happens if I miss my scheduled storage delivery?",
        body:<p>
            There is a $50 charge if a customer misses their delivery. Do not schedule storage delivery on the day you are flying in because of how common flight delays are.
        </p>,
    },
    {
        heading:"Why isn't the delivery date I want showing up on the website?",
        body:<p>
            Because of University restrictions, there are certain black out days which we cannot schedule deliveries. Our website will not allow you to schedule a delivery on those days.
        </p>,
    },
    
    
];

const SchedulingDeliveries = ()=>{
    const data = studentData;
    let ctr = 1; 
    return(
        <>
            {
                data.map(({heading,body})=>
                    <Media key={ctr++}>
                        <Media body>
                        <Media heading>{heading}</Media>
                        {body}
                        </Media>
                    </Media>
                )
            }
        </>
    );
}

export default SchedulingDeliveries;
