import React,{Component} from "react";
import _ from "lodash";
import Joi from "@hapi/joi";

import FormErrorWidget from "components/Common/FormErrorWidget";


// reactstrap components
import {
    Button,
    Row,
    Col,
    Modal, 
    Input, 
    
  } from "reactstrap";

const scrollTop = ()=>{
    document.getElementById("specialInstructionModal").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
    });
};  

class ResidentialCustomerAccountLaundryUpcomingPickupSpecialRequest extends Component{
    
    validationSchema = Joi.object({
        laundryNote: Joi.any().label('Laundry Special Request'),
        drycleaningNote:Joi.any().label('Dry Cleaning'),
        id:Joi.any().allow(""),
    }).or('laundryNote','drycleaningNote');

    errorMessages = {
        "password":{
            "string.pattern.base":"New Password must contain at least one uppercase letter, one lowercase letter and one number",
        },
        "confirmPassword":{
            "any.only":"Confirm Password should match the Password"
        },
        "minimumFieldsMessage":{
            "message": "You need to enter one of the fields"
        }
    }

    /*
    schema = Joi.object({
        a: Joi.any(),
        b: Joi.any()
    }).or('a','b');
    */

    constructor(){
        super();
        this.state = {
            data:{
                id:"",
                laundryNote:"",
                drycleaningNote:"",
            },
            errors:{},
            
            
        }
    }

    componentDidUpdate(prevProps,prevState){
        this.mapModelToView(prevProps,prevState);
    }

    handleChange = ({currentTarget:input})=>{
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[input.name] = input.value;
        this.setState({data,errors});
        
    }

    mapModelToView = (prevProps)=>{
        
        /*
            If props have changed then compare it with previous props and then only update it
        */
        if((this.props.laundryOrder && this.props.laundryOrder.laundryNotesForUpcomingPickup && !prevProps.laundryOrder)
           ||
           (this.props.laundryOrder && 
            this.props.laundryOrder.laundryNotesForUpcomingPickup && 
            prevProps.laundryOrder &&
            prevProps.laundryOrder.laundryNotesForUpcomingPickup &&
            this.props.laundryOrder.laundryNotesForUpcomingPickup.id !== prevProps.laundryOrder.laundryNotesForUpcomingPickup.id
            )
        ){
            const {laundryNotesForUpcomingPickup} = this.props.laundryOrder;
            const data = laundryNotesForUpcomingPickup;
            this.setState({data})
        }
    }
    
    /*  Send state data to the parent laundry detail */
    saveNotes = ()=>{
        const errors = this.validate();
        this.setState({errors: errors || {}});
        if(errors){ scrollTop(); return;}
        
        const data = {...this.state.data}
        this.props.handleSaveOperation(data);
        return;
    }

    validate= ()=>{
        try{
            const options = {abortEarly:false,allowUnknown:true};
            //const options = {};
            
            //console.log(this.state.data);
            //let {error:schemaErrorList} = this.schema.validate({c:'dd'},options);
            //console.log(schemaErrorList);

            /*
              Any empty values then remove it so that when I validate it then it can run against the .or() condition set in the schema
            */
            let data  = {...this.state.data};
            //console.log(data);
            _.forIn(data, function(value, key) {
                if(!value) delete data[key];
            });
            
            //console.log(data);

            let {error:validationErrorList} = this.validationSchema.validate(data,options);
            
            const validationErrors = {};
            if(validationErrorList){
                console.log(validationErrorList.details);
                validationErrorList.details.map(
                    function(errorObject){
                        //console.log(errorObject);
                        if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                            const key = errorObject.context.key;
                            const joiType = errorObject.type;
                            //console.log(this.errorMessages);
                            if(_.has(this.errorMessages, [key, joiType]))
                                return validationErrors[errorObject.path[0]] = this.errorMessages[key][joiType];
                            else
                                return validationErrors[errorObject.path[0]] = errorObject.message;
                        }
                        else if(_.has(errorObject, ['context', 'peers']) &&  _.has(errorObject, ['type'])){
                            const joiType = errorObject.type;
                            if(joiType === "object.missing")
                                return validationErrors[errorObject.path[0]] = this.errorMessages.minimumFieldsMessage.message;
                        }
                        else
                          return validationErrors[errorObject.path[0]] = errorObject.message;
                    }.bind(this)
                );
            }

            const errors = {...validationErrors} 
        
            //console.log(errors);
            return Object.keys(errors).length > 0 ? errors:null;
        }catch(ex){
            console.log(ex);
        }
    }

    render(){
        const {data,errors} = this.state;
        const { 
            laundryNote,
            drycleaningNote
                
        } = data
        
        
        const { 
            handleModalDisplayFlag,
            //listOfOneTimePickupDates,
            modalDisplayFlag,
            laundryOrder,
            user 
        } = this.props


        return(
                <Modal id="specialInstructionModal" size="lg" isOpen={modalDisplayFlag} toggle={() => handleModalDisplayFlag(false)}>
                    <div className="modal-header">
                    <button
                        className="close"
                        type="button"
                        onClick={() => handleModalDisplayFlag(false)}
                    >
                        <span>×</span>
                    </button>
                    <h5
                        className="modal-title text-center"
                        id="exampleModalLabel"
                    >
                        Enter notes for Laundry and Dry Cleaning for Upcoming Pickup
                    </h5>
                    </div>
                    <div className="modal-body">
                        {/*Error display */}
                        {!_.isEmpty(errors) &&
                                <FormErrorWidget
                                  errors={errors}
                                />
                        }
                        {/* End of Error display */}
                        
                        {/* Laundry Special Instructions*/}
                        <Row >
                            <Col>
                                <h6>Enter laundry special requests:</h6>
                                <Row>
                                    <Col>
                                        <Input
                                            className="border-input"
                                            placeholder=""
                                            rows="3"
                                            type="textarea"
                                            maxlength="200"
                                            name="laundryNote"
                                            value={laundryNote}
                                            onChange = {this.handleChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {/* End of Laundry Special Instructions */}

                        {/* Dry cleaning Special Instructions*/}
                        <Row>
                            <Col>
                                <hr/>
                                <h6>Enter Dry Cleaning special requests:</h6>
                                <Row>
                                    <Col>
                                        <Input
                                            className="border-input"
                                            placeholder=""
                                            rows="3"
                                            type="textarea"
                                            maxlength="200"
                                            name="drycleaningNote"
                                            value={drycleaningNote}
                                            onChange = {this.handleChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {/* End of Dry cleaning Special Instructions */}

                        

                        

                    
                    </div>
                    
                    <div className={`modal-footer`}>
                        <div className="left-side">
                            <Button
                            className="btn-link"
                            color="default"
                            type="button"
                            onClick={() => handleModalDisplayFlag(false)}
                            >
                            Cancel
                            </Button>
                        </div>
                        <div className="divider" />
                        <div className="right-side">
                            <Button className="btn-link" color="danger" type="button" onClick={()=>this.saveNotes()}>
                                Save Notes for Upcoming Pickup
                            </Button>
                        </div>
                    </div>
                </Modal>
        );
    }
}

export default ResidentialCustomerAccountLaundryUpcomingPickupSpecialRequest;