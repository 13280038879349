import React,{Component} from "react";

import ShortNavbar from "../../components/Navbars/ShortNavbar.js";
import SignupPageHeader from "../../components/Headers/SignupPageHeader";
import FooterWhite from "../../components/Footers/FooterWhite";

import GiftCardImage from "../../assets/mylazybones/img/GiftCards/GiftCard_Landing.jpg"

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Media,
    NavItem,
    NavLink,
    Nav,
    Container,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Label,
    Row,
    Col,
    Input, 
  } from "reactstrap";


class SignUpServerError extends Component{

    render(){
        return(
            <React.Fragment>
                    <ShortNavbar {...this.props} />
                    <SignupPageHeader />
                    <div className="main">
                        <div className="section section-nude-gray"> 
                            <Container>
                                <div id="description-areas">
                                    {
                                        <Row>
                                            <Col xs="12">
                                                <h2 className="shop">Purchase: Confirmation</h2>
                                                <div className="mb-4">
                                                    <p className="mt-2">
                                                        <strong>We will process your request and send you a confirmation email in 1 business day.</strong> Please feel free to call us if you have any questions or concerns.
                                                    </p>
                                                    <p>
                                                    </p>
                                                </div>
                                                
                                            </Col>
                                           
                                        </Row>  
                                    }
                                            
                                </div>
                            </Container>
                        </div>
                    </div>
                    <FooterWhite />
                </React.Fragment>
        )
    }
}

export default SignUpServerError