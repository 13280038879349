import React,{Component} from "react";
import _ from "lodash";

import StudentAccount from "../../components/Consumer/MyAccount/Laundry/Student/StudentAccount";
import ResidentialCustomerAccount from "../../components/Consumer/MyAccount/Laundry/Residential/ResidentialCustomerAccount";
import CommercialCustomerAccount from "../../components/Consumer/MyAccount/Laundry/Commercial/CommercialCustomerAccount";

import applicationConstant from "../../utils/applicationConstants";

import auth from "../../services/authService";

class MyAccount extends Component{
    constructor(){
        super();
    }

    async componentDidMount(){
      try{
            let {accountType,product} = this.props.match.params;
            
            //Get the account type based on the logged in users
            if(accountType === undefined){
                const user = auth.getCurrentUser()
                if(!_.isEmpty(user)){
                    const { customerRole } = user;
                    accountType = customerRole;
                }

                product  = product || "";
                return this.props.history.replace(`/myaccount/${accountType}/${product}`);
            }

            
      }
      catch(ex){
          console.log(`My account: component did mount`)
      }
    }

    render(){
        const {accountType} = this.props.match.params;
        
        return(
           <> 
           {accountType === "student" && <StudentAccount {...this.props}/>}
           {accountType === "residential" && <ResidentialCustomerAccount {...this.props}/>}
           {    
                accountType === applicationConstant.CUSTOMER_TYPE_COMMERCIAL_TEXT && 
                <CommercialCustomerAccount {...this.props} />
           }
           
           </>
           
        );
    }
}

export default MyAccount;