import React,{Component} from "react";
import _ from "lodash";
import moment from "moment";
import accounting from "accounting";

import FormErrorWidget from "../../../Common/FormErrorWidget";
import {scrollTop} from '../../../../utils/helperUtils';
import {renderError} from '../../../../utils/errorUtils';
import customToast from '../../../Common/CustomToast'

import ShoppingCartReviewWidget from "../../ShoppingCart/common/ShoppingCartReviewWidget";


// reactstrap components
import {
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    FormGroup, 
  } from "reactstrap";

class CustomerReviewScreen extends Component{
    constructor(){
        super();
        this.state = {
          openProcessingModalFlag:false,
        }
    }

    /*
     For some reason the page scrolls to the product area and user cannot see the errors
     I am just setting a timer to it scrolls back to the top if the serverError props gets updated
    */
    componentDidUpdate(prevProps){
      const {serverErrors} = this.props;
      const {serverErrors:serverErrorsPrev} =prevProps;
      if(!_.isEqual(serverErrors,serverErrorsPrev)){
        setTimeout(()=>{
          scrollTop('sectionArea');
        },1000)
      }
    }

    

    getDeliveryAddress = ()=>{
      let address = "";
      const {user} = this.props;
      const product = "supplies";
      const {deliveryAddress} = user[product];
      
      const {streetNumber,addressLine1, apartmentNumber,city,state,postalCode,isDormitory,dormitory} = deliveryAddress;
      const {name:dormitoryName,roomNumber}  = dormitory
      if(isDormitory === false && streetNumber)
        address = <span>{`${streetNumber}, ${addressLine1} ${apartmentNumber}`} <br/> {`${city}, ${state} - ${postalCode}`}</span>;
      else if(isDormitory === true && dormitoryName)
        address = <span>{`${dormitoryName}, ${roomNumber}`} <br/> {`${city}, ${state} - ${postalCode}`}</span>;
      
      return address;
    }

    handleEditButton = (step)=>{
      const { handleRedirectToReviewScreen } = this.props;
      handleRedirectToReviewScreen(true);

      const {user} = this.props;
      const {supplies} = user;    
      let url = `/signup/supplies/${supplies.type}/${step}`; 
      this.props.history.push(url);
    }

    handleSubmit = (e)=>{
      e.preventDefault();
      const {onClickOfPurchaseButtonFromReviewScreen} = this.props; 
      return onClickOfPurchaseButtonFromReviewScreen();
    }

    handleViewCart = (e)=>{
        e.preventDefault();
        this.props.history.push("/shoppingcart");
    }

    proceedToCheckout = (e)=>{

    }

    render(){
        const {
          globalApp,
          handleShoppingCartRemoveItem,handleShoppingCartUpdateProductQuantity,
          onClickOfBackButtonFromReviewScreen,user,serverErrors} = this.props;
        const {shoppingCart}=  globalApp;
        const {shippingDate} = shoppingCart.supplies;
   
        const {paymentInformation} = user;
        const deliveryAddress = this.getDeliveryAddress();
        const {cartTotal} = globalApp.shoppingCart;
       
       
        return(
            <Container id="mainContentArea">
                <Row className="title-row justify-content-between">
                    <Col md="4">
                      <h3 className="shop">Signup for Supplies</h3>
                    </Col>
                    <Col  md="5">
                      <div className="text-md-right d-none">
                          <span className="text-muted">Step 3</span>
                          <Button color="link">
                          <i className="fa fa-shopping-cart" /> Review your selection
                          </Button>
                      </div>
                    </Col>
                </Row>

                <Row className="title-body-row">
                    <Col md="7">
                        <Card  className="card-plain bg-white p-2 rounded-lg">
                            <CardBody>
                                
                                {/*Server Error display */}
                                {!_.isEmpty(serverErrors) &&
                                    <FormErrorWidget
                                        errors={serverErrors}
                                    />
                                }
                                {/* End of Server Error display */}
                                
                                <div>
                                    <h6 className="card-category">
                                        <span className="text-left">
                                          Primary Information
                                        </span>
                                        <span className="float-right text-right cursor text-info"
                                          onClick={()=>this.handleEditButton("enter-customer-information")}
                                        >
                                          <i className="fa fa-edit"></i> Edit
                                        </span>
                                    </h6>
                                    <hr/>

                                    <Row>
                                      <Col xs="3">
                                        Name
                                      </Col>
                                      <Col xs="8">
                                        {`${user.firstName} ${user.lastName}`}
                                      </Col>
                                    </Row>

                                    { user.school && 
                                      <Row className="mt-2">
                                        <Col xs="3">
                                          School
                                        </Col>
                                        <Col xs="8">
                                          {`${user.school.name}`}
                                        </Col>
                                      </Row>
                                    }
                                    
                                    <Row className="mt-2">
                                      <Col xs="3">
                                        Email
                                      </Col>
                                      <Col xs="8">
                                        {`${user.email}`}
                                      </Col>
                                    </Row>

                                    <Row className="mt-2">
                                      <Col xs="3">
                                        Cell Phone
                                      </Col>
                                      <Col xs="8">
                                        {`${user.cellPhone}`}
                                      </Col>
                                    </Row>

                                    <Row className="mt-2">
                                      <Col xs="3">
                                        Home Phone
                                      </Col>
                                      <Col xs="8">
                                        {`${user.homePhone}`}
                                      </Col>
                                    </Row>
                                    
                                </div>
                            </CardBody>
                        </Card>
                        
                    </Col>
                    <Col md="5">
                        <Card  className="card-plain bg-white p-2 rounded-lg">
                            <CardBody>
                                <div>
                                     <h6 className="card-category">
                                        <span className="text-left">
                                          Login Information
                                        </span>
                                        <span className="float-right text-right cursor text-info"
                                        onClick={()=>this.handleEditButton("enter-login-payment-information")}
                                        >
                                          <i className="fa fa-edit"></i> Edit
                                        </span>
                                    </h6>
                                    <hr/>
                                    <Row>
                                      <Col xs="4">
                                        Username
                                      </Col>
                                      <Col xs="8">
                                        {`${user.username}`}
                                      </Col>
                                    </Row>
                                    <Row  className="mt-2">
                                      <Col xs="4">
                                        Password
                                      </Col>
                                      <Col xs="8">
                                        xxxxxxxxx
                                      </Col>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                        <Card  className="card-plain bg-white p-2 rounded-lg">
                            <CardBody>
                                <div>
                                     <h6 className="card-category">
                                        <span className="text-left">
                                          Delivery Address
                                        </span>
                                        <span className="float-right text-right cursor text-info"
                                          onClick={()=>this.handleEditButton("enter-customer-information")}
                                        >
                                          <i className="fa fa-edit"></i> Edit
                                        </span>
                                    </h6>
                                    <hr/>
                                    <Row>
                                      <Col xs="12">
                                        {(deliveryAddress === "") ? "Delivery address not entered":deliveryAddress}
                                      </Col>
                                      
                                    </Row>
                                    
                                </div>
                            </CardBody>
                        </Card>
                    </Col>    
                </Row>
                
                <Row className="title-body-row">
                    <Col md="7">
                        <Card  className="card-plain bg-white p-2 rounded-lg">
                            <CardBody>
                                <div className="mt-3">
                                    <h6 className="card-category">
                                        <span className="text-left">
                                          Payment Information
                                        </span>
                                        <span className="float-right text-right cursor text-info"
                                          onClick={()=>this.handleEditButton("enter-login-payment-information")}
                                        >
                                          <i className="fa fa-edit"></i> Edit
                                        </span>
                                    </h6>
                                    <hr/>
                                    <Row className="mt-2">
                                      <Col xs="4">
                                        Credit Card Holder
                                      </Col>
                                      <Col xs="8">
                                        {paymentInformation.creditCardName}
                                      </Col>
                                    </Row>

                                    <Row className="mt-2">
                                      <Col xs="4">
                                        Credit Card Number
                                      </Col>
                                      <Col xs="8">
                                        { 
                                          paymentInformation && 
                                          paymentInformation.creditCardStripeToken && 
                                          `xxxxxxxxxxx - ${paymentInformation.creditCardStripeToken.card.last4}`
                                        }
                                      </Col>
                                    </Row>

                                    <Row className="mt-2">
                                      <Col xs="4">
                                        Expiration Month - Year
                                      </Col>
                                      <Col xs="8">
                                        { paymentInformation && 
                                          paymentInformation.creditCardStripeToken && 
                                          `${moment(`${paymentInformation.creditCardStripeToken.card.exp_year}-${paymentInformation.creditCardStripeToken.card.exp_month}-1`).format('MMMM')} - ${paymentInformation.creditCardStripeToken.card.exp_year}`
                                        } 
                                      </Col>
                                    </Row>

                                    <Row className="mt-2">
                                      <Col xs="4">
                                        Amount be charged
                                      </Col>
                                      <Col xs="8">
                                        
                                          {(_.has(cartTotal, ['total'])) ? accounting.formatMoney(cartTotal.total):"$ 0.00"} 
                                        
                                      </Col>
                                    </Row>

                                    <Row className="mt-2">
                                      <Col xs="4">
                                        Shipping Date
                                      </Col>
                                      <Col xs="8">
                                        
                                          {(shippingDate !== "") ? moment(shippingDate).format("MMMM Do YYYY"):""} 
                                        
                                      </Col>
                                    </Row>

                                    
                                </div>     

                                <FormGroup row className="mt-5  justify-content-between">
                                    <Col xs={{ size: 2}}>
                                        <Button 
                                        className="btn-rounded btn-danger"
                                        onClick={()=>onClickOfBackButtonFromReviewScreen()}
                                        >
                                        Back
                                        </Button>
                                    </Col>
                                    <Col xs={{ size: 6}} sm={{ size: 4}} className="text-right">
                                        <Button 
                                        disabled={false}
                                        className="btn-rounded btn-danger"
                                        onClick={this.handleSubmit}
                                        >
                                        Proceed to Purchase
                                        </Button>
                                    </Col>
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="5" className="order-first order-sm-first order-md-last">
                        <ShoppingCartReviewWidget
                            {...this.props}
                            onUpdateOfItem ={handleShoppingCartUpdateProductQuantity}
                            onRemoveOfItem = {handleShoppingCartRemoveItem}
                            editMode={true}
                        />

                        
                    </Col>
                </Row> 
            </Container>
        );
    }
}

export default CustomerReviewScreen;