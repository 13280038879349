import React,{Component} from "react";
import _ from "lodash";
import Joi from "@hapi/joi";
import Select from "react-select";
import customToast from '../../../../../Common/CustomToast'
import {renderError} from '../../../../../../utils/errorUtils';
import {scrollTop} from '../../../../../../utils/helperUtils';
import FormErrorWidget from "../../../../../Common/FormErrorWidget";
import accounting from "accounting";


import StudentService from "../../../../../../services/studentService";
import applicationConstants from "../../../../../../utils/applicationConstants";


import {
    Button,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Label,
    Row,
    Col,
    FormGroup, 
    Modal, 
    Input, 
  } from "reactstrap";


class PriceEstimatorModal extends Component{
    validationSchema = Joi.object({
        itemCategory: Joi.object().required().label('Pickup Day'),
        itemType: Joi.object().required().label('Pickup Day'),
        length:Joi.number().optional().allow("").label('Length'),
        width: Joi.number().optional().allow("").label('Width'),
        height:Joi.number().optional().allow("").label('Height'),
        price:Joi.number().optional().allow("").label('Price'),
    })

    errorMessages = {
        "itemCategory":{
            "object.base":"Item category is not allowed to be empty",
        },
        "itemType":{
            "object.base":"Item type is not allowed to be empty",
        },
    }

    constructor(){
        super();
        this.state = {
            itemCategories:[],
            propertiesByCategory:[],
            itemPriceList:[],
            data:{
                itemCategory:"",
                itemType:"",
                length:"",
                width:"",
                height:"",
                price:"",
            },
            errors:{},
        }
    }

    async componentDidMount(){
        try{
            const {storageOrder:storage} = this.props
            const {storageOrder} = storage;
            const {university_id:universityId,season} = storageOrder;
            const formData = {universityId,season}
            const {status:processingStatus,message,itemPriceListObject} = await StudentService.getStorageItemPriceList(formData);
            if(processingStatus === true){
                const {itemCategories,propertiesByCategory,itemPriceList} = itemPriceListObject
                this.setState({
                    itemCategories,
                    propertiesByCategory,
                    itemPriceList,
                })
            }
        }
        catch(ex){
            /*
              400 signals validation errors from the server
              renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop('priceEstimatorModal'); return;}
            }
            else
                renderError(ex);
        } 
    }

    getItemCategoryList = (itemCategories)=>{
        let dropDownOption = [
            { value: "", label: " Choose Category", isDisabled: true },
        ];
        
        if(itemCategories.length > 0){
            itemCategories.forEach(i => {
                const category = {
                    value:i,
                    label:i
                }
                dropDownOption.push(category);
            });
        }
        
        return dropDownOption
        
    }

    getItemTypeList = (itemCategory)=>{
        let dropDownOption = [
            { value: "", label: " Choose Item type", isDisabled: true },
        ];

        if(itemCategory !== ""){
            const {propertiesByCategory} = this.state;
            const {itemType:itemTypeList} = _.find(propertiesByCategory,{'itemCategory':itemCategory.value});
            itemTypeList.forEach(i => {
                const itemType = {
                    value:i,
                    label:i
                }
                dropDownOption.push(itemType);
            });
            
        }
        

        return dropDownOption
    }

    handleChange = ({currentTarget:input})=>{
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[input.name] = input.value;
        data.price="";
        this.setState({data,errors});
        
    }

    setItemCategory = (itemCategory)=>{
        const price="";
        const data = {...this.state.data,
            itemCategory,
            price,
            itemType:"",
            length:"",
            width:"",
            height:"",
        }
        this.setState({data});
    }

    setItemType = (itemType)=>{
        const price="";
        const data = {...this.state.data,itemType,price}
        this.setState({data});
    }

    saveForm = (e)=>{
        e.preventDefault();
        
        const errors = this.validate();
        this.setState({errors: errors || {}});
        if(errors){ scrollTop('priceEstimatorModal'); return;}
            

        const {itemCategory:itemCategoryObject,itemType:itemTypeObject,length,width,height} = this.state.data;
        const itemCategory =  itemCategoryObject.value;
        const itemType =  itemTypeObject.value;

        /*
        Identify the price type
        */
        const {propertiesByCategory} = this.state;
        const {listOfItems} = _.find(propertiesByCategory,{'itemCategory':itemCategory});
        const selectedItemPriceRecord = _.find(listOfItems,{'item_type':itemType})
        console.log(selectedItemPriceRecord);

        const {
            pricing_type:priceType,
            size_increment1:sizeIncrement1,
            size_increment2:sizeIncrement2,
            size_increment3:sizeIncrement3,
            size_increment4:sizeIncrement4,
            price1:price1,
            price2:price2,
            price3:price3,
            price4:price4,
            in3_price:in3Price,
        } = selectedItemPriceRecord;

        /*
        const price1 = parseFloat(price1Decimal);
        const price2 = parseFloat(price2Decimal);
        const price3 = parseFloat(price3Decimal);
        const price4 = parseFloat(price4Decimal);
        const in3Price = parseFloat(in3PriceDecimal);
        */
        let dimensions = 0;
        let sides = [length,width,height];
        switch(priceType){
            case applicationConstants.STORAGE_DIMENSION_PRICE_TYPE_LONGEST_SIDE:
                sides.sort(function(a, b){return b - a});
                dimensions = sides[0];
                break;
            case applicationConstants.STORAGE_DIMENSION_PRICE_TYPE_SECOND_LONGEST_SIDE:
                sides.sort(function(a, b){return b - a});
                dimensions = sides[1];
                break;
            default: dimensions = length * width * height;
        }

        let price = 0;
        //debugger;
        if(dimensions <= sizeIncrement1){
            price = price1;
        }
        else if(dimensions <= sizeIncrement2){
            price = price2;
        }
        else if(dimensions <= sizeIncrement3){
            price = price3;
        }
        else if(dimensions <= sizeIncrement4){
            price = price4;
        }
        else{
            // Need to apply the per-unit pricing. This applies to the balance over the increment 4, and is added to it.
	
            const overage = dimensions - sizeIncrement4;
            if(applicationConstants.STORAGE_DIMENSION_PRICE_TYPE_DEFAULT === priceType){
                price = price4 + ( (overage/144) * in3Price )
            }
            else{
                price = price4 + ( overage * in3Price )
            }
        }
        
        price  = accounting.formatMoney(price); 
        const data = {...this.state.data,price}
        this.setState({data})
        
        return;
    }

    validate = ()=>{
        try{
            let data  = {...this.state.data};
            
            const options = {abortEarly:false,allowUnknown:true};
            let {error:validationErrorList} = this.validationSchema.validate(data,options);
            
            const validationErrors = {};
            if(validationErrorList){
                console.log(validationErrorList.details);
                validationErrorList.details.map(
                    function(errorObject){
                        //console.log(errorObject);
                        if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                            const key = errorObject.context.key;
                            const joiType = errorObject.type;
                            //console.log(this.errorMessages);
                            if(_.has(this.errorMessages, [key, joiType]))
                                return validationErrors[errorObject.path[0]] = this.errorMessages[key][joiType];
                            else
                                return validationErrors[errorObject.path[0]] = errorObject.message;
                        }
                        else if(_.has(errorObject, ['context', 'peers']) &&  _.has(errorObject, ['type'])){
                            const joiType = errorObject.type;
                            if(joiType === "object.missing")
                                return validationErrors[errorObject.path[0]] = this.errorMessages.minimumFieldsMessage.message;
                        }
                        else
                          return validationErrors[errorObject.path[0]] = errorObject.message;
                    }.bind(this)
                );
            }

            const errors = {...validationErrors} 
        
            //console.log(errors);
            return Object.keys(errors).length > 0 ? errors:null;
        }catch(ex){
            console.log(ex);
        }

    }
    
    render(){
        const {itemCategories,data,errors} = this.state;
        const {itemCategory,itemType,length,width,height,price} = data;
        const {modalDisplayFlag,handleModalDisplay,...rest} = this.props 
        const itemCategoryList = this.getItemCategoryList(itemCategories);
        const itemTypeList = this.getItemTypeList(itemCategory);
        
        return(
            <>
                <Modal id="priceEstimatorModal" size="lg" isOpen={modalDisplayFlag} toggle={() => handleModalDisplay(false)}>
                    <div className="modal-header">
                        <button
                            className="close"
                            type="button"
                            onClick={() => handleModalDisplay(false)}
                        >
                            <span>×</span>
                        </button>
                        <h5
                            className="modal-title text-center"
                            id="exampleModalLabel"
                        >
                            Storage:Get a Price Estimate
                        </h5>
                    </div>
                    <div className="modal-body">
                        {/*Error display */}
                        {!_.isEmpty(errors) &&
                                <FormErrorWidget
                                    errors={errors}
                                />
                        }
                        {/* End of Error display */}

                        {
                            !itemCategories.length &&
                            <Row>
                                <Col className="text-center">
                                    Loading .... Please wait
                                </Col>
                            </Row>
                        }
                        {
                            itemCategories.length && 
                            <>
                                <h6>Select item category:</h6>
                                <Row>
                                    <Col sm="4">
                                        Item category
                                    </Col>
                                    <Col sm="4">
                                        <FormGroup>
                                            <Select
                                                className="react-select react-select-default"
                                                classNamePrefix="react-select"
                                                name="defaultSelect"
                                                value={itemCategory}
                                                onChange={value => this.setItemCategory(value)}
                                                options={itemCategoryList}
                                                placeholder="Select"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </>
                        }

                        {
                            _.isObject(itemCategory) && 
                            <>
                                <h6>Select item type:</h6>
                                <Row>
                                    <Col sm="4">
                                        Item type
                                    </Col>
                                    <Col sm="4">
                                        <FormGroup>
                                            <Select
                                                className="react-select react-select-default"
                                                classNamePrefix="react-select"
                                                name="defaultSelect"
                                                value={itemType}
                                                onChange={value => this.setItemType(value)}
                                                options={itemTypeList}
                                                placeholder="Select"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </>
                        } 

                        {
                            _.isObject(itemCategory) && _.isObject(itemType) && 
                            <>
                                <h6>Enter Dimensions:</h6>
                                <Row>
                                    <Col sm="4">
                                        Length (in inches)
                                    </Col>
                                    <Col sm="4">
                                        <Input 
                                            type="text" 
                                            name="length" 
                                            id="length" 
                                            placeholder="Length" 
                                            value={length} 
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col sm="4">
                                        Width (in inches)
                                    </Col>
                                    <Col sm="4">
                                        <Input 
                                            type="text" 
                                            name="width" 
                                            id="width" 
                                            placeholder="Width" 
                                            value={width} 
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col sm="4">
                                        Height (in inches)
                                    </Col>
                                    <Col sm="4">
                                        <Input 
                                            type="text" 
                                            name="height" 
                                            id="height" 
                                            placeholder="Height" 
                                            value={height} 
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </Row>
                            </>
                        }  

                        {   
                            price != "" && 
                            <>
                                <Row className="mt-4">
                                    <Col sm="4">
                                        <h3 className="text-danger">Calculated Price</h3>
                                    </Col>
                                    <Col sm="4">
                                        <h3>{price}</h3>
                                    </Col>
                                </Row>
                            </>
                        }             
                        
                    </div>
                    <div className={`modal-footer`}>
                        <div className="left-side">
                            <Button
                            className="btn-link"
                            color="default"
                            type="button"
                            onClick={() => handleModalDisplay(false)}
                            >
                            Cancel
                            </Button>
                        </div>
                        <div className="divider" />
                        <div className="right-side">
                            <Button className="btn-link" color="danger" type="button" onClick={this.saveForm}>
                                Calculate Price
                            </Button>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

export default PriceEstimatorModal