import _ from "lodash";
import dateFormat from "dateformat";
import moment from "moment";


const PICKUP = 1;
const DELIVERY = 2;

const BY_THE_POUND = 1;
const SEMESTER_PLAN = 2;

const CHANGE_TO_SEMESTER_PLAN = "change-to-semester-plan";


const weekDays= [
    {value:1, day:"Sunday",isoWeekDay:7},
    {value:2, day:"Monday",isoWeekDay:1},
    {value:3, day:"Tuesday",isoWeekDay:2},
    {value:4, day:"Wednesday",isoWeekDay:3},
    {value:5, day:"Thursday",isoWeekDay:4},
    {value:6, day:"Friday",isoWeekDay:5},
    {value:7, day:"Saturday",isoWeekDay:6},
    
]
    
const getPickupDeliveryDayNumerical = (stringDayValue)=>{
    const {value}  = _.find(weekDays, { 'day': stringDayValue });
    return value;
}

const getPickupDeliveryDay = (numericalDayValue)=>{
    const {day}  = _.find(weekDays, { 'value': numericalDayValue });
    return day;
}

/* Select drop down option of days 
    [
        { value: "", label: " Select Day", isDisabled: true },
        { value: "Monday", label: "Monday" },
        { value: "Tuesday", label: "Tuesday" },
        { value: "Wednesday", label: "Wednesday" },
        { value: "Thursday", label: "Thursday" },
        { value: "Friday", label: "Friday" },
        { value: "Saturday", label: "Saturday" },
        { value: "Sunday", label: "Sunday" },
    ];
*/
const generateLaundryPickupDropdownListFromZoneScheduleLaundryRecords = (recordList)=>{
   const dropDownPlaceholder = { value: "", label: " Select Day", isDisabled: true };
    
   let dropdownList = [];
   let uniqueDayList = [];
   if(recordList.length){
       recordList.forEach(
           record=>{
               if(record.action_type === PICKUP){
                   if(!uniqueDayList.includes(record.day_of_week)){
                        uniqueDayList.push(record.day_of_week)
                        const day = getPickupDeliveryDay(record.day_of_week);
                        const dropdownValue = {
                            value:record.zone_schedule_laundry_id,
                            label:day,
                            numericalDayKey:record.day_of_week,
                        } 
                        dropdownList.push(dropdownValue);
                   }
                   
               }
                  
           }
       )
   }

   dropdownList = _.sortBy(dropdownList, ['numericalDayKey']);
   dropdownList = _.map(dropdownList,e=>_.pick(e, ['value', 'label']));
   dropdownList.unshift(dropDownPlaceholder)
    
   return dropdownList;
}

/*Recurring timeslots */
const generateLaundryPickupTimeslotDropdownList = (selectedDay,selectedDayRecordId,recordList)=>{
   let timeslotList = [];
   const dayIntValue = getPickupDeliveryDayNumerical(selectedDay);
   const now = new Date();
   const formattedNow = dateFormat(now,'yyyy-mm-dd');

   if(recordList.length){
       recordList.forEach(
           record=>{
               if(record.action_type === PICKUP && record.day_of_week ===  dayIntValue ){
                    const formattedStartTime =  dateFormat(new Date(`${formattedNow}T${record.time_start}`),'htt');
                    const formattedEndTime =  dateFormat(new Date(`${formattedNow}T${record.time_end}`),'htt') ;
                    timeslotList.push(
                        {
                            id:record.zone_schedule_laundry_id,
                            startTime: formattedStartTime,
                            endTime: formattedEndTime,
                            formattedTime: `${formattedStartTime} - ${formattedEndTime}`,
                        }
                    )
               }
                  
           }
       )
   }

   return timeslotList;
}

/*Onetime pickup timeslots 
    selectedDay- moment
*/
const generateLaundryOneTimeDeliveryTimeslotDropdownList = (selectedDayMoment,recordList)=>{
    let timeslotList = [];
    const selectedDay = selectedDayMoment.format('dddd');
     
    const dayIntValue = getPickupDeliveryDayNumerical(selectedDay);
    const now = moment();
    const formattedNow = now.format('YYYY-MM-DD');
    
    if(recordList.length){
        recordList.forEach(
            record=>{
                if(record.action_type === DELIVERY && record.day_of_week ===  dayIntValue ){
                     const formattedStartTime =  moment(`${formattedNow}T${record.time_start}`).format('h:ss a');
                     const formattedEndTime =  moment(`${formattedNow}T${record.time_end}`).format('h:ss a');
                     
                     timeslotList.push(
                         {
                             id:record.zone_schedule_laundry_id,
                             startTime: formattedStartTime,
                             endTime: formattedEndTime,
                             formattedTime: `${formattedStartTime} - ${formattedEndTime}`,
                         }
                     )
                }
                   
            }
        )
    }
 
    return timeslotList;
 }

/*Onetime pickup timeslots 
    selectedDay- moment
*/
const generateLaundryOneTimePickupTimeslotDropdownList = (selectedDayMoment,recordList)=>{
    let timeslotList = [];
    //console.log(selectedDayMoment);
    const selectedDay = selectedDayMoment.format('dddd');
    const formattedSelectedDay = selectedDayMoment.format('YYYY-MM-DD');
     
    const dayIntValue = getPickupDeliveryDayNumerical(selectedDay);
    const now = moment();
    const formattedNow = now.format('YYYY-MM-DD');
    
    if(recordList.length){
        recordList.forEach(
            record=>{
                if(record.action_type === PICKUP && record.day_of_week ===  dayIntValue ){
                     const startTime = moment(`${formattedSelectedDay}T${record.time_start}`);
                     const endTime = moment(`${formattedSelectedDay}T${record.time_end}`);
                     
                     if(now.isBefore(startTime) || now.isBetween(startTime,endTime)){
                        const formattedStartTime =  startTime.format('h:ss a');
                        const formattedEndTime =  endTime.format('h:ss a');
                        timeslotList.push(
                            {
                                id:record.zone_schedule_laundry_id,
                                startTime: formattedStartTime,
                                endTime: formattedEndTime,
                                formattedTime: `${formattedStartTime} - ${formattedEndTime}`,
                            }
                        )
                     }
                     
                }
                   
            }
        )
    }
 
    return timeslotList;
 }



/* Select drop down option of days */
const generateLaundryDeliveryDropdownListFromZoneScheduleLaundryRecords = (recordList,selectedPickupDayDropdownObject)=>{
    const dropDownPlaceholder = { value: "", label: " Select Day", isDisabled: true };
    let dropdownList = [
            
    ];
    let uniqueDayList = [];

    if(recordList.length){
        let pickupDayNumerical = null;
        let nextDayDeliveryDayNumerical = null
        if(!_.isEmpty(selectedPickupDayDropdownObject)){
            const pickupDay = selectedPickupDayDropdownObject.label;
            pickupDayNumerical = getPickupDeliveryDayNumerical(pickupDay);
            nextDayDeliveryDayNumerical = pickupDayNumerical + 1;
        }

        //If there is just one delivery record for that zone then we just display that option
        //instead of trying to get the next day delivery setup. 
        //This handles where a pickup is on tuesday but delivery is on thursday. 
        const listOfDeliveryRecords = _.filter(recordList, ['action_type', DELIVERY]);

        recordList.forEach(
            record=>{
                if(record.action_type === DELIVERY){
                    if(!uniqueDayList.includes(record.day_of_week)){
                        const day = getPickupDeliveryDay(record.day_of_week);
                        const dropdownValue = {
                        value:record.zone_schedule_laundry_id,
                        label:day,
                        numericalDayKey:record.day_of_week,
                        }
                        
                        if(listOfDeliveryRecords.length === 1){
                            dropdownList.push(dropdownValue);
                        }
                        else{
                            if(!nextDayDeliveryDayNumerical){
                                uniqueDayList.push(record.day_of_week);
                                dropdownList.push(dropdownValue);
                            }else{
                                //If pickup day is selected then only display next day delivery as an option
                                if(record.day_of_week === nextDayDeliveryDayNumerical){
                                    uniqueDayList.push(record.day_of_week);
                                    dropdownList.push(dropdownValue);
                                }
                            } 
                        }
                    }
                    
                }
                
            }
        );
        
    }

    dropdownList = _.sortBy(dropdownList, ['numericalDayKey']);
    dropdownList = _.map(dropdownList,e=>_.pick(e, ['value', 'label']));
    dropdownList.unshift(dropDownPlaceholder)
    
   return dropdownList;
}

/* Recurring timeslots */
const generateLaundryDeliveryTimeslotDropdownList = (selectedDay,selectedDayRecordId,recordList)=>{
    let timeslotList = [];
    const dayIntValue = getPickupDeliveryDayNumerical(selectedDay);
    const now = new Date();
    const formattedNow = dateFormat(now,'yyyy-mm-dd');
 
    if(recordList.length){
        recordList.forEach(
            record=>{
                if(record.action_type === DELIVERY && record.day_of_week ===  dayIntValue ){
                    const formattedStartTime =  dateFormat(new Date(`${formattedNow}T${record.time_start}`),'htt');
                    const formattedEndTime =  dateFormat(new Date(`${formattedNow}T${record.time_end}`),'htt') ;
                    timeslotList.push(
                        {
                            id:record.zone_schedule_laundry_id,
                            startTime: formattedStartTime,
                            endTime: formattedEndTime,
                            formattedTime: `${formattedStartTime} - ${formattedEndTime}`,
                        }
                    )
                }
                   
            }
        )
    }
 
    return timeslotList;
 }

const isLaundryPackageIdForSemesterPlan = (laundryPackageId)=>{
    if(laundryPackageId)
      return true;
    else
      return false  
}



export default {
    BY_THE_POUND,
    SEMESTER_PLAN,
    CHANGE_TO_SEMESTER_PLAN,
    getPickupDeliveryDay,
    generateLaundryPickupDropdownListFromZoneScheduleLaundryRecords,
    generateLaundryDeliveryDropdownListFromZoneScheduleLaundryRecords,
    generateLaundryPickupTimeslotDropdownList,
    generateLaundryDeliveryTimeslotDropdownList,
    generateLaundryOneTimePickupTimeslotDropdownList,
    generateLaundryOneTimeDeliveryTimeslotDropdownList,
    isLaundryPackageIdForSemesterPlan,
}