import React from "react";
import Select from "react-select";
import _ from "lodash";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Media,
    NavItem,
    NavLink,
    Nav,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    TabContent,
    TabPane,
    Container,
    Row,
    Col
  } from "reactstrap";

  import HowItWorks from "../Common/HowItWorks";
  import HowItWorksCommercial from "../Common/HowItWorksCommercial";
  import StudentPricing from "../Common/StudentPricing";
  import ResidentialPricing from "../Common/ResidentialPricing";
  import Faq from "../Common/FaqDesktop";

  
  const DesktopView = (props)=>{
    let userType,action,actionType;
    ([userType,action] = ["student","how-it-works"]);

    const {match} = props;

    if(!_.isEmpty(match.params )){
        ({userType,action:actionType} = match.params);
        action  = (actionType !== undefined) ? actionType:action;
    }
    //console.log(action);
    

    let viewTab = "1";
    //Default view
    const [vTabs,setVTabs] = React.useState(viewTab);

    switch(action){
        case "how-it-works":viewTab = "1";
                            break;
        case "pricing":     viewTab = "2";
                            break;
        case "faq":         viewTab = "3";
                             break;

    }
    
    /*THIS IS WHERE I UDPATE THE COMPONENT */
    if(vTabs !== viewTab){
        setVTabs(viewTab)
    }
    
    const viewPage = (view)=>{
        return props.history.push(`/laundry/how-it-works/${userType}/${view}`)
    }

    const isStudent = (userType === "student") ? true:false;
    const isResidential = (userType === "residential") ? true:false;
    const isCommercial = (userType === "commercial") ? true:false;

    return(
        <>
            <Row>
                <Col md="12" sm="12">
                    <Row>
                        <Col md="2" sm="2" xs="4" className="d-none d-md-block">
                        <div className="nav-tabs-navigation">
                        <div className="nav-tabs-wrapper">
                            <Nav
                            className="flex-column nav-stacked"
                            role="tablist"
                            tabs
                            >
                                    <NavItem>
                                        <NavLink
                                        className={vTabs === "1" ? "active" : ""}
                                        onClick={() => {
                                            return viewPage("");
                                        }}
                                        >
                                        How it works
                                        </NavLink>
                                    </NavItem>
                            { 
                                !isCommercial &&
                                <>
                                    <NavItem>
                                        <NavLink
                                        className={vTabs === "2" ? "active" : ""}
                                        onClick={() => {
                                            return viewPage("pricing");
                                        }}
                                        >
                                        Locations & Pricing
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                        className={vTabs === "3" ? "active" : ""}
                                        onClick={() => {
                                            return viewPage("faq");
                                        }}
                                        >
                                        FAQ's
                                        </NavLink>
                                    </NavItem>
                                </>
                            }
                            </Nav>
                        </div>
                        </div>
                    </Col>
                        <Col md="10" sm="10" xs="8" className="d-none d-md-block">
                        {/* Tab panes */}
                        <TabContent activeTab={"vTabs" + vTabs}>
                            <TabPane tabId="vTabs1">
                                {
                                    (isCommercial === true) ? 
                                      <HowItWorksCommercial /> : 
                                      <HowItWorks 
                                        isStudent={isStudent}
                                        isResidential={isResidential}
                                      />
                                }
                            </TabPane>
                            <TabPane tabId="vTabs2">
                                {
                                    isStudent && 
                                    <StudentPricing
                                        {...props}
                                    />
                                }
                                {
                                    isResidential &&
                                    <ResidentialPricing
                                        {...props}
                                    />
                                }
                            </TabPane>
                            <TabPane tabId="vTabs3">
                                <Faq 
                                    {...props}
                                    isStudent={isStudent}
                                    isResidential={isResidential}
                                />    
                            </TabPane>
                            
                        </TabContent>
                    </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );



}

export default DesktopView;