import React from "react";

// reactstrap components
import {
    Button,
    Container,
    Row,
    Col,
    FormGroup, 
    Modal, 
} from "reactstrap";



const ProcessingModal = (props)=>{
    const {displayModal,handleCloseOfModalWindow} = props;

    return(
        <>
            <Modal isOpen={displayModal} toggle={() => handleCloseOfModalWindow(false)}>
                <div className="modal-header no-border-header">
                <button
                    className="close"
                    type="button"
                    onClick={() => handleCloseOfModalWindow(false)}
                >
                    ×
                </button>
                <h4 className="modal-title text-success modal-myaccount-header-animation" id="myAccountLoadingLabel">
                    <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> Processing...Please wait
                </h4>
                
                </div>
                <div className="modal-body">
                
                
                </div>
                
            </Modal>
        </>
    );

}
export default ProcessingModal;