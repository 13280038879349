import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import auth from "services/authService";

// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Badge,
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  NavLink,
  Container,
  UncontrolledTooltip
} from "reactstrap";

import UserNotifications from "./UserNotification";
// core components

function MyAccountNavbar(props) {
  const {match,userNotifications} = props
  const accountType = match.params.accountType || ""
  const {shoppingCart} = props.globalApp;
  const isCartEmpty = (shoppingCart.supplies.items.length === 0) ? true:false;
  
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  });
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="fixed-top" expand="lg" id="navbar-main" color="danger">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" to="/index" tag={Link}>
                <span style={{fontSize:"20px"}}><strong>Lazybones</strong></span>
            </NavbarBrand>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
                <Nav className="ml-auto" navbar>
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle className="mr-2" color="default" caret nav>
                    Laundry
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-danger" right>
                    <UncontrolledDropdown className="dropdown-item">
                        <DropdownToggle
                        className="dropdown-item"
                        color="default"
                        tag="a"
                        caret
                        >
                        Students
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-danger">
                        <DropdownItem
                            to="/laundry/how-it-works/student" 
                            tag={Link}
                        >
                            How it works?
                        </DropdownItem>
                        <DropdownItem
                            to="/laundry/how-it-works/student/pricing" 
                            tag={Link} 
                        >
                            Locations & Pricing
                        </DropdownItem>
                        <DropdownItem
                            to="/laundry/how-it-works/student/faq" 
                            tag={Link} 
                        >
                            FAQ's
                        </DropdownItem>
                        
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown className="dropdown-item">
                        <DropdownToggle
                        className="dropdown-item"
                        color="default"
                        tag="a"
                        caret
                        >
                        Residential
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-danger">
                        <DropdownItem
                            to="/laundry/how-it-works/residential" 
                            tag={Link}
                        >
                            How it works?
                        </DropdownItem>
                        <DropdownItem
                            to="/laundry/how-it-works/residential/pricing" 
                            tag={Link}
                        >
                            Locations & Pricing
                        </DropdownItem>
                        <DropdownItem
                            to="/laundry/how-it-works/residential/faq" 
                            tag={Link}
                        >
                            FAQ's
                        </DropdownItem>
                        
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown className="dropdown-item">
                        <DropdownToggle
                        className="dropdown-item"
                        color="default"
                        tag="a"
                        caret
                        >
                        Commercial
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-danger">
                            <DropdownItem
                                to="/laundry/how-it-works/commercial" 
                                tag={Link}
                            >
                                How it works?
                            </DropdownItem>
                            
                            
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    
                    
                    </DropdownMenu>
                </UncontrolledDropdown>
                
                
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle className="mr-2" color="default" caret nav>
                    Storage
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-danger" right>
                        <DropdownItem
                            to="/storage/how-it-works/student" 
                            tag={Link}
                        >
                            How it works?
                        </DropdownItem>
                        <DropdownItem
                            to="/storage/how-it-works/student/pricing" 
                            tag={Link}
                        >
                            Locations & Pricing
                        </DropdownItem>
                        {
                            !auth.getCurrentUser() && 
                            <DropdownItem
                                to="/signup/storage/student/select-school" 
                                tag={Link}
                            >
                            Signup
                            </DropdownItem>
                        }
                        <DropdownItem
                            to="/storage/how-it-works/student/faq" 
                            tag={Link}
                        >
                            FAQ's
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle className="mr-2 d-none" color="default" caret nav>
                    Services
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-danger" right>
                        <UncontrolledDropdown>
                            <DropdownToggle
                            className="dropdown-item"
                            color="default"
                            tag="a"
                            caret
                            >
                            Supplies
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-danger">
                                <DropdownItem
                                    href="#pablo"
                                    onClick={e => e.preventDefault()}
                                >
                                    Pricing
                                </DropdownItem>
                                <DropdownItem
                                    href="#pablo"
                                    onClick={e => e.preventDefault()}
                                >
                                    Purchase
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <DropdownItem to="/presentation" tag={Link}>
                            Giftcards
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle className="mr-2 d-none" color="default" caret nav>
                    Signup
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-danger" right>
                    <DropdownItem to="/index" tag={Link}>
                        Laundry
                    </DropdownItem>
                    <DropdownItem to="/presentation" tag={Link}>
                        Storage
                    </DropdownItem>
                    <DropdownItem
                        href="https://demos.creative-tim.com/paper-kit-pro-react/#/documentation/introduction?ref=pkpr-multi-level-dropdown-navbar"
                        target="_blank"
                    >
                        Supplies
                    </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>

                <NavItem>
                    <NavLink 
                        to="/supplies/" 
                        tag={Link} 
                    >
                    Supplies
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink to="/gift-cards/" tag={Link} >Gift Cards</NavLink>
                </NavItem>

                <NavItem className="d-none">
                    <NavLink href="/components/">Find our trucks</NavLink>
                </NavItem>

                {
                    auth.getCurrentUser() && userNotifications !== "" && 
                    <UserNotifications
                        {...props}
                    />
                }
                
                
                {
                    auth.getCurrentUser() && 
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle className="mr-2" color="default" caret nav>
                            <i aria-hidden={true} className="fa fa-user"></i> {auth.getCurrentUser().fullName}
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-danger" right>
                            <DropdownItem to={`/myaccount/${accountType}`} tag={Link}>
                                View Account Details
                            </DropdownItem>
                            <DropdownItem to="/myaccount/view-profile" tag={Link}>
                                View Profile
                            </DropdownItem>
                            <DropdownItem to="/myaccount/view-profile/update-credentials" tag={Link}>
                                Change Password
                            </DropdownItem>
                            <DropdownItem
                               to="/logout" 
                               tag={Link}
                            >
                                Logout
                            </DropdownItem>
                            
                        </DropdownMenu>
                    </UncontrolledDropdown>
                }

                { !isCartEmpty &&  
                    <NavItem>
                    <   NavLink to="/shoppingCart" tag={Link}><i className="fa fa-shopping-cart"></i>{" "}My Cart</NavLink>
                    </NavItem>
                }

                </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}


MyAccountNavbar.defaultProps = {
    userNotifications:"",
}

export default MyAccountNavbar;
