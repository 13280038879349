import React from "react";
import _ from "lodash"
import accounting from "accounting";
import moment from "moment";

import {
    Button,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Label,
    Row,
    Col,
    FormGroup, 
    Modal, 
    Input, 
    Table,
} from "reactstrap";



const SupplyOrderDetailView = ({supplyOrder})=>{

    const {
        supply_order_id:supplyOrderId,
        requested_shipdate:shipDate,
        order_date:orderDate,
        total,
        sales_tax_percent:salesTaxPercent,
        subtotal,
        tax,
        SupplyOrderItems:orderItems
    } = supplyOrder
   
    return(
        <>
        <div className="d-flex justify-content-between bd-highlight">
            <div className="p-2 bd-highlight"><strong>Order Date:</strong> {moment(orderDate).format("MMMM Do, YYYY")}</div>
            <div className="p-2 bd-highlight"><strong>Delivery Date:</strong> {moment(shipDate).format("MMMM Do, YYYY")}</div>
        </div>
        <div>
            <Row className="mt-2">
                <Col>

                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th className="text-center"></th>
                                <th className="text-center">Item</th>
                                <th>Quantity</th>
                                <th>Unit Price</th>
                                <th className="text-right">Total</th>
                                <th/>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orderItems.map(s=>{
                                    let [titleDisplay,imagePath] = ["",""]
                                    const {
                                        id,
                                        quantity,
                                        unit_price:unitPrice,
                                        total_price:totalPrice,
                                        is_bundle:isBundle,
                                        SupplyExtraItem:individualItemRecord,
                                        SupplyPack:bundleDetailRecord
                                    } = s;

                                    if(isBundle === 1){
                                        const {
                                            title,
                                            img_location
                                        } = bundleDetailRecord;

                                        titleDisplay = title;
                                        imagePath = img_location;
                                    
                                    }
                                    else{
                                        const {
                                            extra_title,
                                            extra_img,
                                        } = individualItemRecord;

                                        titleDisplay = extra_title;
                                        imagePath = extra_img;

                                    }
                                    
                                    return(
                                        <tr key={id}>
                                            <td className="text-center">
                                                <img
                                                    alt="..."
                                                    className="img-rounded img-thumbnail"
                                                    src={require(`assets/mylazybones${imagePath}`)}
                                                    style={{width:"100px",height:"100px"}}
                                                />
                                            </td>
                                            <td>
                                                {titleDisplay}
                                            </td>
                                            <td>
                                                {quantity}
                                            </td>
                                            <td>{accounting.formatMoney(unitPrice)}</td>
                                            <td className="text-right">
                                                {accounting.formatMoney(quantity * unitPrice)}
                                            </td>
                                            <td/>
                                            
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <td colSpan="4" className="text-right">Subtotal</td>
                                <td className="text-right">{accounting.formatMoney(subtotal)}</td>
                            </tr>
                            <tr>
                                <td colSpan="4" className="text-right">{`Tax (${accounting.formatNumber(salesTaxPercent,2)}%)`}</td>
                                <td className="text-right">{accounting.formatMoney(tax)}</td>
                            </tr>
                            <tr>
                                <td colSpan="4" className="text-right  font-weight-bold">Total</td>
                                <td className="text-right font-weight-bold">{accounting.formatMoney(total)}</td>
                            </tr>


                        </tbody>
                    </Table>
                
                </Col>
            </Row>
        </div>
        </>
    )
}

export default SupplyOrderDetailView;