import React, {Component} from "react";
import Select from "react-select";
import _ from "lodash";
import {Elements} from 'react-stripe-elements';


// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Media,
    NavItem,
    NavLink,
    Nav,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    TabContent,
    TabPane,
    Container,
    Row,
    Col
} from "reactstrap";

import BaseForm from "../../../../../BaseClass/Form"

import CustomerPrimaryInformation from "../Common/CustomerPrimaryInformation";
import CredentialInformation from "../Common/CredentialInformation";
import StudentCampusAddressInformation from "../Common/StudentCampusAddressInformation";
import StudentHomeAddressInformation from  "../Common/StudentHomeAddressInformation";
import PaymentInformation from "../Common/PaymentInformation";
import ResidentialAddressInformation from "../Common/ResidentialAddressInformation";

import applicationConstants from "../../../../../../utils/applicationConstants";

class ProfileMobileView extends BaseForm{
    constructor(){
        super();
        this.state = {
            viewProfileDropDownOptions:[
                
            ],
            data:{
              selectedView:{ value: "primary-information", label: "Primary Information" },
            },
            errors:{

            }
        }
    }

    componentDidMount(){
        this.mapModelToView();
        
    }

    componentDidUpdate(prevProps){
        const {user,match} = this.props;
        const {user:previousUser,match:prevMatch} = prevProps;

        
        if(!_.isEqual(previousUser.primaryInformation,user.primaryInformation)){
            this.mapModelToView();
        }

        let [action,actionPrev,actionType] = ["primary-info","primary-info"]
        if(!_.isEmpty(match.params )){
            ({widgetView:actionType} = match.params);
            action  = (actionType !== undefined) ? actionType:action;
        }

        if(!_.isEmpty(prevMatch.params )){
            ({widgetView:actionType} = prevMatch.params);
            actionPrev  = (actionType !== undefined) ? actionType:actionPrev;
        }

        if(action !== actionPrev)
            this.mapModelToView();

    }

    handleContentDisplayArea = (viewObject)=>{
        //const selectedValue = dropDownSelection.value;
        //setContentDisplayArea(selectedValue);
        const selectedView = viewObject.value;
        let view = "primary-info";
        switch(selectedView){
            case "primary-information":    view = "primary-info";break;
            case "credential-information": view = "update-credentials";break;
            case "student-primary-address-information":    view = "service-address"; break;
            case "residential-primary-address-information":view = "service-address"; break;
            case "student-home-address-information":  view = "home-address"; break;
            case "payment-information":  view = "payment-info"; break;

        }
        
        return this.props.history.push(`/myaccount/view-profile/${view}`)
    }

    mapModelToView = ()=>{
        let action,actionType;
        ([action] = ["primary-info"]);

        const {
            match,
            user,
        } = this.props;
        
        const {
            customerRole,
        } = user;

        if(!_.isEmpty(match.params )){
            ({widgetView:actionType} = match.params);
            action  = (actionType !== undefined) ? actionType:action;
        }

        const isStudent = (customerRole === applicationConstants.CUSTOMER_TYPE_STUDENT_TEXT) ? true:false;
        const isResidential = (customerRole === applicationConstants.CUSTOMER_TYPE_RESIDENTIAL_TEXT) ? true:false;

        
        let viewProfileDropDownOptions =  [
            { value: "primary-information", label: "Primary Information" },
            { value: "credential-information", label: "Username & Password" },
        ];

        if(isStudent){
            viewProfileDropDownOptions =  [
                { value: "primary-information", label: "Primary Information" },
                { value: "credential-information", label: "Username & Password" },
                { value: "student-primary-address-information", label: "Campus (Pick-up) address" },
                { value: "student-home-address-information", label: "Home address" },
                { value: "payment-information", label: "Payment Information" },
            ];
    
        }

        if(isResidential){
            viewProfileDropDownOptions =  [
                { value: "primary-information", label: "Primary Information" },
                { value: "credential-information", label: "Username & Password" },
                { value: "residential-primary-address-information", label: "Primary address" },
                { value: "payment-information", label: "Payment Information" },
            ];
        }

        let selectedView = { value: "primary-information", label: "Primary Information" }
        switch(action){
            case "primary-info": selectedView = { value: "primary-information", label: "Primary Information" };
                                break;
            case "update-credentials": selectedView = { value: "credential-information", label: "Username & Password" };
                                break;
            case "service-address":  
                                    selectedView = (isResidential) ? 
                                    { value: "residential-primary-address-information", label: "Primary address" } 
                                    : 
                                    { value: "student-primary-address-information", label: "Campus (Pick-up) address" };
                                 break;
            case "home-address":  selectedView = { value: "student-home-address-information", label: "Home address" };
                                     break;
            case "payment-info":  selectedView = { value: "payment-information", label: "Payment Information" };
                                  break;
    
        }

        this.setState({
            viewProfileDropDownOptions,
            data:{
                selectedView
            }
        })
    }

    render(){
        const {
            user,
            customerPrimaryInfoDisplayProperties,
        } = this.props;
        
        const {
            customerRole,
            userDb:userDatabaseInformation,
        } = user;

        const isStudent = (customerRole === applicationConstants.CUSTOMER_TYPE_STUDENT_TEXT) ? true:false;
        const isResidential = (customerRole === applicationConstants.CUSTOMER_TYPE_RESIDENTIAL_TEXT) ? true:false;

        const {
            viewProfileDropDownOptions,
            data,
        } = this.state;

        const { selectedView:selectedViewObject }  = data;
        const {value:selectedView} = selectedViewObject;
        
        return(
            <>
                <Row>
                    <Col md="12" sm="12">
                        <Row className="mt-2">
                            <Col xs="12" sm="12">
                                <FormGroup>
                                    <Select
                                        className="react-select react-select-default"
                                        classNamePrefix="react-select"
                                        name="selectedView"
                                        value={selectedViewObject}
                                        onChange={value => this.handleContentDisplayArea(value,"selectedView")}
                                        options={viewProfileDropDownOptions}
                                        placeholder=""
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="12">
                                {   
                                    selectedView === "primary-information" && 
                                    <CustomerPrimaryInformation  
                                        displaySaveAndBackControlsForStepProcess={false}
                                        displaySaveAndCancelControlForNonStepProcess={true}
                                        {...this.props}
                                    />
                                }

                                {   
                                    selectedView === "credential-information" && 
                                    <CredentialInformation  
                                        {...this.props}
                                    />
                                
                                }

                                {   
                                    selectedView === "payment-information" && 
                                    <Elements>
                                        <PaymentInformation
                                            {...this.props}
                                        />
                                    </Elements>
                                
                                }

                                {   
                                    selectedView === "student-primary-address-information" && 
                                    <StudentCampusAddressInformation
                                        {...this.props}
                                        user={{primaryInformation:userDatabaseInformation}}
                                    />
                                
                                } 

                                

                                {   
                                    selectedView === "student-home-address-information" && 
                                    <StudentHomeAddressInformation
                                        {...this.props}
                                        user={{primaryInformation:userDatabaseInformation}}
                                    />
                                
                                }

                                {   
                                    selectedView === "residential-primary-address-information" && 
                                    <ResidentialAddressInformation
                                        {...this.props}
                                        user={{primaryInformation:userDatabaseInformation}}
                                    />
                                
                                }                   

                 
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }

}

export default ProfileMobileView