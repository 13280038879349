import React,{Component} from "react";
import Select from "react-select";
import _ from "lodash";

import http from "services/httpService";
import Joi from "@hapi/joi";

import {getUSStateList} from "services/dropDownListsService";
import SchoolService from "services/schoolService";
import SuppliesService from "services/suppliesService";
import SuppliesRegistrationStepsDisplayWidget from "../Common/SuppliesRegistrationStepsDisplayWidget";

import {scrollTop} from '../../../../utils/helperUtils';
import {renderError} from '../../../../utils/errorUtils';

import FormErrorWidget from "../../../Common/FormErrorWidget";

// reactstrap components
import {
    Button,
    Container,
    Card,
    CardBody,
    CardFooter,
    CardImg,
    CardImgOverlay,
    CardLink,
    CardTitle,
    CardSubtitle,
    CardText,
    ListGroupItem,
    ListGroup,
    Row,
    Col,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    FormText
    
} from "reactstrap";

const apiUserEndpoint = "/users";

class CustomerInformation extends Component{

    userSchema = Joi.object({
        firstName: Joi.string().required().label('First Name'),
        lastName:Joi.string().required().label('Last Name'),
        email:Joi.string().email({tlds:false}).required().label('Email'),
        cellPhone:Joi.string().required().label('Cell Phone'),
        homePhone:Joi.string().required().label('Home Phone'),
        studentId:Joi.any().allow(""),
        school:Joi.any().allow(""),
    });
    
    deliveryAddressSchema = Joi.object({
        isStudent:Joi.boolean().optional(),
        isDormitory: Joi.alternatives().conditional(
            'isStudent', { 
                is: true, 
                then: Joi.string().required(), 
                otherwise: Joi.string().allow("")
            }
        ),
        dormitoryName: Joi.alternatives().conditional(
            'isDormitory', { 
                is: 'Yes', 
                then: Joi.object().keys({
                    value:Joi.number().required(), 
                    label:Joi.any().optional(),
                }), 
                otherwise: Joi.any().optional()
            }
        ),
        dormitoryRoomNumber: Joi.alternatives().conditional(
            'isDormitory', { 
                is: 'Yes', 
                then: Joi.string().required(), 
                otherwise: Joi.any().optional()
            }
        ).label('Dormitory Room Number'),
        streetNumber: Joi.alternatives().conditional(
            'isDormitory', { is: 'No', then: Joi.string().required().label('Street Number'),otherwise: Joi.any().optional() }
        ),
        addressLine1: Joi.alternatives().conditional(
            'isDormitory', { is: 'No', then: Joi.string().required().label('Address Line 1'),otherwise: Joi.any().optional() }
        ),
        addressLine2: Joi.any().optional(),
        apartmentNumber:Joi.any().optional(),
        zoneId: Joi.any().optional(),
        city:Joi.string().required().label('City'),
        state:Joi.object().keys({
            value:Joi.string().required(), 
            label:Joi.any().optional(),
        }),
        postalCode:Joi.string().required().label('Postal Code'),
        country:Joi.string().required().label('Country'), 
    }) ;

    /*Validation custom messages to map with Job error object */
    errorMessages = {
        "state":{
            "object.base":"Delivery Address State is not allowed to be empty",
        },
        "dormitoryName":{
            "object.base":"Dormitory is not allowed to be empty",
        },
    }

    constructor(){
        super();
        
        this.state = {
            stateList:[{ value: "", label: "", isDisabled: true }],
            schoolList:[{ value: "", label: "", isDisabled: true }],
            dormitoryList:[{ value: "", label: "", isDisabled: true }],
            dormitoryObjectList:"",
            data:{
                firstName:"",
                lastName:"",
                email:"",
                studentId:"",
                cellPhone:"",
                homePhone:"",
                isStudent:"",
                school:"",
                deliveryAddress:{
                    isStudent:false, //this will equal to data.isStudent as I am need it for the validation schema
                    isDormitory:"No",
                    dormitoryName:"",  //{value:"", label:""} this corresponds to the react-select value setup
                    dormitoryRoomNumber:"",
                    streetNumber:"", //Non dormitory street number
                    addressLine1:"",
                    addressLine2:"",
                    apartmentNumber:"", //Non dormitory apartment number
                    zoneId:"",
                    city:"",
                    state:"",
                    postalCode:"",
                    country:"USA",
                }
            },
            errors:{}
        }    

    }

    async componentDidMount(){
        console.log("Component did mount");
        const stateToUpdate =  {};
        
        const {postalCode,supplies,universityId}  = this.props.globalApp.shoppingCart;

        const stateList = getUSStateList();
        stateToUpdate.stateList = stateList;

        /*If school has been set on the new user property then  */
        const {school} = this.props.user;
        if(school){
            const dormitoryList = await SchoolService.getListOfDormitoryBySchool(school);
            stateToUpdate.dormitoryList = dormitoryList;
        }

        /*If city has been set in the shopping cart property then we get the list of schools drop down */
        //if(postalCode && !school && universityId){
            const schoolList = await SuppliesService.getListOfSchoolsByUniversity(universityId);
            stateToUpdate.schoolList = schoolList;
        //}

        
        this.setState(stateToUpdate);
        await this.mapModelToView();

    }

    componentDidUpdate(){
        //console.log("c did update");
    }

    handleChange = ({currentTarget:input})=>{
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[input.name] = input.value;
        this.setState({data,errors});
        
    }

    handleChangeAddress = (e,addressType)=>{
        const {name,value} = e.currentTarget;
        //console.log(`${name} : ${value}`);
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        
        if(name === "isDormitoryDeliveryAddress")
            address.isDormitory = value 
        else
            address[name] = value;

        data[addressType] = address;

        this.setState({data});
        
    }

    handleSelectSchool = async schoolOption => {
        const stateToUpdate =  {};
        const data = {...this.state.data};
        const address = {...this.state.data.deliveryAddress};
        data.school = schoolOption;
        const isStudent = (schoolOption.value !== "" && schoolOption.value !== "none") ? true:false;
        data.isStudent = address.isStudent  = isStudent;
        
        if(data.isStudent){
            const schoolObject = {
                id:schoolOption.value,
                name:schoolOption.label
            };
            const dormitoryList = await SchoolService.getDropdownListOfDormitoryBySchool(schoolObject);
            const dormitoryObjectList = await SchoolService.getListOfDormitoryBySchool(schoolObject);
            

            stateToUpdate.dormitoryList = dormitoryList;
            stateToUpdate.dormitoryObjectList = dormitoryObjectList;
        }else{
            address.isDormitory = "";
            data.deliveryAddress = address;
        }
        stateToUpdate.data = data;
        
        

        this.setState(stateToUpdate);
    }

    handleSubmit = async (e)=>{
        try{
            e.preventDefault();  
 
             //client side errors will be caught in validae(), server side data error will be caught via try/catch
             const errors = await this.validate();
             this.setState({errors: errors || {}});
             if(errors){scrollTop('sectionArea'); return;} 
             
             const {onClickOfNextButtonFromCustomerInformation} = this.props;  
             console.log("Handle submit of student information");
             //console.log(this.state);
             const customerInformation = {...this.state.data};
             onClickOfNextButtonFromCustomerInformation(customerInformation);
             return;
        }catch(ex){
             /*
               400 signals validation errors from the server
               renderError() basically will display server side error other than code 400
             */
             if(ex.response && ex.response.status === 400){ 
                 const errors = ex.response.data;
                 this.setState({errors: errors || {}});
                 if(errors){ scrollTop('sectionArea'); return;}
             }
             else
               renderError(ex);
        }
    }

    setAddressDormitory = (valueObject,addressType)=>{
        const {dormitoryObjectList,stateList} = this.state; 
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        address.dormitoryName = valueObject;

        //Get the dorm object by selected dorm name to populate the city state zip
        const selectedDormitoryObject = _.find(dormitoryObjectList,{"id":valueObject.value});
        //console.log(selectedDormitoryObject);
        
        const selectedStateObject = _.find(stateList,{"value":selectedDormitoryObject.state});
        
        address.city = selectedDormitoryObject.city;
        address.state = selectedStateObject;
        address.postalCode = selectedDormitoryObject.zip;
        
        data[addressType] = address;
        this.setState({data});
    }

    setAddressState = (value,addressType)=>{
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        address.state = value;
        data[addressType] = address;

        this.setState({data});

    }

    mapModelToView = async ()=>{
        const {globalApp,user} = this.props;
        const {firstName,lastName,email,cellPhone,homePhone,studentId,school:selectedSchoolObject} = user;
        const {deliveryAddress:deliveryAddressUser} = user.supplies;
        const stateList = getUSStateList();

        const isStudent = (user.supplies.type === "student") ? true:false;
        let school = "";
        if(isStudent){
            school ={
                value:selectedSchoolObject.id, 
                label:selectedSchoolObject.name
            }
        }else{
            /*
            school ={
                value:"none", 
                label:"None"
            }
            */
        }    

        //Set delivery address
        const deliveryAddress = {...deliveryAddressUser};
        if(deliveryAddress.isDormitory){
            deliveryAddress.isDormitory = "Yes";
            deliveryAddress.dormitoryName={
                value:deliveryAddress.dormitory.id,
                label:deliveryAddress.dormitory.name,
            };
            deliveryAddress.dormitoryRoomNumber = deliveryAddress.dormitory.roomNumber;
        }
        else{
            deliveryAddress.isDormitory="No";
            deliveryAddress.dormitoryName = "";
            deliveryAddress.dormitoryRoomNumber = "";
        }

        if(deliveryAddressUser.state){
            deliveryAddress.state = _.find(stateList,{'value':deliveryAddress.state});
        }

        if(!deliveryAddressUser.postalCode){
            const {shoppingCart} = globalApp;
            const {university} = shoppingCart;
            
            deliveryAddress.state = _.find(stateList,{'value':university.state});
            deliveryAddress.postalCode = shoppingCart.postalCode;
        }

        let dormitoryObjectList="",
            dormitoryList="";
        if(isStudent && !_.isEmpty(selectedSchoolObject)){
          dormitoryList = await SchoolService.getDropdownListOfDormitoryBySchool(selectedSchoolObject);
          dormitoryObjectList = await SchoolService.getListOfDormitoryBySchool(selectedSchoolObject);
        }
        //console.log(dormitoryObjectList);
        
        delete deliveryAddress.dormitory;

        //End of Set delivery address

        
        const data = {firstName,lastName,email,cellPhone,homePhone,studentId,school,isStudent,deliveryAddress};
        
        //console.log(data);
        if(!_.isEqual(this.state.data,data))
           this.setState({data});
        
        if(!_.isEqual(this.state.dormitoryList,dormitoryList))
           this.setState({dormitoryObjectList, dormitoryList});


    }

    validate= async ()=>{
        const options = {abortEarly:false,allowUnknown:true};
        //console.log(this.state.data);
        const {email} = this.state.data
        const {postalCode,supplies,universityId}  = this.props.globalApp.shoppingCart;

        /* Check is zipcode is valid  */
            let zipcodeValidationError= "",
                zipcodeRecord = "",
                postalCodeForValidation="";
            try{
                ({postalCode:postalCodeForValidation} = this.state.data.deliveryAddress);
                zipcodeRecord = await SuppliesService.validateZipcodeForSupplies({postalCode:postalCodeForValidation});
            }
            catch(ex){
                return zipcodeValidationError = {
                    "zipcodeError":"The zip code you entered is not valid for purchasing supplies",
                }; 
            }

            const { university_id:universityIdDbRecord } = zipcodeRecord;
            if(universityId !== universityIdDbRecord){
                return zipcodeValidationError = {
                    "zipcodeError":"The zipcode you entered in the catalog does not match with the zipcode for your delivery address",
                }; 
            }
        /* End of Check is zipcode is valid  */



        let {error:primaryInformationError} = this.userSchema.validate(this.state.data,options);
        let {error:deliveryAddressError} = this.deliveryAddressSchema.validate(this.state.data.deliveryAddress,options);
        

        //Validate if email exists in the system
        if(!primaryInformationError){
            const {data:returnStatus} = await http.post(`${apiUserEndpoint}/validateByEmail`,{email});
            if(returnStatus.status === false){
                primaryInformationError = {
                    details:[
                        {message:"User with email address exist in the system",
                        path:["emailAddressExist"],
                        }
                    ]
                }; 
            }
        }

        if(primaryInformationError === undefined  && deliveryAddressError === undefined) return null;

        const primaryInformationErrors = {};
            if(primaryInformationError){
                primaryInformationError.details.map(
                    function(errorObject){
                        //console.log(errorObject);
                        if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                            const key = errorObject.context.key;
                            const joiType = errorObject.type;
                            //console.log(this.errorMessages);
                            if(_.has(this.errorMessages, [key, joiType]))
                                return primaryInformationErrors[errorObject.path[0]] = this.errorMessages[key][joiType];
                            else
                                return primaryInformationErrors[errorObject.path[0]] = errorObject.message;
                        }
                        else
                          return primaryInformationErrors[errorObject.path[0]] = errorObject.message;
                    }.bind(this)
                );
            }
            
            const deliveryAddressErrors = {};
            if(deliveryAddressError){
                deliveryAddressError.details.map(
                    function(errorObject){
                        //console.log(errorObject);
                        if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                            const key = errorObject.context.key;
                            const joiType = errorObject.type;
                            //console.log(this.errorMessages);
                            if(_.has(this.errorMessages, [key, joiType]))
                                return deliveryAddressErrors[errorObject.path[0]] = this.errorMessages[key][joiType];
                            else
                                return deliveryAddressErrors[errorObject.path[0]] = errorObject.message;
                        }
                        else
                          return deliveryAddressErrors[errorObject.path[0]] = errorObject.message;
                    }.bind(this)
                );
            }

        const errors = {...primaryInformationErrors,...deliveryAddressErrors} 
        
        console.log(errors);
        return Object.keys(errors).length > 0 ? errors:null;
        
    }

    render(){
        //console.log("Supplies Signup Customer Information render"); 
        //console.log(this.props);
        const {data,errors} = this.state;
        const {provideSuppliesToStudent}  = this.props.globalApp.shoppingCart.supplies;
        
        const {deliveryAddress,isStudent} = data;
        const isStudentCheckedYes = (isStudent === "Yes") ? {defaultChecked:true}:{};
        const isStudentCheckedNo = (isStudent === "No") ? {defaultChecked:true}:{};

        const deliveryAddressIsDormitoryCheckedYes = (deliveryAddress.isDormitory === "Yes") ? {defaultChecked:true}:{};
        const deliveryAddressIsDormitoryCheckedNo = (deliveryAddress.isDormitory === "No") ? {defaultChecked:true}:{};
        const deliveryAddressIsDormitoryCSS = "form-check-radio";
        

        const enableDisplaySwitch = false;
        

        return(
            <Container id="mainContentArea">
                <Row className="title-row justify-content-between">
                    <Col md="6">
                    <h3 className="shop">Signup for Supplies</h3>
                    </Col>
                    <Col md="4">
                        <div className="text-md-right d-none">
                            <span className="text-muted">Step 1</span>
                            <Button color="link">
                            <i className="fa fa-shopping-cart" /> Enter your information
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row className="title-body-row">
                    <Col md="8">
                        <Card>
                            <CardBody>
                                {/*Error display */}
                                {!_.isEmpty(errors) &&
                                    <FormErrorWidget
                                    errors={errors}
                                    />
                                }
                                {/* End of Error display */}
                                
                                <Form>
                                    <h6 className="card-category text-left">Primary Information</h6>
                                    <hr/>

                                    <FormGroup row>
                                        <Label for="firsName" sm={2}>Name</Label>
                                        <Col xs={6} sm={5}>
                                            <Input 
                                            type="text" 
                                            name="firstName" 
                                            id="firstName" 
                                            placeholder="First Name" 
                                            value={this.state.data.firstName} 
                                            onChange={this.handleChange}
                                            />
                                        </Col>
                                        <Col xs={6} sm={5}>
                                            <Input 
                                            type="text" 
                                            name="lastName" 
                                            id="firstName" 
                                            placeholder="Last Name" 
                                            value={this.state.data.lastName} 
                                            onChange={this.handleChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                
                                    <FormGroup row>
                                        <Label for="email" sm={2}>Email</Label>
                                        <Col sm={10}>
                                            <Input 
                                                type="email" 
                                                name="email" 
                                                id="email" 
                                                placeholder="Email address" 
                                                value={this.state.data.email} 
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label for="cellPhone" sm={2}>Cell Phone</Label>
                                        <Col sm={10}>
                                            <Input 
                                                type="text" 
                                                name="cellPhone" 
                                                id="cellPhone" 
                                                placeholder="" 
                                                value={this.state.data.cellPhone} 
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label for="cellPhone" sm={2}>Home Phone</Label>
                                        <Col sm={10}>
                                            <Input 
                                                type="text" 
                                                name="homePhone" 
                                                id="homePhone" 
                                                placeholder="" 
                                                maxLength="20"
                                                value={this.state.data.homePhone} 
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </FormGroup>

                                    {
                                        enableDisplaySwitch && provideSuppliesToStudent && 

                                        <FormGroup row>
                                            <Label for="" xs={6} sm={4}>Are you a student from following?</Label>
                                            <Col xs={2} sm={1} className="mt-1">
                                                <div className="form-check-radio">
                                                    <Label check>
                                                        <Input
                                                            {...isStudentCheckedYes}
                                                            defaultValue="Yes"
                                                            id="isStudentYes"
                                                            name="isStudent"
                                                            type="radio"
                                                            onChange={e=>{this.handleChange(e)}}
                                                        />
                                                        Yes
                                                        <span className="form-check-sign" />
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col xs={2} sm={1} className="mt-1 ml-2">
                                                <div className="form-check-radio">
                                                    <Label check>
                                                        <Input
                                                            {...isStudentCheckedNo}
                                                            defaultValue="No"
                                                            id="isStudentNo"
                                                            name="isStudent"
                                                            type="radio"
                                                            onChange={e=>{this.handleChange(e)}}
                                                        />
                                                        No
                                                        <span className="form-check-sign" />
                                                    </Label>
                                                </div>
                                            </Col>
                                            
                                        </FormGroup>
                                    }

                                    {   
                                        <FormGroup row>
                                            <Label for="" xs={6} sm={2}>Do you study in one of these schools:</Label>
                                            <Col xs={12} sm={8}>
                                                <FormGroup>
                                                    <Select
                                                        className="react-select react-select-default"
                                                        classNamePrefix="react-select"
                                                        name="schoolId"
                                                        id="schoolId"
                                                        value={this.state.data.school}
                                                        onChange={value => this.handleSelectSchool(value)}
                                                        options={this.state.schoolList}
                                                        placeholder="Select School"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            
                                        </FormGroup>
                                    }

                                    {   isStudent && 
                                        <FormGroup row className="mb-2">
                                            <Label for="studentId" sm={2}>Student Id</Label>
                                            <Col sm={10}>
                                                <Input 
                                                    type="text" 
                                                    name="studentId" 
                                                    id="studentId" 
                                                    placeholder="" 
                                                    maxLength="16"
                                                    value={this.state.data.studentId} 
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </FormGroup>
                                    }
                                    


                                    <h6 className="card-category text-left mt-2">Delivery Address</h6>
                                    <hr/>

                                    {   
                                        provideSuppliesToStudent && isStudent && 
                                        <FormGroup row >
                                            <Label for="" xs={6} sm={4}>Do you live in a dorm?</Label>
                                            <Col xs={2} sm={1}  className="mt-1">
                                                <div className={deliveryAddressIsDormitoryCSS}>
                                                    <Label check>
                                                        <Input
                                                            {...deliveryAddressIsDormitoryCheckedYes}
                                                            defaultValue="Yes"
                                                            id="deliveryAddressIsDormitory1"
                                                            name="isDormitoryDeliveryAddress"
                                                            type="radio"
                                                            onChange={e=>{this.handleChangeAddress(e,"deliveryAddress")}}
                                                        />
                                                        Yes
                                                        <span className="form-check-sign" />
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col xs={2} sm={1} className="ml-2 mt-1">
                                                <div className={deliveryAddressIsDormitoryCSS}>
                                                    <Label check>
                                                        <Input
                                                            {...deliveryAddressIsDormitoryCheckedNo}
                                                            defaultValue="No"
                                                            id="deliveryAddressIsDormitory2"
                                                            name="isDormitoryDeliveryAddress"
                                                            type="radio"
                                                            onChange={e=>{this.handleChangeAddress(e,"deliveryAddress")}}
                                                        />
                                                        No
                                                        <span className="form-check-sign" />
                                                    </Label>
                                                </div>
                                            </Col>
                                            
                                        </FormGroup>
                                    }

                                    {
                                        (deliveryAddress.isDormitory === "Yes") &&    
                                            <FormGroup row>
                                                <Label for="pickUpDormitory" sm={2}>Dorm Name</Label>
                                                <Col xs={10} sm={4}>
                                                    <FormGroup>
                                                        <Select
                                                            className="react-select react-select-default"
                                                            classNamePrefix="react-select"
                                                            name="dormitoryRoomNumber"
                                                            id="dormitoryRoomNumber"
                                                            value={deliveryAddress.dormitoryName}
                                                            onChange={value => this.setAddressDormitory(value,"deliveryAddress")}
                                                            options={this.state.dormitoryList}
                                                            placeholder="Dorm"
                                                            
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Label for="addressLine1" sm={2}>Room No.</Label>
                                                <Col xs={10} sm={4}>
                                                    <Input 
                                                    type="text" 
                                                    name="dormitoryRoomNumber" 
                                                    id="dormitoryRoomNumber" 
                                                    placeholder="Room No" 
                                                    maxLength="10"
                                                    value={this.state.data.deliveryAddress.dormitoryRoomNumber} 
                                                    onChange={e=>{this.handleChangeAddress(e,"deliveryAddress")}}
                                                    
                                                    />
                                                </Col>
                                            </FormGroup>
                                        }

                                        {
                                            (deliveryAddress.isDormitory !== "Yes") &&   
                                            <FormGroup row>
                                                <Label for="streetNumber" sm={2}>Street #</Label>
                                                <Col sm={4}>
                                                    <Input 
                                                    type="text" 
                                                    name="streetNumber" 
                                                    id="streetNumber" 
                                                    placeholder="Street #" 
                                                    maxLength="6"
                                                    value={this.state.data.deliveryAddress.streetNumber} 
                                                    onChange={e=>{this.handleChangeAddress(e,"deliveryAddress")}}
                                                    />
                                                </Col>

                                                <Label for="apartmentNumber" sm={2}>Apt #</Label>
                                                <Col sm={4}>
                                                    <Input 
                                                    type="text" 
                                                    name="apartmentNumber" 
                                                    id="apartmentNumber" 
                                                    placeholder="Apt Number" 
                                                    maxLength="10"
                                                    value={this.state.data.deliveryAddress.apartmentNumber} 
                                                    onChange={e=>{this.handleChangeAddress(e,"deliveryAddress")}}
                                                    />
                                                </Col>
                                                
                                            </FormGroup>
                                    }
                                    
                                    {
                                        (deliveryAddress.isDormitory !== "Yes") &&   
                                        <FormGroup row>
                                            <Label for="addressLine1" sm={2}>Address Line 1</Label>
                                            <Col sm={10}>
                                                <Input 
                                                type="text" 
                                                name="addressLine1" 
                                                id="addressLine1" 
                                                placeholder="Address Line 1" 
                                                value={this.state.data.deliveryAddress.addressLine1} 
                                                onChange={e=>{this.handleChangeAddress(e,"deliveryAddress")}}
                                                
                                                />
                                            </Col>
                                            
                                        </FormGroup>
                                    }
                                    {
                                        (deliveryAddress.isDormitory !== "Yes") &&  
                                        <FormGroup row className="d-none">
                                            <Label for="addressLine2" sm={2}>Address Line 2</Label>
                                            <Col sm={10}>
                                                <Input 
                                                type="text" 
                                                name="addressLine2" 
                                                id="addressLine2" 
                                                placeholder="Address Line 2" 
                                                value={this.state.data.deliveryAddress.addressLine2} 
                                                onChange={e=>{this.handleChangeAddress(e,"deliveryAddress")}}
                                                
                                                />
                                            </Col>
                                            
                                        </FormGroup>
                                    }    
                                    
                                    
                                    <FormGroup row>
                                        <Label for="city" sm={2}></Label>
                                        <Col xs={12} sm={3}>
                                            <Input 
                                            type="text" 
                                            name="city" 
                                            id="city" 
                                            placeholder="City" 
                                            value={this.state.data.deliveryAddress.city} 
                                            onChange={e=>{this.handleChangeAddress(e,"deliveryAddress")}}
                                            
                                            />
                                        </Col>
                                        <Label for="state" xs={12} sm={2} className="d-block d-sm-none"></Label>
                                        <Col xs={12} sm={4}>
                                            <FormGroup>
                                                <Select
                                                    className="react-select react-select-default"
                                                    classNamePrefix="react-select"
                                                    name="state"
                                                    id="state"
                                                    isDisabled = {true}
                                                    value={this.state.data.deliveryAddress.state}
                                                    onChange={value => this.setAddressState(value,"deliveryAddress")}
                                                    options={this.state.stateList}
                                                    placeholder="State"
                                                    
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            <Input 
                                            type="text" 
                                            name="postalCode" 
                                            id="postalCode" 
                                            placeholder="Zip"
                                            maxLength="5"
                                            disabled={true} 
                                            value={this.state.data.deliveryAddress.postalCode} 
                                            onChange={e=>{this.handleChangeAddress(e,"deliveryAddress")}}
                                            
                                            />
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row  className="mt-5 justify-content-between">
                                        <Col xs={{ size: 2}}>
                                            
                                        </Col>
                                        <Col xs={{ size: 6}} sm={{ size: 4}} className="text-right">
                                            <Button 
                                            className="btn-rounded btn-danger"
                                            onClick={this.handleSubmit}
                                            >
                                            Save & Proceed
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4" className="d-none d-md-block">
                        <SuppliesRegistrationStepsDisplayWidget />
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default CustomerInformation;
