import React,{Component} from "react";
import Joi from "@hapi/joi";
import _ from "lodash";

import {scrollTop} from '../../utils/helperUtils';

class Form extends Component{
  
    state = {
        data:{},
        errors:{},
        success:{},   
    }
      
    constructor(){
        super();
    }
 
  
    handleChange = ({currentTarget:input})=>{
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[input.name] = input.value;
        this.setState({data,errors});
        
    }

    handleChangeNestedAttribute = (e,nestedAttribute)=>{
        const {name,value} = e.currentTarget;
        //console.log(`${name} : ${value}`);
        const data = {...this.state.data};
        
        const nestedAttributeNode = {...this.state.data[nestedAttribute]}
        nestedAttributeNode[name] = value;
        data[nestedAttribute] = nestedAttributeNode
        
        this.setState({data});
    }

    handleDropdownChange = (value,name)=>{
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[name] = value; //Value is an object supplied by the react-select
        this.setState({data,errors});
    }

    handleDropdownChangeNestedAttribute = (value,name,nestedAttribute)=>{
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        const nestedAttributeNode = {...this.state.data[nestedAttribute]}
        nestedAttributeNode[name] = value;
        data[nestedAttribute] = nestedAttributeNode
        this.setState({data,errors});
    }

    submitForm = (e)=>{
        e.preventDefault();
        
        //Handle generic validation
        let errors =  this.validate();
        this.setState({errors: errors || {}});
        if(errors){scrollTop(this.divAreaToScrollForError); return;} 
            
        
        //Perform child class validation
        errors = this.validateLocal();
        this.setState({errors: errors || {}});
        if(errors){scrollTop(this.divAreaToScrollForError); return;} 
        

        //Perform child class submit
        this.submitLocal();
    }

    validate = ()=>{
        if(!_.isEmpty(this.validationSchema)){
            const options = {abortEarly:false,allowUnknown:true};
            //console.log(this.state.data);
            //console.log(this.validationSchema.validate(this.state.data,options))
            let {error:errorListFromJoi} = this.validationSchema.validate(this.state.data,options);
            //console.log(errorListFromJoi.details);
            if( errorListFromJoi === undefined) return null;

            const errorList = {};
            if(errorListFromJoi){
                errorListFromJoi.details.map(
                    function(errorObject){
                        //console.log(errorObject);
                        if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                            const key = errorObject.context.key;
                            const joiType = errorObject.type;
                            //console.log(this.errorMessages);
                            if(_.has(this.errorMessages, [key, joiType]))
                                return errorList[errorObject.path[0]] = this.errorMessages[key][joiType];
                            else
                                return errorList[errorObject.path[0]] = errorObject.message;
                        }
                        else
                        return errorList[errorObject.path[0]] = errorObject.message;
                    }.bind(this)
                );
            }
            
            
            
            const errors = {...errorList} 
            //const errors = {...primaryInformationErrors,...pickUpAddressErrors,...deliveryAddressErrors} 
        
            console.log(errors);
            return Object.keys(errors).length > 0 ? errors:null;
        }
        
        return null;

    }

}

export default Form;