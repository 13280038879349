import React,{Component} from "react";
import moment from "moment";
import Select from "react-select";
import _ from "lodash";
import Joi from "@hapi/joi";
import ReactDatetime from "react-datetime";
import {scrollTop} from '../../../../../../utils/helperUtils';
import FormErrorWidget from "../../../../../Common/FormErrorWidget";
import UserService from "../../../../../../services/userService";
import {getUSStateList} from "../../../../../../services/dropDownListsService";
import SchoolService from "../../../../../../services/schoolService";

import PickupInformationReview from "./PickupInformationReview";


// reactstrap components
import {
    Button,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Label,
    Row,
    Col,
    FormGroup, 
    Modal, 
    Input, 
    Card,
    CardHeader,
    CardBody,
    Collapse,
    
  } from "reactstrap";


class PickupScheduleCancelModal extends Component{
    

    errorMessages = {
        
    }

    constructor(){
        super();

        this.state = {
            
        }
    }

    saveForm = (e)=>{
        e.preventDefault();
            
        this.props.handleSaveOperation();
        return;
    }

    render(){
        

        const { 
            handleScheduleModalDisplay,
            //listOfOneTimePickupDates,
            scheduleModalDisplay,
            storageOrder,
            user 
        } = this.props

        return(
            <Modal id="storagePickupScheduleModal" size="lg" isOpen={scheduleModalDisplay} toggle={() => handleScheduleModalDisplay(false)}>
                <div className="modal-header">
                    <button
                        className="close"
                        type="button"
                        onClick={() => handleScheduleModalDisplay(false)}
                    >
                        <span>×</span>
                    </button>
                    <h5
                        className="modal-title text-center"
                        id="exampleModalLabel"
                    >
                        Cancel Storage Pickup Schedule
                    </h5>
                </div>
                <div className="modal-body">
                    <PickupInformationReview
                        storageOrder={storageOrder}
                        user={user} 
                    />
                </div>
                
                <div className={`modal-footer`}>
                    <div className="left-side">
                        <Button
                        className="btn-link"
                        color="default"
                        type="button"
                        onClick={() => handleScheduleModalDisplay(false)}
                        >
                        Cancel
                        </Button>
                    </div>
                    <div className="divider" />
                    <div className="right-side">
                        <Button className="btn-link" color="danger" type="button" onClick={this.saveForm}>
                            Yes, cancel my Pickup
                        </Button>
                    </div>
                </div>
                
                
                
            </Modal>
        );

    }
}

export default PickupScheduleCancelModal;