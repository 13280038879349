import React,{Component} from "react";
import {Link} from "react-router-dom";
import _ from "lodash";
import Joi from "@hapi/joi";

import UserService from "../../services/userService";

import BaseForm from "../BaseClass/Form"


import FormErrorWidget from "../Common/FormErrorWidget";
import SuccessMessageWidget from "../Common/SuccessMessageWidget";
import customToast from '../Common/CustomToast';
import {scrollTop}     from '../../utils/helperUtils';
import {renderError} from '../../utils/errorUtils';


import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Badge,
    Button,
    ButtonGroup,
    Form,
    FormGroup,
    Input,
    Label,
    Media
} from "reactstrap";

class ForgotPasswordForm extends BaseForm{
    divAreaToScrollForError = "sectionArea"

    validationSchema = Joi.object({
        email: Joi.string().required().label('Email'),
    });

    errorMessages = {}

    constructor(){
        super();

        this.state = {
            ...this.state,
            data:{
                email:"",
            },
            errors:{

            }
        }
    }

    submitLocal = async (e)=>{
        try{
            this.setState({
                errors: {},
                success:{},
            }); 
            const {email} = this.state.data;
            let {status:processingStatus,message} = await UserService.setTemporaryPassword({email});
            if(processingStatus === true){
                message = <p>
                   {`${message} Click`} <Link to="/login">here</Link> {` to login`}
                </p>
                this.setState(
                    {
                        data:{email:""},
                        errors:{},
                        success: {message},
                    }
                ); 
            }
            else{
                const errors = {message};
                this.setState({errors: errors || {}}); 
            }
        }
        catch(ex){
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop(this.divAreaToScrollForError); return;}
            }
            else
                renderError(ex);
        }
    }

    validateLocal= ()=>{
        
    }

    render(){
        const {data,errors,success} = this.state;
        return(
            <>
                <Card className="card-plain bg-white p-2 rounded-lg">
                    <CardBody>
                        <Row>
                            <Col>
                                
                                {/*Error display */}
                                {!_.isEmpty(errors) &&
                                    <FormErrorWidget
                                    errors={errors}
                                    />
                                }
                                {/* End of Error display */}

                                {/*Success info display */}
                                {!_.isEmpty(success) &&
                                    <SuccessMessageWidget
                                    success={success}
                                    />
                                }
                                {/* End of Success info display */}
                                
                                <Form> 
                                    <h6 className="card-category text-left">Forgot Password</h6>
                                    <hr/>
                                    <p className="mb-4">
                                        Please enter your email address registered with our system and we will email you a temporary password. 
                                    </p>
                                    <FormGroup row>
                                        <Label for="email" sm={1}>Email</Label>
                                        <Col sm={4}>
                                            <Input 
                                                type="text" 
                                                name="email" 
                                                id="email" 
                                                placeholder="Email" 
                                                value={this.state.data.email} 
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                    
                                    <FormGroup row className="">
                                        <Label for="loginButton" sm={1}></Label>
                                        
                                        <Col xs={{ size: 6}} sm={{ size: 4}} className="text-left">
                                            <Button 
                                                type="submit"
                                                id="loginButton"
                                                disabled={false}
                                                className="btn-rounded btn-danger"
                                                onClick={this.submitForm}
                                            >
                                            Submit
                                            </Button>
                                        </Col>
                                    </FormGroup>

                                    
                                </Form>     
                            </Col>
                        </Row>  
                    </CardBody>
                </Card>
                
            </>
        );
    }
}

export default ForgotPasswordForm;