import React from "react";
import _ from "lodash"
import Select from "react-select";


import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Media,
    NavItem,
    NavLink,
    Nav,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    TabContent,
    TabPane,
    Container,
    Row,
    Col
  } from "reactstrap";

import LaundryCare from "./FaqContent/LaundryCare";
import LaundryPlan from "./FaqContent/Plan";
import LaundryPricing from "./FaqContent/Pricing";
import LaundryPickupDelivery from "./FaqContent/PickupDelivery";
import DryCleaning from "./FaqContent/DryCleaning";



const FaqMobile = (props)=>{
    const {faqDropdown,isStudent} = props;

    const faqDropDownList = (isStudent === true) ? faqDropdown:_.reject(faqDropdown,{"value": "plans"})
    
    const [faqDisplayArea,setFaqDisplayArea] = React.useState("laundry-care");

    const handleFaqDisplayArea = (dropDownSelection)=>{
        const selectedValue = dropDownSelection.value;
        setFaqDisplayArea(selectedValue);
    }

    const faqDisplayAreaSelectionValue = _.find(faqDropdown,{'value':faqDisplayArea});
    const laundryCareActiveCss = (faqDisplayArea === "laundry-care") ? "d-block": "d-none";
    const plansActiveCss = (faqDisplayArea === "plans") ? "d-block": "d-none";
    const pricingFaqActiveCss = (faqDisplayArea === "pricing-faq") ? "d-block": "d-none";
    const pickupDeliveryActiveCss = (faqDisplayArea === "pickup-delivery") ? "d-block": "d-none";
    const dryCleaningActiveCss = (faqDisplayArea === "dry-cleaning") ? "d-block": "d-none";
    

    return(
        <>
            <Row id="faq" className={`faq`}>
                <Col>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Select
                                    className="react-select react-select-default"
                                    classNamePrefix="react-select"
                                    name="defaultFaq"
                                    value={faqDisplayAreaSelectionValue}
                                    onChange={value => handleFaqDisplayArea(value)}
                                    options={faqDropDownList}
                                    placeholder=""
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    
                    <Row id="laundry-care" className={`laundry-care ${laundryCareActiveCss}`}>
                        <Col> 
                            <LaundryCare {...props}/>
                        </Col>
                    </Row>

                    <Row id="plans" className={`plans ${plansActiveCss}`}>
                        <Col>
                            <LaundryPlan {...props} />
                        </Col>
                    </Row>
                        
                    <Row id="pricing-faq" className={`pricing-faq ${pricingFaqActiveCss}`}>
                        <Col>
                            <LaundryPricing {...props} />
                        </Col>
                    </Row>
                    <Row id="pickup-delivery" className={`pickup-delivery ${pickupDeliveryActiveCss}`}> 
                        <Col>
                            <LaundryPickupDelivery {...props} />
                        </Col>
                    </Row>
                    <Row id="dry-cleaning" className={`dry-cleaning ${dryCleaningActiveCss}`}>
                        <Col>
                           <DryCleaning {...props} />
                        </Col>
                    </Row>
                    
                    
                </Col>
            </Row>
            
        
        </>
    )
}

export default FaqMobile;

