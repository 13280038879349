import React,{Component} from "react";

import _ from "lodash";
import Joi from "@hapi/joi";
import Select from "react-select";
import accounting from "accounting";

import Form from "../../BaseClass/Form"

import FormErrorWidget from "../../Common/FormErrorWidget";
import {scrollTop} from '../../../utils/helperUtils';
import {renderError} from '../../../utils/errorUtils';
import customToast from '../../Common/CustomToast'

// reactstrap components
import {
    Button,
    Label,
    Row,
    Col,
    FormGroup, 
    Modal, 
    Input, 
} from "reactstrap";

class GiftCardRedememptionModal extends Form{
    divAreaToScrollForError = ""

    validationSchema = Joi.object({
        giftCardAmountToBeUsedForRedemption:Joi.number().required().label('Amount to redeem'), 
    }) 
  
    errorMessages = {}

    constructor(){
        super();
        this.state = {
            data:{
                giftCardAmountToBeUsedForRedemption:"",
            },
            errors:{

            }
        }
    }

    componentDidMount(){
        const {divAreaToScrollForError} = this.props;
        this.divAreaToScrollForError = divAreaToScrollForError;
    }
    
    //This gets executed once the parent submission process is executed
    submitLocal = ()=>{
        const {handleSaveOperation} = this.props;
        console.log('submit local');

        const formData = this.state.data;
        return handleSaveOperation(formData)
        
    }

    validateLocal= ()=>{
        //This is anything specific to the form like async validations
        let errors  = "";

        const {giftCardBalance} = this.props;
        const {giftCardAmountToBeUsedForRedemption} = this.state.data;
        if(giftCardAmountToBeUsedForRedemption > parseFloat(giftCardBalance)){
            errors = {
                giftCardAmountToBeUsedForRedemption:`Entered amount should not exceed ${accounting.formatMoney(parseFloat(giftCardBalance))}`,
            }
        }

        return errors;
    }

    render(){
        const {displayRedemptionText,giftCardBalance,modalName,modalDisplayFlag,handleModalDisplay} = this.props;
        const {data,errors} = this.state;
        
        const { giftCardAmountToBeUsedForRedemption } = data;
        
        return(
            <>
            <Modal id={modalName} size="lg" isOpen={modalDisplayFlag} toggle={() => handleModalDisplay(false)}>
                <div className="modal-header">
                    <button
                        className="close"
                        type="button"
                        onClick={() => handleModalDisplay(false)}
                    >
                        <span>×</span>
                    </button>
                    <h5
                        className="modal-title text-center"
                        id="exampleModalLabel"
                    >
                        Amount to Redeem from your Gift card
                    </h5>
                </div>
                <div className="modal-body">
                    {/*Error display */}
                    {!_.isEmpty(errors) &&
                        <FormErrorWidget
                            errors={errors}
                        />
                    }
                    {/* End of Error display */}

                    <Row>
                        <Col>
                            <h6>Currently you have the following amount on your Gift Card: {accounting.formatMoney(parseFloat(giftCardBalance))}</h6>
                            <div className="mt-2">
                                { displayRedemptionText }
                            </div>
                            <Row >
                                <Col xs="4">
                                    Enter amount to redeem:	
                                </Col>  
                                <Col xs="2">   
                                    <Input 
                                        type="text" 
                                        name="giftCardAmountToBeUsedForRedemption" 
                                        id="giftCardAmountToBeUsedForRedemption" 
                                        placeholder="" 
                                        value={giftCardAmountToBeUsedForRedemption} 
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className={`modal-footer`}>
                    <div className="left-side">
                        <Button
                        className="btn-link"
                        color="default"
                        type="button"
                        onClick={() => handleModalDisplay(false)}
                        >
                        Cancel
                        </Button>
                    </div>
                    <div className="divider" />
                    <div className="right-side">
                        <Button className="btn-link" color="danger" type="button" onClick={this.submitForm}>
                            Redeem Amount
                        </Button>
                    </div>
                </div>


            </Modal>
            </>
        );
    }
}

export default GiftCardRedememptionModal;