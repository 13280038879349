import React from "react";

// reactstrap components
import { 
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  UncontrolledDropdown,Dropdown,DropdownItem,DropdownMenu,DropdownToggle } from "reactstrap";

import applicationConstants from "../../utils/applicationConstants";

const { urlFacebook,urlTwitter,urlInstagram} = applicationConstants;




function LandingPageHeader(props) {
  let pageHeader = React.createRef();
  
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        try{
          pageHeader.current.style.transform =
            "translate3d(0," + windowScrollTop + "px,0)";
        }catch(ex){
          console.log(ex.message);
        }
      
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // core components
  const items = [
    {
      src: "url(" + require("assets/mylazybones/img/LandingPage/laundry_carousel.jpg") + ")",
      content: (
        <Container>
          <Row>
            <Col className="text-left" md="6">
              <h1 className="title">Student & Residential Laundry Services</h1>
              <h5>
                Picked up dirty. Delivered fresh and folded. Lazybones has been providing high quality laundry and dry cleaning services to college students across the US since 1993. 
              </h5>
              <br />
              <div className="buttons">
                <UncontrolledDropdown className="d-none d-md-block">
                  <DropdownToggle
                    caret
                    className="btn-round"
                    color="danger"
                    data-toggle="dropdown"
                  >
                    Read More <b className="caret" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-danger" right>
                    <DropdownItem
                      onClick={e =>{ 
                        e.preventDefault()
                        return props.history.push("/laundry/how-it-works/student")
                      }}
                    >
                      Student
                    </DropdownItem>
                    <DropdownItem
                      onClick={e =>{ 
                        e.preventDefault()
                        return props.history.push("/laundry/how-it-works/residential")
                      }}
                    >
                      Residential
                    </DropdownItem>
                    <DropdownItem
                      onClick={e =>{ 
                        e.preventDefault()
                        return props.history.push("/laundry/how-it-works/commercial")
                      }}
                    >
                      Commercial
                    </DropdownItem>
                    
                  </DropdownMenu>
                </UncontrolledDropdown>

                <Button
                  className="btn-round d-block d-md-none"
                  color="danger"
                  onClick={e =>{ 
                    e.preventDefault()
                    return props.history.push("/laundry/how-it-works")
                  }
                  }
                  size="lg"
                >
                  Read More
                </Button>
                        
                <Button
                  className="btn-neutral btn-just-icon"
                  color="link"
                  href={urlTwitter}
                  target="_blank"
                >
                  <i className="fa fa-twitter" />
                </Button>
                <Button
                  className="btn-neutral btn-just-icon"
                  color="link"
                  href={urlFacebook}
                  target="_blank"
                >
                  <i className="fa fa-facebook-square" />
                </Button>
                <Button
                  className="btn-neutral btn-just-icon"
                  color="link"
                  href={urlInstagram}
                  target="_blank"
                >
                  <i className="fa fa-instagram" />
                </Button>
                
              </div>
            </Col>
          </Row>
        </Container>
      ),
      altText: "",
      caption: ""
    },
    {
      src: "url(" + require("assets/mylazybones/img/LandingPage/storage_carousel.jpg") + ")",
      content: (
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Storage & Moving Services</h1>
              <h5>
                College moving made easy! Lazybones provides safe, reliable storage with pick up and delivery right to your door-step. No monthly fees!
              </h5>
              <br />
              <div className="buttons">
                <Button
                  className="btn-round"
                  color="danger"
                  onClick={e =>{ 
                    e.preventDefault()
                    return props.history.push("/storage/how-it-works/student")
                  }
                  }
                  size="lg"
                >
                  Read More
                </Button>
                <Button
                  className="btn-neutral btn-just-icon"
                  color="link"
                  href={urlTwitter}
                  target="_blank"
                >
                  <i className="fa fa-twitter" />
                </Button>
                <Button
                  className="btn-neutral btn-just-icon"
                  color="link"
                  href={urlFacebook}
                  target="_blank"
                >
                  <i className="fa fa-facebook-square" />
                </Button>
                <Button
                  className="btn-neutral btn-just-icon"
                  color="link"
                  href={urlInstagram}
                  target="_blank"
                >
                  <i className="fa fa-instagram" />
                </Button>
                
              </div>
            </Col>
          </Row>
        </Container>
      ),
      altText: "",
      caption: ""
    },
    {
      src: "url(" + require("assets/mylazybones/img/LandingPage/commercial_carousel.jpg") + ")",
      content: (
        <Container>
          <Row>
            <Col className="ml-auto text-right" md="7">
              <h2 className="title">Commercial Laundry Service</h2>
              <h5>
              Lazybones provides efficient and flexible high volume laundry service to all types of businesses; nursing homes, military/private schools, government agencies, hotels, universities and more.
              </h5>
              <br />
              <div className="buttons">
                <Button
                  className="btn-round"
                  color="success"
                  onClick={e =>{ 
                    e.preventDefault()
                    return props.history.push("/laundry/how-it-works/commercial")
                  }
                  }
                  size="lg"
                >
                  Read More
                </Button>
                <Button
                  className="btn-neutral btn-just-icon"
                  color="link"
                  href={urlTwitter}
                  target="_blank"
                >
                  <i className="fa fa-twitter" />
                </Button>
                <Button
                  className="btn-neutral btn-just-icon"
                  color="link"
                  href={urlFacebook}
                  target="_blank"
                >
                  <i className="fa fa-facebook-square" />
                </Button>
                <Button
                  className="btn-neutral btn-just-icon"
                  color="link"
                  href={urlInstagram}
                  target="_blank"
                >
                  <i className="fa fa-instagram" />
                </Button>
                
              </div>
              
            </Col>
          </Row>
        </Container>
      ),
      altText: "",
      caption: ""
    }
  ];


  
  return (
    <>
      <div className="section section-header cd-section p-0" id="headers" ref={pageHeader}>
        <div className="page-carousel" >
            <div className="filter" />
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
              <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {items.map(item => {
                return (
                  <CarouselItem
                    onExiting={onExiting}
                    onExited={onExited}
                    key={item.src}
                  >
                    <div
                      className="page-header"
                      style={{ backgroundImage: item.src }}
                    >
                      <div className="filter" />
                      <div className="content-center">{item.content}</div>
                    </div>
                  </CarouselItem>
                );
              })}
              <a
                className="left carousel-control carousel-control-prev"
                data-slide="prev"
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  previous();
                }}
                role="button"
              >
                <span className="fa fa-angle-left" />
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="right carousel-control carousel-control-next"
                data-slide="next"
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  next();
                }}
                role="button"
              >
                <span className="fa fa-angle-right" />
                <span className="sr-only">Next</span>
              </a>
            </Carousel>
          </div>
        </div>
    </>
  );
}

export default LandingPageHeader;
