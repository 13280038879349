import React,{Component} from "react";
import PropTypes from 'prop-types';
import Select from "react-select";
import Joi from "@hapi/joi"
import _ from "lodash";
import moment from "moment";
import ReactDatetime from "react-datetime";
import accounting from "accounting";


import auth from "../../../../../../services/authService";
import commercialService from "../../../../../../services/commercialService";
import userService from "../../../../../../services/userService";


import BaseForm from "../../../../../BaseClass/Form"
import BaseTable from "../../../../../BaseClass/Table"

import ProcessingModal from "../../../../../Common/ProcessingModal";
import {renderError}   from '../../../../../../utils/errorUtils';
import FormErrorWidget from "../../../../../Common/FormErrorWidget";
import {scrollTop}     from '../../../../../../utils/helperUtils';
import customToast     from '../../../../../Common/CustomToast';



import {
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,  
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Table,

} from "reactstrap";

class AccountActivity extends BaseForm{
    divAreaToScrollForError = "sectionArea"

    validationSchema = Joi.object({
        startDate: Joi.date().less(Joi.ref('endDate')).required().label('Start Date'),
        endDate: Joi.date().required().label('End Date'),
        userId:Joi.any().optional(),
        universityId: Joi.any().optional(),
        campusId: Joi.any().optional(),
        resultSet: Joi.any().optional(),
        selectedProduct: Joi.object().required().label('Select a Product'),
    });

    errorMessages = {
        "startDate":{
            "date.less":"Start date needs to be less than the End date",
        },
    }

    
    constructor(){
        super();
        this.state = {
            processingModalDisplayFlag:false,
            productListDropDown:[
                { value: "", label: "", isDisabled: true },
                { value: "Laundry", label: "Laundry", },
                { value: "Storage", label: "Storage", },
                { value: "Supplies", label: "Supplies", },
                { value: "ALL_PRODUCTS", label: "All Products", },
            ],
            
            data:{
                userId:"",
                universityId:"",
                campusId:"",
                customerName:"",
                startDate:moment().subtract(1,'month').format(`MM/DD/YYYY`),
                endDate:moment().format(`MM/DD/YYYY`),
                resultSet:"",
                accountCurrentBalance:0,
                selectedProduct:{ value: "ALL_PRODUCTS", label: "Show All" }
            },
            errors:{},
        }
    }

    async componentDidMount(){
        const user = auth.getCurrentUser();
        if(!_.isEmpty(user)){
            const { universityId,campusId,userId,fullName:customerName } = user;
            this.setState({
                data:{
                    ...this.state.data, 
                    userId,
                    universityId,
                    campusId,
                    customerName,
                }
            })

            const {status:processingStatus,message,currentBalance:accountCurrentBalance} = await userService.getAccountCurrentBalance({});
            this.setState({
                data:{
                    ...this.state.data, 
                    accountCurrentBalance,
                }
            })
        }        
    }

    handleBackButton = (e)=>{
        e.preventDefault();
        const { onClickOfBackButton } = this.props;
        return onClickOfBackButton();
        //this.props.history.goBack();
    }

    handleCalendarDate = (dateMoment,inputName)=>{
        const data = {...this.state.data}
        data[inputName] = moment(dateMoment).format(`MM/DD/YYYY`);
        this.setState({data});
    }

    setProcessingModalDisplayFlag = (flag)=>{
        this.setState({
            processingModalDisplayFlag:flag
        });
    }
    
    submitLocal = async ()=>{
        try{
            //Build payload
            const formData = {
                ...this.state.data,
                selectedProduct:this.state.data.selectedProduct.value,
            };
            
            this.setState({
                data:{
                    ...this.state.data,
                    resultSet:"",
                }
            })

            const {
                
                startDate,
                endDate,
            } = formData;

            this.setProcessingModalDisplayFlag(true);

            const tableColumnDefinition = [
                { 
                    key:"1",
                    name:"Date",
                    classDefinition:"text-left",
                    styleDefinition:{width:"15%"},
                    getFieldData: data=>`${data.create_date}`,
                    defaultDataValue:"",
                    formattedFieldGetter:(fieldData,defaultDataValue)=>{
                        return <strong>{moment(fieldData).format("MM/DD/YYYY")}</strong>;
                    }
                },
                { 
                    key:"2",
                    name:"Description",
                    classDefinition:"text-left",
                    styleDefinition:null,
                    getFieldData:data=>`${data.description}`,
                    defaultDataValue:"",
                    formattedFieldGetter:(fieldData,defaultDataValue)=>{
                        return <strong>{(fieldData) ? fieldData:defaultDataValue}</strong>;
                    }
                },
                { 
                    key:"3",
                    name:"Amount",
                    classDefinition:"text-right",
                    styleDefinition:{width:"15%"},
                    getFieldData: data=>data.money,
                    defaultDataValue:"",
                    formattedFieldGetter:(fieldData,defaultDataValue)=>{
                        return <strong>{accounting.formatMoney(fieldData)}</strong>;
                    }
                },    
            ]
            
            const {status:processingStatus,message,accountHistoryData,accountCurrentBalance} = await userService.getAccountHistoryReportData(formData);
            if(processingStatus === true && accountHistoryData.length){
                //customToast.success(message);
                
                const resultSet = {
                    tableHeader:`Account History (${startDate} - ${endDate})`,
                    tableColumn:tableColumnDefinition,
                    tableData:accountHistoryData,
                    tableFooterDisplayFlag:true,
                }

                //console.log('salesTotal',accounting.toFixed(salesTotal,2));

                resultSet.tableFooter = <tr>
                                            <td/>
                                            <td className="td-total">Current Balance</td>
                                            <td className="td-price">
                                                {accounting.formatMoney(accountCurrentBalance)}
                                            </td>
                                        </tr>

                //console.log(resultSet);

                this.setState({
                    data:{
                        ...this.state.data,
                        accountCurrentBalance,
                        resultSet,
                    }
                })

            }
            else if(processingStatus === false){
                const resultSet = {
                    tableHeader:`Account History (${startDate} - ${endDate})`,
                    tableColumn:tableColumnDefinition,
                    tableData:[],
                    tableFooterDisplayFlag:false,
                }

                resultSet.tableFooter = <tr>
                                            <td/>
                                            <td className="td-total">Current Balance</td>
                                            <td className="td-price">
                                                {accounting.formatMoney(accountCurrentBalance)}
                                            </td>
                                        </tr>

                this.setState({
                    data:{
                        ...this.state.data,
                        accountCurrentBalance,
                        resultSet,
                    }
                })

                //customToast.error(message); 
            }

            this.setProcessingModalDisplayFlag(false);
            
        } 
        catch(ex){
            /*
              400 signals validation errors from the server
              renderError() basically will display server side error other than code 400
            */
            this.setProcessingModalDisplayFlag(false);
            
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop('sectionArea'); return;}
            }
            else
                renderError(ex);
        } 
    }

    validateLocal= ()=>{

    }

    render(){
        const {
            displayProductDropDown,
        } = this.props;

        const {data,errors,processingModalDisplayFlag,productListDropDown} = this.state;
        const { 
            customerName,
            startDate,
            endDate,
            resultSet,
            accountCurrentBalance,
            selectedProduct,
        } = data;
        
        const displayFlag = false;
        return(
            <>
                <div className="border-bottom mb-4">
                    <div className="d-flex justify-content-between bd-highlight mb-3">
                        <div className="pl-0 py-2 bd-highlight mt-2">
                            <h6 className="card-category">
                                <span className="text-left">
                                Account Activity: {`${customerName}`}
                                </span>
                            </h6>
                        </div>
                        <div className="pl-0 py-2 bd-highlight mt-2">
                            <h6 className="card-category">
                                <span className="text-left text-danger">
                                Current Balance: {`${accounting.formatMoney(accountCurrentBalance)}`}
                                </span>
                            </h6>
                        </div>
                    </div>
                </div>
                
                <div>
                    {/*Error display */}
                    {   !_.isEmpty(errors) &&
                        <FormErrorWidget
                            errors={errors}
                        />
                    }
                    {/* End of Error display */}
                </div>

                {/* Search widget */}
                <div>
                    
                <Form>
                        {
                            displayProductDropDown && 
                            <Row>
                                <Col xs="6" md="4" sm="4">
                                    <FormGroup>
                                        <label>Filter By Product:</label>
                                        <Select
                                            className="react-select react-select-default"
                                            classNamePrefix="react-select"
                                            name="selectedProduct"
                                            id="selectedProduct"
                                            value={selectedProduct}
                                            onChange={value => this.handleDropdownChange(value,'selectedProduct')}
                                            options={productListDropDown}
                                            placeholder="Filter Product By"
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col xs="6" md="4" sm="4">
                                <FormGroup>
                                    <label>Start Date</label>
                                    <InputGroup 
                                        name="startDate"
                                        className="date" 
                                        id="startDate"
                                        
                                    >
                                        <ReactDatetime
                                            value={moment(startDate)} 
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            name="startDate"
                                            onChange={(dateMoment)=>this.handleCalendarDate(dateMoment,"startDate")}
                                            inputProps={{
                                                className: "form-control",
                                                placeholder: "Start Date",
                                            }}
                                            
                                            isValidDate={current=>current.isSameOrBefore(moment())}
                                        />
                                        <InputGroupAddon addonType="append">
                                            <InputGroupText>
                                                <span className="glyphicon glyphicon-calendar">
                                                <i className="fa fa-calendar" />
                                                </span>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col xs="6" md="4" sm="4">
                                <FormGroup>
                                    <label>End Date</label>
                                    <InputGroup 
                                        name="endDate"
                                        className="date" 
                                        id="endDate"
                                        
                                    >
                                        <ReactDatetime
                                            value={moment(endDate)} 
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            name="endDate"
                                            onChange={(dateMoment)=>this.handleCalendarDate(dateMoment,"endDate")}
                                            inputProps={{
                                                className: "form-control",
                                                placeholder: "End Date",
                                            }}
                                            isValidDate={current=>current.isSameOrBefore(moment())}
                                        />
                                        <InputGroupAddon addonType="append">
                                            <InputGroupText>
                                                <span className="glyphicon glyphicon-calendar">
                                                <i className="fa fa-calendar" />
                                                </span>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col xs="6" md="2" sm="2">
                                <Button
                                    className="btn-just-icon mt-lg d-block d-sm-block"
                                    color="default"
                                    onClick={this.submitForm}
                                >
                                    <i className="fa fa-search" />
                                </Button>
                                

                            </Col>
                        </Row>

                </Form>
                </div>
                {/* End of Search widget */}

                {/* Result table view */}

                <div>
                    {   resultSet && 
                        <BaseTable
                            tableProperties={resultSet}
                        />
                    }
                    
                </div>
                {/* End of Result table view */}

                <FormGroup row  className="mt-5 justify-content-between">
                    <Col xs={{ size: 2}}>
                        <Button 
                            className="btn-rounded btn-danger"
                            onClick={this.handleBackButton}
                        >
                            Back
                        </Button>
                    </Col>
                    
                </FormGroup>
                
                {/* Set Processing modal */}
                <ProcessingModal
                    displayModal={processingModalDisplayFlag}
                    handleCloseOfModalWindow={this.setProcessingModalDisplayFlag}
                />
                {/* End of Processing modal */}

            </>
        )
    }
}

AccountActivity.propTypes = {
    //userId: PropTypes.number.isRequired,
    displayProductDropDown:PropTypes.bool,
    onClickOfBackButton:PropTypes.func,
}

AccountActivity.defaultProps = {
    displayProductDropDown:false,
}
export default AccountActivity