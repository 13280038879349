import React,{Component} from "react";
import _ from "lodash";
import Select from "react-select";
import moment from "moment";
import accounting from "accounting";

import auth from "../../../../services/authService";
import StudentService from "../../../../services/studentService";

import FormErrorWidget from "../../../Common/FormErrorWidget";
import {scrollTop} from '../../../../utils/helperUtils';
import {renderError} from '../../../../utils/errorUtils';
import customToast from '../../../Common/CustomToast'

import PriceEstimatorModel from "./PriceEstimatorModal";

import BaseFrom from "../../../BaseClass/Form";

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Media,
    Container,
    Row,
    Col,
    Table,
} from "reactstrap";

/*
$items = array(
	array('Box','Box','price1','Small Box','size_increment1'),
	array('Box','Box','price2','Medium Box','size_increment2'),
	array('Box','Box','price3','Large Box','size_increment3'),
	array('Box','Box','price4','Extra Large Box','size_increment4'),
	array('Plastic Storage','Plastic Bin/Dresser/Rack','price2','Medium Plastic Bin','size_increment2'),
	array('Furniture','Desk/Dresser','price1','Desk or Dresser','size_increment1'),
	array('Furniture','Mattress or Boxspring','price3','Queen Mattress or Boxspring', 'size_increment3'),
	array('Furniture','Couch/Futon/Recliner','price2','Couch/Futon/Recliner','size_increment2'),
);
*/
const priceListDisplaySettings = [
    {
        itemType:'Box',
        itemCategory:'Box',
        priceField:'price1',
        dimensionField:'size_increment1',
        displayLabel:(s,field)=>`Small Box (up to ${s[field]} cubic inches)`,
        priceLabel:(s,field)=>accounting.formatMoney(s[field]),
    },
    {
        itemType:'Box',
        itemCategory:'Box',
        priceField:'price2',
        dimensionField:'size_increment2',
        displayLabel:(s,field)=>`Medium Box (up to ${s[field]} cubic inches)`,
        priceLabel:(s,field)=>accounting.formatMoney(s[field]),
    },
    {
        itemType:'Box',
        itemCategory:'Box',
        priceField:'price3',
        dimensionField:'size_increment3',
        displayLabel:(s,field)=>`Large Box (up to ${s[field]} cubic inches)`,
        priceLabel:(s,field)=>accounting.formatMoney(s[field]),
    },
    {
        itemType:'Box',
        itemCategory:'Box',
        priceField:'price4',
        dimensionField:'size_increment4',
        displayLabel:(s,field)=>`Extra Large Box (up to ${s[field]} cubic inches)`,
        priceLabel:(s,field)=>accounting.formatMoney(s[field]),
    },
    {
        itemType:'Plastic Bin/Dresser/Rack',
        itemCategory:'Plastic Storage',
        priceField:'price2',
        dimensionField:'size_increment2',
        displayLabel:(s,field)=>`Medium Plastic Bin (up to ${s[field]} cubic inches)`,
        priceLabel:(s,field)=>accounting.formatMoney(s[field]),
    },
    {
        itemType:'Desk/Dresser',
        itemCategory:'Furniture',
        priceField:'price1',
        dimensionField:'size_increment1',
        displayLabel:(s,field)=>`Desk or Dresser (up to ${s[field]} cubic inches)`,
        priceLabel:(s,field)=>accounting.formatMoney(s[field]),
    },
    {
        itemType:'Mattress or Boxspring',
        itemCategory:'Furniture',
        priceField:'price3',
        dimensionField:'size_increment3',
        displayLabel:(s,field)=>`Queen Mattress or Boxspring)`,
        priceLabel:(s,field)=>accounting.formatMoney(s[field]),
    },
    {
        itemType:'Couch/Futon/Recliner',
        itemCategory:'Furniture',
        priceField:'price2',
        dimensionField:'size_increment2',
        displayLabel:(s,field)=>`Couch/Futon/Recliner (up to ${s[field]} cubic inches)`,
        priceLabel:(s,field)=>accounting.formatMoney(s[field]),
    },
    

]

class StudentPricing extends BaseFrom {

    constructor(){
        super();
        this.state = {
            schoolList:"",
            schoolDropDownList:[],
            priceEstimateModalDisplayFlag:false,
            data:{
                selectedSchoolDropdownObject:"",
                universityId:"",
                semester:"",
                itemCategories:"",
                propertiesByCategory:"",
                itemPriceList:"",
            },
            errors:{},
        }
    }

    componentDidMount(){
        this.mapModelToView();
    }

    componentDidUpdate(prevProps){
        const { listOfSchoolsByRegionForStorage } = this.props;
        const { listOfSchoolsByRegionForStorage:listOfSchoolsByRegionForStoragePrevProps } = prevProps;
        if(listOfSchoolsByRegionForStoragePrevProps === "" && !_.isEmpty(listOfSchoolsByRegionForStorage)){
            this.mapModelToView();
        }
    }

    displaySemesterList = ()=>{
        const { data,schoolList } = this.state;
        const selectedSchoolObject  = data.selectedSchoolDropdownObject;
        
        if(_.isEmpty(selectedSchoolObject))
           return "";

        const schoolId = selectedSchoolObject.value;

        //Get the schoolObject
        const school  = _.find(schoolList,{"id":schoolId})
        const semesterList =  (!_.isEmpty(school)) ? school.semesters:[];
        
        const {semester:selectedSemester} = this.state.data;
      
        
        let renderSemestersAvailable = true;
        renderSemestersAvailable = (semesterList.length > 0) ? true:false;
        
        //Check if the whole list of Semesters has already expired
        if(renderSemestersAvailable){
            const listOfActiveSemesters = _.filter(semesterList, (s) =>moment().isSameOrBefore(s.endDateDb));
            renderSemestersAvailable = (listOfActiveSemesters.length > 0) ? true:false
        }
        
        //Render no semester available message
        if(!renderSemestersAvailable){
            return(
                <Row>
                    <Col xs="12">
                        <div className="mlb-alert mlb-alert-primary" role="alert">
                            <Row>
                                <Col xs="1" sm="1" className="fs-42 text-danger">
                                        <i className="fa fa-exclamation-triangle fa-6" aria-hidden="true"></i>    
                                </Col>
                                <Col>
                                   <strong>We currently don't have any upcoming semester plans available for sign-up. Please try again later.</strong>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            );
        }
        
        
        if(renderSemestersAvailable){
            return semesterList.map(s=>{
                return(
                  <FormGroup key={s.id} row className="text-center">
                      <Col sm="2"></Col> 
                      <Col sm={{ size: 8}}> 
                          <Button
                              className="btn-round mr-1"
                              color="danger"
                              outline = {selectedSemester.id !== s.id}
                              block
                              type="button"
                              onClick={()=>this.handleSelectSemester(s)}
                          >
                              {s.semester} ({s.startDate} - {s.endDate})
                          </Button>
                      </Col>
                      <Col sm="2"></Col>
                  </FormGroup>   

                );
            })  
        }
    }

    handleSelectSchool = (value,dataAttribute)=>{
        this.handleDropdownChange(value,dataAttribute);
        const {value:schoolId} = value;

        const {schoolList} = this.state;
        const {universityId} = _.find(schoolList,{"id":schoolId})

        this.setState({
            data:{
                ...this.state.data,
                [dataAttribute]:value,
                semester:"",
                universityId,
            }
        })
        
    }

    /*
        Launch processing dialog and fetch pricelist from the server
    */
    handleSelectSemester = async (s)=>{
        //const semester = _.pick(s,['id','semester']);
        try{
            const semester = s;
            const {season}  = semester;
            const {universityId} = this.state.data;
            const formData = {  season,universityId,};
            const {status:processingStatus,message,itemPriceListObject} = await StudentService.getStorageItemPriceList(formData);
            if(processingStatus === true){
                const {itemCategories,propertiesByCategory,itemPriceList} = itemPriceListObject
                this.setState({
                    data:{
                        ...this.state.data,
                        semester,
                        itemCategories,
                        propertiesByCategory,
                        itemPriceList,
                    }
                    
                })
            }
        }
        catch(ex){
            /*
              400 signals validation errors from the server
              renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop('storageInformationArea'); return;}
            }
            else
                renderError(ex);
        }

    }

    
    mapModelToView = ()=>{
        const { listOfSchoolsByRegionForStorage } = this.props;
        //console.log(listOfSchoolsByRegionForStorage);
        if(!_.isEmpty(listOfSchoolsByRegionForStorage)){
            const schoolSet= _.map(listOfSchoolsByRegionForStorage,'schools');
            
            const schoolList = _.flattenDeep(schoolSet);
            const schoolDropDownList = schoolList.map(s=>{
                return {value:s.id,label:s.name}
            })
            
            this.setState({
                schoolDropDownList,
                schoolList,
            })

        }
    
    }

    setPriceEstimateModalDisplayFlag = (flag)=>{
        this.setState({
            priceEstimateModalDisplayFlag:flag    
        })
    }

    render(){
        const { data, schoolDropDownList,priceEstimateModalDisplayFlag } = this.state;
        const {selectedSchoolDropdownObject} = data;
        const {
            semester:selectedSemester,
            propertiesByCategory,
            itemCategories,
            itemPriceList,
        } = data;

        let mapCtr = 1;
        let signUpUrl = `/signup/storage/student/select-school`;

        
        return(
            <>
                <div className="mb-4">
                    <FormGroup>
                        <h6 className="card-category text-left">Select School</h6>
                        <Select
                            className="react-select react-select-default"
                            classNamePrefix="react-select"
                            name="defaultSelect"
                            value={selectedSchoolDropdownObject}
                            onChange={value => this.handleSelectSchool(value,"selectedSchoolDropdownObject")}
                            options={schoolDropDownList}
                            placeholder=""
                        />
                    </FormGroup>
                </div>

                {/* Display semesters */}
                {   
                    !_.isEmpty(selectedSchoolDropdownObject) && 
                    <div className="my-4">
                        <h6 className="card-category text-left">Select Semester</h6>
                        <hr/>
                        { this.displaySemesterList() }
                    </div>
                }

                {/*Display sample price list */}
                {
                    selectedSemester && 
                    typeof propertiesByCategory === "object" && 
                    propertiesByCategory.length >= 0 &&
                    <>
                        {
                            propertiesByCategory.length === 0 &&
                            <div>
                                <h6 className="card-category text-left">Price List</h6>
                                <hr/>
                                <p className="mt-2">
                                    Price list not available. Please email or call us for pricing information.
                                </p>
                            </div>

                        }

                        {
                            propertiesByCategory.length > 0 &&
                            <>
                            <div>
                                <h6 className="card-category text-left">Price List</h6>
                                <hr/>
                                <p className="mt-2">
                                    The prices listed below are just a sampling - we store <strong>anything!</strong> If you don't see it on this list, please refer to the estimator tool at the bottom of the page. Prices include pick up, delivery and storage for the entire storage term you select.
                                </p>
                                <Table responsive striped>
                                    <thead>
                                        <tr>
                                            <td className="text-left">Item Description (cubic inches = length x width x height)</td>
                                            <td className="text-right">Price</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                        priceListDisplaySettings.map(displayItemSettingObject=>{
                                                
                                                const {
                                                    itemType,
                                                    itemCategory,
                                                    priceField,
                                                    dimensionField,
                                                    displayLabel,
                                                    priceLabel,
                                                } = displayItemSettingObject;

                                                const dataObject = _.find(
                                                    _.find(propertiesByCategory,{'itemCategory':itemCategory}).listOfItems,
                                                    {'item_type':itemType}
                                                );
                                                return(
                                                    <tr key={mapCtr++}>
                                                        <td>{displayLabel(dataObject,dimensionField)}</td>
                                                        <td className="text-right">{priceLabel(dataObject,priceField)}</td>
                                                    </tr>
                                                )

                                        }) 
                                        }
                                    </tbody>
                                </Table>
                            </div>

                            <div className="d-flex justify-content-between ">
                                <div>
                                    <Button
                                        className="btn-round mr-1"
                                        color="default"
                                        type="button"
                                        onClick={()=>this.setPriceEstimateModalDisplayFlag(true)}
                                    >
                                        Additional Item Estimator
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        className="btn-round mr-1"
                                        color="danger"
                                        type="button"
                                        onClick={()=>this.props.history.push(signUpUrl)}
                                    >
                                        Sign-up
                                    </Button>
                                </div>
                                
                            </div>

                            <PriceEstimatorModel
                                modalDisplayFlag={priceEstimateModalDisplayFlag}
                                handleModalDisplay={this.setPriceEstimateModalDisplayFlag}
                                itemCategories={itemCategories}
                                propertiesByCategory={propertiesByCategory}
                                itemPriceList={itemPriceList}
                            />
                            </>
                        }
                        
                    </>
                }
                

                

                



                      
            </>
        )
    }   
}

export default StudentPricing;

