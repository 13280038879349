import React,{Component} from "react";
import {Link} from "react-router-dom";
import _ from "lodash";
import Joi from "@hapi/joi";


import FormErrorWidget from "components/Common/FormErrorWidget";

import auth from "services/authService";


import customToast from 'components/Common/CustomToast';
import {renderError} from 'utils/errorUtils';


import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Badge,
    Button,
    ButtonGroup,
    Form,
    FormGroup,
    Input,
    Label,
    Media
} from "reactstrap";

class LoginForm extends Component{

    validationSchema = Joi.object({
        username: Joi.string().required().label('Username'),
        password:Joi.string().required().label('Password'),
    });

    errorMessages = {}

    constructor(){
        super();

        this.state = {
            data:{
                username:"",
                password:""
            },
            errors:{

            }
        }
    }

    handleChange = ({currentTarget:input})=>{
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[input.name] = input.value;
        this.setState({data,errors});
        
    }

    handleSubmit = async (e)=>{
        try{
            e.preventDefault();
            const errors = this.validate();
            if(errors){
                this.setState({errors: errors || {}});
                return;
            }
        
            
            const {username,password} = this.state.data;
            await auth.login(username,password);
        
            //This handles redirect from protected routes
            const {state} = this.props.location;
            //console.log(state);
            window.location = (state) ? state.from.pathname:"/myaccount"; 
            //Call for a full reload so the App will get the token from LocalStorage at ComponentDidMound()
            return;
        }
        catch(ex){
            /*
              400 signals validation errors from the server
              renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors) return;
            }
            else
                renderError(ex);
        }
    }

    validate= ()=>{
        try{
            const options = {abortEarly:false,allowUnknown:true};
            let data  = {...this.state.data};
            let {error:validationErrorList} = this.validationSchema.validate(data,options);
            
            const validationErrors = {};
            if(validationErrorList){
                validationErrorList.details.map(
                    function(errorObject){
                        //console.log(errorObject);
                        if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                            const key = errorObject.context.key;
                            const joiType = errorObject.type;
                            //console.log(this.errorMessages);
                            if(_.has(this.errorMessages, [key, joiType]))
                                return validationErrors[errorObject.path[0]] = this.errorMessages[key][joiType];
                            else
                                return validationErrors[errorObject.path[0]] = errorObject.message;
                        }
                        else if(_.has(errorObject, ['context', 'peers']) &&  _.has(errorObject, ['type'])){
                            const joiType = errorObject.type;
                            if(joiType === "object.missing")
                                return validationErrors[errorObject.path[0]] = this.errorMessages.minimumFieldsMessage.message;
                        }
                        else
                          return validationErrors[errorObject.path[0]] = errorObject.message;
                    }.bind(this)
                );
            }

            const errors = {...validationErrors} 
        
            //console.log(errors);
            return Object.keys(errors).length > 0 ? errors:null;
        }catch(ex){
            console.log(ex);
        }
    }

    render(){
        const {data,errors} = this.state;
        const {signupProductPath} = this.props.globalApp.shoppingCart;
        return(
            <>
                <Card className="card-plain bg-white p-2 rounded-lg">
                    <CardBody>
                        <Row>
                            <Col sm="7">
                                {/*Error display */}
                                {!_.isEmpty(errors) &&
                                    <FormErrorWidget
                                    errors={errors}
                                    />
                                }
                                {/* End of Error display */}
                                
                                <Form> 
                                    <h6 className="card-category text-left">Login Information</h6>
                                    <hr/>
                                    <FormGroup row>
                                        <Label for="username" sm={3}>Username</Label>
                                        <Col sm={6}>
                                            <Input 
                                                type="text" 
                                                name="username" 
                                                id="username" 
                                                placeholder="Username" 
                                                value={this.state.data.username} 
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="userPassword" sm={3}>Password</Label>
                                        <Col sm={6}>
                                            <Input 
                                                type="password" 
                                                name="password" 
                                                id="userPassword" 
                                                placeholder="Password" 
                                                value={this.state.data.password} 
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </FormGroup> 
                                    <FormGroup row className="">
                                        <Label for="loginButton" sm={3}></Label>
                                        
                                        <Col xs={{ size: 6}} sm={{ size: 4}} className="text-left">
                                            <Button 
                                                type="submit"
                                                id="loginButton"
                                                disabled={false}
                                                className="btn-rounded btn-danger"
                                                onClick={this.handleSubmit}
                                            >
                                            Login
                                            </Button>
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row className="">
                                        <Col sm={{ size: 3 }}>
                                            
                                        </Col>
                                        <Col xs={{ size: 9}} sm={{ size: 6}} className="text-left">
                                            <Button
                                                className="px-0"
                                                color="link"
                                                href="/forgotPassword"
                                                onClick={e =>{
                                                    e.preventDefault()
                                                    this.props.history.push('/forgotPassword');
                                                }}
                                            >
                                                Forgot Password?
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                </Form>     
                            </Col>
                            {
                                signupProductPath !== "" && 
                                <Col sm="5" className="bl-solid">
                                    <h6 className="card-category text-left">New Account?</h6>
                                    <hr/>
                                    
                                    <Row>
                                        <Col className="">
                                            <Button 
                                                disabled={false}
                                                className="btn-rounded btn-danger mx-auto"
                                                href={signupProductPath}
                                                onClick={e =>{
                                                    e.preventDefault()
                                                    this.props.history.push(signupProductPath);
                                                }}
                                            >
                                                Create a New Account
                                            </Button>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                            }
                            
                        </Row>  
                    </CardBody>
                </Card>
                
            </>
        );
    }
}

export default LoginForm;