import React,{Component} from "react";
import _ from "lodash";

import FormErrorWidget from "../../../../Common/FormErrorWidget";
import {scrollTop} from '../../../../../utils/helperUtils';
import applicationConstants from '../../../../../utils/applicationConstants';


// reactstrap components
import {
    Button,
    Row,
    Col,
    Modal, 
    Input, 
    
  } from "reactstrap";

class StudentAccountLaundryModifyDeliveryWaiverAndDriverInstructions extends Component{
    

    constructor(){
        super();
        this.state = {
            isUserLivingInDormitory:false,
            data:{
                deliveryPreference:"",
                deliveryInstructions:"",
            },
            errors:{},
            
            
        }
    }

    componentDidUpdate(prevProps,prevState){
        this.mapModelToView(prevProps,prevState);
    }

    closeWindow = ()=>{
        
        const { 
            handleModalDisplayFlag,
            user 
        } = this.props
        
        const {laundry_waiver:laundryWaiver,delivery_instructions:deliveryInstructions} = user;
        let deliveryPreference = ""
        if(laundryWaiver === 1)
            deliveryPreference = true;
        else if(laundryWaiver === 0)
            deliveryPreference = false;
            
        const data = {
            deliveryPreference,
            deliveryInstructions
        };
        this.setState({data})
        
        handleModalDisplayFlag(false);
    }

    handleChange = ({currentTarget:input})=>{
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[input.name] = input.value;
        this.setState({data,errors});
        
    }

    mapModelToView = (prevProps)=>{
        
        /*
            If props have changed then compare it with previous props and then only update it
        */
       if((this.props.user &&  !prevProps.user)
            ||
            (this.props.user && 
            prevProps.user && 
            !_.isEqual(this.props.user,prevProps.user)
            )
        ){
            const {
                laundry_waiver:laundryWaiver,
                delivery_instructions:deliveryInstructions,
                dorm_non_dorm:isDormitoryOrNonDormitory
            } = this.props.user;

            const isUserLivingInDormitory = (isDormitoryOrNonDormitory === applicationConstants.DORMITORY) ? true:false

            let deliveryPreference = ""
            if(laundryWaiver === 1)
                deliveryPreference = true;
            else if(laundryWaiver === 0)
                deliveryPreference = false;
            
            const data = {
                deliveryPreference,
                deliveryInstructions
            };
            this.setState({isUserLivingInDormitory,data})
        }
    }
    
    /*  Send state data to the parent laundry detail */
    saveForm = ()=>{
        const errors = this.validateLocal();
        if(!_.isEmpty(errors)){
            this.setState({errors});
            scrollTop("studentLaundryDeliveryPreferenceAndDrivereInstructionModal");
            return
        }

        const data = {...this.state.data}
        this.props.handleSaveOperation(data);
        return;
    }

    setDeliveryPreference = (e,waiverFlag)=>{
        e.preventDefault();
        const data = {...this.state.data};
        data.deliveryPreference = waiverFlag;
        this.setState({data});
    }

    validateLocal = ()=>{
        const {data} = this.state;
        let errors  = {};

        const {deliveryPreference,deliveryInstructions} = data;

        /* Plan based validation */
        if(deliveryPreference === ""){
            errors.deliveryPreference = "Please select delivery preference."
        }
        if(deliveryInstructions === ""){
            //errors.deliveryInstructions = "Please select delivery instructions for the driver."
        }

        return errors;
    }

    render(){
        const {data,errors,isUserLivingInDormitory,} = this.state;
        const { 
            deliveryPreference,
            deliveryInstructions,
        } = data
        
        
        const { 
            handleModalDisplayFlag,
            modalDisplayFlag,
            laundryOrder,
            user 
        } = this.props


        return(
                <Modal id="studentLaundryDeliveryPreferenceAndDrivereInstructionModal" size="lg" isOpen={modalDisplayFlag} toggle={() => this.closeWindow()}>
                    <div className="modal-header">
                    <button
                        className="close"
                        type="button"
                        onClick={() => this.closeWindow()}
                    >
                        <span>×</span>
                    </button>
                    <h5
                        className="modal-title text-center"
                        id="exampleModalLabel"
                    >
                        Update Delivery Waiver and Driver Instructions
                    </h5>
                    </div>
                    <div className="modal-body">
                        {/*Error display */}
                        {!_.isEmpty(errors) &&
                                <FormErrorWidget
                                    errors={errors}
                                />
                            }
                        {/* End of Error display */}
                        
                        {/* Delivery Instructions: Hide it for students living in dormitory*/}
                        {
                            isUserLivingInDormitory === false && 
                            <Row>
                                <Col>
                                    <h6>Delivery Preference:</h6>
                                    <Row>
                                        <Col xs="7" lg="10">
                                            I give my permission to leave my laundry and accept responsibility for the package once it is delivered.	
                                        </Col>  
                                        <Col xs="4" sm="4" md="4" lg="2">
                                            <Button
                                                className="btn-round mr-1"
                                                color="danger"
                                                outline = {(deliveryPreference === true) ? false:true}
                                                block
                                                type="button"
                                                onClick={(e)=>{this.setDeliveryPreference(e,true)}}
                                                
                                            >
                                                {(deliveryPreference === true) ? "Selected":"Select"}
                                            </Button>
                                        </Col>  
                                    </Row>
                                    <Row className="mt-2">
                                        <Col xs="7" lg="10">
                                            I would prefer being present with my laundry, do not leave my laundry unattended.	
                                        </Col>  
                                        <Col xs="4" sm="4" md="4" lg="2">
                                            <Button
                                                className="btn-round mr-1"
                                                color="danger"
                                                outline = {(deliveryPreference === false) ? false:true}
                                                block
                                                type="button"
                                                onClick={(e)=>{this.setDeliveryPreference(e,false)}}
                                            >
                                                {(deliveryPreference === false) ? "Selected":"Select"}
                                            </Button>
                                        </Col>  
                                    </Row>
                                    <hr/>
                                </Col>
                            </Row>
                        }
                        {/* End of Delivery Instructions */}

                        {/* Driver Instructions*/}
                        <Row>
                            <Col>
                                
                                <h6>Driver Instructions:</h6>
                                <Row>
                                    <Col>
                                        <p>
                                        Please list any details you would like our driver to have prior to pickup/delivery each week. These instructions will remain valid for our driver until you delete or change them (ie. leave package outside my door this week.)
                                        </p>
                                        <Input
                                            className="border-input"
                                            placeholder=""
                                            rows="3"
                                            type="textarea"
                                            maxlength="200"
                                            name="deliveryInstructions"
                                            value={deliveryInstructions}
                                            onChange = {this.handleChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {/* End of Driver Instructions */}

                        

                        

                    
                    </div>
                    
                    <div className={`modal-footer`}>
                        <div className="left-side">
                            <Button
                            className="btn-link"
                            color="default"
                            type="button"
                            onClick={() => this.closeWindow()}
                            >
                            Cancel
                            </Button>
                        </div>
                        <div className="divider" />
                        <div className="right-side">
                            <Button className="btn-link" color="danger" type="button" onClick={()=>this.saveForm()}>
                                Save Changes
                            </Button>
                        </div>
                    </div>
                </Modal>
        );
    }
}

export default StudentAccountLaundryModifyDeliveryWaiverAndDriverInstructions;