import React, {Component} from "react";
import _ from "lodash";

import SchoolService from "../../../services/schoolService";
import ResidentialService from "../../../services/residentialService";

import DesktopView from "./Views/DesktopView";
import MobileView from "./Views/MobileView";

// reactstrap components
import {
    Row,
    Col
  } from "reactstrap";


const contentDropdown = [
{ value: "how-it-works", label: "How it works" },
{ value: "pricing", label: "Location & Pricing" },
{ value: "faq", label: "FAQ`s" },

];


const faqDropdown = [
{ value: "storage-signing-up", label: "General Info" },
{ value: "pricing-faq", label: "Pricing" },
{ value: "packing-faq", label: "Packing and Insurance" },
{ value: "scheduling-pickups-deliveries", label: "Pickups and Deliveries" },
{ value: "shipping", label: "Shipping" },
];

class StorageInformation extends Component{
        
    constructor(){
        super();
        this.state = {
            listOfSchoolsByRegionForLaundry:"",
            listOfSchoolsByRegionForStorage:"",
            listOfResidentialCitiesByStateForLaundry:"",
        }
    }

    async componentDidMount(){
        const listOfSchoolsByRegionForLaundry = await SchoolService.getListOfSchoolsByRegionForLaundry();
        const listOfSchoolsByRegionForStorage = await SchoolService.getListOfSchoolsByRegionForStorage();
        const listOfResidentialCitiesByStateForLaundry = await ResidentialService.getListOfResidentialCitiesByState();
        
        this.setState({
            listOfSchoolsByRegionForLaundry,
            listOfResidentialCitiesByStateForLaundry,
            listOfSchoolsByRegionForStorage,
        });
    }

    render(){
        const {
            listOfSchoolsByRegionForLaundry,
            listOfSchoolsByRegionForStorage,
            listOfResidentialCitiesByStateForLaundry,
        } = this.state;

        const displayCSS = "min-height-500";
        
        return(
                <>
                    <Row className={displayCSS}>
                        <Col md="12" sm="12">
                            <div className="d-none d-md-block">
                                <DesktopView 
                                   contentDropdown = {contentDropdown}
                                   faqDropdown = {faqDropdown}
                                   listOfSchoolsByRegionForLaundry = {listOfSchoolsByRegionForLaundry}
                                   listOfSchoolsByRegionForStorage = {listOfSchoolsByRegionForStorage}
                                   listOfResidentialCitiesByStateForLaundry = {listOfResidentialCitiesByStateForLaundry}
                                   {...this.props}
                                />
                            </div>
                            <div className="d-block d-md-none">
                                <MobileView 
                                    contentDropdown = {contentDropdown}
                                    faqDropdown = {faqDropdown}
                                    listOfSchoolsByRegionForLaundry = {listOfSchoolsByRegionForLaundry}
                                    listOfSchoolsByRegionForStorage = {listOfSchoolsByRegionForStorage}
                                    listOfResidentialCitiesByStateForLaundry = {listOfResidentialCitiesByStateForLaundry}
                                    {...this.props}
                                />
                            </div>
                        </Col>
                    </Row>

                    

                </>
            
        )
    }
}


export default StorageInformation