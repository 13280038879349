import React from "react";

import {
    Media,
} from "reactstrap";

const studentData = [
    {
        heading:"Will my laundry be washed with other people's laundry?",
        body:<p>
        Absolutely not! We wash and dry all of our customers' laundry individually.
        </p>
    },
    {
        heading:"How can I be sure that nothing is missing?",
        body:<p>
        We weigh the clothes when we receive them (to the tenth of the pound) and we weigh them when the process is completed.  We use a sophisticated bar-coding system to keep track of laundry throughout the process.  We also utilize a video surveillance system that records all of the clothing as it moves through our laundry system. 
        </p>,
    },
    {
    heading:"Do I need to separate my laundry?",
    body:<p>
        There is no need to separate your colors and whites. We will do that for you.
    </p>,
    },
    {
        heading:"Should I un-ball my socks?",
        body:<p>
        Yes! You should also make sure that there aren't clothes inside of clothes, such as two shirts that you took off together, or shorts and sweats that came off together.
    Separating these items is not mandatory, but it does help to get your clothes their cleanest and prevent color runs.</p>,
    },
    {
        heading:"Are garment bags required for delicate items?",
        body:<p>
        No, but they are a good idea. If you are worried about damage to your bras, underwear and other delicate items, we suggest you place them into a garment bag within your larger laundry bag. Also make sure not to overfill your garment bag to ensure adequate cleaning. 
        </p>,
    },
    {
        heading:"Can I give special instructions for my laundry?",
        body:<p>
        If you would like something special done with your clothes, we will be more than happy to comply if possible.  Simply put a note in your laundry bag with specific instructions. Place special care garments into a separate bag within your larger laundry bag. 
        </p>,
    },
    {
        heading:"What if I give you something brand new?",
        body:<p>
        Please let us know when you are washing something for the first time. We will be happy to wash it separately (at no extra charge). This will prevent colors from running on your other clothes.
        </p>,
    },
    {
        heading:"What are your locations?",
        body:<p>
        We currently provide laundry service in Wisconsin, New York, Illinois, Pennsylvania, Delaware and Colorado.
        </p>,
    },
];

const residentialData = [
    {
        heading:"Will my laundry be washed with other people's laundry?",
        body:<p>
        Absolutely not! We wash and dry all of our customers' laundry individually.
        </p>
    },
    {
        heading:"How can I be sure that nothing is missing?",
        body:<p>
        We weigh the clothes when we receive them (to the tenth of the pound) and we weigh them when the process is completed.  We use a sophisticated bar-coding system to keep track of laundry throughout the process.  We also utilize a video surveillance system that records all of the clothing as it moves through our laundry system. 
        </p>,
    },
    {
    heading:"Do I need to separate my laundry?",
    body:<p>
    There is no need to separate your colors and whites. We will do that for you.
    </p>,
    },
    {
        heading:"Should I un-ball my socks?",
        body:<p>
        Yes! You should also make sure that there aren't clothes inside of clothes, such as two shirts that you took off together, or shorts and sweats that came off together.
    Separating these items is not mandatory, but it does help to get your clothes their cleanest and prevent color runs.</p>,
    },
    {
        heading:"Are garment bags required for delicate items?",
        body:<p>
        No, but they are a good idea. If you are worried about damage to your bras, underwear and other delicate items, we suggest you place them into a garment bag within your larger laundry bag. Also make sure not to overfill your garment bag to ensure adequate cleaning. 
        </p>,
    },
    {
        heading:"Can I give special instructions for my laundry?",
        body:<p>
        If you would like something special done with your clothes, we will be more than happy to comply if possible.  Simply put a note in your laundry bag with specific instructions. Place special care garments into a separate bag within your larger laundry bag. 
        </p>,
    },
    {
        heading:"What if I give you something brand new?",
        body:<p>
        Please let us know when you are washing something for the first time. We will be happy to wash it separately (at no extra charge). This will prevent colors from running on your other clothes.
        </p>,
    },
    {
        heading:"What are your locations?",
        body:<p>
        We currently provide laundry service in Wisconsin, New York, Illinois, Pennsylvania, Delaware and Colorado.
        </p>,
    },
];

  const LaundryCare = (props)=>{
      const {isStudent,isResidential} = props;
      const data = (isStudent === true) ? studentData:residentialData;
      
      let ctr = 1;
      return(
        <>
            {
                data.map(({heading,body})=>
                    <Media key={ctr++}>
                        <Media body>
                        <Media heading>{heading}</Media>
                        {body}
                        </Media>
                    </Media>
                )
            }
        </>
      );
  }

  export default LaundryCare;
