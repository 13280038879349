import React from "react";

import applicationConstants  from "../../../../../../utils/applicationConstants"


// reactstrap components
import {
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    FormGroup, 
    Modal, 
} from "reactstrap";


const LandingPageComponent = (props)=>{
    const { 
        COMMERCIAL_CUSTOMER_TYPE_SCHOOL,
        COMMERCIAL_CUSTOMER_TYPE_BY_THE_POUND,
    } = applicationConstants;

    const {
         customerProperties, 
         onClickOfAccountActivityButton, 
         onClickOfSalesReportButton 
    } = props;
    
    const {
        commercialCustomerType:customerType,
        customerName,
    } = customerProperties;

    return(
        <>
            <div className="border-bottom mb-4">
                <div className="d-flex justify-content-between bd-highlight mb-3">
                    <div className="pl-0 py-2 bd-highlight mt-2">
                        <h6 className="card-category">
                            <span className="text-left">
                            Account Details
                            </span>
                        </h6>
                    </div>
                    <div className="p-2 bd-highlight">
                    </div>
                </div>
            </div>
            
            <Row >
                <Col md="4" className="">
                    <Row>
                        <Col xs="9" sm="9" md="12">
                            <Row>
                                <Col xs="4" sm="4" md="4">
                                    Commercial Account:
                                </Col>
                                <Col xs="8" sm="8" md="8">
                                    {`${customerName}`}
                                </Col>
                            </Row>


                            {   customerType === COMMERCIAL_CUSTOMER_TYPE_SCHOOL && 
                                <Row>
                                    <Col xs="4" sm="4" md="4">
                                    
                                    </Col>
                                    <Col xs="8" sm="8" md="8" className="text-left">
                                        <Button
                                            className="btn-link"
                                            color="info"
                                            size="sm"
                                            type="button"
                                            style={{paddingLeft:"0px"}}
                                            onClick={onClickOfSalesReportButton}
                                        >
                                            <i className="fa fa-table" aria-hidden="true"></i>
                                            View Sales Report
                                        </Button>
                                    </Col>
                                 </Row>
                            }

                            {   customerType === COMMERCIAL_CUSTOMER_TYPE_BY_THE_POUND && 
                                <Row>
                                    <Col xs="4" sm="4" md="4">
                                    
                                    </Col>
                                    <Col xs="8" sm="8" md="8" className="text-left">
                                        <Button
                                            className="btn-link"
                                            color="info"
                                            size="sm"
                                            type="button"
                                            style={{paddingLeft:"0px"}}
                                            onClick={onClickOfAccountActivityButton}
                                        >
                                            <i className="fa fa-table" aria-hidden="true"></i>
                                            View Account Activity
                                        </Button>
                                    </Col>
                                 </Row>
                            }           
                            

                            
                        </Col>
                        <Col xs="3" sm="3" className="align-middle d-block d-md-none">
                            <img  
                                className="img-rounded img-responsive"
                                src={require("../../../../../../assets/mylazybones/img/commercial-laundry-new.jpg")}
                                
                            />
                        </Col>
                    </Row>

                </Col>
                <Col  md="4">
                </Col>
                <Col md="3" className="align-middle d-none d-md-block">
                    <img  
                        className="img-rounded img-responsive"
                        src={require("../../../../../../assets/mylazybones/img/commercial-laundry-new.jpg")}
                    />
                </Col>
            </Row>
        
        </>
    )
}

export default LandingPageComponent;

