import React,{Component} from "react";
import moment from "moment";
import _ from "lodash";
import Joi from "@hapi/joi";
import ReactDatetime from "react-datetime";
import {scrollTop} from '../../../../../../../utils/helperUtils';
import FormErrorWidget from "../../../../../../Common/FormErrorWidget";
import {renderError} from '../../../../../../../utils/errorUtils';
import applicationConstants from '../../../../../../../utils/applicationConstants';

// reactstrap components
import {
    Button,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    FormGroup, 
    Input, 
  } from "reactstrap";

  class DeliveryDateAndTimeSelectionStep3 extends Component{
    divAreaToScrollForError = "deliveryDateAndTimeSelectionStep3"

    deliveryTimeId
    validationSchema = Joi.object({
        deliveryTimeId:Joi.number().required(1),
    }) ;

    errorMessages = {
        "deliveryTimeId":{
            "number.base":"Please select a delivery date and timeslot",
        },
    }
    constructor(){
        super();
        this.state = {
            data:{
                deliveryDay:"",
                deliveryStartTime:"",
                deliveryEndTime:"",
                deliveryTimeId:"",
                deliveryTimeslotList:[],
                schedulingNotes:"",
            },
            errors:{},
        }
    }

    componentDidMount(){
        try{
            this.mapModelToView();
        }
        catch(ex){
            console.log(ex);
        }
        
    }

    handleChange = ({currentTarget:input})=>{
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[input.name] = input.value;
        this.setState({data,errors});
        
    }

    handleDeliveryDay = (deliveryDayMoment)=>{
        const data = {...this.state.data}
        data.deliveryDay = deliveryDayMoment;
        data.deliveryStartTime = "";
        data.deliveryEndTime = "";
        data.deliveryTimeId = "";
        
        const { newDeliveryAddressProperties,storageOrder } = this.props
        if(newDeliveryAddressProperties && newDeliveryAddressProperties.zoneId){
            const {storageDeliveryTimeslot} = newDeliveryAddressProperties
                
            const deliveryDayMomentToCompare = deliveryDayMoment.clone().utc();
            const deliveryTimeslotListObject = storageDeliveryTimeslot.find(p=>deliveryDayMomentToCompare.isSame(moment(p.date).utc(),'day'))
            data.deliveryTimeslotList = deliveryTimeslotListObject.timeslot;       
        }else{
            if(storageOrder){
                const {storagePickupDeliveryTimeslot} = storageOrder;
                const {delivery:deliveryList} = storagePickupDeliveryTimeslot;
                //const deliveryTimeslotListObject = deliveryList.find(p=>deliveryDayMoment.isSame(p.date,'day'))
                const deliveryDayMomentToCompare = deliveryDayMoment.clone().utc();
                const deliveryTimeslotListObject = deliveryList.find(p=>deliveryDayMomentToCompare.isSame(moment(p.date).utc(),'day'))
                data.deliveryTimeslotList = deliveryTimeslotListObject.timeslot;
            }
        }
        
        this.setState({data});
    }

    handleSubmit = (e)=>{
        try{
            e.preventDefault();  
            
            const errors = this.validate();
            this.setState({errors: errors || {}});
            if(errors){ scrollTop(this.divAreaToScrollForError); return;}
            
            const {handleSubmit} = this.props;  
            const formData = {...this.state.data};
            handleSubmit(formData);
            
        }catch(ex){
            renderError(ex);
        }
    }

    mapModelToView = ()=>{
        const {deliveryDateAndTime:deliveryDateAndTimeObject,earlyDeliveryIndicatorProperties,newDeliveryAddressProperties,storageOrder,schedulingNotes} = this.props;
        const {deliveryDay} = deliveryDateAndTimeObject;
        let data = deliveryDateAndTimeObject;
        data.schedulingNotes = schedulingNotes;
        if(newDeliveryAddressProperties && newDeliveryAddressProperties.zoneId && deliveryDay){
            const deliveryDayMoment = moment(deliveryDay)
            const {storageDeliveryTimeslot} = newDeliveryAddressProperties
            const deliveryTimeslotListObject = storageDeliveryTimeslot.find(p=>deliveryDayMoment.isSame(p.date,'day'))
            data.deliveryTimeslotList = deliveryTimeslotListObject.timeslot;         
        }
        else{
            if(storageOrder && deliveryDay){
                const deliveryDayMoment = moment(deliveryDay)
                const {storagePickupDeliveryTimeslot} = storageOrder;
                const {delivery:deliveryList} = storagePickupDeliveryTimeslot;
                const deliveryTimeslotListObject = deliveryList.find(p=>deliveryDayMoment.isSame(p.date,'day'))
                data.deliveryTimeslotList = deliveryTimeslotListObject.timeslot;
            }
        }

        const isEarlyDeliveryAvailable  = earlyDeliveryIndicatorProperties && earlyDeliveryIndicatorProperties.status
        if(isEarlyDeliveryAvailable){
            data.deliveryDay = moment(earlyDeliveryIndicatorProperties.deliveryDate)
            data.deliveryTimeId = applicationConstants.STORAGE_EARLY_DELIVERY_TIME_ID //Indicator that it 
            data.deliveryStartTime = applicationConstants.STORAGE_EARLY_DELIVERY_TIME_START_TIME
            data.deliveryEndTime = applicationConstants.STORAGE_EARLY_DELIVERY_TIME_END_TIME
        }
        
        this.setState({data});
    }

    setDeliveryTime = (deliveryTimeslotObject)=>{
        //console.log(deliveryTimeslotObject);
        const data = {...this.state.data};
        const {storage_scheduling_id:deliveryTimeId,start_time:deliveryStartTime,end_time:deliveryEndTime} = deliveryTimeslotObject
        data.deliveryStartTime = deliveryStartTime;
        data.deliveryEndTime = deliveryEndTime;
        data.deliveryTimeId = deliveryTimeId;
        this.setState({data});
    }

    parseErrors = (validationErrorList)=>{
        const validationErrors = {};
        if(validationErrorList){
            console.log(validationErrorList.details);
            validationErrorList.details.map(
                function(errorObject){
                    //console.log(errorObject);
                    if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                        const key = errorObject.context.key;
                        const joiType = errorObject.type;
                        //console.log(this.errorMessages);
                        if(_.has(this.errorMessages, [key, joiType]))
                            return validationErrors[errorObject.path[0]] = this.errorMessages[key][joiType];
                        else
                            return validationErrors[errorObject.path[0]] = errorObject.message;
                    }
                    else if(_.has(errorObject, ['context', 'peers']) &&  _.has(errorObject, ['type'])){
                        const joiType = errorObject.type;
                        if(joiType === "object.missing")
                            return validationErrors[errorObject.path[0]] = this.errorMessages.minimumFieldsMessage.message;
                    }
                    else
                        return validationErrors[errorObject.path[0]] = errorObject.message;
                }.bind(this)
            );
        }

        return validationErrors
    }

    validate = ()=>{
        try{
            let data  = {...this.state.data}, 
                errorList = null;

            const options = {abortEarly:false,allowUnknown:true};
            const {deliveryTimeId} = data
            const dataObject = {deliveryTimeId:data.deliveryTimeId};
            ({error:errorList} = this.validationSchema.validate(dataObject,options));
            //console.log(errorList);
            const primaryErrors = this.parseErrors(errorList);

            
            const errors = {...primaryErrors} 
        
            //console.log(errors);
            return Object.keys(errors).length > 0 ? errors:null;
        }catch(ex){
            console.log(ex);
        }

    }

    render(){
        const {data,errors} = this.state;

        const { 
            deliveryDay,
            deliveryTimeId,
            deliveryTimeslotList,
            schedulingNotes
            
        } = data

        const { 
            handleBack,
            newDeliveryAddressProperties,
            earlyDeliveryIndicatorProperties,
            storageOrder,
            user 
        } = this.props
        
        let listOfDeliveryDates = [];

        if(newDeliveryAddressProperties && newDeliveryAddressProperties.zoneId){
                const {storageDeliveryDateList} = newDeliveryAddressProperties;
                listOfDeliveryDates = storageDeliveryDateList.map(d => moment.utc(d));
        }
        else{
            if(storageOrder){
                const {storagePickupDeliveryDateList} = storageOrder;
                listOfDeliveryDates = storagePickupDeliveryDateList.deliveryDates.map(d => moment.utc(d));
            }
        }

        const isEarlyDeliveryAvailable  = earlyDeliveryIndicatorProperties && earlyDeliveryIndicatorProperties.status
        
        return(
            <div id="deliveryDateAndTimeSelectionStep3">
                {/*Error display */}
                {!_.isEmpty(errors) &&
                    <FormErrorWidget
                        errors={errors}
                    />
                }
                {/* End of Error display */}

                {
                    isEarlyDeliveryAvailable && 
                    <>
                        <Row>
                            <Col lg="3">
                                Delivery Date:
                            </Col>
                            <Col lg="7">
                                <strong>{moment(earlyDeliveryIndicatorProperties.deliveryDate).format('MM/DD/YYYY')}</strong>
                                <br/>
                                {earlyDeliveryIndicatorProperties.deliveryMessage}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <hr/>
                                <h6>Notes:</h6>
                                <Row>
                                    <Col>
                                        <p>
                                        Please enter any notes you have for us regarding your storage delivery.
                                        </p>
                                        <Input
                                            className="border-input"
                                            placeholder=""
                                            rows="3"
                                            type="textarea"
                                            maxLength="200"
                                            name="schedulingNotes"
                                            value={schedulingNotes}
                                            onChange = {this.handleChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row> 
                    </>
                }

                {
                    !isEarlyDeliveryAvailable && 
                    <Row>
                        <Col lg="4">
                            Select Delivery Date:
                        </Col>
                        <Col lg="4">
                        <FormGroup>
                            <InputGroup 
                                name="deliveryDatetimePicker"
                                className="date" 
                                id="deliveryDatetimePicker"
                                
                            >
                                <ReactDatetime
                                    value={deliveryDay} 
                                    timeFormat={false}
                                    closeOnSelect={true}
                                    onChange={this.handleDeliveryDay}
                                    inputProps={{
                                        className: "form-control",
                                        placeholder: "Delivery Day",
                                    }}
                                    renderDay={( props, currentDate, selectedDate)=>{
                                        /*
                                        console.log(props);
                                        console.log(currentDate);
                                        console.log(selectedDate);
                                        console.log(this.props);
                                        */

                                        if(listOfDeliveryDates.length){
                                            const currentMomentUtc = moment.utc(currentDate);
                                                    
                                            let highLightDate = false;
                                        
                                            listOfDeliveryDates.forEach(d=>{
                                                if(currentMomentUtc.isSame(d,'day') && moment.utc().isSameOrBefore(currentMomentUtc,'day'))
                                                    highLightDate = true;
                                            })

                                            if(highLightDate)
                                                props.className += " rdtActive color-white fw-strong";
                                                
                                        }
                                        
                                        
                                        return <td {...props}>{currentDate.date() }</td>;
                                    }}

                                    isValidDate={currentDate=>{
                                        const currentMomentUtc = moment.utc(currentDate);
                                        let enableDate = false;
                                        listOfDeliveryDates.forEach(d=>{
                                            if(currentMomentUtc.isSame(d,'day') && moment.utc().isSameOrBefore(currentMomentUtc,'day'))
                                                enableDate = true;
                                        })
                                        return enableDate;
                                    }}
                                />
                                <InputGroupAddon addonType="append">
                                <InputGroupText>
                                    <span className="glyphicon glyphicon-calendar">
                                    <i className="fa fa-calendar" />
                                    </span>
                                </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup> 
                        </Col>
                    </Row>
                }

                {   !isEarlyDeliveryAvailable && deliveryDay && deliveryTimeslotList.length &&
                        <>
                        <Row >
                            <Col lg="4">
                                Select Timeslots:
                            </Col>
                            <Col lg="8">
                                <Row>
                                    {
                                       deliveryTimeslotList.map(deliveryTimeslotObject=>{
                                            return(
                                                <Col key={deliveryTimeslotObject.storage_scheduling_id} xs="6" className="mb-2">
                                                    <Button
                                                        className="btn-round mr-1"
                                                        color="danger"
                                                        outline = {(deliveryTimeId === deliveryTimeslotObject.storage_scheduling_id) ? false:true}
                                                        block
                                                        type="button"
                                                        onClick={()=>this.setDeliveryTime(deliveryTimeslotObject)}
                                                    >
                                                        {`${moment(`${deliveryTimeslotObject.date} ${deliveryTimeslotObject.start_time}`).format('h:mm a')}
                                                         - 
                                                         ${moment(`${deliveryTimeslotObject.date} ${deliveryTimeslotObject.end_time}`).format('h:mm a')}`
                                                        }
                                                    </Button>

                                                </Col>
                                            );
                                        })
                                    }
                                    
                                      
                                </Row>
                                
                            
                                
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <hr/>
                                <h6>Notes:</h6>
                                <Row>
                                    <Col>
                                        <p>
                                        Please enter any notes you have for us regarding your storage delivery.
                                        </p>
                                        <Input
                                            className="border-input"
                                            placeholder=""
                                            rows="3"
                                            type="textarea"
                                            maxLength="200"
                                            name="schedulingNotes"
                                            value={schedulingNotes}
                                            onChange = {this.handleChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row> 
                        </>
                }

                <FormGroup row  className="mt-3 justify-content-between">
                    <Col xs={{ size: 2}}>
                        <Button 
                            className="btn-rounded btn-danger"
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                    </Col>
                    <Col xs={{ size: 6}} sm={{ size: 6}} className="text-right">
                        <Button 
                            className="btn-rounded btn-danger"
                            onClick={this.handleSubmit}
                        >
                            Save & Review
                        </Button>
                    </Col>
                </FormGroup>

            </div>
        )
    }
  }

  export default DeliveryDateAndTimeSelectionStep3;
