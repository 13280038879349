import React from "react";
import _ from "lodash";
import {Elements} from 'react-stripe-elements';


// reactstrap components
import {
    Button,
    Container,
    Row,
    Col,
    FormGroup, 
    Modal, 
} from "reactstrap";


// core components
import MyAccountNavbar from "../../../../Navbars/MyAccountNavbar";
import FooterBlack from "../../../../Footers/FooterBlack";

//Signup components
import SelectSemesterAndPricing from "./SignupSteps/SelectSemesterAndPricing"
import CustomerAddressInformation from "../../Common/CustomerAddressInformation";
import StorageDeliveryAddressInformation from "../../Common/StorageDeliveryAddressInformation"
import PaymentInformation from "../../Common/PaymentInformation"
import ReviewScreen from "./SignupSteps/ReviewScreen";

const proceedToDeliveryAddressInformationStep = user=>{
    let proceed  = false;

    const {updatePrimaryAddressFlag,storage} = user;

    if(proceedToPickupAddressInformationStep(user) && updatePrimaryAddressFlag === false){
        proceed  = true;
    }
    else if(proceedToPickupAddressInformationStep(user) && updatePrimaryAddressFlag === true){
        const {pickUpAddress} = storage 
        const {isDormitory,dormitory,addressLine1,city,state,postalCode} = pickUpAddress;
        if(isDormitory === true && !_.isEmpty(dormitory)){
            proceed  = true;
        }
        else if(isDormitory === false && addressLine1 !== "" && city !== "" && postalCode != ""){
            proceed  = true;
        }
    }

    return proceed;
}

const proceedToPaymentInformation = user=>{
    let proceed  = false;
    
    if(!proceedToPickupAddressInformationStep(user) || !proceedToDeliveryAddressInformationStep(user)){
        return false;
    }
    
    const { storage } = user; 
    const { deliveryAddress } = storage;
    const {isSameAsPickupAddress,deliverToNewAddress,deliveryAddressLater} = deliveryAddress;

    if(isSameAsPickupAddress === true){
        proceed  = true;
    }

    if(deliverToNewAddress === true){
        const {isDormitory,dormitory,addressLine1,city,state,postalCode} = deliveryAddress;
        if(isDormitory === true && !_.isEmpty(dormitory)){
            proceed  = true;
        }
        else if(isDormitory === false && addressLine1 !== "" && city !== "" && postalCode != ""){
            proceed  = true;
        }
    }

    if(deliveryAddressLater === true){
        proceed  = true;
    }

    return proceed;
}

const proceedToPickupAddressInformationStep = user=>{
    let proceed  = false;

    const { storage } = user; 
    const {student:storageProperties} = storage; 
    const {pricingProperties,semester} = storageProperties;

    proceed  = (
        pricingProperties !== "" && semester !== ""
      ) ? true:false;

    return proceed;
    
}

const proceedToReviewScreen = user=>{
    let proceed  = false;

    if(
        !proceedToPickupAddressInformationStep(user) || 
        !proceedToDeliveryAddressInformationStep(user) ||
        !proceedToPaymentInformation(user)
    ){
        return false;
    }

    const {updatePaymentInformationFlag,existingPaymentInformationOnFile,paymentInformation} = user;
    const {isExpired} = existingPaymentInformationOnFile;
    const {creditCardStripeToken} = paymentInformation

    if(updatePaymentInformationFlag === false){
        proceed  = true;
    }
    else if(updatePaymentInformationFlag === true && creditCardStripeToken !== ""){
        proceed  = true;
    }
    
    return proceed;
}

const validateToRenderComponent = (props)=>{
    const {accountType, product, school,step } = props.match.params;
    let proceed = false;
  
    const {user} = props;
    
    switch(step){
        case "select-plan":                     
                proceed = true;
                break;
        case "update-campus-address": 
                proceed = proceedToPickupAddressInformationStep(user)                    
                break;
        case "set-delivery-address":                     
                proceed = proceedToDeliveryAddressInformationStep(user)   
                break;
        case "payment-info":                     
                proceed = proceedToPaymentInformation(user);
                break;
        case "review-screen":                     
                proceed = proceedToReviewScreen(user);
                break;
        default:    
                proceed = true;
    }
  
    return proceed;
}

function renderComponent(props){
    const {step } = props.match.params;
    let component = <SelectSemesterAndPricing  {...props}  />
  
    switch(step){
        case "select-plan":                     
                component= <SelectSemesterAndPricing {...props}  />;
                break;
        case "update-campus-address":                     
                component= <CustomerAddressInformation {...props}  />;
                break;
        case "set-delivery-address":                     
                component= <StorageDeliveryAddressInformation {...props}  />;
                break;
        case "payment-info":         
                component= <Elements><PaymentInformation {...props} /></Elements>;
                break;
        case "review-screen":                     
                component= <ReviewScreen {...props}  />;
                break;
        default:    
                component= <SelectSemesterAndPricing {...props}  />;
    }
    return component;
}

function RenewStorageStudent(props) {
    const {accountType, product, schoolId, semesterId,step } = props.match.params;
        
    React.useEffect(() => {
        const proceed = validateToRenderComponent(props)
        if(!proceed){
            return props.history.push(`/existing-user/signup/${accountType}/${product}/${schoolId}/${semesterId}/select-plan`)
        }
        
        document.body.classList.add("product-page");
        return function cleanup() {
          document.body.classList.remove("product-page");
        };
    });
    //console.log(props);
    return (
        <>
        <MyAccountNavbar {...props} />
        <div className="main">
            <div id="sectionArea" className="section section-gray min-height-lg" style={{paddingTop:"100px"}}>
                {renderComponent(props)}
            </div>
        </div>
        <FooterBlack />
        </>
    );
}

export default RenewStorageStudent;
