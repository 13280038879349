import React,{Component} from "react"
import _ from "lodash"
import moment from "moment"

// reactstrap components
import {
    Button,
    Container,
    Card,
    CardBody,
    CardFooter,
    CardImg,
    CardImgOverlay,
    CardLink,
    CardTitle,
    CardSubtitle,
    CardText,
    ListGroupItem,
    ListGroup,
    Row,
    Col,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    FormText,
    UncontrolledTooltip
    
  } from "reactstrap";

class StudentSemesterAndPricingStorage extends Component{
    constructor(){
        super();
        this.state = {
            data:{
                semester:"",    //{id,semester}
                pricingProperties:"",
            },
            errors:{}
        }
    }

    componentDidMount(){
        document.getElementById("sectionArea").scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
          });

        this.mapStudentModelToView();
       //window.scrollTo(0, 0);
       //document.body.scrollTop = 0;
       
      
    }

    handleSelectSemester = (semester)=>{
        //const semester = _.pick(s,['id','semester']);
        const data = {...this.state.data}
        data.semester = semester;
        data.pricingProperties = semester.pricing;
        this.setState({data}); 
    }

    handleSubmit = (e)=>{
        e.preventDefault();  
        const {onClickOfNextButtonFromSemesterAndPricingInformation} = this.props;  
        console.log("Handle submit of semester information");
        //console.log(this.state);
        const semesterAndPricingForm = {...this.state.data};
        onClickOfNextButtonFromSemesterAndPricingInformation(semesterAndPricingForm);
        return;
     }

     /*
       Accepting storage is checked in the select school screen where it accepts atleast one 
       semester with acceptingStorage => true
       
       Filter semesters that fulfill the following condition:
         - accept storage => true
         - startDateForDisplayingOnWeb <= current date
         - endDateForDisplayingOnWeb > current date
     */
     isSemesterAvailableForSignup = ()=>{
        const {user}  = this.props;  
        const semesterList = (user && user.school && user.school.semesters) || [];
        
        if(semesterList.length){
            const semester = _.find(semesterList, (s)=>{
                const acceptStorage = s.acceptingStorage
                const startDateSellingSeason = moment(s.startDateForDisplayingOnWeb);
                const endDateSellingSeason = moment(s.endDateForDisplayingOnWeb);
                const now = moment();

                return (
                    acceptStorage && 
                    now.isSameOrAfter(startDateSellingSeason) && 
                    now.isSameOrBefore(endDateSellingSeason)
                );
            });

            return (semester !== undefined) ? true:false;
        }

        return false;
     }

     mapStudentModelToView = ()=>{
        const {user}  = this.props;  
        const semesters  = user.school.semesters;
        const {semester,pricingProperties} = this.props.user.storage.student;
        
        /*
          If user has not selected a semester or come to the screen the first time 
          then auto populate the state if only semester is available
        */
        let data = {...this.state.data}
        if(!semester){
            const semestersAcceptingStorage =  _.filter(semesters, function(s) { return s.acceptingStorage; });
            if(semestersAcceptingStorage.length === 1 ){
                const singleSemester = semestersAcceptingStorage[0];
                //const defaultSemester = _.pick(singleSemester,['id','semester']);
                const defaultSemester = singleSemester;
                data.semester = defaultSemester;
                data.pricingProperties = singleSemester.pricing;
            }
        }
        else{
            data = {
                semester,    
                pricingProperties,
            }
        }
        
        if(!_.isEqual(this.state.data,data))
           this.setState({data});
     }

    

    render(){
      const {onClickOfBackButtonFromSemesterAndPricingInformation,user}  = this.props;  
      const semesterList = user.school.semesters;
      //console.log(semesterList);

      const {semester:selectedSemester,pricingProperties:selectedPricingProperties} = this.state.data;
      const isSemesterAvailableForSignup = this.isSemesterAvailableForSignup();
      
      return(
        <Container id="mainContentArea">
            <Row className="title-row justify-content-between">
                <Col md="6">
                    <h3 className="shop">Signup for Storage - Student</h3>
                </Col>
                <Col md="5">
                    <div className="text-md-right d-none">
                        <span className="text-muted">Step 2</span>
                        <Button color="link">
                        <i className="fa fa-shopping-cart" /> Reserve Space
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row className="title-body-row">
                <Col md="12">
                        <Card>
                            <CardBody>
                                {   !isSemesterAvailableForSignup && 
                                    <>
                                        <div>
                                            <Row>
                                                <Col xs="12">
                                                    <div className="mlb-alert mlb-alert-primary" role="alert">
                                                        <Row>
                                                            <Col xs="1" sm="1" className="fs-42 text-danger">
                                                                    <i className="fa fa-exclamation-triangle fa-6" aria-hidden="true"></i>    
                                                            </Col>
                                                            <Col>
                                                            <strong>We currently don't have any upcoming semester plans available for sign-up. Please try again later.</strong>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>

                                        <FormGroup row className="mt-5 justify-content-between">
                                            <Col xs={{ size: 2}}>
                                                <Button 
                                                className="btn-rounded btn-danger"
                                                onClick={()=>onClickOfBackButtonFromSemesterAndPricingInformation()}
                                                >
                                                Back
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </>
                                }

                                {
                                    isSemesterAvailableForSignup &&
                                    <Form>
                                        <div>
                                            <h6 className="card-category text-left">Selected Semester</h6>
                                            <hr/>
                                            {
                                            semesterList.map(s=>{
                                                if(!s.acceptingStorage) return;
                                                let outlineFlag = false;
                                                
                                                //If multiple semesters
                                                if(semesterList.length > 1){
                                                    outlineFlag = (selectedSemester.id !== s.id) ?  true:false
                                                }
                                                
                                                return(
                                                    <FormGroup key={s.id} row className="text-center">
                                                        <Col sm="2"></Col> 
                                                        <Col sm={{ size: 8}}> 
                                                            <Button
                                                                className="btn-round mr-1"
                                                                color="danger"
                                                                outline = {outlineFlag}
                                                                block
                                                                type="button"
                                                                onClick={()=>this.handleSelectSemester(s)}
                                                            >
                                                                {s.semester} ({s.startDate} - {s.endDate})
                                                            </Button>
                                                        </Col>
                                                        <Col sm="2"></Col>
                                                    </FormGroup>   

                                                );
                                            })      
                                            }
                                        </div>
                                        
                                        <FormGroup row className="mt-5 justify-content-between">
                                            <Col xs={{ size: 2}}>
                                                <Button 
                                                className="btn-rounded btn-danger"
                                                onClick={()=>onClickOfBackButtonFromSemesterAndPricingInformation()}
                                                >
                                                Back
                                                </Button>
                                            </Col>
                                            <Col xs={{ size: 6}} sm={{ size: 4}} className="text-right">
                                                <Button 
                                                disabled={false}
                                                className="btn-rounded btn-danger"
                                                onClick={this.handleSubmit}
                                                >
                                                Reserve Space & Proceed
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </Form> 
                                }
                                                     
                            </CardBody>
                        </Card> 
                </Col>
                 
            
            </Row> 
        </Container>  
      );
    }
}
export default StudentSemesterAndPricingStorage;