import React,{Component} from "react"
import _ from "lodash";
import accounting from "accounting";
import moment from "moment";

import SuppliesService from "../../../../../../services/suppliesService";

import SupplyOrderDetailView from "./SupplyOrderDetailView";
import {renderError} from '../../../../../../utils/errorUtils';
import {scrollTop} from '../../../../../../utils/helperUtils';


import {
    Button,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Label,
    Row,
    Col,
    FormGroup, 
    Modal, 
    Input, 
    Table,
} from "reactstrap";

class SupplyOrderListModal extends Component{
    constructor(){
        super()

        this.state = {
            supplyOrderList:"",
            processingFlag:false,
            viewMode:"list-view",
            supplyOrderRecordForDetailView:""
        }
        
    }

    async componentDidMount(){
        try{
            this.setState({processingFlag:true});
            const {status:processingStatus,message,supplyOrders:supplyOrderList} = await SuppliesService.getSupplyOrdersForUser();
            if(processingStatus === true){
                this.setState({
                    supplyOrderList,
                    processingFlag:false,
                })
            }
        }
        catch(ex){
            /*
              400 signals validation errors from the server
              renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop('supplyOrderListModal'); return;}
            }
            else
                renderError(ex);
        }        

    }

    viewDetail = (supplyOrderRecord)=>{
        this.setState({
            supplyOrderRecordForDetailView:supplyOrderRecord,
            viewMode:"detail-view",
        })

    }

    viewList = (e)=>{
        e.preventDefault();
        this.setState({
            supplyOrderRecordForDetailView:"",
            viewMode:"list-view",
        })
    }



    render(){
        const {modalDisplayFlag,handleModalDisplay,...rest} = this.props 
        const {supplyOrderList,processingFlag,supplyOrderRecordForDetailView,viewMode} = this.state;

        return(
            <>
            <Modal id="supplyOrderListModal" size="lg" isOpen={modalDisplayFlag} toggle={() => handleModalDisplay(false)}>
                <div className="modal-header">
                    <button
                        className="close"
                        type="button"
                        onClick={() => handleModalDisplay(false)}
                    >
                        <span>×</span>
                    </button>
                    <h5
                        className="modal-title text-center"
                        id="exampleModalLabel"
                    >
                        Supplies:Your Order History
                    </h5>
                </div>
                <div className="modal-body">
                    {
                        processingFlag && 
                        <Row>
                            <Col className="text-center">
                                Loading .... Please wait
                            </Col>
                        </Row>
                    }

                    {
                        !processingFlag && _.isEmpty(supplyOrderList) && viewMode === "list-view" && 
                        <Row>
                            <Col className="text-center">
                                No Storage Orders available.
                            </Col>
                        </Row>
                    }

                    {
                        !processingFlag && !_.isEmpty(supplyOrderList) && viewMode === "list-view" && 
                        <Row>
                            <Col className="text-center">
                                <Table responsive striped>
                                    <thead>
                                        <tr>
                                            <th className="text-center">Order #</th>
                                            <th>Order Date</th>
                                            <th>Ship Date</th>
                                            <th className="text-right">Amount</th>
                                            <th/>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            supplyOrderList.map(s=>{
                                                const {
                                                    supply_order_id:supplyOrderId,
                                                    requested_shipdate:shipDate,
                                                    order_date:orderDate,
                                                    total,
                                                } = s
                                                return(
                                                    <tr key={supplyOrderId}>
                                                        <td className="text-center">{supplyOrderId}</td>
                                                        <td>
                                                            {orderDate && moment(orderDate).format("MM/DD/YYYY")}
                                                        </td>
                                                        <td>
                                                            {shipDate && moment(shipDate).format("MM/DD/YYYY")}
                                                        </td>
                                                        <td>{accounting.formatMoney(total)}</td>
                                                        <td>
                                                            <Button
                                                                className="btn-round mr-1"
                                                                color="danger"
                                                                outline
                                                                type="button"
                                                                onClick={(e)=>{e.preventDefault(); this.viewDetail(s)}}
                                                            >
                                                            View Items
                                                            </Button>
                                                        </td>
                                                        
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    }


                    {
                        !processingFlag && !_.isEmpty(supplyOrderRecordForDetailView) && viewMode === "detail-view" && 
                        <div>
                            <Row>
                                <Col className="text-left">
                                    <Button
                                        className="btn-move-left btn-round mr-1"
                                        color="default"
                                        onClick={this.viewList}
                                    >
                                        <i className="nc-icon nc-minimal-left mr-1" />
                                        Back
                                    </Button>
                                </Col>
                            </Row>
                            
                            <SupplyOrderDetailView
                                supplyOrder={supplyOrderRecordForDetailView}
                            />
                        </div>
                        
                    }
                    
                </div>
                <div className={`modal-footer`}>
                    <div className="divider" />
                    <div className="right-side">
                        <Button
                        className="btn-link"
                        color="default"
                        type="button"
                        onClick={() => handleModalDisplay(false)}
                        >
                        Cancel
                        </Button>
                    </div>
                    
                </div>
            </Modal>    
            </>
        )
    }
}

export default SupplyOrderListModal;