import React,{Component} from "react";
import moment from "moment";
import Select from "react-select";
import _ from "lodash";
import Joi from "@hapi/joi";
import ReactDatetime from "react-datetime";
import {scrollTop} from '../../../../../../utils/helperUtils';
import FormErrorWidget from "../../../../../Common/FormErrorWidget";
import UserService from "../../../../../../services/userService";
import {getUSStateList} from "../../../../../../services/dropDownListsService";
import SchoolService from "../../../../../../services/schoolService";

import PickupInformationReview from "./PickupInformationReview";

// reactstrap components
import {
    Button,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Label,
    Row,
    Col,
    FormGroup, 
    Modal, 
    Input, 
    Card,
    CardHeader,
    CardBody,
    Collapse,
    
  } from "reactstrap";


class PickupScheduleModifyModal extends Component{
    validationSchema = Joi.object({
        pickupDay: Joi.object().required().label('Pickup Day'),
        pickUpStartTime:Joi.any().optional(),
        pickUpEndTime:Joi.any().optional(),
        pickUpTimeId: Joi.number().required().label('Pickup Timeslot'),
        pickupTimeslotList:Joi.any().optional(),
        storageEstimateQuestionMoreThan10Items:Joi.boolean().label('Estimate Question: Do you have more than 10 items'),
        storageEstimateQuestionHaveLargeItems:Joi.boolean().label('Estimate Question: Do you have large items'),
        storagePickupSchedulingNotes:Joi.any().optional(),
        //acceptTermsAndConditions:Joi.boolean().invalid(false).label('Accept terms and conditions'),
    })

    errorMessages = {
        "pickupDay":{
            "object.base":"Pickup Day is not allowed to be empty",
        },
        "pickUpTimeId":{
            "number.base":"Pickup time is not allowed to be empty",
        },
        "storageEstimateQuestionMoreThan10Items":{
            "boolean.base":"Storage Estimate: Please answer if you will have more than 10 items",
        },
        "storageEstimateQuestionHaveLargeItems":{
            "boolean.base":"Storage Estimate: Please answer if you will have large items",
        },
        "acceptTermsAndConditions":{
            "boolean.base":"Please accept your terms and conditions",
        },
    }

    collapseArea = {
        screen1:1,
        screen2:2,
    }

    termsAndConditionsOld = <>
        <p align="justify"><strong>Minimum Order: </strong>There is a $100 minimum charge on storage orders at all Lazybones locations.  No exceptions.</p>

        <p align="justify"><strong>Minimum Order: </strong>All storage prices include pickup and delivery.</p>

        <p align="justify">Insurance Restrictions. The basic and additional Lazybones insurance for stored items provides coverage against damage or loss of the Customer's stored packages. Our insurance does not cover:</p>
        <ol>

        <li type="a">Jewelry, coins, and collectibles</li>
        <li type="a">Cash</li>
        <li type="a"> Damage to electronic equipment if there is no evidence of physical damage or breakage to the                 outside packaging container</li>
        <li type="a"> Items of intangible value (e.g., sentimental)</li>
        <li type="a"> Extremely fragile items (e.g., mirrors)</li>
        <li type="a">Improperly packed items. We judge a packing job based on UPS standards.</li>
        <li type="a"> Concealed damage. Concealed damage refers to any damage that occurs to items inside a carton when            there is no evidence of damage to the carton itself. Concealed damage is the result of inadequate            packing as opposed to mishandling, and therefore will not be insured.</li>
        <li type="a"> Unpacked items, including furniture that is not wrapped and protected</li>
        <li type="a">Minor damage due to normal handling (including but not limited to scratches, nicks, and cuts)</li>
        <li type="a"> Damage to storage containers (e.g., boxes, trunks, etc.)</li>
        <li type="a">Damage due to natural disasters, and any and all loss or damages occurring while the items are not in the possession of Lazybones.</li>
        <li type="a"> Damage caused to assembled furniture</li>

        </ol>
        <p className="p2"><b>Shipping: </b>Lazybones can ship items that have been stored by us to a customer's home upon the customer's request.<span className="Apple-converted-space">  </span>Items requested to be shipped will be an additional charge to the customer. Items will be shipped ground and insured for $750 unless the customer requests otherwise.<span className="Apple-converted-space">  </span>All items will be shipped within 3 days of the request.</p>

        <p align="justify"><u><strong>First claim</strong></u>: The Customer agrees to first claim any loss with any insurance carried by the Customer or the Customer's parents, if available. The Customer will make claims against Lazybones only after exhausting other areas of coverage.</p>

        <p align="justify"><u><strong>Damage or Loss</strong></u>: Any damaged cartons must be opened and inspected at the time of delivery in the presence of a Lazybones representative, who will confirm and document the damage. In the case of loss of stored packages: the Customer must notify the Lazybones representative of the loss at the time of delivery of the other packages, and must sign the delivery receipt amended for the lost packages. The missing items must be noted on the form and signed off by the Lazybones representative.</p>

        <p align="justify">If the Customer is not present during the time of delivery, the condition notes recorded on the contract, if any, will determine the existence and extent of damage or loss.</p>

        <p align="justify"> Claims for loss of or damage to the Customer's property must be filed with Lazybones in writing (email counts). Claims shall be deemed waived if they are not filed within 72 hours following the delivery. Claims shall also be deemed waived if the stored items are moved out of the room they were delivered to before Lazybones staff view the damage. Upon receipt of a claim in writing, Lazybones will process the claim and issue a formal reply to the Customer within 30 days. Lazybones will pay the lesser of the following claim amounts:</p>

        <ul>
        <li>The cost of reasonably restoring the property to its condition immediately before the move (applies only in the case of damaged items).</li>
        <li>The actual replacement of the property as it was specified on the Storage Contract at time of pick up.</li>
        <li>$50 unless additional insurance was purchased at time of pick up and is so designated on the Storage Contract specifically on the item in question.</li>
        </ul>

        <p align="justify"> The Customer recognizes that Lazybones will not be held liable for damage done to their belongings more than 24 hours after delivery has occurred.</p>

        <p align="justify"><u><strong>Electronic Equipment</strong></u>: In order for your electronic equipment to be insured, it must be securely packed in its original packaging (box and foam inserts) or wrapped in bubble wrap or other cushioning material. The equipment must be fitted inside the box so that the box is not misshapen or rounded in any way. These requirements apply to computers, monitors, printers, stereo equipment, televisions, and ALL other electronic equipment.</p>

        <p align="justify"><u><strong>Shipped Items</strong></u>: Lazybones is not responsible for insurance claims on shipped items. Lazybones acts as an intermediary for UPS, our shipping carrier, and shipping claims are subject to their terms that are available at http://www.ups.com. The Customer agrees to familiarize him/herself with UPS' restrictions prior to packing his/her articles for shipping. The Customer accepts full responsibility and liability for any damages or losses that result from the Customer's failure to adhere to these service restrictions.</p>

        <p align="justify"><u><strong>Storage Services</strong></u>: Storage services will include the pickup, transportation, storage, and delivery of the customer's possessions. The Customer will present pre-packed personal items to Lazybones at the time of pickup. Lazybones will transport these goods to an appropriate storage facility chosen at the sole discretion of Lazybones. Lazybones will return the goods to the Customer during dates and times established by Lazybones by considering school and dormitory schedules. The Customer will agree to be present to receive the goods at the specified delivery date, time, and location unless early deliveries are arranged by Lazybones and the building owner/manager. The Customer understands that access to goods in storage is permitted on a case-by-case basis and only with 5 days prior notice. Pickups scheduled less than 48 hours in advance are also subject to additional fees.</p>

        <p align="justify"> Lazybones service includes pickup from your place (room to room when possible), storage (time frame depends on season chosen), and delivery to your place. If you are getting a pickup from a University property, Lazybones must follow all University regulations.</p>

        <p align="justify"> Any customer who has their storage picked up while not present, does herby release and discharge Lazybones, its successors, assigns, shareholders, directors, officers, employees, and agents from any liabilities, duties, responsibilities, obligations, claims, demands, actions, causes of action, controversies, damages, costs, losses and expenses, now existing out of or in any way relating to or associated with directly or indirectly with their storage items. </p>

        <p align="justify"><u><strong>What we will not ship</strong></u>: Lazybones will not store or ship packages containing liquids.  The Customer will be liable for any damage their storage contents inflict on the property of others.</p>

        <p align="justify"> No storage shall be rendered by Lazybones:</p>
        <ul>
        <li>In the case of articles of unusual value, or of single packages having a value of more than $2,000 (US).</li>
        <li>For items containing valuables such as jewelry and collectibles.</li>
        <li>For hazardous materials and firearms</li>
        <li>For any items that are prohibited by law or regulations of any federal, state, provincial, or local government in the U.S.</li>
        </ul>

        <p align="justify"> Lazybones reserves the right to refuse any package which by reason of the character of its contents is likely, in the judgment of Lazybones, to soil, taint, or otherwise damage other merchandise or equipment, or which is economically or operationally impractical to transport, or which is improperly packed or wrapped. Lazybones reserves the right to open and inspect any package tendered to it for storage or shipping.</p>

        <p align="justify"><u><strong>Abandonment</strong></u>: The Customer agrees that their failure to be present on the scheduled delivery day and their failure to communicate with Lazybones concerning an alternate delivery arrangement constitutes abandonment of their stored possessions. In the case of abandonment of their possessions, the Customer agrees to pay Lazybones for all charges and fees relating to the failed delivery and further shipping of the possessions to the permanent address provided by the Customer. Lazybones has the right to assume ownership of the abandoned property on December 30th of the service year.</p>

        <p align="justify"><u><strong>Payment</strong></u>: All charges for services are due and payable immediately following the pickup of the Customer's possessions. All accounts must be paid by credit card at time of pickup. The Customer hereby authorizes Lazybones to charge the Customer-provided credit card.</p>

        <p align="justify"> The Customer acknowledges that they remain indebted to Lazybones for any balance owed on their account as a result of an invalid credit card, an inability to receive credit card authorizations, or any other reason that prevents Lazybones from being paid for the services and any additional fees. If the Customer has failed to pay the charges for the services or any additional fees prior to the scheduled delivery date of stored packages, Lazybones reserves right to hold the Customer's possessions until payment has been received in full. The Customer acknowledges that failure to pay their account in full prior to the delivery date could result in a change of the delivery date and the assessment of additional fees.</p>

        <p align="justify"> Pricing for services shall be in accordance with the pricing schedule contained on the Lazybones website. In addition, Lazybones will charge and the Customer will pay additional fees described in the section. The purpose of the additional fees is to compensate Lazybones for expenses it incurs beyond those associated with the provision of services according to standard procedures. Additional fees may be charged for the following reasons, as explained in the sections of this agreement: </p>

        <ul>
        <li>Pickups scheduled less than 48 hours in advance are subject to additional fees</li>
        <li>Redelivery after failed attempt--$50; </li>
        <li>Delivery outside standard dates--$50; </li>
        <li>Billing fee--$15; </li>
        <li>Additional Storage time: 10% of storage bill per month,  charges automatically charged to credit card on file.

        and additional abandonment fees, or costs incurred when storage items are not claimed by the Customer.</li>
        </ul>

        <p align="justify"><u><strong>Refunds</strong></u>: We are able to refund your storage deposit up to 3 days prior to your scheduled storage pickup. If you do not schedule a storage pickup, we will be able to refund your deposit within 30 days of your reservation. We do not refund for storage services rendered.</p>

    </>

    termsAndConditions = <>
        <div>
            <p>
                <strong>I. <u>INTRODUCTION</u></strong>
            </p>
            <p align="justify">These Terms and Conditions (the “Terms,” “Terms and Conditions” or the “Agreement”) govern your use of the internet-based services offered by “Lazybones.”  All references to “you” or “your” shall mean and refer to the customer (“Customer”) ordering the Services (as that term is defined herein).  The Companies and you are collectively referred to as the “Parties”.</p>
            <p align="justify">The following entities are independent entities operating in connection with the Lazybones brand, but by using the Lazybones service, you agree that each are separate and distinct corporate entities, and that none of these entities are responsible for the obligations of the other entities.  These entities include: Lazybones Laundry & Cleaning Services Inc., Lazybones Laundry & Storage Inc., Colorado Lazybones, Inc., Lazybones of Delaware LLC, Paquete LPB LLC. (collectively the “Companies”).  You understand that your order for Services will be between you and one of the Companies, and that your order will be governed by these Terms. </p>
            <p align="justify">By using the pick-up, storage and delivery services (the “Services”) offered by the Companies, you must utilize the website, mylazybones.com (the “Website”), or directly contact customer service at (877) 215-2105. </p>
            <p align="justify">Please read these Terms carefully, as they are legally binding on you even if you are browsing the Website without requesting any storage quotes or contracting with the Companies.  These Terms are in addition to, and not in lieu of, any other conditions outlined on the Website or agreement(s) you may enter into with the Companies.  In the event of conflict between these Terms and any other condition outlined on the Website or agreement(s), these Terms shall control.  Lazybones may revise the Terms from time to time when it is appropriate or necessary, without prior notice, and such revisions shall be applicable when first set forth on the Website.  Please check the Website periodically to take notice of any changes to the Terms.  If you do not want to accept and agree to these Terms, you must exit the Website and not utilize the Services.  Your continued use of the Website with the revised Terms shall constitute your acceptance of the Terms and any subsequent changes thereto.</p>
        </div>


        <div className="mt-2">
            <p>
                <strong>II. <u>USER RESPONSIBILITIES</u></strong>
            </p>
            <p align="justify">By using this Website, you represent, warrant and agree that: You are at least 18 years of age and of full capacity to form a legally binding contract.  You will provide valid payment information when necessary and pay all outstanding charges when due.  You will not file any chargeback or claim without first attempting to resolve the dispute informally by contacting customer service in writing.  Your acceptance of these Terms does not and will not violate any other agreement to which you are bound, or any law, rule, regulation, order or judgment to which you are subject.  You are solely and exclusively liable for your conduct via the Website.</p>
        </div>

        <div>
            <p>
                <strong>III. <u>STORAGE POLICY</u></strong>
            </p>
            <p align="justify">The Services shall include pickup, storage, and delivery of the Customer’s items (the “Item” or “Items”) to a destination within a five (5) mile radius of the campus location at which the Items are picked up.  As used herein, the phrase “Item” shall mean and refer to a Storage Container (as that term is defined in Section VI hereof).  The Items shall be stored at a climate controlled facility selected at the sole discretion of the Companies.  A climate controlled storage facility is defined as a storage facility that maintains a safe temperature for the storage of possessions and is effectively ventilated.  The Customer understands that this does not guarantee that the storage facility is air-conditioned or heated, and Customer acknowledges and agrees that none of the Companies bear any responsibility for items that are damaged while in storage due to the temperature in the storage facility.</p>
            <p align="justify">You understand and agree that the summer storage period included in pricing for storage of Items is a four (4) month period (typically May-August).  Should the stored Items remain stored longer than the summer storage period for any reason, and not delivered by September 15th you shall be charged 15% of your invoice for each month thereafter that the Items remain in storage.  In the event Items are removed prior to the end of a full month, the 10% charge will NOT be prorated, and must be paid in full prior to release of the Items.</p>
            <p align="justify">You understand and agree that the semester abroad storage period included in pricing for storage of Items is an eight (8) month period (typically December-August).  Should the stored Items remain stored longer than the summer storage period for any reason, and not delivered by September 15th you shall be charged 10% of your invoice for each month the Items remain in storage.  In the event Items are removed prior to the end of a full month, the 10% charge will NOT be prorated, and must be paid in full prior to release of the Items.</p>
            <p align="justify">For Items placed in storage, the Companies reserve the right to assume ownership of any abandoned Items.  An Item becomes “abandoned” for purposes of the foregoing sentence if (i) the Item is not delivered or is not picked up by you by September 15th, and (ii) you fail to pay  the additional 10% fee.</p>
        </div>

        <div>
            <p>
                <strong>IV. <u>PICKUP POLICY</u></strong>
            </p>
            <p align="justify">Pricing includes room-to-room pickup.  Each of the Companies must adhere to any University or school policies.  If the University or school denies any of the Companies access to come up to your room, you must deal with the University or school directly to resolve such an issue.  Prior to a pickup of your Items (a “Pickup”), you must set a time and date (a “Pickup Time”) within a window of Pickup Times determined by the Companies.  You agree to be present at the designated Pickup location during the Pickup Time.</p>
            <p align="justify">You agree and acknowledge that failure to cancel or adjust the Pickup Time within 48 hours of the scheduled Pickup Time shall forfeit your storage deposit.</p>
        </div>

        <div>
            <p>
                <strong>V. <u>DELIVERY POLICY</u></strong>
            </p>
            <p align="justify">You will incur a $35 scheduling fee if you do not schedule a delivery time and location (the “Delivery”) for your Items prior to August 1st.  There will be no scheduling fee if the Delivery is scheduled prior to August 1st.  Customers may schedule deliveries on-line or by phone by calling customer service. You agree to be present at the Delivery.  If you are not present at the Delivery, you will incur a $50 missed delivery fee.  If you are not present for the Delivery, you agree and acknowledge that a roommate or other person present at the Delivery may sign for the Items to indicate that the Items were delivered in acceptable condition, and that such signature relieves each of the Companies for any and all liability for claims of damage, lost or missing Items, or any other claim related to the storage of your Items.  In advance of the Delivery, you may also permit the Companies, via written correspondence, to leave the Items in any location you specify, provided, however, that if you do so and the Items are delivered in accordance with your instructions, you agree that each of the Companies are relieved of any and all liability for claims of damage, lost or missing Items, or any other claim related to the storage of your Items.</p>
            <p align="justify">Some Universities/Schools have “Early Deliveries” (also referred to herein as “Early Delivery”).  Early Deliveries are agreements with the University/School, Landlords, and some Greek Housing to have stored Items delivered prior to you returning to campus.  The Companies may work with the University/School, Landlords and some Greek Housing directly, and may complete the Early Delivery <strong><u>UNLESS</u></strong> the Customer notifies customer service in writing otherwise.  The University/School, Landlords and some Greek Housing work directly with the Companies to update the delivery address.  Once the Items are delivered to the University/School, Landlords or Greek Housing, the Companies will no longer be liable for such Items.</p>
            <p align="justify">You acknowledge that the Delivery must be within a five (5) mile radius of the campus location at which the Items were picked up.  You may be subject to additional fees if the Delivery exceeds the foregoing five (5) mile radius. In the event such a charge applies, the charge will be  $50 per hour spent making the deliver (with a minimum of one hour charged).</p>
            <p align="justify">You must provide notice of a request for access to the Items or the Delivery of the Items at least five business (5) days prior to the Delivery.  Modification of the original Delivery date may result in additional fees pursuant to the Pricing and Fees section of these Terms.</p>
        </div>

        <div>
            <p>
                <strong>VI. <u>INSURANCE</u></strong>
            </p>
            <p align="justify">Boxes, trunks, duffels, suitcases, plastic bins, and wash/dryclean and store boxes (each a “Storage Container”) are automatically insured for $50 each due to the negligence of the Companies.  Poster tubes, lamps, and desk chairs are automatically insured for $30 each due to the negligence of the Companies.  This $50/$30 amount is <strong><u>per Item</u></strong> stored, <b>NOT</b> for individual items contained within a Storage Container, and you acknowledge and agree that <strong><u>individual items</u></strong> contained within a Storage Container are not individually covered for $50/$30.</p>
            <br/><br/>
            <p align="justify">
                <strong>Additional Storage Insurance</strong>
                <br/>
                You may purchase additional insurance coverage where the replacement value of your stored Item is greater than $50.  You may not purchase additional insurance coverage for more than $2,000 for any single Item.
                <br/><br/>
                Each additional $100 in declared value may be purchased for $1 of Additional Charges.  The cost of additional insurance coverage may also be found on the Website.  For example, an Item with a $500 declared value will incur an additional charge of $5.
                <ol>
                    To purchase additional insurance coverage for storage you must:
                    <li>Write the declared value on the Initial Receipt (defined below); or</li>
                    <li>Emailing customer service and adjusting the declared amount.  HOWEVER, additional insurance is NOT official until the customer receives the Official Receipt (defined below) with the new declared amounts displaying. </li>
                </ol>

                <br/>
                Any receipts NOT displaying the additional insurance will be subject to a maximum of the $50 declared value coverage. 
                <br/><br/>
                Claims or losses not reported within the forty-eight hours (48) immediately following the Delivery will be waived, and you shall have no further recourse against the Companies.  All properly completed and timely submitted claims will be processed, and a response will be issued within thirty (30) days of receipt of such claim.  Upon approval of the claim, the lesser of the following amounts will be disbursed:
                <ol>
                    To purchase additional insurance coverage for storage you must:
                    <li>The cost of reasonably restoring the Item to its original condition;</li>
                    <li>The actual replacement value of the Item; or</li>
                    <li>The declared value for the Item.</li>
                </ol>

                <br/><br/>
                Insurance may be added at pickup or up to 14 days after pickup (unless otherwise stated herein), provided that any damage incurred prior to obtaining insurance will not be covered.  A Customer who has their Items stored for less than 14 days must have additional insurance added 2 days prior to delivery.  Each of the Companies has its own storage insurance and each is responsible for claims made as a result of its individual actions or lack thereof.  It is up to either the individual company or its insurance carrier to determine whether to make payment of a customer claim, and you agree that the other Companies are not responsible for any such claims.  You must show either the Initial Receipt (defined below), the Official Receipt (defined below), or such other documentation evidencing additional insurance and payment therefore to prove the additional insurance amount.
                <br/><br/>
                To the extent you are seeking insurance coverage in excess of $1,000 (meaning the declared value of an item), the Companies reserve the right to inspect the Item and you agree to show the Item upon request, both before packaging the Item and upon delivery of the Item to you.  If at the time of pick-up, the company determines that the Item is not worth the amount of requested insurance, the company may decline to pick-up the Item.
                <br/><br/>
                <strong>
                    If you fail to purchase additional insurance, you acknowledge and agree that you forfeit any claims for damaged or lost Items in excess of the automatic $50/$30 amounts set forth above, and you acknowledge and agree that the Companies will not have any liability therefore in excess of the $50/$30 amounts set forth above.
                </strong>
                <br/><br/>
                The basic and additional insurance for stored Items provides coverage against damage or loss of the Customer's stored Items. 
                <ol>
                    <strong>Insurance does not cover:</strong>
                    <li type="a">Jewelry, coins, and collectibles;</li>
                    <li type="a">Cash;</li>
                    <li type="a">Damage to electronic equipment if there is no evidence of physical damage or breakage to the outside packaging container;</li>
                    <li type="a">Items of intangible value (e.g., sentimental);</li>
                    <li type="a">Extremely fragile items (e.g., mirrors);</li>
                    <li type="a">Improperly packed items.  Proper packing is judged based on UPS standards;</li>
                    <li type="a">Concealed damage. Concealed damage refers to any damage that occurs to items inside a container (e.g. suitcases, boxes, trunks, etc.) when there is no evidence of damage to the container itself. Concealed damage is the result of improper packing as opposed to mishandling, and therefore will not be insured;</li>
                    <li type="a">Unpacked items, including furniture that is not wrapped and protected.  If you purchase insurance on furniture, it only covers major damage or loss, not small scratches and paint chipping.  Insurance on furniture <b>MAY ONLY</b> be purchased at the time of pickup;</li>
                    <li type="a">Minor damage due to normal handling (including but not limited to scratches, nicks, and cuts);</li>
                    <li type="a">Damage to storage containers (e.g., suitcases, boxes, trunks, etc.);</li>
                    <li type="a">Damage due to natural disasters, and any and all loss or damages occurring while the Items are not in the possession of the Companies.</li>
                    <li type="a">Damage caused to assembled furniture - <b>Particle board furniture cannot be insured</b> against damage of any kind because it is not designed to be moved.  Almost all IKEA furniture is particle board.  Such items will be handled with care items, but they are stored entirely at YOUR risk.</li>
                </ol>
                <br/>
                All claims, including, but not limited to claims for damaged or lost items must be made within 48 hours of Delivery.  In the case of an Early Delivery, claims must be made within 48 hours of arrival to University/School, residence hall, Landlord, Greek Housing, or apartment (you are responsible for providing acceptable proof of time of actual arrival to the Companies, i.e. plane ticket, affidavit, etc.). 
                <br/><br/><br/>
                <strong>Shipping Coverage and Damage</strong>
                <p>
                You understand that the Companies ship your items through third party shippers (such as UPS, DHL, FEDEX, etc.).  You release the Companies of all liability for damage or loss done to any Items or any of an Item’s contents before, during, or after shipping.  You will be provided with the tracking information once the shipped Items has been processed.  You understand that the automatic $100 in declared value coverage provided to you is no longer applicable as soon as your Items have been released to a third party shipper.  Any additional insurance coverage you desire for shipping MUST be indicated on the Initial Receipt at the time of Pickup.  Declared value for shipped Items CANNOT be changed after the Pickup.  Any shipped Items that are damaged or lost, will be handled through the third party shipper and you agree to all of the rules, regulations, and claims requirements of the third party shipper.  You are responsible for packing the box (or shipping container) correctly.  The Companies are NOT responsible for boxes (or shipping containers) packed incorrectly.  All claims will be handled by third party shipping provider and the total amount to be reimbursed is at the third party shipper’s discretion and in accordance with the third party shipper’s rules.  In the case of loss or damage to your shipped Items, the following steps must be followed:
                    <ol>
                        <li>You must take pictures of your package both outside and inside of the damage your Items sustain.  <strong><u>YOU MUST KEEP THE BOX (OR SHIPPING CONTAINER) AND MERCHANDISE – DO NOT DISCARD THE DAMAGED ITEMS OR THE BOX (OR SHIPPING CONTAINER)</u></strong>.  If you discard the damaged items or the box (or shipping container), your claim with the third party shipper will be rejected regardless of who is at fault.</li>
                        <li>You must keep all protective materials, such as bubble wrap, foam, etc., and take pictures of these materials as well.</li>
                        <li>You must submit your claim to the third party shipper directly and independently of any of the Companies, and send a copy of your claim to customer service by email.  Once submitted to the third party shipper, you must communicate directly with the third party shipper.</li>
                    </ol>
                </p>
            </p>
        </div>

        <div>
            <p>
                <strong>VII. <u>PACKING AND PROTECTION OF ITEMS</u></strong>
            </p>
            <p align="justify">You shall read, accept, and follow the packing tips as listed on the Website.  You agree that the packing of Items is the sole responsibility of you.  Items damaged due to improper packing will not be covered by insurance, any of the Companies, or any third party shipping agents.</p>
        </div>

        <div>
            <p>
                <strong>VIII. <u>PAYMENT POLICY</u></strong>
            </p>
            <p align="justify">You shall pay for the Services via credit card.  Once entered, the Companies do not have access to your personal financial information, except that financial information will be held encrypted with our merchant services provider.  At the time you reserve your storage space, you will be charged a $35 deposit, which will be applied towards your storage order.  For example, if you have a $200 storage invoice, you will only owe an additional $165 at the timer your Official Receipt is emailed to you.</p>
            <p align="justify">Storage deposits are refundable up to 48 hours before your scheduled Pickup.  If you cancel your storage reservation within 48 hours of your scheduled Pickup, you forfeit your storage deposit.  Any deposits for storage and storage services not used after September 1st are not refundable. After the Pickup of your Items, you will receive and sign an ‘Initial Receipt’ listing all the Items that were picked up.  The Initial Receipt will be reviewed and you will receive confirmation (the “Official Receipt”) that all Items listed on the Initial Receipt were picked up.  The Companies reserve the right to correct any employee error prior to sending out the Official Receipt, which will come in the form of an email within five (5) days of the pickup.  You will have seven (7) days from the time of receipt of the Official Receipt (measured from the date and time the emailed Official Receipt was sent to you (not from the date and time you opened the email)) to review the Official Receipt and contact your respective customer service with any questions or disputes regarding the contents of the Official, after which time all such disputes shall be waived.  Your credit card will be charged the balance due once the Official Receipt is emailed to you.</p>
            <p align="justify">In the event that incorrect credit card information is given or payment processing is rejected in any way, you shall remain responsible for payment of all Fees until you pay the Fees in full.  Should you fail to pay the remaining balance, the Companies reserves the right to retain such your Items until full payment, including any additional fees, is received.  You understand and acknowledge that delayed payments may cause the delay of the Delivery, as well as the assessment of additional fees due to rescheduling and/or non-payment.</p>
            <p align="justify">Balances that are unable to be collected and are not paid when due may result in the holding of your Items as collateral.  If payment is not made during the time periods set forth herein (see Section III above), your Items may be sold, donated, or otherwise disposed of if you fail to pay the balance due.  You acknowledge that your failure to be present at the Delivery and your subsequent failure to communicate with customer service concerning an alternate arrangement for the Delivery shall constitute the abandonment of the Items.  The Companies shall make reasonable attempt to contact you.  If you fail to respond to such communication and more than two weeks has passed after the scheduled Delivery, the Companies reserve the right to dispose of the Items as the Companies see fit and/or to continue to charge you storage fees as set forth herein.</p>
        </div>


        <div>
            <p>
                <strong>IX. <u>PRICING AND FEES</u></strong>
            </p>
            <p align="justify">
            Pricing for all Services shall be in accordance with the price list on the Website.  The Fees listed are for each Storage Period: (1) Summer Storage (May - August), or (ii) Semester Abroad Storage (December - August).  In addition to such Fees, various Additional Charges may apply as described in these Terms.  You agree to pay any and all of the Additional Charges applicable to you. Additional Charges are intended to compensate for the additional expenses incurred as a result of the deviation from standard procedures and practices of the Services.  The following is a non-exhaustive list of Additional Charges:
            </p>
            <p align="justify">
                <u>Unthawed Fridge</u> – There shall be an additional fee of $25 for any refrigerator not completely thawed out prior to Pickup.
            </p>
            <p align="justify">
                <u>Rescheduling</u> – You shall pay $25 per rescheduling if notice is not given at least 48 hours prior to the Pickup Time.
                <br/>
            </p>
            <p align="justify">
                <u>Missed Delivery</u> – You shall pay a $50 missed delivery fee if you are not present for your scheduled Delivery.
                <br/>
            </p>
            <p align="justify">
                <u>Delivery Radius</u> – If the Delivery location is outside of the five (5) mile radius from where the Items were picked up, you shall pay $50 per hour spent delivering the Items by the relevant Company.(with a one hour minimum).
                <br/>
            </p>
            <p align="justify">
                <u>Disposal of Items</u> – In the event that you do not wish to have the Items returned, you shall pay $15-25 per Item (depending on size) for the handling and disposal of the Item.  Said determination shall be made solely and exclusively by the Company which picked up your Items.
                <br/>
            </p>
            <p align="justify">
                <u>Scheduling Fee</u> – Storage deliveries not scheduled by August 1st will be charged a $35 scheduling fee.
                <br/>
            </p>
            <p align="justify">
                <u>Outstanding Balance</u> – Items will not be delivered until payment is made in FULL.  There will be a $35 processing fee if Items are not paid in full prior to scheduling for Delivery. 
                <br/>
            </p>
            <p align="justify">
                <u>Shipping Pricing</u><br/>
                <p>When our pick-up crews receive your boxes for shipping, they do not calculate your charges, nor do they give advice re: whether or not it is a good idea to ship your belongings. All items picked up are sent to our warehouse for labeling and shipping through UPS, then we apply the charges to your card on file and send you an invoice with tracking labels.</p>
                <p>UPS charges are based on dimensional weights and include a fuel surcharge. Go UPS.com to estimate your potential shipping charges.   Be sure to enter the box dimensions as well as the weight.  If you only enter the weight it will not calculate the price correctly.  You must enter the length at its longest, the width at its widest and the height at its tallest point. This is especially true for duffel bags. Please enter the following information:</p>
                Your campus zip code<br/>
                Box dimensions (length, width, height) <br/>
                Note that our box dimensions signify INTERIOR dimensions. When prompted to enter box size, you must add 1” to every side for UPS specs, based on EXTERIOR dimensions. For example an 18x18x24 box, should be entered as a 19x19x25 box<br/>
                Estimated weight of box<br/>
                Indicate payment at a retail location<br/>
                Once you have obtained an estimate from the UPS website, remember that our prices will also include a 20% service charge, as with any other retail location. This charge will include our cost for labor, transport and handling of your items. Please note, shipping costs are separate from storage costs. These are two separate services. 
                <br/>
            </p>
        </div>

        <div>
            <p>
                <strong>X. <u>COMMUNICATION</u></strong>
            </p>
            <p align="justify"><b>Contact Information.</b> To contact Lazybones with any questions regarding our policies and Terms and Conditions, please email us at <b>wecare@mylazybones.com</b>.</p>
        </div>

        <div>
            <p>
                <strong>XI. <u>OTHER TERMS</u></strong>
            </p>
            <p align="">
                <ol>
                    <li><u><b>Compliance with Laws</b></u> – At all times the Parties hereto shall be responsible for maintaining compliance with all local, state, and federal laws.</li>
                    <li><u><b>Waiver</b></u> – The failure of either party at any time or times to demand strict performance by the other of any terms, covenants, or conditions set forth herein shall not be construed as a waiver or relinquishment thereof and each party may at any time demand strict and complete performance by the other of said terms, covenants and conditions.</li>
                    <li><u><b>Assignment</b></u> – Neither of the Parties may assign the rights granted hereunder without the written consent of the other, except that the Parties agree that the Companies may utilize the services of third party shippers.  Any assignment in violation of this section shall be null and void.</li>
                    <li><u><b>Entire Agreement</b></u> – These Terms, together with any other terms set forth on the Website constitute the entire Agreement between the parties relating to the subject matter hereof.</li>
                    <li><u><b>Integrated Agreement</b></u> – These Terms constitutes the complete and exclusive statement of the agreement between the Parties, and supersedes all prior agreements, proposals, communications, and negotiations between the Parties, both written and oral, regarding the subject matter hereof.</li>
                    <li><u><b>Nature of Relationship</b></u> – These Terms and the relationship of the Parties, does not constitute and shall not be construed as constituting a partnership, joint venture, or franchise between the Parties.  Neither party shall have any right to obligate or bind the other party in any manner whatsoever, except as set forth in these Terms, and nothing herein contained, shall give or intend to give, any rights of any kind to any third parties.</li>
                    <li><u><b>Severability</b></u> – If any provision of this Agreement is held to be unenforceable or illegal by a court of competent jurisdiction, such provision shall be severed from this Agreement, and all other provisions of this Agreement shall remain in full force and effect.</li>
                    <li><u><b>Indemnity</b></u> – You agrees to defend, indemnify, and hold harmless the Companies and their officers, directors, members, managers, employees, attorneys, agents and affiliated persons and entities, successors and assigns (“Indemnities”) from any claim, action, liability, loss, damages, lawsuit or any other proceeding arising from or related in any way to the provision of Services, including, but not limited to customer complaints, property damage or loss, or any lawsuits or arbitration proceedings brought by you, or any conduct by you that results in such lawsuits or arbitration proceedings (“Claims”).  In the event that any Claims are asserted against Indemnities, Indemnities shall provide you with written notice of same, and thereafter you shall at your own expense defend, indemnify, and hold harmless Indemnities against said Claims or any loss or liability thereunder.  In the further event you shall fail to so defend, indemnify and hold Indemnities harmless, then in such instance Indemnities shall have full rights to defend, pay or settle said Claims on its behalf without notice to you and with full rights to recourse against you for all fees, costs, expenses, including, but not limited to, reasonable attorneys fees, and payments made or agreed to be paid to discharge said claims.<br/><br/>Upon your default of the obligations imposed by this paragraph, you further agree to pay all reasonable attorney’s fees necessary to enforce this Terms.  These Terms shall be binding upon and inure to the benefit of the Parties, their successors, assigns and personal representatives, and shall survive termination and cancellation of these Terms.</li>
                    <li><u><b>Limitation of Liability</b></u> – IN NO EVENT SHALL THE COMPANIES, THEIR OFFICERS, MEMBERS, MANAGERS, DIRECTORS, EMPLOYEES, ATTORNEYS, AGENTS, AFFILIATED ENTITIES, BE LIABLE TO YOU FOR ANY DIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, ARISING OUT OF THIS AGREEMENT OR THE PERFORMANCE THEREOF, WHETHER BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, EVEN IF SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILTY OF SUCH DAMAGES.  Furthermore, notwithstanding any of the terms of this Agreement, under no circumstances shall THE COMPANIES monetary liability for any cause of action whatsoever exceed the amounts PAID YOU TO THE COMPANIES.</li>
                    <li><u><b>Governing Law and Venue</b></u> – This Agreement and any and all disputes hereunder shall be governed in accordance with the law of the place where the services are delivered, without regard to conflict of laws principles, and, as necessary the laws of the United States of America.  You agree that any action or proceeding involving any dispute arising out of or in connection with these Terms or the storage/return of your Items shall be brought only in either (a) the United States District Court for the District where the Services are provided, or (b) any appropriate state court located where the Services are provided, and for which purpose you irrevocably consent and submit to the exclusive jurisdiction and venue of such courts in respect of any such proceeding.  You expressly waive the right to a trial by jury of any dispute arising out of or in connection with these Terms or the storage/return of your Items.</li>
                    <li><u><b>Further Assurances</b></u> – You agree that you shall, at the reasonable request of any of the Companies, execute and deliver such documents, certificates, and other writings, and take such other actions as may be necessary or desirable, to accomplish the goals of these Terms and the storage/return of your Items.</li>
                    <li><u><b>No Implied License</b></u> - Nothing in these Terms shall be construed as granting any right or license under any intellectual property rights of the Companies by implication, estoppel, or otherwise, except as expressly set forth in these Terms.</li>
                    <li><u><b>No Adverse Construction </b></u> - You agree that in the event of any ambiguity in these Terms, there will not be any adverse construction against any entity or individual on the grounds that they drafted these Terms.</li>
                    <li>
                        <p>
                            <u><b>Notices</b></u> - All notices or other communications under these Terms shall be sufficiently given and shall be deemed given immediately when sent by electronic mail at the address(es) listed below, or, if sent by regular mail, within three business days after being sent by regular mail to the address(es) set forth below:
                            <br/><br/>
                            <ol>
                                <li type="a">To Companies:<br/>
                                Lazybones Laundry and Storage, Inc.<br/>
                                1 LePage Place, Building B<br/>
                                Syracuse, NY 13026<br/><br/>

                                and<br/><br/>
                                wecare@mylazybones.com
                                <br/><br/>
                                </li>
                                <li type="a">To you: <br/><br/> The email address you provided when you signed up for the Service.</li>
                            </ol>
                        </p>
                    </li>
                    <li><u><b>Authority</b></u> – You and warrant that you have the full right, power, and authority to enter into and abide by these Terms.</li>
                    <li><u><b>Force Majeure</b></u> – The Companies shall not be liable for any failure to perform any of their obligations hereunder during any period in which such performance is delayed by fire, flood, earthquake, tornado, other natural disaster, war, embargo, riot or the intervention of any government authority, provided that the respective company that is unable to perform notifies the you of such inability. </li>
                </ol>
            </p>
            
        </div>
    </>
    
    constructor(){
        super();

        this.state = {
            stateList:[{ value: "", label: "", isDisabled: true }],
            dormitoryList:[{ value: "", label: "", isDisabled: true }],
            dormitoryObjectList:"",
            collapses:[1],
            displayModifyPickupScheduleCollapsibleAreaFlag:false,
            data:{
                pickupAddress:{
                    pickupFromYourCurrentAddress:true,
                    isDormitory:"",
                    dormitoryName:"", //{value:"", label:""} this corresponds to the react-select value setup
                    dormitoryRoomNumber:"",
                    streetNumber:"",
                    addressLine1:"",
                    addressLine2:"",
                    apartmentNumber:"",
                    zoneId:"",
                    city:"",
                    state:"",
                    postalCode:"",
                    country:"USA", 
                },
                pickupDay:"",
                pickUpStartTime:"",
                pickUpEndTime:"",
                pickUpTimeId:"",
                pickupTimeslotList:[],
                storageEstimateQuestionMoreThan10Items:"",
                storageEstimateQuestionHaveLargeItems:"",
                storagePickupSchedulingNotes:"",
                acceptTermsAndConditions:"",
            },
            errors:{},
            
            
        }
    }

    async componentDidMount(){
        const stateList = getUSStateList();
        const {user,storageOrder:storage} = this.props
        const {campus_id:school_id} = user;
        const school = {id:school_id};
        const data = {...this.state.data}

        if(storage && storage.nextPickupDeliverySchedule && storage.nextPickupDeliverySchedule.pickup){
            const pickup = storage.nextPickupDeliverySchedule.pickup;
            data.storagePickupSchedulingNotes = pickup.storageScheduleNote;
            
        }

        const dormitoryList = await SchoolService.getDropdownListOfDormitoryBySchool(school);
        const dormitoryObjectList = await SchoolService.getListOfDormitoryBySchool(school);
        
        this.setState({
            stateList,
            dormitoryList,
            dormitoryObjectList,
            data,
        });
    }

    changeCollapse = collapse => {
        const state = {...this.state};
        
        const collapses = state.collapses;
        let updatedCollapses = "";
        if (collapses.includes(collapse)) {
          updatedCollapses = collapses.filter(prop => prop !== collapse)
          
        } else {
          updatedCollapses  = [...collapses, collapse]
        }

        updatedCollapses = (updatedCollapses.length === 0) ? [this.collapseArea.screen1]:updatedCollapses;

        state.collapses = updatedCollapses;
        
        //state.collapses = [collapse];
        this.setState(state);
    }


    enableSaveButton= ()=>{
        let enable = false;

        const { 
            pickupDay,
            pickUpTimeId,
            storageEstimateQuestionMoreThan10Items,
            storageEstimateQuestionHaveLargeItems,
            acceptTermsAndConditions,
        } = this.state.data

        enable = (
            pickupDay &&  pickUpTimeId &&   storageEstimateQuestionMoreThan10Items !== "" && 
            storageEstimateQuestionHaveLargeItems !== "" && acceptTermsAndConditions === true
        ) ? true:false;
        
        return enable;
    }

    getFormattedUserAddress = (user)=>{
        const {line1:addressLine1,line2:addressLine2} = UserService.getFormattedUserAddress(user);
        return <>{addressLine1} <br/> {addressLine2}</>;
    }

    getPickupDate = ()=>{
        let pickupDate = "";
        const {storageOrder:storage} = this.props;
        if(storage && storage.nextPickupDeliverySchedule && storage.nextPickupDeliverySchedule.pickup){
            const {pickup:pickupObject} = storage.nextPickupDeliverySchedule;
            const {displayDate,startTime,endTime} = pickupObject;
            pickupDate = `${displayDate} ${startTime} - ${endTime}`;
        }
        
        return pickupDate ;
    }


    handleChange = ({currentTarget:input})=>{
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[input.name] = input.value;
        this.setState({data,errors});
        
    }

    handlePickupDay = (pickupDayMoment)=>{
        const data = {...this.state.data}
        data.pickupDay = pickupDayMoment;
        data.pickUpStartTime = "";
        data.pickUpEndTime = "";
        data.pickUpTimeId = "";
        
        const { storageOrder } = this.props
        if(storageOrder){
            const {storagePickupDeliveryTimeslot} = storageOrder;
            const {pickup:pickupList} = storagePickupDeliveryTimeslot;
            const pickupDayMomentToCompare = pickupDayMoment.clone().utc();
            const pickupTimeslotListObject = pickupList.find(p=>pickupDayMomentToCompare.isSame(moment(p.date).utc(),'day'))
            data.pickupTimeslotList = pickupTimeslotListObject.timeslot;
        }
        
        this.setState({data});
    }

    handleChangeAddress = (e,addressType)=>{
        const {name,value} = e.currentTarget;
        //console.log(`${name} : ${value}`);
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        
        if(name === "isDormitoryPickupAddress")
            address.isDormitory = value 
        else
            address[name] = value;

        data[addressType] = address
    

        this.setState({data});
    }

    setAcceptTermsAndConditions = (e,value)=>{
        e.preventDefault();
        const data = {...this.state.data};
        data.acceptTermsAndConditions = value
        this.setState({data});
    }

    setAddressDormitory = (valueObject,addressType)=>{
        const {dormitoryObjectList,stateList} = this.state; 
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        address.dormitoryName = valueObject;

        //Get the dorm object by selected dorm name to populate the city state zip
        const selectedDormitoryObject = _.find(dormitoryObjectList,{"id":valueObject.value});
        //console.log(selectedDormitoryObject);
        
        const selectedStateObject = _.find(stateList,{"value":selectedDormitoryObject.state});
        
        address.city = selectedDormitoryObject.city;
        address.state = selectedStateObject;
        address.postalCode = selectedDormitoryObject.zip;
        
        data[addressType] = address;
        this.setState({data});
    }

    setAddressState = (value,addressType)=>{
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        address.state = value;
        data[addressType] = address;
        this.setState({data});

    }

    setPickupTime = (pickupTimeslotObject)=>{
        //console.log(pickupTimeslotObject);
        const data = {...this.state.data};
        const {storage_scheduling_id:pickUpTimeId,start_time:pickUpStartTime,end_time:pickUpEndTime} = pickupTimeslotObject
        data.pickUpStartTime = pickUpStartTime;
        data.pickUpEndTime = pickUpEndTime;
        data.pickUpTimeId = pickUpTimeId;
        
        this.setState({data});
    }

    setStorageEstimateQuestionMoreThan10Items = (e,value)=>{
        e.preventDefault();
        const data = {...this.state.data};
        data.storageEstimateQuestionMoreThan10Items = value
        this.setState({data});
    }

    setStorageEstimateQuestionHaveLargeItems = (e,value)=>{
        e.preventDefault();
        const data = {...this.state.data};
        data.storageEstimateQuestionHaveLargeItems = value
        this.setState({data});

    }

    saveForm = (e)=>{
        e.preventDefault();
            
        const errors = this.validate();
        this.setState({errors: errors || {}});
        if(errors){ scrollTop('storagePickupScheduleModal'); return;}
        
        const data = {...this.state.data}
        this.props.handleSaveOperation(data);
        return;
    }

    setDisplayModifyPickupScheduleCollapsibleArea = flag=>{
        this.setState({
            ...this.state,
            collapses:[this.collapseArea.screen2],
            displayModifyPickupScheduleCollapsibleAreaFlag:true
        })
        
    }

    setPickupFromYouCurrentAddress = (flag,addressType)=>{
        const data = {...this.state.data}
        const address = {...this.state.data[addressType]}
        address.pickupFromYourCurrentAddress = flag;
        data[addressType] = address;
        this.setState({data})
    }

    validate = ()=>{
        try{
            let data  = {...this.state.data};
            
            const options = {abortEarly:false,allowUnknown:true};
            let {error:validationErrorList} = this.validationSchema.validate(data,options);
            
            const validationErrors = {};
            if(validationErrorList){
                console.log(validationErrorList.details);
                validationErrorList.details.map(
                    function(errorObject){
                        //console.log(errorObject);
                        if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                            const key = errorObject.context.key;
                            const joiType = errorObject.type;
                            //console.log(this.errorMessages);
                            if(_.has(this.errorMessages, [key, joiType]))
                                return validationErrors[errorObject.path[0]] = this.errorMessages[key][joiType];
                            else
                                return validationErrors[errorObject.path[0]] = errorObject.message;
                        }
                        else if(_.has(errorObject, ['context', 'peers']) &&  _.has(errorObject, ['type'])){
                            const joiType = errorObject.type;
                            if(joiType === "object.missing")
                                return validationErrors[errorObject.path[0]] = this.errorMessages.minimumFieldsMessage.message;
                        }
                        else
                          return validationErrors[errorObject.path[0]] = errorObject.message;
                    }.bind(this)
                );
            }

            const errors = {...validationErrors} 
        
            //console.log(errors);
            return Object.keys(errors).length > 0 ? errors:null;
        }catch(ex){
            console.log(ex);
        }

    }

    

    render(){
        const {collapses,data,errors} = this.state;
        
        const { 
            pickupAddress,
            pickupDay,
            pickUpTimeId,
            pickupTimeslotList,
            storageEstimateQuestionMoreThan10Items,
            storageEstimateQuestionHaveLargeItems,
            storagePickupSchedulingNotes,
            acceptTermsAndConditions,
        } = data

        const {
            pickupFromYourCurrentAddress
        } = pickupAddress;
        

        const { 
            handleScheduleModalDisplay,
            //listOfOneTimePickupDates,
            scheduleModalDisplay,
            storageOrder,
            user 
        } = this.props

        let listOfPickupDates = [];

        if(storageOrder){
            const {storagePickupDeliveryDateList} = storageOrder;
            listOfPickupDates = storagePickupDeliveryDateList.pickupDates.map(d => moment.utc(d));
        }

        const pickupAddressIsDormitoryCheckedYes = (pickupAddress.isDormitory === "Yes") ? {defaultChecked:true}:{};
        const pickupAddressIsDormitoryCheckedNo = (pickupAddress.isDormitory === "No") ? {defaultChecked:true}:{};
        const pickupAddressIsDormitoryCSS = "form-check-radio";
        
        const pickupAddressInputGenericProperties = {};
        
        const formattedUserAddress = (!_.isEmpty(user)) ? this.getFormattedUserAddress(user):"";
        const formattedDateAndTimeForPickup = this.getPickupDate();

        const pickupAddressAreaCSS = (pickupFromYourCurrentAddress === false) ? `mt-4`: `mt-4 d-none`;

        const currentCollapsibleArea = collapses[0];
        
        //const displayModifyPickupScheduleAreaCss = (currentCollapsibleArea === this.collapseArea.screen2 ) ? "":"d-none";
        const displayModifyPickupScheduleAreaCss = (collapses.indexOf(this.collapseArea.screen2) !== -1) ? "":"d-none";
        
        //const enableSaveButton = this.enableSaveButton();

        return(
            <Modal id="storagePickupScheduleModal" size="lg" isOpen={scheduleModalDisplay} toggle={() => handleScheduleModalDisplay(false)}>
                <div className="modal-header">
                    <button
                        className="close"
                        type="button"
                        onClick={() => handleScheduleModalDisplay(false)}
                    >
                        <span>×</span>
                    </button>
                    <h5
                        className="modal-title text-center"
                        id="exampleModalLabel"
                    >
                        Modify Storage Pickup Schedule
                    </h5>
                </div>
                <div className="modal-body">
                <div aria-multiselectable={true} id="accordion" role="tablist">
                  <Card className="no-transition">
                    <CardHeader
                      className="card-collapse"
                      id="headingOne"
                      role="tab"
                    >
                      <h5 className="mb-0 panel-title">
                        <a
                          aria-expanded={collapses.includes(this.collapseArea.screen1)}
                          className="collapsed"
                          data-parent="#accordion"
                          href="#pablo"
                          id="collapseOne"
                          onClick={e => {
                            e.preventDefault();
                            this.changeCollapse(this.collapseArea.screen1);
                          }}
                        >
                          Your Current Pickup Schedule Details{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </h5>
                    </CardHeader>
                    <Collapse isOpen={collapses.includes(this.collapseArea.screen1)}>
                      <CardBody>
                        <PickupInformationReview
                            storageOrder={storageOrder}
                            user={user} 
                        />
                        
                        <FormGroup row  className="mt-5 justify-content-between">
                            <Col xs={{ size: 2}}>
                                <Button 
                                    className="btn-rounded btn-danger"
                                    onClick={() => handleScheduleModalDisplay(false)}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col xs={{ size: 6}} sm={{ size: 6}} className="text-right">
                                <Button 
                                    className="btn-rounded btn-danger"
                                    onClick={()=>this.setDisplayModifyPickupScheduleCollapsibleArea(true)}
                                >
                                    Proceed to Modify Pickup Schedule
                                </Button>
                            </Col>
                        </FormGroup>
                      </CardBody>
                    </Collapse>
                    <CardHeader
                      className={`card-collapse ${displayModifyPickupScheduleAreaCss}`}
                      id="headingTwo"
                      role="tab"
                    >
                      <h5 className="mb-0 panel-title">
                        <a
                          aria-controls="collapseTwo"
                          aria-expanded={collapses.includes(this.collapseArea.screen2)}
                          className="collapsed"
                          data-parent="#accordion"
                          id="collapseTwo"
                          onClick={e => {
                            e.preventDefault();
                            this.changeCollapse(this.collapseArea.screen2);
                          }}
                        >
                           Modify Pickup Schedule{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </h5>
                    </CardHeader>
                    <Collapse isOpen={collapses.includes(this.collapseArea.screen2)}>
                      <CardBody>

                        {/*Error display */}
                        {!_.isEmpty(errors) &&
                                <FormErrorWidget
                                    errors={errors}
                                />
                        }
                        {/* End of Error display */}

                        {/* Current Campus address setting */}
                        <h6>Your current campus address for pickup:</h6>
                        <Row>
                            <Col xs="6" md="4">
                                Campus Address:	
                            </Col>  
                            <Col xs="6" md="8">   
                            <p>
                                {formattedUserAddress}
                            </p>
                            </Col>
                        </Row>

                        <Row className="mt-2 d-none">
                            <Col xs="4">
                            Pickup from your current Campus Address:	
                            </Col>  
                            <Col xs="3" sm="3" md="3" lg="2">
                                <Button
                                    className="btn-round mr-1"
                                    color="danger"
                                    outline = {(pickupFromYourCurrentAddress === true) ? false:true}
                                    block
                                    type="button"
                                    onClick={(e)=>{this.setPickupFromYouCurrentAddress(true,'pickupAddress')}}
                                >
                                    Yes
                                </Button>
                            </Col>
                            <Col xs="3" sm="3" md="3" lg="2">
                                <Button
                                    className="btn-round mr-1"
                                    color="danger"
                                    outline = {(pickupFromYourCurrentAddress === false) ? false:true}
                                    block
                                    type="button"
                                    onClick={(e)=>{this.setPickupFromYouCurrentAddress(false,'pickupAddress')}}
                                >
                                    No
                                </Button>
                            </Col>
                        </Row>

                        {/* Enter Pickup address */}
                        {   
                                <Row  className={pickupAddressAreaCSS}>
                                    <Col>
                                
                                        <h6>Enter your pickup address:</h6>

                                        <FormGroup row>
                                            <Label for="" xs={6} sm={4} md={4}>Is it a dormitory?</Label>
                                            <Col xs={2}  className="mt-1">
                                                <div className={pickupAddressIsDormitoryCSS}>
                                                    <Label check>
                                                        <Input
                                                            {...pickupAddressIsDormitoryCheckedYes}
                                                            defaultValue="Yes"
                                                            id="pickupAddressIsDormitory1"
                                                            name="isDormitoryPickupAddress"
                                                            type="radio"
                                                            onChange={e=>{this.handleChangeAddress(e,"pickupAddress")}}
                                                        />
                                                        Yes
                                                        <span className="form-check-sign" />
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col xs={2} sm={1} className="ml-2 mt-1">
                                                <div className={pickupAddressIsDormitoryCSS}>
                                                    <Label check>
                                                        <Input
                                                            {...pickupAddressIsDormitoryCheckedNo}
                                                            defaultValue="No"
                                                            id="pickupAddressIsDormitory2"
                                                            name="isDormitoryPickupAddress"
                                                            type="radio"
                                                            onChange={e=>{this.handleChangeAddress(e,"pickupAddress")}}
                                                        />
                                                        No
                                                        <span className="form-check-sign" />
                                                    </Label>
                                                </div>
                                            </Col>
                                            
                                        </FormGroup>

                                        {
                                            (pickupAddress.isDormitory === "Yes") &&    
                                            <FormGroup row>
                                                <Label for="pickUpDormitory" sm={2}>Dorm Name</Label>
                                                <Col xs={10} sm={4}>
                                                    <FormGroup>
                                                        <Select
                                                            className="react-select react-select-default"
                                                            classNamePrefix="react-select"
                                                            name="dormitoryRoomNumber"
                                                            id="dormitoryRoomNumber"
                                                            value={pickupAddress.dormitoryName}
                                                            onChange={value => this.setAddressDormitory(value,"pickupAddress")}
                                                            options={this.state.dormitoryList}
                                                            placeholder="Dorm"
                                                            {...pickupAddressInputGenericProperties}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Label for="addressLine1" sm={2}>Room No.</Label>
                                                <Col xs={10} sm={4}>
                                                    <Input 
                                                    type="text" 
                                                    name="dormitoryRoomNumber" 
                                                    id="dormitoryRoomNumber" 
                                                    placeholder="Room No" 
                                                    maxLength="10"
                                                    value={this.state.data.pickupAddress.dormitoryRoomNumber} 
                                                    onChange={e=>{this.handleChangeAddress(e,"pickupAddress")}}
                                                    {...pickupAddressInputGenericProperties}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        }

                                        {
                                            (pickupAddress.isDormitory !== "Yes") &&   
                                            <FormGroup row>
                                                <Label for="streetNumber" sm={2}>Street #</Label>
                                                <Col sm={4}>
                                                    <Input 
                                                    type="text" 
                                                    name="streetNumber" 
                                                    id="streetNumber" 
                                                    placeholder="Street #" 
                                                    maxLength="6"
                                                    value={this.state.data.pickupAddress.streetNumber} 
                                                    onChange={e=>{this.handleChangeAddress(e,"pickupAddress")}}
                                                    />
                                                </Col>

                                                <Label for="apartmentNumber" sm={2}>Apt #</Label>
                                                <Col sm={4}>
                                                    <Input 
                                                    type="text" 
                                                    name="apartmentNumber" 
                                                    id="apartmentNumber" 
                                                    placeholder="Apt Number" 
                                                    maxLength="10"
                                                    value={this.state.data.pickupAddress.apartmentNumber} 
                                                    onChange={e=>{this.handleChangeAddress(e,"pickupAddress")}}
                                                    />
                                                </Col>
                                                
                                            </FormGroup>
                                        }               
                                            
                                        {
                                            (pickupAddress.isDormitory !== "Yes") &&   
                                            <FormGroup row>
                                                <Label for="addressLine1" sm={2}>Street Name</Label>
                                                <Col sm={10}>
                                                    <Input 
                                                    type="text" 
                                                    name="addressLine1" 
                                                    id="addressLine1" 
                                                    placeholder="Street Name" 
                                                    value={this.state.data.pickupAddress.addressLine1} 
                                                    onChange={e=>{this.handleChangeAddress(e,"pickupAddress")}}
                                                    {...pickupAddressInputGenericProperties}
                                                    />
                                                </Col>
                                                
                                            </FormGroup>
                                        }
                                        {
                                            (pickupAddress.isDormitory !== "Yes") &&  
                                            <FormGroup row className="d-none">
                                                <Label for="addressLine2" sm={2}>Address Line 2</Label>
                                                <Col sm={10}>
                                                    <Input 
                                                    type="text" 
                                                    name="addressLine2" 
                                                    id="addressLine2" 
                                                    placeholder="Address Line 2" 
                                                    value={this.state.data.pickupAddress.addressLine2} 
                                                    onChange={e=>{this.handleChangeAddress(e,"pickupAddress")}}
                                                    {...pickupAddressInputGenericProperties}
                                                    />
                                                </Col>
                                                
                                            </FormGroup>
                                        }    
                                            
                                            
                                        <FormGroup row>
                                            <Label for="city" sm={2}></Label>
                                            <Col xs={12} sm={3}>
                                                <Input 
                                                type="text" 
                                                name="city" 
                                                id="city" 
                                                placeholder="City" 
                                                value={this.state.data.pickupAddress.city} 
                                                onChange={e=>{this.handleChangeAddress(e,"pickupAddress")}}
                                                {...pickupAddressInputGenericProperties}
                                                />
                                            </Col>
                                            <Label for="state" xs={12} sm={2} className="d-block d-sm-none"></Label>
                                            <Col xs={12} sm={4}>
                                                <FormGroup>
                                                    <Select
                                                        className="react-select react-select-default"
                                                        classNamePrefix="react-select"
                                                        name="state"
                                                        id="state"
                                                        value={this.state.data.pickupAddress.state}
                                                        onChange={value => this.setAddressState(value,"pickupAddress")}
                                                        options={this.state.stateList}
                                                        placeholder="State"
                                                        {...pickupAddressInputGenericProperties}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} sm={3}>
                                                <Input 
                                                type="text" 
                                                name="postalCode" 
                                                id="postalCode" 
                                                placeholder="Zip" 
                                                value={this.state.data.pickupAddress.postalCode} 
                                                onChange={e=>{this.handleChangeAddress(e,"pickupAddress")}}
                                                {...pickupAddressInputGenericProperties}
                                                />
                                            </Col>
                                        </FormGroup>

                                        

                                    </Col>
                                </Row>  
                            }
                            
                            {/* End of Enter Delivery address */}
                            
                        
                        {   pickupFromYourCurrentAddress !== "" &&
                            <Row className="mt-3">
                                <Col lg="4">
                                    Select Pickup Date:
                                </Col>
                                <Col lg="4">
                                <FormGroup>
                                    <InputGroup 
                                        name="pickUpDatetimePicker"
                                        className="date" 
                                        id="pickUpDatetimePicker"
                                        
                                    >
                                        <ReactDatetime
                                            value={pickupDay} 
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            onChange={this.handlePickupDay}
                                            inputProps={{
                                                className: "form-control",
                                                placeholder: "Pickup Day",
                                            }}
                                            renderDay={( props, currentDate, selectedDate)=>{
                                                
                                                if(listOfPickupDates.length){
                                                    const currentMomentUtc = moment.utc(currentDate);
                                                
                                                    let highLightDate = false;
                                                
                                                    listOfPickupDates.forEach(d=>{
                                                        if(currentMomentUtc.isSame(d,'day') && moment.utc().isSameOrBefore(currentMomentUtc,'day'))
                                                            highLightDate = true;
                                                    })

                                                    if(highLightDate)
                                                        props.className += " rdtActive color-white fw-strong";
                                                        
                                                }
                                                
                                                
                                                return <td {...props}>{currentDate.date() }</td>;
                                            }}

                                            isValidDate={currentDate=>{
                                                const currentMomentUtc = moment.utc(currentDate);
                                                
                                                let enableDate = false;
                                                listOfPickupDates.forEach(d=>{
                                                    if(currentMomentUtc.isSame(d,'day') && moment.utc().isSameOrBefore(currentMomentUtc,'day'))
                                                        enableDate = true;
                                                })
                                                return enableDate;
                                            }}
                                        />
                                        <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                            <span className="glyphicon glyphicon-calendar">
                                            <i className="fa fa-calendar" />
                                            </span>
                                        </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup> 
                                </Col>
                            </Row>
                        }
                        
                        
                        
                        {   pickupDay && pickupTimeslotList.length &&
                            <Row >
                                <Col lg="4">
                                    Select Timeslots:
                                </Col>
                                <Col lg="8">
                                    <Row>
                                        {
                                        pickupTimeslotList.map(pickupTimeslotObject=>{
                                                return(
                                                    <Col key={pickupTimeslotObject.storage_scheduling_id} xs="6" className="mb-2">
                                                        <Button
                                                            className="btn-round mr-1"
                                                            color="danger"
                                                            outline = {(pickUpTimeId === pickupTimeslotObject.storage_scheduling_id) ? false:true}
                                                            block
                                                            type="button"
                                                            onClick={()=>this.setPickupTime(pickupTimeslotObject)}
                                                        >
                                                            {`${moment(`${pickupTimeslotObject.date} ${pickupTimeslotObject.start_time}`).format('h:mm a')}
                                                            - 
                                                            ${moment(`${pickupTimeslotObject.date} ${pickupTimeslotObject.end_time}`).format('h:mm a')}`
                                                            }
                                                        </Button>

                                                    </Col>
                                                );
                                            })
                                        }
                                        
                                        
                                    </Row>
                                    
                                
                                    
                                </Col>
                            </Row>  
                        }

                        {/* Storage Estimate questions*/}
                        {   pickupDay && pickUpTimeId && 
                            <>
                            <Row>
                                <Col>
                                    <hr/>
                                    <h6>Storage Estimate:</h6>
                                    <Row>
                                        <Col xs="6" lg="8">
                                        Do you expect to have more than 15 total items (including boxes and furniture)?	
                                        </Col>  
                                        <Col xs="3" sm="3" md="3" lg="2">
                                            <Button
                                                className="btn-round mr-1"
                                                color="danger"
                                                outline = {(storageEstimateQuestionMoreThan10Items === true) ? false:true}
                                                block
                                                type="button"
                                                onClick={(e)=>{this.setStorageEstimateQuestionMoreThan10Items(e,true)}}
                                                
                                            >
                                                Yes
                                            </Button>
                                        </Col> 
                                        <Col xs="3" sm="3" md="3" lg="2">
                                            <Button
                                                className="btn-round mr-1"
                                                color="danger"
                                                outline = {(storageEstimateQuestionMoreThan10Items === false) ? false:true}
                                                block
                                                type="button"
                                                onClick={(e)=>{this.setStorageEstimateQuestionMoreThan10Items(e,false)}}
                                                
                                            >
                                                No
                                            </Button>
                                        </Col>  
                                    </Row>
                                    
                                    <Row className="mt-2">
                                        <Col xs="6" lg="8">
                                        Will you have any large items like a couch, desk, dresser or mattress?		
                                        </Col>  
                                        <Col xs="3" sm="3" md="3" lg="2">
                                            <Button
                                                className="btn-round mr-1"
                                                color="danger"
                                                outline = {(storageEstimateQuestionHaveLargeItems === true) ? false:true}
                                                block
                                                type="button"
                                                onClick={(e)=>{this.setStorageEstimateQuestionHaveLargeItems(e,true)}}
                                            >
                                                Yes
                                            </Button>
                                        </Col>
                                        <Col xs="3" sm="3" md="3" lg="2">
                                            <Button
                                                className="btn-round mr-1"
                                                color="danger"
                                                outline = {(storageEstimateQuestionHaveLargeItems === false) ? false:true}
                                                block
                                                type="button"
                                                onClick={(e)=>{this.setStorageEstimateQuestionHaveLargeItems(e,false)}}
                                                
                                            >
                                                No
                                            </Button>
                                        </Col>

                                    </Row>
                                    
                                    
                                </Col>
                            </Row>  
                            <Row>
                                <Col>
                                    <hr/>
                                    <h6>Notes:</h6>
                                    <Row>
                                        <Col>
                                            <p>
                                            Please enter any notes you have for us regarding your storage pickup.
                                            </p>
                                            <Input
                                                className="border-input"
                                                placeholder=""
                                                rows="3"
                                                type="textarea"
                                                maxlength="200"
                                                name="storagePickupSchedulingNotes"
                                                value={storagePickupSchedulingNotes}
                                                onChange = {this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="d-none">
                                <Col>
                                    <hr/>
                                    <h6>Terms and Conditions:</h6>
                                    <Row>
                                        <Col>
                                            <div className="overflow-auto border rounded p-2" style={{height:"150px", backgroundColor:"#EEEEEE"}}>
                                            {this.termsAndConditions}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col xs="6" lg="8">
                                            I accept the terms and conditions
                                        </Col>  
                                        <Col xs="3" sm="3" md="3" lg="2">
                                            <Button
                                                className="btn-round mr-1"
                                                color="danger"
                                                outline = {(acceptTermsAndConditions === true) ? false:true}
                                                block
                                                type="button"
                                                onClick={(e)=>{this.setAcceptTermsAndConditions(e,true)}}
                                                
                                            >
                                                Yes
                                            </Button>
                                        </Col> 
                                        <Col xs="3" sm="3" md="3" lg="2">
                                            <Button
                                                className="btn-round mr-1"
                                                color="danger"
                                                outline = {(acceptTermsAndConditions === false) ? false:true}
                                                block
                                                type="button"
                                                onClick={(e)=>{this.setAcceptTermsAndConditions(e,false)}}
                                                
                                            >
                                                No
                                            </Button>
                                        </Col>  
                                    </Row>
                                    
                                    
                                    
                                    
                                </Col>
                            </Row> 
                            
                            
                            </>
                        }
                        {/* End of Storage Estimate Questions */}


                        <FormGroup row  className="mt-5 justify-content-between">
                            <Col xs={{ size: 2}}>
                                <Button 
                                    className="btn-rounded btn-danger"
                                    onClick={() => handleScheduleModalDisplay(false)}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col xs={{ size: 6}} sm={{ size: 6}} className="text-right">
                                <Button 
                                    className="btn-rounded btn-danger"
                                    onClick={this.saveForm}
                                >
                                    Modify my Pickup Schedule
                                </Button>
                            </Col>
                        </FormGroup>


                      </CardBody>
                    </Collapse>
                    
                  </Card>
                </div>
                    
                    
                    
                </div>
                
                
                
                
            </Modal>
        );

    }
}

export default PickupScheduleModifyModal;