import React from "react"

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Media,
    Container,
    Row,
    Col
} from "reactstrap";

import step1Image from "../../../../assets/mylazybones/img/Storage_HowItWorks/Storage-how-it-works-1.jpg";
import step2Image from "../../../../assets/mylazybones/img/Storage_HowItWorks/Storage-how-it-works-2.jpg";
import step3Image from "../../../../assets/mylazybones/img/Storage_HowItWorks/Storage-how-it-works-3.jpg";
import step4Image from "../../../../assets/mylazybones/img/Storage_HowItWorks/Storage-how-it-works-4.jpg";
import step5Image from "../../../../assets/mylazybones/img/Storage_HowItWorks/Storage-how-it-works-5.jpg";
import step6Image from "../../../../assets/mylazybones/img/Storage_HowItWorks/Storage-how-it-works-6.jpg";



const HowItWorks = (props)=>{

    return(
        <>
        <div className="how-it-works">
                                
            <Row>
                <Col className="ml-auto mr-auto text-center">
                    <h5 className="description">
                    Lazybones is a local moving and storage company which has been servicing students since 1993. We are a year-round business lead by highly experienced moving professionals. Our storage price includes pick up and delivery from your room/apartment with no monthly fees or fixed packages. Store only what you need!
                    </h5>
                </Col>
            </Row>
            <div className="space-top" />
            <Row>
                <Col className="ml-auto" md="5">
                <Card
                    data-background="image"
                    style={{
                        backgroundImage:`url(${step1Image})`
                    }}
                >
                    
                </Card>
                </Col>
                <Col className="mr-auto" md="5">
                <Card className="card-plain">
                    <CardBody>
                        <CardTitle tag="h2" className="text-center">
                            Step 1 
                        </CardTitle> 
                        <CardTitle tag="h3" className="how-it-works-step-title">
                            Reserve your space
                        </CardTitle>
                        <ul className="card-description">
                            <li>Reserve your space online or call us at 877-215-2105</li>
                            <li>Pay the $35 fully refundable storage deposit online or by phone</li>  
                            
                        </ul> 
                        <CardFooter />
                    </CardBody>
                </Card>
                </Col>
            </Row>
            <br />
            <hr />
            <br />
            <Row>
                <Col className="ml-auto order-12 order-md-1" md="5">
                <Card className="card-plain">
                    <CardBody>
                        <CardTitle tag="h2" className="text-center">
                            Step 2 
                        </CardTitle> 
                        <CardTitle tag="h3" className="how-it-works-step-title">
                        Schedule your pick up
                        </CardTitle>
                        <ul className="card-description">
                            <li>Chose the date and time you want your items picked up</li>  
                            <li>Make sure to choose your pick up date as soon as possible. Times do fill up.</li>  
                            
                        </ul> 
                        <CardFooter />
                    </CardBody>
                </Card>
                </Col>
                <Col className="mr-auto order-1 order-md-12" md="5">
                <Card
                    data-background="image"
                    style={{
                        backgroundImage:`url(${step2Image})`
                    }}
                >
                    
                </Card>
                </Col>
            </Row>
            
            <br />
            <hr />
            <br />

            <Row>
                <Col className="ml-auto" md="5">
                <Card
                    data-background="image"
                    style={{
                        backgroundImage:`url(${step3Image})`
                    }}
                >
                    
                </Card>
                </Col>
                <Col className="mr-auto" md="5">
                <Card className="card-plain">
                    <CardBody>
                        <CardTitle tag="h2" className="text-center">
                            Step 3
                        </CardTitle> 
                        <CardTitle tag="h3" className="how-it-works-step-title">
                            Pack & Label your stuff
                        </CardTitle>
                        <ul className="card-description">
                            <li>Separate items that need to be stored from items that need to be shipped</li>  
                            <li>Print storage labels directly from our website</li>  
                            <li>Print shipping labels directly from out website</li>  
                            <li>Place correct storage and shipping labels on your items</li>
                        </ul> 
                        <CardFooter />
                    </CardBody>
                </Card>
                </Col>
            </Row>
            <br />
            <hr />
            <br />
            <Row>
                <Col className="ml-auto order-12 order-md-1" md="5">
                <Card className="card-plain">
                    <CardBody>
                        <CardTitle tag="h2" className="text-center">
                            Step 4
                        </CardTitle> 
                        <CardTitle tag="h3" className="how-it-works-step-title">
                            We pick up from your room
                        </CardTitle>
                        <ul className="card-description">
                            <li>Be home when we arrive for your scheduled storage pickup</li>  
                            <li>Purchase storage insurance if desired</li>
                            <li>Receive an initial receipt before we leave</li>
                        </ul> 
                        <CardFooter />
                    </CardBody>
                </Card>
                </Col>
                <Col className="mr-auto order-1 order-md-12" md="5">
                <Card
                    data-background="image"
                    style={{
                        backgroundImage:`url(${step4Image})`
                    }}
                >
                    
                </Card>
                </Col>
            </Row>
            <br />
            <hr />
            <br />

            <Row>
                <Col className="ml-auto" md="5">
                <Card
                    data-background="image"
                    style={{
                        backgroundImage:`url(${step5Image})`
                    }}
                >
                    
                </Card>
                </Col>
                <Col className="mr-auto" md="5">
                <Card className="card-plain">
                    <CardBody>
                        <CardTitle tag="h2" className="text-center">
                            Step 5 
                        </CardTitle> 
                        <CardTitle tag="h3" className="how-it-works-step-title">
                            We store your stuff in our storage facility
                        </CardTitle>
                        <ul className="card-description">
                            <li>Temperature controlled</li>  
                            <li>Video monitored 24/7</li>  
                            <li>Alarm system secured</li>  
                            
                        </ul> 
                        <CardFooter />
                    </CardBody>
                </Card>
                </Col>
            </Row>
            <br />
            <hr />
            <br />
            <Row>
                <Col className="ml-auto order-12 order-md-1" md="5">
                <Card className="card-plain">
                    <CardBody>
                        <CardTitle tag="h2" className="text-center">
                            Step 6
                        </CardTitle> 
                        <CardTitle tag="h3" className="how-it-works-step-title">
                            Schedule your delivery
                        </CardTitle>
                        <ul className="card-description">
                            <li>Update your Lazybones account with your new campus address</li>  
                            <li>Chose the date and time you want your items delivered</li>  
                            <li>Options for delivery dates are available on the website by the third week in June</li>  
                            
                        </ul> 
                        <CardFooter />
                    </CardBody>
                </Card>
                </Col>
                <Col className="mr-auto order-1 order-md-12" md="5">
                <Card
                    data-background="image"
                    style={{
                        backgroundImage:`url(${step2Image})`
                    }}
                >
                    
                </Card>
                </Col>
            </Row>
            <br />
            <hr />
            <br />
            <Row>
                <Col className="ml-auto" md="5">
                <Card
                    data-background="image"
                    style={{
                        backgroundImage:`url(${step6Image})`
                    }}
                >
                    
                </Card>
                </Col>
                <Col className="mr-auto" md="5">
                <Card className="card-plain">
                    <CardBody>
                        <CardTitle tag="h2" className="text-center">
                            Step 7
                        </CardTitle> 
                        <CardTitle tag="h3" className="how-it-works-step-title">
                            We deliver to your new place
                        </CardTitle>
                        <ul className="card-description">
                            <li>Your belongings are delivered right to your door!</li>  
                        </ul> 
                        <CardFooter />
                    </CardBody>
                </Card>
                </Col>
            </Row>
            
        </div>
        </>
    )
}

export default HowItWorks;

