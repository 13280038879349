import React from "react";

// reactstrap components
import {
    Badge,
    Button,
    Container,
    Card,
    CardBody,
    CardFooter,
    CardImg,
    CardImgOverlay,
    CardLink,
    CardTitle,
    CardSubtitle,
    CardText,
    ListGroupItem,
    ListGroup,
    Row,
    Col,
    
  } from "reactstrap";
  
const ResidentialSelectCity = (props)=>{
    const {onClickOfCity,listOfResidentialCitiesByState, user} = props;
    const selectedCity = user.residentialCity;
    console.log('listOfResidentialCitiesByState');
    console.log(listOfResidentialCitiesByState);

    React.useEffect(()=>{
        document.getElementById("sectionArea").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
    })
    
    let ctr = 1;
    return(
        <Container id="mainContentArea">
          <Row className="title-row justify-content-between">
            <Col md="8">
              <h3 className="shop">Signup for Laundry - Residential</h3>
            </Col>
            <Col md="4">
              <div className="text-md-right d-none">
                <span className="text-muted">Step 1</span>
                <Button color="link">
                  <i className="fa fa-shopping-cart" /> Select City
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="title-body-row">
                <Col md="12">
                    <Row>
                    {
                        listOfResidentialCitiesByState.map(state=>{
                        console.log(state);   
                        return(
                          <Col key={ctr++} md="6" sm="6">
                            <div key={state.id}>
                                <hr />
                                    <h3 >
                                      {state.name}
                                    </h3>
                                <hr />
                                <Row className="card-body-row">
                                {
                                state.cities.map(city =>{
                                        const selectedAttributes = (selectedCity && selectedCity.id === city.id) ? {"data-color":"orange"}:{};
                                        
                                        let backgroundImage = {
                                          backgroundImage: ""
                                        }
                                        let flagImage = require(`assets/mylazybones/img/signup/residential/${city.name}.jpg`);
                                        backgroundImage = {
                                          backgroundImage: "url(" +
                                            flagImage + 
                                          ")"
                                        }
                                        /*
                                        if(city.id === 1){
                                           backgroundImage = {
                                            backgroundImage: "url(" +
                                            require("assets/mylazybones/img/uw_madison.jpg") +
                                            ")"
                                          }
  
                                           flagImage = require("assets/mylazybones/img/uw_madison_flag.png");
                                        }

                                        if(city.id === 2){
                                          backgroundImage = {
                                           backgroundImage: "url(" +
                                           require("assets/mylazybones/img/syracuse_univ.jpg") +
                                           ")"
                                         }
 
                                          flagImage = require("assets/mylazybones/img/syracuse_univ_flag.png");
                                       }
                                       */


                                        return(
                                            <Col key={city.id} md="8" sm="8">
                                                <Card 
                                                  className="card-profile cursor mt-0" 
                                                  {...selectedAttributes}
                                                  onClick={()=>onClickOfCity(city)}
                                                >
                                                  <div
                                                    className="card-cover"
                                                    style={backgroundImage}
                                                  />
                                                  <CardBody>
                                                    <CardTitle tag="h4">
                                                      {city.name}
                                                      <br/>
                                                      & <br/> Sorrounding Area
                                                    </CardTitle>
                                                    <CardFooter className="text-center mt-2">
                                                      { (selectedCity && selectedCity.id === city.id) ?
                                                        <Badge className="mr-1" color="success" pill>
                                                          Selected
                                                        </Badge>
                                                        :
                                                        <br/>
                                                      }
                                                    </CardFooter>
                                                  </CardBody>
                                                </Card>
                                            </Col>
                                        );     
                                }) 
                                }
                                </Row>
                                

                            </div>      
                          </Col>
                        )


                        })    

                    }
                    </Row>
                </Col>
                
          </Row>
          
          
        
      </Container>      
    );
    

}

export default ResidentialSelectCity;

