// CardSection.js
import React from 'react';
import {CardElement,
        CardNumberElement,
        CardExpiryElement,
        CardCVCElement,} from 'react-stripe-elements';
  

// reactstrap components
import {
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    InputGroupAddon,
    InputGroupText,
    InputGroup,
  } from "reactstrap";

class CardSection extends React.Component {
  render() {
    return (
      <>  
      <FormGroup row>
            <Label for="card-element" sm={4}>Credit Card Number</Label>
            <Col id="card-element" sm={6}>
                <CardNumberElement />
            </Col>
      </FormGroup>
      
      <FormGroup row>
            <Label for="card-element" sm={4}>Expiration Date</Label>
            <Col id="card-element" sm={6}>
                <CardExpiryElement />
            </Col>
      </FormGroup> 
      
      <FormGroup row>
            <Label for="card-element" sm={4}>CVC</Label>
            <Col id="card-element" sm={6}>
                <CardCVCElement />
            </Col>
      </FormGroup> 
      </>

    );
  }
}

export default CardSection;