/*  
    Query the server to retrieve a list of upcoming notifications
    [
        message:{
            id:
            universityId:
            campusId:
            expirationDate:
            body:
            productType:"Storage","Laundry","Supplies"
            customerType:"Student","Residential"
            type:"SITE-WIDE","FRANCHISE-SPECIFIC"
        },
        ....

    ]

    Local Storage:
    [
        {
            id:
            expirationDate:
        },
        ....,
        {
            id:
            expirationDate:
        }

    ]

    On click of mark as read:
    - We save the message in the application storage  
    - run routine to filter out messages from the notification list and application storage 

    One time action:
    - Query to get list of messages from the server
    - If user logs in then we trigger the message retrieval but we get location specific message 

*/

import React,{useState,useEffect} from "react"
import _ from "lodash"

import messageService from "../../services/footerMessageService"

const NotificationContext = React.createContext()
NotificationContext.displayName = "NotificationContext";

const {Provider,Consumer,displayName} = NotificationContext

const NotificationContextProvider  = (props)=>{
    
    const [notifications,setNotification] = useState([])
    
    //List of messages id that are already read
    const [notificationsMarkedAsRead,setNotificationsMarkedAsRead] = useState([])
    
    //This will be the indicator to move to next or previous message
    const [currentNotificationId,setCurrentNotificationId] = useState() 
    //This is used for display on the message bar
    const [currentMessageNumber,setCurrentMessageNumber] = useState(1) 
    
    const [counter,setCounter] = useState({selectedValue:1});

     
    //Get notifications from server
    /*
    const getNotificationsPromise = new Promise((resolve,reject)=>{
        setTimeout(()=>resolve(messagesDb),5000)
    })
    */

    const handleMoveToNextMessage = (e)=>{
        e.preventDefault();
        if(currentNotificationId){
                const currentIndex = _.findIndex(notifications,{"notificationId":currentNotificationId})
                if(currentIndex < notifications.length - 1){
                    const messageIndex =  currentIndex + 1
                    setCurrentNotificationId(notifications[messageIndex].notificationId)
                    setCurrentMessageNumber(currentMessageNumber + 1)
                }            
        }
    }

    const handleMoveToPreviousMessage = (e)=>{
        e.preventDefault();
        if(currentNotificationId){
            const currentIndex = _.findIndex(notifications,{"notificationId":currentNotificationId})
            if(currentIndex > 0){
                const messageIndex =  currentIndex - 1
                setCurrentNotificationId(notifications[messageIndex].notificationId)
                if(currentMessageNumber > 1) setCurrentMessageNumber(currentMessageNumber - 1)
            }            
        } 
    }

     /*
       Add message to the local storage and then add it to notificationsMarkedAsRead
       Update the notification list in state
     */
    const handleNotificationMarkedAsRead = (notification)=>{
        messageService.saveMessageToLocalCache(notification)
        
        const notificationIndex = _.findIndex(notifications,{"notificationId":notification.notificationId})
                
        let unreadNotifications = []
        unreadNotifications = notifications.filter(message=>message.notificationId != notification.notificationId)

        if(unreadNotifications.length > 0){
            
            //Set index to the last position if last message is read otherwise it's the next message
            let index = (notificationIndex == unreadNotifications.length) ? unreadNotifications.length -1:notificationIndex
            setCurrentNotificationId(unreadNotifications[index].notificationId)
            if(currentMessageNumber > 1 && notificationIndex == unreadNotifications.length){
                setCurrentMessageNumber(currentMessageNumber - 1)
            }
           
            setNotification(unreadNotifications)
            
        }
        else{
            setCurrentNotificationId(null)
            setCurrentMessageNumber(1)
            setNotification([])
        }
           

    }
   

    const handleIncrementCounter = ()=>{
        setCounter(prevValue=>{
            return({
                selectedValue:prevValue.selectedValue + 1
            })

        })
    }
        

    //Get messages from the server 
    useEffect(()=>{
        const getNotifications = async ()=>{
            try{
                //const notificationsFromServer = await getNotificationsPromise
                const notificationsFromServer = await messageService.getMessagesFromServer()
                messageService.removeExpiredMessagesFromLocalCache()
                const notificationsFromLocalCache = messageService.getMessagesFromLocalCache()
                
                let unreadNotifications = []
                unreadNotifications = notificationsFromServer.filter(message=>{
                    const {notificationId} = message
                    return !_.find(notificationsFromLocalCache, { 'notificationId': notificationId});
                })


                if(unreadNotifications.length > 0){
                    setNotification(unreadNotifications)
                    setCurrentNotificationId(unreadNotifications[0].notificationId)
                }
            }
            catch(ex){
                console.log(ex);
            }
        }
        getNotifications()
    },[])
    
    
    return(
    <Provider value={{
            counter,
            currentNotificationId,
            currentMessageNumber,
            notifications,
            notificationsMarkedAsRead,
            handleMoveToNextMessage,
            handleMoveToPreviousMessage,
            handleNotificationMarkedAsRead,
            incrementCounter:handleIncrementCounter,
    }}>
        {props.children}
    </Provider>
    )

}

export  {
    NotificationContextProvider,
    Consumer as NotificationContextConsumer,   
}