import React from "react"

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Media,
    Container,
    Row,
    Col
} from "reactstrap";


const HowItWorksCommercial = (props)=>{

    return(
        <>
        <div className="how-it-works">
                                
            <Row>
                <Col className="ml-auto mr-auto">
                    <h5 className="description">
                        Lazybones provides efficient and flexible high volume laundry service to all types of businesses; nursing homes, summer camps, military/private schools, government agencies, hotels, universities and more.
                    </h5>
                    <h5 className="description mt-4">
                        Please call (877) 215-2105 or email <a href="mailto:commercial@mylazybones.com">commercial@mylazybones.com</a> for information regarding commercial laundry pricing and scheduling. 
                    </h5>
                </Col>
            </Row>
            
        </div>
        </>
    )
}

export default HowItWorksCommercial;

