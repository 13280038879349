import React,{Component} from "react";
import {StripeProvider} from 'react-stripe-elements';
import {Elements} from 'react-stripe-elements';
import {Link} from "react-router-dom";


import http from "services/httpService";
import auth from "services/authService";
import {renderError} from 'utils/errorUtils';
import {scrollTop} from 'utils/helperUtils';
import customToast from '../../components/Common/CustomToast'

import applicationConstants from "utils/applicationConstants";


import ShortNavbar from "../../components/Navbars/ShortNavbar";
import SuppliesPageHeader from "../../components/Headers/SuppliesPageHeader";
import FooterBlack from "../../components/Footers/FooterBlack";

//Services
import UserService    from "../../services/userService";

//Reusable component
import PaymentInformation from "../../components/Consumer/ExistingUserSignup/Common/PaymentInformation"


import SuppliesCheckoutDeliveryAndPaymentLoggedInUser from "../../components/Consumer/ShoppingCart/Supplies/SuppliesCheckoutDeliveryAndPaymentLoggedInUser"
import SuppliesReviewScreenForLoggedInUser from "../../components/Consumer/ShoppingCart/Supplies/SuppliesCheckoutReviewLoggedInUser";

import ProcessingModal from "../../components/Common/ProcessingModal"

const apiUserEndpoint = "/users";

class ShoppingCartSuppliesCheckoutForLoggedInUser extends Component{
    
    constructor(){
        super();
        this.state = {
            processingModalDisplayFlag:false,
            existingCustomer:{
                updatePaymentInformationFlag:false,
                primaryInformation:"", //Logged in users information 
                existingPaymentInformationOnFile:"",
                paymentInformation:{
                  appliedPaymentType:"",//Gift Card or Credit Card 
                  creditCardStripeToken:"", 
                  creditCardName:"",  
                  creditCardNumber:"",
                  creditCardExpirationMonth:"",
                  creditCardExpirationYear:"",
                  creditCardCCV:"",
                  billingAddress:{
                        addressLine1:"",
                        addressLine2:"",
                        city:"",
                        state:"",
                        postalCode:"",
                        country:"",
                  },
                  creditCardResponse:{
                        transactionId:"",
                  },
                  giftCard:{
                        code:"",
                        value:"",
                  }
                },
            },
            serverErrors:{},
        }
    }

    async componentDidMount(){
        try{
            const user = await auth.getUserRecord();
            const {user_id:userId} = user;
            //UserService.getFormattedUserAddress(user);

            //Get existing card information
                let existingPaymentInformationOnFile = "";
                const {status:processingStatus,message,returnObject:cardInformation} = await UserService.getCreditCardInfo({userId});
                if(processingStatus === true){
                    existingPaymentInformationOnFile = cardInformation;
                }
            //End of Get existing card information

            this.setState({
                existingCustomer:{
                    ...this.state.existingCustomer,
                    primaryInformation:user,
                    existingPaymentInformationOnFile,
                }
            })
            
        }
        catch(ex){
            renderError(ex);
        } 


    }

    handleBackOnPaymentInformationReusableComponent = ()=>{
        const {product} = this.props.match.params;
        this.props.history.push(`/supplies/view-catalog`); 
    }

    handleDisplayProcessingModalWindow = (flag)=>{
        const myAccountState = {...this.state}
        myAccountState.processingModalDisplayFlag = flag;
        this.setState(myAccountState);
    }


    handleSuppliesBackOnReviewScreen = (e)=>{
        e.preventDefault();
        const {product} = this.props.match.params;
        let step = "checkout";
        this.props.history.push(`/shoppingCart/${product}/${step}`); 
    }
    
    handleSaveOnPaymentInformationReusableComponent = (formData)=>{
        const {product} = this.props.match.params;
        let step = "review"; 

        try{
            const user = {...this.state.existingCustomer}
            const {billingAddress,updatePaymentInformationFlag} = formData;
            if(updatePaymentInformationFlag === true){
                billingAddress.state = billingAddress.state.value;
                billingAddress.country = billingAddress.country.value;
                user.updatePaymentInformationFlag = updatePaymentInformationFlag;
                user.paymentInformation = {
                    creditCardStripeToken:formData.stripeToken,
                    creditCardName:formData.creditCardName,  
                    billingAddress
                }
            }

            this.setState({existingCustomer:user});
            this.props.history.push(`/shoppingCart/${product}/${step}`);  
        }
        catch(ex){
            console.log("Error handleSaveLoginAndPaymentInformation",ex);
        }
    }


    handleSaveDeliveryAddressAndPaymentInformation = (formData)=>{
        try{
            console.log(formData);
            const {product} = this.props.match.params;
            const existingCustomer = {...this.state.existingCustomer}
            const {billingAddress} = formData;
            billingAddress.state = billingAddress.state.value;
            billingAddress.country = billingAddress.country.value;
            
            let step = "review";
            existingCustomer.paymentInformation = {
                creditCardStripeToken:formData.stripeToken,
                creditCardName:formData.creditCardName,  
                billingAddress
            }
            this.setState({existingCustomer});
            this.props.history.push(`/shoppingCart/${product}/${step}`);  
        }
        catch(ex){
            console.log("Error handleSaveLoginAndPaymentInformation",ex);
        }
    }

    handleSuppliesPurchaseOnReviewScreen = async ()=>{
        console.log("handle purchase");
        try{
            const existingCustomer = {...this.state.existingCustomer};
            const {globalApp,clearShoppingCart} = this.props;
            const {shoppingCart}=  globalApp;
            const {customerType} = shoppingCart.loggedInUser
            let customerTypeText = "";
            if(customerType === applicationConstants.CUSTOMER_TYPE_STUDENT)
                customerTypeText = applicationConstants.CUSTOMER_TYPE_STUDENT_TEXT;
            else if(customerType === applicationConstants.CUSTOMER_TYPE_RESIDENTIAL)
                customerTypeText = applicationConstants.CUSTOMER_TYPE_RESIDENTIAL_TEXT;
              
            this.setState({serverErrors:""});
            this.handleDisplayProcessingModalWindow(true);
            
            //Build payload
            const payload = {
                ...this.state.existingCustomer,
                shoppingCart
            };
    
            //console.log(payload);
            const response = await http.post(`${apiUserEndpoint}/supplies/purchase`,payload);
            const {status,message,errorType} = response.data;

            if(status === true){
                //this.props.history.push("/");
                
                this.setState({existingCustomer})
                clearShoppingCart();
                window.location = `/myaccount/${customerTypeText}/supplies`;  
            }
            else{
                this.handleDisplayProcessingModalWindow(false);
                //Handle errors from the server
                let [errors,errorMessage] = [{},""];
                if(errorType === "credit-card"){
                    errorMessage = <>
                       {`${message} Click `}<Link to={`/shoppingCart/supplies/checkout`}>here</Link>{` to re-enter payment informtion.`}
                    </>
                }
                else{
                    errorMessage = message; 
                } 
                
                errors = {message:errorMessage};
                this.setState({serverErrors: errors || {}});
                
            }  
            
    
        }
        catch(ex){
            this.handleDisplayProcessingModalWindow(false);
            /*
              400 signals validation errors from the server
              renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop('sectionArea'); return;}
            }
            else
                renderError(ex);
        }

    }

    getPaymentInformationComponentDisplayProperties = ()=>{
        const {product} = this.props.match.params;
        const componentIsUsedFor = "existingUserSignUp";
        return applicationConstants.paymentInformationComponentDisplayProperties[componentIsUsedFor]['student'][product];
    }

    render(){
        const {product,step} = this.props.match.params;
        const {globalApp} = this.props;
        const {universityId} = globalApp.shoppingCart;
        const stripeMerchantAccountUniverityId = universityId;
        const {existingCustomer,processingModalDisplayFlag,serverErrors} = this.state
        const enableSuppliesDeliveryAndPaymentComponentFlag =  false;

        const paymentInformationComponentDisplayProperties = this.getPaymentInformationComponentDisplayProperties();
        

        if(stripeMerchantAccountUniverityId){
            return(
                <StripeProvider key={stripeMerchantAccountUniverityId} apiKey={process.env[`REACT_APP_STRIPE_PRODUCTION_KEY_UNIVERSITY_ID_${stripeMerchantAccountUniverityId}`]}>  
                    <React.Fragment>
                        <ShortNavbar {...this.props}/>
                        <SuppliesPageHeader />  
                        <div id="sectionArea" className="section section-gray" style={{paddingTop:"10px"}}>
                            {
                                step === "checkout" &&
                                <Elements>
                                    <PaymentInformation
                                        {...this.props}
                                        user = {existingCustomer}
                                        paymentInformationComponentDisplayProperties={paymentInformationComponentDisplayProperties}
                                        onClickOfNextButtonFromPaymentInformation = {this.handleSaveOnPaymentInformationReusableComponent}
                                        onClickOfBackButtonFromPaymentInformation = {this.handleBackOnPaymentInformationReusableComponent}
                                    />
                                </Elements>
                            }
                            {
                                enableSuppliesDeliveryAndPaymentComponentFlag && 
                                step === "checkout" &&
                                <Elements>
                                    <SuppliesCheckoutDeliveryAndPaymentLoggedInUser
                                        {...this.props}
                                        user = {existingCustomer}
                                        onClickOfNextButtonFromDeliveryAddressAndPaymentInformation = {this.handleSaveDeliveryAddressAndPaymentInformation}
                                    />
                                </Elements>
                            }
                            {
                                step === "review" &&
                                <SuppliesReviewScreenForLoggedInUser
                                    {...this.props}
                                    user = {existingCustomer}
                                    onClickOfBackButtonFromReviewScreen = {this.handleSuppliesBackOnReviewScreen}
                                    onClickOfPurchaseButtonFromReviewScreen = {this.handleSuppliesPurchaseOnReviewScreen}
                                    serverErrors={serverErrors}
                                />

                            }
                        </div>
                        <FooterBlack />
                        
                        {/* Set Processing modal */}
                        <ProcessingModal
                            displayModal={processingModalDisplayFlag}
                            handleCloseOfModalWindow={this.handleDisplayProcessingModalWindow}
                        />
                        {/* End of Processing modal */}
                    </React.Fragment>
                </StripeProvider>
            
            );
        }else{
            return "Loading ....please wait";
        }
    }
}

export default ShoppingCartSuppliesCheckoutForLoggedInUser;