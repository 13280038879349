import React,{Component} from "react";
import _ from "lodash";
import moment from "moment";
import accounting from "accounting";

import applicationConstants from "../../../../../utils/applicationConstants"
import customToast from '../../../../Common/CustomToast'
import {renderError} from '../../../../../utils/errorUtils';
import {scrollTop} from '../../../../../utils/helperUtils';

import SupplyOrderListModal from "../Common/Supplies/SupplyOrderListModal";
import SupplyOrderDetailModal from "../Common/Supplies/SupplyOrderDetailModal";

import UserNotificationWidget from "../Common/Notifications/UserNotifications";


// reactstrap components
import {
    Button,
    Card,
    CardBody,
    Row,
    Col,
    Modal,
} from "reactstrap";

class StudentAccountSuppliesDetail extends Component{
    constructor(){
        super();
        this.state = {
            supplyOrderListModalDisplayFlag:false,
            supplyOrderDetailModalDisplayFlag:false,
            supplyOrderRecordToView:"",
        }
    }

    getLastOrderDetails = ()=>{
        let lastOrderDetail = null;
        const {supplies} = this.props
        if(!_.isEmpty(supplies)){
            const {lastOrder} = supplies;
            const {order_date:orderDate,requested_shipdate:shipDate,total} = lastOrder;
            const isOrderPast = (moment(shipDate).isBefore(moment())) ? true:false;
            lastOrderDetail = {
                isOrderPast,
                orderDate:moment(orderDate).format("ddd, MMM Do YYYY"),
                shipDate:moment(shipDate).format("ddd, MMM Do YYYY"),
                total:accounting.formatMoney(total),
                displayLabel:(isOrderPast) ? "Your last order details":"Your upcoming order details"
            }

        }    
        return lastOrderDetail;
    }

    handleViewSupplyOrderDetails = supplyOrderRecordToView=>{
        this.setState({supplyOrderRecordToView});
        this.setSupplyOrderDetailModalDisplayFlag(true);
    }

    setSupplyOrderDetailModalDisplayFlag = (flag)=>{
        this.setState({
            supplyOrderDetailModalDisplayFlag:flag    
        })
    }

    setSupplyOrderListModalDisplayFlag = (flag)=>{
        this.setState({
            supplyOrderListModalDisplayFlag:flag    
        })
    }

     

    render(){
        const {           
            displayRenewLaundryPlan:displayRenewLaundryPlanInformation,
            displayFlag,
            displayWelcomeModal,
            handleOnCloseOfWelcomeModal,
            handleOnClickOfSignupLaundryButton,
            supplies,
            user,
            userNotifications,
        } = this.props;

        const {
            supplyOrderRecordToView
        } = this.state

        let lastSupplyOrderDetailRecord = (!_.isEmpty(supplies)) ? supplies.lastOrder:null;
        const {supplyOrderDetailModalDisplayFlag,supplyOrderListModalDisplayFlag} = this.state;
        const lastOrderDetail = this.getLastOrderDetails();
        const firstTimeUser = (_.isEmpty(supplies)) ? true:false;
        const displayCSS = (displayFlag) ? "min-height-500":"d-none min-height-500";

        const upcomingLaundrySeason = displayRenewLaundryPlanInformation;
        const isUpcomingLaundrySeasonAvailable = (!_.isEmpty(upcomingLaundrySeason)) ? true:false;

        const upcomingOrderList = (!_.isEmpty(supplies)) ? supplies.upcomingOrderList:null;

        return(
            <>
            <Row  className={displayCSS}>
                <Col>
                    <Card>
                        <CardBody>
                            <div>
                                <div className="d-flex bd-highlight mb-3">
                                    <div className="mr-auto p-2 bd-highlight mt-2">
                                        <h6 className="card-category">
                                            <span className="text-left">
                                            Your Supplies: 
                                            </span>
                                        </h6>
                                    </div>
                                    {   
                                        userNotifications &&  
                                        <div className="bd-highlight d-none d-lg-block">
                                            <UserNotificationWidget
                                                {...this.props}
                                            />
                                        </div>
                                    }
                                    
                                </div>
                                <hr/>
                                
                                
                                {
                                    firstTimeUser &&
                                        <div className="min-height-sm">
                                            <Row>
                                                <Col xs="9">
                                                    <div className="mlb-alert mlb-alert-primary" role="alert">
                                                        <Row>
                                                            <Col xs="1" sm="1" className="fs-42 text-danger d-none d-sm-block">
                                                                    <i className="fa fa-exclamation-triangle fa-6" aria-hidden="true"></i>    
                                                            </Col>
                                                            <Col>
                                                            <strong>You currently have not purchased any supplies. Click 
                                                                <Button
                                                                className="px-1"
                                                                color="link"
                                                                href="/supplies"
                                                                onClick={(e) =>{ 
                                                                    e.preventDefault()
                                                                    return this.props.history.push("/supplies")
                                                                }}
                                                                >
                                                                Here
                                                                </Button> to view our supplies catalog.</strong>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                                <Col xs="2" >
                                                    <img  
                                                        className="img-rounded img-responsive"
                                                        src={require("../../../../../assets/mylazybones/img/MyAccount/Supplies/supplies_landing.jpg")}
                                                        
                                                    />
                                                </Col>
                                            </Row>
                                            <div>
                                                <hr/>
                                            </div>
                                            <Row>
                                                <Col xs="12" md="6">
                                                    <div>
                                                        <h6>
                                                            Other Services
                                                        </h6>
                                                    </div>
                                                    <div className="mt-2 ">
                                                        <Row>
                                                            <Col xs="2" md="3">
                                                                <img
                                                                    alt="..."
                                                                    className="img-responsive"
                                                                    src={require("assets/mylazybones/img/MyAccount/Storage/storage_package.jpg")}
                                                                    style={{width:"50px",height:"50px"}}
                                                                />
                                                            </Col>
                                                            <Col xs="8">
                                                                <Button
                                                                    className="btn-link mr-1"
                                                                    color="info"
                                                                    onClick={e => {e.preventDefault();
                                                                        return this.props.history.push("/myaccount/student/storage");
                                                                    }}
                                                                    style={{textTransform:"none",paddingLeft:"0px",textAlign:"left"}}
                                                                >
                                                                    View Storage Service
                                                                </Button> 
                                                            </Col>

                                                        </Row>
                                                        {
                                                            <Row className="mt-2">
                                                                <Col xs="2" md="3">
                                                                    <img
                                                                        alt="..."
                                                                        className="img-responsive"
                                                                        src={require("assets/mylazybones/img/MyAccount/Laundry/Laundry_Myaccount_Landing.jpg")}
                                                                        style={{width:"50px",height:"50px"}}
                                                                    />
                                                                </Col>
                                                                <Col xs="8">
                                                                    <Button
                                                                        className="btn-link mr-1"
                                                                        color="info"
                                                                        onClick={e => {e.preventDefault();
                                                                            return this.props.history.push("/myaccount/student/laundry");
                                                                        }}
                                                                        style={{textTransform:"none",paddingLeft:"0px",textAlign:"left"}}
                                                                    >
                                                                        View Laundry Service
                                                                    </Button> 
                                                                </Col>
                                                            </Row>
                                                        }
                                                        
                                                        
                                                        
                                                    </div> 
                                                        
                                                
                                
                                                </Col>
                                            </Row>
                                            
                                        </div>
                                }

                                { !firstTimeUser && 
                                <div className="mt-2">
                                    <Row>
                                        <Col md="4" className="br-solid">
                                            <Row>
                                                <Col xs="9" sm="9" md="12">
                                                    

                                                    <Row>
                                                        <Col className="text-left">
                                                            <Button
                                                                className="btn-link"
                                                                color="info"
                                                                size="sm"
                                                                type="button"
                                                                style={{paddingLeft:"0px"}}
                                                                onClick={(e) =>{ 
                                                                    e.preventDefault()
                                                                    return this.props.history.push("/supplies")
                                                                }}
                                                            >
                                                                <i className="fa fa-book" aria-hidden="true"></i>
                                                                Get more Supplies
                                                            </Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col className="text-left">
                                                            <Button
                                                                className="btn-link"
                                                                color="info"
                                                                size="sm"
                                                                type="button"
                                                                style={{paddingLeft:"0px"}}
                                                                onClick={()=>this.setSupplyOrderListModalDisplayFlag(true)}
                                                            >
                                                                <i className="fa fa-table" aria-hidden="true"></i>
                                                                Your Order History
                                                            </Button>
                                                        </Col>
                                                    </Row>

                                                    
                                                    

                                                    
                                                </Col>
                                                <Col xs="3" sm="3" className="align-middle d-block d-md-none">
                                                    <img  
                                                        className="img-rounded img-responsive"
                                                        src={require("../../../../../assets/mylazybones/img/supply_kit_1.png")}
                                                        
                                                    />
                                                </Col>
                                            </Row>
                                                
                                            
                                        </Col>
                                        
                                        
                                        <Col md="5" className="br-solid">
                                            <div className="d-block d-md-none">
                                                <hr/>
                                            </div>
                                            { /* If there are upcoming order then display the list of orders */}
                                            {
                                               upcomingOrderList && upcomingOrderList.length > 0 &&
                                               <>
                                                   <div>
                                                       <h6>Your upcoming order details:</h6>
                                                   </div>
                                                   { 
                                                   upcomingOrderList.map(supplyOrderRecord=>{
                                                    const {order_date:orderDate,requested_shipdate:shipDate,total} = supplyOrderRecord;
            
                                                    return(
                                                        <>
                                                            <Row className="mt-2">
                                                                <Col xs="4" sm="6" md="4">
                                                                    <strong>Order Date</strong>
                                                                </Col>
                                                                <Col xs="8" sm="6" md="8">
                                                                    {moment(orderDate).format('MM/DD/YYYY')}
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-2">
                                                                <Col xs="4" sm="6" md="4">
                                                                    <strong>Ship Date</strong>
                                                                </Col>
                                                                <Col xs="8" sm="6" md="8">
                                                                    {moment(shipDate).format('dddd, MMMM Do')}
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-2">
                                                                <Col xs="4" sm="6" md="4">
                                                                    <strong>Total Amount</strong>
                                                                </Col>
                                                                <Col xs="8" sm="6" md="8">
                                                                    {accounting.formatMoney(total)}
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-2">
                                                                <Col xs="4" sm="6" md="4">
                                                                    
                                                                </Col>   
                                                                <Col xs="8" sm="6" md="8">
                                                                    <div className="mt-2">
                                                                        <Button
                                                                                color="info"
                                                                                outline
                                                                                size="sm"
                                                                                type="button"
                                                                                className="mr-1"
                                                                                onClick={()=>this.handleViewSupplyOrderDetails(supplyOrderRecord)}
                                                                                
                                                                        >
                                                                            <i className="fa fa-calendar" aria-hidden="true"></i>
                                                                            Vew Details
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    )
                                                   })
                                                   
                                                 }

                                                       
                                               </>
                                            
                                            }
                                            
                                            { /* If there are no upcoming order then display the last order */}
                                            {   upcomingOrderList.length === 0 && 
                                                <>
                                                    <div>
                                                        <h6>{ lastOrderDetail.displayLabel}</h6>
                                                    </div>
                                                    <Row className="mt-2">
                                                        <Col xs="4" sm="6" md="4">
                                                            <strong>Order Date</strong>
                                                        </Col>
                                                        <Col xs="8" sm="6" md="8">
                                                            {lastOrderDetail.orderDate}
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-2">
                                                        <Col xs="4" sm="6" md="4">
                                                            <strong>Ship Date</strong>
                                                        </Col>
                                                        <Col xs="8" sm="6" md="8">
                                                            {lastOrderDetail.shipDate}
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-2">
                                                        <Col xs="4" sm="6" md="4">
                                                            <strong>Total Amount</strong>
                                                        </Col>
                                                        <Col xs="8" sm="6" md="8">
                                                            {lastOrderDetail.total}
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-2">
                                                            <Col xs="4" sm="6" md="4">
                                                                
                                                            </Col>   
                                                            <Col xs="8" sm="6" md="8">
                                                                <div className="mt-2">
                                                                    <Button
                                                                            color="info"
                                                                            outline
                                                                            size="sm"
                                                                            type="button"
                                                                            className="mr-1"
                                                                            onClick={()=>this.setSupplyOrderDetailModalDisplayFlag(true)}
                                                                            
                                                                    >
                                                                        <i className="fa fa-calendar" aria-hidden="true"></i>
                                                                        Vew Details
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                    </Row>
                                                        
                                                </>
                                            
                                            }
                                        </Col>

                                        <Col md="2" className="align-middle d-none d-md-block">
                                            <img  
                                                className="img-rounded img-responsive"
                                                src={require("../../../../../assets/mylazybones/img/supply_kit_1.png")}
                                                
                                            />
                                                
                                        
                        
                                        </Col>
                                    </Row>

                                    <div className="d-none d-md-block">
                                        <hr/>
                                    </div>

                                    <Row>
                                        <Col md="4" className="br-solid">
                                            
                                        </Col>
                                        
                                        
                                        <Col md="5" className="br-solid">
                                                            
                                        </Col>

                                        <Col md="3" className="">
                                            <div className="d-block d-md-none">
                                                <hr/>
                                            </div>
                                            <div>
                                                <h6>
                                                    Other Services
                                                </h6>
                                            </div>
                                            <div className="mt-2 ">
                                                <Row>
                                                    <Col xs="2" md="3">
                                                        <img
                                                            alt="..."
                                                            className="img-responsive"
                                                            src={require("assets/mylazybones/img/order_packing_supplies.jpg")}
                                                            style={{width:"50px",height:"50px"}}
                                                        />
                                                    </Col>
                                                    <Col xs="8">
                                                        <Button
                                                            className="btn-link mr-1"
                                                            color="info"
                                                            href="#pablo"
                                                            onClick={(e)=>this.props.history.push("/myaccount/student/storage")}
                                                            style={{textTransform:"none",paddingLeft:"0px",textAlign:"left"}}
                                                        >
                                                            View Storage Service
                                                        </Button> 
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col xs="2" md="3">
                                                        <img
                                                            alt="..."
                                                            className="img-responsive"
                                                            src={require("assets/mylazybones/img/storage_box.jpg")}
                                                            style={{width:"50px",height:"50px"}}
                                                        />
                                                    </Col>
                                                    <Col xs="8">
                                                        <Button
                                                            className="btn-link mr-1"
                                                            color="info"
                                                            href="#pablo"
                                                            onClick={(e)=>this.props.history.push("/myaccount/student/laundry")}
                                                            style={{textTransform:"none",paddingLeft:"0px",textAlign:"left"}}
                                                        >
                                                            View Laundry Service
                                                        </Button> 
                                                    </Col>
                                                </Row>
                                                
                                                
                                            </div> 
                                                
                                        
                        
                                        </Col>
                                    </Row>
                                    
                                </div>
                                }
                                
                            </div> 

                        
                            
                        </CardBody>
                    </Card>
                </Col> 
            </Row>

            <Modal isOpen={displayWelcomeModal} toggle={() =>handleOnCloseOfWelcomeModal(false)}>
                <div className="modal-header no-border-header">
                <button
                    className="close"
                    type="button"
                    onClick={() => handleOnCloseOfWelcomeModal(false)}
                >
                    ×
                </button>
                <h4 className="modal-title animated bounce text-success modal-myaccount-header-animation" id="myModalLabel">
                    <i className="fa fa-thumbs-o-up" aria-hidden="true"></i> Congratulations!
                </h4>
                
                </div>
                <div className="modal-body">
                
                <p>
                    You have successfully created your account. Please visit the <Button
                    className="mr-1"
                    color="link"
                    onClick={(e) =>{
                        handleOnCloseOfWelcomeModal(false)
                        return this.props.history.push("/myaccount/view-profile")
                    }}
                    >
                    View Profile
                    </Button> page to make changes to your personal information. 
                    
                </p>  
                
                
                
                </div>
                <div className="modal-footer">
                <Button
                    className="btn-link"
                    color="primary"
                    type="button"
                    onClick={() => handleOnCloseOfWelcomeModal(false)}
                >
                    Close
                </Button>
                </div>
            </Modal>

            {/* Supply Order List Modal */}
            {
                !firstTimeUser &&
                <SupplyOrderListModal
                    modalDisplayFlag={supplyOrderListModalDisplayFlag}
                    handleModalDisplay={this.setSupplyOrderListModalDisplayFlag}
                    user={user}
                />
            }    
            {/* End of Price Estimator Modal */}

            {/* Supply Order Detail Modal */}
            {
                !firstTimeUser && supplyOrderRecordToView !== "" &&
                <SupplyOrderDetailModal
                    modalDisplayFlag={supplyOrderDetailModalDisplayFlag}
                    handleModalDisplay={this.setSupplyOrderDetailModalDisplayFlag}
                    supplyOrderRecordForDetailView={supplyOrderRecordToView}
                />
            }    
            {/* End of Price Estimator Modal */}


            
            </>
        );
    }
}

export default StudentAccountSuppliesDetail;
