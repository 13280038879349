import React,{useState,useEffect,Component} from "react";
import queryString from "query-string";
import Select from "react-select";
import moment from "moment";
import _ from "lodash";
import customToast from '../../../../Common/CustomToast'


import UserService from "services/userService";
import auth from "services/authService";

import MyAccountNavbar from "../../../../Navbars/MyAccountNavbar";

import ProcessingModal from "../../../../Common/ProcessingModal";
import FooterBlack from "../../../../Footers/FooterBlack.js";

import LandingPageView from "./Views/LandingPageComponent";
import SalesReportView from "./Reports/SalesReport";
import AccountActivityView from "../Common/Financials/AccountActivity";

// reactstrap components
import {
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    FormGroup, 
    Modal, 
} from "reactstrap";

class CommercialCustomerAccount extends Component{
    constructor(){
        super();
        this.state = {
            commercialCustomerType:"",
            customerName:"",
            userId:"",
        }
    }

    componentDidMount(){
        const user = auth.getCurrentUser()
        if(!_.isEmpty(user)){
            const { commercialCustomerType,fullName:customerName,userId, } = user;
            this.setState({
                commercialCustomerType,
                customerName,
                userId,
            })
        }
    }

    handleOnClickOfSalesReportButton = (e)=>{
        e.preventDefault();
        this.props.history.push("/myaccount/commercial/sales-report")
    }

    handleOnClickOfAccountActivityButton = (e)=>{
        e.preventDefault();
        this.props.history.push("/myaccount/commercial/account-activity")
    }

    handleOnClickBackButtonFromAccountActivityButton = ()=>{
        return this.props.history.push("/myaccount/commercial");
    }
  

    render(){
        const displayCSS = "min-height-500";
        const {accountType,product:urlActionParameter} = this.props.match.params;
        const customerProperties = {...this.state};
        
        let action = urlActionParameter || "landing-page"
        //console.log('action',action);

        
        return(
            <>
            <MyAccountNavbar {...this.props} />
            <div className="main">
                <div id="sectionArea" className="section section-gray">
                    <Container>
                        <Row className="title-row">
                            <Col>
                                <h3>Commercial Account:</h3>
                            </Col>
                        </Row>
                        <Row className="title-body-row mt-3">
                            <Col>
                                <Row className={displayCSS}>
                                    <Col>
                                        <Card className="card-plain bg-white p-2 rounded-lg">
                                            <CardBody>
                                                
                                                {
                                                    action === "landing-page" && 
                                                    <LandingPageView 
                                                        customerProperties = {customerProperties}
                                                        onClickOfSalesReportButton={this.handleOnClickOfSalesReportButton}
                                                        onClickOfAccountActivityButton={this.handleOnClickOfAccountActivityButton}
                                                        {...this.props}
                                                    />
                                                }
                                                {
                                                    action === "sales-report" &&
                                                    <SalesReportView 
                                                        {...this.props}
                                                    />
                                                }
                                                {
                                                    action === "account-activity" &&
                                                    <AccountActivityView 
                                                        onClickOfBackButton={this.handleOnClickBackButtonFromAccountActivityButton}
                                                        {...this.props}
                                                    />
                                                }       

                                            </CardBody>
                                        </Card>    
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <FooterBlack />
            </>
        );
    }
}

export default CommercialCustomerAccount;

