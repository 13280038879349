import React,{Component} from "react";
import _ from "lodash";
import Joi from "@hapi/joi"
import Select from "react-select";


import http             from "../../../../services/httpService";
import {getUSStateList} from "../../../../services/dropDownListsService";
import {getCountryList} from "../../../../services/dropDownListsService";
import UserService      from "../../../../services/userService";
import SchoolService      from "../../../../services/schoolService";

import BaseForm from "../../../BaseClass/Form"
import FormErrorWidget from "../../../Common/FormErrorWidget";
import {scrollTop} from '../../../../utils/helperUtils';

// reactstrap components
import {
    Badge,
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Form,
    FormGroup, 
    Label, 
    Input, 
    InputGroupAddon,
    InputGroupText,
    InputGroup,
} from "reactstrap";

class StorageDeliveryAddressInformation extends BaseForm{
    deliveryChoiceButtons = [
        {
            id:1,
            buttonLabel:"Same as Pickup Address",
            stateKey:'deliverToYourCurrentAddress',
        },
        {
            id:2,
            buttonLabel:"Enter a New Delivery Address",
            stateKey:'deliverToNewAddress',
        },
        {
            id:3,
            buttonLabel:"I will enter Delivery Address at a later time",
            stateKey:'deliveryAddressLater',
        }
    ]

    divAreaToScrollForError = "sectionArea"

    validationSchema = "";

    deliveryAddressSchema = Joi.object({
        deliverToYourCurrentAddress:Joi.any().optional(),
        deliverToNewAddress:Joi.bool().required(),
        deliveryAddressLater:Joi.any().optional(),
        isDormitory:Joi.string().label("Is it a Dormitory"),
        dormitoryName: Joi.alternatives().conditional(
            'isDormitory', { 
                is: 'Yes', 
                then: Joi.object().keys({
                    value:Joi.number().required(), 
                    label:Joi.any().optional(),
                }), 
                otherwise: Joi.any().optional()
            }
        ),
        dormitoryRoomNumber: Joi.alternatives().conditional(
            'isDormitory', { 
                is: 'Yes', 
                then: Joi.string().required(), 
                otherwise: Joi.any().optional()
            }
        ).label('Dormitory Room Number'),
        streetNumber: Joi.alternatives().conditional(
            'isDormitory', { is: 'No', then: Joi.string().required().label('Street Number'),otherwise: Joi.any().optional() }
        ),
        addressLine1: Joi.alternatives().conditional(
            'isDormitory', { is: 'No', then: Joi.string().required().label('Address Line 1'),otherwise: Joi.any().optional() }
        ),
        addressLine2: Joi.any().optional(),
        apartmentNumber:Joi.any().optional(),
        zoneId: Joi.any().optional(),
        city:Joi.string().required().label('City'),
        state:Joi.object().keys({
            value:Joi.string().required(), 
            label:Joi.any().optional(),
        }),
        postalCode:Joi.string().required().label('Postal Code'),
        country:Joi.string().required().label('Country'), 
    }) ;

    errorMessages = {
        "state":{
            "object.base":"Delivery Address State is not allowed to be empty",
        },
        "dormitoryName":{
            "object.base":"Delivery Address Dormitory Name is not allowed to be empty",
        },
    }
    
    constructor(){
        super();
        this.state = {
            stateList:[{ value: "", label: "", isDisabled: true }],
            dormitoryList:[{ value: "", label: "", isDisabled: true }],
            dormitoryObjectList:"",
            data:{
                deliveryAddress:{
                    deliverToYourCurrentAddress:"", 
                    deliverToNewAddress:"",
                    deliveryAddressLater:"",
                    isDormitory:"",
                    dormitoryName:"", //{value:"", label:""} this corresponds to the react-select value setup
                    dormitoryRoomNumber:"",
                    streetNumber:"",
                    addressLine1:"",
                    addressLine2:"",
                    apartmentNumber:"",
                    zoneId:"",
                    city:"",
                    state:"",
                    postalCode:"",
                    country:"USA", 
                }
            },
            errors:{},
        }
    }

    componentDidMount(){
        this.mapModelToView();
    }

    componentDidUpdate(prevProps){
        const {user} = this.props;
        const {user:previousUser} = prevProps;
        if(_.isEmpty(previousUser.primaryInformation) && !_.isEmpty(user.primaryInformation)){
            this.mapModelToView();
        }
    }

    getAddressForDisplay = ()=>{
        let displayAddress  = "";
        const {user} = this.props;
        const {primaryInformation} = user;
        if(primaryInformation !== ""){
            const {line1:addressLine1,line2:addressLine2} = UserService.getFormattedUserAddress(primaryInformation);
            return <>{addressLine1} <br/> {addressLine2}</>;
        }

        return displayAddress
    }

    handleDeliveryChange = (value,name)=>{
        const deliveryAddress = {
            ...this.state.data.deliveryAddress,
            deliverToYourCurrentAddress:"", 
            deliverToNewAddress:"",
            deliveryAddressLater:"", 
            [name]:value,
        }

        const data = {
            ...this.state.data,
            deliveryAddress,
        }

        this.setState({
            data
        })
    }

    mapModelToView = async ()=>{
        
        try{
            const stateList = getUSStateList();

            const {user} = this.props;
            const {updatePrimaryAddressFlag} = user;
            const {deliveryAddress:deliveryAddressUser} = user.storage;
            const {
                isSameAsPickupAddress,
                deliverToNewAddress,
                deliveryAddressLater
            } = deliveryAddressUser;
        
            const {campus_id:school_id} = user.primaryInformation;
            
            const school = {id:school_id};
            const dormitoryList = await SchoolService.getDropdownListOfDormitoryBySchool(school);
            const dormitoryObjectList = await SchoolService.getListOfDormitoryBySchool(school);

            //Set delivery address
            const deliveryAddress = {
                ...deliveryAddressUser,
                deliverToYourCurrentAddress:isSameAsPickupAddress,
                deliveryAddressLater
            };
            
            if(deliveryAddress.deliverToNewAddress === true){
                if(deliveryAddress.isDormitory){
                    deliveryAddress.isDormitory = "Yes";
                    deliveryAddress.dormitoryName={
                        value:deliveryAddress.dormitory.id,
                        label:deliveryAddress.dormitory.name,
                    };
                    deliveryAddress.dormitoryRoomNumber = deliveryAddress.dormitory.roomNumber;
                }
                else{
                    deliveryAddress.isDormitory = "No";
                    deliveryAddress.dormitoryName="";
                    deliveryAddress.dormitoryRoomNumber = "";
                }

                if(deliveryAddress.state){
                    deliveryAddress.state = _.find(stateList,{'value':deliveryAddress.state});
                }

                delete deliveryAddress.dormitory;
            }
            else{
                deliveryAddress.dormitoryName="";
                deliveryAddress.dormitoryRoomNumber = "";     
                delete deliveryAddress.dormitory;
            }
            //End of Set delivery address
            
            const data = {
                ...this.state.data,
                deliveryAddress,
            }

            this.setState(
                {
                    stateList,
                    dormitoryList,
                    dormitoryObjectList,
                    data,
                }
            );
        }
        catch(ex){
            console.log(`Component did mount on Laundry Student Info ${ex}`);
        }

          
        
    }

    setAddressDormitory = (valueObject,addressType)=>{
        const {dormitoryObjectList,stateList} = this.state; 
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        address.dormitoryName = valueObject;

        //Get the dorm object by selected dorm name to populate the city state zip
        const selectedDormitoryObject = _.find(dormitoryObjectList,{"id":valueObject.value});
        //console.log(selectedDormitoryObject);
        
        const selectedStateObject = _.find(stateList,{"value":selectedDormitoryObject.state});
        
        address.city = selectedDormitoryObject.city;
        address.state = selectedStateObject;
        address.postalCode = selectedDormitoryObject.zip;
        
        data[addressType] = address;
        this.setState({data});
    }

   
    //This gets executed once the parent submission process is executed
    submitLocal = ()=>{
        const {onClickOfNextButtonFromDeliveryAddressInformation} = this.props;
        console.log('submit local');

        const formData = this.state.data;
        return onClickOfNextButtonFromDeliveryAddressInformation(formData)
        
        
    }

    validateLocal= ()=>{
        const {
            deliverToYourCurrentAddress,
            deliverToNewAddress,
            deliveryAddressLater,
        } = this.state.data.deliveryAddress;

        if(!deliverToYourCurrentAddress && !deliverToNewAddress && !deliveryAddressLater){
            localErrors = "";
        
           const localErrors = {selectionError:"Please select one of the options for your delivery address"};
           if(!_.isEmpty(localErrors)){
                return localErrors;
            }
        }

        if(deliverToNewAddress === true){
            const options = {abortEarly:false,allowUnknown:true};
            //console.log(this.state.data);
            //console.log(this.validationSchema.validate(this.state.data,options))
            let {error:errorListFromJoi} = this.deliveryAddressSchema.validate(this.state.data.deliveryAddress,options);
            //console.log(errorListFromJoi.details);
            if( errorListFromJoi === undefined) return null;

            const errorList = {};
            if(errorListFromJoi){
                errorListFromJoi.details.map(
                    function(errorObject){
                        //console.log(errorObject);
                        if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                            const key = errorObject.context.key;
                            const joiType = errorObject.type;
                            //console.log(this.errorMessages);
                            if(_.has(this.errorMessages, [key, joiType]))
                                return errorList[errorObject.path[0]] = this.errorMessages[key][joiType];
                            else
                                return errorList[errorObject.path[0]] = errorObject.message;
                        }
                        else
                        return errorList[errorObject.path[0]] = errorObject.message;
                    }.bind(this)
                );
            }
            
            
            
            const errors = {...errorList} 
            //const errors = {...primaryInformationErrors,...pickUpAddressErrors,...deliveryAddressErrors} 
        
            console.log(errors);
            return Object.keys(errors).length > 0 ? errors:null;
        }

        
        return null;
    }

    render(){
        const {dormitoryList,data,errors,stateList,} = this.state;
        const {deliveryAddress} = data;
        const {
            deliverToYourCurrentAddress, 
            deliverToNewAddress,
            deliveryAddressLater, 
        } = deliveryAddress;

        const {onClickOfBackButtonFromDeliveryAddressInformation}  = this.props;
        
        
        const addressIsDormitoryCheckedYes = (deliveryAddress.isDormitory === "Yes") ? {defaultChecked:true}:{};
        const addressIsDormitoryCheckedNo = (deliveryAddress.isDormitory === "No") ? {defaultChecked:true}:{};
        const addressIsDormitoryCSS = "form-check-radio";
        
        return(
            <Container id="mainContentArea">
                <Row className="title-row justify-content-between">
                    <Col lg="5">
                        <h3 className="shop">Signup for Storage - Student</h3>
                    </Col>
                    <Col lg="6">
                        <div className="text-lg-right">
                            <span className="text-muted">Step 3</span>
                            <Button color="link">
                            <i className="fa fa-shopping-cart" /> Enter Delivery Address
                            </Button>
                        </div>
                    </Col>
                </Row> 
                <Row className="title-body-row">
                    <Col>
                        <Card>
                            <CardBody>
                                {/*Error display */}
                                {!_.isEmpty(errors) &&
                                    <FormErrorWidget
                                    errors={errors}
                                    />
                                }
                                {/* End of Error display */}

                                
                                <div className="mt-2 mb-4">
                                    <h6 className="card-category">
                                        <span className="text-left">
                                        Please enter delivery address
                                        </span>
                                    </h6>
                                    <hr/>

                                    
                                    {
                                        this.deliveryChoiceButtons.map(s=>{
                                            return(
                                                <FormGroup key={s.id} row className="text-center">
                                                    <Col sm="2"></Col> 
                                                    <Col sm={{ size: 8}}> 
                                                        <Button
                                                            className="btn-round mr-1"
                                                            color="danger"
                                                            outline={ (deliveryAddress[s.stateKey] !== true) && true}
                                                            block
                                                            type="button"
                                                            name={s.stateKey}
                                                            id={s.stateKey}
                                                            value={true}
                                                            onClick={(e)=>{
                                                                e.preventDefault();
                                                                return this.handleDeliveryChange(true,s.stateKey);
                                                            }}
                                                        >
                                                            {s.buttonLabel}
                                                        </Button>
                                                    </Col>
                                                    <Col sm="2"></Col>
                                                </FormGroup>   

                                            );
                                        })      
                                         
                                    }
                                    
                                </div>


                                {
                                    deliverToNewAddress && 
                                    <Row>
                                        <Col>
                                            <h6>Enter your delivery address:</h6>

                                            <FormGroup row>
                                                <Label for="" xs={6} sm={4} md={2}>Is it a dormitory?</Label>
                                                <Col xs={2} sm={1} className="mt-1">
                                                    <div className={addressIsDormitoryCSS}>
                                                        <Label check>
                                                            <Input
                                                                {...addressIsDormitoryCheckedYes}
                                                                defaultValue="Yes"
                                                                id="deliveryAddressIsDormitory1"
                                                                name="isDormitory"
                                                                type="radio"
                                                                onChange={e=>{this.handleChangeNestedAttribute(e,"deliveryAddress")}}
                                                            />
                                                            Yes
                                                            <span className="form-check-sign" />
                                                        </Label>
                                                    </div>
                                                </Col>
                                                <Col xs={2} sm={1} className="ml-2 mt-1">
                                                    <div className={addressIsDormitoryCSS}>
                                                        <Label check>
                                                            <Input
                                                                {...addressIsDormitoryCheckedNo}
                                                                defaultValue="No"
                                                                id="deliveryAddressIsDormitory2"
                                                                name="isDormitory"
                                                                type="radio"
                                                                onChange={e=>{this.handleChangeNestedAttribute(e,"deliveryAddress")}}
                                                            />
                                                            No
                                                            <span className="form-check-sign" />
                                                        </Label>
                                                    </div>
                                                </Col>
                                                
                                            </FormGroup>

                                            {
                                                (deliveryAddress.isDormitory === "Yes") &&    
                                                <FormGroup row>
                                                    <Label for="pickUpDormitory" sm={2}>Dorm Name</Label>
                                                    <Col xs={10} sm={4}>
                                                        <FormGroup>
                                                            <Select
                                                                className="react-select react-select-default"
                                                                classNamePrefix="react-select"
                                                                name="dormitoryName"
                                                                id="dormitoryName"
                                                                value={deliveryAddress.dormitoryName}
                                                                onChange={value => this.setAddressDormitory(value,"deliveryAddress")}
                                                                options={dormitoryList}
                                                                placeholder="Dorm"
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Label for="addressLine1" sm={2}>Room No.</Label>
                                                    <Col xs={10} sm={4}>
                                                        <Input 
                                                        type="text" 
                                                        name="dormitoryRoomNumber" 
                                                        id="dormitoryRoomNumber" 
                                                        placeholder="Room No" 
                                                        maxLength="10"
                                                        value={deliveryAddress.dormitoryRoomNumber} 
                                                        onChange={e=>{this.handleChangeNestedAttribute(e,"deliveryAddress")}}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            }

                                            {
                                                (deliveryAddress.isDormitory !== "Yes") &&   
                                                <FormGroup row>
                                                    <Label for="streetNumber" sm={2}>Street #</Label>
                                                    <Col sm={4}>
                                                        <Input 
                                                        type="text" 
                                                        name="streetNumber" 
                                                        id="streetNumber" 
                                                        placeholder="Street #"
                                                        maxLength="6" 
                                                        value={deliveryAddress.streetNumber} 
                                                        onChange={e=>{this.handleChangeNestedAttribute(e,"deliveryAddress")}}
                                                        />
                                                    </Col>

                                                    <Label for="apartmentNumber" sm={2}>Apt #</Label>
                                                    <Col sm={4}>
                                                        <Input 
                                                        type="text" 
                                                        name="apartmentNumber" 
                                                        id="apartmentNumber" 
                                                        placeholder="Apt Number" 
                                                        maxLength="10"
                                                        value={deliveryAddress.apartmentNumber} 
                                                        onChange={e=>{this.handleChangeNestedAttribute(e,"deliveryAddress")}}
                                                        />
                                                    </Col>
                                                    
                                                </FormGroup>
                                            }               
                                                
                                            {
                                                (deliveryAddress.isDormitory !== "Yes") &&   
                                                <FormGroup row>
                                                    <Label for="addressLine1" sm={2}>Street Name</Label>
                                                    <Col sm={10}>
                                                        <Input 
                                                            type="text" 
                                                            name="addressLine1" 
                                                            id="addressLine1" 
                                                            placeholder="Street Name" 
                                                            value={deliveryAddress.addressLine1} 
                                                            onChange={e=>{this.handleChangeNestedAttribute(e,"deliveryAddress")}}
                                                        />
                                                    </Col>
                                                    
                                                </FormGroup>
                                            }
                                            {
                                                (deliveryAddress.isDormitory !== "Yes") &&  
                                                <FormGroup row className="d-none">
                                                    <Label for="addressLine2" sm={2}>Address Line 2</Label>
                                                    <Col sm={10}>
                                                        <Input 
                                                            type="text" 
                                                            name="addressLine2" 
                                                            id="addressLine2" 
                                                            placeholder="Address Line 2" 
                                                            value={deliveryAddress.addressLine2} 
                                                            onChange={e=>{this.handleChangeNestedAttribute(e,"deliveryAddress")}}
                                                        />
                                                    </Col>
                                                    
                                                </FormGroup>
                                            }    
                                                
                                                
                                            <FormGroup row>
                                                <Label for="city" sm={2}></Label>
                                                <Col xs={12} sm={3}>
                                                    <Input 
                                                    type="text" 
                                                    name="city" 
                                                    id="city" 
                                                    placeholder="City" 
                                                    value={deliveryAddress.city} 
                                                    onChange={e=>{this.handleChangeNestedAttribute(e,"deliveryAddress")}}
                                                    />
                                                </Col>
                                                <Label for="state" xs={12} sm={2} className="d-block d-sm-none"></Label>
                                                <Col xs={12} sm={4}>
                                                    <FormGroup>
                                                        <Select
                                                            className="react-select react-select-default"
                                                            classNamePrefix="react-select"
                                                            name="state"
                                                            id="state"
                                                            value={deliveryAddress.state}
                                                            onChange={value => this.handleDropdownChangeNestedAttribute(value,"state","deliveryAddress",)}
                                                            options={stateList}
                                                            placeholder="State"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} sm={3}>
                                                    <Input 
                                                        type="text" 
                                                        name="postalCode" 
                                                        id="postalCode" 
                                                        placeholder="Zip" 
                                                        maxLength="5"
                                                        value={deliveryAddress.postalCode} 
                                                        onChange={e=>{this.handleChangeNestedAttribute(e,"deliveryAddress")}}
                                                    />
                                                </Col>
                                            </FormGroup>

                                            

                                        </Col>
                                    </Row>  
                                
                            
                            
                                }
                            
                                
                               
                                
                                

                                <FormGroup row className="mt-5 justify-content-between">
                                    <Col xs={{ size: 2 }}>
                                        <Button 
                                        className="btn-rounded btn-danger"
                                        onClick={()=>onClickOfBackButtonFromDeliveryAddressInformation()}
                                        >
                                        Back
                                        </Button>
                                    </Col>
                                    <Col xs={{ size: 6}} sm={{ size: 4}} className="text-right">
                                        <Button 
                                        className="btn-rounded btn-danger"
                                        onClick={this.submitForm}
                                        >
                                        Save & Proceed
                                        </Button>
                                    </Col>
                                </FormGroup>
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }


}

export default StorageDeliveryAddressInformation;

