import React,{Component} from "react";
import {StripeProvider} from 'react-stripe-elements';
import {Elements} from 'react-stripe-elements';


import PurchaseForm from "../../components/Consumer/GiftCard/PurchaseForm";
import ShortNavbar from "../../components/Navbars/ShortNavbar.js";
import SignupPageHeader from "../../components/Headers/SignupPageHeader";
import FooterWhite from "../../components/Footers/FooterWhite";

import GiftCardImage from "../../assets/mylazybones/img/GiftCards/GiftCard_Landing.jpg"

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Media,
    NavItem,
    NavLink,
    Nav,
    Container,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Label,
    Row,
    Col,
    Input, 
  } from "reactstrap";

class GiftCards extends Component{
    defaultStripeUniversityId = 1

    constructor(){
        super()
        this.state = {
            data:{
                stripeMerchantAccountUniverityId:this.defaultStripeUniversityId,
                universityId:"",
                recaptchaResponse:"",
                amount:"",
                purchaserName:"",
                purchaserEmail:"",
                giftRecipientName:"",
                giftRecipientEmail:"",
                giftRecipientCustomNote:"",
                giftCertificateShippingDate:"",
                isStudent:"",
                school:"",
                creditCardName:"",
                billingAddress:{
                    addressLine1:"",
                    addressLine2:"",
                    city:"",
                    state:"",
                    postalCode:"",
                    country:{label:"United States of America",value:"US"},
                },
            },
        }
    }

    handleChangeOfUniversity = (universityId,formData)=>{
        this.setState({
            data:formData
        })
    }

    render(){
        const {data} = this.state;
        const {step} = this.props.match.params;
        const displayStep = step || "purchase"

        const {stripeMerchantAccountUniverityId} = data;

        return(
            <>
            <StripeProvider key={stripeMerchantAccountUniverityId} apiKey={process.env[`REACT_APP_STRIPE_PRODUCTION_KEY_UNIVERSITY_ID_${stripeMerchantAccountUniverityId}`]}>  
                <React.Fragment>
                    <ShortNavbar {...this.props} />
                    <SignupPageHeader />
                    <div className="main">
                        <div className="section section-nude-gray"> 
                            <Container>
                                <div id="description-areas">
                                    {
                                        displayStep === "purchase" &&   
                                        <Row>
                                            <Col xs="12" md="8">
                                                <h2 className="shop">Order your Gift Voucher:</h2>
                                                <div className="mb-4">
                                                    <p className="mt-2">
                                                    Make someone truly happy with the gift of cleanliness! All gift certificate purchases are emailed directly to your lucky someone on the date you choose. Just enter that info in the appropriate section below and we will take care of the rest! As a guideline: a month's worth of laundry service averages about $75. Please note: There is a $20 minimum for all gift card purchases.
                                                    </p>
                                                </div>
                                                <div className="mt-2">
                                                    <Elements>
                                                        <PurchaseForm 
                                                            stripeMerchantAccountUniverityId={stripeMerchantAccountUniverityId}
                                                            handleChangeOfUniversity={this.handleChangeOfUniversity}
                                                            formData={data}
                                                            {...this.props}
                                                        />
                                                    </Elements>
                                                </div>

                                            </Col>
                                            <Col md="4" className="d-none d-md-block">
                                                <img
                                                    alt="..."
                                                    className="img-rounded img-responsive"
                                                    src={GiftCardImage}
                                                />
                                            </Col>
                                        </Row>  
                                    }

                                    {
                                        displayStep === "confirmation" &&  
                                        <Row>
                                            <Col xs="12" md="8">
                                                <h2 className="shop">Gift Voucher: Confirmation</h2>
                                                <div className="mb-4">
                                                    <p className="mt-2">
                                                        Thank you for your gift certificate purchase. You will receive an email confirmation shortly.
                                                    </p>
                                                    <p>
                                                    </p>
                                                </div>
                                                
                                            </Col>
                                            <Col md="4" className="d-none d-md-block">
                                                <img
                                                    alt="..."
                                                    className="img-rounded img-responsive"
                                                    src={GiftCardImage}
                                                />
                                            </Col>
                                        </Row>  
                                    }
                                            
                                </div>
                            </Container>
                        </div>
                    </div>
                    <FooterWhite />
                </React.Fragment>
            </StripeProvider>
            </>
        )
    }
}  

export default GiftCards;