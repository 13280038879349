import React,{Component} from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import auth from "services/authService";

// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Badge,
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  NavLink,
  Container,
  UncontrolledTooltip
} from "reactstrap";
// core components



class UserNotifications extends Component{
    ctr = 1;

    /*
        user:[
                {
                    type:"credit-card",
                    message:"Update your credit card",
                },
                {
                    type:"home-address",
                    message:"Enter your home address"
                }
            ],
    */
    generateUserNotifications = (notificationList,accountType)=>{
        const {history} = this.props;
        
        return notificationList.map(n=>{
            const {type,message} = n;
            let navigationLink = ""
            switch(type){
                case "credit-card":
                    navigationLink = "/myaccount/view-profile/payment-info";
                    break;
                case "home-address":
                    navigationLink =  "/myaccount/view-profile/payment-info";
                    break;
                
            }
            return <a   
                        key={this.ctr++}
                        className="notification-item cursor"
                        onClick={(e) =>{
                            e.preventDefault();
                            history.push(navigationLink)
                        }}
                    >
                        <div className="notification-text">
                            <Badge color="info" pill>
                                <i className="nc-icon nc-alert-circle-i" />
                            </Badge>
                            <span className="message">
                                {message}
                            </span>
                        </div>
                    </a>
        })
    }
    
    render(){
        const {match,userNotifications} = this.props;
        const {notificationCount} = userNotifications;
        
        const accountType = match.params.accountType || ""
  
        
        return(
            <UncontrolledDropdown nav inNavbar >
                <DropdownToggle
                className="btn-just-icon d-none d-lg-block"
                data-toggle="dropdown"
                >
                <i className="nc-icon nc-email-85" />
                <Badge
                    className="notification-bubble"
                    color="danger"
                    pill
                >
                    {notificationCount}
                </Badge>
                </DropdownToggle>
                <DropdownMenu
                className="dropdown-wide dropdown-notification"
                tag="ul"
                right
                >
                <DropdownItem header>
                    You have {notificationCount} unread notifications
                </DropdownItem>
                <li>
                    <ul className="dropdown-notification-list scroll-area">
                    {
                        userNotifications.user.length && 
                        this.generateUserNotifications(userNotifications.user,accountType)
                    }
                    
                    <a
                        className="notification-item"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                    >
                        <div className="notification-text">
                        <Badge color="success" pill>
                            <i className="nc-icon nc-chat-33" />
                        </Badge>
                        <span className="message">
                            <b>Patrick</b>
                            mentioned you in a comment.
                        </span>
                        <br />
                        <span className="time">20min ago</span>
                        <Button
                            className="btn-just-icon read-notification btn-round"
                            color="default"
                        >
                            <i className="nc-icon nc-check-2" />
                        </Button>
                        </div>
                    </a>
                    <a
                        className="notification-item"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                    >
                        <div className="notification-text">
                        <Badge color="info" pill>
                            <i className="nc-icon nc-alert-circle-i" />
                        </Badge>
                        <span className="message">
                            Our privacy policy changed!
                        </span>
                        <br />
                        <span className="time">1day ago</span>
                        </div>
                    </a>
                    <a
                        className="notification-item"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                    >
                        <div className="notification-text">
                        <Badge color="warning" pill>
                            <i className="nc-icon nc-ambulance" />
                        </Badge>
                        <span className="message">
                            Please confirm your email address.
                        </span>
                        <br />
                        <span className="time">2days ago</span>
                        </div>
                    </a>
                    <a
                        className="notification-item"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                    >
                        <div className="notification-text">
                        <Badge color="primary" pill>
                            <i className="nc-icon nc-paper" />
                        </Badge>
                        <span className="message">
                            Have you thought about marketing?
                        </span>
                        <br />
                        <span className="time">3days ago</span>
                        </div>
                    </a>
                    </ul>
                </li>
                {/* end scroll area */}
                </DropdownMenu>
            </UncontrolledDropdown>

        )


    }
}

export default UserNotifications;
