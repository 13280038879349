import React,{Component} from "react";
import accounting from "accounting";
import _ from "lodash";

import auth from "../../../../services/authService";

import {scrollTop} from '../../../../utils/helperUtils';
import customToast from '../../../Common/CustomToast'



import ShoppingCartWidget from "../common/ShoppingCartWidget";
import SupplyBundleItemCard from "./displayComponents/SupplyBundleItemCard";
import SupplyBundleItemCardMobile from "./displayComponents/SupplyBundleItemCardMobile";
import SupplyIndividualItemCard from "./displayComponents/SupplyIndividualItemCard";
import SupplyIndividualItemCardMobile from "./displayComponents/SupplyIndividualItemCardMobile";


import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Badge,
    Button,
    ButtonGroup,
    Input,
    Nav,
    NavLink,
    NavItem,
    TabContent,
    TabPane,
    Table,
    Media,
    UncontrolledTooltip
} from "reactstrap";

class SuppliesCatalog extends Component{
    individualItemsGridSetting = {
        numberOfColumnsPerRow:2
    }

    bundleGridSetting = {
        numberOfColumnsPerRow:1
    }

    constructor(){
        super();
        this.state = {
            pills:"1",
            itemCount:0,
            checkoutFooter:false,
        }    
    }

    componentDidMount(){
        //console.log("Supplies Catalog did mount");
        //console.log(this.props);
        const shoppingCart = {...this.props.globalApp.shoppingCart}
        const items = [...shoppingCart.supplies.items];
        if(items.length > 0)
         this.setCheckoutFooter(true); 

    }

    allowedToShop = ()=>{
        let allowedToShop = true;
        const shoppingCart = {...this.props.globalApp.shoppingCart}
        const {supplies} = shoppingCart;
        if(supplies.provideSuppliesToLoggedInUser === false){
            allowedToShop = false;
        }
        return allowedToShop;
    }
    
    /*Desktop mode */
    displayBundleItemList = (allowedToShop = true)=>{
        let returnData = [];
        const {productCatalog} = this.props;
        if(typeof productCatalog === 'object' && productCatalog != null){
            const {bundles:productList} = productCatalog.products;
            const numberOfProducts  =  productList.length;
            
            const numberOfColumnsPerRow = this.bundleGridSetting.numberOfColumnsPerRow;
            const numberOfRows = Math.ceil(numberOfProducts/numberOfColumnsPerRow);
            
            for(let row=0;row<numberOfRows;row++){
                let startItemIndex = row * numberOfColumnsPerRow;
                let endItemIndex = startItemIndex + numberOfColumnsPerRow
                let itemsPerRowToProcess = productList.slice(startItemIndex,endItemIndex);

                let itemsPerColumn = itemsPerRowToProcess.map(p=>{
                    return(
                        <Col key={p.id}>
                            <SupplyBundleItemCard
                                product={p}
                                handleAddToCart = {this.handleAddToCart}
                                editMode={allowedToShop}
                            />
                        </Col>
                    )
                })

                let individualRowComponent = <Row key={row}>{itemsPerColumn}</Row>
                returnData.push(individualRowComponent)
            }
        }
        else{

        }

        return returnData;
    }

    /*Mobile mode */
    displayBundleItemListMobile = (allowedToShop = true)=>{
        let returnData = [];
        const {productCatalog} = this.props;
        if(typeof productCatalog === 'object' && productCatalog != null){
            const {bundles:productList} = productCatalog.products;
            
            let itemsPerColumn = productList.map(p=>{
                return(
                    <td key={p.id}  style={{minWidth:"400px"}}>
                        <SupplyBundleItemCardMobile
                            product={p}
                            handleAddToCart = {this.handleAddToCart}
                            editMode={allowedToShop}
                        />
                    </td>
                )
            })

            let individualRowComponent = <tr key="1">{itemsPerColumn}</tr>
            returnData.push(individualRowComponent)
            
        }
        else{

        }

        return returnData;
    }

    /*Desktop mode */
    displayIndividialItemList = (allowedToShop = true)=>{
        let returnData = [];
        const {productCatalog} = this.props;
        if(typeof productCatalog === 'object' && productCatalog != null){
            const {individualProduct:individualProductList} = productCatalog.products;
            const numberOfProducts  =  individualProductList.length;
            
            const numberOfRows = Math.ceil(numberOfProducts/(this.individualItemsGridSetting.numberOfColumnsPerRow));
            const numberOfColumnsPerRow = this.individualItemsGridSetting.numberOfColumnsPerRow;
            
            for(let row=0;row<numberOfRows;row++){
                let startItemIndex = row * numberOfColumnsPerRow;
                let endItemIndex = startItemIndex + numberOfColumnsPerRow
                let itemsPerRowToProcess = individualProductList.slice(startItemIndex,endItemIndex);

                let itemsPerColumn = itemsPerRowToProcess.map(p=>{
                    return(
                        <Col lg="6" key={p.id}>
                            <SupplyIndividualItemCard
                                product={p}
                                handleAddToCart = {this.handleAddToCart}
                                editMode={allowedToShop}
                            />
                        </Col>
                    )
                })

                let individualRowComponent = <Row key={row}>{itemsPerColumn}</Row>
                returnData.push(individualRowComponent)
            }
        }
        else{

        }

        return returnData;
        
    }

    displayIndividialItemListMobile = (allowedToShop = true)=>{
        let returnData = [];
        const {productCatalog} = this.props;
        if(typeof productCatalog === 'object' && productCatalog != null){
            const {individualProduct:individualProductList} = productCatalog.products;
            
            let itemsPerColumn = individualProductList.map(p=>{
                return(
                    <td key={p.id} style={{minWidth:"300px"}}>
                        <SupplyIndividualItemCardMobile
                            product={p}
                            handleAddToCart = {this.handleAddToCart}
                            editMode={allowedToShop}
                        />
                    </td>
                )
            })

            let individualRowComponent = <tr key="1">{itemsPerColumn}</tr>
            returnData.push(individualRowComponent)
            
        }
        else{

        }

        return returnData;
        
    }

    getNumberOfItemsInCart = ()=>{
        const shoppingCart = {...this.props.globalApp.shoppingCart}
        const items = [...shoppingCart.supplies.items];
        return items.length;
    }

    

    handleAddToCart = (e,product = null,quantity =1)=>{
        e.preventDefault(); 
        const {updateShoppingCart} = this.props;
        const shoppingCart = {...this.props.globalApp.shoppingCart}
        const items = [...shoppingCart.supplies.items];
        
        if(items.length > 0){
            const index = _.findIndex(items, {'id':product.id,'isBundle':product.isBundle})
            if(index !== -1){
                let {quantity:currentQuantity} = items[index]
                currentQuantity = quantity;
                items[index] = {
                    ...product,
                    quantity:currentQuantity
                }
            }else{
                items.push({
                    ...product,
                    quantity
                })
            }
        }
        else
         items.push({
             ...product,
             quantity
         })

         shoppingCart.supplies.items = items;
         updateShoppingCart(shoppingCart);
         this.setCheckoutFooter(true); 
         //this.updateItemCount();
         //scrollTop('sectionArea');
         
         
    }

    handleViewCart = (e)=>{
        e.preventDefault();
        const {updateShoppingCart} = this.props;
        const shoppingCart = {...this.props.globalApp.shoppingCart}
        const {couponProcessingFlag,cartTotal,discount,supplies,university} = shoppingCart;
        const {minimum_supply_cart_total:minimumShoppingCartAmount} = university;
        const {subTotal} = cartTotal
        if(subTotal < minimumShoppingCartAmount){
            customToast.error(`Please enter a minimum amount of ${accounting.formatMoney(minimumShoppingCartAmount)} to proceed.`)
            return;
        }
        
        if(!auth.getCurrentUser()){
            //Setting the signup path as we have purchased supplies so a specific signup process 
            shoppingCart.signupProductPath = "/signup/supplies";
            updateShoppingCart(shoppingCart);
        }
            
        this.props.history.push("/shoppingcart");
    }

    proceedToCheckout = (e)=>{
        e.preventDefault();
        const {updateShoppingCart} = this.props;
        const shoppingCart = {...this.props.globalApp.shoppingCart}
        const {couponProcessingFlag,cartTotal,discount,supplies,university} = shoppingCart;
        const {shippingDate} = supplies;
        const {minimum_supply_cart_total:minimumShoppingCartAmount} = university;
        const {subTotal} = cartTotal
        if(subTotal < minimumShoppingCartAmount){
            customToast.error(`Please enter a minimum amount of ${accounting.formatMoney(minimumShoppingCartAmount)} to proceed.`)
            return;
        }

        if(shippingDate === ""){
            customToast.error(`Please select a delivery date.`)
            return;
        }

        

        if(auth.getCurrentUser()){
            //Logged in user we display delivery and payment screen
            this.props.history.push("/shoppingCart/supplies/checkout");
        }
        else{
            shoppingCart.signupProductPath = "/signup/supplies";
            updateShoppingCart(shoppingCart);
            this.props.history.push("/shoppingCart/supplies/checkout");
        }

        return;
    }

    setPills = (pills)=>{
        this.setState({pills});
    }

    setCheckoutFooter = (flag)=>{
        this.setState({checkoutFooter:true});
    }

    updateItemCount = ()=>{
        let {itemCount} = this.state
        const updatedItemCount = itemCount + 1;
        this.setState({itemCount:updatedItemCount});
    }
    

    render(){
        const {checkoutFooter,pills}  = this.state; 
        const {globalApp} = this.props;
        const itemCount = this.getNumberOfItemsInCart();
         
        const {handleShoppingCartRemoveItem,handleShoppingCartUpdateProductQuantity,productCatalog} = this.props;
        const {postalCode} = globalApp.shoppingCart;
        //console.log(this.props);

        const allowedToShop = this.allowedToShop();

        const individualItemList = this.displayIndividialItemList(allowedToShop);
        const individualItemListMobile = this.displayIndividialItemListMobile(allowedToShop);
        const bundleItemList = this.displayBundleItemList(allowedToShop);
        const bundleItemListMobile = this.displayBundleItemListMobile(allowedToShop);

        
        
        return(
            <>
                <Row>
                    <Col lg="10">
                        <h3 className="title">
                            {`Packing Supplies ${postalCode && `for Postal Code - ${postalCode}`}`}                           
                        </h3>
                    </Col>
                </Row> 

                {/* Desktop view */}
                <div className="d-none d-lg-block">
                    <Row>
                        {/* Display items in grid using horizontal pills */}
                        <Col md="8">
                            <Card className="card-plain bg-white p-2 rounded-lg">
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <Nav className="nav-pills-danger text-center" pills>
                                                <NavItem>
                                                <NavLink
                                                    className={pills === "1" ? "active" : ""}
                                                    onClick={() => {
                                                        this.setPills("1");
                                                    }}
                                                >
                                                    All Supplies
                                                </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                <NavLink
                                                    className={pills === "2" ? "active" : ""}
                                                    onClick={() => {
                                                        this.setPills("2");
                                                    }}
                                                >
                                                    Bundles
                                                </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                <NavLink
                                                    className={pills === "3" ? "active" : ""}
                                                    onClick={() => {
                                                        this.setPills("3");
                                                    }}
                                                >
                                                    Individual Items
                                                </NavLink>
                                                </NavItem>
                                            </Nav>
                                            {/* Pill panes */}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            
                                
                            <TabContent className="mt-3" activeTab={"pills" + pills}>
                                <TabPane tabId="pills1">
                                    {bundleItemList}
                                    
                                    {individualItemList}
                                    
                                </TabPane>
                                <TabPane tabId="pills2">
                                    {bundleItemList}
                                    

                                </TabPane>
                                <TabPane tabId="pills3">
                                    {individualItemList}
 
                                </TabPane>
                                
                            </TabContent>
                                        
                            
                        </Col>  
                        {/* End of Display items in grid using horizontal pills */}

                        {/* Display cart summary*/}                            
                        <Col md="4">
                            {
                                allowedToShop && 
                                <ShoppingCartWidget
                                    {...this.props}
                                    onClickViewCart={this.handleViewCart}
                                    onClickProceedToCheckout = {this.proceedToCheckout}
                                    onUpdateOfItem ={handleShoppingCartUpdateProductQuantity}
                                    onRemoveOfItem = {handleShoppingCartRemoveItem}
                                    editMode={allowedToShop}

                                />
                            }
                            
                        </Col>  
                         {/* End of Display cart summary*/}                              
                    </Row>
                    
                </div>
                {/* Desktop view */}

                {/* Mobile view */}
                <div className="d-block d-lg-none">
                    <div>
                        <Row>
                            <Col>
                                    <Row>
                                        <Col xs="9">
                                          <h5>Individual Items</h5>
                                        </Col>
                                        <Col xs="3" className="text-right">
                                           <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                           <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                        </Col>
                                    </Row>
                                    
                                    
                                    <div className="table-responsive" style={{border:"0px"}}>
                                        <table className="table">
                                            <tbody>
                                                {individualItemListMobile}
                                            </tbody>
                                        </table>
                                        
                                    </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col xs="9">
                                        <h5>Bundles</h5>
                                    </Col>
                                    <Col xs="3" className="text-right">
                                        <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </Col>
                                </Row>
                                <div className="table-responsive" style={{border:"0px"}}>
                                    <table className="table">
                                        <tbody>
                                            {bundleItemListMobile}
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    {   checkoutFooter &&
                        <div className="mobile-cart-footer fixed-bottom border bg-light bx-0 py-2 px-3" style={{height:"60px"}}>
                            <div className="d-flex flex-row-reverse bd-highlight">
                                <div className="p-2 bd-highlight">
                                    <Button
                                        className="btn btn-sm"
                                        color="info"
                                        onClick={this.handleViewCart}
                                        outline
                                    >
                                        View Cart
                                    </Button>
                                </div>
                                <div className="px-3 pt-1 bd-highlight">
                                    <div className="position-relative">
                                        <Button
                                            className="btn-just-icon"
                                            color="info"
                                            data-toggle="dropdown"
                                            onClick={this.handleViewCart}
                                        >
                                            <i className="fa fa-shopping-cart" />
                                        </Button>
                                        <Badge
                                            className="notification-bubble"
                                            color="danger"
                                            pill
                                        >
                                            {itemCount}
                                        </Badge>
                                    </div>
                                </div>
                            </div>
                            <Row className="d-none">
                                <Col xs="5" sm="9"></Col>
                                <Col xs="2" sm="1" className="text-right px-0">
                                    <div className="position-relative">
                                        <Button
                                            className="btn-just-icon"
                                            color="info"
                                            data-toggle="dropdown"
                                            onClick={e => e.preventDefault()}
                                        >
                                            <i className="fa fa-shopping-cart" />
                                        </Button>
                                        <Badge
                                            className="notification-bubble"
                                            color="danger"
                                            pill
                                        >
                                            {itemCount}
                                        </Badge>
                                    </div>
                                
                                </Col>
                                <Col xs="5" sm="2" className="mt-1 text-center px-0" >
                                    <div>
                                        <Button
                                            className="btn btn-sm"
                                            color="info"
                                            onClick={this.handleViewCart}
                                            outline
                                        >
                                            View Cart
                                        </Button>
                                    </div>  
                                
                                </Col>
                            </Row>
                                
                        </div>
  
                    }
                    

                </div> 
                {/* End of Mobile view */}   
            </>
        );
    }
}

export default SuppliesCatalog;

