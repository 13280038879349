import React,{Component} from "react";
import _ from "lodash";
import accounting from "accounting"
import moment from "moment";

import BaseTable from "../../../../../BaseClass/Table"

import {
    Badge,
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,  
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Table,

} from "reactstrap";

class StorageItemsInWarehouse extends Component{

    tableColumnDefinition = [
        { 
            key:"1",
            name:"Item",
            classDefinition:"text-left",
            styleDefinition:{width:"20%"},
            getFieldData: data=>`${data.description}`,
            defaultDataValue:"",
            formattedFieldGetter:"",
        },
        { 
            key:"1.1",
            name:"Delivery Date",
            classDefinition:"text-left",
            styleDefinition:{width:"10%"},
            getFieldData: data=>{
                const {delivery_date:deliveryDate} = data;
                if(deliveryDate && moment(deliveryDate).isBefore(moment())){
                    return <Badge className="mr-1" color="success" pill>
                                <strong>
                                    {`Delivered: ${moment(deliveryDate).format('MMM Do')}`}
                                </strong>
                            </Badge>
                }
                else if(deliveryDate && moment(deliveryDate).isSameOrAfter(moment())){
                    return <Badge className="mr-1" color="info" pill>
                                <strong>
                                    {`Scheduled: ${moment(deliveryDate).format('MMM Do')}`}
                                </strong>
                            </Badge>
                }else
                   return "";

                    
            },
            defaultDataValue:"",
            formattedFieldGetter:"",
        },
        { 
            key:"2",
            name:"Barcode",
            classDefinition:"text-left",
            styleDefinition:{width:"10%"},
            getFieldData:data=>`${data.barcode}`,
            defaultDataValue:"",
            formattedFieldGetter:"",
        },
        { 
            key:"3",
            name:"Insured Value",
            classDefinition:"text-right",
            styleDefinition:{width:"10%"},
            getFieldData:data=>`${accounting.formatMoney(data.ins_value || 0)}`,
            defaultDataValue:"",
            formattedFieldGetter:"",
        },
        { 
            key:"4",
            name:"Price",
            classDefinition:"text-right",
            styleDefinition:{width:"10%"},
            getFieldData:data=>`${accounting.formatMoney(data.price || 0)}`,
            defaultDataValue:"",
            formattedFieldGetter:"",
        },
        { 
            key:"5",
            name:"Tax",
            classDefinition:"text-right",
            styleDefinition:{width:"10%"},
            getFieldData:data=>`${accounting.formatMoney(data.tax || 0)}`,
            defaultDataValue:"",
            formattedFieldGetter:"",
        },
        { 
            key:"6",
            name:"Total",
            classDefinition:"text-right",
            styleDefinition:{width:"10%"},
            getFieldData:data=>`${accounting.formatMoney((data.itemTotal) || 0)}`,
            defaultDataValue:"",
            formattedFieldGetter:"",
        }

           
    ]

    handleBackButton = (e)=>{
        e.preventDefault();
        this.props.history.push("/myaccount/student/storage");
    }

    render(){
        let [storageItemsAvailableForNewDelivery,cumulativeTotalAmount,cumulativeTotalTax] = [[],null,null];

        const {storage} = this.props;
        if(!_.isEmpty(storage)){
            const {nextPickupDeliverySchedule} = storage;
            ({storageItemsNotDeliveredOrDeliveredPast30Days:storageItemsAvailableForNewDelivery,cumulativeTotalAmount,cumulativeTotalTax} = nextPickupDeliverySchedule);
        }
        
        const resultSet = {
            tableHeader:"",
            tableColumn:this.tableColumnDefinition,
            tableData:storageItemsAvailableForNewDelivery || [],
            tableFooterDisplayFlag:true,
        }
        resultSet.tableFooter = <>
                                <tr>
                                    <td colSpan="5" />
                                    <td className="td-total" style={{fontSize:"14px"}}>Tax</td>
                                    <td className="td-total" style={{fontSize:"14px"}}>
                                        {accounting.formatMoney(cumulativeTotalTax)}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="5" />
                                    <td className="td-total" style={{fontSize:"14px"}}>Total</td>
                                    <td className="td-total" style={{fontSize:"14px"}}>
                                        {accounting.formatMoney(cumulativeTotalAmount)}
                                    </td>
                                </tr>
                                </>
        return(
            <>  
                <div>
                {   
                    resultSet && 
                    <BaseTable
                        tableProperties={resultSet}
                    />
                }
                </div>

                <FormGroup row  className="mt-5 justify-content-between">
                    <Col xs={{ size: 2}}>
                        <Button 
                            className="btn-rounded btn-danger"
                            onClick={this.handleBackButton}
                        >
                            Back
                        </Button>
                    </Col>
                    
                </FormGroup>
            </>
        );
    }
}

export default StorageItemsInWarehouse