/*
  This will be used for updating:
  Residental -Primary and Home address fields
*/
import React,{Component} from "react";
import _ from "lodash";
import Joi from "@hapi/joi"
import Select from "react-select";


import http             from "../../../../../../services/httpService";
import {getUSStateList} from "../../../../../../services/dropDownListsService";
import {getCountryList} from "../../../../../../services/dropDownListsService";
import UserService      from "../../../../../../services/userService";
import SchoolService      from "../../../../../../services/schoolService";

import BaseForm from "../../../../../BaseClass/Form"
import FormErrorWidget from "../../../../../Common/FormErrorWidget";

import {scrollTop}     from '../../../../../../utils/helperUtils';
import {renderError}   from '../../../../../../utils/errorUtils';
import customToast     from '../../../../../Common/CustomToast';

// reactstrap components
import {
    Badge,
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Form,
    FormGroup, 
    Label, 
    Input, 
    InputGroupAddon,
    InputGroupText,
    InputGroup,
} from "reactstrap";

class ResidentialPrimaryAddressInformation extends BaseForm{
    divAreaToScrollForError = "description-areas"

    validationSchema = "";

    addressSchema = Joi.object({
        streetNumber: Joi.string().required().label('Street Number'),
        addressLine1: Joi.string().required().label('Address Line 1'),
        addressLine2: Joi.any().optional(),
        apartmentNumber:Joi.any().optional(),
        zoneId: Joi.any().optional(),
        city:Joi.string().required().label('City'),
        state:Joi.object().keys({
            value:Joi.string().required(), 
            label:Joi.any().optional(),
        }),
        postalCode:Joi.string().required().label('Postal Code'),
        country:Joi.string().required().label('Country'),
    }) ;

    errorMessages = {}
    
    constructor(){
        super();
        this.state = {
            stateList:[{ value: "", label: "", isDisabled: true }],
            countryList:[{ value: "", label: "", isDisabled: true }],
            data:{
                updatePrimaryAddressFlag:false,
                pickUpAddress:{
                    streetNumber:"", //Non dormitory street number
                    apartmentNumber:"", //Non dormitory apartment number
                    addressLine1:"",
                    addressLine2:"",
                    zoneId:"",
                    city:"",
                    state:"",
                    postalCode:"",
                    country:"USA",

                },
            },
            errors:{},
        }
    }

    componentDidMount(){
        this.mapModelToView();
    }

    componentDidUpdate(prevProps){
        const {user} = this.props;
        const {user:previousUser} = prevProps;
        if(!_.isEqual(previousUser.primaryInformation,user.primaryInformation)){
            this.mapModelToView();
        }
            
        
    }

    getAddressForDisplay = ()=>{
        let displayAddress  = "Primary address not entered.";
        const {user} = this.props;
        const {primaryInformation} = user;
        if(primaryInformation !== ""){
            const {line1:addressLine1,line2:addressLine2} = UserService.getFormattedUserAddress(primaryInformation);
            if(addressLine1 && addressLine1)
                return <>{addressLine1} <br/> {addressLine2}</>;
            
        }

        return displayAddress
    }

    mapModelToView = async ()=>{
        
        try{
            const stateList = getUSStateList();
            const countryList = getCountryList();

            const {user} = this.props;
            
            const {campus_id:school_id} = user.primaryInformation;
            
            const school = {id:school_id};
        
            const data = {
                ...this.state.data,
            }

            this.setState(
                {
                    stateList,
                    countryList,
                    data,
                }
            );
        }
        catch(ex){
            console.log(`Component did mount ${ex}`);
        }

          
        
    }

    

   
    setUpdateFlagInformation = (updatePrimaryAddressFlag)=>{
        this.setState({
            data:{
                ...this.state.data,
                updatePrimaryAddressFlag,
            }
        })
    }

    //This gets executed once the parent submission process is executed
    submitLocal = async ()=>{
        const {
            handleOnSaveOfCustomerInformation,
            handleDisplayOfProcessingModal,
        } = this.props;
        
        try{
            
            handleDisplayOfProcessingModal(true);
            let {pickUpAddress:pickUpAddressFormData} = this.state.data;

            const pickUpAddress = {
                streetNumber:(pickUpAddressFormData.isDormitory !== "Yes") ? pickUpAddressFormData.streetNumber:"",
                addressLine1:(pickUpAddressFormData.isDormitory !== "Yes") ? pickUpAddressFormData.addressLine1:"",
                addressLine2:(pickUpAddressFormData.isDormitory !== "Yes") ?pickUpAddressFormData.addressLine2:"",
                apartmentNumber:(pickUpAddressFormData.isDormitory !== "Yes") ?pickUpAddressFormData.apartmentNumber:"",
                city:pickUpAddressFormData.city,
                state:(_.has(pickUpAddressFormData,['state','value'])) ? pickUpAddressFormData.state.value:"",
                postalCode:pickUpAddressFormData.postalCode,
                country:"USA", 
            };

            const formData = {
                ...this.state.data,
                pickUpAddress,
            }
            
            const {status,message,errorObject} = await UserService.viewProfileSaveResidentialPrimaryAddress(formData);

            if(status === true){
                handleDisplayOfProcessingModal(false);
                
                //Reset form object
                let addressFormData = {...pickUpAddressFormData}
                _.forIn(addressFormData,(v,k)=>addressFormData[k]="");
               
                this.setState({
                    data:{
                        ...this.state.data,
                        updatePrimaryAddressFlag:false,
                        pickUpAddress:addressFormData,
                    } 
                })
                
                customToast.success(message); 
                //Go back to the parent view for information reload
                return handleOnSaveOfCustomerInformation();
            }
        } 
        catch(ex){
            handleDisplayOfProcessingModal(false);
            /*
            400 signals validation errors from the server
            renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop(this.divAreaToScrollForError); return;}
            }
            else
                renderError(ex);
        }
        
    }

    validateLocal= ()=>{
        //This is anything specific to the form like async validations
        const {updatePrimaryAddressFlag} = this.state.data;
        if(updatePrimaryAddressFlag === false) return null;

        const options = {abortEarly:false,allowUnknown:true};
        let {error:addressError} = this.addressSchema.validate(this.state.data.pickUpAddress,options);
        
        if(addressError === undefined) return null;

        const addressErrors = {};
        if(addressError){
            addressError.details.map(
                function(errorObject){
                    const addressKey = "primaryAddress";
                    const addressLabel = "Primary Address: ";
                    //console.log(errorObject);
                    if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                        const key = errorObject.context.key;
                        const joiType = errorObject.type;
                        //console.log(this.errorMessages);
                        if(_.has(this.errorMessages, [key, joiType]))
                            return addressErrors[`${addressKey}.${errorObject.path[0]}`] = addressLabel + this.errorMessages[key][joiType];
                        else
                            return addressErrors[`${addressKey}.${errorObject.path[0]}`] = addressLabel + errorObject.message;
                    }
                    else
                      return addressErrors[`${addressKey}.${errorObject.path[0]}`] = addressLabel + errorObject.message;
                }.bind(this)
            );
        }
        
        const errors = {...addressErrors} 
        //console.log(errors);
        return Object.keys(errors).length > 0 ? errors:null;
    }

    render(){
        const {
            user,
        } = this.props;

        
        const {data,errors,stateList,} = this.state;
        const currentAddress = this.getAddressForDisplay();

        const   {
            updatePrimaryAddressFlag,
            pickUpAddress,
        } = data;

        
        
        return(
            <Container id="mainContentArea">
                <Row className="title-body-row">
                    <Col>
                        <Card className="card-plain bg-white p-2 rounded-lg">
                            <CardBody>
                                {/*Error display */}
                                {!_.isEmpty(errors) &&
                                    <FormErrorWidget
                                    errors={errors}
                                    />
                                }
                                {/* End of Error display */}

                                
                                <div className="mt-2 mb-4">
                                    <h6 className="card-category">
                                        <span className="text-left">
                                            Primary Address Information:
                                        </span>
                                    </h6>
                                    <hr/>
                                    <Row className="mt-2">
                                        <Col xs="2">
                                            Current Address
                                        </Col>
                                        <Col xs="8">
                                            {currentAddress}
                                        </Col>
                                    </Row>

                                    {
                                        !updatePrimaryAddressFlag && 
                                        <Row className="mt-2">
                                            <Col xs="2">
                                            </Col>
                                            <Col xs="8">
                                                <Button 
                                                    className="btn-round mr-1"
                                                    color="default"
                                                    outline
                                                    size="sm"
                                                    onClick={()=>this.setUpdateFlagInformation(true)}
                                                >
                                                Update Address
                                                </Button>
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            
                                
                                {
                                    updatePrimaryAddressFlag && 
                                    <Row >
                                        <Col>
                                            <h6 className="mb-4">Upate your primary address:</h6>

                                            <FormGroup row>
                                                <Label for="streetNumber" sm={2}>Street #</Label>
                                                <Col sm={4}>
                                                    <Input 
                                                    type="text" 
                                                    name="streetNumber" 
                                                    id="streetNumber" 
                                                    placeholder="Street #" 
                                                    maxLength="6"
                                                    value={pickUpAddress.streetNumber} 
                                                    onChange={e=>{this.handleChangeNestedAttribute(e,"pickUpAddress")}}
                                                    />
                                                </Col>

                                                <Label for="apartmentNumber" sm={2}>Apt #</Label>
                                                <Col sm={4}>
                                                    <Input 
                                                    type="text" 
                                                    name="apartmentNumber" 
                                                    id="apartmentNumber" 
                                                    placeholder="Apt Number" 
                                                    maxLength="10"
                                                    value={pickUpAddress.apartmentNumber} 
                                                    onChange={e=>{this.handleChangeNestedAttribute(e,"pickUpAddress")}}
                                                    />
                                                </Col>
                                                
                                            </FormGroup>

                                            <FormGroup row>
                                                <Label for="addressLine1" sm={2}>Street Name</Label>
                                                <Col sm={10}>
                                                    <Input 
                                                    type="text" 
                                                    name="addressLine1" 
                                                    id="addressLine1" 
                                                    placeholder="Address Line 1" 
                                                    value={pickUpAddress.addressLine1} 
                                                    onChange={e=>{this.handleChangeNestedAttribute(e,"pickUpAddress")}}
                                                    />
                                                </Col>
                                                
                                            </FormGroup>

                                            <FormGroup row>
                                                <Label for="city" sm={2}></Label>
                                                <Col xs={12} sm={3}>
                                                    <Input 
                                                    type="text" 
                                                    name="city" 
                                                    id="city" 
                                                    placeholder="City" 
                                                    value={pickUpAddress.city} 
                                                    onChange={e=>{this.handleChangeNestedAttribute(e,"pickUpAddress")}}
                                                    />
                                                </Col>
                                                <Label for="state" xs={12} sm={2} className="d-block d-sm-none"></Label>
                                                <Col xs={12} sm={4}>
                                                    <FormGroup>
                                                        <Select
                                                            className="react-select react-select-default"
                                                            classNamePrefix="react-select"
                                                            name="state"
                                                            id="state"
                                                            value={pickUpAddress.state}
                                                            onChange={value => this.handleDropdownChangeNestedAttribute(value,"state","pickUpAddress",)}
                                                            options={this.state.stateList}
                                                            placeholder="State"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} sm={3}>
                                                    <Input 
                                                    type="text" 
                                                    name="postalCode" 
                                                    id="postalCode" 
                                                    placeholder="Zip" 
                                                    value={pickUpAddress.postalCode} 
                                                    onChange={e=>{this.handleChangeNestedAttribute(e,"pickUpAddress")}}
                                                    />
                                                </Col>
                                            </FormGroup>

                                            

                                        </Col>
                                    </Row>  
                                
                            
                            
                                }
                                
                                
                                {
                                    updatePrimaryAddressFlag && 
                                    <FormGroup row className="mt-5 justify-content-between">
                                        <Col xs={{ size: 2 }}>
                                            <Button 
                                            className="btn-rounded btn-danger"
                                            onClick={()=>this.setUpdateFlagInformation(false)}
                                            >
                                            Cancel
                                            </Button>
                                        </Col>
                                        <Col xs={{ size: 6}} sm={{ size: 4}} className="text-right">
                                            <Button 
                                            className="btn-rounded btn-danger"
                                            onClick={this.submitForm}
                                            >
                                            Save
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                }
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }


}

export default ResidentialPrimaryAddressInformation;

