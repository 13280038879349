import React,{Component} from "react";
import _ from "lodash";
import Joi from "@hapi/joi";
import Select from "react-select";
import {scrollTop} from '../../../../../../utils/helperUtils';
import FormErrorWidget from "../../../../../Common/FormErrorWidget";
import {renderError} from '../../../../../../utils/errorUtils';




// reactstrap components
import {
    Button,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Label,
    Row,
    Col,
    FormGroup, 
    Modal, 
    Input, 
  } from "reactstrap";


class LabelGeneratorModal extends Component{
    labelTypeList = [
        { value: "", label: "", isDisabled: true },
        { value: "Storage", label: "Storage Labels"},
        { value: "Wash & Store", label: "Wash & Store Labels"},
        { value: "Dry Clean & Store", label: "Dry Clean & Store Labels"},
        /*{ value: "Shipping", label: "Shipping"},*/
    ]

    quantityList = [
        { value: "", label: "", isDisabled: true },
        { value: 1, label: "1"},
        { value: 2, label: "2"},
        { value: 3, label: "3"},
        { value: 4, label: "4"},
        { value: 5, label: "5"},
        { value: 6, label: "6"},
        { value: 7, label: "7"},
        { value: 8, label: "8"},
        { value: 9, label: "9"},
        { value: 10, label: "10"},
        { value: 11, label: "11"},
        { value: 12, label: "12"},
        
    ]

    validationSchema = Joi.object({
        labelType:Joi.object().keys({
            value:Joi.string().required(), 
            label:Joi.any().optional(),
        }).label('Label Type'),
        quantity:Joi.object().keys({
            value:Joi.number().required(), 
            label:Joi.any().optional(),
        }).label('Quantity'),
        
    }) ;

    errorMessages = {
        "labelType":{
            "object.base":"Please select a label type",
        },
        "quantity":{
            "object.base":"Please select quantity"
        }
    }
            
    constructor(){
        super()
        this.state = {
            data:{
                labelType:"",
                quantity:"",
            },
            errors:{

            }
        }
    }

    handleChange = ({currentTarget:input})=>{
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[input.name] = input.value;
        this.setState({data,errors});
        
    }

    handleDropdownChange = (value,name)=>{
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[name] = value;
        this.setState({data,errors});
    }

    submitForm = (e)=>{
        try{
            e.preventDefault();  

            //client side errors will be caught in validae(), server side data error will be caught via try/catch
            const errors =  this.validate();
            this.setState({errors: errors || {}});
            if(errors){scrollTop('labelGeneratorModal'); return;} 
            
            const {handleSaveOperation} = this.props;  
            const data = {...this.state.data};
            const formData = {
                labelType: data.labelType.value,
                quantity: data.quantity.value,
            }

            handleSaveOperation(formData);

            /*
            this.setState({
                data:{
                    labelType:"",
                    quantity:"",
                },
                errors:{}
            });
            */
            return;
        }catch(ex){
            /*
            400 signals validation errors from the server
            renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop(); return;}
            }
            else
            renderError(ex);
        } 
    }

    validate=  ()=>{
        const options = {abortEarly:false,allowUnknown:true};
        //console.log(this.state.data);
        console.log(this.validationSchema.validate(this.state.data,options))
        let {error:errorListFromJoi} = this.validationSchema.validate(this.state.data,options);
        console.log(errorListFromJoi);
        if( errorListFromJoi === undefined) return null;

        const errorList = {};
        if(errorListFromJoi){
            errorListFromJoi.details.map(
                function(errorObject){
                    //console.log(errorObject);
                    if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                        const key = errorObject.context.key;
                        const joiType = errorObject.type;
                        //console.log(this.errorMessages);
                        if(_.has(this.errorMessages, [key, joiType]))
                            return errorList[errorObject.path[0]] = this.errorMessages[key][joiType];
                        else
                            return errorList[errorObject.path[0]] = errorObject.message;
                    }
                    else
                      return errorList[errorObject.path[0]] = errorObject.message;
                }.bind(this)
            );
        }
        
        
        
        const errors = {...errorList} 
        //const errors = {...primaryInformationErrors,...pickUpAddressErrors,...deliveryAddressErrors} 
    
        console.log(errors);
        return Object.keys(errors).length > 0 ? errors:null;
    }

    render(){
        const {modalDisplayFlag,handleModalDisplay,handleShipWithUsModalDisplay} = this.props;
        const {data,errors} = this.state;
        const {labelType,quantity} = data;
        
        return(
            <Modal id="labelGeneratorModal" size="lg" isOpen={modalDisplayFlag} toggle={() => handleModalDisplay(false)}>
                <div className="modal-header">
                    <button
                        className="close"
                        type="button"
                        onClick={() => handleModalDisplay(false)}
                    >
                        <span>×</span>
                    </button>
                    <h5
                        className="modal-title text-center"
                        id="exampleModalLabel"
                    >
                        Generate Storage Labels
                    </h5>
                </div>
                <div className="modal-body">
                    {/*Error display */}
                    {!_.isEmpty(errors) &&
                        <FormErrorWidget
                            errors={errors}
                        />
                    }
                    {/* End of Error display */}

                    <Row>
                        <Col>
                            <h6>Select label type and quantity to download & print labels:</h6>
                            <p>
                                Each label has a unique barcode number, so please only adhere one barcode label to each box/item.
                            </p>
                            <Row>
                                <Col xs="2">
                                    Label type:	
                                </Col>  
                                <Col xs="6">   
                                    <FormGroup>
                                        <Select
                                            className="react-select react-select-default"
                                            classNamePrefix="react-select"
                                            name="labelType"
                                            id="labelType"
                                            value={labelType}
                                            onChange={value => this.handleDropdownChange(value,"labelType")}
                                            options={this.labelTypeList}
                                            placeholder="Label type"
                                            
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="2">
                                    Quantity:	
                                </Col>  
                                <Col xs="6">   
                                    <FormGroup>
                                        <Select
                                            className="react-select react-select-default"
                                            classNamePrefix="react-select"
                                            name="quantity"
                                            id="quantity"
                                            value={quantity}
                                            onChange={value => this.handleDropdownChange(value,"quantity")}
                                            options={this.quantityList}
                                            placeholder="Quantity"
                                            
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <p>
                                If you are in need of shipping services, click <a className="btn-link btn btn-info btn-sm px-0 general-link" onClick={e=>{
                                    e.preventDefault();
                                    handleModalDisplay(false);
                                    return handleShipWithUsModalDisplay(true);
                                }}>here</a> for labels. 
                            </p>
                        </Col>
                    </Row>
                </div>
                <div className={`modal-footer`}>
                    <div className="left-side">
                        <Button
                        className="btn-link"
                        color="default"
                        type="button"
                        onClick={() => handleModalDisplay(false)}
                        >
                        Cancel
                        </Button>
                    </div>
                    <div className="divider" />
                    <div className="right-side">
                        <Button className="btn-link" color="danger" type="button" onClick={this.submitForm}>
                            Generate Labels
                        </Button>
                    </div>
                </div>
            </Modal>
            
        )
    }
}

export default LabelGeneratorModal;