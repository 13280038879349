import React,{Component} from "react";
import _ from "lodash";
import Switch from "react-bootstrap-switch";


// reactstrap components
import {
    Button,
    Row,
    Col,
    Modal, 
    Input, 
    UncontrolledTooltip
    
  } from "reactstrap";

class CustomerEmailAndTextReminderModal extends Component{
    

    constructor(){
        super();
        this.state = {
            data:{
                enrollForTextNotification:false,
                enrollForEmailNotification:true,
            },
            errors:{},
            
            
        }
    }

    closeWindow = ()=>{
        const { 
            handleModalDisplayFlag,
            user 
        } = this.props
        const {email_notifications:emailNotification,texting_ok:textNotification} = user;
        const data = {
            enrollForTextNotification:(textNotification) ? true:false,
            enrollForEmailNotification:(emailNotification) ? true:false,
        };
        this.setState({data})
        handleModalDisplayFlag(false);

    }

    componentDidMount(){
        
    }

    componentDidUpdate(prevProps,prevState){
        this.mapModelToView(prevProps,prevState);
    }

    handleEmailNofificationSwitch = (elem, value)=>{
        
        const data = {...this.state.data}
        data.enrollForEmailNotification = value;
        this.setState({data});

    }

    handleTextNofificationSwitch = (elem, value)=>{
        console.log('handleSwitch. elem:', elem);
        console.log('name:', elem.props.name);
        console.log('new state:', value);

        const data = {...this.state.data}
        data.enrollForTextNotification = value;
        this.setState({data});
    }

    mapModelToView = (prevProps)=>{
        /*
            If props have changed then compare it with previous props and then only update it
        */
        if((this.props.user &&  !prevProps.user)
           ||
           (this.props.user && 
            prevProps.user && 
            !_.isEqual(this.props.user,prevProps.user)
            )
        ){
            const {email_notifications:emailNotification,texting_ok:textNotification} = this.props.user;
            const data = {
                enrollForTextNotification:(textNotification) ? true:false,
                enrollForEmailNotification:(emailNotification) ? true:false,
            };
            this.setState({data})
        }
        
    }
    
    /*  Send state data to the parent laundry detail */
    saveForm = ()=>{
        const data = {...this.state.data}
        this.props.handleSaveOperation(data);
        return;
    }

    render(){
        const { 
            enrollForEmailNotification,
            enrollForTextNotification,
                
        } = this.state.data
        
        
        const { 
            handleModalDisplayFlag,
            //listOfOneTimePickupDates,
            modalDisplayFlag,
            user 
        } = this.props


        return(
                <Modal size="lg" isOpen={modalDisplayFlag} toggle={() => this.closeWindow()}>
                    <div className="modal-header">
                    <button
                        className="close"
                        type="button"
                        onClick={() => this.closeWindow()}
                    >
                        <span>×</span>
                    </button>
                    <h5
                        className="modal-title text-center"
                        id="exampleModalLabel"
                    >
                        Notifications
                    </h5>
                    </div>
                    <div className="modal-body">
                        
                        {/* Text and Email Notification */}
                        <Row>
                            <Col>
                                <h6>Text Notification Setting:</h6>
                                <Row>
                                    <Col lg="6">
                                       <Row className="mb-2">
                                           <Col xs="4">
                                              Cellphone:
                                           </Col>
                                           <Col xs="8">
                                                {user && user.cell}
                                                <i id="icon_info_cellPhone" className="fa fa-info-circle mr-1 text-info d-none" />  
                                           </Col>
                                           <UncontrolledTooltip
                                                delay={0}
                                                placement="right"
                                                target={`icon_info_cellPhone`}
                                            >
                                            We will have a modal to display info saying that we send text to your primary phone. Please update your cellphone in your profile section. 
                                            </UncontrolledTooltip>
                                       </Row>
                                       <Row>
                                           <Col xs="4">
                                              Notification:
                                           </Col>
                                           <Col xs="8">
                                                <Switch
                                                    onColor="success"
                                                    offColor="success"
                                                    value={enrollForTextNotification}
                                                    onChange={this.handleTextNofificationSwitch}
                                                />
                                           </Col>
                                       </Row>
                                       
                                    </Col>
                                    <Col lg="6" className="d-none">
                                        <hr className="d-block d-md-none" />
                                        
                                        <Row className="mb-2">
                                            <Col xs="4">
                                                Email:
                                            </Col>
                                            <Col xs="8">
                                                {user && user.email}
                                                <i id="icon_info_email" className="fa fa-info-circle mr-1 text-info d-none" /> 
                                                <UncontrolledTooltip
                                                delay={0}
                                                placement="right"
                                                target={`icon_info_email`}
                                                >
                                                We will have a modal to display info saying that we send email to your primary email address used for login. Please update your email in your profile section. 
                                                </UncontrolledTooltip> 
                                            </Col> 
                                        </Row>
                                        <Row>
                                           <Col xs="4">
                                              Enroll for Email Notification:
                                           </Col>
                                           <Col xs="8">
                                                <Switch
                                                    onColor="success"
                                                    offColor="success"
                                                    value={enrollForEmailNotification}
                                                    onChange = {this.handleEmailNofificationSwitch}
                                                    disabled={true}
                                                />
                                           </Col>
                                       </Row>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col><strong>I agree to allow Lazybones to send text notifications. Message and data rates may apply. To opt out, switch it to off.</strong></Col>
                                </Row>
                            </Col>
                        </Row>

                        {/* End of Text and Email Notification */}

                        

                        

                    
                    </div>
                    
                    <div className={`modal-footer`}>
                        <div className="left-side">
                            <Button
                            className="btn-link"
                            color="default"
                            type="button"
                            onClick={() => this.closeWindow()}
                            >
                            Cancel
                            </Button>
                        </div>
                        <div className="divider" />
                        <div className="right-side">
                            <Button className="btn-link" color="danger" type="button" onClick={()=>this.saveForm()}>
                                Save Notification Setup
                            </Button>
                        </div>
                    </div>
                </Modal>
        );
    }
}

export default CustomerEmailAndTextReminderModal;