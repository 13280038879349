import React,{Component} from "react";
import _ from "lodash";
import Select from "react-select";
import Joi from "@hapi/joi";
import {scrollTop} from '../../../../../../../utils/helperUtils';
import {getUSStateList} from "../../../../../../../services/dropDownListsService";
import SchoolService from "../../../../../../../services/schoolService";
import UserService from "../../../../../../../services/userService";
import StudentService from "../../../../../../../services/studentService";

import FormErrorWidget from "../../../../../../Common/FormErrorWidget";
import {renderError} from '../../../../../../../utils/errorUtils';

// reactstrap components
import {
    Button,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Label,
    FormGroup, 
    Input, 
  } from "reactstrap";

  class DeliveryAddressSelectionStep1 extends Component{
    divAreaToScrollForError = "deliveryAddressSelectionStep1"

    validationSchema = Joi.object({
        deliverToYourCurrentAddress:Joi.bool().required().label("Please enter Delivery to your current address"),
    }) ;

    deliveryAddressSchema = Joi.object({
        deliverToYourCurrentAddress:Joi.bool().required().label("Please enter Delivery to your current address"),
        isDeliveryAddressYourNewCampusAddress:Joi.bool().required().label("Please enter is delivery address going to be your new campus address"),
        isDormitory:Joi.string().label("Is Dormitory"),
        dormitoryName: Joi.alternatives().conditional(
            'isDormitory', { 
                is: 'Yes', 
                then: Joi.object().keys({
                    value:Joi.number().required(), 
                    label:Joi.any().optional(),
                }), 
                otherwise: Joi.any().optional()
            }
        ),
        dormitoryRoomNumber: Joi.alternatives().conditional(
            'isDormitory', { 
                is: 'Yes', 
                then: Joi.string().required(), 
                otherwise: Joi.any().optional()
            }
        ).label('Room Number'),
        streetNumber: Joi.alternatives().conditional(
            'isDormitory', { is: 'No', then: Joi.string().required().label('Street Number'),otherwise: Joi.any().optional() }
        ),
        addressLine1: Joi.alternatives().conditional(
            'isDormitory', { is: 'No', then: Joi.string().required().label('Address Line 1'),otherwise: Joi.any().optional() }
        ),
        addressLine2: Joi.any().optional(),
        apartmentNumber:Joi.any().optional(),
        zoneId: Joi.any().optional(),
        city:Joi.string().required().label('City'),
        state:Joi.object().keys({
            value:Joi.string().required(), 
            label:Joi.any().optional(),
        }),
        postalCode:Joi.string().required().label('Postal Code'),
        country:Joi.string().required().label('Country'), 
    }) ;

    errorMessages = {
        "state":{
            "object.base":"State is not allowed to be empty",
        },
        "dormitoryName":{
            "object.base":"Please select a dormitory",
        },
        "deliverToYourCurrentAddress":{
            "boolean.base":"Please enter Delivery to your current address",
        },
        "isDeliveryAddressYourNewCampusAddress":{
            "boolean.base":"Please enter is delivery address going to be your new campus address",
        }
        
    }

    constructor(){
        super();
        this.state={
            processingFlag:false,
            stateList:[{ value: "", label: "", isDisabled: true }],
            dormitoryList:[{ value: "", label: "", isDisabled: true }],
            dormitoryObjectList:"",
            data:{
                deliveryAddress:{
                    deliverToYourCurrentAddress:"",
                    isDeliveryAddressYourNewCampusAddress:"",
                    isDormitory:"",
                    dormitoryName:"", //{value:"", label:""} this corresponds to the react-select value setup
                    dormitoryRoomNumber:"",
                    streetNumber:"",
                    addressLine1:"",
                    addressLine2:"",
                    apartmentNumber:"",
                    zoneId:"",
                    city:"",
                    state:"",
                    postalCode:"",
                    country:"USA",
                    newDeliveryAddressProperties:{
                        zoneId:"",
                        storagePickupDeliveryDateList:"",
                        storagePickupDeliveryTimeslot:"",
                    } 
                }
            },

        }
    }

    async componentDidMount(){
        try{
            const stateList = getUSStateList();
            const {campus_id:school_id} = this.props.user;
            const school = {id:school_id};

            const dormitoryList = await SchoolService.getDropdownListOfDormitoryBySchool(school);
            const dormitoryObjectList = await SchoolService.getListOfDormitoryBySchool(school);
            this.setState({
                stateList,
                dormitoryList,
                dormitoryObjectList,
            });
            
            this.mapModelToView();
        }
        catch(ex){
            console.log(`Component did mount on Laundry Student Info ${ex}`);
        }
        
    }

    getFormattedUserAddress = (user)=>{
        const {line1:addressLine1,line2:addressLine2} = UserService.getFormattedUserAddress(user);
        return <>{addressLine1} <br/> {addressLine2}</>;
    }

    handleChange = ({currentTarget:input})=>{
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[input.name] = input.value;
        this.setState({data,errors});
        
    }

    handleChangeAddress = (e,addressType)=>{
        const {name,value} = e.currentTarget;
        //console.log(`${name} : ${value}`);
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        
        if(name === "isDormitoryDeliveryAddress")
            address.isDormitory = value 
        else
            address[name] = value;

        data[addressType] = address
    

        this.setState({data});
    }

    handleSubmit = async (e)=>{
        try{
            e.preventDefault();
            
            let errors = this.validate();
            this.setState({errors: errors || {}});
            if(errors){ scrollTop(this.divAreaToScrollForError); return;}
            

            const {handleSubmit} = this.props;  
            const formData = {...this.state.data.deliveryAddress};
            

            /* 
                If deliverToYourCurrentAddress is set to false then get below based on the new address
                - zone id
                - delivery timeslots
            */

            const {deliverToYourCurrentAddress} = this.state.data.deliveryAddress
            let newDeliveryAddressProperties = ""
            if(deliverToYourCurrentAddress === false){
                this.setProcessingFlag(true);

                let addressObject = null;
                const {
                    isDormitory,
                    dormitoryName, //{value:"", label:""} this corresponds to the react-select value setup
                    streetNumber,
                    addressLine1,
                    addressLine2,
                    apartmentNumber,
                    city,
                    state,
                    postalCode,
                    country,
                } = this.state.data.deliveryAddress 

                addressObject = {
                    streetNumber,
                    addressLine1,
                    addressLine2,
                    apartmentNumber,
                    city,
                    state,
                    postalCode,
                    country,
                    dormitory: (isDormitory === "Yes")? {id: dormitoryName.value}:"",
                    isDormitory:(isDormitory === "Yes")? true:false, 
                }

                const {status,message,deliveryScheduleByZone} = await StudentService.getStorageDeliveryScheduleByAddress(addressObject)
                
                this.setProcessingFlag(false);
                if(status === true){
                    formData.newDeliveryAddressProperties = deliveryScheduleByZone
                    newDeliveryAddressProperties = deliveryScheduleByZone
                }
                else{
                    errors = {deliveryAddress:message}
                    this.setState({errors: errors || {}});
                    if(errors){ scrollTop(this.divAreaToScrollForError); return;}
                }

                
                //console.log(deliveryScheduleByZone)
            }

            //Get early delivery date by zone 
                this.setProcessingFlag(true);
                let zoneIdBasedOnDeliveryAddress = null
                zoneIdBasedOnDeliveryAddress = (deliverToYourCurrentAddress === false) ? 
                    newDeliveryAddressProperties.zoneId:this.props.user.zone_id

                const {status,message,earlyDeliveryDateProperties} = await StudentService.getStorageEarlyDeliveryDatePropertiesByZone({zoneId:zoneIdBasedOnDeliveryAddress})
                
                this.setProcessingFlag(false);
                if(status === true){
                    formData.earlyDeliveryIndicatorProperties ={ 
                        status:true,
                        zoneId:earlyDeliveryDateProperties.zoneId,
                        deliveryDate:earlyDeliveryDateProperties.deliveryDate,
                        deliveryMessage:earlyDeliveryDateProperties.deliveryMessage,
                    }
                }
                else{
                    formData.earlyDeliveryIndicatorProperties ={ 
                        status:false,
                        zoneId:"",
                        deliveryDate:"",
                        deliveryMessage:"",
                    }
                }
            //End of Get early delivery date by zone 
            

            handleSubmit(formData);
            return;
            
         }catch(ex){
             renderError(ex);
         } 
 
     }

    mapModelToView = ()=>{
        const {deliveryAddress} = this.props;
        const data = {...this.state.data}
        data.deliveryAddress = deliveryAddress
        //console.log(deliveryAddress);
        this.setState({data});
    }

    setAddressDormitory = (valueObject,addressType)=>{
        const {dormitoryObjectList,stateList} = this.state; 
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        address.dormitoryName = valueObject;

        //Get the dorm object by selected dorm name to populate the city state zip
        const selectedDormitoryObject = _.find(dormitoryObjectList,{"id":valueObject.value});
        //console.log(selectedDormitoryObject);
        
        const selectedStateObject = _.find(stateList,{"value":selectedDormitoryObject.state});
        
        address.city = selectedDormitoryObject.city;
        address.state = selectedStateObject;
        address.postalCode = selectedDormitoryObject.zip;
        
        data[addressType] = address;
        this.setState({data});
    }

    setAddressState = (value,addressType)=>{
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        address.state = value;
        data[addressType] = address;
        this.setState({data});

    }

    setDeliverToYouCurrentAddress = (flag,addressType)=>{
        const data = {...this.state.data}
        const address = {...this.state.data[addressType]}
        address.deliverToYourCurrentAddress = flag;
        data[addressType] = address;
        this.setState({data})
    }

    setDeliveryAddressYourNewCampusAddress = (flag,addressType)=>{
        const data = {...this.state.data}
        const address = {...this.state.data[addressType]}
        address.isDeliveryAddressYourNewCampusAddress = flag
        data[addressType] = address;
        this.setState({data})
    }

    setProcessingFlag = flag=>{
        this.setState({
            processingFlag:flag
        })
    }

    parseErrors = (validationErrorList)=>{
        const validationErrors = {};
        if(validationErrorList){
            console.log(validationErrorList.details);
            validationErrorList.details.map(
                function(errorObject){
                    //console.log(errorObject);
                    if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                        const key = errorObject.context.key;
                        const joiType = errorObject.type;
                        //console.log(this.errorMessages);
                        if(_.has(this.errorMessages, [key, joiType]))
                            return validationErrors[errorObject.path[0]] = this.errorMessages[key][joiType];
                        else
                            return validationErrors[errorObject.path[0]] = errorObject.message;
                    }
                    else if(_.has(errorObject, ['context', 'peers']) &&  _.has(errorObject, ['type'])){
                        const joiType = errorObject.type;
                        if(joiType === "object.missing")
                            return validationErrors[errorObject.path[0]] = this.errorMessages.minimumFieldsMessage.message;
                    }
                    else
                        return validationErrors[errorObject.path[0]] = errorObject.message;
                }.bind(this)
            );
        }

        return validationErrors
    }

    validate = ()=>{
        try{
            let data  = {...this.state.data}, 
                errorList = null;

            const options = {abortEarly:false,allowUnknown:true},
                  {deliveryAddress} = data,
                  {deliverToYourCurrentAddress} = deliveryAddress;
            
            const dataObject = {deliverToYourCurrentAddress:deliveryAddress.deliverToYourCurrentAddress};
            ({error:errorList} = this.validationSchema.validate(dataObject,options));
            const primaryErrors = this.parseErrors(errorList);

            let deliveryAddressErrors  = {};
            if(deliverToYourCurrentAddress === false){
                ({error:errorList} = this.deliveryAddressSchema.validate(deliveryAddress,options));
                deliveryAddressErrors = this.parseErrors(errorList);
            }
            
            const errors = {...primaryErrors,...deliveryAddressErrors} 
        
            //console.log(errors);
            return Object.keys(errors).length > 0 ? errors:null;
        }catch(ex){
            console.log(ex);
        }

    }

    render(){
        const {closeWindow,user} = this.props;
        const {data,errors,processingFlag} = this.state;
        const {deliveryAddress} = data;

        const {
            deliverToYourCurrentAddress,
            isDeliveryAddressYourNewCampusAddress
        } = deliveryAddress
        
        const deliveryAddressIsDormitoryCheckedYes = (deliveryAddress.isDormitory === "Yes") ? {defaultChecked:true}:{};
        const deliveryAddressIsDormitoryCheckedNo = (deliveryAddress.isDormitory === "No") ? {defaultChecked:true}:{};
        const deliveryAddressIsDormitoryCSS = "form-check-radio";
        
        const deliveryAddressInputGenericProperties = {};
        
        const formattedUserAddress = (!_.isEmpty(user)) ? this.getFormattedUserAddress(user):"";

        const deliveryAddressAreaCSS = (deliverToYourCurrentAddress === false) ? `mt-4`: `mt-4 d-none`;

        const displayFlag = false;
        return(
            <div id="deliveryAddressSelectionStep1">
                {/*Error display */}
                {!_.isEmpty(errors) &&
                    <FormErrorWidget
                        errors={errors}
                    />
                }
                {/* End of Error display */}
                
                <Row>
                    <Col>

                        {/* Current Campus address setting */}
                        <h6>Your current campus address:</h6>
                        <Row>
                            <Col xs="6">
                                Your current Campus Address:	
                            </Col>  
                            <Col xs="6">   
                            <p>
                                {formattedUserAddress}
                            </p>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col xs="6">
                            Deliver to your current Campus Address:	
                            </Col>  
                            <Col xs="3" sm="3" md="3" lg="2">
                                <Button
                                    className="btn-round mr-1"
                                    color="danger"
                                    outline = {(deliverToYourCurrentAddress === true) ? false:true}
                                    block
                                    type="button"
                                    onClick={(e)=>{this.setDeliverToYouCurrentAddress(true,'deliveryAddress')}}
                                >
                                    Yes
                                </Button>
                            </Col>
                            <Col xs="3" sm="3" md="3" lg="2">
                                <Button
                                    className="btn-round mr-1"
                                    color="danger"
                                    outline = {(deliverToYourCurrentAddress === false) ? false:true}
                                    block
                                    type="button"
                                    onClick={(e)=>{this.setDeliverToYouCurrentAddress(false,'deliveryAddress')}}
                                >
                                    No
                                </Button>
                            </Col>
                        </Row>
                        
                        {/* End of Current Campus address setting */}
                        
                        
                        {/* Enter Delivery address */}
                        {   <Row  className={deliveryAddressAreaCSS}>
                                <Col>
                            
                                    <h6>Enter your delivery address:</h6>

                                    <FormGroup row>
                                        <Label for="" xs={6} sm={4} md={6}>Is it a dormitory?</Label>
                                        <Col xs={2}  className="mt-1">
                                            <div className={deliveryAddressIsDormitoryCSS}>
                                                <Label check>
                                                    <Input
                                                        {...deliveryAddressIsDormitoryCheckedYes}
                                                        defaultValue="Yes"
                                                        id="deliveryAddressIsDormitory1"
                                                        name="isDormitoryDeliveryAddress"
                                                        type="radio"
                                                        onChange={e=>{this.handleChangeAddress(e,"deliveryAddress")}}
                                                    />
                                                    Yes
                                                    <span className="form-check-sign" />
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col xs={2} sm={1} className="ml-2 mt-1">
                                            <div className={deliveryAddressIsDormitoryCSS}>
                                                <Label check>
                                                    <Input
                                                        {...deliveryAddressIsDormitoryCheckedNo}
                                                        defaultValue="No"
                                                        id="deliveryAddressIsDormitory2"
                                                        name="isDormitoryDeliveryAddress"
                                                        type="radio"
                                                        onChange={e=>{this.handleChangeAddress(e,"deliveryAddress")}}
                                                    />
                                                    No
                                                    <span className="form-check-sign" />
                                                </Label>
                                            </div>
                                        </Col>
                                        
                                    </FormGroup>

                                    {
                                        (deliveryAddress.isDormitory === "Yes") &&    
                                        <FormGroup row>
                                            <Label for="pickUpDormitory" sm={2}>Dorm Name</Label>
                                            <Col xs={10} sm={4}>
                                                <FormGroup>
                                                    <Select
                                                        className="react-select react-select-default"
                                                        classNamePrefix="react-select"
                                                        name="dormitoryRoomNumber"
                                                        id="dormitoryRoomNumber"
                                                        value={deliveryAddress.dormitoryName}
                                                        onChange={value => this.setAddressDormitory(value,"deliveryAddress")}
                                                        options={this.state.dormitoryList}
                                                        placeholder="Dorm"
                                                        {...deliveryAddressInputGenericProperties}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Label for="addressLine1" sm={2}>Room No.</Label>
                                            <Col xs={10} sm={4}>
                                                <Input 
                                                type="text" 
                                                name="dormitoryRoomNumber" 
                                                id="dormitoryRoomNumber" 
                                                placeholder="Room No" 
                                                maxLength="10"
                                                value={this.state.data.deliveryAddress.dormitoryRoomNumber} 
                                                onChange={e=>{this.handleChangeAddress(e,"deliveryAddress")}}
                                                {...deliveryAddressInputGenericProperties}
                                                />
                                            </Col>
                                        </FormGroup>
                                    }

                                    {
                                        (deliveryAddress.isDormitory !== "Yes") &&   
                                        <FormGroup row>
                                            <Label for="streetNumber" sm={2}>Street #</Label>
                                            <Col sm={4}>
                                                <Input 
                                                type="text" 
                                                name="streetNumber" 
                                                id="streetNumber" 
                                                placeholder="Street #" 
                                                maxLength="6"
                                                value={this.state.data.deliveryAddress.streetNumber} 
                                                onChange={e=>{this.handleChangeAddress(e,"deliveryAddress")}}
                                                />
                                            </Col>

                                            <Label for="apartmentNumber" sm={2}>Apt #</Label>
                                            <Col sm={4}>
                                                <Input 
                                                type="text" 
                                                name="apartmentNumber" 
                                                id="apartmentNumber" 
                                                placeholder="Apt Number" 
                                                maxLength="10"
                                                value={this.state.data.deliveryAddress.apartmentNumber} 
                                                onChange={e=>{this.handleChangeAddress(e,"deliveryAddress")}}
                                                />
                                            </Col>
                                            
                                        </FormGroup>
                                    }               
                                        
                                    {
                                        (deliveryAddress.isDormitory !== "Yes") &&   
                                        <FormGroup row>
                                            <Label for="addressLine1" sm={2}>Street Name</Label>
                                            <Col sm={10}>
                                                <Input 
                                                type="text" 
                                                name="addressLine1" 
                                                id="addressLine1" 
                                                placeholder="Street Name" 
                                                value={this.state.data.deliveryAddress.addressLine1} 
                                                onChange={e=>{this.handleChangeAddress(e,"deliveryAddress")}}
                                                {...deliveryAddressInputGenericProperties}
                                                />
                                            </Col>
                                            
                                        </FormGroup>
                                    }
                                    {
                                        (deliveryAddress.isDormitory !== "Yes") &&  
                                        <FormGroup row className="d-none">
                                            <Label for="addressLine2" sm={2}>Address Line 2</Label>
                                            <Col sm={10}>
                                                <Input 
                                                type="text" 
                                                name="addressLine2" 
                                                id="addressLine2" 
                                                placeholder="Address Line 2" 
                                                value={this.state.data.deliveryAddress.addressLine2} 
                                                onChange={e=>{this.handleChangeAddress(e,"deliveryAddress")}}
                                                {...deliveryAddressInputGenericProperties}
                                                />
                                            </Col>
                                            
                                        </FormGroup>
                                    }    
                                        
                                        
                                    <FormGroup row>
                                        <Label for="city" sm={2}></Label>
                                        <Col xs={12} sm={3}>
                                            <Input 
                                            type="text" 
                                            name="city" 
                                            id="city" 
                                            placeholder="City" 
                                            value={this.state.data.deliveryAddress.city} 
                                            onChange={e=>{this.handleChangeAddress(e,"deliveryAddress")}}
                                            {...deliveryAddressInputGenericProperties}
                                            />
                                        </Col>
                                        <Label for="state" xs={12} sm={2} className="d-block d-sm-none"></Label>
                                        <Col xs={12} sm={4}>
                                            <FormGroup>
                                                <Select
                                                    className="react-select react-select-default"
                                                    classNamePrefix="react-select"
                                                    name="state"
                                                    id="state"
                                                    value={this.state.data.deliveryAddress.state}
                                                    onChange={value => this.setAddressState(value,"deliveryAddress")}
                                                    options={this.state.stateList}
                                                    placeholder="State"
                                                    {...deliveryAddressInputGenericProperties}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            <Input 
                                            type="text" 
                                            name="postalCode" 
                                            id="postalCode" 
                                            placeholder="Zip" 
                                            value={this.state.data.deliveryAddress.postalCode} 
                                            onChange={e=>{this.handleChangeAddress(e,"deliveryAddress")}}
                                            {...deliveryAddressInputGenericProperties}
                                            />
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label for="" xs={6} sm={4} md={6}>Will this address be your new campus address?</Label>
                                        <Col xs={3} sm={3} md={3} lg={2}>
                                            <Button
                                                className="btn-round mr-1"
                                                color="danger"
                                                outline = {(isDeliveryAddressYourNewCampusAddress === true) ? false:true}
                                                block
                                                type="button"
                                                onClick={(e)=>{this.setDeliveryAddressYourNewCampusAddress(true,'deliveryAddress')}}
                                            >
                                                Yes
                                            </Button>
                                        </Col>
                                        <Col xs={3} sm={3} md={3} lg={2}>
                                            <Button
                                                className="btn-round mr-1"
                                                color="danger"
                                                outline = {(isDeliveryAddressYourNewCampusAddress === false) ? false:true}
                                                block
                                                type="button"
                                                onClick={(e)=>{this.setDeliveryAddressYourNewCampusAddress(false,'deliveryAddress')}}
                                            >
                                                No
                                            </Button>
                                        </Col>
                        
                                    </FormGroup>

                                </Col>
                            </Row>  
                        }
                        
                        {/* End of Enter Delivery address */}

                    </Col>   
                </Row>

                <FormGroup row  className="mt-5 justify-content-between">
                    <Col xs={{ size: 2}}>
                        <Button 
                            className="btn-rounded btn-danger d-none"
                            onClick={closeWindow}
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col xs={{ size: 6}} sm={{ size: 6}} className="text-right">
                        { 
                            displayFlag && 
                            <Button 
                                className="btn-rounded btn-danger"
                                onClick={this.handleSubmit}
                            >
                                Proceed to Item Selection
                            </Button>
                        }

                        <Button 
                            className="btn-rounded btn-danger"
                            onClick={this.handleSubmit}
                        >
                            {
                                processingFlag && 
                                <div className="uil-reload-css reload-small mr-1">
                                    <div />
                                </div>
                            }
                            Proceed to Item Selection
                        </Button>
                    </Col>
                </FormGroup>

                
            </div>
        );
    }
  }

  export default DeliveryAddressSelectionStep1;