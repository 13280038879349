import React,{Component} from "react";
import _ from "lodash";

import StudentService from "services/studentService";

import DeliveryAddressSelectionStep1 from "./DeliveryScheduleModalComponents/DeliveryAddressSelectionStep1";
import DeliveryItemSelectionStep2 from "./DeliveryScheduleModalComponents/DeliveryItemSelectionStep2";
import DeliveryDateAndTimeSelectionStep3 from "./DeliveryScheduleModalComponents/DeliveryDateAndTimeSelectionStep3";
import DeliveryScheduleReviewStep4 from "./DeliveryScheduleModalComponents/DeliveryScheduleReviewStep4";

// reactstrap components
import {
    Button,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    FormGroup, 
    Modal, 
    Input, 
  } from "reactstrap";

  class DeliveryScheduleModal extends Component{
    
    /*
    modifyFlag: This is used to create and modify a delivery schedule where we display labels accordingly
    */
    constructor(){
        super();
        this.state = {
            currentStep:"enter-delivery-address",
            modifyFlag:false, 
            data:{
                deliveryAddress:{
                    deliverToYourCurrentAddress:"",
                    isDeliveryAddressYourNewCampusAddress:"",
                    isDormitory:"",
                    dormitoryName:"", //{value:"", label:""} this corresponds to the react-select value setup
                    dormitoryRoomNumber:"",
                    streetNumber:"",
                    addressLine1:"",
                    addressLine2:"",
                    apartmentNumber:"",
                    zoneId:"",
                    city:"",
                    state:"",
                    postalCode:"",
                    country:"USA",
                    newDeliveryAddressProperties:{
                        zoneId:"",
                        storageDeliveryDateList:"",
                        storageDeliveryTimeslot:"",
                    },
                    earlyDeliveryIndicatorProperties:{
                        status:false,
                        zoneId:"",
                        deliveryDate:"",
                        deliveryMessage:"",
                    }
                },
                deliveryDateAndTime:{
                    deliveryDay:"",
                    deliveryStartTime:"",
                    deliveryEndTime:"",
                    deliveryTimeId:"",
                },
                listOfStorageItems:[],
                listOfItemsSelectedForDelivery:[],
                schedulingNotes:"",
                 
            },
            errors:{},
        }
        
    }

    async componentDidMount(){
        try{
            const {modifyFlag,storageOrder:storage} = this.props;
            //Get list of undelivered storage items
            if(!_.isEmpty(storage)){
                const {storage_order_id:storageOrderId} = storage.storageOrder;
                const {nextPickupDeliverySchedule} = storage;
                const {delivery} = nextPickupDeliverySchedule;
                let  storageScheduleId = ""
                if(delivery && delivery.storageScheduleId)
                  storageScheduleId = delivery.storageScheduleId
                
                
                /*
                const {status:processingStatus,message,storageItems} =  (modifyFlag) ?
                    await StudentService.getStorageItemsToModifyDelivery(storageScheduleId):
                    await StudentService.getStorageItemsForDeliverySchedule(storageOrderId);
                
                const data = {...this.state.data}
                if(storageItems.length){
                    data.listOfStorageItems = storageItems;
                    this.setState({...this.state,modifyFlag,data});
                }
                else
                    this.setState({...this.state,modifyFlag,data});
                */
               const data = {...this.state.data}
               data.listOfStorageItems = (modifyFlag) ? 
                nextPickupDeliverySchedule.delivery.storageItems
                :
                nextPickupDeliverySchedule.storageItemsAvailableForNewDelivery;
               this.setState({...this.state,modifyFlag,data});

            }
            
        }
        catch(ex){
            console.log(ex);
        }
    }

    
    async componentDidUpdate(prevProps){
        try{
            const {modifyFlag,storageOrder:storage} = this.props;
            //Get list of undelivered storage items

            if(!_.isEmpty(storage) && (modifyFlag !== this.state.modifyFlag)){
                
                const {storage_order_id:storageOrderId} = storage.storageOrder;
                const {nextPickupDeliverySchedule} = storage;
                const {delivery} = nextPickupDeliverySchedule;
                
                
                /*
                let  storageScheduleId = ""
                if(delivery && delivery.storageScheduleId){
                  storageScheduleId = delivery.storageScheduleId
                  
                }
                const {status:processingStatus,message,storageItems} =  (modifyFlag) ?
                    await StudentService.getStorageItemsToModifyDelivery(storageScheduleId):
                    await StudentService.getStorageItemsForDeliverySchedule(storageOrderId);
                
                
                const data = {...this.state.data}
                if(storageItems.length){
                    
                    data.listOfStorageItems = storageItems;
                    this.setState({...this.state,modifyFlag,data});
                }
                else
                    this.setState({...this.state,modifyFlag,data});
                */

                const data = {...this.state.data}
                data.listOfStorageItems = (modifyFlag) ? 
                    nextPickupDeliverySchedule.delivery.storageItems
                    :
                    nextPickupDeliverySchedule.storageItemsAvailableForNewDelivery;
                
                if(modifyFlag) data.schedulingNotes = delivery.storageScheduleNote;
                this.setState({...this.state,modifyFlag,data});
            }
            
        }
        catch(ex){
            console.log(ex);
        }
    }
    
    

    getModalTitle = ()=>{
        let modalTitle = "";

        const {modifyFlag} = this.props
        const modifyModalPrefix = (modifyFlag) ? "Modify ":"Select";
        
        const state = {...this.state}
        if(state.currentStep === "enter-delivery-address")
            modalTitle = `${modifyModalPrefix} Delivery Address`;
        else if(state.currentStep === "enter-items-selection")
            modalTitle = `Select Items for Delivery`;
        else if(state.currentStep === "enter-date-time-selection")
            modalTitle = `${modifyModalPrefix} Date and Time for Delivery`;
        else if(state.currentStep === "review-selections")
            modalTitle = `Review your selection`;
         
        return modalTitle
    }

    handleBackToStep1 = ()=>{
        const state = {...this.state}
        state.currentStep = "enter-delivery-address";
        this.setState(state)
    
    }

    handleBackToStep2 = ()=>{
        const state = {...this.state}
        state.currentStep = "enter-items-selection";
        this.setState(state)
    
    }

    handleBackToStep3 = ()=>{
        const state = {...this.state}
        state.currentStep = "enter-date-time-selection";
        this.setState(state)
    
    }

    handleSaveDeliveryAddressStep1 = (formData)=>{
        //console.log(formData)
        
        const state = {...this.state}
        state.currentStep = "enter-items-selection";
        const data = {...this.state.data}
        data.deliveryAddress = formData;
        state.data  = data;
        
        this.setState(state)
    
    }

    handleSaveDeliveryItemsStep2 = (formData)=>{
        //console.log(formData)
        
        const state = {...this.state}
        state.currentStep = "enter-date-time-selection";
        const data = {...this.state.data}
        data.listOfItemsSelectedForDelivery = formData;
        state.data  = data;
        
        this.setState(state)
    
    }

    handleSaveDeliveryDateTimeStep3 = (formData)=>{
        const state = {...this.state}
        state.currentStep = "review-selections"
        const {schedulingNotes} = formData;
        const deliveryDateAndTimeFormObject = _.omit(formData, ['deliveryTimeslotList','schedulingNotes']);

        const data = {...this.state.data}
        data.deliveryDateAndTime = deliveryDateAndTimeFormObject;
        data.schedulingNotes = schedulingNotes;
        state.data  = data;

        this.setState(state)
    }

    //Step 4
    handleSaveDeliverySchedule = ()=>{
        //const errors = this.validate();
        //this.setState({errors: errors || {}});
        //if(errors){ scrollTop('storageDeliveryScheduleModal'); return;}
        
        const deliveryAddressFormData = this.state.data.deliveryAddress
        const deliveryDataForm  = {
            ...this.state.data.deliveryAddress,
            isDormitory: (deliveryAddressFormData.isDormitory === "Yes")? true:false,
            dormitory:{
                id:(deliveryAddressFormData.isDormitory === "Yes") ? deliveryAddressFormData.dormitoryName.value:"",
                name:(deliveryAddressFormData.isDormitory === "Yes") ? deliveryAddressFormData.dormitoryName.label:"",
                roomNumber:(deliveryAddressFormData.isDormitory === "Yes") ? deliveryAddressFormData.dormitoryRoomNumber:""
            },
            state:(_.has(deliveryAddressFormData,['state','value'])) ? deliveryAddressFormData.state.value:"",
        }

        const data = {
            ...this.state.data,
            deliveryAddress:deliveryDataForm
        }
        
        this.props.handleSaveOperation(data);
        return;
    }

    validate = ()=>{
        return null;
    }
    
    render(){
        const {modalName,modalDisplayFlag,handleModalDisplay,...rest} = this.props 
        const {currentStep,data,errors} = this.state;
        const {
            deliveryAddress,
            deliveryDateAndTime,
            listOfStorageItems,
            listOfItemsSelectedForDelivery,
            schedulingNotes,
        } = data;

        const {newDeliveryAddressProperties,earlyDeliveryIndicatorProperties} = deliveryAddress

        const modalTitle = this.getModalTitle();
        return(
            <>
            <Modal id={modalName} size="lg" isOpen={modalDisplayFlag} toggle={() => handleModalDisplay(false)}>
                <div className="modal-header">
                    <button
                        className="close"
                        type="button"
                        onClick={() => handleModalDisplay(false)}
                    >
                        <span>×</span>
                    </button>
                    <h5
                        className="modal-title text-center"
                        id="exampleModalLabel"
                    >
                        Schedule Storage Delivery: {modalTitle}
                    </h5>
                </div>
                <div className="modal-body">
                    
                    <Row>
                        <Col>
                        {/* Step 1 - Enter Delivery Address */}
                        {   currentStep === "enter-delivery-address"  &&  
                            <DeliveryAddressSelectionStep1
                                handleSubmit={this.handleSaveDeliveryAddressStep1} 
                                closeWindow = {() => handleModalDisplay(false)}
                                deliveryAddress={deliveryAddress}
                                {...rest}
                            />  
                        }
                        

                        {/* Step 2 - Select Items */}
                        {   currentStep === "enter-items-selection"  &&
                            <DeliveryItemSelectionStep2
                                handleSubmit={this.handleSaveDeliveryItemsStep2}
                                handleBack={this.handleBackToStep1} 
                                listOfStorageItems={listOfStorageItems}
                                listOfItemsSelectedForDelivery={listOfItemsSelectedForDelivery}
                                {...rest}
                            />
                        }

                        {/* Step 3 - Select Delivery Schedule */}
                        {   currentStep === "enter-date-time-selection"  &&
                            <DeliveryDateAndTimeSelectionStep3
                                handleSubmit={this.handleSaveDeliveryDateTimeStep3}
                                handleBack={this.handleBackToStep2} 
                                deliveryDateAndTime={deliveryDateAndTime}
                                schedulingNotes={schedulingNotes}
                                newDeliveryAddressProperties={newDeliveryAddressProperties}
                                earlyDeliveryIndicatorProperties={earlyDeliveryIndicatorProperties}
                                {...rest}
                            />
                        }

                        {/* Step 4 - Review screen */}
                        {   currentStep === "review-selections"  &&
                            <DeliveryScheduleReviewStep4
                                handleSubmit={this.handleSaveDeliverySchedule}
                                handleBack={this.handleBackToStep3}
                                deliverySchedulingData={data} 
                                {...rest}
                            />
                        }
                        
                        </Col>
                    </Row>
                    
                </div>
                
            </Modal>
            </>
        );
    }
  }

  export default DeliveryScheduleModal;