import React from "react"

import {
    Button,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Table,

} from "reactstrap";


const generateColumnHeaders = (columnHeader)=>{
    let counter = 1;
    return <tr>
                {columnHeader.map(c=>{
                    counter++;
                    return(
                        <th 
                            key={counter}
                            className={(c.classDefinition) ? c.classDefinition:""}
                            style={c.styleDefinition}
                        >
                            {c.name}
                        </th>
                    );
                    
                })}
            </tr>
}

const TableView  = (props)=>{
    const {tableProperties} = props
    const {tableHeader,tableColumn,tableData,tableFooter,tableFooterDisplayFlag} = tableProperties;
    let rowCtr = 1; 
    return(
        <>
        {   
            tableHeader!== "" &&
            <h4 className="title">
                <small>{tableHeader}</small>
            </h4>
        }           
        <Table responsive striped>
            <thead>
            {
                generateColumnHeaders(tableColumn)
            }
            </thead>
            <tbody>
                {
                    !tableData.length && 
                    <tr>
                        <td colSpan={tableColumn.length} className="text-center">
                            No results found
                        </td>
                    </tr>
                }
                {   
                    tableData.length > 0 && 
                    tableData.map(tableData=>{
                        let columnCounter = 1;
                        return (
                        <tr key={rowCtr++}>
                            {
                                tableColumn.map(c=>{
                                    const fieldData = (typeof c.getFieldData ===  "function")?  c.getFieldData(tableData):"";
                                    const {defaultDataValue} = c;
                                    const formattedFieldData = (typeof c.formattedFieldGetter === "function") ? c.formattedFieldGetter(fieldData,defaultDataValue):fieldData;
                                    
                                    return(
                                    <td 
                                        key={columnCounter++}
                                        className={(c.classDefinition) ? c.classDefinition:""}
                                    >
                                       {formattedFieldData}
                                    </td>
                                    )
                                })
                            }
                        </tr>
                        );
                        
                    })
                }

                {  tableFooterDisplayFlag && 
                   tableFooter 
                }
            </tbody>
        </Table>
        </>
    )
}

export default TableView;
