import React from "react";
import _ from "lodash";


// core components
import ShortNavbar from "../../components/Navbars/ShortNavbar.js";
import SignupPageHeader from "../../components/Headers/SignupPageHeader";
import FooterWhite from "../../components/Footers/FooterWhite";

import LaundryInformation from "../../components/Consumer/LaundryInformation"

import auth from "../../services/authService";

// reactstrap components
import {
    Button,
    Container,
    FormGroup,
    Row,
    Col
  } from "reactstrap";

  

  const LaundryDetails = (props)=>{
    let userType;
    ({userType} = props.match.params);

    let userTypeSelected = (userType === undefined) ? false:true;

    let [isStudent,isResidential,isCommercial] = [false,false,false];
    isStudent = (userType === "student") ? true:false;
    isResidential = (userType === "residential") ? true:false;
    isCommercial = (userType === "commercial") ? true:false;

    let signUpUrl = "";
    if(isStudent) signUpUrl = `/signup/laundry/student/select-school`;
    if(isResidential) signUpUrl = `/signup/laundry/residential/select-city`;
    
    return(
      <>
        <ShortNavbar {...props} />
        <SignupPageHeader />
        <div className="main">
            <div className="section section-nude-gray"> 
            <Container>
                {
                    userTypeSelected === true && 
                    <>
                    <Row className="title-row justify-content-between">
                        <Col xs="6" md="6">
                        <h3 className="shop">Laundry - {`${_.startCase(userType)}`}</h3>
                        </Col>
                        <Col xs="6" md="6">
                        <div className="text-right">
                            {
                                !auth.getCurrentUser() && !isCommercial && 
                                <Button
                                    className="btn-round mr-1"
                                    color="danger"
                                    type="button"
                                    onClick={()=>props.history.push(signUpUrl)}
                                >
                                    Sign-up
                                </Button>
                            }
                        </div>
                        </Col>
                    </Row>
                    <div id="description-areas">
                        <LaundryInformation
                            {...props} 
                        />
                    </div>
                    </>
                }


                {
                    userTypeSelected === false && 
                    <>
                    <Row className="title-row justify-content-between">
                        <Col xs="6" md="6">
                        <h3 className="shop">Select:</h3>
                        </Col>
                        <Col xs="6" md="6">
                            <div className="text-right">
                                
                            </div>
                        </Col>
                    </Row>
                    <div id="description-areas">
                        <FormGroup row className="text-center">
                            <Col sm="2"></Col> 
                            <Col sm={{ size: 8}}> 
                                <Button
                                    className="btn-round mr-1"
                                    color="danger"
                                    outline = {true}
                                    block
                                    type="button"
                                    onClick={()=>{props.history.push("/laundry/how-it-works/student")}}
                                >
                                    Student Laundry
                                </Button>
                            </Col>
                            <Col sm="2"></Col>
                        </FormGroup>

                        <FormGroup row className="text-center">
                            <Col sm="2"></Col> 
                            <Col sm={{ size: 8}}> 
                                <Button
                                    className="btn-round mr-1"
                                    color="danger"
                                    outline = {true}
                                    block
                                    type="button"
                                    onClick={()=>{props.history.push("/laundry/how-it-works/residential")}}
                                >
                                    Residential Laundry
                                </Button>
                            </Col>
                            <Col sm="2"></Col>
                        </FormGroup>

                        <FormGroup row className="text-center">
                            <Col sm="2"></Col> 
                            <Col sm={{ size: 8}}> 
                                <Button
                                    className="btn-round mr-1"
                                    color="danger"
                                    outline = {true}
                                    block
                                    type="button"
                                    onClick={()=>{props.history.push("/laundry/how-it-works/commercial")}}
                                >
                                    Commercial Laundry
                                </Button>
                            </Col>
                            <Col sm="2"></Col>
                        </FormGroup>
                    </div>
                    </>
                }


            {/* info areas */}
            </Container>
          </div>
         </div> 
        
        <FooterWhite />
      </>
    );



}

export default LaundryDetails;