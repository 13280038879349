import React,{ useState } from "react";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import accounting from "accounting";
import {scrollTop} from '../../../../../utils/helperUtils';



import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Badge,
    Button,
    ButtonGroup,
    Input,
    Nav,
    NavLink,
    NavItem,
    TabContent,
    TabPane,
    Table,
    Media,
    UncontrolledTooltip
} from "reactstrap";

const SupplyIndividualItemCard = (props)=>{
    const [quantity, setQuantity] = useState(1);
    const {product,handleAddToCart,editMode} = props;
    const {
        title,
        description,
        price,
        id,
        imagePath,
    } = product;

    const handleChange = ({currentTarget:input})=>setQuantity(input.value);
    
    return(
        <>
            <Card className="card-plain bg-white p-2 rounded-lg">
                <CardBody className="">
                    <Row className="" style={{minHeight:"300px"}} >
                        <Col xs="12">
                            <CardTitle tag="h5" className="text-danger text-center">{title}</CardTitle>
                            <div className="text-center">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require(`../../../../../assets/mylazybones${imagePath}`)}
                                />
                            </div>
                            <p className="card-description  fw-strong">
                            {ReactHtmlParser(description)}
                            </p>
                            <Row>
                                <Col xs="4">
                                    Price:
                                </Col>
                                <Col xs="4">{accounting.formatMoney(price)}</Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs="4">
                                    Quantity: 
                                </Col>
                                <Col xs="3">
                                <Input 
                                    type="select" 
                                    name="quantity" 
                                    id="quantity" 
                                    value={quantity}
                                    onChange={handleChange}
                                >
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                </Input>
                                </Col>
                            </Row>

                        </Col>
                        

                    </Row>
                    <Row>
                        <Col>
                            <CardFooter className="text-center ">
                                {
                                    editMode && 
                                    <Button
                                        className="btn btn-sm mr-1 mt-4 align-text-bottom"
                                        color="danger"
                                        onClick={(e)=>{ 
                                            scrollTop('sectionArea'); 
                                            return  handleAddToCart(e,product,parseInt(quantity))}
                                        }
                                        outline
                                        >
                                            Add to Cart
                                    </Button>
                                }
                                
                            </CardFooter>
                        </Col>
                    </Row>
                </CardBody>
            </Card>  
        </>
    );
}

export default SupplyIndividualItemCard;
