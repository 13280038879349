import React from "react";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import accounting from "accounting";
import {scrollTop} from '../../../../../utils/helperUtils';


import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Badge,
    Button,
    ButtonGroup,
    Input,
    Nav,
    NavLink,
    NavItem,
    TabContent,
    TabPane,
    Table,
    Media,
    UncontrolledTooltip
} from "reactstrap";

const SupplyBundleItemCard = (props)=>{
    const quantity = 1;
    
    const {product,handleAddToCart,editMode} = props;
    const {
        title,
        description,
        price,
        id,
        imagePath,
    } = product;

    
    
    return(
        <>
            <Card className="card-plain bg-white p-2 rounded-lg">
                <CardBody className="">
                    <Row>
                        <Col xs="8">
                            <CardTitle tag="h5" className="text-danger">{title}</CardTitle>
                            <p className="card-description  fw-strong">
                                {ReactHtmlParser(description)}
                            </p>
                        </Col>
                        <Col xs="4" className="text-center">
                            <img
                            alt="..."
                            className="img-rounded img-responsive"
                            src={require(`../../../../../assets/mylazybones${imagePath}`)}
                            />

                            <p className="mr-1 mt-4">
                            Price: {accounting.formatMoney(price)}
                            </p>
                            
                            {
                                editMode && 
                                <Button
                                    className="btn btn-sm mr-1 mt-4"
                                    color="danger"
                                    href="#pablo"
                                    onClick={(e)=>{ 
                                        scrollTop('sectionArea'); 
                                        return handleAddToCart(e,product,quantity)}
                                    }
                                    outline
                                >
                                    Add to Cart
                                </Button>
                            }
                            
                        </Col>

                    </Row>
                </CardBody>
            </Card>
        </>
    );
}

export default SupplyBundleItemCard;
