import React,{Component} from "react";
import _ from "lodash";
import Joi from "@hapi/joi"
import Select from "react-select";


import http             from "../../../../services/httpService";
import {getUSStateList} from "../../../../services/dropDownListsService";
import {getCountryList} from "../../../../services/dropDownListsService";
import UserService      from "../../../../services/userService";
import SchoolService      from "../../../../services/schoolService";

import BaseForm from "../../../BaseClass/Form"
import FormErrorWidget from "../../../Common/FormErrorWidget";
import {scrollTop} from '../../../../utils/helperUtils';

// reactstrap components
import {
    Badge,
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Form,
    FormGroup, 
    Label, 
    Input, 
    InputGroupAddon,
    InputGroupText,
    InputGroup,
} from "reactstrap";

class CustomerAddressInformation extends BaseForm{
    divAreaToScrollForError = "sectionArea"

    validationSchema = "";

    pickupAddressSchema = Joi.object({
        isDormitory:Joi.string(),
        dormitoryName: Joi.alternatives().conditional(
            'isDormitory', { 
                is: 'Yes', 
                then: Joi.object().keys({
                    value:Joi.number().required(), 
                    label:Joi.any().optional(),
                }), 
                otherwise: Joi.any().optional()
            }
        ),
        dormitoryRoomNumber: Joi.alternatives().conditional(
            'isDormitory', { 
                is: 'Yes', 
                then: Joi.string().required(), 
                otherwise: Joi.any().optional()
            }
        ).label('Dormitory Room Number'),
        streetNumber: Joi.alternatives().conditional(
            'isDormitory', { is: 'No', then: Joi.string().required().label('Street Number'),otherwise: Joi.any().optional() }
        ),
        addressLine1: Joi.alternatives().conditional(
            'isDormitory', { is: 'No', then: Joi.string().required().label('Address Line 1'),otherwise: Joi.any().optional() }
        ),
        addressLine2: Joi.any().optional(),
        apartmentNumber:Joi.any().optional(),
        zoneId: Joi.any().optional(),
        city:Joi.string().required().label('City'),
        state:Joi.object().keys({
            value:Joi.string().required(), 
            label:Joi.any().optional(),
        }),
        postalCode:Joi.string().required().label('Postal Code'),
        country:Joi.string().required().label('Country'), 
    }) ;

    errorMessages = {}
    
    constructor(){
        super();
        this.state = {
            stateList:[{ value: "", label: "", isDisabled: true }],
            dormitoryList:[{ value: "", label: "", isDisabled: true }],
            dormitoryObjectList:"",
            data:{
                updatePrimaryAddressFlag:false,
                pickUpAddress:{
                    isDormitory:"No",
                    dormitoryName:"",  //{value:"", label:""} this corresponds to the react-select value setup
                    dormitoryRoomNumber:"",
                    streetNumber:"", //Non dormitory street number
                    addressLine1:"",
                    addressLine2:"",
                    apartmentNumber:"", //Non dormitory apartment number
                    zoneId:"",
                    city:"",
                    state:"",
                    postalCode:"",
                    country:"USA", 

                },
            },
            errors:{},
        }
    }

    componentDidMount(){
        this.mapModelToView();
    }

    componentDidUpdate(prevProps){
        const {user} = this.props;
        const {user:previousUser} = prevProps;
        if(_.isEmpty(previousUser.primaryInformation) && !_.isEmpty(user.primaryInformation)){
            this.mapModelToView();
        }
            
        
    }

    getAddressForDisplay = ()=>{
        let displayAddress  = "";
        const {user} = this.props;
        const {primaryInformation} = user;
        if(primaryInformation !== ""){
            const {line1:addressLine1,line2:addressLine2} = UserService.getFormattedUserAddress(primaryInformation);
            return <>{addressLine1} <br/> {addressLine2}</>;
        }

        return displayAddress
    }

    mapModelToView = async ()=>{
        
        try{
            const stateList = getUSStateList();

            const {user} = this.props;
            const {updatePrimaryAddressFlag} = user;
            const {pickupAndDeliveryAddress:pickUpAddressUser} = user.laundry;
        
            const {campus_id:school_id} = user.primaryInformation;
            
            const school = {id:school_id};
            const dormitoryList = await SchoolService.getDropdownListOfDormitoryBySchool(school);
            const dormitoryObjectList = await SchoolService.getListOfDormitoryBySchool(school);

            //Set pickup address
            const pickUpAddress = {...pickUpAddressUser};
            if(updatePrimaryAddressFlag){
                if(pickUpAddress.isDormitory){
                    pickUpAddress.isDormitory = "Yes";
                    pickUpAddress.dormitoryName={
                        value:pickUpAddress.dormitory.id,
                        label:pickUpAddress.dormitory.name,
                    };
                    pickUpAddress.dormitoryRoomNumber = pickUpAddress.dormitory.roomNumber;
                }
                else{
                    pickUpAddress.isDormitory = "No";
                    pickUpAddress.dormitoryName="";
                    pickUpAddress.dormitoryRoomNumber = "";
                }

                if(pickUpAddressUser.state){
                    pickUpAddress.state = _.find(stateList,{'value':pickUpAddressUser.state});
                }

                delete pickUpAddress.dormitory;
            }
            else{
                pickUpAddress.dormitoryName="";
                pickUpAddress.dormitoryRoomNumber = "";     
                delete pickUpAddress.dormitory;
                
            }
            //End of Set pickup address
            
            const data = {
                ...this.state.data,
                updatePrimaryAddressFlag,
                pickUpAddress,
            }

            this.setState(
                {
                    stateList,
                    dormitoryList,
                    dormitoryObjectList,
                    data,
                }
            );
        }
        catch(ex){
            console.log(`Component did mount on Laundry Student Info ${ex}`);
        }

          
        
    }

    setAddressDormitory = (valueObject,addressType)=>{
        const {dormitoryObjectList,stateList} = this.state; 
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        address.dormitoryName = valueObject;

        //Get the dorm object by selected dorm name to populate the city state zip
        const selectedDormitoryObject = _.find(dormitoryObjectList,{"id":valueObject.value});
        //console.log(selectedDormitoryObject);
        
        const selectedStateObject = _.find(stateList,{"value":selectedDormitoryObject.state});
        
        address.city = selectedDormitoryObject.city;
        address.state = selectedStateObject;
        address.postalCode = selectedDormitoryObject.zip;
        
        data[addressType] = address;
        this.setState({data});
    }

   
    setUpdateAddressInformation = (updatePrimaryAddressFlag)=>{
        this.setState({
            data:{
                ...this.state.data,
                updatePrimaryAddressFlag,
            }
        })
    }

    //This gets executed once the parent submission process is executed
    submitLocal = ()=>{
        const {onClickOfNextButtonFromCustomerAddressInformation} = this.props;
        console.log('submit local');

        const formData = this.state.data;
        return onClickOfNextButtonFromCustomerAddressInformation(formData)
        
    }

    validateLocal= ()=>{
        //This is anything specific to the form like async validations
        const {updatePrimaryAddressFlag} = this.state.data;
        if(updatePrimaryAddressFlag === false) return null;

        const options = {abortEarly:false,allowUnknown:true};
        let {error:pickUpAddressError} = this.pickupAddressSchema.validate(this.state.data.pickUpAddress,options);
        
        if(pickUpAddressError === undefined) return null;

        const pickUpAddressErrors = {};
        if(pickUpAddressError){
            pickUpAddressError.details.map(
                function(errorObject){
                    const addressKey = "pickUpAddress";
                    const addressLabel = "Pickup Address: ";
                    //console.log(errorObject);
                    if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                        const key = errorObject.context.key;
                        const joiType = errorObject.type;
                        //console.log(this.errorMessages);
                        if(_.has(this.errorMessages, [key, joiType]))
                            return pickUpAddressErrors[`${addressKey}.${errorObject.path[0]}`] = addressLabel + this.errorMessages[key][joiType];
                        else
                            return pickUpAddressErrors[`${addressKey}.${errorObject.path[0]}`] = addressLabel + errorObject.message;
                    }
                    else
                      return pickUpAddressErrors[`${addressKey}.${errorObject.path[0]}`] = addressLabel + errorObject.message;
                }.bind(this)
            );
        }
        
        const errors = {...pickUpAddressErrors} 
        //console.log(errors);
        return Object.keys(errors).length > 0 ? errors:null;
    }

    render(){
        const {
            onClickOfBackButtonFromCustomerAddressInformation,
            user,
            customerAddressComponentDisplayProperties,
        } = this.props;

        
        const { 
            currentAddressTitle, 
            headerTitle,
            stepTitle,
            step, 
        } = customerAddressComponentDisplayProperties;

        
        const {dormitoryList,data,errors,stateList,} = this.state;
        const currentAddress = this.getAddressForDisplay();

        const   {
            updatePrimaryAddressFlag,
            pickUpAddress,
        } = data;

        
        const addressIsDormitoryCheckedYes = (pickUpAddress.isDormitory === "Yes") ? {defaultChecked:true}:{};
        const addressIsDormitoryCheckedNo = (pickUpAddress.isDormitory === "No") ? {defaultChecked:true}:{};
        const addressIsDormitoryCSS = "form-check-radio";
        
        return(
            <Container id="mainContentArea">
                <Row className="title-row justify-content-between">
                    <Col lg="5">
                        <h3 className="shop">{headerTitle}</h3>
                    </Col>
                    <Col lg="6">
                        <div className="text-lg-right">
                            <span className="text-muted">{step}</span>
                            <Button color="link">
                            <i className="fa fa-shopping-cart" /> {stepTitle}
                            </Button>
                        </div>
                    </Col>
                </Row> 
                <Row className="title-body-row">
                    <Col>
                        <Card>
                            <CardBody>
                                {/*Error display */}
                                {!_.isEmpty(errors) &&
                                    <FormErrorWidget
                                    errors={errors}
                                    />
                                }
                                {/* End of Error display */}

                                
                                <div className="mt-2 mb-4">
                                    <h6 className="card-category">
                                        <span className="text-left">
                                        {currentAddressTitle}
                                        </span>
                                    </h6>
                                    <hr/>
                                    <Row className="mt-2">
                                        <Col xs="2">
                                            Current Address
                                        </Col>
                                        <Col xs="8">
                                            {currentAddress}
                                        </Col>
                                    </Row>

                                    {
                                        !updatePrimaryAddressFlag && 
                                        <Row className="mt-2">
                                            <Col xs="2">
                                            </Col>
                                            <Col xs="8">
                                                <Button 
                                                    className="btn-round mr-1"
                                                    color="default"
                                                    outline
                                                    size="sm"
                                                    onClick={()=>this.setUpdateAddressInformation(true)}
                                                >
                                                Update Address
                                                </Button>
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            
                                
                                {
                                    updatePrimaryAddressFlag && 
                                    <Row>
                                        <Col>
                                            <h6>Enter your new campus address:</h6>

                                            <FormGroup row>
                                                <Label for="" xs={6} sm={4} md={2}>Is it a dormitory?</Label>
                                                <Col xs={2} sm={1} className="mt-1">
                                                    <div className={addressIsDormitoryCSS}>
                                                        <Label check>
                                                            <Input
                                                                {...addressIsDormitoryCheckedYes}
                                                                defaultValue="Yes"
                                                                id="deliveryAddressIsDormitory1"
                                                                name="isDormitory"
                                                                type="radio"
                                                                onChange={e=>{this.handleChangeNestedAttribute(e,"pickUpAddress")}}
                                                            />
                                                            Yes
                                                            <span className="form-check-sign" />
                                                        </Label>
                                                    </div>
                                                </Col>
                                                <Col xs={2} sm={1} className="ml-2 mt-1">
                                                    <div className={addressIsDormitoryCSS}>
                                                        <Label check>
                                                            <Input
                                                                {...addressIsDormitoryCheckedNo}
                                                                defaultValue="No"
                                                                id="deliveryAddressIsDormitory2"
                                                                name="isDormitory"
                                                                type="radio"
                                                                onChange={e=>{this.handleChangeNestedAttribute(e,"pickUpAddress")}}
                                                            />
                                                            No
                                                            <span className="form-check-sign" />
                                                        </Label>
                                                    </div>
                                                </Col>
                                                
                                            </FormGroup>

                                            {
                                                (pickUpAddress.isDormitory === "Yes") &&    
                                                <FormGroup row>
                                                    <Label for="pickUpDormitory" sm={2}>Dorm Name</Label>
                                                    <Col xs={10} sm={4}>
                                                        <FormGroup>
                                                            <Select
                                                                className="react-select react-select-default"
                                                                classNamePrefix="react-select"
                                                                name="dormitoryName"
                                                                id="dormitoryName"
                                                                value={pickUpAddress.dormitoryName}
                                                                onChange={value => this.setAddressDormitory(value,"pickUpAddress")}
                                                                options={dormitoryList}
                                                                placeholder="Dorm"
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Label for="addressLine1" sm={2}>Room No.</Label>
                                                    <Col xs={10} sm={4}>
                                                        <Input 
                                                        type="text" 
                                                        name="dormitoryRoomNumber" 
                                                        id="dormitoryRoomNumber" 
                                                        placeholder="Room No" 
                                                        maxLength="10"
                                                        value={pickUpAddress.dormitoryRoomNumber} 
                                                        onChange={e=>{this.handleChangeNestedAttribute(e,"pickUpAddress")}}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            }

                                            {
                                                (pickUpAddress.isDormitory !== "Yes") &&   
                                                <FormGroup row>
                                                    <Label for="streetNumber" sm={2}>Street #</Label>
                                                    <Col sm={4}>
                                                        <Input 
                                                        type="text" 
                                                        name="streetNumber" 
                                                        id="streetNumber" 
                                                        placeholder="Street #" 
                                                        value={pickUpAddress.streetNumber} 
                                                        onChange={e=>{this.handleChangeNestedAttribute(e,"pickUpAddress")}}
                                                        />
                                                    </Col>

                                                    <Label for="apartmentNumber" sm={2}>Apt #</Label>
                                                    <Col sm={4}>
                                                        <Input 
                                                        type="text" 
                                                        name="apartmentNumber" 
                                                        id="apartmentNumber" 
                                                        placeholder="Apt Number" 
                                                        maxLength="10"
                                                        value={pickUpAddress.apartmentNumber} 
                                                        onChange={e=>{this.handleChangeNestedAttribute(e,"pickUpAddress")}}
                                                        />
                                                    </Col>
                                                    
                                                </FormGroup>
                                            }               
                                                
                                            {
                                                (pickUpAddress.isDormitory !== "Yes") &&   
                                                <FormGroup row>
                                                    <Label for="addressLine1" sm={2}>Street Name</Label>
                                                    <Col sm={10}>
                                                        <Input 
                                                            type="text" 
                                                            name="addressLine1" 
                                                            id="addressLine1" 
                                                            placeholder="Street Name" 
                                                            value={pickUpAddress.addressLine1} 
                                                            onChange={e=>{this.handleChangeNestedAttribute(e,"pickUpAddress")}}
                                                        />
                                                    </Col>
                                                    
                                                </FormGroup>
                                            }
                                            {
                                                (pickUpAddress.isDormitory !== "Yes") &&  
                                                <FormGroup row className="d-none">
                                                    <Label for="addressLine2" sm={2}>Address Line 2</Label>
                                                    <Col sm={10}>
                                                        <Input 
                                                            type="text" 
                                                            name="addressLine2" 
                                                            id="addressLine2" 
                                                            placeholder="Address Line 2" 
                                                            value={pickUpAddress.addressLine2} 
                                                            onChange={e=>{this.handleChangeNestedAttribute(e,"pickUpAddress")}}
                                                        />
                                                    </Col>
                                                    
                                                </FormGroup>
                                            }    
                                                
                                                
                                            <FormGroup row>
                                                <Label for="city" sm={2}></Label>
                                                <Col xs={12} sm={3}>
                                                    <Input 
                                                    type="text" 
                                                    name="city" 
                                                    id="city" 
                                                    placeholder="City" 
                                                    value={pickUpAddress.city} 
                                                    onChange={e=>{this.handleChangeNestedAttribute(e,"pickUpAddress")}}
                                                    />
                                                </Col>
                                                <Label for="state" xs={12} sm={2} className="d-block d-sm-none"></Label>
                                                <Col xs={12} sm={4}>
                                                    <FormGroup>
                                                        <Select
                                                            className="react-select react-select-default"
                                                            classNamePrefix="react-select"
                                                            name="state"
                                                            id="state"
                                                            value={pickUpAddress.state}
                                                            onChange={value => this.handleDropdownChangeNestedAttribute(value,"state","pickUpAddress",)}
                                                            options={stateList}
                                                            placeholder="State"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} sm={3}>
                                                    <Input 
                                                        type="text" 
                                                        name="postalCode" 
                                                        id="postalCode" 
                                                        placeholder="Zip" 
                                                        maxLength="5"
                                                        value={pickUpAddress.postalCode} 
                                                        onChange={e=>{this.handleChangeNestedAttribute(e,"pickUpAddress")}}
                                                    />
                                                </Col>
                                            </FormGroup>

                                            

                                        </Col>
                                    </Row>  
                                
                            
                            
                                }
                                
                                

                                <FormGroup row className="mt-5 justify-content-between">
                                    <Col xs={{ size: 2 }}>
                                        <Button 
                                        className="btn-rounded btn-danger"
                                        onClick={()=>onClickOfBackButtonFromCustomerAddressInformation()}
                                        >
                                        Back
                                        </Button>
                                    </Col>
                                    <Col xs={{ size: 6}} sm={{ size: 4}} className="text-right">
                                        <Button 
                                        className="btn-rounded btn-danger"
                                        onClick={this.submitForm}
                                        >
                                        Save & Proceed
                                        </Button>
                                    </Col>
                                </FormGroup>
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }


}

export default CustomerAddressInformation;

