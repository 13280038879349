import React,{Component} from "react"
import _ from "lodash";
import Joi from "@hapi/joi";

import Select from "react-select";
import {getUSStateList} from "services/dropDownListsService";
import {renderError} from '../../../../../../utils/errorUtils';
import {scrollTop} from '../../../../../../utils/helperUtils';
import FormErrorWidget from "../../../../../Common/FormErrorWidget";




import {
    Button,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Label,
    Row,
    Col,
    FormGroup, 
    Modal, 
    Input, 
} from "reactstrap";

const upsImage = require("../../../../../../assets/mylazybones/img/ups_logo_new.png")

class ShipWithUsModal extends Component{
    
    validationSchema = Joi.object({
        labelType: Joi.string().required().label('Label type'),
        quantity: Joi.object().required().label('Quantity'),
    })

    validationAddressSchema = Joi.object({
        addressLine1: Joi.string().required().label('Address Line 1'),
        city: Joi.string().required().label('City'),
        state:Joi.object().required().label('State'),
        postalCode: Joi.string().required().label('Postal Code'),
        country:Joi.string().required().label('Country'),
        
    })

    errorMessages = {
        "state":{
            "object.base":"State is not allowed to be empty",
        },

        "quantity":{
            "object.base":"Quantity is not allowed to be empty",
        },
        
    }

    quantityList = [
        { value: "", label: "", isDisabled: true },
        { value: 1, label: "1"},
        { value: 2, label: "2"},
        { value: 3, label: "3"},
        { value: 4, label: "4"},
        { value: 5, label: "5"},
        { value: 6, label: "6"},
        { value: 7, label: "7"},
        { value: 8, label: "8"},
        { value: 9, label: "9"},
        { value: 10, label: "10"},
        { value: 11, label: "11"},
        { value: 12, label: "12"},
        
    ]

    constructor(){
        super();
        this.state = {
            stateList:[{ value: "", label: "", isDisabled: true }],
            data:{
                labelType:"Shipping",
                quantity:"",
                shippingAddress:{
                    addressLine1:"",
                    city:"",
                    state:"",
                    postalCode:"",
                    country:"USA", 
                },
            },
            errors:{}
        }
    }

    async componentDidMount(){
        try{
            const stateList = getUSStateList();
            this.setState({
                stateList,
            });
        }
        catch(ex){
            console.log(`Component did mount on Laundry Student Info ${ex}`);
        }
        
    }

    handleChange = ({currentTarget:input})=>{
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[input.name] = input.value;
        this.setState({data,errors});
        
    }

    handleChangeAddress = (e,addressType)=>{
        const {name,value} = e.currentTarget;
        //console.log(`${name} : ${value}`);
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        address[name] = value;
        data[addressType] = address;
        this.setState({data});
     }

    handleDropdownChange = (value,name)=>{
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[name] = value;
        this.setState({data,errors});
    }

    setAddressState = (value,addressType)=>{
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        address.state = value;
        data[addressType] = address;

        this.setState({data});

    }


    submitForm = (e)=>{
            e.preventDefault(); 
            
            const errors = this.validate();
            this.setState({errors: errors || {}});
            if(errors){ scrollTop('shipWithUsModal'); return;}
            

            //client side errors will be caught in validae(), server side data error will be caught via try/catch
            //const errors =  this.validate();
            //this.setState({errors: errors || {}});
            //if(errors){scrollTop('shipWithUsModal'); return;} 
            
            const {handleSaveOperation} = this.props;  
            const data = {...this.state.data};

            const shippingAddress = {...data.shippingAddress}
            if(_.isObject(shippingAddress.state))
                shippingAddress.state = shippingAddress.state.value;
            
            const formData = {
                labelType: data.labelType,
                quantity: data.quantity.value,
                shippingAddress:shippingAddress,
            }

            return handleSaveOperation(formData);
    }

    parseErrors = (validationErrorList)=>{
        const validationErrors = {};
        if(validationErrorList){
            console.log(validationErrorList.details);
            validationErrorList.details.map(
                function(errorObject){
                    //console.log(errorObject);
                    if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                        const key = errorObject.context.key;
                        const joiType = errorObject.type;
                        //console.log(this.errorMessages);
                        if(_.has(this.errorMessages, [key, joiType]))
                            return validationErrors[errorObject.path[0]] = this.errorMessages[key][joiType];
                        else
                            return validationErrors[errorObject.path[0]] = errorObject.message;
                    }
                    else if(_.has(errorObject, ['context', 'peers']) &&  _.has(errorObject, ['type'])){
                        const joiType = errorObject.type;
                        if(joiType === "object.missing")
                            return validationErrors[errorObject.path[0]] = this.errorMessages.minimumFieldsMessage.message;
                    }
                    else
                        return validationErrors[errorObject.path[0]] = errorObject.message;
                }.bind(this)
            );
        }

        return validationErrors
    }

    validate = ()=>{
        try{
            let data  = {...this.state.data}, 
                errorList = null;

            const options = {abortEarly:false,allowUnknown:true},
                  {labelType,quantity,shippingAddress} = data;
            
            ({error:errorList} = this.validationSchema.validate({labelType,quantity},options));
            const primaryErrors = this.parseErrors(errorList);

            
            ({error:errorList} = this.validationAddressSchema.validate(shippingAddress,options));
            const shippingAddressErrors = this.parseErrors(errorList);
            
            const errors = {...primaryErrors,...shippingAddressErrors} 
        
            //console.log(errors);
            return Object.keys(errors).length > 0 ? errors:null;
        }catch(ex){
            console.log(ex);
        }

    }

    getSchedulePickupModalText = ()=>{
        const {
            modalDisplayFlag,
            handleModalDisplay,
            handleLaunchSchedulePickupModal,
            isStorageDetailAvailable,
            setPickupScheduleFlag,
            ...rest
        } = this.props;

        let returnData = "";
        if(isStorageDetailAvailable){
            returnData = <li>
                First schedule a storage pickup by clicking &nbsp; 
                <a className="btn-link btn btn-info p-0 general-link" onClick={()=>{ handleModalDisplay(false); return handleLaunchSchedulePickupModal(true)}}>HERE</a>
                &nbsp; and following the instructions. If you have already scheduled a pickup, you can skip this step.
            </li>
        }


        return returnData;
                                    
   
    }
    
    render(){
        const {
            modalDisplayFlag,
            handleModalDisplay,
            handleLaunchSchedulePickupModal,
            isStorageDetailAvailable,
            setPickupScheduleFlag,
            ...rest
        } = this.props;

        const {data,errors} = this.state;
        const {quantity} = data;
    
        return(
            <>
            <Modal id="shipWithUsModal" size="lg" isOpen={modalDisplayFlag} toggle={() => handleModalDisplay(false)}>
                <div className="modal-header">
                    <button
                        className="close"
                        type="button"
                        onClick={() => handleModalDisplay(false)}
                    >
                        <span>×</span>
                    </button>
                    <h5
                        className="modal-title text-center"
                        id="exampleModalLabel"
                    >
                        Storage: Ship with Us
                    </h5>
                </div>
                <div className="modal-body">
                    {/*Error display */}
                    {!_.isEmpty(errors) &&
                        <FormErrorWidget
                            errors={errors}
                        />
                    }
                    {/* End of Error display */}

                    <div>
                        <span className="float-right">
                            <img src={upsImage} />
                        </span>
                        <div style={{display:"block",width:"auto"}}>
                            <h6>Shipping Your Stuff With Lazybones</h6>
                                 We can pick up your stuff from your dorm or apartment and ship it via UPS ground.
                                <ul>
                                    {this.getSchedulePickupModalText()}
                                    <li>
                                     Next, print out Shipping Labels from this page. Just choose the quantity of labels you want, enter the shipping destination address, then click "Generate Labels." 
                                    </li>
                                    <li>
                                    Finally, place labels on the boxes/items you want us to ship before we arrive for your storage pickup. Please only adhere one label to each box/item.
                                    </li>
                                </ul>
                                
                                Please be aware that the cost for shipping can be quite expensive. In order to get the best shipping estimate, please visit UPS.com and enter the following information:
                                <ul>
                                    <li>Your campus zip code.</li>
                                    <li>Box/item dimensions at their largest points (length, width, height).</li>
                                    <li>Estimated weight of box.</li>
                                    <li>Indicate payment at a retail location.</li>
                                </ul>
                                <p>
                                    <b>Note:</b> Our box dimensions signify INTERIOR dimensions. When prompted to enter box size, you must add 1” to every side because UPS specs are based on EXTERIOR dimensions. For example, an 18x18x24 box, should be entered as a 19x19x25 box.
                                </p>
                                <p>
                                    Once you have obtained an estimate from the UPS website, remember that our prices will also include a 20% service charge, as with any other retail location.
                                </p>
                        </div>
                    </div>
                    <div>
                        <hr/>
                        <h6># of Labels</h6>
                        <Row>
                            <Col sm="2">Quantity</Col>
                            <Col sm="4">
                                <Select
                                    className="react-select react-select-default"
                                    classNamePrefix="react-select"
                                    name="quantity"
                                    id="quantity"
                                    value={quantity}
                                    onChange={value => this.handleDropdownChange(value,"quantity")}
                                    options={this.quantityList}
                                    placeholder="Quantity"
                                />
                            </Col>
                        </Row>

                        <h6 className="card-category text-left mt-2">Shipping Address</h6>
                        <hr/>
                        {
                            <FormGroup row>
                                <Label for="addressLine1" sm={2}>Address Line</Label>
                                <Col sm={10}>
                                    <Input 
                                        type="text" 
                                        name="addressLine1" 
                                        id="addressLine1" 
                                        placeholder="Street Name" 
                                        value={this.state.data.shippingAddress.addressLine1} 
                                        onChange={e=>{this.handleChangeAddress(e,"shippingAddress")}}
                                    />
                                </Col>
                                
                            </FormGroup>
                        }
                        <FormGroup row>
                            <Label for="city" sm={2}></Label>
                            <Col xs={12} sm={3}>
                                <Input 
                                    type="text" 
                                    name="city" 
                                    id="city" 
                                    placeholder="City" 
                                    value={this.state.data.shippingAddress.city} 
                                    onChange={e=>{this.handleChangeAddress(e,"shippingAddress")}}
                                />
                            </Col>
                            <Label for="state" xs={12} sm={2} className="d-block d-sm-none"></Label>
                            <Col xs={12} sm={4}>
                                <FormGroup>
                                    <Select
                                        className="react-select react-select-default"
                                        classNamePrefix="react-select"
                                        name="state"
                                        id="state"
                                        value={this.state.data.shippingAddress.state}
                                        onChange={value => this.setAddressState(value,"shippingAddress")}
                                        options={this.state.stateList}
                                        placeholder="State"
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={3}>
                                <Input 
                                    type="text" 
                                    name="postalCode" 
                                    id="postalCode" 
                                    placeholder="Zip" 
                                    value={this.state.data.shippingAddress.postalCode} 
                                    onChange={e=>{this.handleChangeAddress(e,"shippingAddress")}}
                                />
                            </Col>
                        </FormGroup>

                    </div>
                </div>
                <div className={`modal-footer`}>
                    <div className="left-side">
                        <Button
                            className="btn-link"
                            color="default"
                            type="button"
                            onClick={() => handleModalDisplay(false)}
                        >
                        Cancel
                        </Button>
                    </div>
                    <div className="divider" />
                    <div className="right-side">
                        <Button className="btn-link" color="danger" type="button" onClick={this.submitForm}>
                            Generate Labels
                        </Button>
                    </div>
                </div>
            </Modal>            
            </>
        );
    }
}

export default ShipWithUsModal;
