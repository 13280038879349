import React from "react";
import _ from "lodash";

import {
    Row,
    Col,
} from "reactstrap";


//Destructuring the props to extract the success object
const SuccessMessageWidget = ({success})=>{
    return(
        <Row>
            <Col xs="12">
                <div className="alert alert-success" role="alert">
                    <Row>
                        <Col xs="1" sm="1" className="fs-42">
                                <i class="fa fa-thumbs-up fa-6" aria-hidden="true"></i>  
                        </Col>
                        <Col>
                        <ul>
                            {
                                _.values(success).map((e,i)=>{
                                    return(
                                        <li key={i}>
                                            {e}
                                        </li> 
                                    );
                                })

                            }
                            
                        </ul> 
                        </Col>
                        
                        
                    </Row>
                    
                </div>
            </Col>
        </Row>
    );
}

export default SuccessMessageWidget;