import React,{useEffect} from 'react';

function PageSetting(props) {
    const {title} = props
    
    useEffect(()=>{
		if(title)
			window.document.title = `${process.env['REACT_APP_PAGE_TITLE']}: ${title}`
	},[title])

    return (
        <>
            {props.children}
        </>
    );
}

export default PageSetting;