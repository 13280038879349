import _ from "lodash";

import http from "./httpService";

const apiUsersEndpoint = "/users";



async function getSalesReportData(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/commercial/getSalesReportData`,formData);
    return returnData;
}


export default {
    getSalesReportData,

}