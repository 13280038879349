import React from "react";

// reactstrap components
import {
    Row,
    Col,
    Modal, 
} from "reactstrap";


const StudentAccountLaundryDoublePoundModal = (props)=>{
    const {modalDisplayFlag,handleModalDisplayFlag} = props;

    return(
        <Modal size="lg" isOpen={modalDisplayFlag} toggle={() =>{handleModalDisplayFlag(false)}}>
                <div className="modal-header">
                    <button
                        className="close"
                        type="button"
                        onClick={() =>{handleModalDisplayFlag(false)}}
                    >
                        <span>×</span>
                    </button>
                    <h5
                        className="modal-title text-center"
                        id="exampleModalLabel"
                    >
                        Double pounds for a missed pick up
                    </h5>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col>
                            <p>
                                If we miss you, we can double your pounds for the following week. For example, if you have a weekly 15lbs plan, and you miss the week, you can add 15lbs to the following week (doubling your limit to 30lbs that week!). Just email us so we can double you up! Just include your name and pick up address.
                            </p>
                            <p>
                                Please email us at <a href="mailto://wecare@mylazybones.com">wecare@mylazybones.com </a> and use the subject line as "Double Pounds".
                            </p>

                        </Col>
                    </Row>
                    
                </div>
        </Modal>
    );
}

export default StudentAccountLaundryDoublePoundModal;