import React,{Component} from "react";
import _ from "lodash";
import {Link} from "react-router-dom";
import accounting from "accounting";
import {StripeProvider} from 'react-stripe-elements';


import customToast from '../../components/Common/CustomToast'
import http from "services/httpService";
import auth from "services/authService";
import {renderError} from 'utils/errorUtils';
import {scrollTop} from 'utils/helperUtils';


import SchoolService from "../../services/schoolService";
import ResidentialService from "../../services/residentialService";
import SignupLaundryStudent from "../../components/Consumer/SignupLaundryStudent";
import SignupLaundryResidential from "../../components/Consumer/SignupLaundryResidential";
import SignupStorageStudent from "../../components/Consumer/SignupStorageStudent";
import SignupSupplies from "../../components/Consumer/SignupSupplies"

import ApplicationConstant from "components/Common/ApplicationConstants";
import applicationConstantUtility from "../../utils/applicationConstants" //This is used everywhere instead of the above one but this page uses the above one
import ProcessingModal from "../../components/Common/ProcessingModal"

import SignupProcessingModal from "../../components/Consumer/SignupSteps/Laundry/ProcessingModal";


const apiLaundryEndpoint = "/laundry";
const apiStorageEndpoint = "/storage";
const apiSuppliesEndpoint = "/supplies";
const REVIEW_SCREEN_STEP = "review-screen";

/*
 /signup/laundry/student
 /signup/laundry/residential
 
*/

class SignUp extends Component{
   constructor(){
        super();
        /*
            currentSignUpProcessingComponent: this is to copy the url params and if there is a change 
            with the url params then we change state 
        */
        this.state = {
            listOfSchoolsByRegionForLaundry:"",
            listOfSchoolsByRegionForStorage:"",
            listOfResidentialCitiesByStateForLaundry:"",
            processingModalDisplayFlag:false,
            processingModalLaundrySignupDisplayFlag:false,
            directUserToReviewScreen:false,
            currentSignUpProcessingComponent:{
                product:"",
                productType:"",
            }, 
            newUser:{
                step:"", 
                stripeMerchantAccountUniverityId:1,
                firstName:"",
                lastName:"",
                email:"",
                username:"",  
                password:"", 
                studentId:"",
                cellPhone:"",
                homePhone:"",
                homeAddress:{
                    addressLine1:"",
                    addressLine2:"",
                    city:"",
                    state:"",
                    postalCode:"",
                    country:"",
                },
                paymentInformation:{
                  appliedPaymentType:"",//Gift Card or Credit Card 
                  creditCardStripeToken:"", 
                  creditCardName:"",  
                  creditCardNumber:"",
                  creditCardExpirationMonth:"",
                  creditCardExpirationYear:"",
                  creditCardCCV:"",
                  billingAddress:{
                        addressLine1:"",
                        addressLine2:"",
                        city:"",
                        state:"",
                        postalCode:"",
                        country:"US",
                  },
                  creditCardResponse:{
                        transactionId:"",
                  },
                },
                selectedProducts:[],//laundry,storage,supplies
                school:"", //Object{schooName,schoolId,semesterlist}
                residentialCity:"", //Object City{id,name,pricing}
                laundry:{
                    type:"", //student,residential,commercial
                    discount:{
                        discountCode:"",
                        discountType:"", //percent and dollar
                        discountValue:"", //discount value 0.05 or 5.00
                        voucherType:"", //Gift card or Coupon
                    }, 
                    totalCost:{
                        subtotal:"",
                        discountAmount:"", //this is basically a credit 
                        tax:"",
                        finalTotal:"",
                    }, 
                    pickupAndDeliveryAddress:{
                        zoneId:"",
                        streetNumber:"", //Non dormitory street number
                        addressLine1:"", //Street name
                        addressLine2:"",
                        apartmentNumber:"", //Non dormitory apartment number
                        city:"",
                        state:"",
                        postalCode:"",
                        country:"USA",
                        dormitory:{
                            id:"",
                            name:"",
                            roomNumber:""
                        },
                        isDormitory:"",
                    },
                    student:{
                        semester:"",    //{id,semester}
                        laundryType:"", //{id,type}
                        pricePlan:"",
                        insurance:"",
                    },
                    residential:{
                        laundryType:"", //{id,type}
                        pricePlan:"",  
                        insurance:"",  

                    },
                    commercial:{}
                },
                storage:{
                    type:"", //student,residential
                    discount:{
                        discountCode:"",
                        discountType:"", //percent and dollar
                        discountValue:"", //discount value 0.05 or 5.00
                        voucherType:"", //Gift card or Coupon
                    },
                    totalCost:{
                        subtotal:"",
                        discountAmount:"",  //this is basically a credit 
                        tax:"",
                        finalTotal:"",
                    },
                    pickUpAddress:{
                        isDormitory:"",
                        dormitory:{
                            id:"",
                            name:"",
                            roomNumber:""
                        },
                        zoneId:"",
                        streetNumber:"", //Non dormitory street number
                        apartmentNumber:"", //Non dormitory apartment number
                        addressLine1:"",
                        addressLine2:"",
                        city:"",
                        state:"",
                        postalCode:"",
                        country:"USA", 
    
                    },
                    deliveryAddress:{
                        isSameAsPickupAddress:"",
                        isDormitory:"",
                        dormitory:{
                            id:"",
                            name:"",
                            roomNumber:""
                        },
                        zoneId:"",
                        streetNumber:"", //Non dormitory street number
                        apartmentNumber:"", //Non dormitory apartment number
                        addressLine1:"",
                        addressLine2:"",
                        city:"",
                        state:"",
                        postalCode:"",
                        country:"USA", 
                    },
                    student:{
                        semester:"",    //{id,semester}
                        pricingProperties:"", //{storageDeposit, salesTax}
                    },
                },
                supplies:{
                    type:"", //student or residential
                    deliveryAddress:{
                        isStudent:false,
                        isDormitory:"",
                        dormitory:{
                            id:"",
                            name:"",
                            roomNumber:""
                        },
                        zoneId:"",
                        streetNumber:"", //Non dormitory street number
                        apartmentNumber:"", //Non dormitory apartment number
                        addressLine1:"",
                        addressLine2:"",
                        city:"",
                        state:"",
                        postalCode:"",
                        country:"USA", 
                    },

                },
                processingSteps:{
                    laundry:{
                        student:{
                            paymentProcessedFlag:false,
                            createAccountFlag:false,
                            emailNotificationFlag:false,
                            loggingYouInFlag:false, 
                        },
                        residential:{
                            paymentProcessedFlag:false,
                            createAccountFlag:false,
                            emailNotificationFlag:false,
                            loggingYouInFlag:false, 
                        }
                    },
                    storage:{
                        student:{
                            paymentProcessedFlag:false,
                            createAccountFlag:false,
                            emailNotificationFlag:false,
                            loggingYouInFlag:false, 
                        },
                    },
                    supplies:{
                        student:{
                            paymentProcessedFlag:false,
                            createAccountFlag:false,
                            emailNotificationFlag:false,
                            loggingYouInFlag:false, 
                        },
                        residential:{
                            paymentProcessedFlag:false,
                            createAccountFlag:false,
                            emailNotificationFlag:false,
                            loggingYouInFlag:false, 
                        }
                    }
                    
                }       

            },
            serverErrors:{},
        }
   }

   componentDidMount(){
       console.log('signup component did mount');
       this.setNewUserProperties();
   }

   componentDidUpdate(){
       console.log('signup component did update');  
       /*When a user clicks on another link in the nav bar it needs to update state */
       this.setNewUserProperties();
   }

   /*
     subtotal: Plan + insurance
      
     GiftCard: We subtract the gift card value to the final amount
     Coupon: We update the subtotal which affects the tax and final amount

   */
   calculateTotalCostLaundry(laundryType,pricePlanProperties,insuranceProperties,taxPercentage,discountProperties){
        let totalCost = {};
        const isSemesterPlan = (laundryType.id === ApplicationConstant.SEMESTER_PLAN) ? true:false;
        
        let [subTotal,
            tax,
            discountAmount,
            discountPercent,
            finalTotal,
            originalTotalWithoutDiscount] = [0,0,0,0,0,{}];

        if(isSemesterPlan){
            subTotal = parseFloat(accounting.toFixed((pricePlanProperties.price + insuranceProperties.totalPrice),2));
            tax = parseFloat(accounting.toFixed((subTotal * (taxPercentage/100)),2));
            finalTotal  = parseFloat(accounting.toFixed((subTotal + tax),2));
            originalTotalWithoutDiscount = {
                subTotal,
                tax,
                finalTotal,
            }

            if(discountProperties.voucherType === applicationConstantUtility.DISCOUNT_TYPE_GIFTCARD){
                if(discountProperties.discountType === applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR){
                  discountAmount = (isNaN(discountProperties.discountValue)) ? 0:discountProperties.discountValue;
                  discountAmount = parseFloat(accounting.toFixed(discountAmount,2));
                }
                finalTotal  = subTotal + tax - discountAmount;
                finalTotal = parseFloat(accounting.toFixed(finalTotal,2));
            }
            else if(discountProperties.voucherType === applicationConstantUtility.DISCOUNT_TYPE_COUPON){    
                if(discountProperties.discountType === applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR){
                    discountAmount = (isNaN(discountProperties.discountValue)) ? 0:discountProperties.discountValue;
                    discountAmount = parseFloat(accounting.toFixed(discountAmount,2));
                    subTotal = subTotal - discountAmount;
                }
                else if(discountProperties.discountType === applicationConstantUtility.DISCOUNT_VALUE_TYPE_PERCENT){
                    discountPercent = (isNaN(discountProperties.discountValue)) ? 0:discountProperties.discountValue;
                    discountAmount = parseFloat(accounting.toFixed(subTotal * (discountPercent/100),2));
                    subTotal = subTotal - discountAmount;
                }
                subTotal = parseFloat(accounting.toFixed(subTotal,2));
                tax = parseFloat(accounting.toFixed((subTotal * (taxPercentage/100)),2));
                finalTotal  = subTotal + tax;
                finalTotal = parseFloat(accounting.toFixed(finalTotal,2));
            }
        }
        else{
           //By the block 
           if(ApplicationConstant.BY_THE_POUND_SIGNUP_MINIMUMCHARGE > 0){
                subTotal = ApplicationConstant.BY_THE_POUND_SIGNUP_MINIMUMCHARGE;
                tax = (ApplicationConstant.COMPUTE_TAX_FOR_BY_THE_POUND === true ) ? parseFloat(accounting.toFixed((subTotal * (taxPercentage/100)),2)):0;
                discountAmount = parseFloat(accounting.toFixed((isNaN(discountProperties.discountValue)) ? 0:discountProperties.discountValue,2));
                finalTotal  = subTotal + tax - discountAmount;
                finalTotal = parseFloat(accounting.toFixed(finalTotal,2));
           }
           else{
                //This is to handle $0 on By the pound plan
                if(discountProperties.discountType === applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR){
                    discountAmount = parseFloat(accounting.toFixed((isNaN(discountProperties.discountValue)) ? 0:discountProperties.discountValue,2));
                    finalTotal  = subTotal + tax - discountAmount;
                    finalTotal = parseFloat(accounting.toFixed(finalTotal,2));
                }
           }
        }

        totalCost = {
            subTotal,
            discountAmount,
            tax,
            finalTotal,
            originalTotalWithoutDiscount,
        }
        

        return totalCost;
   }

   /*
    GiftCard: We subtract the gift card value to the final amount
     Coupon: We update the subtotal which affects the tax and final amount

   */
   calculateTotalCostStorage(priceProperties,taxPercentage,discountProperties){
        let totalCost = {};

        let [subTotal,
            tax,
            discountAmount,
            discountPercent,
            finalTotal,
            originalTotalWithoutDiscount] = [0,0,0,0,0,{}];

        subTotal = parseFloat(accounting.toFixed((priceProperties.storageDeposit),2));
        finalTotal  = parseFloat(accounting.toFixed((subTotal + tax),2));
        originalTotalWithoutDiscount = {
            subTotal,
            tax,
            finalTotal,
        }

        if(discountProperties.voucherType === applicationConstantUtility.DISCOUNT_TYPE_GIFTCARD){
            if(discountProperties.discountType === applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR){
                discountAmount = (isNaN(discountProperties.discountValue)) ? 0:discountProperties.discountValue;
                discountAmount = parseFloat(accounting.toFixed(discountAmount,2));
                
            }
        }
        else if(discountProperties.voucherType === applicationConstantUtility.DISCOUNT_TYPE_COUPON){
            //We are not going to apply the discounts to the totals but apply it to the first invoice
        }
        
        //tax = parseFloat(accounting.toFixed((subTotal * (taxPercentage/100)),2));
        finalTotal  = subTotal + tax - discountAmount;
        finalTotal = parseFloat(accounting.toFixed(finalTotal,2));    
        
        totalCost = {
            subTotal,
            discountAmount,
            tax,
            finalTotal,
            originalTotalWithoutDiscount,
        }
        
        return totalCost;
   }

   getRedirectUserToReviewScreenFlag = ()=>{
       return this.state.directUserToReviewScreen;
   }

   handleCouponCodeProcessLaundryStudent = (formData)=>{
        //console.log(formData);
        const {couponCode,couponType,giftCardBalance,giftCardAmountToBeUsedForRedemption,couponDetails}  = formData;
        const newUser = {...this.state.newUser}
        
        let discountProperties = {};
        if(couponType === applicationConstantUtility.DISCOUNT_TYPE_GIFTCARD){
            discountProperties = {
                ...newUser.laundry.discount,
                discountCode:couponCode,
                discountType:applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR, 
                discountValue:giftCardAmountToBeUsedForRedemption, 
                voucherType:couponType, 
            }
        }
        else if(couponType === applicationConstantUtility.DISCOUNT_TYPE_COUPON){
            const {couponDiscountType,couponDiscountValue} = couponDetails
            if(couponDiscountType === applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR){
                discountProperties = {
                    ...newUser.laundry.discount,
                    discountCode:couponCode,
                    discountType:applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR, 
                    discountValue:couponDiscountValue, 
                    voucherType:couponType, 
                } 
            }
            else if(couponDiscountType === applicationConstantUtility.DISCOUNT_VALUE_TYPE_PERCENT){
                discountProperties = {
                    ...newUser.laundry.discount,
                    discountCode:couponCode,
                    discountType:applicationConstantUtility.DISCOUNT_VALUE_TYPE_PERCENT, 
                    discountValue:couponDiscountValue, 
                    voucherType:couponType, 
                } 
            }

        }
        
        const {pricePlan,insurance,laundryType} = newUser.laundry.student;
        const {laundry_tax:taxPercentage} =  newUser.school.university;
        
        
        const totalCost = this.calculateTotalCostLaundry(laundryType,pricePlan,insurance,taxPercentage,discountProperties);
        
        newUser.laundry.discount = discountProperties;
        newUser.laundry.totalCost = totalCost;
        this.setState({newUser})

   }

   handleCouponCodeProcessLaundryResidential = (formData)=>{
        const {couponCode,couponType,giftCardBalance,giftCardAmountToBeUsedForRedemption,couponDetails}  = formData;
        const newUser = {...this.state.newUser}

        let discountProperties = {};
        if(couponType === applicationConstantUtility.DISCOUNT_TYPE_GIFTCARD){
            discountProperties = {
                ...newUser.laundry.discount,
                discountCode:couponCode,
                discountType:applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR, 
                discountValue:giftCardAmountToBeUsedForRedemption, 
                voucherType:couponType, 
            }
        }
        else if(couponType === applicationConstantUtility.DISCOUNT_TYPE_COUPON){
            const {couponDiscountType,couponDiscountValue} = couponDetails
            if(couponDiscountType === applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR){
                discountProperties = {
                    ...newUser.laundry.discount,
                    discountCode:couponCode,
                    discountType:applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR, 
                    discountValue:couponDiscountValue, 
                    voucherType:couponType, 
                } 
            }
            else if(couponDiscountType === applicationConstantUtility.DISCOUNT_VALUE_TYPE_PERCENT){
                discountProperties = {
                    ...newUser.laundry.discount,
                    discountCode:couponCode,
                    discountType:applicationConstantUtility.DISCOUNT_VALUE_TYPE_PERCENT, 
                    discountValue:couponDiscountValue, 
                    voucherType:couponType, 
                } 
            }

        }
        
        const {pricePlan,insurance,laundryType} = newUser.laundry.residential;
        const {laundry_tax:taxPercentage} =  newUser.residentialCity.university;
        
        
        const totalCost = this.calculateTotalCostLaundry(laundryType,pricePlan,insurance,taxPercentage,discountProperties);
        newUser.laundry.discount = discountProperties;
        newUser.laundry.totalCost = totalCost;
        this.setState({newUser})
    }

   handleCouponCodeProcessStorageStudent = (formData)=>{
        const {couponCode,couponType,giftCardBalance,giftCardAmountToBeUsedForRedemption,couponDetails}  = formData;
        const newUser = {...this.state.newUser}

        let discountProperties = {};
        if(couponType === applicationConstantUtility.DISCOUNT_TYPE_GIFTCARD){
            discountProperties = {
                ...newUser.storage.discount,
                discountCode:couponCode,
                discountType:applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR, 
                discountValue:giftCardAmountToBeUsedForRedemption, 
                voucherType:couponType, 
            }
        }
        else if(couponType === applicationConstantUtility.DISCOUNT_TYPE_COUPON){
            const {couponDiscountType,couponDiscountValue} = couponDetails
            if(couponDiscountType === applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR){
                discountProperties = {
                    ...newUser.storage.discount,
                    discountCode:couponCode,
                    discountType:applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR, 
                    discountValue:couponDiscountValue, 
                    voucherType:couponType, 
                } 
            }
            else if(couponDiscountType === applicationConstantUtility.DISCOUNT_VALUE_TYPE_PERCENT){
                discountProperties = {
                    ...newUser.storage.discount,
                    discountCode:couponCode,
                    discountType:applicationConstantUtility.DISCOUNT_VALUE_TYPE_PERCENT, 
                    discountValue:couponDiscountValue, 
                    voucherType:couponType, 
                } 
            }

        }

        const {pricingProperties} = newUser.storage.student;
        const {salesTax:taxPercentage} =  pricingProperties;
        
        
        const totalCost = this.calculateTotalCostStorage(pricingProperties,taxPercentage,discountProperties);
        newUser.storage.discount = discountProperties;
        newUser.storage.totalCost = totalCost;
        this.setState({newUser})
    
       
   }

   handleCloseOfProcessingModalWindow = (flag)=>{
        const myAccountState = {...this.state}
        myAccountState.processingModalDisplayFlag = flag;
        this.setState(myAccountState);
    }

   handleResidentialBackOnLaundryPricingAndInsuranceInformation = ()=>{
      //const newUser = {...this.state.newUser};
      //newUser.step = "select-city";
      //this.setState({newUser})

      const {product,productType} = this.props.match.params;
      let step = "select-city";
      
      const directUserToReviewScreen = this.getRedirectUserToReviewScreenFlag();
      if(directUserToReviewScreen) this.setState({ directUserToReviewScreen:!directUserToReviewScreen }); 
      
      this.props.history.push(`/signup/${product}/${productType}/${step}`); 
   }

   handleResidentialBackOnCustomerInformation = ()=>{
      //const newUser = {...this.state.newUser};
      //newUser.step = "select-pricing-and-insurance";
      //this.setState({newUser});

      const {product,productType} = this.props.match.params;
      let step = "select-pricing-and-insurance";

      const directUserToReviewScreen = this.getRedirectUserToReviewScreenFlag();
      if(directUserToReviewScreen) this.setState({ directUserToReviewScreen:!directUserToReviewScreen }); 
      
      this.props.history.push(`/signup/${product}/${productType}/${step}`); 
   }

   handleResidentialBackOnReviewScreen = ()=>{
        //const newUser = {...this.state.newUser};
        //newUser.step = "enter-login-payment-information";
        //this.setState({newUser});

        const {product,productType} = this.props.match.params;
        let step = "enter-login-payment-information";
        this.props.history.push(`/signup/${product}/${productType}/${step}`); 
   }

   /*
    onClickOfPurchaseButtonFromReviewScreen
    async function 
    */
   handleResidentialPurchaseForLaundryRequest = async ()=>{
        try{
            const newUser = {...this.state.newUser};
            const productType = newUser.laundry.type;
            //console.log(productType);
            if(productType === "residential"){
                const processingSteps  = {...newUser.processingSteps.laundry[productType]};
                this.setState({serverErrors:""});
                this.setProcessingModalSignupDisplayFlag(true);
                
                //console.log(processingSteps);
                //console.log(newUser);
                
                //Build payload
                const payload = {...this.state.newUser};
                //console.log(payload);
                const response = await http.post(`${apiLaundryEndpoint}/residential/signup`,payload);
                const {status,message,errorType} = response.data;

                if(status === true){
                    //console.log(response);
                    //return;
                    //console.log(response.headers['x-auth-token']);
                    auth.loginWithJwt(response.headers['x-auth-token']);
                    //this.props.history.push("/");
                    
                    processingSteps.paymentProcessedFlag = true;
                    processingSteps.createAccountFlag = true;
                    processingSteps.emailNotificationFlag = true;
                    processingSteps.loggingYouInFlag = true;
                    newUser.processingSteps.laundry[productType] = processingSteps;
                    this.setState({newUser})

                    setTimeout(()=>{
                        window.location = "/myaccount/residential/laundry?welcome=true";  
                    },2000);
                }
                else{
                    this.setProcessingModalSignupDisplayFlag(false);
                    //Handle errors from the server
                    let [errors,errorMessage] = [{},""];
                    if(errorType === "credit-card"){
                        errorMessage = <>
                           {`${message} Click `}<Link to="/signup/laundry/residential/enter-login-payment-information">here</Link>{` to re-enter payment informtion.`}
                        </>
                    }
                    else if(errorType === applicationConstantUtility.FINAL_PROCESSING_ERROR_TYPE_SIGNUP){
                        return this.props.history.replace("/signup/manual-processing/confirmation/laundry")
                    }
                    else{
                        errorMessage = message; 
                    } 
                    
                    errors = {message:errorMessage};
                    this.setState({serverErrors: errors || {}});
                }

                
            }  
        }
        catch(ex){
            this.setProcessingModalSignupDisplayFlag(false);
            //console.log('handleStudentPurchaseForLaundryRequest',ex);
            /*
                400 signals validation errors from the server
                renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({serverErrors: errors || {}});
                if(errors){ scrollTop('sectionArea'); return;}
            }
            else
                renderError(ex);    
        }  
        
    }

   handleResidentialSaveLaundryPricingAndInsuranceInformation = formData=>{
       //console.log(formData);
       //let processingModalDisplayFlag = true;
       //this.setState({processingModalDisplayFlag});
        
       const {product,productType} = this.props.match.params;
        
       const newUser = {...this.state.newUser};
       let step="";
       if(newUser.laundry.type === "residential"){
            const {laundryType,pricePlan,insurance} = formData;
            const discountProperties = {...newUser.laundry.discount}
            const {laundry_tax:taxPercentage} =  newUser.residentialCity.university;
            const totalCost = this.calculateTotalCostLaundry(laundryType,pricePlan,insurance,taxPercentage,discountProperties);
           
            const residentialLaundryPricingAndInsuranceInformation = {
                laundryType, 
                pricePlan,
                insurance
            }
            step = "enter-residential-customer-information";
            newUser.laundry[newUser.laundry.type] = residentialLaundryPricingAndInsuranceInformation;
            newUser.laundry.totalCost = totalCost;
        }
        
        const directUserToReviewScreen = this.getRedirectUserToReviewScreenFlag();
        if(directUserToReviewScreen){
            this.setState({ newUser,directUserToReviewScreen:!directUserToReviewScreen }); 
            this.props.history.push(`/signup/${product}/${productType}/${REVIEW_SCREEN_STEP}`); 
        } 
        else{
            this.setState({newUser}); 
            this.props.history.push(`/signup/${product}/${productType}/${step}`); 
        }
   }

   handleResidentialSaveCustomerInformation = (formData)=>{
        //console.log(formData);
        const {product,productType} = this.props.match.params;
        const newUser = {...this.state.newUser};
            
        let step="";
        if(newUser.laundry.type === "residential"){
            const {firstName,lastName,email,cellPhone,homePhone,address} = formData;
            newUser.firstName = firstName;
            newUser.lastName = lastName;
            newUser.email = email;
            if(newUser.username === "") newUser.username = email;
            newUser.cellPhone = cellPhone;
            newUser.homePhone = homePhone;

            /*
            We need set homeAddress and laundry.residential.pickupAndDeliveryAddress
            */
            address.state = address.state.value;
            newUser.homeAddress = address;

            const pickupAndDeliveryAddress = {...this.state.newUser.laundry.pickupAndDeliveryAddress};
            pickupAndDeliveryAddress.streetNumber = address.streetNumber;
            pickupAndDeliveryAddress.apartmentNumber = address.apartmentNumber
            pickupAndDeliveryAddress.addressLine1 = address.addressLine1;
            pickupAndDeliveryAddress.addressLine2 = address.addressLine2;
            pickupAndDeliveryAddress.city = address.city;
            pickupAndDeliveryAddress.state = address.state;
            pickupAndDeliveryAddress.postalCode = address.postalCode;
            pickupAndDeliveryAddress.country = address.country;
            pickupAndDeliveryAddress.isDormitory = false;
            pickupAndDeliveryAddress.dormitory={
                id:"",
                name:"",
                roomNumber:""
            };
            newUser.laundry.pickupAndDeliveryAddress = pickupAndDeliveryAddress;

            step = "enter-login-payment-information";
            
            const directUserToReviewScreen = this.getRedirectUserToReviewScreenFlag();
            if(directUserToReviewScreen){
                this.setState({ newUser,directUserToReviewScreen:!directUserToReviewScreen }); 
                this.props.history.push(`/signup/${product}/${productType}/${REVIEW_SCREEN_STEP}`); 
            } 
            else{
                this.setState({newUser}); 
                this.props.history.push(`/signup/${product}/${productType}/${step}`); 
            }
        }    
   }

   handleResidentialSelectCity = (c)=>{
        let processingModalDisplayFlag = true;
        this.setState({processingModalDisplayFlag});
        
        const selectedCity  = _.pick(c,['id','name','pricing','insurance','universityId','university']);
        const {product,productType} = this.props.match.params;
        const newUser = {...this.state.newUser}
        
        let step="";
        newUser.residentialCity = selectedCity;
        newUser.stripeMerchantAccountUniverityId = selectedCity.universityId;
        processingModalDisplayFlag = false;
        this.setState({processingModalDisplayFlag,newUser})
        
        step = "select-pricing-and-insurance";
        this.props.history.push(`/signup/${product}/${productType}/${step}`); 
   }

   

   handleStudentBackOnLaundrySemesterAndPricingInformation = ()=>{
        //const newUser = {...this.state.newUser};
        //newUser.step = "enter-student-information";
        //this.setState({newUser});

        let step = "enter-student-information";
        const directUserToReviewScreen = this.getRedirectUserToReviewScreenFlag();
        if(directUserToReviewScreen) this.setState({ directUserToReviewScreen:!directUserToReviewScreen }); 
      
        this.props.history.push(`/signup/laundry/student/${step}`);  
   }

   handleBackOnLoginAndPaymentInformation = ()=>{
        console.log('Back to Semeseter and Pricing Information'); 
        /*
        const newUser = {...this.state.newUser};
        const {currentSignUpProcessingComponent} = this.state;
        if(currentSignUpProcessingComponent.product === "laundry"){
            if(newUser.laundry.type === "residential"){
                newUser.step = "enter-residential-customer-information";
            }
            else if(newUser.laundry.type === "student"){
                newUser.step = "select-semester-pricing";
            }
        }
        else if(currentSignUpProcessingComponent.product === "storage"){
            newUser.step = "enter-student-information";
        }
        else if(currentSignUpProcessingComponent.product === "supplies"){
            newUser.step = "enter-customer-information";
        }
        
        this.setState({newUser});
        */

        const newUser = {...this.state.newUser};
        const {currentSignUpProcessingComponent} = this.state;
        const {product,productType} = this.props.match.params;

        let step="";
        if(currentSignUpProcessingComponent.product === "laundry"){
            if(newUser.laundry.type === "residential"){
                step = "enter-residential-customer-information";
            }
            else if(newUser.laundry.type === "student"){
                step = "select-semester-pricing";
            }
        }
        else if(currentSignUpProcessingComponent.product === "storage"){
            step = "enter-student-information";
        }
        else if(currentSignUpProcessingComponent.product === "supplies"){
            step = "enter-customer-information";
        }
        
        const directUserToReviewScreen = this.getRedirectUserToReviewScreenFlag();
        if(directUserToReviewScreen) this.setState({ directUserToReviewScreen:!directUserToReviewScreen }); 
      
        this.props.history.push(`/signup/${product}/${productType}/${step}`); 
   }

   handleStudentBackOnReviewScreen = ()=>{
        //const newUser = {...this.state.newUser};
        //newUser.step = "enter-login-payment-information";
        //this.setState({newUser});
        
        const {product,productType} = this.props.match.params;
        let step = "enter-login-payment-information";
        this.props.history.push(`/signup/${product}/${productType}/${step}`); 
    }

   handleStudentBackOnStudentInformation = ()=>{
       //console.log('Back to select School'); 
       //const newUser = {...this.state.newUser};
       //this.setState({newUser});
       
       let step = "select-school";
       const directUserToReviewScreen = this.getRedirectUserToReviewScreenFlag();
        if(directUserToReviewScreen) this.setState({ directUserToReviewScreen:!directUserToReviewScreen }); 
      
       this.props.history.push(`/signup/laundry/student/${step}`);  
   }

    /*
    onClickOfPurchaseButtonFromReviewScreen
    async function 

    server errors get transmitted via props to the review screen
    */
    handleStudentPurchaseForLaundryRequest = async ()=>{
        try{
            const newUser = {...this.state.newUser};
            if(newUser.laundry.type === "student"){
                const processingSteps  = {...newUser.processingSteps.laundry.student};
                this.setState({serverErrors:""});
                this.setProcessingModalSignupDisplayFlag(true);
                /*
                try{
                    let paymentProcessedFlag = await processCreditCard();
                    let createAccountFlag = await saveStudentLaundryInformation();
                    let emailNotificationFlag = await sendStudentyLaundryDetailsViaEmail();
                    
                    //If all looks good then 
                    - Display confirmation screen
                    - Click to login to view my account info

                }
                catch(ex){

                }
                */
                
                //Build payload
                const payload = {...this.state.newUser};
                //console.log(payload);
                const response = await http.post(`${apiLaundryEndpoint}/student/signup`,payload);
                const {status,message,errorType} = response.data;
                
                if(status === true){
                    auth.loginWithJwt(response.headers['x-auth-token']);
                    
                    processingSteps.paymentProcessedFlag = true;
                    processingSteps.createAccountFlag = true;
                    processingSteps.emailNotificationFlag = true;
                    processingSteps.loggingYouInFlag = true;
                    newUser.processingSteps.laundry.student = processingSteps;
                    this.setState({newUser})

                    setTimeout(()=>{
                        window.location = "/myaccount/student/laundry?welcome=true";  
                    },2000);
                    return;
                }else{
                    this.setProcessingModalSignupDisplayFlag(false);
                    //Handle errors from the server
                    let [errors,errorMessage] = [{},""];
                    if(errorType === "credit-card"){
                        errorMessage = <>
                           {`${message} Click `}<Link to="/signup/laundry/student/enter-login-payment-information">here</Link>{` to re-enter payment informtion.`}
                        </>
                    }
                    else if(errorType === applicationConstantUtility.FINAL_PROCESSING_ERROR_TYPE_SIGNUP){
                        return this.props.history.replace("/signup/manual-processing/confirmation/laundry")
                    }
                    else{
                        errorMessage = message; 
                    } 
                    
                    errors = {message:errorMessage,errorType};
                    this.setState({serverErrors: errors || {}});
                    
                }

            }  
        }
        catch(ex){
            this.setProcessingModalSignupDisplayFlag(false);
            //console.log('handleStudentPurchaseForLaundryRequest',ex);
            /*
              400 signals validation errors from the server
              renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({serverErrors: errors || {}});
                if(errors){ scrollTop('sectionArea'); return;}
            }
            else
             renderError(ex);
        }
          
        
    }

   handleSaveStudentInformation = (studentInformationForm)=>{
       //console.log(studentInformationForm)
       const {firstName,lastName,email,cellPhone,homePhone,studentId,pickUpAddress:pickUpAddressFormData} = studentInformationForm;
       let step = "select-semester-pricing";
       
       const newUser = {...this.state.newUser};
       newUser.firstName = firstName;
       newUser.lastName = lastName;
       newUser.email = email;
       if(newUser.username === "") newUser.username = email;

       newUser.cellPhone = cellPhone;
       newUser.homePhone = homePhone;
       newUser.studentId = studentId;
       newUser.laundry = {...this.state.newUser.laundry}
       newUser.laundry.pickupAndDeliveryAddress = {
            isDormitory: (pickUpAddressFormData.isDormitory === "Yes")? true:false,
            dormitory:{
                id:(pickUpAddressFormData.isDormitory === "Yes") ? pickUpAddressFormData.dormitoryName.value:"",
                name:(pickUpAddressFormData.isDormitory === "Yes") ? pickUpAddressFormData.dormitoryName.label:"",
                roomNumber:(pickUpAddressFormData.isDormitory === "Yes") ? pickUpAddressFormData.dormitoryRoomNumber:""
            },
            streetNumber:(pickUpAddressFormData.isDormitory !== "Yes") ? pickUpAddressFormData.streetNumber:"",
            addressLine1:(pickUpAddressFormData.isDormitory !== "Yes") ? pickUpAddressFormData.addressLine1:"",
            addressLine2:(pickUpAddressFormData.isDormitory !== "Yes") ?pickUpAddressFormData.addressLine2:"",
            apartmentNumber:(pickUpAddressFormData.isDormitory !== "Yes") ?pickUpAddressFormData.apartmentNumber:"",
            city:pickUpAddressFormData.city,
            state:pickUpAddressFormData.state.value,
            postalCode:pickUpAddressFormData.postalCode,
            country:"USA", 
        };

        const serverErrors={};
        const directUserToReviewScreen = this.getRedirectUserToReviewScreenFlag();
        if(directUserToReviewScreen){
            this.setState({ newUser,serverErrors,directUserToReviewScreen:!directUserToReviewScreen }); 
            this.props.history.push(`/signup/laundry/student/${REVIEW_SCREEN_STEP}`); 
        } 
        else{
            this.setState({newUser,serverErrors}); 
            this.props.history.push(`/signup/laundry/student/${step}`);  
        }
       
   }

   handleSaveLoginAndPaymentInformation = formData => {
    //console.log(formData);
    try{
        const {product,productType} = this.props.match.params;
        const newUser = {...this.state.newUser}
        const {billingAddress} = formData;
        billingAddress.state = billingAddress.state.value;
        billingAddress.country = billingAddress.country.value;
        
        let step = "review-screen";
        newUser.username = formData.username;
        newUser.password = formData.password;
        newUser.paymentInformation = {
            creditCardStripeToken:formData.stripeToken,
            creditCardName:formData.creditCardName,  
            creditCardNumber:formData.creditCardNumber,
            creditCardExpirationMonth:formData.creditCardExpirationMonth,
            creditCardExpirationYear:formData.creditCardExpirationYear,
            creditCardCCV:formData.creditCardCCV,
            billingAddress
        }
        
        const serverErrors={};
        
        const directUserToReviewScreen = this.getRedirectUserToReviewScreenFlag();
        if(directUserToReviewScreen){
            this.setState({ newUser,serverErrors,directUserToReviewScreen:!directUserToReviewScreen }); 
            this.props.history.push(`/signup/${product}/${productType}/${REVIEW_SCREEN_STEP}`); 
        } 
        else{
            this.setState({newUser,serverErrors}); 
            this.props.history.push(`/signup/${product}/${productType}/${step}`); 
        }
        

    }
    catch(ex){
        console.log("Error handleSaveLoginAndPaymentInformation",ex);
    }
    
 }

   /*
     product: product is  being passed from the url as we are using the same routine
              for step 1 storage and laundry 
   */
   handleSelectSchool = (s,product)=>{
    //console.log('Selected School',s,product); 
    const {productType} = this.props.match.params;

    const school = _.pick(s,['id','name','universityId','university','semesters']);
    const newUser = {...this.state.newUser};
    newUser.stripeMerchantAccountUniverityId = school.universityId;

    //Reset the student selections on switch of school
    if(newUser.laundry.type === "student"){
        newUser.laundry.student = {
            semester:"",    
            laundryType:"", 
            pricePlan:"",
            insurance:"",
        }
    }
    
    /*
    if(product === "laundry")
      newUser.step = "enter-student-information";
    if(product === "storage")
      newUser.step = "select-semester-pricing";
    */ 

   let step = "";
   if(product === "laundry"){
    step = "enter-student-information";
   }
    
   if(product === "storage")
    step = "select-semester-pricing";

    newUser.school = school; 
    const serverErrors={};
    this.setState({newUser,serverErrors});
    this.props.history.push(`/signup/${product}/${productType}/${step}`);  
    
    
  }

  handleStudentSaveLaundrySemesterAndPricingInformation = (formData)=>{
    //console.log(formData)
    const newUser = {...this.state.newUser}
    let step = "enter-login-payment-information";
    if(newUser.laundry.type === "student"){
        const {semester,laundryType,pricePlan,insurance} = formData;
        const discountProperties = {...newUser.laundry.discount}
        //Update the total
        const {laundry_tax:taxPercentage} =  newUser.school.university;
        /*
        totalCost:{
            subtotal:"",
            discountAmount:"",
            tax:"",
            finalTotal:"",
        }, 
        */
       const totalCost = this.calculateTotalCostLaundry(laundryType,pricePlan,insurance,taxPercentage,discountProperties);
         

        //End of updating total

        const studentLaundrySemesterAndPricing = {
            semester,
            laundryType, 
            pricePlan,
            insurance,

        }
        
        newUser.laundry[newUser.laundry.type] = studentLaundrySemesterAndPricing;
        newUser.laundry.totalCost = totalCost;
    }
    const serverErrors={};
    
    const directUserToReviewScreen = this.getRedirectUserToReviewScreenFlag();
    if(directUserToReviewScreen){
        this.setState({ newUser,serverErrors,directUserToReviewScreen:!directUserToReviewScreen }); 
        this.props.history.push(`/signup/laundry/student/${REVIEW_SCREEN_STEP}`); 
    } 
    else{
        this.setState({newUser,serverErrors}); 
        this.props.history.push(`/signup/laundry/student/${step}`);  
    }
     
  }

  handleStorageStudentBackOnStudentInformation = ()=>{
    //const newUser = {...this.state.newUser}
    //newUser.step = "select-semester-pricing";
    //this.setState({newUser});

    const {product,productType} = this.props.match.params;
    let step = "select-semester-pricing";
    this.props.history.push(`/signup/${product}/${productType}/${step}`); 
  }

  handleStorageStudentBackSemesterAndPricingInformation = ()=>{
    //const newUser = {...this.state.newUser}
    //newUser.step = "select-school";
    //this.setState({newUser});
    
    const {product,productType} = this.props.match.params;
    let step = "select-school";
    this.props.history.push(`/signup/${product}/${productType}/${step}`); 
  }

  handleStorageStudentBackOnReviewScreen = ()=>{
    //const newUser = {...this.state.newUser}
    //newUser.step = "enter-login-payment-information";
    //this.setState({newUser});

    const {product,productType} = this.props.match.params;
    let step = "enter-login-payment-information";
    this.props.history.push(`/signup/${product}/${productType}/${step}`); 
  }

  handleStorageStudentPurchaseForStorageRequest = async ()=>{
    const newUser = {...this.state.newUser};
    try{
        const newUser = {...this.state.newUser};
        const productType = newUser.storage.type;
        //console.log(productType);
        if(productType === "student"){
            const processingSteps  = {...newUser.processingSteps.storage[productType]};
            this.setState({serverErrors:""});
            this.setProcessingModalSignupDisplayFlag(true);
                
            //console.log(processingSteps);
            //console.log(newUser);
            
            //Build payload
            const payload = {...this.state.newUser};
            //console.log(payload);
            const response = await http.post(`${apiStorageEndpoint}/student/signup`,payload);
            const {status,message,errorType} = response.data;

            //console.log(response);
            //return;
            //console.log(response.headers['x-auth-token']);
            if(status === true){
                auth.loginWithJwt(response.headers['x-auth-token']);
                //this.props.history.push("/");
                
                processingSteps.paymentProcessedFlag = true;
                processingSteps.createAccountFlag = true;
                processingSteps.emailNotificationFlag = true;
                processingSteps.loggingYouInFlag = true;
                newUser.processingSteps.storage[productType] = processingSteps;
                this.setState({newUser})

                setTimeout(()=>{
                    window.location = "/myaccount/student/storage?welcome=true";  
                },2000);
            }
            else{
                this.setProcessingModalSignupDisplayFlag(false);
                //Handle errors from the server
                let [errors,errorMessage] = [{},""];
                if(errorType === "credit-card"){
                    errorMessage = <>
                       {`${message} Click `}<Link to="/signup/storage/student/enter-login-payment-information">here</Link>{` to re-enter payment informtion.`}
                    </>
                }
                else if(errorType === applicationConstantUtility.FINAL_PROCESSING_ERROR_TYPE_SIGNUP){
                    return this.props.history.replace("/signup/manual-processing/confirmation/storage")
                }
                else{
                    errorMessage = message; 
                } 
                
                errors = {message:errorMessage};
                this.setState({serverErrors: errors || {}});
                
            }   

            
        }  
    }
    catch(ex){
        this.setProcessingModalSignupDisplayFlag(false);
        /*
          400 signals validation errors from the server
          renderError() basically will display server side error other than code 400
        */
        if(ex.response && ex.response.status === 400){ 
            const errors = ex.response.data;
            this.setState({errors: errors || {}});
            if(errors){ scrollTop('sectionArea'); return;}
        }
        else
            renderError(ex);
    } 

  }

  
  handleStorageStudentSaveSemesterAndPricingInformation = (formData)=>{
    //console.log("Save from StorageStudentSaveSemesterAndPricingInformation");
    //console.log(formData);
    const {product,productType} = this.props.match.params;

    const newUser = {...this.state.newUser};
    const storageObject = {...newUser.storage};
    let step = "";
    if(newUser.storage.type === "student"){
        const {semester,pricingProperties} = formData;
        const discountProperties = {...newUser.storage.discount}
        const taxPercentage = pricingProperties.salesTax;
        const totalCost = this.calculateTotalCostStorage(pricingProperties,taxPercentage,discountProperties);
        
        const studentStorageSemesterAndPricing = {
            semester,
            pricingProperties,
        };

        newUser.storage[newUser.storage.type] =  studentStorageSemesterAndPricing;
        newUser.storage.totalCost = totalCost;
        step = "enter-student-information";
    
    }

    const serverErrors={};
    const directUserToReviewScreen = this.getRedirectUserToReviewScreenFlag();
    if(directUserToReviewScreen){
        this.setState({ newUser,serverErrors,directUserToReviewScreen:!directUserToReviewScreen }); 
        this.props.history.push(`/signup/${product}/${productType}/${REVIEW_SCREEN_STEP}`); 
    } 
    else{
        this.setState({newUser,serverErrors});
        this.props.history.push(`/signup/${product}/${productType}/${step}`);  
    }
  }

  handleStorageStudentSaveStudentInformation = (formData)=>{
    //console.log(formData);
    const {product,productType} = this.props.match.params;
    
    const {
        firstName,
        lastName,email,cellPhone,homePhone,studentId,
        pickUpAddress:pickUpAddressFormData,
        deliveryAddress:deliveryAddressFormData
    } = formData;

    const newUser = {...this.state.newUser};
    //newUser.step = "enter-login-payment-information";
    let step = "enter-login-payment-information";
    newUser.firstName = firstName;
    newUser.lastName = lastName;
    newUser.email = email;
    if(newUser.username === "") newUser.username = email;
    newUser.cellPhone = cellPhone;
    newUser.homePhone = homePhone;
    newUser.studentId = studentId;
    newUser.storage.pickUpAddress = {
        isDormitory: (pickUpAddressFormData.isDormitory === "Yes")? true:false,
        dormitory:{
            id:(pickUpAddressFormData.isDormitory === "Yes") ? pickUpAddressFormData.dormitoryName.value:"",
            name:(pickUpAddressFormData.isDormitory === "Yes") ? pickUpAddressFormData.dormitoryName.label:"",
            roomNumber:(pickUpAddressFormData.isDormitory === "Yes") ? pickUpAddressFormData.dormitoryRoomNumber:""
        },
        streetNumber:(pickUpAddressFormData.isDormitory !== "Yes") ? pickUpAddressFormData.streetNumber:"",
        addressLine1:(pickUpAddressFormData.isDormitory !== "Yes") ? pickUpAddressFormData.addressLine1:"",
        addressLine2:(pickUpAddressFormData.isDormitory !== "Yes") ?pickUpAddressFormData.addressLine2:"",
        apartmentNumber:(pickUpAddressFormData.isDormitory !== "Yes") ?pickUpAddressFormData.apartmentNumber:"",
        city:pickUpAddressFormData.city,
        state:(_.has(pickUpAddressFormData,['state','value'])) ? pickUpAddressFormData.state.value:"",
        postalCode:pickUpAddressFormData.postalCode,
        country:"USA", 

            
    };
    newUser.storage.deliveryAddress = {
        isSameAsPickupAddress:(deliveryAddressFormData.isSameAsPickupAddress === "Yes") ? true:false,
        isDormitory: (deliveryAddressFormData.isDormitory === "Yes") ? true:false,
        dormitory:{
            id:(deliveryAddressFormData.isDormitory === "Yes") ? deliveryAddressFormData.dormitoryName.value:"",
            name:(deliveryAddressFormData.isDormitory === "Yes") ? deliveryAddressFormData.dormitoryName.label:"",
            roomNumber:(deliveryAddressFormData.isDormitory === "Yes") ? deliveryAddressFormData.dormitoryRoomNumber:""
        },
        streetNumber:(deliveryAddressFormData.isDormitory !== "Yes") ? deliveryAddressFormData.streetNumber:"",
        addressLine1:(deliveryAddressFormData.isDormitory !== "Yes") ? deliveryAddressFormData.addressLine1:"",
        addressLine2:(deliveryAddressFormData.isDormitory !== "Yes") ? deliveryAddressFormData.addressLine2:"",
        apartmentNumber:(deliveryAddressFormData.isDormitory !== "Yes") ?deliveryAddressFormData.apartmentNumber:"",
        city:deliveryAddressFormData.city,
        state:(_.has(deliveryAddressFormData,['state','value'])) ? deliveryAddressFormData.state.value:"",
        postalCode:deliveryAddressFormData.postalCode,
        country:"USA", 
    };

    const serverErrors={};
    const directUserToReviewScreen = this.getRedirectUserToReviewScreenFlag();
    if(directUserToReviewScreen){
        this.setState({ newUser,serverErrors,directUserToReviewScreen:!directUserToReviewScreen }); 
        this.props.history.push(`/signup/${product}/${productType}/${REVIEW_SCREEN_STEP}`); 
    } 
    else{
        this.setState({newUser,serverErrors});
        this.props.history.push(`/signup/${product}/${productType}/${step}`);  
    }

  }

  handleSuppliesCustomerBackOnReviewScreen = ()=>{
        const {product,productType} = this.props.match.params;
        let step = "enter-login-payment-information";
        this.props.history.push(`/signup/${product}/${productType}/${step}`); 
  }

  handleSuppliesCustomerPurchaseForSuppliesRequest  = async ()=>{
    //console.log('Supplies review submit');
    try{
        const newUser = {...this.state.newUser};
        const {globalApp,clearShoppingCart} = this.props;
        const {shoppingCart}=  globalApp;
   
        const productType = newUser.supplies.type;
        //console.log(productType);
        
        const processingSteps  = {...newUser.processingSteps.supplies[productType]};
        this.setState({serverErrors:""});
        this.setProcessingModalSignupDisplayFlag(true);
            
        //console.log(processingSteps);
        //console.log(newUser);
        
        //Build payload
        const payload = {
            ...this.state.newUser,
            shoppingCart
        };

        //console.log(payload);
        const response = await http.post(`${apiSuppliesEndpoint}/customer/signup`,payload);
        const {status,message,errorType} = response.data;

        if(status === true){
            auth.loginWithJwt(response.headers['x-auth-token']);
            //this.props.history.push("/");
            
            processingSteps.paymentProcessedFlag = true;
            processingSteps.createAccountFlag = true;
            processingSteps.emailNotificationFlag = true;
            processingSteps.loggingYouInFlag = true;
            newUser.processingSteps.supplies[productType] = processingSteps;
            this.setState({newUser})
            
            clearShoppingCart();

            
            window.location = `/myaccount/${productType}/supplies?welcome=true`;  
            
        }
        else{
            this.setProcessingModalSignupDisplayFlag(false);
            //Handle errors from the server
            let [errors,errorMessage] = [{},""];
            if(errorType === "credit-card"){
                errorMessage = <>
                   {`${message} Click `}<Link to={`/signup/supplies/${productType}/enter-login-payment-information`}>here</Link>{` to re-enter payment informtion.`}
                </>
            }
            else if(errorType === applicationConstantUtility.FINAL_PROCESSING_ERROR_TYPE_SIGNUP){
                return this.props.history.replace("/signup/manual-processing/confirmation/supplies")
            }
            else{
                errorMessage = message; 
            } 
            
            errors = {message:errorMessage};
            this.setState({serverErrors: errors || {}});
            
        }   
        

    }
    catch(ex){
        this.setProcessingModalSignupDisplayFlag(false);
        /*
          400 signals validation errors from the server
          renderError() basically will display server side error other than code 400
        */
        if(ex.response && ex.response.status === 400){ 
            const errors = ex.response.data;
            this.setState({errors: errors || {}});
            if(errors){ scrollTop('sectionArea'); return;}
        }
        else
            renderError(ex);
    } 

  }

  handleSuppliesSaveCustomerInformation = (formData)=>{
    //console.log(formData);
    let {product,productType} = this.props.match.params;
    
    
    const {
        firstName,
        lastName,email,cellPhone,homePhone,
        deliveryAddress:deliveryAddressFormData,
        studentId,
        isStudent,
        school,
    } = formData;

    const newUser = {...this.state.newUser};
    let step = "enter-login-payment-information";
    newUser.firstName = firstName;
    newUser.lastName = lastName;
    newUser.email = email;
    if(newUser.username === "") newUser.username = email;
    newUser.cellPhone = cellPhone;
    newUser.homePhone = homePhone;
    newUser.studentId = studentId;

    newUser.supplies.type = (isStudent) ? "student":"residential";

    if(productType === undefined){
        productType = (isStudent) ? "student":"residential"
    }

    const currentSignUpProcessingComponent = {
        ...this.state.currentSignUpProcessingComponent,
        productType,
    };


    if(isStudent){
        const schoolObject = {
            id:school.value,
            name:school.label
        }
        newUser.school = schoolObject;
    }
    else
        newUser.school = "";
    
    newUser.supplies.deliveryAddress = {
        isDormitory: (deliveryAddressFormData.isDormitory === "Yes") ? true:false,
        dormitory:{
            id:(deliveryAddressFormData.isDormitory === "Yes") ? deliveryAddressFormData.dormitoryName.value:"",
            name:(deliveryAddressFormData.isDormitory === "Yes") ? deliveryAddressFormData.dormitoryName.label:"",
            roomNumber:(deliveryAddressFormData.isDormitory === "Yes") ? deliveryAddressFormData.dormitoryRoomNumber:""
        },
        streetNumber:(deliveryAddressFormData.isDormitory !== "Yes") ? deliveryAddressFormData.streetNumber:"",
        addressLine1:(deliveryAddressFormData.isDormitory !== "Yes") ? deliveryAddressFormData.addressLine1:"",
        addressLine2:(deliveryAddressFormData.isDormitory !== "Yes") ? deliveryAddressFormData.addressLine2:"",
        apartmentNumber:(deliveryAddressFormData.isDormitory !== "Yes") ?deliveryAddressFormData.apartmentNumber:"",
        city:deliveryAddressFormData.city,
        state:(_.has(deliveryAddressFormData,['state','value'])) ? deliveryAddressFormData.state.value:"",
        postalCode:deliveryAddressFormData.postalCode,
        country:"USA", 
    };

    //console.log(currentSignUpProcessingComponent);
    //console.log(newUser);
    
    
    const serverErrors={};
    const directUserToReviewScreen = this.getRedirectUserToReviewScreenFlag();
    if(directUserToReviewScreen){
        this.setState({ newUser,serverErrors,directUserToReviewScreen:!directUserToReviewScreen }); 
        this.props.history.push(`/signup/${product}/${productType}/${REVIEW_SCREEN_STEP}`); 
    } 
    else{
        this.setState({newUser,serverErrors}); 
        this.props.history.push(`/signup/${product}/${productType}/${step}`);
    }
  }

  setDirectUserToReviewScreen = flag=>{
    this.setState({directUserToReviewScreen:flag});
  }

   /*
     This is called if primary property changes such as product and product type from the URL 
     product: laundry, storage
     productType: student, residential, commercial
   */
    setNewUserProperties = async ()=>{
        try{
            //console.log(this.props.match.params);
            const {globalApp} = this.props;
            const {product,productType,step} = this.props.match.params;
            let statusChanged = false;
            
            let {
                listOfSchoolsByRegionForLaundry,
                listOfResidentialCitiesByStateForLaundry,
                listOfSchoolsByRegionForStorage,} = this.state;

            if(!listOfSchoolsByRegionForLaundry){
                listOfSchoolsByRegionForLaundry = await SchoolService.getListOfSchoolsByRegionForLaundry();
            }
            if(!listOfResidentialCitiesByStateForLaundry){
                listOfResidentialCitiesByStateForLaundry = await ResidentialService.getListOfResidentialCitiesByState();
            }
            if(!listOfSchoolsByRegionForStorage){
                listOfSchoolsByRegionForStorage = await SchoolService.getListOfSchoolsByRegionForStorage();
            }
            
            //console.log(listOfSchoolsByRegionForLaundry);
                
            const currentSignUpProcessingComponent = {...this.state.currentSignUpProcessingComponent};
            const newUser = {...this.state.newUser};
            const selectedProducts = [...newUser.selectedProducts];
            
            if(currentSignUpProcessingComponent.product !== product){
                statusChanged = true; 
            }
            else if(currentSignUpProcessingComponent.product === product && 
                currentSignUpProcessingComponent.productType !== productType
            ){
                statusChanged = true; 
            }
            
            /*
            if(newUser.selectedProducts.length === 0){
                //If selected products basket is empty 
                statusChanged = true;     
            }
            else if(selectedProducts.indexOf(product) === -1){
                //if the product is not in the selected product
                statusChanged = true;     
            }
            else if(product === "laundry" && selectedProducts.indexOf(product) !== -1 && newUser[product].type !== productType){
                //if product selected is laundry but the product type changed. Ex: user switched from student to laundry
                statusChanged = true;     
            }
            else if(product === "storage" && selectedProducts.indexOf(product) !== -1  && newUser[product].type !== productType){
                statusChanged = true;         
            }
            */
            //console.log(newUser);
            if(statusChanged){
                if(product === "laundry" && listOfSchoolsByRegionForLaundry && listOfResidentialCitiesByStateForLaundry){
                    //Initial the signup step
                    if(productType === "student")
                    newUser.step = "select-school";

                    if(productType === "residential")
                    newUser.step = "select-city";
                    //end of Initial the signup step
        
                    //Setting laundry, supplies or storage for product
                    if(selectedProducts.indexOf(product) === -1){
                        console.log('pushing product');
                        selectedProducts.push(product); 
                    }
                    
                    //Setting product type under selected product
                    const productObject = {...newUser[product]};
                    productObject.type = productType;
                    
                    newUser.selectedProducts = selectedProducts;
                    newUser[product] = productObject;
                    
                }
                else if(product === "storage" && listOfSchoolsByRegionForStorage){
                    newUser.step = (productType === "student") && "select-school";
                    
                    //Setting laundry, supplies or storage for product
                    if(selectedProducts.indexOf(product) === -1){
                        console.log('pushing product');
                        selectedProducts.push(product); 
                    }

                    //Setting product type under selected product
                    const productObject = {...newUser[product]};
                    productObject.type = productType;

                    newUser.selectedProducts = selectedProducts;
                    newUser[product] = productObject;
                    
                }
                else if(product === "supplies"){
                    newUser.step = "enter-customer-information";

                    const {shoppingCart} = globalApp;
                    if(shoppingCart.universityId) 
                        newUser.stripeMerchantAccountUniverityId = shoppingCart.universityId; 

                    //Setting laundry, supplies or storage for product
                    if(selectedProducts.indexOf(product) === -1){
                        console.log('pushing product');
                        selectedProducts.push(product); 
                    }

                    //Setting product type under selected product
                    const productObject = {...newUser[product]};
                    
                    /* We by default treat as residential and if the zipcode entered on the supplies screen 
                    will indicate schools in the neighborhood
                    */
                    productObject.type = (productType !== undefined) ? productType:"residential"; 
                    
                    newUser.selectedProducts = selectedProducts;
                    newUser[product] = productObject;
                }
                
                currentSignUpProcessingComponent.product = product;
                currentSignUpProcessingComponent.productType = productType;
                
                console.log('Updating state');
                this.setState({
                    listOfSchoolsByRegionForLaundry,
                    listOfSchoolsByRegionForStorage,
                    listOfResidentialCitiesByStateForLaundry,
                    currentSignUpProcessingComponent,
                    newUser
                });
            }
            else{
                console.log('product/product type didnt change');
                if(step && step != newUser.step){
                    console.log('Step changed');
                    newUser.step = step; 
                    
                    //const billingAddress = {...newUser.paymentInformation.billingAddress};
                    //if(typeof billingAddress.state === "object" && typeof billingAddress.country === "object"){
                        //billingAddress.state = billingAddress.state.value
                        //billingAddress.country = billingAddress.country.value
                        //newUser.paymentInformation.billingAddress = billingAddress;
                    //}
                    
                    this.setState({
                        newUser
                    });
                    
                }
            }
        }
        catch(ex){
            console.log(ex);
        }
        

   }


   setProcessingModalSignupDisplayFlag = (flag)=>{
        this.setState({
            processingModalSignupDisplayFlag:flag
        });
   }

   render(){
        //console.log('signup render');
        const props = this.props;
        //console.log(this.state);
        const {product,productType,step} = this.props.match.params;
        const user = this.state.newUser;
        const stripeMerchantAccountUniverityId = user.stripeMerchantAccountUniverityId;
        
        //console.log(user);
        //const listOfSchoolsByRegionForLaundry = SchoolService.getListOfSchoolsByRegionForLaundry();
        const {
            processingModalDisplayFlag,
            processingModalSignupDisplayFlag,
            listOfSchoolsByRegionForLaundry,
            listOfSchoolsByRegionForStorage,
            listOfResidentialCitiesByStateForLaundry,
            serverErrors,} = this.state;

        //const listOfSchoolsByRegionForStorage = SchoolService.getListOfSchoolsByRegionForStorage();
        //const listOfResidentialCitiesByState = ResidentialService.getListOfResidentialCitiesByState();
        
        //this.setNewUserProperties();

        return(
          <StripeProvider key={stripeMerchantAccountUniverityId} apiKey={process.env[`REACT_APP_STRIPE_PRODUCTION_KEY_UNIVERSITY_ID_${stripeMerchantAccountUniverityId}`]}>  
            <React.Fragment>
            
            {product === "laundry" &&
                (user.selectedProducts.indexOf("laundry") !== -1) && 
                user.laundry.type === "student" &&  
                listOfSchoolsByRegionForLaundry && 
                <SignupLaundryStudent 
                onClickOfSchool={this.handleSelectSchool}
                onClickOfBackButtonFromStudentInformation = {this.handleStudentBackOnStudentInformation}
                onClickOfNextButtonFromStudentInformation = {this.handleSaveStudentInformation}
                onClickOfBackButtonFromSemesterAndPricingInformation = {this.handleStudentBackOnLaundrySemesterAndPricingInformation}
                onClickOfNextButtonFromSemesterAndPricingInformation = {this.handleStudentSaveLaundrySemesterAndPricingInformation}
                onClickOfBackButtonFromLoginAndPaymentInformation = {this.handleBackOnLoginAndPaymentInformation}
                onClickOfNextButtonFromLoginAndPaymentInformation = {this.handleSaveLoginAndPaymentInformation}
                onClickOfBackButtonFromReviewScreen = {this.handleStudentBackOnReviewScreen}
                onClickOfPurchaseButtonFromReviewScreen = {this.handleStudentPurchaseForLaundryRequest}
                onClickOfApplyCouponButtonFromReviewScreen = {this.handleCouponCodeProcessLaundryStudent}
                launchProcessingModal = {this.handleCloseOfProcessingModalWindow}
                user={user}
                listOfSchoolsByRegion={listOfSchoolsByRegionForLaundry}
                product={product}
                handleRedirectToReviewScreen={this.setDirectUserToReviewScreen}
                serverErrors={serverErrors}
                {...props}
                />  
            }
            {product === "laundry" &&
                (user.selectedProducts.indexOf("laundry") !== -1) && 
                user.laundry.type === "residential" && 
                listOfResidentialCitiesByStateForLaundry &&  
                <SignupLaundryResidential 
                    user={user}
                    listOfResidentialCitiesByState ={listOfResidentialCitiesByStateForLaundry}
                    launchProcessingModal = {this.handleCloseOfProcessingModalWindow}
                    onClickOfCity={this.handleResidentialSelectCity}
                    onClickOfBackButtonFromPricingAndInsuranceInformation = {this.handleResidentialBackOnLaundryPricingAndInsuranceInformation}
                    onClickOfNextButtonFromPricingAndInsuranceInformation = {this.handleResidentialSaveLaundryPricingAndInsuranceInformation}
                    onClickOfBackButtonFromCustomerInformation = {this.handleResidentialBackOnCustomerInformation}
                    onClickOfNextButtonFromCustomerInformation = {this.handleResidentialSaveCustomerInformation}
                    onClickOfBackButtonFromLoginAndPaymentInformation = {this.handleBackOnLoginAndPaymentInformation}
                    onClickOfNextButtonFromLoginAndPaymentInformation = {this.handleSaveLoginAndPaymentInformation}
                    onClickOfBackButtonFromReviewScreen = {this.handleResidentialBackOnReviewScreen}
                    onClickOfPurchaseButtonFromReviewScreen = {this.handleResidentialPurchaseForLaundryRequest}
                    onClickOfApplyCouponButtonFromReviewScreen = {this.handleCouponCodeProcessLaundryResidential}
                    handleRedirectToReviewScreen={this.setDirectUserToReviewScreen}
                    serverErrors={serverErrors}
                    {...props}  
                />  
            } 
            {   product === "storage" && 
                listOfSchoolsByRegionForLaundry && 
                (user.selectedProducts.indexOf(product) !== -1) &&
                <SignupStorageStudent
                        user={user}
                        listOfSchoolsByRegion={listOfSchoolsByRegionForStorage}
                        onClickOfSchool={this.handleSelectSchool}
                        product={product}
                        onClickOfBackButtonFromSemesterAndPricingInformation = {this.handleStorageStudentBackSemesterAndPricingInformation}
                        onClickOfNextButtonFromSemesterAndPricingInformation = {this.handleStorageStudentSaveSemesterAndPricingInformation}
                        onClickOfBackButtonFromStudentInformation = {this.handleStorageStudentBackOnStudentInformation}
                        onClickOfNextButtonFromStudentInformation =  {this.handleStorageStudentSaveStudentInformation}
                        onClickOfBackButtonFromLoginAndPaymentInformation = {this.handleBackOnLoginAndPaymentInformation}
                        onClickOfNextButtonFromLoginAndPaymentInformation = {this.handleSaveLoginAndPaymentInformation}
                        onClickOfBackButtonFromReviewScreen = {this.handleStorageStudentBackOnReviewScreen}
                        onClickOfPurchaseButtonFromReviewScreen = {this.handleStorageStudentPurchaseForStorageRequest}
                        onClickOfApplyCouponButtonFromReviewScreen = {this.handleCouponCodeProcessStorageStudent}
                        handleRedirectToReviewScreen={this.setDirectUserToReviewScreen}
                        serverErrors={serverErrors}
                        {...props}
                />

            }

            {   product === "supplies" && 
                (user.selectedProducts.indexOf(product) !== -1) &&
                <SignupSupplies
                        user={user}
                        product={product}
                        onClickOfNextButtonFromCustomerInformation = {this.handleSuppliesSaveCustomerInformation}
                        onClickOfBackButtonFromLoginAndPaymentInformation = {this.handleBackOnLoginAndPaymentInformation}
                        onClickOfNextButtonFromLoginAndPaymentInformation = {this.handleSaveLoginAndPaymentInformation}
                        onClickOfBackButtonFromReviewScreen = {this.handleSuppliesCustomerBackOnReviewScreen}
                        onClickOfPurchaseButtonFromReviewScreen = {this.handleSuppliesCustomerPurchaseForSuppliesRequest}
                        handleRedirectToReviewScreen={this.setDirectUserToReviewScreen}
                        serverErrors={serverErrors}
                        {...props}
                />

            }

                {/* Set Processing modal */}
                <ProcessingModal
                    displayModal={processingModalDisplayFlag}
                    handleCloseOfModalWindow={this.handleCloseOfProcessingModalWindow}
                />
                {/* End of Processing modal */}
                
                {/* Set Signup Review screen Processing modal */}
                {   
                    <SignupProcessingModal 
                        displayModal={processingModalSignupDisplayFlag}
                        handleCloseOfModalWindow={this.setProcessingModalSignupDisplayFlag}
                        user={user}
                        {...this.state}
                        {...this.props}
                    />
                }
                {/* End of Set Signup Review screen Processing modal */}

            </React.Fragment>
          </StripeProvider>
        );
   }
}

export default SignUp