import React from "react";

import {
    Media,
    
} from "reactstrap";


const studentData = [
    {
        heading:"How do I properly pack my TV?",
        body:<p>
        If possible, use the original TV box and Styrofoam to pack your television. If you do not have the original packaging, you will need to purchase a TV box. Wrap your television at least three times in protective bubble wrap and place it in the box. You should fill any empty space inside the box, to avoid movement during the storage process.
        </p>
    },
    {
        heading:"Where can I purchase a Television Box?",
        body:<p>
        Some Lazybones locations sell and deliver packing supplies including TV boxes. Please check our website to see if you are on a Lazybones campus that delivers packing supplies. Other places you can purchase television boxes include, The Home Depot, Amazon, and Walmart.
        </p>,
    },
    {
        heading:"What if my Television is not properly packed in a television box at the time of my storage pickup?",
        body:<p>
            If your television is not safely packed inside of a TV box at the time of your storage pickup, Lazybones will automatically pack it for you, and a $75 packing fee will be added to your storage invoice. Please note, televisions are the only item that Lazybones will pack & protect if not already done so by the customer.
        </p>,
    },
    {
        heading:"Besides my TV, do any other storage items need to be packed inside of a box?",
        body:<p>
            Yes, you should make sure that all your storage items are packed inside a proper storage/packing container. Boxes, plastic bins/drawers, duffle bags, trunks and suitcases are the only packing containers Lazybones considers to be proper and secure storage containers. 
        </p>,
    },
    {
        heading:"What if I have items that are not packed inside of a proper packing container?",
        body:<p>
            We will still store these 'loose' items for you; however, no insurance can be purchased, and no compensation will be given in the rare event of damage. 
        </p>,
    },
    {
        heading:"Can I pack items in my dresser or desk drawers?",
        body:<p>
            No, you should never pack items in your dresser or desk drawers. The weight will likely cause the drawers to break.
        </p>,
    },{
        heading:"Where can I get storage supplies to pack all my storage items?",
        body:<p>
            Some Lazybones locations sell and deliver packing supplies including TV boxes. Please check our website to see if you live in an area where we sell and deliver supplies. Other places you can purchase packing supplies include The Home Depot, Amazon, Walmart and Syracuse University Campus Store.
        </p>,
    },{
        heading:"When should I purchase my packing supplies from Lazybones?",
        body:<p>
            You should purchase your packing supplies at least 7 days before your scheduled storage pickup. Please remember, not all Lazybones locations sell storage supplies, so check our website to be sure that you live in a location that does.
        </p>,
    },{
        heading:"How do I order packing supplies from Lazybones?",
        body:<p>
            Purchasing supplies from Lazybones is so easy! Just go to our website, click on "Supplies" at the top of the page. Please remember, not all Lazybones locations sell storage supplies, so check our website to be sure that you live in a location that does. 
        </p>,
    },{
        heading:"Can I pack the food items that I didn't use during the semester?",
        body:<p>
           No, never pack any food items with belongings to be stored. Edible items of any kind, including gum and candy, attract rodents. Our storage facility is clean and rodent free, and we'd like to keep it that way. There is a $100 fine for packing food items of any kind.
        </p>,
    },{
        heading:"Can I pack liquids, like my shampoo?",
        body:<p>
            No, packing liquids of any kind with your belongings is a huge risk, and Lazybones will not reimburse for damage caused by spillage.
        </p>,
    },
    {
        heading:"Will Lazybones disassemble my furniture?",
        body:<p>
            The only items Lazybones will disassemble are bed frames that do not require any tools and have no loose screws. All other storage items must be disassembled by the customer.
        </p>,
    },
    {
        heading:"Does Lazybones automatically insure my belongings?",
        body:<p>
            Yes, Lazybones automatically insures the contents inside properly packed and protected storage/packing containers for $50, at no additional cost to the customer. The packing container itself cannot be insured.
        </p>,
    },
    {
        heading:"What if the contents inside my packing container are worth more than $50?",
        body:<p>
            You can opt to purchase additional insurance (above the $50, and up to $1,000) for the contents within any properly protected storage/packing container. The cost for additional insurance is $1 for every additional $100 of insurance ie: $1000 of insurance costs $10. You can purchase additional insurance, up to 14 days after you receive your FINAL RECEIPT, by calling or emailing Lazybones Customer Service.
        </p>,
    },
    {
        heading:"What does storage insurance cover?",
        body:<p>
            Storage insurance covers the collective contents within (inside) a properly packed and protected storage container, not the storage/packing container itself. Storage/packing containers (any kind) are not insurable, and no compensation is given for damage to these items. Lazybones only considers the following objects to be acceptable storage/packing containers: boxes, duffle bags, trunks, suitcases, and plastic bins/drawers.
        </p>,
    },
    {
        heading:"Besides storage/packing containers, what other items are not insurable?",
        body:<p>
            Other items that are not insurable are loose items (not packed inside of an acceptable storage/packing container) and particle board furniture. We are happy to store these items for you, however no compensation will be given in the rare event of damage. 
        </p>,
    },
    {
        heading:"What should I do if one of my storage containers is lost/damaged?",
        body:<p>
            In the rare event of damage/loss of a properly packed/protected, and insured storage container, the customer must contact Lazybones within 48 hours of their storage delivery date, and all packaging materials (i.e., packing container, bubble wrap, stretch wrap etc.) must be kept and inspected before compensation for damaged items can be made. Please remember, compensation is only made for the insured value of damaged/lost container(s), and the costs for storage services are non-refundable.
        </p>,
    },
    
    
    
];

const Packing = ()=>{
    const data = studentData;
    let ctr = 1; 
    return(
        <>
            {
                data.map(({heading,body})=>
                    <Media key={ctr++}>
                        <Media body>
                        <Media heading>{heading}</Media>
                        {body}
                        </Media>
                    </Media>
                )
            }
        </>
    );
}

export default Packing;
