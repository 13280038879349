import React from "react";

import {
    Media,

} from "reactstrap";

const studentData = [
    {
        heading:"Do you offer dry cleaning services?",
        body:<p>
        Yes! We offer dry cleaning services at competitive rates. 
        </p>
    },
    {
        heading:"How do I request an item be dry cleaned?",
        body:<p>
        Place the item(s) to be dry cleaned into a separate bag along with a note that indicates your request for dry cleaning services. Then just place this bag inside your larger laundry bag.
        </p>,
    },
    {
        heading:"Why did you dry clean items I gave you to launder?",
        body:<p>
        Our personnel are trained to watch out for items of clothing that might be damaged by regular laundering. If they find an item whose care instructions say 'dry clean only' or 'hand wash' those items will be sent out to be dry cleaned and your credit card will be charged for that service. If you want to avoid this, you can either tell us NOT to dry clean a specific item by pinning a note on it, or email or call us telling us to NEVER dry clean ANY of your items.
        </p>,
    },
    {
        heading:"Can I use laundry pounds to pay for my dry cleaning? ",
        body:<p>
        No. Dry cleaning is a separate service from laundry and must be paid for via the credit card we have on file with you. Our coupon codes never apply to dry cleaning services.
        </p>,
    },
    {
        heading:"How does pricing work for dry cleaned items?",
        body:<p>
        We charge a per item price for dry cleaning services and these prices are separate from our laundry prices. 
        </p>,
    },
    {
        heading:"How long does it take to get my dry cleaning back? ",
        body:<p>
        Dry cleaned items generally take 3-5 business days longer than the laundry delivery to be returned to you. Your clothing is returned on hangers and covered in plastic.
        </p>,
    },
];

const residentialData = [
    {
        heading:"Do you offer dry cleaning services?",
        body:<p>
        Yes! We offer dry cleaning services at competitive rates. 
        </p>
    },
    {
        heading:"How do I request an item be dry cleaned?",
        body:<p>
        Place the item(s) to be dry cleaned into a separate bag along with a note that indicates your request for dry cleaning services. Then just place this bag inside your larger laundry bag.
        </p>,
    },
    {
        heading:"Why did you dry clean items I gave you to launder?",
        body:<p>
        Our personnel are trained to watch out for items of clothing that might be damaged by regular laundering. If they find an item whose care instructions say 'dry clean only' or 'hand wash' those items will be sent out to be dry cleaned and your credit card will be charged for that service. If you want to avoid this, you can either tell us NOT to dry clean a specific item by pinning a note on it, or email or call us telling us to NEVER dry clean ANY of your items.
        </p>,
    },
    {
        heading:"How does pricing work for dry cleaned items?",
        body:<p>
        We charge a per item price for dry cleaning services and these prices are separate from our laundry prices. 
        </p>,
    },
    {
        heading:"How long does it take to get my dry cleaning back? ",
        body:<p>
        Dry cleaned items generally take 3-5 business days longer than the laundry delivery to be returned to you. Your clothing is returned on hangers and covered in plastic.
        </p>,
    },
    {
        heading:"Is residential laundry done all year long? ",
        body:<p>
            Yes, in most areas. Please call the office for additional information. 
        </p>,
    },
    
];

const DryCleaning = (props)=>{
    const {isStudent,isResidential} = props;
    const data = (isStudent === true) ? studentData:residentialData;
    
    let ctr = 1;
    
    return(
    <>
        {
            data.map(({heading,body})=>
                <Media key={ctr++}>
                    <Media body>
                    <Media heading>{heading}</Media>
                    {body}
                    </Media>
                </Media>
            )
        }
    </>
    );

    
}

export default DryCleaning;
