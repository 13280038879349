import React from "react";

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Media,
    NavItem,
    NavLink,
    Nav,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    TabContent,
    TabPane,
    Container,
    Row,
    Col
  } from "reactstrap";

  const Pricing = ()=>{
      return(
        <>
          <p>Here are your Pricing message.</p>
        </>
      );
  }

  export default Pricing;
