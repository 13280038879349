import React, {Component} from "react";
import _ from "lodash";
import Joi from "@hapi/joi"
import http from "services/httpService";
import Select from "react-select";
import {getUSStateList} from "services/dropDownListsService";
import {getCountryList} from "services/dropDownListsService";



import {CardElement,
        CardNumberElement,
        CardExpiryElement,
        CardCVCElement,
        injectStripe} from 'react-stripe-elements';

import UserService from "../../../../services/userService";

import FormErrorWidget from "components/Common/FormErrorWidget";
import CreditCardHelper from "../../../../helpers/CreditCardHelper";
import CardSection from './CardSection';



// reactstrap components
import {
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    InputGroupAddon,
    InputGroupText,
    InputGroup,
  } from "reactstrap";

import StudentLaundryRegistrationStepsDisplayWidget from "./StudentLaundryRegistrationStepsDisplayWidget";
import ResidentialLaundryRegistrationStepsDisplayWidget from "./ResidentialLaundryRegistrationStepsDisplayWidget";


const scrollTop = ()=>{
    document.getElementById("sectionArea").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
    });
}

const cardExpirationDate = (value, helpers) => {

    // Throw an error (will be replaced with 'any.custom' error)
    //console.log(helpers.schema);
    //console.log(helpers.state);
    //console.log(value);
    
    //console.log(this.state.data.creditCardExpirationMonth);

    if (value === '2019') {
        throw new Error('nope');
    }

    // Replace value with a new value
    if (value === '2') {
        return '3';
    }

    // Use error to return an existing error code
    if (value === '4') {
        return helpers.error('any.invalid');
    }

    // Override value with undefined to unset
    if (value === '5') {
        return undefined;
    }

    // Return the value unchanged
    return value;
};

const getDaysInMonth  = (month, year)=>{ // Use 1 for January, 2 for February, etc.
    return new Date(year, month, 0).getDate();
}

const stripeInputFieldStyling = {
    style: {
      base: {
        fontWeight:'100',
        fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',  
        '::placeholder': {
            color: '#999',
            fontWeight:'100',
        }
      }
    },
};

class LoginAndPaymentInformation extends Component{
    validationSchema = Joi.object({
        username: Joi.string().min(8).required().label('User ID'),
        password:Joi.string().min(8).required().pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^ ]{8,}$/).label('Password'),
        confirmPassword:Joi.string().required().valid(Joi.ref('password')).label('Confirm Password'),
        creditCardName:Joi.string().required().label('Credit Card Holders Name'),
        creditCardNumber:Joi.any().allow(""),
        creditCardExpirationMonth:Joi.any().optional(),
        creditCardExpirationMonthDropDownDisplay:Joi.any().optional(),
        /*
        creditCardExpirationYear: Joi.string().when('creditCardExpirationMonth', {
            is: Joi.string().required(),
            then: Joi.custom(cardExpirationDate.bind(this),"Custom check"),
        }),*/
        creditCardExpirationYear:Joi.any().optional(),
        creditCardExpirationYearDropDownDisplay:Joi.any().optional(),
        creditCardCCV:Joi.any().allow(""),
        
    });

    billingAddressSchema = Joi.object({
        addressLine1: Joi.string().required().label('Billing Address Line 1'),
        addressLine2: Joi.any().optional(),
        city:Joi.string().required().label('City'),
        state:Joi.object().keys({
            value:Joi.string().required(), 
            label:Joi.any().optional(),
        }),
        postalCode:Joi.string().required().label('Postal Code'),
        country:Joi.object().keys({
            value:Joi.string().required(), 
            label:Joi.any().optional(),
        }), 
    }) ;

    /*Validation custom messages to map with Job error object */
    errorMessages = {
        "password":{
            "string.pattern.base":"New Password must contain at least one uppercase letter, one lowercase letter and one number",
        },
        "confirmPassword":{
            "any.only":"Confirm Password should match the Password"
        },
        "state":{
            "object.base":"Billing State is not allowed to be empty",
        },
        "country":{
            "object.base":"Billing Country is not allowed to be empty",
        },
    }

    constructor(){
        super();
        this.state = {
            stateList:[{ value: "", label: "", isDisabled: true }],
            countryList:[{ value: "", label: "", isDisabled: true }],
            buttonLoader:false,
            data:{
                username:"",
                password:"",    
                confirmPassword:"", 
                creditCardName:"",
                creditCardNumber:"",
                creditCardExpirationMonth:"",
                creditCardExpirationMonthDropDownDisplay:"",
                creditCardExpirationYear:"",
                creditCardExpirationYearDropDownDisplay:"",
                creditCardCCV:"",
                billingAddress:{
                    addressLine1:"",
                    addressLine2:"",
                    city:"",
                    state:"",
                    postalCode:"",
                    country:{label:"United States of America", value:"US"},
                }
                
            },
            errors:{}
        }

    }

    componentDidMount(){
        this.mapModelToView();
        document.getElementById("sectionArea").scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });

        
        
    }

    handleChange = ({currentTarget:input})=>{
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[input.name] = input.value;
        this.setState({data,errors});
        
    }

    handleChangeAddress = (e,addressType)=>{
        const {name,value} = e.currentTarget;
        //console.log(`${name} : ${value}`);
        const data = {...this.state.data};
        
        const address = {...this.state.data[addressType]}
        address[name] = value;
        data[addressType] = address
        
        this.setState({data});
    }

    handleSelectDropDown = (selectedValueObject,name,dropDownDisplayInput)=>{
        const value = selectedValueObject.value;
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[name] = value;
        data[dropDownDisplayInput] = selectedValueObject;
        this.setState({data,errors});
    }

    handleSubmit = async (e)=>{
        e.preventDefault();
        try{
            this.setButtonLoader(true);
            const {onClickOfNextButtonFromLoginAndPaymentInformation} = this.props;
            const loginAndPaymentFormData = {...this.state.data};
            const  {addressLine1:address_line1,
                    addressLine2:address_line2,
                    city:address_city,
                    state,
                    postalCode:address_zip,
                    country} = loginAndPaymentFormData.billingAddress;
            
            let errors = await this.validate();
            console.log(errors);
            
            
            let stripeResult = "";
            if (this.props.stripe) {
                stripeResult = await this.props.stripe.createToken(
                    {
                        type: 'card', 
                        name: loginAndPaymentFormData.creditCardName,
                        address_line1,
                        address_line2,
                        address_city,
                        address_state:state.value,
                        address_zip,
                        address_country:country.value,
                    }
                );
                //console.log(stripeResult);
                if(stripeResult.error){
                    errors = (!_.isEmpty(errors)) ? errors : {};
                    errors["invalidCard"] = stripeResult.error.message;
                }
                else
                    loginAndPaymentFormData.stripeToken = stripeResult.token
            } 
            this.setButtonLoader(false);
            this.setState({errors: errors || {}});
            if(errors){
                scrollTop()
                return;
            } 
            onClickOfNextButtonFromLoginAndPaymentInformation(loginAndPaymentFormData);
            return;
        }
        catch(ex){
            this.setButtonLoader(false);
            console.log(ex);
        }
       
    }

    mapModelToView = ()=>{
        const monthList = CreditCardHelper.getMonthList();
        const yearList = CreditCardHelper.getYearList();
        const stateList = getUSStateList();
        const countryList = getCountryList();
          
        const {user} = this.props;
        const { creditCardName,  
                creditCardNumber,
                creditCardExpirationMonth,
                creditCardExpirationYear,
                creditCardCCV,
                billingAddress
        } = user.paymentInformation;
        
        const {username,password} = user;
        const confirmPassword  = password;
        
        let creditCardExpirationMonthDropDownDisplay  = "";
        let index = null;
        if(creditCardExpirationMonth){
            index = _.findIndex(monthList, ['value', creditCardExpirationMonth]);
            creditCardExpirationMonthDropDownDisplay = monthList[index];
        }
        
        let creditCardExpirationYearDropDownDisplay  = "";
        if(creditCardExpirationYear){
            index = _.findIndex(yearList,['value',creditCardExpirationYear]);
            creditCardExpirationYearDropDownDisplay = yearList[index];
        }

        const billingAddressObject = {...billingAddress};

        if(billingAddressObject.state){
            billingAddressObject.state = _.find(stateList,{'value':billingAddressObject.state});
        }

        if(billingAddressObject.country){
            billingAddressObject.country = _.find(countryList,{'value':billingAddressObject.country});
        }
        
        const data = {
            username,
            password,
            confirmPassword,
            creditCardName,
            creditCardNumber,
            creditCardExpirationMonth,
            creditCardExpirationMonthDropDownDisplay,
            creditCardExpirationYear,
            creditCardExpirationYearDropDownDisplay,
            creditCardCCV,
            billingAddress:billingAddressObject,
        }

        this.setState(
            {
                stateList,
                countryList,
                data,
            }
        );
    }

    setAddressState = (value,addressType)=>{
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        address.state = value;
        data[addressType] = address;

        this.setState({data});

    }

    setButtonLoader = flag=>this.setState({buttonLoader:flag})

    setCountry = (value)=>{
        const addressType = "billingAddress";
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        address.country = value;
        data[addressType] = address;

        this.setState({data});

    }

    validate=  async ()=>{
        try{
            const options = {abortEarly:false,allowUnknown:true};
            //console.log(this.state.data);
            const {creditCardExpirationMonth,creditCardExpirationYear} = this.state.data;
            let {error:formErrorList} = this.validationSchema.validate(this.state.data,options);
            let {error:billingAddressErrorList} = this.billingAddressSchema.validate(this.state.data.billingAddress,options);
            if(formErrorList === undefined){
                const {username} = this.state.data;
                const {status:processingStatus,message}  = await UserService.validateByUsername({username});
                if(processingStatus === false){
                    formErrorList = {
                        details:[
                            {   message,
                                path:["usernameExist"],
                            }
                        ]
                    }; 
                }
            }
            
            //check if date is greater than today
            //console.log(formErrorList)
            if(!formErrorList && !billingAddressErrorList){
                const creditCardExpirationEndDay = getDaysInMonth(creditCardExpirationMonth,creditCardExpirationYear);
                const creditCardExpirationDate = new Date(`${creditCardExpirationYear}-${creditCardExpirationMonth}-${creditCardExpirationEndDay}`);
                console.log(creditCardExpirationDate);

                const now = new Date();
                console.log(now);
                
                if(now > creditCardExpirationDate){
                    formErrorList = {
                        details:[
                            {
                             message:"Enter a Valid Credit Card",
                             path:["invalidCreditCardMonthAndYear"],
                            }
                        ]
                    };
                }
                console.log(formErrorList);
            }

            if(formErrorList === undefined && billingAddressErrorList === undefined) return null;


            const formErrors = {};
            if(formErrorList){
                formErrorList.details.map(function(errorObject){
                    //console.log(errorObject);
                    if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                        const key = errorObject.context.key;
                        const joiType = errorObject.type;
                        //console.log(this.errorMessages);
                        if(_.has(this.errorMessages, [key, joiType]))
                            return formErrors[errorObject.path[0]] = this.errorMessages[key][joiType];
                        else
                            return formErrors[errorObject.path[0]] = errorObject.message;
                    }
                    else
                      return formErrors[errorObject.path[0]] = errorObject.message;
                }.bind(this));
            }

            const billingAddressErrors = {};
            if(billingAddressErrorList){
                billingAddressErrorList.details.map(
                    function(errorObject){
                        //console.log(errorObject);
                        if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                            const key = errorObject.context.key;
                            const joiType = errorObject.type;
                            //console.log(this.errorMessages);
                            if(_.has(this.errorMessages, [key, joiType]))
                                return billingAddressErrors[errorObject.path[0]] = this.errorMessages[key][joiType];
                            else
                                return billingAddressErrors[errorObject.path[0]] = errorObject.message;
                        }
                        else
                          return billingAddressErrors[errorObject.path[0]] = errorObject.message;
                    }.bind(this)
                );
            }
            
            const errors = {...formErrors,...billingAddressErrors} 
            
            return Object.keys(errors).length > 0 ? errors:null;
        }catch(ex){
            console.log(ex);
        }
    }

    render(){
       const {buttonLoader,data,errors} = this.state;
       const monthList = CreditCardHelper.getMonthList();
       const yearList = CreditCardHelper.getYearList();
       const {onClickOfBackButtonFromLoginAndPaymentInformation,user} = this.props; 
       const {selectedProducts} = user;
       const {type:laundryType} = user.laundry;
       //console.log(laundryType);

       const displayCardSectionFlag = false;
       const displayNativeCardFields = false;

       const selectedProductForSignUp = (selectedProducts && selectedProducts.length > 0) ? selectedProducts[0]:"";
       const displayProductName = _.startCase(selectedProductForSignUp);
       const displayStepNumber = (selectedProductForSignUp === "supplies") ? "Step 2":"Step 4";

       const   {
                creditCardName,
                creditCardNumber,
                creditCardExpirationMonth,
                creditCardExpirationMonthDropDownDisplay,
                creditCardExpirationYear,
                creditCardExpirationYearDropDownDisplay,
                creditCardCCV } = this.state.data;
       
       let {password, confirmPassword} = this.state.data;
       
       return(
            <Container id="mainContentArea">
                <Row className="title-row justify-content-between">
                    <Col lg="5">
                        <h3 className="shop">Signup for {displayProductName}</h3>
                    </Col>
                    <Col lg="6">
                        <div className="text-lg-right d-none">
                            <span className="text-muted">{displayStepNumber}</span>
                            <Button color="link">
                            <i className="fa fa-shopping-cart" /> Login and Credit Card Information
                            </Button>
                        </div>
                    </Col>
                </Row> 
                <Row className="title-body-row">
                    <Col md="8">
                        <Card>
                            <CardBody>
                                {/*Error display */}
                                {!_.isEmpty(errors) &&
                                    <FormErrorWidget
                                    errors={errors}
                                    />
                                }
                                {/* End of Error display */}
                                <Form>
                                    <div>
                                        <h6 className="card-category text-left">Login Information</h6>
                                        <hr/>

                                        <FormGroup row>
                                            <Label for="username" sm={3}>Username</Label>
                                            <Col sm={6} id="username">
                                                <Input 
                                                    type="text" 
                                                    name="username" 
                                                    id="username" 
                                                    placeholder="Username" 
                                                    value={this.state.data.username} 
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="userPassword" sm={3}>Password</Label>
                                            <Col sm={4}>
                                                <Input 
                                                    type="password" 
                                                    name="password" 
                                                    id="userPassword" 
                                                    placeholder="Password" 
                                                    value={password} 
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="userConfirmPassword" sm={3}>Confirm Password</Label>
                                            <Col sm={4}>
                                                <Input 
                                                    type="password" 
                                                    name="confirmPassword" 
                                                    id="userConfirmPassword" 
                                                    placeholder="Retype Password" 
                                                    value={confirmPassword} 
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </div>

                                    <div className="mt-5">
                                        <h6 className="card-category text-left">
                                            Credit Card Information 
                                            <i className="fa fa-cc-amex fa-3 ml-1 " aria-hidden="true"></i>
                                            <i className="fa fa-cc-mastercard fa-3 ml-1 " aria-hidden="true"></i>
                                            <i className="fa fa-cc-visa fa-3 ml-1 " aria-hidden="true"></i>
                                            <i className="fa fa-cc-discover fa-3 ml-1 " aria-hidden="true"></i>
                                            <i className="fa fa-cc-jcb fa-3 ml-1 " aria-hidden="true"></i>
                                        </h6>
                                        
                                        <hr/>
                                        
                                        {displayCardSectionFlag && <CardSection />}

                                        <FormGroup row>
                                            <Label for="creditCardName" sm={4}>Name on Credit Card</Label>
                                            <Col sm={6}>
                                                <Input 
                                                    type="text" 
                                                    name="creditCardName" 
                                                    id="creditCardName" 
                                                    placeholder="Name on Credit Card" 
                                                    value={creditCardName} 
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                                <Label for="card-number" sm={4}>Credit Card Number</Label>
                                                <Col id="card-number" sm={6}>
                                                    <CardNumberElement 
                                                        {...stripeInputFieldStyling}
                                                    />
                                                </Col>
                                        </FormGroup>
                                        
                                        <FormGroup row>
                                                <Label for="card-expiration-date" sm={4}>Expiration Date</Label>
                                                <Col id="card-expiration-date" sm={6}>
                                                    <CardExpiryElement 
                                                        {...stripeInputFieldStyling}
                                                    />
                                                </Col>
                                        </FormGroup> 
                                        
                                        <FormGroup row>
                                                <Label for="card-cvc" sm={4}>CVC</Label>
                                                <Col id="card-cvc" sm={6}>
                                                    <CardCVCElement 
                                                        {...stripeInputFieldStyling}
                                                    />
                                                </Col>
                                        </FormGroup> 

                                        <h6 className="card-category text-left mt-2">Billing Address</h6>
                                        <hr/>

                                        <FormGroup row>
                                            <Label for="addressLine1" sm={2}>Address Line 1</Label>
                                            <Col sm={10}>
                                                <Input 
                                                type="text" 
                                                name="addressLine1" 
                                                id="addressLine1" 
                                                placeholder="Address Line 1" 
                                                value={this.state.data.billingAddress.addressLine1} 
                                                onChange={e=>{this.handleChangeAddress(e,"billingAddress")}}
                                                />
                                            </Col>
                                            
                                        </FormGroup>
                                        
                                        
                                        <FormGroup row>
                                            <Label for="addressLine2" sm={2}>Address Line 2</Label>
                                            <Col sm={10}>
                                                <Input 
                                                type="text" 
                                                name="addressLine2" 
                                                id="addressLine2" 
                                                placeholder="Address Line 2" 
                                                value={this.state.data.billingAddress.addressLine2} 
                                                onChange={e=>{this.handleChangeAddress(e,"billingAddress")}}
                                                />
                                            </Col>
                                            
                                        </FormGroup>
                                           
                                        
                                        
                                        <FormGroup row>
                                            <Label for="city" sm={2}></Label>
                                            <Col xs={12} sm={3}>
                                                <Input 
                                                type="text" 
                                                name="city" 
                                                id="city" 
                                                placeholder="City" 
                                                value={this.state.data.billingAddress.city} 
                                                onChange={e=>{this.handleChangeAddress(e,"billingAddress")}}
                                                />
                                            </Col>
                                            <Label for="state" xs={12} sm={2} className="d-block d-sm-none"></Label>
                                            <Col xs={12} sm={4}>
                                                <FormGroup>
                                                    <Select
                                                        className="react-select react-select-default"
                                                        classNamePrefix="react-select"
                                                        name="state"
                                                        id="state"
                                                        value={this.state.data.billingAddress.state}
                                                        onChange={value => this.setAddressState(value,"billingAddress")}
                                                        options={this.state.stateList}
                                                        placeholder="State"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} sm={3}>
                                                <Input 
                                                type="text" 
                                                name="postalCode" 
                                                id="postalCode" 
                                                placeholder="Zip" 
                                                value={this.state.data.billingAddress.postalCode} 
                                                onChange={e=>{this.handleChangeAddress(e,"billingAddress")}}
                                                />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                            <Label for="country" sm={2}>Country</Label>
                                            <Col sm={10}>
                                                <Select
                                                    className="react-select react-select-default"
                                                    classNamePrefix="react-select"
                                                    name="country"
                                                    id="country"
                                                    value={this.state.data.billingAddress.country}
                                                    onChange={value => this.setCountry(value)}
                                                    options={this.state.countryList}
                                                    placeholder="Country"
                                                    autoComplete="off"
                                                />
                                            </Col>
                                            
                                        </FormGroup>


                                        {
                                            displayNativeCardFields && 
                                            <FormGroup row>
                                                <Label for="creditCardNumber" sm={4}>Credit Card Number</Label>
                                                <Col sm={6}>
                                                    <InputGroup>
                                                        <Input 
                                                            name="creditCardNumber" 
                                                            id="creditCardNumber" 
                                                            placeholder="Number on Credit Card" 
                                                            value={creditCardNumber} 
                                                            onChange={this.handleChange}
                                                        />
                                                        <InputGroupAddon addonType="append">
                                                            <InputGroupText>
                                                            <i className="fa fa-credit-card" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        }
                                        

                                        {
                                            displayNativeCardFields &&
                                            <Row>
                                                <Col sm={4}> 
                                                Expiration Month/Year
                                                </Col>
                                                <Col sm={4}>
                                                    <FormGroup>
                                                        <Select
                                                            className="react-select react-select-default"
                                                            classNamePrefix="react-select"
                                                            name="creditCardExpirationMonth"
                                                            id="creditCardExpirationMonth"
                                                            value={creditCardExpirationMonthDropDownDisplay}
                                                            onChange={
                                                                function(selectedValue){ 
                                                                this.handleSelectDropDown(selectedValue,"creditCardExpirationMonth","creditCardExpirationMonthDropDownDisplay")
                                                                }.bind(this)
                                                            }
                                                            options={monthList}
                                                            placeholder="Select Month"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup>
                                                        <Select
                                                            className="react-select react-select-default"
                                                            classNamePrefix="react-select"
                                                            name="creditCardExpirationYear"
                                                            id="creditCardExpirationYear"
                                                            value={creditCardExpirationYearDropDownDisplay}
                                                            onChange={
                                                            function(selectedValue){ 
                                                                this.handleSelectDropDown(selectedValue,"creditCardExpirationYear","creditCardExpirationYearDropDownDisplay")
                                                            }.bind(this)
                                                            }
                                                            options={yearList}
                                                            placeholder="Select Year"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                
                                            </Row>
                                        }
                                        

                                        {
                                            displayNativeCardFields &&
                                            <FormGroup row>
                                                <Label for="creditCardName" sm={4}>CCV</Label>
                                                <Col sm={2}>
                                                    <Input 
                                                        type="password" 
                                                        name="creditCardCCV" 
                                                        id="creditCardCCV" 
                                                        placeholder="CCV" 
                                                        value={creditCardCCV} 
                                                        onChange={this.handleChange}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        }
                                        

                                        



                                        
                                    </div> 


                                    <FormGroup row className="mt-5 justify-content-between">
                                        <Col xs={{ size: 2 }}>
                                            <Button 
                                            className="btn-rounded btn-danger"
                                            onClick={()=>onClickOfBackButtonFromLoginAndPaymentInformation()}
                                            >
                                            Back
                                            </Button>
                                        </Col>
                                        <Col xs={{ size: 6}} sm={{ size: 4}} className="text-right">
                                            <Button 
                                                type="submit"
                                                className="btn-round" 
                                                color="danger"
                                                onClick={this.handleSubmit}
                                            >
                                                {   buttonLoader && 
                                                    <div className="uil-reload-css reload-small mr-1">
                                                        <div />
                                                    </div>
                                                }
                                                Save & Review
                                            </Button>
                                        </Col>
                                    </FormGroup>

                                </Form>
                            </CardBody>
                        </Card>    
                    </Col>
                    <Col md="4" className="d-none d-sm-block">
                        {
                            laundryType === "student" && <StudentLaundryRegistrationStepsDisplayWidget />
                        }
                        {
                            laundryType === "residential" && <ResidentialLaundryRegistrationStepsDisplayWidget />
                        }
                        
                    </Col>
                </Row>
            </Container>  
       );

    }
}

export default injectStripe(LoginAndPaymentInformation);