import React,{Component} from "react";


import {
    Button,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Label,
    Row,
    Col,
    FormGroup, 
    Modal, 
    Input, 
  } from "reactstrap";


class ByThePoundPricingModal extends Component{
    constructor(){
        super();
        this.state = {
            
        }
    }

    render(){
        const {handleModalDisplay,modalDisplayFlag} = this.props;
        return(
            <>
                <Modal id="storageInsuranceModal" size="lg" isOpen={modalDisplayFlag} toggle={() => handleModalDisplay(false)}>
                    <div className="modal-header">
                        <button
                            className="close"
                            type="button"
                            onClick={() => handleModalDisplay(false)}
                        >
                            <span>×</span>
                        </button>
                        <h5
                            className="modal-title text-center"
                            id="exampleModalLabel"
                        >
                            What is the $5.00 charge for?
                        </h5>
                    </div>
                    <div className="modal-body">
                        <p>
                        This $5.00 charge will be applied to your one time pickup. For example, if you have a laundry pickup that equals $36.50, you will only owe an additional $31.50. 
                        </p>
                    </div>
                    <div className={`modal-footer`}>
                        <div className="right-side">
                            <Button
                            className="btn-link"
                            color="default"
                            type="button"
                            onClick={() => handleModalDisplay(false)}
                            >
                            Close
                            </Button>
                        </div>
                        
                    </div>
                </Modal>
            </>
        );
    }
}

export default ByThePoundPricingModal