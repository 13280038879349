import React from "react"
import _ from "lodash";
import accounting from "accounting";
import moment from "moment";


import SupplyOrderDetailView from "./SupplyOrderDetailView";
import {renderError} from '../../../../../../utils/errorUtils';
import {scrollTop} from '../../../../../../utils/helperUtils';


import {
    Button,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Label,
    Row,
    Col,
    FormGroup, 
    Modal, 
    Input, 
    Table,
} from "reactstrap";

const SupplyOrderDetailModal = (props) =>{
    
        const {modalDisplayFlag,handleModalDisplay,supplyOrderRecordForDetailView} = props 
        
        return(
            <>
            <Modal id="supplyOrderListModal" size="lg" isOpen={modalDisplayFlag} toggle={() => handleModalDisplay(false)}>
                <div className="modal-header">
                    <button
                        className="close"
                        type="button"
                        onClick={() => handleModalDisplay(false)}
                    >
                        <span>×</span>
                    </button>
                    <h5
                        className="modal-title text-center"
                        id="exampleModalLabel"
                    >
                        Supplies:Your Order Detail
                    </h5>
                </div>
                <div className="modal-body">
                    {
                        !_.isEmpty(supplyOrderRecordForDetailView)  && 
                        <div>
                            
                            
                            <SupplyOrderDetailView
                                supplyOrder={supplyOrderRecordForDetailView}
                            />
                        </div>
                    }
                </div>
                <div className={`modal-footer`}>
                    <div className="divider" />
                    <div className="right-side">
                        <Button
                        className="btn-link"
                        color="default"
                        type="button"
                        onClick={() => handleModalDisplay(false)}
                        >
                        Cancel
                        </Button>
                    </div>
                    
                </div>
            </Modal>    
            </>
        )
    
}

export default SupplyOrderDetailModal;