/*
- Final save gets triggered in the parent component
*/
import React,{Component} from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Joi from "@hapi/joi"
import Select from "react-select";


import http             from "../../../../../../services/httpService";
import {getUSStateList} from "../../../../../../services/dropDownListsService";
import {getCountryList} from "../../../../../../services/dropDownListsService";
import UserService      from "../../../../../../services/userService";
import SchoolService      from "../../../../../../services/schoolService";

import BaseForm from "../../../../../BaseClass/Form"
import FormErrorWidget from "../../../../../Common/FormErrorWidget";

import {scrollTop}     from '../../../../../../utils/helperUtils';
import {renderError}   from '../../../../../../utils/errorUtils';
import customToast     from '../../../../../Common/CustomToast';


// reactstrap components
import {
    Badge,
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Form,
    FormGroup, 
    Label, 
    Input, 
    InputGroupAddon,
    InputGroupText,
    InputGroup,
} from "reactstrap";


const customerCredentialsInfoDisplayProperties = {
        headerTitle: 'Credentials',
        currentAddressTitle:'Username & Password',
        stepTitle:'',
        step:'',
        displayHeader:false,
}       


class CredentialInformation extends BaseForm{
    divAreaToScrollForError = "description-areas"

    validationSchema = Joi.object({
        updateInformationFlag: Joi.any().optional(),
        username:Joi.string().min(5).required().label('Username'),
        password:Joi.string().min(8).required().pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^ ]{8,}$/).label('Password'),
        retypePassword:Joi.string().required().valid(Joi.ref('password')).label('Confirm Password'),
        
    }) ;

    errorMessages = {
        "password":{
            "string.pattern.base":"New Password must contain at least one uppercase letter, one lowercase letter and one number",
        },
        "retypePassword":{
            "any.only":"Confirm Password should match the Password"
        },
        
    }
    
    constructor(){
        super();
        this.state = {
            data:{
                updateInformationFlag:false,
                username:"",
                password:"",  
                retypePassword:"",
            },
            errors:{},
        }
    }

    componentDidMount(){
        this.mapModelToView();
    }

    componentDidUpdate(prevProps){
        const {user} = this.props;
        const {user:previousUser} = prevProps;
        if(!_.isEqual(previousUser.credentialInformation,user.credentialInformation)){
            this.mapModelToView();
        }
            
        
    }

    getInformationForDisplay = ()=>{
        let displayInformation  = "";
        const {user} = this.props;
        const {credentialInformation} = user;
        const {
            username,
        } = credentialInformation;

        if(credentialInformation !== ""){
            return <>
               <div className="mb-2">
                Username: 
                <span className="ml-2">{`${username}`}</span>
               </div> 
               <div  className="mb-2">
                Password: 
                <span  className="ml-2">xxxxxxxxxxxx</span>
               </div> 
            </>
        }

        return displayInformation
    }

    mapModelToView = async ()=>{
        
        try{
            const {user} = this.props;
            const {credentialInformation} = user;
            const {
                username, 
            } = credentialInformation
            
            const data = {
                ...this.state.data,
                updateInformationFlag:false,
                username,
                
            }

            this.setState({
                data,
            });
        }
        catch(ex){
            console.log(`Component did mount  ${ex}`);
        }

          
        
    }

    
    setUpdateInformationFlag = (updateInformationFlag)=>{
        this.setState({
            data:{
                ...this.state.data,
                updateInformationFlag,
            }
        })
    }

    //This gets executed once the parent submission process is executed
    submitLocal = async ()=>{
        const {
            handleOnSaveOfCustomerInformation,
            handleDisplayOfProcessingModal,
        } = this.props;
        
        try{
            
            handleDisplayOfProcessingModal(true);
            const formData = this.state.data;

            const {status,message,errorObject} = await UserService.viewProfileSaveCredentials(formData);

            if(status === true){
                handleDisplayOfProcessingModal(false);
                
                this.setState({
                    data:{
                        ...this.state.data,
                        updateInformationFlag:false,
                        password:"",  
                        retypePassword:"",
                    } 
                })
                
                customToast.success(message); 
                //Go back to the parent view for information reload
                return handleOnSaveOfCustomerInformation();
            }
        } 
        catch(ex){
            handleDisplayOfProcessingModal(false);
            /*
            400 signals validation errors from the server
            renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop(this.divAreaToScrollForError); return;}
            }
            else
                renderError(ex);
        } 

    }

    validateLocal= ()=>{
        //This is anything specific to the form like async validations
        
    }

    render(){
        const {
            user,
        } = this.props;

        
        const { 
            currentAddressTitle, 
            displayHeader,
            headerTitle,
            stepTitle,
            step, 
        } = customerCredentialsInfoDisplayProperties;

        
        const {data,errors,} = this.state;

        const {
            username,
            password,
            retypePassword
        } =  data;

        const currentInformation = this.getInformationForDisplay();

        const   {
            updateInformationFlag,
        } = data;

        
        return(
            <Container id="mainContentArea">
                {
                    displayHeader && 
                    <Row className="title-row justify-content-between">
                        <Col lg="5">
                            <h3 className="shop">{headerTitle}</h3>
                        </Col>
                        <Col lg="6">
                            <div className="text-lg-right">
                                <span className="text-muted">{step}</span>
                                <Button color="link">
                                <i className="fa fa-shopping-cart" /> {stepTitle}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                }
                 
                <Row className="title-body-row">
                    <Col>
                        <Card className="card-plain bg-white p-2 rounded-lg">
                            <CardBody>
                                {/*Error display */}
                                {!_.isEmpty(errors) &&
                                    <FormErrorWidget
                                    errors={errors}
                                    />
                                }
                                {/* End of Error display */}

                                
                                <div className="mt-2 mb-4">
                                    <h6 className="card-category">
                                        <span className="text-left">
                                        {currentAddressTitle}
                                        </span>
                                    </h6>
                                    <hr/>
                                    <Row className="mt-2">
                                        <Col xs="8">
                                            {currentInformation}
                                        </Col>
                                    </Row>

                                    {
                                        !updateInformationFlag && 
                                        <Row className="mt-2">
                                            <Col xs="8">
                                                <Button 
                                                    className="btn-round mr-1"
                                                    color="default"
                                                    outline
                                                    size="sm"
                                                    onClick={()=>this.setUpdateInformationFlag(true)}
                                                >
                                                Change Password
                                                </Button>
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            
                                
                                {
                                    updateInformationFlag && 
                                    <Row>
                                        <Col>
                                            <h6>Update your password:</h6>
                                            <p>Please note: New Password must contain at least one uppercase letter, one lowercase letter and one number.</p>
                                            <div className="mt-2">
                                                <FormGroup row>
                                                    <Label for="username" sm={4} md={2}>Username</Label>
                                                    <Col sm={10}>
                                                        <Input 
                                                            type="text" 
                                                            name="username" 
                                                            id="username" 
                                                            disabled={true}
                                                            placeholder="Username" 
                                                            value={username} 
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup row>
                                                    <Label for="password" sm={4} md={2}>Password</Label>
                                                    <Col sm={10}>
                                                        <Input 
                                                            type="password" 
                                                            name="password" 
                                                            id="password" 
                                                            placeholder="Password" 
                                                            value={password} 
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup row>
                                                    <Label for="password" sm={4} md={2}>Confirm Password</Label>
                                                    <Col sm={10}>
                                                        <Input 
                                                            type="password" 
                                                            name="retypePassword" 
                                                            id="retypePassword" 
                                                            placeholder="Confirm Password" 
                                                            value={retypePassword} 
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </div>
                                            

                                        </Col>
                                    </Row>  
                                
                            
                            
                                }
                                
                                
                                

                                {/* This is used for updates to be handled on the same page */}
                                {
                                    updateInformationFlag &&
                                    <FormGroup row className="mt-5 justify-content-between">
                                        <Col xs={{ size: 2 }}>
                                            <Button 
                                            className="btn-rounded btn-danger"
                                            onClick={()=>this.setUpdateInformationFlag(false)}
                                            >
                                            Cancel
                                            </Button>  
                                        </Col>
                                        <Col xs={{ size: 6}} sm={{ size: 4}} className="text-right">
                                            <Button 
                                            className="btn-rounded btn-danger"
                                            onClick={this.submitForm}
                                            >
                                            Save
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                }
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }


}

CredentialInformation.propTypes = {
    user: PropTypes.object.isRequired,
}

CredentialInformation.defaultProps = {

}


export default CredentialInformation;

