
  import http from "./httpService";
  import jwtDecode from "jwt-decode";

  const apiEndpoint = "/auth";
  const jsonWebToken = 'token';

  const apiUserEndpoint = "/users";


  //This is called on refresh of the whole page (Remove bidirectional dependencies)
  http.setJwt(getJwt());

  const isLoggedInMode = false;
  const localStorageShoppingCart = "shoppingCart";

  
  function getCurrentUser(){
    try{
      const jwt = localStorage.getItem(jsonWebToken);
      const user = jwtDecode(jwt);

      const {exp:expirationTime} = user;
      const now = Math.floor(Date.now() / 1000);
      if(now < expirationTime)
        return user;
      else{
        logout();
        return null;
      }
    }
    catch(ex){
      return null;
    }

    /*
    try{
        if(!isLoggedInMode)
            throw new Error('Invalid User');

        let user = {
            id: '1111',
            name: 'John Doe',
            email: 'jdoe@test.com',
            role: 'user',
        }
        return user;
    }
    catch(ex){
      return null;
    }
    */
  }

  export function getJwt(){
    return localStorage.getItem(jsonWebToken);
  }

  export function setJwtOnResetPassword(token){
    localStorage.setItem(jsonWebToken,token);
    http.setJwt(getJwt());
  }

  export async function login(username,password){
    const {data:jwt} = await http.post(apiEndpoint,{username,password});
    localStorage.setItem(jsonWebToken,jwt);
  }
  
  export function loginWithJwt(token){
    localStorage.setItem(jsonWebToken,token);
  }
  
  export function logout(){
    localStorage.removeItem(jsonWebToken);
    localStorage.removeItem(localStorageShoppingCart);
    window.location = "/";
    
  }

  async function getUserRecord(){
      const user  = getCurrentUser();
      const {data:userDb} = await http.get(`${apiUserEndpoint}/${user.userId}`);
      return userDb;    
  }
  

  export default {
    getCurrentUser,
    getJwt,
    getUserRecord,
    localStorageShoppingCart,
    login,
    loginWithJwt,
    logout,
    setJwtOnResetPassword,
  }