import React, {Component} from "react";
import _ from "lodash";

import SchoolService from "../../../services/schoolService";
import ResidentialService from "../../../services/residentialService";

import DesktopView from "./Views/DesktopView";
import MobileView from "./Views/MobileView";



// reactstrap components
import {
    Row,
    Col
  } from "reactstrap";


const contentDropdown = [
{ value: "how-it-works", label: "How it works" },
{ value: "pricing", label: "Location & Pricing" },
{ value: "faq", label: "FAQ`s" },

];

const faqDropdown = [
{ value: "laundry-care", label: "Laundry Care" },
{ value: "plans", label: "Plans" },
{ value: "pickup-delivery", label: "Pickup & Delivery" },
{ value: "dry-cleaning", label: "Dry Cleaning" },

];

class LaundryInformation extends Component{
        
    constructor(){
        super();
        this.state = {
            listOfSchoolsByRegionForLaundry:"",
            listOfResidentialCitiesByStateForLaundry:"",
        }
    }

    async componentDidMount(){
        const listOfSchoolsByRegionForLaundry = await SchoolService.getListOfSchoolsByRegionForLaundry();
        const listOfResidentialCitiesByStateForLaundry = await ResidentialService.getListOfResidentialCitiesByState();
        
        this.setState({
            listOfSchoolsByRegionForLaundry,
            listOfResidentialCitiesByStateForLaundry
        });
    }

    render(){
        const {
            listOfSchoolsByRegionForLaundry,
            listOfResidentialCitiesByStateForLaundry,
        } = this.state;

        const displayCSS = "min-height-500";
        
        return(
                <>
                    <Row className={displayCSS}>
                        <Col md="12" sm="12">
                            <div className="d-none d-md-block">
                                <DesktopView 
                                   contentDropdown = {contentDropdown}
                                   faqDropdown = {faqDropdown}
                                   listOfSchoolsByRegionForLaundry = {listOfSchoolsByRegionForLaundry}
                                   listOfResidentialCitiesByStateForLaundry = {listOfResidentialCitiesByStateForLaundry}
                                   {...this.props}
                                />
                            </div>
                            <div className="d-block d-md-none">
                                <MobileView 
                                    contentDropdown = {contentDropdown}
                                    faqDropdown = {faqDropdown}
                                    listOfSchoolsByRegionForLaundry = {listOfSchoolsByRegionForLaundry}
                                    listOfResidentialCitiesByStateForLaundry = {listOfResidentialCitiesByStateForLaundry}
                                    {...this.props}
                                />
                            </div>
                        </Col>
                    </Row>

                    

                </>
            
        )
    }
}


export default LaundryInformation