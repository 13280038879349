import React from "react";

import {
    Media,
    
} from "reactstrap";

const studentData = [
    {
        heading:"How much is my storage going to cost?",
        body:<p>
        The price for storage is determined by the number of items, what those items are, and their size.  You can get an estimate for the price of any item by going to the 'storage pricing' page on our website and using the price estimator.  
        </p>,
    },
    {
        heading:"Is there a minimum storage order?",
        body:<p>
        Yes, $100. Every customer must pay at least $100 regardless of how many items they give us to store.
        </p>
    },
    {
        heading:"Is the $35.00 deposit an extra charge?",
        body:<p>
        The $35.00 deposit will be deducted from your total storage fee on the final invoice. If you cancel without scheduling a pickup, or cancel at least 48 hours before your pickup is scheduled, your deposit can also be refunded.
        </p>,
    },
    {
        heading:"What if I decide to keep my items in storage past the end date of my storage term?",
        body:<p>
        We are happy to keep your items in storage as long as you need. For each month after your initial storage term ends, we will charge you 15% of the cost for the initial storage term. For instance, if you stored items over the summer with us that cost $300, and keep your items with us for an extra month, we will charge your credit card an additional $45. If you kept it with us for two extra months, we will charge your card an additional $90, and so on. Extra charges occur monthly. 
        </p>,
    },
    
    {
        heading:"Are there any items I shouldn't store?",
        body:<p>
        We will store anything you give us, but there are some items that do not make sense to store economically. For instance, an empty plastic bin may cost more to store than to throw away and repurchase next semester/year. Here are some other examples; empty trash can, empty laundry hamper, and other miscellaneous loose items that could be packed into a larger box. Please also keep in mind, while Lazybones will store any of these unpacked items for you, only the items properly packed inside of storage containers carry insurance against damage. Additionally, loose/unpacked items may be more expensive to store, and there is no compensation given in the event of damage to these items.

        </p>,
    },
    
];

const Pricing = ()=>{
    const data = studentData;
    let ctr = 1; 
    return(
        <>
            {
                data.map(({heading,body})=>
                    <Media key={ctr++}>
                        <Media body>
                        <Media heading>{heading}</Media>
                        {body}
                        </Media>
                    </Media>
                )
            }
        </>
    );
}

export default Pricing;
