import React,{Component} from "react";
import _ from "lodash";
import Select from "react-select";
import moment from "moment";
import accounting from "accounting";
import date from "date-and-time"
import dateFormat from "dateformat";

import auth from "../../../../services/authService";
import BaseFrom from "../../../BaseClass/Form";

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Media,
    Container,
    Row,
    Col
} from "reactstrap";


class StudentPricing extends BaseFrom {

    pricingConstants = {
        pricingBysemesterPlan:{
            id:2,
            type:"semester-plan",
            description:"Semester Plan",
        },
        pricingByThePound:{
            "id":1,
            "type":"by-the-pound",
            "description":"By the Pound",
        }
    }

    constructor(){
        super();
        this.state = {
            schoolList:"",
            schoolDropDownList:[],
            data:{
                selectedSchoolDropdownObject:"",
                semester:"",
                laundryType:"",
                pricePlan:"",
            },
            errors:{},
        }
    }

    componentDidMount(){
        this.mapModelToView();
    }

    componentDidUpdate(prevProps){
        const { listOfSchoolsByRegionForLaundry } = this.props;
        const { listOfSchoolsByRegionForLaundry:listOfSchoolsByRegionForLaundryPrevProps } = prevProps;
        if(listOfSchoolsByRegionForLaundryPrevProps === "" && !_.isEmpty(listOfSchoolsByRegionForLaundry)){
            this.mapModelToView();
        }
    }

    displaySemesterList = ()=>{
        const { data,schoolList } = this.state;
        const selectedSchoolObject  = data.selectedSchoolDropdownObject;
        
        if(_.isEmpty(selectedSchoolObject))
           return "";

        const schoolId = selectedSchoolObject.value;

        //Get the schoolObject
        const school  = _.find(schoolList,{"id":schoolId})
        const semesterList =  (!_.isEmpty(school)) ? school.semesters:[];
        
        const {semester:selectedSemester} = this.state.data;
      
        
        let renderSemestersAvailable = true;
        renderSemestersAvailable = (semesterList.length > 0) ? true:false;
        
        //Check if the whole list of Semesters has already expired
        if(renderSemestersAvailable){
            const listOfActiveSemesters = _.filter(semesterList, (s) =>moment().isSameOrBefore(s.endDateDb));
            renderSemestersAvailable = (listOfActiveSemesters.length > 0) ? true:false
        }
        
        //Render no semester available message
        if(!renderSemestersAvailable){
            return(
                <Row>
                    <Col xs="12">
                        <div className="mlb-alert mlb-alert-primary" role="alert">
                            <Row>
                                <Col xs="1" sm="1" className="fs-42 text-danger">
                                        <i className="fa fa-exclamation-triangle fa-6" aria-hidden="true"></i>    
                                </Col>
                                <Col>
                                   We currently don't have any upcoming semester plans available for sign-up. Please try again later.
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            );
        }
        
        
        if(renderSemestersAvailable){
            return semesterList.map(s=>{
                return(
                  <FormGroup key={s.id} row className="text-center">
                      <Col sm="2"></Col> 
                      <Col sm={{ size: 8}}> 
                          <Button
                              className="btn-round mr-1"
                              color="danger"
                              outline = {selectedSemester.id !== s.id}
                              block
                              type="button"
                              onClick={()=>this.handleSelectSemester(s)}
                          >
                              {s.semester} ({s.startDate} - {s.endDate})
                          </Button>
                      </Col>
                      <Col sm="2"></Col>
                  </FormGroup>   

                );
            })  
        }
    }

    getPriceList = ()=>{
        const { data, } = this.state;
        const {semester:selectedSemester,laundryType:selectedLaundryType,pricePlan:selectedPricePlan} = data;
        if(!_.isEmpty(selectedLaundryType)){
          
          let priceList = _.find(selectedSemester.pricing,{"id":selectedLaundryType.id}).price;

          if(selectedLaundryType.id === this.pricingConstants.pricingBysemesterPlan.id){
                const {startDate,endDate, numberOfWeeks, numberOfWeeksForFullSignupFees} = selectedSemester;
                const endDateObj = new Date(endDate);
            
                const dateThresholdForFullPrice = this.getThresholdDateForFullPrice(selectedSemester);
                const now = new Date();
                
                
                if(now > dateThresholdForFullPrice){
                    //Update the price value, price-per-pound and set original price and pro-rate flag to true
                    const originalPriceList = [...priceList];
                    priceList = originalPriceList.map(p=>{
                        const packagePricePerWeek = p.originalPrice/numberOfWeeks;
                        const remainingWeeks = (dateFormat(endDateObj, "W") - dateFormat(now, "W")) + 1;
                        p.price = parseFloat(accounting.toFixed((packagePricePerWeek * remainingWeeks),2));
                        p.proRatedFlag = true;
                        return p;
                    });
        
                    console.log(priceList);
                }
                //console.log(now);
          }

          return priceList;
        }
    }

    getThresholdDateForFullPrice  = ({startDate,endDate, numberOfWeeks, numberOfWeeksForFullSignupFees})=>{
        const startDateObj = new Date(startDate);
        console.log(dateFormat(startDateObj, "W"));
        const dateThresholdForFullPrice = date.addDays(startDateObj, numberOfWeeksForFullSignupFees * 7);
        return dateThresholdForFullPrice;
    }

    handleSelectSchool = (value,dataAttribute)=>{
        this.handleDropdownChange(value,dataAttribute);
        this.setState({
            data:{
                [dataAttribute]:value,
                semester:"",
                laundryType:"",
                pricePlan:"",
            }
        })
        
    }

    handleSelectLaundryPlan= (l)=>{
        const laundryType = _.pick(l,['id','type'])
        const data = {...this.state.data}
        data.laundryType = laundryType;
        data.pricePlan = "";
        this.setState({data});
    } 

    handleSelectSemester = (s)=>{
        //const semester = _.pick(s,['id','semester']);
        const semester = s;
        const data = {...this.state.data}
        data.semester = semester;
        data.laundryType = "";
        data.pricePlan = "";
        this.setState({data}); 
    }

    
    mapModelToView = ()=>{
        const { listOfSchoolsByRegionForLaundry } = this.props;
        //console.log(listOfSchoolsByRegionForLaundry);
        if(!_.isEmpty(listOfSchoolsByRegionForLaundry)){
            const schoolSet= _.map(listOfSchoolsByRegionForLaundry,'schools');
            
            const schoolList = _.flattenDeep(schoolSet);
            const schoolDropDownList = schoolList.map(s=>{
                return {value:s.id,label:s.name}
            })
            
            this.setState({
                schoolDropDownList,
                schoolList,
            })

        }
    
    }

    render(){
        const { data, schoolDropDownList } = this.state;
        const {selectedSchoolDropdownObject} = data;
        const {semester:selectedSemester,laundryType:selectedLaundryType,pricePlan:selectedPricePlan} = data;
        const laundryTypeList = selectedSemester.pricing || [];
      
        const priceList = this.getPriceList() || [];
        const dataColor = ["orange","green","yellow","blue"];
        let priceColorCtr = 0;

        return(
            <>
                <div>
                    <FormGroup>
                        <h6 className="card-category text-left">Select School</h6>
                        <Select
                            className="react-select react-select-default"
                            classNamePrefix="react-select"
                            name="defaultSelect"
                            value={selectedSchoolDropdownObject}
                            onChange={value => this.handleSelectSchool(value,"selectedSchoolDropdownObject")}
                            options={schoolDropDownList}
                            placeholder=""
                        />
                    </FormGroup>
                </div>

                {/* Display semesters */}
                {   
                    !_.isEmpty(selectedSchoolDropdownObject) && 
                    <div>
                        <h6 className="card-category text-left">Select Semester</h6>
                        <hr/>
                        { this.displaySemesterList() }
                    </div>
                }

                {
                    selectedSemester && 
                    <div>
                    <h6 className="card-category text-left">Select Plan</h6>
                    <hr/> 
                    
                    <FormGroup row className="text-center">
                            <Col sm="2"></Col> 
                            {
                                laundryTypeList.map(l=>{
                                    return(
                                        <Col key={l.id} sm={{ size: 4}} className="mt-2"> 
                                            <Button
                                                className="btn-round mr-1"
                                                color="danger"
                                                outline={l.id !== selectedLaundryType.id}
                                                block
                                                type="button"
                                                id={`btn_${l.type}`}
                                                onClick={()=>{this.handleSelectLaundryPlan(l)}}
                                            >
                                                {l.description}
                                            </Button>
                                        </Col>
                                    )
                                }) 
                            }
                            <Col sm="2"></Col>
                    </FormGroup>
                    </div>

                }

                {
                    selectedLaundryType &&
                    <div>
                        <h6 className="card-category text-left">Select Price Plan</h6>
                        <hr/> 
                        <Row className="justify-content-center">
                            {
                                priceList.map(p=>{
                                    
                                    const selectedAttributes = (p.id === selectedPricePlan.id) ? {"data-color":"orange"}:{};
                                    return(
                                        <Col key={p.id} md="6" sm="6">
                                            <Card 
                                                className="card-pricing"  
                                                data-color={dataColor[priceColorCtr++]}
                                                {...selectedAttributes}
                                            >
                                                <CardBody>
                                                    <CardTitle tag="h1" style={{fontSize:"3.0em"}}>{accounting.formatMoney(p.price)}</CardTitle>
                                                    <ul>
                                                        <li>
                                                        {p.name}
                                                        </li>
                                                        <li>
                                                        {p.description}
                                                        </li>
                                                        <li>
                                                        ${accounting.toFixed(p.pricePerPound,2)} per pound
                                                        </li>
                                                        <li>&nbsp;</li>
                                                    </ul>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    );
                                })
                            }
                            
                        </Row>
                        <div className="d-flex justify-content-center">
                            {
                                !auth.getCurrentUser() && 
                                <Button
                                    className="btn-round mr-1"
                                    color="danger"
                                    type="button"
                                    onClick={()=>this.props.history.push("/signup/laundry/student/select-school")}
                                >
                                    Ready to Sign-up
                                </Button>
                            }
                        </div>
                    
                    </div>

                }



                <Row id="pricing" className={`pricing d-none`}>
                    <Col md="4">
                        <Card className="card-pricing" data-color="orange">
                            <CardBody>
                            <h6 className="card-category">Enterprise</h6>
                            <CardTitle tag="h1">$139</CardTitle>
                            <ul>
                                <li>
                                <i className="fa fa-check text-success mr-1" />
                                Sharing Tools
                                </li>
                                <li>
                                <i className="fa fa-check text-success mr-1" />
                                Design Tools
                                </li>
                                <li>
                                <i className="fa fa-times text-danger mr-1" />
                                Private Messages
                                </li>
                                <li>
                                <i className="fa fa-times text-danger mr-1" />
                                Personal Brand
                                </li>
                                <li>
                                <i className="fa fa-times text-danger mr-1" />
                                Twitter API
                                </li>
                            </ul>
                            <Button
                                className="btn-round card-link"
                                color="success"
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                            >
                                Get Started
                            </Button>
                            </CardBody>
                        </Card>
                    </Col>    
                    <Col md="4">
                    <Card className="card-pricing"  data-color="green">
                            <CardBody>
                            <h6 className="card-category">Enterprise</h6>
                            <CardTitle tag="h1">$139</CardTitle>
                            <ul>
                                <li>
                                <i className="fa fa-check text-success mr-1" />
                                Sharing Tools
                                </li>
                                <li>
                                <i className="fa fa-check text-success mr-1" />
                                Design Tools
                                </li>
                                <li>
                                <i className="fa fa-times text-danger mr-1" />
                                Private Messages
                                </li>
                                <li>
                                <i className="fa fa-times text-danger mr-1" />
                                Personal Brand
                                </li>
                                <li>
                                <i className="fa fa-times text-danger mr-1" />
                                Twitter API
                                </li>
                            </ul>
                            <Button
                                className="btn-round card-link"
                                color="success"
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                            >
                                Get Started
                            </Button>
                            </CardBody>
                        </Card>
                    </Col>    
                    <Col md="4">
                    <Card className="card-pricing"  data-color="yellow">
                            <CardBody>
                            <h6 className="card-category">Enterprise</h6>
                            <CardTitle tag="h1">$139</CardTitle>
                            <ul>
                                <li>
                                <i className="fa fa-check text-success mr-1" />
                                Sharing Tools
                                </li>
                                <li>
                                <i className="fa fa-check text-success mr-1" />
                                Design Tools
                                </li>
                                <li>
                                <i className="fa fa-times text-danger mr-1" />
                                Private Messages
                                </li>
                                <li>
                                <i className="fa fa-times text-danger mr-1" />
                                Personal Brand
                                </li>
                                <li>
                                <i className="fa fa-times text-danger mr-1" />
                                Twitter API
                                </li>
                            </ul>
                            <Button
                                className="btn-round card-link"
                                color="success"
                                onClick={e => e.preventDefault()}
                            >
                                Get Started
                            </Button>
                            </CardBody>
                        </Card>
                    </Col>    
                </Row>
                      
            </>
        )
    }   
}

export default StudentPricing;

