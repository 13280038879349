import React,{Component} from "react";
import _ from "lodash";
import moment from "moment";
import UserService from "../../../../../../services/userService";

import {scrollTop} from '../../../../../../utils/helperUtils';
import FormErrorWidget from "../../../../../Common/FormErrorWidget";
import {renderError} from '../../../../../../utils/errorUtils';


// reactstrap components
import {
    Modal,
    Button,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    FormGroup, 
    Label,
    Input, 
    Table,
  } from "reactstrap";

  class DeliveryScheduleCancelModal extends Component{

    constructor(){
        super();
        this.state={
            data:{},
            errors:{},
        }
    }

    handleSubmit = (e)=>{
        try{
            e.preventDefault();  
            const {handleSubmit} = this.props;  
            return handleSubmit();
            
        }catch(ex){
            renderError(ex);
        }
    }

    getDeliveryAddress = ()=>{
        let address = "";
        const {storageOrder:storage} = this.props;
        if(storage && !_.isEmpty(storage.storageOrder)){
            const {storageOrder} = storage;
            const {
                sched_streetnum:streetNumber,
                sched_streetname:addressLine1,
                sched_aptnum:apartmentNumber,
                sched_city:city,
                sched_zip:postalCode,
            } = storageOrder

            if(apartmentNumber)
                address = <span>{`${streetNumber}, ${addressLine1} Apt: ${apartmentNumber}`} <br/> {`${city} - ${postalCode}`}</span>;
            else
                address = <span>{`${streetNumber}, ${addressLine1}`} <br/> {`${city} - ${postalCode}`}</span>;
                
        }

        return address;
        
    }

    
    getDeliveryDateAndTime = ()=>{
        let deliveryDate = "";
        const {storageOrder:storage} = this.props;
        if(storage && storage.nextPickupDeliverySchedule && storage.nextPickupDeliverySchedule.delivery){
            const {delivery:deliveryObject} = storage.nextPickupDeliverySchedule;
            const {displayDate,startTime,endTime} = deliveryObject;
            deliveryDate = `${displayDate} ${startTime} - ${endTime}`;
        }
        
        return deliveryDate ;

    }

    getDeliverySchedulingNote = ()=>{
        let storageScheduleNote = "";
        const {storageOrder:storage} = this.props;
        if(storage && storage.nextPickupDeliverySchedule && storage.nextPickupDeliverySchedule.delivery){
            storageScheduleNote = storage.nextPickupDeliverySchedule.delivery.storageScheduleNote;
            
        }
        return storageScheduleNote ;

    }

    

    getSelectedItems = ()=>{
        const {storageOrder:storage} = this.props;
        if(storage && storage.nextPickupDeliverySchedule && storage.nextPickupDeliverySchedule.delivery){
            const {storageItems} = storage.nextPickupDeliverySchedule.delivery
            return storageItems.map(s=>{
                const {storage_item_id:storageItemId,barcode,description} = s;
                return <tr key={storageItemId}>
                            <td>{description}</td>
                            <td>{barcode}</td>
                        </tr>;
            })
        }
       
    }

    saveForm = (e)=>{
        e.preventDefault();
            
        this.props.handleSaveOperation();
        return;
    }

    render(){
        const {handleModalDisplay,modalDisplayFlag} = this.props;
        const {data,errors} = this.state;
        const schedulingNotes= this.getDeliverySchedulingNote();
        
        return(
            
            <Modal id="storageDeliveryCancelScheduleModal" size="lg" isOpen={modalDisplayFlag} toggle={() => handleModalDisplay(false)}>
                <div className="modal-header">
                    <button
                        className="close"
                        type="button"
                        onClick={() => handleModalDisplay(false)}
                    >
                        <span>×</span>
                    </button>
                    <h5
                        className="modal-title text-center"
                        id="exampleModalLabel"
                    >
                        Modify Storage Pickup Schedule
                    </h5>
                </div>
                <div className="modal-body">
                {/*Error display */}
                {!_.isEmpty(errors) &&
                    <FormErrorWidget
                        errors={errors}
                    />
                }
                {/* End of Error display */}

                <Row>
                    <Col xs="4">
                        <h6>Date and Time</h6>
                    </Col>
                    <Col>
                        {this.getDeliveryDateAndTime()}
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col xs="4">
                        <h6>Delivery Address</h6>
                    </Col>
                    <Col>
                        <p>
                            {this.getDeliveryAddress()}
                        </p>
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col xs="4">
                        <h6>Delivery Notes:</h6>
                    </Col>
                    <Col>
                        <p>
                            {schedulingNotes}
                        </p>
                    </Col>
                </Row>


                <Row className="mt-4">
                    <Col>
                        <h6>Selected Items for Delivery:</h6>
                        <Table responsive striped className="section-gray">
                            <thead>
                                <tr>
                                    <th className="table-header-font-strong">Item Description</th>
                                    <th className="table-header-font-strong">Item Code</th>
                                    
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {this.getSelectedItems()}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                </div>
                <div className={`modal-footer`}>
                    <div className="left-side">
                        <Button
                        className="btn-link"
                        color="default"
                        type="button"
                        onClick={() => handleModalDisplay(false)}
                        >
                        Cancel
                        </Button>
                    </div>
                    <div className="divider" />
                    <div className="right-side">
                        <Button className="btn-link" color="danger" type="button" onClick={this.saveForm}>
                        Cancel Delivery Schedule
                        </Button>
                    </div>
                </div>
            </Modal>
            
        )
    }
  }

  export default DeliveryScheduleCancelModal;
