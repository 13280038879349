import React,{Component} from "react";


import {
    Button,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Label,
    Row,
    Col,
    FormGroup, 
    Modal, 
    Input, 
  } from "reactstrap";


class GarmentInsuranceModal extends Component{
    constructor(){
        super();
        this.state = {
            
        }
    }

    render(){
        const {handleModalDisplay,modalDisplayFlag} = this.props;
        return(
            <>
                <Modal id="priceEstimatorModal" size="lg" isOpen={modalDisplayFlag} toggle={() => handleModalDisplay(false)}>
                    <div className="modal-header">
                        <button
                            className="close"
                            type="button"
                            onClick={() => handleModalDisplay(false)}
                        >
                            <span>×</span>
                        </button>
                        <h5
                            className="modal-title text-center"
                            id="exampleModalLabel"
                        >
                            Maximum Reimbursement Rates
                        </h5>
                    </div>
                    <div className="modal-body">
                        <table cellPadding="5px" border="1" width="100%">
                            <thead>
                                <tr>
                                    <th>Garment</th>
                                    <th>Standard</th>
                                    <th>Plus</th>
                                    <th>Premium</th>
                                </tr>
                            </thead>
                			<tbody>
                                <tr>
                                    <td >2 socks (pair)</td>
                                    <td >$6.00</td>
                                    <td >$12.00</td>
                                    <td >$18.00</td>
                                </tr>
                                <tr>
                                    <td >Athletic shorts</td>
                                    <td >$25.00</td>
                                    <td >$40.00</td>
                                    <td >$75.00</td>
                                </tr>
                                <tr>
                                    <td >Blouse</td>
                                    <td >$30.00</td>
                                    <td >$75.00</td>
                                    <td >$125.00</td>
                                </tr>
                                <tr>
                                    <td >Boxers</td>
                                    <td >$12.00</td>
                                    <td >$20.00</td>
                                    <td >$40.00</td>
                                </tr>
                                <tr>
                                    <td >Bra</td>
                                    <td >$25.00</td>
                                    <td >$45.00</td>
                                    <td >$80.00</td>
                                </tr>
                                <tr>
                                    <td >Dress/Capri Pants</td>
                                    <td >$25.00</td>
                                    <td >$100.00</td>
                                    <td >$175.00</td>
                                </tr>
                                <tr>
                                    <td >Hoody</td>
                                    <td >$45.00</td>
                                    <td >$85.00</td>
                                    <td >$125.00</td>
                                </tr>
                                <tr>
                                    <td >Jeans</td>
                                    <td >$40.00</td>
                                    <td >$125.00</td>
                                    <td >$220.00</td>
                                </tr>
                                <tr>
                                    <td >Leggings</td>
                                    <td >$20.00</td>
                                    <td >$45.00</td>
                                    <td >$100.00</td>
                                </tr>
                                <tr>
                                    <td >Panty</td>
                                    <td >$10.00</td>
                                    <td >$15.00</td>
                                    <td >$25.00</td>
                                </tr>
                                <tr>
                                    <td >PJ Pants</td>
                                    <td >$20.00</td>
                                    <td >$30.00</td>
                                    <td >$40.00</td>
                                </tr>
                                <tr>
                                    <td >Polo</td>
                                    <td >$35.00</td>
                                    <td >$75.00</td>
                                    <td >$125.00</td>
                                </tr>
                                <tr>
                                    <td >Sm. Shorts</td>
                                    <td >$40.00</td>
                                    <td >$70.00</td>
                                    <td >$100.00</td>
                                </tr>
                                <tr>
                                    <td >Sweater</td>
                                    <td >$40.00</td>
                                    <td >$90.00</td>
                                    <td >$150.00</td>
                                </tr>
                                <tr>
                                    <td >Sweats</td>
                                    <td >$25.00</td>
                                    <td >$45.00</td>
                                    <td >$85.00</td>
                                </tr>
                                <tr>
                                    <td >T-shirt</td>
                                    <td >$15.00</td>
                                    <td >$30.00</td>
                                    <td >$60.00</td>
                                </tr>
                                <tr>
                                    <td >Tank top/undershirt</td>
                                    <td >$10.00</td>
                                    <td >$25.00</td>
                                    <td >$50.00</td>
                                </tr>
                                <tr>
                                    <td >Towel</td>
                                    <td >$16.00</td>
                                    <td >$25.00</td>
                                    <td >$45.00</td>
                                </tr>
                                <tr>
                                    <td >Velvet sweat pants</td>
                                    <td >$30.00</td>
                                    <td >$80.00</td>
                                    <td >$150.00</td>
                                </tr>
                        </tbody></table>           
                            <tfoot>
                                <tr>
                                    <td colSpan="4">
                                        <p><strong>Any items lost or damaged while in our care will be reimbursed at replacement value up to the rates listed.</strong></p>
                                    </td>
                                </tr>
                            </tfoot>
                    </div>
                    <div className={`modal-footer`}>
                        <div className="right-side">
                            <Button
                            className="btn-link"
                            color="default"
                            type="button"
                            onClick={() => handleModalDisplay(false)}
                            >
                            Close
                            </Button>
                        </div>
                        
                    </div>
                </Modal>
            </>
        );
    }
}

export default GarmentInsuranceModal