import React,{Component} from "react";

// reactstrap components
import {
    Button,
    Modal,
} from "reactstrap";
  

class SignupProcessingModal extends Component{
    
    render(){
        const {
            handleCloseOfModalWindow, 
            displayModal,
            user
        } = this.props;

        const productType = user.laundry.type || "student";
        
        const {
            paymentProcessedFlag,
            createAccountFlag,
            emailNotificationFlag,
            loggingYouInFlag, 
        } = user.processingSteps.laundry[productType];

        const processingIconOnSuccess = <i className="fa fa-check fa-4" />;
        const processingIconDefault = <div className="uil-reload-css reload-small mr-1">
                                        <div />
                                      </div>;
        

        const paymentProcessedButtonCSS = (paymentProcessedFlag) ?  "btn-success":"";
        const paymentProcessedIconDisplay = (paymentProcessedFlag) ?  processingIconOnSuccess :processingIconDefault;
        
        const createAccountButtonCSS = (createAccountFlag) ?  "btn-success":"";
        const createAccountIconDisplay = (createAccountFlag) ?  processingIconOnSuccess :processingIconDefault;
        
        const emailNotificationButtonCSS = (emailNotificationFlag) ?  "btn-success":"";
        const emailNotificationIconDisplay = (emailNotificationFlag) ?  processingIconOnSuccess :processingIconDefault;
        
        const loggingYouInButtonCSS = (loggingYouInFlag) ?  "btn-success":"";
        const loggingYouInIconDisplay = (loggingYouInFlag) ?  processingIconOnSuccess :processingIconDefault;
        
        return(
            <Modal
                isOpen={displayModal}
                toggle={() => handleCloseOfModalWindow(false)}
                className="modal-register"
              >
                <div className="modal-header no-border-header text-center">
                  <button
                    className="close d-none"
                    type="button"
                    onClick={() => handleCloseOfModalWindow(false)}
                  >
                    ×
                  </button>
                  <h6 className="text-muted">Please Wait...</h6>
                  
                </div>
                <div className="modal-body">
                    <Button block className= {`btn-round text-left ${paymentProcessedButtonCSS}`} color="default">
                        {paymentProcessedIconDisplay}
                        Processing Payment Information
                    </Button>
                    <Button block className= {`btn-round text-left ${createAccountButtonCSS}`} color="default">
                        {createAccountIconDisplay}
                        Creating your account
                    </Button>
                    <Button block className= {`btn-round text-left ${emailNotificationButtonCSS}`} color="default">
                        {emailNotificationIconDisplay}
                        Sending an Email Notification
                    </Button>
                    <Button block className= {`btn-round text-left ${loggingYouInButtonCSS}`} color="default">
                        {loggingYouInIconDisplay}
                        Logging you in
                    </Button>
                </div>
                <div className="modal-footer no-border-footer" />
            </Modal> 
        );     


    }
}

export default SignupProcessingModal;