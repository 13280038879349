import http from "./httpService";
import _ from "lodash";
import listOfSchoolsByRegionForSupplies from "../data/listOfSchoolsByRegionForSupplies";
import {renderError} from "../utils/errorUtils";

const apiSuppliesSettingEndpoint = "/suppliesSettings";
const apiSuppliesEndpoint = "/supplies";
const apiUserEndpoint = "/users";



async function getListOfCitiesForSupplies(){
    try{
          const {data:listOfCitiesForSupplies} = await http.get(`${apiSuppliesSettingEndpoint}/listOfCitiesForSupplies`);
          return listOfCitiesForSupplies;
    }catch(ex){
          renderError(ex);
    }
}

async function getListOfSchoolsByRegionForSupplies(){
    try{
        const {data:listOfSchoolsByRegionForSupplies} = await http.get(`${apiSuppliesSettingEndpoint}/listOfSchoolsByRegionForSupplies`);
        return listOfSchoolsByRegionForSupplies;
    }catch(ex){
            renderError(ex);
    }
}

async function getListOfSchoolsByUniversity(universityId){
    //console.log('getListOfSchoolsByUniversity');
    //console.log(universityId);
    const listOfSchoolsByRegionForSupplies = await getListOfSchoolsByRegionForSupplies();
    const filteredRegion = _.filter(listOfSchoolsByRegionForSupplies, {schools: [{universityId: universityId}] }); 

    let schoolOption = [
          { value: "", label: "Choose School", isDisabled: false },
          { value: "none", label: "None", isDisabled: false },
    ];

    //console.log(universityId);
    

    filteredRegion.forEach(r=>{
        r.schools.forEach(s=>{
            if(s.universityId === universityId){
                const school = {
                    value:s.id,
                    label:s.name
                } 
                schoolOption.push(school);    
            }
        })
    })
    //console.log(schoolOption);
    

    return schoolOption;
}

async function getListOfSchoolsByCity(city){
    //console.log('getListOfSchoolsByCity',city);
    //console.log(listOfSchoolsByRegionForSupplies);
    
    let schoolOption = [
          { value: "", label: " Choose School", isDisabled: false },
    ];

    listOfSchoolsByRegionForSupplies.forEach(r=>{
        r.schools.forEach(s=>{
            if(s.city === city){
                const school = {
                    value:s.id,
                    label:s.name
                } 
                schoolOption.push(school);    
            }
        })
    })
    console.log(schoolOption);
    

    return schoolOption;
}

/* this get list of supply order for the authenticated user */
async function getSupplyOrdersForUser(){
    const {data:returnData} = await http.get(`${apiUserEndpoint}/supplies/getSupplyOrdersForUser`);
    return returnData;
}

async function validateZipcodeForSupplies(formData){
    const {data:returnData} = await http.post(`${apiSuppliesEndpoint}/validateZipcodeForSupplies`,formData);
    return returnData;
}



export default {
    getListOfCitiesForSupplies,
    getListOfSchoolsByCity,
    getListOfSchoolsByRegionForSupplies,
    getListOfSchoolsByUniversity,
    getSupplyOrdersForUser,
    validateZipcodeForSupplies,
}