import React,{Component} from "react";
import Select from "react-select";
import moment from "moment";
import ReactDatetime from "react-datetime";
import _ from "lodash";
import laundryService from "services/laundryService"

// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";




// reactstrap components
import {
    Button,
    Row,
    Col,
    FormGroup, 
    Modal, 
  } from "reactstrap";

class ResidentialCustomerAccountLaundryCancelRecurringScheduleModal extends Component{
    constructor(){
        super();
        this.state = {
            data:{},
            errors:{},
            
            
        }
    }

    getRecurringWeeklyScheduleDisplayText = (laundryOrder)=>{
        let displayText = "";

        const {laundryOrder:laundryOrderDb} = laundryOrder;
        const now = moment();
        const {
            pickup_dayname:pickupDayNumerical,
            pickup_starttime:pickupStartTime,
            pickup_endtime:pickupEndTime,
            delivery_dayname:deliveryDayNumerical,
            delivery_starttime:deliveryStartTime,
            delivery_endtime:deliveryEndTime,
        } = laundryOrderDb;
        
        if(pickupDayNumerical && deliveryDayNumerical){
            const pickupDay = laundryService.getPickupDeliveryDay(pickupDayNumerical);
            const deliveryDay = laundryService.getPickupDeliveryDay(deliveryDayNumerical);

            const pickupStartTimeMoment = moment(`${now.format('YYYY-MM-DD')} ${pickupStartTime}`);
            const pickupEndTimeMoment = moment(`${now.format('YYYY-MM-DD')} ${pickupEndTime}`);
            const deliveryStartTimeMoment = moment(`${now.format('YYYY-MM-DD')} ${deliveryStartTime}`);
            const deliveryEndTimeMoment = moment(`${now.format('YYYY-MM-DD')} ${deliveryEndTime}`);

            
            displayText = <p>
                <strong>Pickup Day and Time:</strong> {`Every ${pickupDay} between ${pickupStartTimeMoment.format('ha')} - ${pickupEndTimeMoment.format('ha')}`}.<br/>
                <strong>Delivery Day and Time:</strong> {`Every ${deliveryDay} between ${deliveryStartTimeMoment.format('ha')} - ${deliveryEndTimeMoment.format('ha')}`}
            </p>
        }
        
        
        
        

        return displayText;

    }

    

    /*  Send state data to the parent laundry detail */
    saveSchedule = ()=>{
        const data = {...this.state.data}
        this.props.handleSaveModifyRecurringSchedule(data);
        return;
    }

    render(){
        
        const { 
                handleModalDisplayFlag,
                modalDisplayFlag,
                laundryOrder,
                user 
        } = this.props;

        let recurringWeeklyScheduleDisplayText = ""
        if(laundryOrder){
            recurringWeeklyScheduleDisplayText = this.getRecurringWeeklyScheduleDisplayText(laundryOrder);

        }

        
        return(
            <Modal size="lg" isOpen={modalDisplayFlag} toggle={() =>handleModalDisplayFlag(false)}>
                <div className="modal-header">
                    <button
                        className="close"
                        type="button"
                        onClick={() =>handleModalDisplayFlag(false)}
                    >
                        <span>×</span>
                    </button>
                    <h5
                        className="modal-title text-center"
                        id="exampleModalLabel"
                    >
                        Cancel Weekly Pickup/Delivery Schedule
                    </h5>
                </div>
                <div className="modal-body">
                    {/* Recurring Properties */}                    
                    <Row>
                        <Col>
                            <h6>Your current weekly schedule:</h6>
                            {recurringWeeklyScheduleDisplayText}
                        </Col>
                    </Row>
                    {/* End of Recurring Properties */}
                </div>
                <div className={`modal-footer`}>
                    <div className="left-side">
                        <Button
                        className="btn-link"
                        color="default"
                        type="button"
                        onClick={() => handleModalDisplayFlag(false)}
                        >
                        Close Window
                        </Button>
                    </div>
                    <div className="divider" />
                    <div className="right-side">
                        <Button className="btn-link" color="danger" type="button" onClick={()=>this.saveSchedule()}>
                            Yes, Cancel Weekly Schedule
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
};

export default ResidentialCustomerAccountLaundryCancelRecurringScheduleModal;