import React,{Component} from "react";
import accounting from "accounting";
import _ from "lodash";
import moment from "moment";
import Joi from "@hapi/joi";

import ProcessingModal from "./ProcessingModal";
import ApplicationConstant from "components/Common/ApplicationConstants";
import applicationConstantUtility from "../../../../utils/applicationConstants";

import FormErrorWidget from "../../../Common/FormErrorWidget";
import {scrollTop} from '../../../../utils/helperUtils';
import {renderError} from '../../../../utils/errorUtils';
import customToast from '../../../Common/CustomToast'

import GiftCardRedemptionModal from "../../GiftCard/GiftCardRedemptionModal"

import UserService from "../../../../services/userService";


// reactstrap components
import {
    Badge,
    Button,
    Container,
    Card,
    CardBody,
    CustomInput,
    Row,
    Col,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    
  } from "reactstrap";

class StudentReviewScreen extends Component{
    validationSchema = Joi.object({
      agreeTermsAndConditions:Joi.boolean().valid(true).truthy('true').falsy('false').sensitive().label('Terms and Conditions'),

    })
    
    validationCouponCodeSchema = Joi.object({
      couponCode:Joi.string().required().label('Coupon Code'), 
      couponType:Joi.allow(""),
      couponBalance:Joi.allow(""),
      amountToBeUsedForRedemption:Joi.allow(""),
    })

    errorMessages = {
      "agreeTermsAndConditions":{
          "any.only":"Please accept the terms and conditions",
      },
    }

    /*
    validationSchema = Joi.object({
      couponCode:Joi.string().required().label('Coupon Code'), 
      couponType:Joi.allow(""),
      couponBalance:Joi.allow(""),
      amountToBeUsedForRedemption:Joi.allow(""),
    })
    
    errorMessages = {}
    */ 

   constructor(){
       super();
       this.state = {
        couponProcessingFlag:false,
        giftCardRedemptionModalDisplayFlag:false,
        data:{
          couponCode:"",
          couponType:"",
          giftCardBalance:"",
          giftCardAmountToBeUsedForRedemption:"", //This is basically the amount a user enters or it uses the balance
          agreeTermsAndConditions:false,
        },
        errors:{}
       }
   }

   componentDidMount(){
      scrollTop('sectionArea');
   }

    /*
     For some reason the page scrolls to the product area and user cannot see the errors
     I am just setting a timer to it scrolls back to the top if the serverError props gets updated
   */
    componentDidUpdate(prevProps){
        const {serverErrors} = this.props;
        const {serverErrors:serverErrorsPrev} =prevProps;
        if(!_.isEqual(serverErrors,serverErrorsPrev)){
          setTimeout(()=>{
            scrollTop('sectionArea');
          },1000)
        }
    }

   
   displayRedemptionTextInModal = ()=>{
      return(
      <p>We will apply the amount you would like to redeem as a credit towards the invoice once we have picked up the items.</p>
      )
    }

    displayDiscountApplied = ()=>{
      const {user} = this.props;
      const product = "storage";
      const userType = user[product].type; //student, residential
      //console.log(ApplicationConstant.SEMESTER_PLAN);
      const {totalCost,discount} = user[product];
      
      let [discountDisplayLabel,discountAppliedDisplayText ]= ["",""];
      
      if(discount.voucherType === "GIFT_CARD"){
         discountDisplayLabel = "Gift card entered"
         discountAppliedDisplayText = `${accounting.formatMoney(discount.discountValue)}`;
      }else if(discount.voucherType === applicationConstantUtility.DISCOUNT_TYPE_COUPON){
         discountDisplayLabel = "Coupon code entered";
         discountAppliedDisplayText = `${discount.discountValue}% will be applied towards your first invoice`;
      }
         
      

      return <>
            <Row className="mt-2">
                <Col xs="4">
                  {discountDisplayLabel}
                </Col>
                <Col xs="8">
                  {
                    <>
                    {discount.discountCode} {" "}
                    <Badge color="success" pill>
                      Valid
                    </Badge>
                    </> 
                  }  
                </Col>
            </Row>
            <Row className="mt-2">
                <Col xs="4">
                  Discounts Applied
                </Col>
                <Col xs="8">
                  {discountAppliedDisplayText}  
                </Col>
            </Row>
            </>
   }

  displayTotalAmount = ()=>{
    const {user} = this.props;
    const product = "storage";
    const userType = user[product].type; //student, residential
    //console.log(ApplicationConstant.SEMESTER_PLAN);
    const {totalCost} = user[product];
    
    
    if(_.has(totalCost, ['finalTotal'])){
      let displayObject = {};
      if(totalCost.finalTotal >= 0)
        displayObject.displayLabel = "Total amount";
      else if(totalCost.finalTotal < 0)  
        displayObject.displayLabel = "Credit to be applied";
      
      displayObject.displayAmount  = accounting.formatMoney(totalCost.finalTotal);

      return    <Row className="mt-2">
                  <Col xs="4">
                    {displayObject.displayLabel}
                  </Col>
                  <Col xs="8">
                    {displayObject.displayAmount}  
                  </Col>
                </Row>

    }
    else
      return "";
     
  }

   getDeliveryAddress = ()=>{
    let address = "";
    const {user} = this.props;
    const product = "storage";
    const {deliveryAddress} = user[product];
    
    const {streetNumber,addressLine1, apartmentNumber,city,state,postalCode,isDormitory,dormitory} = deliveryAddress;
    const {name:dormitoryName,roomNumber}  = dormitory
    if(isDormitory === false && streetNumber)
      address = <span>{`${streetNumber}, ${addressLine1} ${apartmentNumber}`} <br/> {`${city}, ${state} - ${postalCode}`}</span>;
    else if(isDormitory === true && dormitoryName)
      address = <span>{`${dormitoryName}, ${roomNumber}`} <br/> {`${city}, ${state} - ${postalCode}`}</span>;
    
    return address;
   }

   getPickupAddress = ()=>{
    const {user} = this.props;
    const product = "storage";
    const {pickUpAddress} = user[product];
    const {streetNumber,addressLine1, apartmentNumber,city,state,postalCode,isDormitory,dormitory} = pickUpAddress;
    const {name:dormitoryName,roomNumber}  = dormitory
    let address = "";
    if(isDormitory === false)
      address = <span>{`${streetNumber}, ${addressLine1} ${apartmentNumber}`} <br/> {`${city}, ${state} - ${postalCode}`}</span>;
    else
      address = <span>{`${dormitoryName}, ${roomNumber}`} <br/> {`${city}, ${state} - ${postalCode}`}</span>;
      
    return address;
   }

   getStorageDeposit = ()=>{
      const {user} = this.props;
      const product = "storage";
      const userType = user[product].type; //student, residential
      const {pricingProperties} = user[product][userType];

      const {storageDeposit} = pricingProperties;
      return accounting.formatMoney(storageDeposit);
   }

   handleChange = ({currentTarget:input})=>{
    const errors = {...this.state.errors};
    const data = {...this.state.data};
    data[input.name] = input.value;
    this.setState({data,errors});
    
   } 
   
    handleCheckbox= ()=>{
        const data  = {...this.state.data};
        data.agreeTermsAndConditions = !data.agreeTermsAndConditions
        this.setState({data})
    }  


   handleEditButton = (step)=>{
    const { handleRedirectToReviewScreen } = this.props;
    handleRedirectToReviewScreen(true);
    
    let url = `/signup/storage/student/${step}`; 
    this.props.history.push(url);
   }


   handleSubmit = (e)=>{
      e.preventDefault();
        
      const errors =  this.validate(
        _.pick({...this.state.data}, ['agreeTermsAndConditions']),
        this.validationSchema
      );
      this.setState({errors: errors || {}});
      if(errors){scrollTop('sectionArea'); return;} 

      
      const {onClickOfPurchaseButtonFromReviewScreen} = this.props; 
      onClickOfPurchaseButtonFromReviewScreen();
    
   }

   handleGiftCardCreditFromRedemptionModal = ({giftCardAmountToBeUsedForRedemption})=>{
        const {onClickOfApplyCouponButtonFromReviewScreen} = this.props;
        const data  = {
          ...this.state.data,
          giftCardAmountToBeUsedForRedemption,
        }
        
        this.setGiftCardRedemptionModalDisplayFlag(false);
        this.setState({data})
        return onClickOfApplyCouponButtonFromReviewScreen(data);
    }
    
    /*
      Validate if a coupon code is entered
    */
    handleApplyCoupon = async (e)=>{
        try{
            e.preventDefault(); 
            const product = "storage";
            const {onClickOfApplyCouponButtonFromReviewScreen,user} = this.props;
            const userType = user[product].type; //student, residential
            const {totalCost} = user.storage;
            const {student:storageProperties} = user.storage;
            const isSemesterPlan = false;
          

            //client side errors will be caught in validate(), server side data error will be caught via try/catch
            //const errors =  this.validate();
            const errors =  this.validate(
              _.omit({...this.state.data}, ['agreeTermsAndConditions']),
              this.validationCouponCodeSchema
            );
            this.setState({errors: errors || {}});
            if(errors){scrollTop('sectionArea'); return;} 

            this.setState({couponProcessingFlag:true});
            
            let formDataObject = {
              ...this.state.data,
              product,
              productType:null, 
              userType:userType,
              student:{
                universityId:user.school.university.university_id,
                campusId:user.school.id
              }
            };

            /* Send data to server */
                const {
                  status:processingStatus,
                  message,
                  couponCodeReturnObject} = await UserService.validateCouponCode(formDataObject);
                
                this.setState({couponProcessingFlag:false});
                if(processingStatus === true){
                  const {couponType,giftCardBalance,couponDetails} = couponCodeReturnObject;
                  const data = {
                    ...this.state.data,
                    couponType,
                    giftCardBalance,
                    couponDetails,
                  }
                  
                  if(couponType === applicationConstantUtility.DISCOUNT_TYPE_GIFTCARD){
                    const launchGiftcardRedemptionModal = (giftCardBalance > totalCost.finalTotal) ? true:false;

                    if(launchGiftcardRedemptionModal){
                      //By the pound plan 
                      //We need to have them enter a amount in a modal window that they would like to 
                      //We ask the user how much they want to redeem in order to updateamountToBeUsedForRedemption
                      
                      //Launch modal -- PENDING
                      this.setState({data});
                      this.setGiftCardRedemptionModalDisplayFlag(true);
                    }
                    else{
                      //Semester plan
                      data.giftCardAmountToBeUsedForRedemption = giftCardBalance;
                      this.setState({data});
                      return onClickOfApplyCouponButtonFromReviewScreen(data);
                    }

                  }
                  else if(couponType === applicationConstantUtility.DISCOUNT_TYPE_COUPON){
                    this.setState({data});
                    return onClickOfApplyCouponButtonFromReviewScreen(data);
                  }

                  //Need to update signup construct if valid  
                    

                }
                else
                    customToast.error(message);
            /* End of Send data to server */
            
            
            return;
        }catch(ex){
            //console.log(ex);
            this.setState({couponProcessingFlag:false});
            /*
            400 signals validation errors from the server
            renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop('sectionArea'); return;}
            }
            else
            renderError(ex);
        }
            

    }

    isCouponCodeEntered = ()=>{

        const {user} = this.props;
        const product = "storage";
        const userType = user[product].type; //student, residential
        //console.log(ApplicationConstant.SEMESTER_PLAN);
        const {totalCost,discount} = user.storage;
        return (discount.discountCode !== "") ? true:false;
          

    }

    setGiftCardRedemptionModalDisplayFlag = (flag)=>{
        this.setState({
            giftCardRedemptionModalDisplayFlag:flag    
        })
    }

    validate=  (data,validationSchema)=>{
          const options = {abortEarly:false,allowUnknown:true};
          //console.log(this.state.data);
          console.log(this.validationSchema.validate(this.state.data,options))
          let {error:errorListFromJoi} = validationSchema.validate(data,options);
          console.log(errorListFromJoi);
          if( errorListFromJoi === undefined) return null;

          const errorList = {};
          if(errorListFromJoi){
              errorListFromJoi.details.map(
                  function(errorObject){
                      //console.log(errorObject);
                      if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                          const key = errorObject.context.key;
                          const joiType = errorObject.type;
                          //console.log(this.errorMessages);
                          if(_.has(this.errorMessages, [key, joiType]))
                              return errorList[errorObject.path[0]] = this.errorMessages[key][joiType];
                          else
                              return errorList[errorObject.path[0]] = errorObject.message;
                      }
                      else
                        return errorList[errorObject.path[0]] = errorObject.message;
                  }.bind(this)
              );
          }
          
          const errors = {...errorList} 
          
          return Object.keys(errors).length > 0 ? errors:null;
    }

   
   render(){
       const {onClickOfBackButtonFromReviewScreen,user,serverErrors} = this.props;
       const {couponProcessingFlag,giftCardRedemptionModalDisplayFlag,data,errors} = this.state;
       const {agreeTermsAndConditions,giftCardBalance} = data;
       const {paymentInformation} = user;
       const product = "storage";
       const userType = user[product].type; //student, residential
       const {totalCost} = user[product];
       const {student:productProperties} = user[product];
       const pickupAddress = this.getPickupAddress();
       const deliveryAddress = this.getDeliveryAddress();
       
       return(
            <>
            <Container id="mainContentArea">
                <Row className="title-row justify-content-between">
                    <Col md="4">
                      <h3 className="shop">Signup for Storage</h3>
                    </Col>
                    <Col  md="5">
                      <div className="text-md-right d-none">
                          <span className="text-muted">Step 5</span>
                          <Button color="link">
                          <i className="fa fa-shopping-cart" /> Review your selection
                          </Button>
                      </div>
                    </Col>
                </Row> 
                <Row className="title-body-row">
                    <Col md="7">
                        <Card className="card-plain bg-white p-2 rounded-lg">
                            <CardBody>
                                {/*Error display */}
                                {!_.isEmpty(errors )&&
                                    <FormErrorWidget
                                        errors={errors}
                                    />
                                }
                                {/* End of Error display */}
                                
                                {/*Server Error display */}
                                {!_.isEmpty(serverErrors) &&
                                    <FormErrorWidget
                                        errors={serverErrors}
                                    />
                                }
                                {/* End of Server Error display */}
                                <div>
                                    <h6 className="card-category">
                                        <span className="text-left">
                                          Primary Information
                                        </span>
                                        <span className="float-right text-right cursor text-info"
                                          onClick={()=>this.handleEditButton("enter-student-information")}
                                        >
                                          <i className="fa fa-edit"></i> Edit
                                        </span>
                                    </h6>
                                    <hr/>

                                    <Row>
                                      <Col xs="3">
                                        Name
                                      </Col>
                                      <Col xs="8">
                                        {`${user.firstName} ${user.lastName}`}
                                      </Col>
                                    </Row>

                                    <Row className="mt-2">
                                      <Col xs="3">
                                        School
                                      </Col>
                                      <Col xs="8">
                                        {`${user.school.name}`}
                                      </Col>
                                    </Row>

                                    <Row className="mt-2">
                                      <Col xs="3">
                                        Email
                                      </Col>
                                      <Col xs="8">
                                        {`${user.email}`}
                                      </Col>
                                    </Row>

                                    <Row className="mt-2">
                                      <Col xs="3">
                                        Cell Phone
                                      </Col>
                                      <Col xs="8">
                                        {`${user.cellPhone}`}
                                      </Col>
                                    </Row>

                                    <Row className="mt-2">
                                      <Col xs="3">
                                        Home Phone
                                      </Col>
                                      <Col xs="8">
                                        {`${user.homePhone}`}
                                      </Col>
                                    </Row>
                                    
                                </div>
                            </CardBody>
                        </Card>
                        
                    </Col>
                    <Col md="5">
                        <Card className="card-plain bg-white p-2 rounded-lg">
                            <CardBody>
                                <div>
                                     <h6 className="card-category">
                                        <span className="text-left">
                                          Login Information
                                        </span>
                                        <span className="float-right text-right cursor text-info"
                                          onClick={()=>this.handleEditButton("enter-login-payment-information")}
                                        >
                                          <i className="fa fa-edit"></i> Edit
                                        </span>
                                    </h6>
                                    <hr/>
                                    <Row>
                                      <Col xs="4">
                                        Username
                                      </Col>
                                      <Col xs="8">
                                        {`${user.username}`}
                                      </Col>
                                    </Row>
                                    <Row  className="mt-2">
                                      <Col xs="4">
                                        Password
                                      </Col>
                                      <Col xs="8">
                                        xxxxxxxxx
                                      </Col>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>    
                </Row>

                <Row className="title-body-row">
                    <Col md="7">
                        <Card className="card-plain bg-white p-2 rounded-lg">
                            <CardBody>
                                <div>
                                    <h6 className="card-category">
                                        <span className="text-left">
                                          Your Storage Product
                                        </span>
                                        <span className="float-right text-right cursor text-info"
                                          onClick={()=>this.handleEditButton("select-semester-pricing")}
                                        >
                                          <i className="fa fa-edit"></i> Edit
                                        </span>
                                    </h6>
                                    <hr/>
                                    <Row>
                                      <Col xs="4">
                                        Semester
                                      </Col>
                                      <Col xs="8">
                                        {productProperties.semester.semester}
                                      </Col>
                                    </Row>

                                    <Row className="mt-2">
                                      <Col xs="4">
                                        Deposit
                                      </Col>
                                      <Col xs="8">
                                      {this.getStorageDeposit()}
                                      </Col>
                                    </Row>

                                    {
                                      this.isCouponCodeEntered() && 
                                      this.displayDiscountApplied()  
                                      
                                    }

                                    { !this.isCouponCodeEntered() &&  
                                      <Row className="mt-2">
                                          <Col xs="4">
                                            Enter Coupon or Gift Card Code
                                          </Col>
                                          <Col xs="4">
                                              <Input 
                                                  type="text" 
                                                  name="couponCode" 
                                                  id="couponCode" 
                                                  placeholder="" 
                                                  value={this.state.data.couponCode} 
                                                  onChange={this.handleChange}
                                                  autoComplete="off"
                                              />
                                          </Col>
                                          <Col xs="4" className="mt-1">
                                              <Button className="btn-round" color="default" size="sm"
                                                onClick={this.handleApplyCoupon}
                                              >
                                                {
                                                  couponProcessingFlag &&
                                                  <div className="uil-reload-css reload-small mr-1">
                                                    <div />
                                                  </div>
                                                }
                                                
                                                Apply
                                              </Button>
                                          </Col>
                                      </Row>
                                    }

                                    { 
                                      this.displayTotalAmount()   
                                    }
                                </div> 

                                <div className="mt-3">
                                    <h6 className="card-category">
                                        <span className="text-left">
                                          Payment Information
                                        </span>
                                        <span className="float-right text-right cursor text-info"
                                          onClick={()=>this.handleEditButton("enter-login-payment-information")}
                                        >
                                          <i className="fa fa-edit"></i> Edit
                                        </span>
                                    </h6>
                                    <hr/>
                                    <Row className="mt-2">
                                      <Col xs="4">
                                        Credit Card Holder
                                      </Col>
                                      <Col xs="8">
                                        {paymentInformation.creditCardName}
                                      </Col>
                                    </Row>

                                    <Row className="mt-2">
                                      <Col xs="4">
                                        Credit Card Number
                                      </Col>
                                      <Col xs="8">
                                        xxxxxxxxxxx - { !_.isEmpty(paymentInformation.creditCardStripeToken.card) &&  paymentInformation.creditCardStripeToken.card.last4}
                                      </Col>
                                    </Row>

                                    <Row className="mt-2">
                                      <Col xs="4">
                                        Expiration Month - Year
                                      </Col>
                                      <Col xs="8">
                                        { !_.isEmpty(paymentInformation.creditCardStripeToken.card) && moment(`${paymentInformation.creditCardStripeToken.card.exp_year}-${paymentInformation.creditCardStripeToken.card.exp_month}-1`).format('MMMM')} - { !_.isEmpty(paymentInformation.creditCardStripeToken.card) &&  paymentInformation.creditCardStripeToken.card.exp_year}
                                      </Col>
                                    </Row>

                                    <Row className="mt-2">
                                      <Col xs="4">
                                        Amount be charged
                                      </Col>
                                      <Col xs="8">
                                        {(_.has(totalCost, ['finalTotal']) && totalCost.finalTotal > 0) ? accounting.formatMoney(totalCost.finalTotal):"$ 0.00"} 
                                      </Col>
                                    </Row>

                                    <Row className="mt-4">
                                        <Col xs="1" className="pt-1">
                                          <CustomInput
                                              type="checkbox"
                                              id="agreeTermsAndConditions"
                                              name="agreeTermsAndConditions"
                                              label=""
                                              value={agreeTermsAndConditions}
                                              onChange={()=>this.handleCheckbox()}
                                              checked={agreeTermsAndConditions}
                                          />
                                        </Col>
                                        <Col className="pl-0">
                                            I agree to the {' '}
                                            <a 
                                                href={`${process.env.REACT_APP_API_URL}/users/terms-and-conditions`}
                                                className="btn-link btn btn-info btn-sm pl-0"
                                                target="_blank"
                                            >
                                                Terms and Conditions
                                            </a>
                                        </Col>
                                    </Row>

                                    
                                </div>     

                                <FormGroup row className="mt-5  justify-content-between">
                                    <Col xs={{ size: 2}}>
                                        <Button 
                                        className="btn-rounded btn-danger"
                                        onClick={()=>onClickOfBackButtonFromReviewScreen()}
                                        >
                                        Back
                                        </Button>
                                    </Col>
                                    <Col xs={{ size: 6}} sm={{ size: 4}} className="text-right">
                                        <Button 
                                        disabled={false}
                                        className="btn-rounded btn-danger"
                                        onClick={this.handleSubmit}
                                        >
                                        Proceed to Purchase
                                        </Button>
                                    </Col>
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="5" className="order-first order-sm-first order-md-last">
                        <Card className="card-plain bg-white p-2 rounded-lg">
                            <CardBody>
                                <div>
                                     <h6 className="card-category">
                                        <span className="text-left">
                                          Pickup Address
                                        </span>
                                        <span className="float-right text-right cursor text-info"
                                          onClick={()=>this.handleEditButton("enter-student-information")}
                                        >
                                          <i className="fa fa-edit"></i> Edit
                                        </span>
                                    </h6>
                                    <hr/>
                                    <Row>
                                      <Col xs="12">
                                        {pickupAddress}
                                      </Col>
                                      
                                    </Row>
                                    
                                </div>
                            </CardBody>
                        </Card>

                        <Card className="card-plain bg-white p-2 rounded-lg">
                            <CardBody>
                                <div>
                                     <h6 className="card-category">
                                        <span className="text-left">
                                          Delivery Address
                                        </span>
                                        <span className="float-right text-right cursor text-info"
                                          onClick={()=>this.handleEditButton("enter-student-information")}
                                        >
                                          <i className="fa fa-edit"></i> Edit
                                        </span>
                                    </h6>
                                    <hr/>
                                    <Row>
                                      <Col xs="12">
                                        {(deliveryAddress === "") ? "Delivery address not entered":deliveryAddress}
                                      </Col>
                                      
                                    </Row>
                                    
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>       
            </Container>
            {/* Gift Card Redemption Generator Modal */}
            {
                <GiftCardRedemptionModal
                    modalName="giftCardRedemptionModal"
                    divAreaToScrollForError="giftCardRedemptionModal"
                    modalDisplayFlag={giftCardRedemptionModalDisplayFlag}
                    handleModalDisplay={this.setGiftCardRedemptionModalDisplayFlag}
                    handleSaveOperation = {this.handleGiftCardCreditFromRedemptionModal}
                    giftCardBalance={giftCardBalance}
                    displayRedemptionText={this.displayRedemptionTextInModal()}
                    
                />
            }  
            </>
       )
   }

}

export default StudentReviewScreen;