import React from "react";
import { Link } from "react-router-dom";

import auth from "../../services/authService";

// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  NavLink,
  Container,
  UncontrolledTooltip
} from "reactstrap";
// core components

function ShortNavbar(props) {
  //console.log(props);
  const {shoppingCart} = props.globalApp;
  const isCartEmpty = (shoppingCart.supplies.items.length === 0) ? true:false;
  
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 199 ||
        document.body.scrollTop > 199
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 200 ||
        document.body.scrollTop < 200
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  

  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className={classnames("fixed-top", navbarColor)}
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" to="/index" tag={Link}>
              <span style={{fontSize:"20px"}}><strong>Lazybones</strong></span>
              <img src={require("assets/mylazybones/img/logo.png")} className="d-none" style={{height:"30px",width:"160px"}} />
            </NavbarBrand>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className="mr-2" color="default" caret nav>
                  Laundry
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <UncontrolledDropdown className="dropdown-item">
                    <DropdownToggle
                      className="dropdown-item"
                      color="default"
                      tag="a"
                      caret
                    >
                      Students
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-danger">
                      <DropdownItem
                        to="/laundry/how-it-works/student" 
                        tag={Link}
                      >
                        How it works?
                      </DropdownItem>
                      <DropdownItem
                        to="/laundry/how-it-works/student/pricing" 
                        tag={Link} 
                      >
                        Locations & Pricing
                      </DropdownItem>
                      {
                        !auth.getCurrentUser() && 
                        <DropdownItem
                          to="/signup/laundry/student/select-school" 
                          tag={Link}
                        >
                          Signup
                        </DropdownItem>
                      }
                      <DropdownItem
                        to="/laundry/how-it-works/student/faq" 
                        tag={Link} 
                      >
                        FAQ's
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown className="dropdown-item">
                    <DropdownToggle
                      className="dropdown-item"
                      color="default"
                      tag="a"
                      caret
                    >
                      Residential
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-danger">
                      <DropdownItem
                        to="/laundry/how-it-works/residential" 
                        tag={Link}
                      >
                        How it works?
                      </DropdownItem>
                      <DropdownItem
                        to="/laundry/how-it-works/residential/pricing" 
                        tag={Link}
                      >
                        Locations & Pricing
                      </DropdownItem>
                      {
                        !auth.getCurrentUser() && 
                        <DropdownItem
                          to="/signup/laundry/residential/select-city" 
                          tag={Link}
                        >
                          Signup
                        </DropdownItem>
                      }
                      <DropdownItem
                        to="/laundry/how-it-works/residential/faq" 
                        tag={Link}
                      >
                        FAQ's
                      </DropdownItem>
                      

                      
                      
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown className="dropdown-item">
                    <DropdownToggle
                      className="dropdown-item"
                      color="default"
                      tag="a"
                      caret
                    >
                      Commercial
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-danger">
                      <DropdownItem
                        to="/laundry/how-it-works/commercial" 
                        tag={Link}
                      >
                        How it works?
                      </DropdownItem>
                      
                      
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  
                  
                </DropdownMenu>
              </UncontrolledDropdown>
              
             
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className="mr-2" color="default" caret nav>
                  Storage
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem
                    to="/storage/how-it-works/student" 
                    tag={Link}
                  >
                    How it works?
                  </DropdownItem>
                  <DropdownItem
                    to="/storage/how-it-works/student/pricing" 
                    tag={Link}
                  >
                    Locations & Pricing
                  </DropdownItem>
                  {
                    !auth.getCurrentUser() && 
                    <DropdownItem
                          to="/signup/storage/student/select-school" 
                          tag={Link}
                    >
                      Signup
                    </DropdownItem>
                  }
                  <DropdownItem
                    to="/storage/how-it-works/student/faq" 
                    tag={Link}
                  >
                    FAQ's
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <NavItem>
                <NavLink 
                    to="/supplies/" 
                    tag={Link}href="/supplies/"
                >
                  Supplies
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink to="/gift-cards/" tag={Link} >Gift Cards</NavLink>
              </NavItem>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className="mr-2 d-none" color="default" caret nav>
                  Signup
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/index" tag={Link}>
                    Laundry
                  </DropdownItem>
                  <DropdownItem to="/presentation" tag={Link}>
                    Storage
                  </DropdownItem>
                  <DropdownItem
                    href="https://demos.creative-tim.com/paper-kit-pro-react/#/documentation/introduction?ref=pkpr-multi-level-dropdown-navbar"
                    target="_blank"
                  >
                    Supplies
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <NavItem className="d-none">
                <NavLink href="/components/">Find our trucks</NavLink>
              </NavItem>
              
              {
                !auth.getCurrentUser() && 
                <NavItem>
                  <NavLink to="/login" tag={Link}>Login</NavLink>
                </NavItem>
              }

              {
                auth.getCurrentUser() && 
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle className="mr-2" color="default" caret nav>
                        <i aria-hidden={true} className="fa fa-user"></i> {auth.getCurrentUser().fullName}
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-danger" right>
                        <DropdownItem to={`/myaccount`} tag={Link}>
                            View Account Details
                        </DropdownItem>
                        <DropdownItem to="/myaccount/view-profile" tag={Link}>
                            View Profile
                        </DropdownItem>
                        <DropdownItem to="/myaccount/view-profile/update-credentials" tag={Link}>
                            Change Password
                        </DropdownItem>
                        <DropdownItem
                            to="/logout" 
                            tag={Link}
                        >
                            Logout
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
              }
              
              { !auth.getCurrentUser() && 
                !isCartEmpty &&  
                <NavItem>
                  <NavLink to="/shoppingCart" tag={Link}><i className="fa fa-shopping-cart"></i>{" "}My Cart</NavLink>
                </NavItem>
              }
              
                

              

              
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ShortNavbar;
