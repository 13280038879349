import React,{useEffect} from "react";
import _ from "lodash";
import {Elements} from 'react-stripe-elements';


// core components
import ShortNavbar from "../Navbars/ShortNavbar.js";
import SignupPageHeader from "../Headers/SignupPageHeader";
import FooterBlack from "../Footers/FooterBlack.js";

import LoginAndPaymentInformation from "./SignupSteps/Common/LoginAndPaymentInformation";
import ReviewScreen from "./SignupSteps/Supplies/CustomerReviewScreen";

import CustomerInformation from "./SignupSteps/Supplies/CustomerInformation";


const proceedToCustomerInformationStep = (props)=>{
    let proceed  = false;
    const {globalApp} = props; 
    const {shoppingCart} = globalApp;
    const {supplies} = shoppingCart;
    if(!_.isEmpty(supplies) && !_.isEmpty(supplies.items) && supplies.items.length > 0)
       proceed = true;

    return proceed;
}

const proceedToLoginAndPaymentInformation = (props)=>{
    let proceed  = false;
    const {user} = props;
    const { firstName,lastName,email } = user; 
   
    proceed  = (
        proceedToCustomerInformationStep(props) && 
        firstName !== "" && lastName !== "" && email !== ""
    ) ? true:false;
    
    return proceed;
}

const proceedToReviewScreen = (props)=>{
    let proceed  = false;
    
    const {user} = props;
    const { paymentInformation } = user; 
    const { creditCardStripeToken } = paymentInformation;

    proceed  = (
        proceedToCustomerInformationStep(props) && 
        proceedToLoginAndPaymentInformation(props) &&
        creditCardStripeToken !== ""
    ) ? true:false;
    
    return proceed;
}

const validateToRenderComponent = (props)=>{
    let proceed = false;
  
    const {user} = props;
    const {step} = user;
    
    switch(step){
        case "enter-customer-information":                     
            proceed = proceedToCustomerInformationStep(props);
            break;
        case "enter-login-payment-information":   
            proceed = proceedToLoginAndPaymentInformation(props);
            break;
        case "review-screen":                     
            proceed = proceedToReviewScreen(props);
            break;
        default:    
            proceed = false;
    }
  
    return proceed;
}

function renderComponent(props){
    const {user} = props;
    const {step} = user;
    let component = <CustomerInformation  {...props}  />
  
    switch(step){
        case "enter-customer-information":                     
            component= <CustomerInformation {...props}  />;
            break;
        case "enter-login-payment-information":   
            component= <Elements><LoginAndPaymentInformation {...props} /></Elements>;
            break;
        case "review-screen":                     
            component= <ReviewScreen {...props}  />;
            break;
        default:    
            component= <CustomerInformation {...props}  />;
    }
    return component;
}


const SignupSupplies = (props)=>{
   
    const {user} = props;
    //console.log(props);

    useEffect(() => {
        const proceed = validateToRenderComponent(props)
        if(!proceed) props.history.push("/supplies")
    });

    return (
        <>
        <ShortNavbar {...props} />
        <SignupPageHeader />
        <div className="main">
            <div id="sectionArea" className="section section-gray">
                {renderComponent(props)}
            </div>
        </div>
        <FooterBlack />
        </>
    );
    

}

export default SignupSupplies;