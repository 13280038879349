import React,{Component} from "react";
import _ from "lodash";

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Media,
    NavItem,
    NavLink,
    Nav,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    TabContent,
    TabPane,
    Container,
    Row,
    Col
  } from "reactstrap";


import applicationConstant from "../../utils/applicationConstants";

import MyAccountNavbar from "../../components/Navbars/MyAccountNavbar";
import SignupPageHeader from "../../components/Headers/SignupPageHeader";
import FooterBlack from "../../components/Footers/FooterBlack";

import ProfileLandingArea from "../../components/Consumer/MyAccount/Laundry/Profile"



import auth from "../../services/authService";

class ViewUserProfile extends Component{
    constructor(){
        super();
    }

    async componentDidMount(){
      try{
            let {accountType} = this.props.match.params;
            
            //Get the account type based on the logged in users
            if(accountType === undefined){
                const user = auth.getCurrentUser()
                if(!_.isEmpty(user)){
                    const { customerRole } = user;
                    accountType = customerRole;
                }
            }

            
      }
      catch(ex){
          //console.log(`My account: component did mount`)
      }
    }

    render(){
        const widgetView = this.props.match.params.widgetView || 'primary-info';
        
        return(
           <>
                <MyAccountNavbar {...this.props} />
                <div className="main">
                    <div className="section section-nude-gray"> 
                        <Container>
                            <Row className="title-row justify-content-between">
                                <Col xs="6" md="6">
                                <h3 className="shop">User Profile</h3>
                                </Col>
                                <Col xs="6" md="6">
                                <div className="text-right">
                                    
                                </div>
                                </Col>
                            </Row>
                            <Row className="title-body-row mt-1">
                                <Col md="12">
                                    <div id="description-areas">
                                        <ProfileLandingArea
                                            {...this.props}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        {/* info areas */}
                        </Container>
                    </div>
                </div> 
                
                <FooterBlack />
           
           </>
           
        );
    }
}

export default ViewUserProfile;