import React,{Component} from "react";


import {
    Button,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Label,
    Row,
    Col,
    FormGroup, 
    Modal, 
    Input, 
  } from "reactstrap";


class StorageInsuranceModal extends Component{
    constructor(){
        super();
        this.state = {
            
        }
    }

    render(){
        const {handleModalDisplay,modalDisplayFlag} = this.props;
        return(
            <>
                <Modal id="storageInsuranceModal" size="lg" isOpen={modalDisplayFlag} toggle={() => handleModalDisplay(false)}>
                    <div className="modal-header">
                        <button
                            className="close"
                            type="button"
                            onClick={() => handleModalDisplay(false)}
                        >
                            <span>×</span>
                        </button>
                        <h5
                            className="modal-title text-center"
                            id="exampleModalLabel"
                        >
                            Storage Insurance
                        </h5>
                    </div>
                    <div className="modal-body">
                        <ul>
                            <li>In the event of damage to or loss of an insured container, Lazybones <strong>reimburses</strong> the customer for either the full insurance value of the container, or the current cost of the damaged item(s) inside of the properly packed and protected storage container (whichever is less). The cost for storage services is not refundable. </li>
                            <li><strong>Storage Insurance covers</strong> the collective contents within (inside) a properly packed and protected storage container, not the storage/packing container itself.  </li>
                            <li><strong>Storage/Packing Containers</strong> (any kind) are not insurable, and no compensation is given for damage to these items.</li>
                            <li>Lazybones only considers the following objects to be "<strong>Acceptable Storage/Packing Containers:</strong> boxes, duffle bags, trunks, suitcases, and plastic bins/drawers."</li>
                            <li><strong>Lazybones automatically insures</strong> the contents within properly packed and protected storage containers <strong>for $50</strong>, at no additional cost to the customer. </li>
                            <li>The customer can opt to <strong>purchase additional insurance</strong> (above the $50, and up to $1,000) for the contents within any properly protected storage/packing container. We highly recommend purchasing additional insurance, especially if your storage/packing container contains valuable items. </li>
                            <li>You can purchase additional insurance, <strong>up to 14 days</strong> after you receive your FINAL RECEIPT, by calling or emailing Lazybones Customer Service.</li>
                            <li>Other <strong>items that are not insurable</strong> are; loose items that are not packed inside of an acceptable storage/packing container and/or protected properly and particle board furniture. We are happy to store these items, however no compensation will be given in the rare event of damage. These items are considered, "Store at your own risk." </li>
                            <li>Common examples of, <strong>"Store at your own risk"</strong> items include fans (not packed inside of an acceptable storage container), lamps (not packed inside of an acceptable storage container), loose items placed inside of a hamper, bean bag chairs, fuzzy chairs etc.</li>
                            <li>In the rare event of damage/loss of a properly packed/protected, and insured storage container, the customer must <strong>contact Lazybones within 48 hours</strong> of their storage delivery date, and all packaging materials (i.e., packing container, bubble wrap, stretch wrap etc.) must be kept and inspected before compensation for damaged items can be made.</li>
                            <li>Please remember, compensation is only given for the insured value of the damaged/lost containers, and the <strong>costs for storage services are not refundable.</strong></li>
                            
                        </ul>
                       
                    </div>
                    <div className={`modal-footer`}>
                        <div className="right-side">
                            <Button
                            className="btn-link"
                            color="default"
                            type="button"
                            onClick={() => handleModalDisplay(false)}
                            >
                            Close
                            </Button>
                        </div>
                        
                    </div>
                </Modal>
            </>
        );
    }
}

export default StorageInsuranceModal