import React,{Component} from "react";
import Select from "react-select";
import moment from "moment";
import Datetime from "react-datetime";
import ReactDatetime from "react-datetime";
import _ from "lodash";
import laundryService from "services/laundryService"

// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";

import FormErrorWidget from "../../../../Common/FormErrorWidget";
import {scrollTop} from '../../../../../utils/helperUtils';


// reactstrap components
import {
    Button,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    FormGroup, 
    Modal, 
    Input, 
    UncontrolledTooltip,
    
  } from "reactstrap";

class ResidentialCustomerAccountLaundryModifyOneTimeUpcomingPickupModal extends Component{
    recurringPickupDayOptions = [
        { value: "", label: " Select Day", isDisabled: true },
        
    ];

    recurringDeliveryDayOptions = [
        { value: "", label: " Select Day", isDisabled: true },
        
        
    ];

    constructor(){
        super();
        this.state = {
            data:{
                pickUpType:"one-time", //recurring, one-time, one-time-asap
            
                oneTimePickupDay:"",
                oneTimePickUpTime:"",
                oneTimePickUpTimeId:"",
                oneTimePickupTimeslotList:[],
                
                oneTimeDeliveryDay:"",
                oneTimeDeliveryTime:"",
                oneTimeDeliveryTimeId:"",
                oneTimeDeliveryTimeslotList:[],

                
            },
            errors:{},
            
            
        }
    }

    componentDidUpdate(){
        const data = {...this.state.data};
        const { laundryOrder } = this.props
        if( data.asapPickupDay === "" && 
            laundryOrder && 
            laundryOrder.laundryOneTimeDateList && 
            laundryOrder.laundryOneTimeDateList.pickupDates.length)
        {
            this.setNextAsapDateAndTimeslotForPickup();
        }
    }

    getUpcomingPickupDisplayText = (laundryOrder)=>{
        let displayText = "";
    
        if(!_.isEmpty(laundryOrder) && !_.isEmpty(laundryOrder.nextPickupDeliverySchedule.pickup)){
            const {pickup,delivery} = laundryOrder.nextPickupDeliverySchedule;
            const pickupDate = moment(pickup.date);
            const deliveryDate = moment(delivery.date);
           
            displayText = <p>
            <strong>Pickup Day and Time:</strong> {`${pickupDate.format('dddd (MMM Do)')} between ${pickup.startTime} - ${pickup.endTime}`}.<br/>
            <strong>Delivery Day and Time:</strong> {`${deliveryDate.format('dddd (MMM Do)')} between ${delivery.startTime} - ${delivery.endTime}`}
           </p>
            
        }
    
        return displayText;
    }

    handleChange = ({currentTarget:input})=>{
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[input.name] = input.value;
        this.setState({data,errors});
        
    }
    
    

    handleOneTimeDeliveryDay = (value)=>{
        const data = {...this.state.data}
        data.oneTimeDeliveryDay = value;

        const { laundryOrder } = this.props
        if(laundryOrder){
            const {laundrySchedules} = laundryOrder;
            const deliveryDay = value;
            const deliveryTimeslotList = laundryService.generateLaundryOneTimeDeliveryTimeslotDropdownList(deliveryDay,laundrySchedules);
            data.oneTimeDeliveryTimeslotList = deliveryTimeslotList;
        }

        this.setState({data});
    }

    handleOneTimePickupDay = (value)=>{
        const data = {...this.state.data}
        data.oneTimePickupDay = value;
        data.oneTimePickUpTime = "";
        data.oneTimePickUpTimeId = "";
        data.oneTimeDeliveryDay = "";
        
        const { laundryOrder } = this.props
        if(laundryOrder){
            const {laundrySchedules} = laundryOrder;
            const pickupDay = value;
            const pickupTimeslotList = laundryService.generateLaundryOneTimePickupTimeslotDropdownList(pickupDay,laundrySchedules);
            //console.log(pickupTimeslotList);
            data.oneTimePickupTimeslotList = pickupTimeslotList;
        }
        
        this.setState({data});
    }

    

    setOneTimeDeliveryTime = (timeslotObject)=>{
        const data = {...this.state.data}
        data.oneTimeDeliveryTime = timeslotObject.formattedTime;
        data.oneTimeDeliveryTimeId = timeslotObject.id
        this.setState({data}) 
    }
    
    setOneTimePickupTime = (timeslotObject)=>{
        const data = {...this.state.data}
        data.oneTimePickUpTime = timeslotObject.formattedTime;
        data.oneTimePickUpTimeId = timeslotObject.id
        this.setState({data}) 
    }

    /*  Send state data to the parent laundry detail */
    saveSchedule = ()=>{
        const errors = this.validateLocal();
        if(!_.isEmpty(errors)){
            this.setState({errors});
            scrollTop("residentialLaundryOnetimeModifyScheduleModal");
            return
        }
        this.setState({errors:{}})
        const data = {...this.state.data}
        this.props.handleSaveOperation(data);
        return;
    }

    validateLocal = ()=>{
        const {data} = this.state;
        let errors  = "";

        const {oneTimePickUpTimeId,oneTimeDeliveryTimeId} = data;

        /* Plan based validation */
        if(oneTimePickUpTimeId === "" || oneTimeDeliveryTimeId === ""){
            errors = {
                deliverySchedule:"Please select pickup & delivery timeslots to proceed.",
            }
        }

        return errors;
    }

    render(){
        let oneTimePickupScheduleButtonDisplayCSS = "mt-2 d-none" , oneTimePickupAsapScheduleButtonDisplayCSS = "mt-2 d-none"; 
        const {data,errors} = this.state
        const { 
            pickUpType,
            oneTimePickupDay,
            oneTimePickUpTime,
            oneTimePickUpTimeId,
            oneTimePickupTimeslotList,
            oneTimeDeliveryDay,
            oneTimeDeliveryTime, 
            oneTimeDeliveryTimeId,
            oneTimeDeliveryTimeslotList,
        } = data
        
        const { 
                handleModalDisplayFlag,
                //listOfOneTimePickupDates,
                modalDisplayFlag,
                laundryOrder,
                user 
        } = this.props

        let listOfOneTimePickupDates = [],listOfOneTimeDeliveryDates = [], upcomingPickupDisplayText = "";
        
        if(laundryOrder){
            const {laundryOneTimeDateList, laundrySchedules, laundryPlanType} = laundryOrder;
            listOfOneTimePickupDates = laundryOneTimeDateList.pickupDates.map(d => moment.utc(d));
            listOfOneTimeDeliveryDates = laundryOneTimeDateList.deliveryDates.map(d => moment.utc(d));

            if(oneTimePickupDay){
                //Filter out delivery dates to only the delivery date for that day
                listOfOneTimeDeliveryDates = [oneTimePickupDay.clone().add(1,'days')];
            }
            
            upcomingPickupDisplayText = this.getUpcomingPickupDisplayText(laundryOrder);
            
            if(laundryPlanType === laundryService.BY_THE_POUND)
              oneTimePickupScheduleButtonDisplayCSS = oneTimePickupAsapScheduleButtonDisplayCSS = "mt-2";
        }
             
        

        const displayOneTimeSettingPropertiesAreaCSS = (pickUpType === "one-time" || pickUpType === "one-time-asap") ? "":"d-none";
        
        


        return(
                <Modal id="residentialLaundryOnetimeModifyScheduleModal" size="lg" isOpen={modalDisplayFlag} toggle={() => handleModalDisplayFlag(false)}>
                    <div className="modal-header">
                    <button
                        className="close"
                        type="button"
                        onClick={() => handleModalDisplayFlag(false)}
                    >
                        <span>×</span>
                    </button>
                    <h5
                        className="modal-title text-center"
                        id="exampleModalLabel"
                    >
                        Change Upcoming Laundry Pickup
                    </h5>
                    </div>
                    <div className="modal-body">
                        {/*Error display */}
                        {!_.isEmpty(errors) &&
                                <FormErrorWidget
                                    errors={errors}
                                />
                            }
                        {/* End of Error display */}

                        <Row>
                            <Col>
                                <h6>Your upcoming pickup details:</h6>
                                {upcomingPickupDisplayText}
                            </Col>
                        </Row>
                        
                        
                        {/* One-time pickup & Delivery */}
                        <Row className={displayOneTimeSettingPropertiesAreaCSS}>
                            <Col>
                                <hr/>
                                <h6>Select one-time pickup/delivery date & time?</h6>
                                <Row>
                                    <Col md="6">
                                        <Row>
                                            <Col sm="4" className="mt-2">
                                                Pickup Day
                                            </Col>
                                            <Col sm="8">
                                                <FormGroup>
                                                    <InputGroup 
                                                        name="oneTimePickUpDatetimePicker"
                                                        className="date" 
                                                        id="oneTimePickUpDatetimePicker"
                                                        
                                                    >
                                                        <ReactDatetime
                                                            value={oneTimePickupDay} 
                                                            closeOnSelect={true}
                                                            timeFormat={false}
                                                            onChange={this.handleOneTimePickupDay}
                                                            inputProps={{
                                                                className: "form-control",
                                                                placeholder: "Pickup Day",
                                                            }}
                                                            renderDay={( props, currentDate, selectedDate)=>{
                                                                /*
                                                                console.log(props);
                                                                console.log(currentDate);
                                                                console.log(selectedDate);
                                                                console.log(this.props);
                                                                */

                                                                if(listOfOneTimePickupDates.length){
                                                                    let highLightDate = false;
                                                                
                                                                    listOfOneTimePickupDates.forEach(d=>{
                                                                        if(moment.utc(currentDate).isSame(d,'day'))
                                                                            highLightDate = true;
                                                                     })
     
                                                                     if(highLightDate)
                                                                        props.className += " rdtActive color-white fw-strong";
                                                                     
                                                                }
                                                                
                                                                
                                                                return <td {...props}>{currentDate.date() }</td>;
                                                            }}

                                                            isValidDate={currentDate=>{
                                                                let enableDate = false;
                                                                listOfOneTimePickupDates.forEach(d=>{
                                                                    if(moment.utc(currentDate).isSame(d,'day'))
                                                                       enableDate = true;
                                                                })
                                                                return enableDate;
                                                            }}
                                                        />
                                                        <InputGroupAddon addonType="append">
                                                        <InputGroupText>
                                                            <span className="glyphicon glyphicon-calendar">
                                                            <i className="fa fa-calendar" />
                                                            </span>
                                                        </InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </FormGroup> 
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md="6" className={(oneTimePickupDay && oneTimePickUpTime) ? "":"d-none"}>
                                        <Row>
                                            <Col sm="4"  className="mt-2">
                                                Delivery Day
                                            </Col>
                                            <Col sm="8">
                                                <FormGroup>
                                                    <InputGroup 
                                                        name="oneTimeDeliveryDatetimePicker"
                                                        className="date" 
                                                        id="oneTimeDeliveryDatetimePicker"
                                                    >
                                                        <ReactDatetime
                                                            value={oneTimeDeliveryDay} 
                                                            closeOnSelect={true}
                                                            timeFormat={false}
                                                            onChange={this.handleOneTimeDeliveryDay}
                                                            inputProps={{
                                                                className: "form-control",
                                                                placeholder: "Delivery Day",
                                                            }}

                                                            renderDay={( props, currentDate, selectedDate)=>{
                                                                if(listOfOneTimeDeliveryDates.length){
                                                                    let highLightDate = false;
                                                                
                                                                    listOfOneTimeDeliveryDates.forEach(d=>{
                                                                        if(moment.utc(currentDate).isSame(d,'day'))
                                                                        highLightDate = true;
                                                                     })
     
                                                                     if(highLightDate)
                                                                        props.className += " rdtActive color-white fw-strong";
                                                                     
                                                                }
                                                                
                                                                
                                                                return <td {...props}>{currentDate.date() }</td>;
                                                            }}

                                                            isValidDate={currentDate=>{
                                                                let enableDate = false;
                                                                listOfOneTimeDeliveryDates.forEach(d=>{
                                                                    if(moment.utc(currentDate).isSame(d,'day'))
                                                                       enableDate = true;
                                                                })
                                                                return enableDate;
                                                            }}
                                                        />
                                                        <InputGroupAddon addonType="append">
                                                        <InputGroupText>
                                                            <span className="glyphicon glyphicon-calendar">
                                                            <i className="fa fa-calendar" />
                                                            </span>
                                                        </InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </FormGroup> 
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="6"  className={(oneTimePickupDay) ? "":"d-none"}>
                                        <Row>
                                            <Col sm="4">
                                                Pickup Time
                                            </Col>
                                            <Col sm="8" md="8" lg="8">
                                                {  (oneTimePickupTimeslotList.length) ? 
                                                   oneTimePickupTimeslotList.map((timeslot)=>{
                                                       return(
                                                            <Row key={`pickup_${timeslot.id}`}>
                                                                <Col className="mb-2">
                                                                    <Button
                                                                        className="btn-round mr-1"
                                                                        color="danger"
                                                                        outline = {oneTimePickUpTimeId === timeslot.id ? false:true}
                                                                        block
                                                                        type="button"
                                                                        onClick={()=>this.setOneTimePickupTime(timeslot)}
                                                                    >
                                                                        {`${timeslot.formattedTime}`}
                                                                    </Button>
                                                                </Col>
                                                            </Row> 
                                                       )
                                                   }) 
                                                   :
                                                   "No timeslots available for the selected date"
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md="6" className={(oneTimeDeliveryDay) ? "" : "d-none"}>
                                        <hr className="d-block d-md-none" />
                                        <Row>
                                            <Col sm="4">
                                                Delivery Time
                                            </Col>
                                            <Col sm="8" md="8" lg="8">
                                                {
                                                   oneTimeDeliveryTimeslotList.map((timeslot)=>{
                                                       return(
                                                            <Row key={`delivery_${timeslot.id}`}>
                                                                <Col className="mb-2">
                                                                    <Button
                                                                        className="btn-round mr-1"
                                                                        color="danger"
                                                                        outline = {oneTimeDeliveryTimeId === timeslot.id ? false:true}
                                                                        block
                                                                        type="button"
                                                                        onClick={()=>this.setOneTimeDeliveryTime(timeslot)}
                                                                    >
                                                                        {`${timeslot.formattedTime}`}
                                                                    </Button>
                                                                </Col>
                                                            </Row> 
                                                       )
                                                   }) 
                                                }
                                                
                                                
                                            </Col>
                                        </Row>                                        
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {/* End of One-time pickup & Delivery */}  

                        

                        

                    
                    </div>
                    
                    <div className={`${oneTimeDeliveryTimeId} modal-footer`}>
                        <div className="left-side">
                            <Button
                            className="btn-link"
                            color="default"
                            type="button"
                            onClick={() => handleModalDisplayFlag(false)}
                            >
                            Cancel
                            </Button>
                        </div>
                        <div className="divider" />
                        <div className="right-side">
                            <Button className="btn-link" color="danger" type="button" onClick={()=>this.saveSchedule()}>
                                Change Upcoming Pickup
                            </Button>
                        </div>
                    </div>
                </Modal>
        );
    }
}

export default ResidentialCustomerAccountLaundryModifyOneTimeUpcomingPickupModal;