import React,{ useEffect } from "react";
import {Elements} from 'react-stripe-elements';
import _ from "lodash";



// core components
import ShortNavbar from "../Navbars/ShortNavbar.js";
import SignupPageHeader from "../Headers/SignupPageHeader";
import FooterBlack from "../Footers/FooterBlack.js";

import ResidentialSelectCity from "./SignupSteps/Laundry/ResidentialSelectCity";
import ResidentialPricingAndInsurance from "./SignupSteps/Laundry/ResidentialPricingAndInsurance";
import ResidentialCustomerInformation from "./SignupSteps/Laundry/ResidentialCustomerInformation";
import LoginAndPaymentInformation from "./SignupSteps/Common/LoginAndPaymentInformation";
import ResidentialReviewScreen from "./SignupSteps/Laundry/ResidentialReviewScreen";

const proceedToCustomerInformationStep = user=>{
  let proceed  = false;

  const { laundry } = user; 
  const {residential:laundryProperties} = laundry; 
  const {insurance,laundryType,pricePlan} = laundryProperties;
  proceed  = (
    proceedToPricingAndInsuranceStep(user) &&
    insurance !== "" && laundryType !== "" && pricePlan !== "" 
  ) ? true:false;
     
  return proceed;
}

const proceedToLoginAndPaymentInformation = user=>{
  let proceed  = false;

  const { firstName,lastName,email } = user; 
  proceed  = (proceedToCustomerInformationStep(user) && firstName !== "" && lastName !== "" && email !== "") ? true:false;
  
  return proceed;
}

const proceedToPricingAndInsuranceStep = user=>{
  let proceed  = false;
  const {residentialCity} = user;
  
  proceed  = (!_.isEmpty(residentialCity) && residentialCity.id) ? true:false;

  return proceed;
}

const proceedToReviewScreen = user=>{
  let proceed  = false;

  const { paymentInformation } = user; 
  const { creditCardStripeToken } = paymentInformation;
  
  proceed  = (
    proceedToCustomerInformationStep(user) && 
    proceedToPricingAndInsuranceStep(user) &&
    creditCardStripeToken !== ""
  ) ? true:false;


  return proceed; 
}

function renderComponent(props){
  const {user} = props;
  const {step} = user;
  let component = <ResidentialSelectCity {...props} />;

  switch(step){
    case "select-city":                     
            component= <ResidentialSelectCity {...props} />;
            break;
    case "select-pricing-and-insurance":         
            component= <ResidentialPricingAndInsurance {...props} />;
            break;
    case "enter-residential-customer-information":           
            component= <ResidentialCustomerInformation {...props} />;
            break;
    case "enter-login-payment-information":   
            component= <Elements><LoginAndPaymentInformation {...props} /></Elements>;
            break;
    case "review-screen":                     
            component= <ResidentialReviewScreen {...props} />;
            break;
    default:    
            component= <ResidentialSelectCity {...props} />;
  }
  return component;
}

const validateToRenderComponent = (props)=>{
  let proceed = false;

  const {user} = props;
  const {step} = user;
  
  switch(step){
    case "select-city":                     
            proceed = true;
            break;
    case "select-pricing-and-insurance":         
            proceed = proceedToPricingAndInsuranceStep(user);
            break;
    case "enter-residential-customer-information":           
            proceed = proceedToCustomerInformationStep(user);
            break;
    case "enter-login-payment-information":   
            proceed = proceedToLoginAndPaymentInformation(user);
            break;
    case "review-screen":                     
            proceed = proceedToReviewScreen(user);
            break;
    default:    
            proceed = false;
  }

  return proceed;
}

function SignupLaundryResidential(props) {
  const {user} = props;  

  useEffect(() => {
    const proceed = validateToRenderComponent(props)
    if(!proceed) props.history.push("/signup/laundry/residential/select-city")
  });

  return (
    <>
      <ShortNavbar {...props}  />
      <SignupPageHeader />
      <div className="main">
        <div id="sectionArea" className="section section-gray">
          {renderComponent(props)}
        </div>
      </div>
      <FooterBlack />
    </>
  );
}

export default SignupLaundryResidential;
