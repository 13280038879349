import React,{Component,useEffect} from "react";
import {Route,Redirect} from "react-router-dom";
import auth from 'services/authService';


/*
 <Route expects component or render 
 compnent is dynamic and lowercase components is invalid hence component:Component
 
 render is a function to be called by Route hence the function call is executed inside
 render = ()=>{
   if 
     no user then redirect to login
   else{
     <Component />
     or
     render(props)
   }
 }
 
*/
const ProtectedRoute = ({path,component:Component,render,title,...rest})=>{
  //console.log(path);

  	useEffect(()=>{
		if(title)
			window.document.title = `${process.env['REACT_APP_PAGE_TITLE']}: ${title}`
	},[title])
	
  return(
    <Route
      {...rest}
	  render={props=>{
	    //console.log(props);
	    if(!auth.getCurrentUser()){ 
		  return(
		    <Redirect 
		       to={
		         {
		           pathname:"/login",
		           state:{from:props.location}
		         }
		       }
		       
		    />
		  );   
		}  
		
		if(Component)
		  return <Component {...props} />
		else 
		  return render(props)   
	  }}
    />
  
  )
}
export default ProtectedRoute;