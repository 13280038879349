import React from "react"

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Media,
    NavItem,
    NavLink,
    Nav,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    TabContent,
    TabPane,
    Container,
    Row,
    Col
} from "reactstrap";

import StoragePricing from "./FaqContent/Pricing";
import StorageSchedulingPickup from "./FaqContent/SchedulingPickups";
import StorageSchedulingDeliveries from "./FaqContent/SchedulingDeliveries";
import Shipping from "./FaqContent/Shipping";
import StorageSigningUp from "./FaqContent/StorageSigningUp";
import Packing from "./FaqContent/Packing";
import PickupDeliveries from "./FaqContent/PickupDeliveries";




const faqDropdown = [
    { value: "signing-up", label: "Signing Up" },
    { value: "pricing-faq", label: "Pricing" },
    { value: "scheduling-pickups", label: "Scheduling Pickups" },
    { value: "scheduling-deliveries", label: "Scheduling Deliveries" },
    { value: "shipping", label: "Shipping" },
    ];
    


const FaqDesktop = (props)=>{
    const [pills, setPills] = React.useState("1");

    return(
        <>
            <Row>
                <Col>
                    <Nav className="nav-pills-danger" pills>
                        <NavItem>
                        <NavLink
                            className={pills === "1" ? "active" : ""}
                            onClick={() => {
                            setPills("1");
                            }}
                        >
                            General Info
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink
                            className={pills === "2" ? "active" : ""}
                            onClick={() => {
                            setPills("2");
                            }}
                        >
                            Pricing
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink
                            className={pills === "3" ? "active" : ""}
                            onClick={() => {
                            setPills("3");
                            }}
                        >
                            Packing/Insurance
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink
                            className={pills === "4" ? "active" : ""}
                            onClick={() => {
                            setPills("4");
                            }}
                        >
                            Pickups/Deliveries
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink
                            className={pills === "5" ? "active" : ""}
                            onClick={() => {
                            setPills("5");
                            }}
                        >
                            Shipping
                        </NavLink>
                        </NavItem>
                    </Nav>
                    {/* Pill panes */}
                
                    <TabContent className="mt-3" activeTab={"pills" + pills}>
                        <TabPane tabId="pills1">
                            <Row>
                                <Col> 
                                    <StorageSigningUp />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="pills2">
                            <Row>
                                <Col> 
                                    <StoragePricing />
                                </Col>
                            </Row>
                        </TabPane>
                        
                        <TabPane tabId="pills3">
                            <Row>
                                <Col> 
                                    <Packing />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="pills4">
                            <Row>
                                <Col> 
                                    <PickupDeliveries />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="pills5">
                        <   Row>
                                <Col> 
                                    <Shipping />
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        
        </>
    )
}

export default FaqDesktop;

