import React,{Component} from "react";
import _ from "lodash";

// core components
import ShortNavbar from "../../components/Navbars/ShortNavbar";
import SuppliesPageHeader from "../../components/Headers/SuppliesPageHeader";
import FooterBlack from "../../components/Footers/FooterBlack";

import SuppliesLandingGuest from "components/Consumer/ShoppingCart/Supplies/SuppliesLandingGuest";
import SuppliesCatalog from "components/Consumer/ShoppingCart/Supplies/SuppliesCatalog";

import SuppliesService from "../../services/suppliesService";


import {
    Alert,
    Button,
    Container,
    Card,
    CardBody,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Row,
    Col,
    
} from "reactstrap";

class Supplies extends Component{
   
    constructor(){
        super();
        this.state = {
           step:"supplies-landing-guest",  
           productListByCities:[], 
           selectedCityForProductCatalog:""
        };

    }

    async componentDidMount(){
       
        let {
            productListByCities,
        } = this.state;
        
        if(productListByCities.length === 0){
            productListByCities = await SuppliesService.getListOfCitiesForSupplies();
            this.setState({...this.state,productListByCities});
        }

    }

    componentDidUpdate(prevProps){
        const {selectedCityForProductCatalog} =  this.state
        const {
                university:universityObject,
                universityId,
                postalCode,
                postalCodeShippingStartDateToBlock,
                postalCodeShippingEndDateToBlock,
        }  = this.props.globalApp.shoppingCart; 
      
        const {universityId:previousUniversityId}  = prevProps.globalApp.shoppingCart; 
        
        if((previousUniversityId != universityId && !_.isEmpty(universityObject)) || selectedCityForProductCatalog == ""){
            const functionObject = {
                university_id:universityId,
                University:universityObject,
                postal_code:postalCode,
                postalCodeShippingStartDateToBlock,
                postalCodeShippingEndDateToBlock
            };
            this.displayCatalogByPostalCode(functionObject);
        }  
    }

    /*
        
    */
    displayCatalogByPostalCode =  (supplyRecord)=>{
        const {
            university_id:universityId,
            University,
            postal_code:postalCode,
            shipping_start_date_block:postalCodeShippingStartDateToBlock,
            shipping_end_date_block:postalCodeShippingEndDateToBlock,
        } = supplyRecord;
        const {updateShoppingCart} = this.props;
        const {supplies_tax:suppliesTax} = University;
        //const step = "view-catalog";
        //this.setState({step});
            
        const shoppingCart = {
            ...this.props.globalApp.shoppingCart,
            postalCode,
            universityId,
            
        }
        shoppingCart.university = University;
        if(postalCodeShippingStartDateToBlock && postalCodeShippingEndDateToBlock){
            shoppingCart.university = University;
            shoppingCart.postalCodeShippingStartDateToBlock = postalCodeShippingStartDateToBlock;
            shoppingCart.postalCodeShippingEndDateToBlock = postalCodeShippingEndDateToBlock;
        }

        const cartTotal = {...shoppingCart.cartTotal}
        cartTotal.salesTaxPercent = suppliesTax;
        shoppingCart.cartTotal = cartTotal;
        
        console.log("hello");
        console.log(shoppingCart);
        updateShoppingCart(shoppingCart);
        
        /*If we find the zipcode via async or JSON check */
        const selectedCityForProductCatalog = _.find(this.state.productListByCities,{'universityId':universityId});
        //const step = "view-catalog";
        this.setState({...this.state,selectedCityForProductCatalog})  
        this.props.history.push('/supplies/view-catalog'); 
        

        
    }

    render(){
        let {step} = this.props.match.params;
        let {selectedCityForProductCatalog} = this.state;
        
        const props = this.props;
        const {postalCode}  = this.props.globalApp.shoppingCart;
        //const {step} = this.state;
        if(!step || postalCode === "") step = this.state.step;

        return(
            <>
                <ShortNavbar {...props} />
                <SuppliesPageHeader />   
                <div className="main">
                    <div id="sectionArea" className="section section-gray" style={{paddingTop:"0px"}}>
                       <Container id="mainContentArea">
                            {
                                step === "supplies-landing-guest" &&
                                <SuppliesLandingGuest
                                    {...props}
                                    onClickOfOrderSuppliesByPostalCode = {this.displayCatalogByPostalCode}
                                />
                            }
                            
                            {
                                step === "view-catalog" &&
                                <SuppliesCatalog 
                                    productCatalog={selectedCityForProductCatalog}    
                                    {...props}
                                />
                            }


                            
                            
                           
                       </Container>    
                    </div>
                </div>
                <FooterBlack />
            </>
        )
    }

}

export default Supplies;