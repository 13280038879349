import React from "react";

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Media,
    NavItem,
    NavLink,
    Nav,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    TabContent,
    TabPane,
    Container,
    Row,
    Col
  } from "reactstrap";

const studentData = [
    {
        heading:"Where does my laundry get picked up and delivered if I live in the dorms?",
        body:<p>
        Lazybones Laundry bins are provided in most dorms on designated days for pick up. Most deliveries are left at the front desk. 
        </p>
    },
    {
        heading:"Where does my laundry get picked up and delivered if I live in an apartment?",
        body:<p>
        If you do not live in a dorm, your laundry will either be picked up and delivered right at your apartment door, or at the front desk if the building permits. 
        </p>,
    },
    {
        heading:"Do I need to be home for laundry pickup and delivery?",
        body:<p>
        If you have signed the "unattended pick-up and delivery waiver", you do not need to be present. 
        </p>,
    },
    {
        heading:"What if I just want a one time pick-up for laundry service? ",
        body:<p>
        You can schedule a one time laundry pick-up by calling Lazybones Customer Service (877) 215-2105, or emailing us at wecare@mylazybones.com. You can also schedule a one time pick-up by going online and scheduling it yourself at mylazybones.com. 
        </p>,
    },
    {
        heading:"What if I miss my scheduled laundry pickup?",
        body:<p>
        If you miss a laundry pick-up on your scheduled day, you can contact our office to find out if there is availability to pick it up later that week, or you can request to have double the pounds of laundry picked up the following week of service. 
        </p>,
    },
];

const residentialData = [
    {
        heading:"Where does my laundry get picked up and delivered if I live in an apartment or house? ",
        body:<p>
        Your laundry will either be picked up and delivered at your front door or at the front desk if the building permits. 
        </p>
    },
    {
        heading:"Do I need to be home for laundry pickup and delivery?",
        body:<p>
        If you have signed the "unattended pick-up and delivery waiver", you do not need to be present. 
        </p>,
    },
    {
      heading:"What if I just want a one time pick-up for laundry service? ",
      body:<p>
      You can schedule a one time laundry pick-up by calling Lazybones Customer Service (877) 215-2105, or emailing us at wecare@mylazybones.com. You can also schedule a one time pick-up by going online and scheduling it yourself at mylazybones.com.
      </p>,
    },
    {
        heading:"What if I miss my scheduled laundry pickup?",
        body:<p>
        If you miss a laundry pick-up on your scheduled day, you can contact our office to find out if there is availability for us to pick it up later that week.</p>,
    },
    
];


  const PickupDelivery = (props)=>{
      const {isStudent,isResidential} = props;
      const data = (isStudent === true) ? studentData:residentialData;
      
      let ctr = 1;
      
      return(
        <>
            {
                data.map(({heading,body})=>
                    <Media key={ctr++}>
                        <Media body>
                        <Media heading>{heading}</Media>
                        {body}
                        </Media>
                    </Media>
                )
            }
        </>
      );
  }

  export default PickupDelivery;
