import React,{Component} from "react"
import _ from "lodash"
import moment from "moment";
import date from "date-and-time"
import dateFormat from "dateformat";
import accounting from "accounting";
import LaundryService from "../../../../../../services/laundryService";

// reactstrap components
import {
    Button,
    Container,
    Card,
    CardBody,
    CardFooter,
    CardImg,
    CardImgOverlay,
    CardLink,
    CardTitle,
    CardSubtitle,
    CardText,
    ListGroupItem,
    ListGroup,
    Row,
    Col,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    FormText,
    UncontrolledTooltip
    
  } from "reactstrap";

class SelectSemesterAndPricingLaundry extends Component{
    tooltipDescription = {
      "by-the-pound":"No plan is required. Whether you just want to try us out or just don't like commitments we'll pick up and deliver your laundry for one low price by the pound.",
      "semester-plan":"These plans include a laundry pick up once a week for the duration of the semester. They are the best for keeping you organized and clean on a regular schedule."
    }

    tooltipPlacement= {
        "by-the-pound":"left",
        "semester-plan":"right"
      }

    pricingConstants = {
        pricingBysemesterPlan:{
            id:2,
            type:"semester-plan",
            description:"Semester Plan",
        },
        pricingByThePound:{
            "id":1,
            "type":"by-the-pound",
            "description":"By the Pound",
        }
    }

    constructor(){
        super();
        this.state = {
            data:{
                semester:"",    //{id,semester}
                laundryType:"", //{id,type}
                pricePlan:"",
                insurance:{
                    id: 1,
                    type: "Standard",
                    price: 0,
                    totalPrice:0,
                    proRatedFlag:false,
                }, 
            },
            errors:{}
        }
    }

    componentDidMount(){
        document.getElementById("sectionArea").scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
          });

        this.mapStudentModelToView();
       //window.scrollTo(0, 0);
       //document.body.scrollTop = 0;
       
      
    }

    componentDidUpdate(prevProps){
        if(
            this.isStudentChangingToSemesterPlan() === true && 
            !_.isEqual(this.props.user.school,prevProps.user.school)  
        ){
            this.mapStudentModelToView()
        }
    }

    displaySemesterList = ()=>{
        const {user}  = this.props;  
        const semesterList =  (!_.isEmpty(user.school.semesters)) ? user.school.semesters:[];
        const {semester:selectedSemester} = this.state.data;
      
        
        let renderSemestersAvailable = true;
        renderSemestersAvailable = (semesterList.length > 0) ? true:false;
        
        //Check if the whole list of Semesters has already expired
        if(renderSemestersAvailable){
            const listOfActiveSemesters = _.filter(semesterList, (s) =>moment().isSameOrBefore(s.endDateDb));
            renderSemestersAvailable = (listOfActiveSemesters.length > 0) ? true:false
        }
        
        //Render no semester available message
        if(!renderSemestersAvailable){
            return(
                <Row>
                    <Col xs="12">
                        <div className="mlb-alert mlb-alert-primary" role="alert">
                            <Row>
                                <Col xs="1" sm="1" className="fs-42 text-danger">
                                        <i className="fa fa-exclamation-triangle fa-6" aria-hidden="true"></i>    
                                </Col>
                                <Col>
                                   We currently don't have any upcoming semester plans available for sign-up. Please try again later.
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            );
        }
        
        
        if(renderSemestersAvailable){
            return semesterList.map(s=>{
                return(
                  <FormGroup key={s.id} row className="text-center">
                      <Col sm="2"></Col> 
                      <Col sm={{ size: 8}}> 
                          <Button
                              className="btn-round mr-1"
                              color="danger"
                              outline = {selectedSemester.id !== s.id}
                              block
                              type="button"
                              onClick={()=>this.handleSelectSemester(s)}
                          >
                              {s.semester} ({s.startDate} - {s.endDate})
                          </Button>
                      </Col>
                      <Col sm="2"></Col>
                  </FormGroup>   

                );
            })  
        }
    }

    handleSelectInsurancePlan = (i)=>{
        console.log(i); 
        const data = {...this.state.data};
        data.insurance = i;
        this.setState({data});   
    }

    handleSelectLaundryPlan= (l)=>{
        const laundryType = _.pick(l,['id','type'])
        const data = {...this.state.data}
        data.laundryType = laundryType;
        data.pricePlan = "";
        this.setState({data});
    } 

    handleSelectPricePlan = (p)=>{
        const data = {...this.state.data}
        data.pricePlan = p;
        this.setState({data});
    }

    handleSelectSemester = (s)=>{
        //const semester = _.pick(s,['id','semester']);
        const semester = s;
        const data = {...this.state.data}
        data.semester = semester;
        data.laundryType = "";
        data.pricePlan = "";
        this.setState({data}); 
    }

    handleSubmit = (e)=>{
        e.preventDefault();  
        const {onClickOfNextButtonFromSemesterAndPricingInformation} = this.props;  
        console.log("Handle submit of semester information");
        //console.log(this.state);
        const semesterAndPricingForm = {...this.state.data};
        onClickOfNextButtonFromSemesterAndPricingInformation(semesterAndPricingForm);
        return;
    }

    isStudentChangingToSemesterPlan = ()=>{
        let flag = false;
        const {changePlanFlag} = this.props.match.params
        flag = (changePlanFlag === LaundryService.CHANGE_TO_SEMESTER_PLAN) ? true:false
        
        return flag;
    }

    mapStudentModelToView = ()=>{
        const {user}  = this.props;  
        const {semesterId} = this.props.match.params
        
        const {semester,laundryType,pricePlan,insurance} = user.laundry.student 
        
        const semesterList =  (!_.isEmpty(user.school.semesters)) ? user.school.semesters:[];
        
        const data = {
            semester,    
            laundryType, 
            pricePlan,
            insurance:(insurance) ? insurance:this.state.data.insurance
        }

        if(this.isStudentChangingToSemesterPlan() === true && semesterList.length > 0){
            //We need to set the semester and semester plan selection
            const selectedSemester  = _.find(semesterList,{"id":parseInt(semesterId)})
            data.semester = selectedSemester;
            data.laundryType = _.pick(this.pricingConstants.pricingBysemesterPlan,['id','type']);
        }


        
        if(!_.isEqual(this.state.data,data))
           this.setState({data});
    }

    getInsuranceList = (laundryTypeList,selectedSemesterObj,insuranceList)=>{
        const enableProRateFlag = true;
        const {user}  = this.props;  
        const {pricePlan:selectedPricePlan,semester:selectedSemester,laundryType:selectedLaundryType} = this.state.data;
        
        const originalInsuranceList = [...insuranceList];
            
        //If semester plan then check if we need to pro-rate it
        if(selectedLaundryType.id === this.pricingConstants.pricingBysemesterPlan.id){
            console.log('detect pro-rate');
            const {startDate,endDate, numberOfWeeks, numberOfWeeksForFullSignupFees} = selectedSemesterObj;
            const endDateObj = new Date(endDate);
        
            const dateThresholdForFullPrice = this.getThresholdDateForFullPrice(selectedSemesterObj);
            console.log(dateThresholdForFullPrice);
            const now = new Date();
            console.log(dateFormat(now, "W"));
            console.log(dateFormat(endDateObj, "W"));
            
            if(now > dateThresholdForFullPrice && enableProRateFlag){
                insuranceList = originalInsuranceList.map(i=>{
                    const poundsPerWeek = selectedPricePlan.pounds;
                    const remainingWeeks = (dateFormat(endDateObj, "W") - dateFormat(now, "W")) + 1;
                    let totalPounds = poundsPerWeek * remainingWeeks;
                    i.totalPrice = parseFloat(accounting.toFixed((totalPounds * i.price),2));
                    i.proRatedFlag = true;
                    return i;
                });
            }
            else{
                insuranceList = originalInsuranceList.map(i=>{
                    let totalPounds = selectedPricePlan.totalPounds;
                    i.totalPrice = parseFloat(accounting.toFixed((totalPounds * i.price),2));
                    i.proRatedFlag = false;
                    return i;
                });
            }

        }
        else{
            //By the block
            insuranceList = originalInsuranceList.map(i=>{
                i.totalPrice = 0;
                i.proRatedFlag = false;
                return i;
            });
        }
      
      return insuranceList;
    }

    

    getPriceList = (laundryTypeList,selectedSemesterObj)=>{
        const {user}  = this.props;  
        const {semester:selectedSemester,laundryType:selectedLaundryType} = this.state.data;
        
        let priceList = _.find(laundryTypeList,['id',selectedLaundryType.id]).price;
        
        /*
          If semester plan then check if we need to pro-rate it
        */
       if(selectedLaundryType.id === this.pricingConstants.pricingBysemesterPlan.id){
           //console.log(selectedSemesterObj)
        
           console.log('detect pro-rate');
           const {startDate,endDate, numberOfWeeks, numberOfWeeksForFullSignupFees} = selectedSemesterObj;
           const endDateObj = new Date(endDate);
        
           const dateThresholdForFullPrice = this.getThresholdDateForFullPrice(selectedSemesterObj);
           console.log(dateThresholdForFullPrice);
           const now = new Date();
           console.log(dateFormat(now, "W"));
           console.log(dateFormat(endDateObj, "W"));

           
           if(now > dateThresholdForFullPrice){
               console.log('Start Pro-rate');
               //Update the price value, price-per-pound and set original price and pro-rate flag to true
              const originalPriceList = [...priceList];
              priceList = originalPriceList.map(p=>{
                const packagePricePerWeek = p.originalPrice/numberOfWeeks;
                const remainingWeeks = (dateFormat(endDateObj, "W") - dateFormat(now, "W")) + 1;
                p.price = parseFloat(accounting.toFixed((packagePricePerWeek * remainingWeeks),2));
                p.proRatedFlag = true;
                return p;
              });

              console.log(priceList);
           }
           //console.log(now);
        }

       //

        
      return priceList;
    }

    getThresholdDateForFullPrice  = ({startDate,endDate, numberOfWeeks, numberOfWeeksForFullSignupFees})=>{
        const startDateObj = new Date(startDate);
        console.log(dateFormat(startDateObj, "W"));
        const dateThresholdForFullPrice = date.addDays(startDateObj, numberOfWeeksForFullSignupFees * 7);
        return dateThresholdForFullPrice;
    }

    render(){
      const {onClickOfBackButtonFromSemesterAndPricingInformation,user}  = this.props;  
      const semesterList = user.school.semesters;
      //console.log(semesterList);

      const {semester:selectedSemester,laundryType:selectedLaundryType,pricePlan:selectedPricePlan,insurance:selectedInsurance} = this.state.data;
      
      const laundryTypeList = selectedSemester && _.find(semesterList, ['id', selectedSemester.id]).pricing;
      const selectedSemesterObj = selectedSemester && _.find(semesterList, ['id', selectedSemester.id]);
      
      const priceList = selectedLaundryType && this.getPriceList(laundryTypeList,selectedSemesterObj);
      
      let insuranceList =  selectedSemester && _.find(semesterList, ['id', selectedSemester.id]).insurance;
      insuranceList = this.getInsuranceList(laundryTypeList,selectedSemesterObj,insuranceList);
      
      //console.log(insuranceList);
      
      
      return(
        <Container id="mainContentArea">
            <Row className="title-row justify-content-between">
                <Col md="6">
                    <h3 className="shop">Signup for Laundry - Student</h3>
                </Col>
                <Col md="5">
                    <div className="text-md-right">
                        <span className="text-muted">Step 1</span>
                        <Button color="link">
                        <i className="fa fa-shopping-cart" /> Select Semester and Price Plan
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row className="title-body-row">
                <Col md="12">
                        <Card>
                            <CardBody>
                                <Form>
                                    <div>
                                        <h6 className="card-category text-left">Select Semester</h6>
                                        <hr/>
                                        { this.displaySemesterList() }
                                    </div>
                                    
                                    {
                                        selectedSemester && 
                                        <div>
                                        <h6 className="card-category text-left">Select Plan</h6>
                                        <hr/> 
                                        
                                        <Row className="justify-content-center">
                                                <Col sm="2"></Col> 
                                                {
                                                   laundryTypeList.map(l=>{
                                                        
                                                        const returnComponent = <Col key={l.id} sm={{ size: 4}} className="mt-2"> 
                                                            <Button
                                                                className="btn-round mr-1"
                                                                color="danger"
                                                                outline={l.id !== selectedLaundryType.id}
                                                                block
                                                                type="button"
                                                                id={`btn_${l.type}`}
                                                                onClick={()=>{this.handleSelectLaundryPlan(l)}}
                                                            >
                                                                {l.description}
                                                            </Button>
                                                        </Col>
                                                        
                                                        if(this.isStudentChangingToSemesterPlan() === true){
                                                            if(l.id === LaundryService.SEMESTER_PLAN)
                                                               return returnComponent
                                                        }
                                                        else{    
                                                            return returnComponent
                                                        }
                                                    }) 
                                                }
                                                <Col sm="2"></Col>
                                        </Row>
                                        </div>

                                    }

                                    {
                                        selectedLaundryType &&
                                        <div>
                                        <h6 className="card-category text-left">Select Price Plan</h6>
                                        <hr/> 
                                        <Row className="justify-content-center">
                                            {
                                                priceList.map(p=>{
                                                    
                                                    const selectedAttributes = (p.id === selectedPricePlan.id) ? {"data-color":"orange"}:{};
                                                    return(
                                                        <Col key={p.id} md="3" sm="6">
                                                            <Card className="card-pricing"  {...selectedAttributes}>
                                                                <CardBody>
                                                                    <CardTitle tag="h1" style={{fontSize:"2.75em"}}>{accounting.formatMoney(p.price)}</CardTitle>
                                                                    <ul>
                                                                        <li>
                                                                        {p.name}
                                                                        </li>
                                                                        <li>
                                                                        {p.description}
                                                                        </li>
                                                                        <li>
                                                                        ${p.pricePerPound} per pound
                                                                        </li>
                                                                        <li>&nbsp;</li>
                                                                    </ul>
                                                                    <Button
                                                                        className="btn-round mr-1"
                                                                        color="success"
                                                                        outline
                                                                        type="button"
                                                                        onClick={()=>{this.handleSelectPricePlan(p)}}
                                                                        
                                                                    >
                                                                        Select
                                                                    </Button>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    );
                                                })
                                            }
                                            
                                        </Row>
                                        
                                        </div>

                                    }


                                    {
                                        selectedPricePlan &&
                                        <div>
                                        <h6 className="card-category text-left">Select Insurance</h6>
                                        <hr/> 
                                        <Row className="justify-content-center">
                                            {
                                                insuranceList.map(i=>{
                                                    const selectedAttributes = (selectedInsurance && i.id === selectedInsurance.id) ? {"data-color":"orange"}:{};
                                                    return(
                                                        <Col key={i.id} md="3" sm="6">
                                                            <Card className="card-pricing"  {...selectedAttributes}>
                                                                <CardBody>
                                                                    <CardTitle tag="h5">{i.type}</CardTitle>
                                                                    <ul>
                                                                        <li>
                                                                        <h5>{i.price * 100}¢ per pound</h5>
                                                                        </li>
                                                                        <li>
                                                                            {`Reimbursement at a flat rate of ${i.insuranceLimitPerPount}/pound or per the '${i.type.toLowerCase()}' column of our garment pricing list.`}  
                                                                        </li>
                                                                        <li>&nbsp;</li>
                                                                    </ul>
                                                                    <Button
                                                                        className="btn-round mr-1"
                                                                        color="success"
                                                                        outline={(selectedInsurance && i.id != selectedInsurance.id) ? true:false}
                                                                        type="button"
                                                                        onClick={()=>{this.handleSelectInsurancePlan(i)}}
                                                                        
                                                                    >
                                                                        Select
                                                                    </Button>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    );
                                                })
                                            }
                                            
                                        </Row>
                                        
                                        </div>

                                    }                                   
                                    

                                    

                                    <FormGroup row className="mt-5 justify-content-between">
                                        <Col xs={{ size: 2}}>
                                        </Col>
                                        <Col xs={{ size: 6}} sm={{ size: 4}} className="text-right">
                                            <Button 
                                            disabled={!selectedSemester && !selectedPricePlan && !selectedLaundryType && true}
                                            className="btn-rounded btn-danger"
                                            onClick={this.handleSubmit}
                                            >
                                            Save & Proceed
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                </Form>                      
                            </CardBody>
                        </Card> 
                </Col>
                 
            
            </Row> 
        </Container>  
      );
    }
}
export default SelectSemesterAndPricingLaundry;