import React from 'react';
import _ from "lodash"
import ReactHtmlParser from 'react-html-parser';


// reactstrap components
import {
    Button,
    Container,
    Row,
    Col,
    FormGroup, 
    Modal, 
} from "reactstrap";


function FooterMessageModal(props) {
    
    const {
        currentMessageNumber,
        currentNotificationId,
        handleMoveToNextMessage,
        handleMoveToPreviousMessage,
        handleModalDisplayFlag,
        handleNotificationMarkedAsRead,
        modalDisplayFlag,
        notifications,
    } = props

    const displayNotificationBarStatus = notifications.length > 0 ? true:false;

    let currentNotification = null
    if(displayNotificationBarStatus === true){
        currentNotification = _.find(notifications,{"notificationId":currentNotificationId})

    }


    return (
        <Modal size="lg" isOpen={modalDisplayFlag}>
            <div className="modal-header">
                <button
                    className="close"
                    type="button"
                    onClick={() => handleModalDisplayFlag(false)}
                >
                    <span>×</span>
                </button>
                <h5
                    className="modal-title text-center"
                    id="exampleModalLabel"
                >
                    {currentMessageNumber} of {notifications.length} unread message(s)
                </h5>
            </div>
            <div className="modal-body min-height-sm">
                <Row>
                    <Col>
                        <strong className="text-danger">Alert:</strong> {currentNotification && ReactHtmlParser(currentNotification.messageBody.replace(/(?:\r\n|\r|\n)/g, "<br/>"))}
                    </Col>
                </Row>
            </div>
            <div className={`modal-footer justify-content-between p-3`}>
                    <div>
                        <i  
                            className="fa fa-chevron-left fa-9 mr-lg-3 cursor" 
                            onClick={handleMoveToPreviousMessage}
                        />
                    </div>
                    <div>
                        <Button
                            color="danger"
                            outline
                            size="sm"
                            type="button"
                            className="mr-1"
                            onClick={()=>handleNotificationMarkedAsRead(currentNotification)}
                        >
                            <i className="fa fa-calendar" aria-hidden="true"></i>
                            Mark as Read
                        </Button>
                    </div>

                    <div>
                        <i  
                            className="fa fa-chevron-right fa-9 mr-lg-3 cursor" 
                            onClick={handleMoveToNextMessage}
                        />
                    </div>
                
            </div>
        </Modal>
        
    );
}


export default FooterMessageModal;