import React from "react";
import _ from "lodash";
import Joi from "@hapi/joi";
import Select from "react-select";

import BaseForm from "../../../../../BaseClass/Form"
import {scrollTop} from '../../../../../../utils/helperUtils';
import FormErrorWidget from "../../../../../Common/FormErrorWidget";
import {renderError} from '../../../../../../utils/errorUtils';


// reactstrap components
import {
    Button,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Label,
    Row,
    Col,
    FormGroup, 
    Modal, 
    Input, 
  } from "reactstrap";



class ShipToUsModal extends BaseForm{
    quantityList = [
        { value: "", label: "", isDisabled: true },
        { value: 1, label: "1"},
        { value: 2, label: "2"},
        { value: 3, label: "3"},
        { value: 4, label: "4"},
        { value: 5, label: "5"},
        { value: 6, label: "6"},
        { value: 7, label: "7"},
        { value: 8, label: "8"},
        { value: 9, label: "9"},
        { value: 10, label: "10"},
        { value: 11, label: "11"},
        { value: 12, label: "12"},
        
    ]

    validationSchema = Joi.object({
        labelType:Joi.object().keys({
            value:Joi.string().required(), 
            label:Joi.any().optional(),
        }).label('Label Type'),
        quantity:Joi.object().keys({
            value:Joi.number().required(), 
            label:Joi.any().optional(),
        }).label('Quantity'),
        
    }) ;

    errorMessages = {
        "labelType":{
            "object.base":"Please select a label type",
        },
        "quantity":{
            "object.base":"Please select quantity"
        }
    }
            
    constructor(){
        super()
        this.state = {
            data:{
                labelType:{ value: "Storage", label: "Storage Labels"},
                quantity:"",
            },
            errors:{

            }
        }
    }

    //This gets executed once the parent submission process is executed
    submitLocal = ()=>{
        try{
            const {handleSaveOperation} = this.props;  
            const data = {...this.state.data};
            const formData = {
                labelType: data.labelType.value,
                quantity: data.quantity.value,
            }

            return handleSaveOperation(formData);
        }catch(ex){
            /*
            400 signals validation errors from the server
            renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop(); return;}
            }
            else
            renderError(ex);
        } 
    }

    validateLocal= ()=>{
        
        
    }


    render(){
        const {modalDisplayFlag,handleModalDisplay,universityRecord,...rest} = this.props 
        const {data,errors} = this.state;
        const {labelType,quantity} = data;

        
            
        return(
            <>
            <Modal id="shipToUsModal" size="lg" isOpen={modalDisplayFlag} toggle={() => handleModalDisplay(false)}>
                <div className="modal-header">
                    <button
                        className="close"
                        type="button"
                        onClick={() => handleModalDisplay(false)}
                    >
                        <span>×</span>
                    </button>
                    <h5
                        className="modal-title text-center"
                        id="exampleModalLabel"
                    >
                        Storage:Ship to Lazybones
                    </h5>
                </div>
                <div className="modal-body">
                    {/*Error display */}
                    {!_.isEmpty(errors) &&
                        <FormErrorWidget
                            errors={errors}
                        />
                    }
                    {/* End of Error display */}

                    <h6>Shipping Your Stuff to Lazybones (for delivery to you on campus)</h6>
                    <p>
                        You can ship your stuff to Lazybones ahead of time, and we will deliver it to your campus address at a later date of your choosing. 
                        <ul>
                                <li>If you are shipping items directly from your home address, just go to the bottom of this page and enter the number of labels you want to print, and then click "Generate Labels" button. Tape one label to each box to be shipped. </li>
                                <li>If you are shipping items directly from a vendor (ie. Amazon) you will not be able to place our barcode labels on your shipment. Please be sure to include your son/daughter's name as well as our company name and address (below) when filling out the shipping information for the vendor.</li>
                                <li>
                                    Once you have put your labels on the box, ship it to us at the address below <br/>
                                    {
                                        universityRecord && 
                                        <strong>
                                        {universityRecord.name} <br/>
                                        {universityRecord.street_address} <br/>
                                        {universityRecord.city}, {universityRecord.state} {universityRecord.zip} <br/>    
                                        </strong>
                                    }
                                </li>
                                <li>You will also need to schedule a storage delivery, however you will not be able to do so until Lazybones has received and processed your shipped items. </li>
                        </ul>
                        <strong>Please Note:</strong> Shipments must arrive by August 1.
                    </p>
                    <div>
                        <hr/>    
                        <Row>
                            <Col xs="2">
                                # of Labels:	
                            </Col>  
                            <Col xs="6">   
                                <FormGroup>
                                    <Select
                                        className="react-select react-select-default"
                                        classNamePrefix="react-select"
                                        name="quantity"
                                        id="quantity"
                                        value={quantity}
                                        onChange={value => this.handleDropdownChange(value,"quantity")}
                                        options={this.quantityList}
                                        placeholder="Quantity"
                                        
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className={`modal-footer`}>
                    <div className="left-side">
                        <Button
                        className="btn-link"
                        color="default"
                        type="button"
                        onClick={() => handleModalDisplay(false)}
                        >
                        Cancel
                        </Button>
                    </div>
                    <div className="divider" />
                    <div className="right-side">
                        <Button className="btn-link" color="danger" type="button" onClick={this.submitForm}>
                            Generate Labels
                        </Button>
                    </div>
                    </div>
            </Modal>
            </>
        );
    }
}

export default ShipToUsModal;