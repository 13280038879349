import React,{Component} from "react";
import _ from "lodash";
import moment from "moment";
import accounting from "accounting";
import Joi from "@hapi/joi";


import BaseForm from "../../../../../BaseClass/Form";
import FormErrorWidget from "../../../../../Common/FormErrorWidget";
import {scrollTop} from '../../../../../../utils/helperUtils';
import {renderError} from '../../../../../../utils/errorUtils';
import customToast from '../../../../../Common/CustomToast'


import ApplicationConstant from "../../../../../Common/ApplicationConstants";
import applicationConstantUtility from "../../../../../../utils/applicationConstants";

import GiftCardRedemptionModal from "../../../../GiftCard/GiftCardRedemptionModal"

import UserService from "../../../../../../services/userService";


// reactstrap components
import {
    Badge,
    Button,
    Container,
    Card,
    CardBody,
    CustomInput,
    Row,
    Col,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    
} from "reactstrap";

class ReviewScreen extends BaseForm{
    divAreaToScrollForError = "sectionArea"

    validationSchema = Joi.object({
        agreeTermsAndConditions:Joi.boolean().valid(true).truthy('true').falsy('false').sensitive().label('Terms and Conditions'),
  
    })


    validationCouponSchema = Joi.object({
        couponCode:Joi.string().required().label('Coupon Code'), 
        couponType:Joi.allow(""),
        couponBalance:Joi.allow(""),
        amountToBeUsedForRedemption:Joi.allow(""),
    }) 
  
    errorMessages = {
        "agreeTermsAndConditions":{
            "any.only":"Please accept the terms and conditions",
        },
    }

    constructor(){
        super();
        this.state = {
            openProcessingModalFlag:false,
            couponProcessingFlag:false,
            giftCardRedemptionModalDisplayFlag:false,
            data:{
                couponCode:"",
                couponType:"",
                giftCardBalance:"",
                giftCardAmountToBeUsedForRedemption:"", //This is basically the amount a user enters or it uses the balance
                couponDetails:"",
                agreeTermsAndConditions:false
            },
            errors:{}
        }
    }

    displayDiscountApplied = ()=>{
        const {user} = this.props;
        const product = "storage";
        const userType = user[product].type; //student, residential
        //console.log(ApplicationConstant.SEMESTER_PLAN);
        const {totalCost,discount} = user[product];
        
        let [discountDisplayLabel,discountAppliedDisplayText ]= ["",""];
        
        if(discount.voucherType === "GIFT_CARD"){
           discountDisplayLabel = "Gift card entered"
           discountAppliedDisplayText = `${accounting.formatMoney(discount.discountValue)}`;
        }else if(discount.voucherType === applicationConstantUtility.DISCOUNT_TYPE_COUPON){
           discountDisplayLabel = "Coupon code entered";
           discountAppliedDisplayText = `${discount.discountValue}% will be applied towards your first invoice`;
        }
           
        
  
        return <>
              <Row className="mt-2">
                  <Col xs="4">
                    {discountDisplayLabel}
                  </Col>
                  <Col xs="8">
                    {
                      <>
                      {discount.discountCode} {" "}
                      <Badge color="success" pill>
                        Valid
                      </Badge>
                      </> 
                    }  
                  </Col>
              </Row>
              <Row className="mt-2">
                  <Col xs="4">
                    Discounts Applied
                  </Col>
                  <Col xs="8">
                    {discountAppliedDisplayText}  
                  </Col>
              </Row>
              </>
     }
  
    displayTotalAmount = ()=>{
      const {user} = this.props;
      const product = "storage";
      const userType = user[product].type; //student, residential
      //console.log(ApplicationConstant.SEMESTER_PLAN);
      const {totalCost} = user[product];
      
      
      if(_.has(totalCost, ['finalTotal'])){
        let displayObject = {};
        if(totalCost.finalTotal >= 0)
          displayObject.displayLabel = "Total amount";
        else if(totalCost.finalTotal < 0)  
          displayObject.displayLabel = "Credit to be applied";
        
        displayObject.displayAmount  = accounting.formatMoney(totalCost.finalTotal);
  
        return    <Row className="mt-2">
                    <Col xs="4">
                      {displayObject.displayLabel}
                    </Col>
                    <Col xs="8">
                      {displayObject.displayAmount}  
                    </Col>
                  </Row>
  
      }
      else
        return "";
       
    }

    displayPaymentInformation = ()=>{
        const {user} = this.props;
        const {existingPaymentInformationOnFile,updatePaymentInformationFlag,paymentInformation} =  user;
        const {
            cardExpirationDate,
            cardNumber,
            cardType,
            isExpired
        }  = existingPaymentInformationOnFile;
       
        let [cardNumberDisplay,expirationMonthYearDisplay] = ["",""];
        if(updatePaymentInformationFlag === true){
            cardNumberDisplay = `xxxxxxxxxxx - ${ !_.isEmpty(paymentInformation.creditCardStripeToken.card) && paymentInformation.creditCardStripeToken.card.last4}`
            expirationMonthYearDisplay = `${!_.isEmpty(paymentInformation.creditCardStripeToken.card) && moment(`${paymentInformation.creditCardStripeToken.card.exp_year}-${paymentInformation.creditCardStripeToken.card.exp_month}-1`).format('MMMM')} - ${ !_.isEmpty(paymentInformation.creditCardStripeToken.card) && paymentInformation.creditCardStripeToken.card.exp_year}`
        }
        else{
            cardNumberDisplay = `${cardType} - ${cardNumber}`;
            expirationMonthYearDisplay = cardExpirationDate;
        }

        return(
            <>
            {   updatePaymentInformationFlag && 
                <Row className="mt-2">
                    <Col xs="4">
                     Credit Card Holder
                    </Col>
                    <Col xs="8">
                    {paymentInformation.creditCardName}
                    </Col>
                </Row>
            }

            <Row className="mt-2">
                <Col xs="4">
                 Credit Card Number
                </Col>
                <Col xs="8">
                    {cardNumberDisplay}
                </Col>
            </Row>

            <Row className="mt-2">
                <Col xs="4">
                 Expiration Month - Year
                </Col>
                <Col xs="8">
                    {expirationMonthYearDisplay}
                </Col>
            </Row>
            </>
        )

    }

    displayRedemptionTextInModal = ()=>{
        return(
        <p>We will apply the amount you would like to redeem as a credit towards the invoice once we have picked up the items.</p>
        )
    }

    getDeliveryAddress = ()=>{
        let address = "";
        const {user} = this.props;
        const product = "storage";
        const {deliveryAddress} = user[product];

        const {
            isSameAsPickupAddress,
            deliverToNewAddress,
            deliveryAddressLater
        } = deliveryAddress;

        if(isSameAsPickupAddress === true)
          return this.getPickupAddress();
        if(deliveryAddressLater === true)
          return "Will enter delivery address at a later time";  
        if(deliverToNewAddress === true){
            const {streetNumber,addressLine1, apartmentNumber,city,state,postalCode,isDormitory,dormitory} = deliveryAddress;
            const {name:dormitoryName,roomNumber}  = dormitory
            if(isDormitory === false && streetNumber)
            address = <span>{`${streetNumber}, ${addressLine1} ${apartmentNumber}`} <br/> {`${city}, ${state} - ${postalCode}`}</span>;
            else if(isDormitory === true && dormitoryName)
            address = <span>{`${dormitoryName}, ${roomNumber}`} <br/> {`${city}, ${state} - ${postalCode}`}</span>;
            
            return address;
        }
    }

    getPickupAddress = ()=>{
        const {user} = this.props;
        const {updatePrimaryAddressFlag} = user;
        const product = "storage";
        
        if(updatePrimaryAddressFlag === false)
           return this.getCurrentAddressForDisplay();
        else{
            const {pickUpAddress} = user[product];
            const {streetNumber,addressLine1, apartmentNumber,city,state,postalCode,isDormitory,dormitory} = pickUpAddress;
            const {name:dormitoryName,roomNumber}  = dormitory
            let address = "";
            if(isDormitory === false)
            address = <span>{`${streetNumber}, ${addressLine1} ${apartmentNumber}`} <br/> {`${city}, ${state} - ${postalCode}`}</span>;
            else
            address = <span>{`${dormitoryName}, ${roomNumber}`} <br/> {`${city}, ${state} - ${postalCode}`}</span>;
            
            return address;
        }
    }


    getCurrentAddressForDisplay = ()=>{
        let displayAddress  = "";
        const {user} = this.props;
        const {primaryInformation} = user;
        if(primaryInformation !== ""){
            const {line1:addressLine1,line2:addressLine2} = UserService.getFormattedUserAddress(primaryInformation);
            return <>{addressLine1} <br/> {addressLine2}</>;
        }

        return displayAddress
    }

    getStorageDeposit = ()=>{
        const {user} = this.props;
        const product = "storage";
        const userType = user[product].type; //student, residential
        if(!_.isEmpty(user[product][userType])){
            const {pricingProperties} = user[product][userType];
            const {storageDeposit} = pricingProperties;
            return accounting.formatMoney(storageDeposit);
        }else{
            return accounting.formatMoney(0);
        }
        
    }

    /*
      Validate if a coupon code is entered
    */
   handleApplyCoupon = async (e)=>{
        try{
            e.preventDefault(); 
            const product = "storage";
            const {onClickOfApplyCouponButtonFromReviewScreen,user} = this.props;
            const userType = user[product].type; //student, residential
            const {totalCost} = user.storage;
            const {student:storageProperties} = user.storage;
            const isSemesterPlan = false;
        

            //client side errors will be caught in validate(), server side data error will be caught via try/catch
            const errors =  this.validateCoupon(
                _.omit({...this.state.data}, ['agreeTermsAndConditions'])
            );
            this.setState({errors: errors || {}});
            if(errors){scrollTop('sectionArea'); return;} 

            this.setState({couponProcessingFlag:true});
            
            let formDataObject = {
            ...this.state.data,
            product,
            productType:null, 
            userType:userType,
            student:{
                universityId:user.school.university.university_id,
                campusId:user.school.id
            }
            };

            /* Send data to server */
                const {
                status:processingStatus,
                message,
                couponCodeReturnObject} = await UserService.validateCouponCode(formDataObject);
                
                this.setState({couponProcessingFlag:false});
                if(processingStatus === true){
                const {couponType,giftCardBalance,couponDetails} = couponCodeReturnObject;
                const data = {
                    ...this.state.data,
                    couponType,
                    giftCardBalance,
                    couponDetails,
                }
                
                if(couponType === applicationConstantUtility.DISCOUNT_TYPE_GIFTCARD){
                    const launchGiftcardRedemptionModal = (giftCardBalance > totalCost.finalTotal) ? true:false;

                    if(launchGiftcardRedemptionModal){
                    //By the pound plan 
                    //We need to have them enter a amount in a modal window that they would like to 
                    //We ask the user how much they want to redeem in order to updateamountToBeUsedForRedemption
                    
                    //Launch modal -- PENDING
                    this.setState({data});
                    this.setGiftCardRedemptionModalDisplayFlag(true);
                    }
                    else{
                    //Semester plan
                    data.giftCardAmountToBeUsedForRedemption = giftCardBalance;
                    this.setState({data});
                    return onClickOfApplyCouponButtonFromReviewScreen(data);
                    }

                }
                else if(couponType === applicationConstantUtility.DISCOUNT_TYPE_COUPON){
                    this.setState({data});
                    return onClickOfApplyCouponButtonFromReviewScreen(data);
                }

                //Need to update signup construct if valid  
                    

                }
                else
                    customToast.error(message);
            /* End of Send data to server */
            
            
            return;
        }catch(ex){
            //console.log(ex);
            this.setState({couponProcessingFlag:false});
            /*
            400 signals validation errors from the server
            renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop('sectionArea'); return;}
            }
            else
            renderError(ex);
        }
            

    }

    handleCheckbox= ()=>{
        const data  = {...this.state.data};
        data.agreeTermsAndConditions = !data.agreeTermsAndConditions
        this.setState({data})
    } 

    handleGiftCardCreditFromRedemptionModal = ({giftCardAmountToBeUsedForRedemption})=>{
        const {onClickOfApplyCouponButtonFromReviewScreen} = this.props;
        const data  = {
          ...this.state.data,
          giftCardAmountToBeUsedForRedemption,
        }
        
        this.setGiftCardRedemptionModalDisplayFlag(false);
        this.setState({data})
        return onClickOfApplyCouponButtonFromReviewScreen(data);
    }

    isCouponCodeEntered = ()=>{

        const {user} = this.props;
        const product = "storage";
        const userType = user[product].type; //student, residential
        const {totalCost,discount} = user.storage;
        return (discount.discountCode !== "") ? true:false;
           
  
    }

    setGiftCardRedemptionModalDisplayFlag = (flag)=>{
        this.setState({
            giftCardRedemptionModalDisplayFlag:flag    
        })
    }

    //This gets executed once the parent submission process is executed
    submitLocal = ()=>{
        const {onClickOfPurchaseButtonFromReviewScreen} = this.props;
        console.log('submit local');
        //const formData = this.state.data;
        return onClickOfPurchaseButtonFromReviewScreen();
        
    }

    validateLocal= ()=>{
        //This is anything specific to the form like async validations
        
    }

    validateCoupon=  (data)=>{
        const options = {abortEarly:false,allowUnknown:true};
        //console.log(this.state.data);
        //console.log(this.validationSchema.validate(this.state.data,options))
        let {error:errorListFromJoi} = this.validationCouponSchema.validate(data,options);
        //console.log(errorListFromJoi);
        if( errorListFromJoi === undefined) return null;

        const errorList = {};
        if(errorListFromJoi){
            errorListFromJoi.details.map(
                function(errorObject){
                    //console.log(errorObject);
                    if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                        const key = errorObject.context.key;
                        const joiType = errorObject.type;
                        //console.log(this.errorMessages);
                        if(_.has(this.errorMessages, [key, joiType]))
                            return errorList[errorObject.path[0]] = this.errorMessages[key][joiType];
                        else
                            return errorList[errorObject.path[0]] = errorObject.message;
                    }
                    else
                      return errorList[errorObject.path[0]] = errorObject.message;
                }.bind(this)
            );
        }
        
        const errors = {...errorList} 
        
        return Object.keys(errors).length > 0 ? errors:null;
    }


    render(){
        const {onClickOfBackButtonFromReviewScreen,user,serverErrors} = this.props;
        const {couponProcessingFlag,giftCardRedemptionModalDisplayFlag,data,errors} = this.state;
        const {agreeTermsAndConditions,couponCode,giftCardBalance} = data;
       
        const product = "storage";
        const userType = user[product].type; //student, residential
        const {totalCost} = user.storage;
        const {student:productProperties} = user.storage;

        const pickupAddress = this.getPickupAddress();
        const deliveryAddress = this.getDeliveryAddress();

        
        

        return(
            <>
            <Container id="mainContentArea">
                <Row className="title-row justify-content-between">
                    <Col lg="5">
                        <h3 className="shop">Signup for Storage</h3>
                    </Col>
                    <Col lg="6">
                        <div className="text-lg-right">
                            <span className="text-muted">Step 4</span>
                            <Button color="link">
                            <i className="fa fa-shopping-cart" /> Review your selection
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row className="title-body-row">
                    <Col md="7">
                        <Card>
                        <CardBody>
                            {/*Server Error display */}
                            {!_.isEmpty(serverErrors) &&
                                <FormErrorWidget
                                    errors={serverErrors}
                                />
                            }
                            {/* End of Server Error display */}
                            {/*Error display */}
                            {!_.isEmpty(errors )&&
                                <FormErrorWidget
                                    errors={errors}
                                />
                            }
                            {/* End of Error display */}
                            <div>
                                <h6 className="card-category">
                                    <span className="text-left">
                                        Your Storage Product
                                    </span>
                                </h6>
                                <hr/>
                                <Row>
                                    <Col xs="4">
                                     Semester
                                    </Col>
                                    <Col xs="8">
                                    {productProperties.semester.semester}
                                    </Col>
                                </Row>

                                <Row className="mt-2">
                                    <Col xs="4">
                                     Deposit
                                    </Col>
                                    <Col xs="8">
                                    {this.getStorageDeposit()}
                                    </Col>
                                </Row>

                                {
                                    this.isCouponCodeEntered() && 
                                    this.displayDiscountApplied()  
                                    
                                }

                                { !this.isCouponCodeEntered() &&  
                                    <Row className="mt-2">
                                        <Col xs="4">
                                         Enter Coupon or Gift Card Code
                                        </Col>
                                        <Col xs="4">
                                             <Input 
                                                type="text" 
                                                name="couponCode" 
                                                id="couponCode" 
                                                placeholder="" 
                                                value={couponCode} 
                                                onChange={this.handleChange}
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col xs="4" className="mt-1">
                                            <Button className="btn-round" color="default" size="sm"
                                            onClick={this.handleApplyCoupon}
                                            >
                                            {
                                                couponProcessingFlag &&
                                                <div className="uil-reload-css reload-small mr-1">
                                                <div />
                                                </div>
                                            }
                                            
                                            Apply
                                            </Button>
                                        </Col>
                                    </Row>
                                }

                                { 
                                    this.displayTotalAmount()   
                                }
                            </div> 

                            <div className="mt-3">
                                <h6 className="card-category">
                                    <span className="text-left">
                                        Payment Information
                                    </span>
                                </h6>
                                <hr/>
                                
                                {  this.displayPaymentInformation() }
                                
                                {
                                    _.has(totalCost, ['finalTotal']) && totalCost.finalTotal > 0 && 
                                    <Row className="mt-2">
                                    <Col xs="4">
                                        Amount be charged
                                    </Col>
                                    <Col xs="8">
                                        {(_.has(totalCost, ['finalTotal'])) ? accounting.formatMoney(totalCost.finalTotal):"$ 0.00"} 
                                    </Col>
                                    </Row>
                                }


                                <Row className="mt-4">  
                                    <Col xs="1" className="pt-1">
                                    <CustomInput
                                        type="checkbox"
                                        id="agreeTermsAndConditions"
                                        name="agreeTermsAndConditions"
                                        label=""
                                        value={agreeTermsAndConditions}
                                        onChange={()=>this.handleCheckbox()}
                                        checked={agreeTermsAndConditions}
                                    />
                                    </Col>
                                    <Col className="pl-0">
                                        I agree to the {' '}
                                        <a 
                                            href={`${process.env.REACT_APP_API_URL}/users/terms-and-conditions`}
                                            className="btn-link btn btn-info btn-sm pl-0"
                                            target="_blank"
                                        >
                                            Terms and Conditions
                                        </a>
                                    </Col>
                                </Row>
                                

                                
                            </div>
                            
                            <FormGroup row className="mt-5 justify-content-between">
                                <Col xs={{ size: 2 }}>
                                    <Button 
                                    className="btn-rounded btn-danger"
                                    onClick={()=>onClickOfBackButtonFromReviewScreen()}
                                    >
                                    Back
                                    </Button>
                                </Col>
                                <Col xs={{ size: 6}} sm={{ size: 4}} className="text-right">
                                    <Button 
                                    className="btn-rounded btn-danger"
                                    onClick={this.submitForm}
                                    >
                                    Save & Review
                                    </Button>
                                </Col>
                            </FormGroup>
                        </CardBody>
                    </Card>
                    </Col>
                    <Col md="5" className="order-first order-sm-first order-md-last">
                        <Card>
                            <CardBody>
                                <div>
                                    <h6 className="card-category">
                                        <span className="text-left">
                                          Campus Address
                                        </span>
                                    </h6>
                                    <hr/>
                                    <Row>
                                      <Col xs="12">
                                        { pickupAddress }
                                      </Col>
                                      
                                    </Row>
                                    
                                </div>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <div>
                                    <h6 className="card-category">
                                        <span className="text-left">
                                          Delivery Address
                                        </span>
                                    </h6>
                                    <hr/>
                                    <Row>
                                      <Col xs="12">
                                        {(deliveryAddress === "") ? "Delivery address not entered":deliveryAddress}
                                      </Col>
                                      
                                    </Row>
                                    
                                </div>
                            </CardBody>
                        </Card>
                    
                    </Col>            
                </Row> 
            </Container>
            {/* Gift Card Redemption Generator Modal */}
            {
                <GiftCardRedemptionModal
                    modalName="giftCardRedemptionModal"
                    divAreaToScrollForError="giftCardRedemptionModal"
                    modalDisplayFlag={giftCardRedemptionModalDisplayFlag}
                    handleModalDisplay={this.setGiftCardRedemptionModalDisplayFlag}
                    handleSaveOperation = {this.handleGiftCardCreditFromRedemptionModal}
                    giftCardBalance={giftCardBalance}
                    displayRedemptionText={this.displayRedemptionTextInModal()}
                    
                />
            }  
            </>
        )
    }
}

export default ReviewScreen;