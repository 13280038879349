import React,{Component} from "react";
import _ from "lodash";
import moment from "moment";
import UserService from "../../../../../../../services/userService";

import {scrollTop} from '../../../../../../../utils/helperUtils';
import FormErrorWidget from "../../../../../../Common/FormErrorWidget";
import {renderError} from '../../../../../../../utils/errorUtils';


// reactstrap components
import {
    Button,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    FormGroup, 
    Label,
    Input, 
    Table,
  } from "reactstrap";

  class DeliveryScheduleReviewStep4 extends Component{

    constructor(){
        super();
        this.state={
            data:{},
            errors:{},
        }
    }

    handleSubmit = (e)=>{
        try{
            e.preventDefault();  
            const {handleSubmit} = this.props;  
            return handleSubmit();
            
        }catch(ex){
            renderError(ex);
        }
    }

    getDeliveryDateAndTimeDisplayText = ()=>{
       let deliveryDateAndTimeDisplayText = "";
       const {deliveryDateAndTime,deliveryAddress} =  this.props.deliverySchedulingData;
       const {earlyDeliveryIndicatorProperties} = deliveryAddress
       
       const {deliveryDay:deliveryDayMoment,deliveryStartTime,deliveryEndTime} = deliveryDateAndTime;
       const deliveryDay = deliveryDayMoment.format('YYYY-MM-DD');
       const deliveryStartTimeMoment = moment(`${deliveryDay} ${deliveryStartTime}`);
       const deliveryEndTimeMoment = moment(`${deliveryDay} ${deliveryEndTime}`);
       
       if(earlyDeliveryIndicatorProperties.status === true)
        deliveryDateAndTimeDisplayText = `${deliveryDayMoment.format('MMM Do')} - Scheduled for early delivery.`;
       else
        deliveryDateAndTimeDisplayText = `${deliveryDayMoment.format('MMM Do')} between ${deliveryStartTimeMoment.format('h:mm a')} - ${deliveryEndTimeMoment.format('h:mm a')}`;
       
       return deliveryDateAndTimeDisplayText;
    }

    getFormattedCurrentCampusAddressForUser = (user)=>{
        const {line1:addressLine1,line2:addressLine2} = UserService.getFormattedUserAddress(user);
        return <>{addressLine1} <br/> {addressLine2}</>;
    }

    getFormattedDeliveryAddress = ()=>{
        let [formattedAddress,addressObject,completeStreetAddress] = ["","",""];
        const {deliverySchedulingData,user} = this.props;
        const {deliveryAddress} = deliverySchedulingData;
        if(deliveryAddress.deliverToYourCurrentAddress === true){
            formattedAddress =  this.getFormattedCurrentCampusAddressForUser(user)
        }
        else{
            const {
                isDeliveryAddressYourNewCampusAddress,
                isDormitory,
                dormitoryName, //{value:"", label:""} this corresponds to the react-select value setup
                dormitoryRoomNumber,
                streetNumber,
                addressLine1,
                addressLine2,
                apartmentNumber,
                city,
                state,
                postalCode,
            }  = deliveryAddress;

            if(isDormitory === "No"){
                completeStreetAddress = `${streetNumber} ${addressLine1}`;
                if(apartmentNumber)
                    completeStreetAddress = `${completeStreetAddress}, Apt:${apartmentNumber}`;
                
                addressObject = {
                    line1:completeStreetAddress,
                    line2:`${city}, ${state.value} - ${postalCode}`,
                }
               
            }else if(isDormitory === "Yes"){
                completeStreetAddress = `${dormitoryName.label}, Room #:${dormitoryRoomNumber}`;
                addressObject = {
                    line1:completeStreetAddress,
                    line2:`${city}, ${state.value} - ${postalCode}`,
                }
            }

              
            const {line1,line2} = addressObject;
            let changeOfCurrentAddressNote = ""
            if(isDeliveryAddressYourNewCampusAddress)
                changeOfCurrentAddressNote = <><br/><strong>Please note:</strong><br/>  We will be updating your current campus address to your delivery address.</>;
            
            return <>{line1} <br/> {line2} {changeOfCurrentAddressNote}</>;
        }

        return formattedAddress
        
    }

    getSelectedItems = ()=>{
        const {listOfItemsSelectedForDelivery} =  this.props.deliverySchedulingData;
        return listOfItemsSelectedForDelivery.map(s=>{
            const {storage_item_id:storageItemId,barcode,description} = s;
            return <tr key={storageItemId}>
                        <td>{description}</td>
                        <td>{barcode}</td>
                    </tr>;
        })
    }

    render(){
        const {handleBack,modifyFlag} = this.props;
        const {data,errors} = this.state;
        const {schedulingNotes} = this.props.deliverySchedulingData;
        
        return(
            <>
                {/*Error display */}
                {!_.isEmpty(errors) &&
                    <FormErrorWidget
                        errors={errors}
                    />
                }
                {/* End of Error display */}

                <Row>
                    <Col xs="4">
                        <h6>Date and Time</h6>
                    </Col>
                    <Col>
                        {this.getDeliveryDateAndTimeDisplayText()}
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col xs="4">
                        <h6>Delivery Address</h6>
                    </Col>
                    <Col>
                        <p>
                            {this.getFormattedDeliveryAddress()}
                        </p>
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col xs="4">
                        <h6>Delivery Notes:</h6>
                    </Col>
                    <Col>
                        <p>
                            {schedulingNotes}
                        </p>
                    </Col>
                </Row>


                <Row className="mt-4">
                    <Col>
                        <h6>Selected Items for Delivery:</h6>
                        <Table responsive striped className="section-gray">
                            <thead>
                                <tr>
                                    <th className="table-header-font-strong">Item Description</th>
                                    <th className="table-header-font-strong">Item Code</th>
                                    
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {this.getSelectedItems()}
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                

                <FormGroup row  className="mt-3 justify-content-between">
                    <Col xs={{ size: 2}}>
                        <Button 
                            className="btn-rounded btn-danger"
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                    </Col>
                    <Col xs={{ size: 6}} sm={{ size: 4}} className="text-right">
                        <Button 
                            className="btn-rounded btn-danger"
                            onClick={this.handleSubmit}
                        >
                            {modifyFlag ? `Modify Delivery Schedule`:`Confirm Delivery Schedule`}
                        </Button>
                    </Col>
                </FormGroup>

            </>
        )
    }
  }

  export default DeliveryScheduleReviewStep4;
