const urlTwitter = "https://twitter.com/mylazybones";
const urlFacebook = "https://business.facebook.com/myLazybones/?business_id=613858228989851";
const urlInstagram = "https://www.instagram.com/lazybones_inc/";

const FINAL_PROCESSING_ERROR_TYPE_SIGNUP = "database-error-from-signup";

const DORMITORY = 1;
const NON_DORMITORY = 2;
const PICKUP = 1;
const DELIVERY = 2;
const LAUNDRY_RECURRING_SCHEDULE = 1;
const LAUNDRY_ONETIME_SCHEDULE = 2;
const BY_THE_POUND = 1;
const BY_THE_POUND_DESCRIPTION = "By the pound service";
const BY_THE_POUND_LAUNDRY_PACKAGE_ID = 0;
const SEMESTER_PLAN = 2;
const CUSTOMER_TYPE_STUDENT = 1;
const CUSTOMER_TYPE_RESIDENTIAL= 2;
const CUSTOMER_TYPE_COMMERCIAL = 3;
const CUSTOMER_TYPE_STUDENT_TEXT = "student";
const CUSTOMER_TYPE_RESIDENTIAL_TEXT= "residential";
const CUSTOMER_TYPE_COMMERCIAL_TEXT = "commercial";
//commercial customer St johs and st andrews
const COMMERCIAL_CUSTOMER_TYPE_SCHOOL = 'commercial_school';
//commercial customer who just pay by the pound
const COMMERCIAL_CUSTOMER_TYPE_BY_THE_POUND = 'commercial_by_the_pound';

const STORAGE_ITEMS_FOR_PICKUP_MORE_ITEMS =  "15 or more";
const STORAGE_ITEMS_FOR_PICKUP_LESS_ITEMS =  "Less than 15";
const STORAGE_ITEMS_FOR_PICKUP_LARGE_ITEMS_YES =  "Yes, large items";
const STORAGE_ITEMS_FOR_PICKUP_LARGE_ITEMS_NO =  "No large items";
const STORAGE_DIMENSION_PRICE_TYPE_LONGEST_SIDE =  2;
const STORAGE_DIMENSION_PRICE_TYPE_SECOND_LONGEST_SIDE =  3;
const STORAGE_DIMENSION_PRICE_TYPE_DEFAULT =  1;
const STORAGE_EARLY_DELIVERY_TIME_ID =  -1;
const STORAGE_EARLY_DELIVERY_TIME_START_TIME =  "08:00:00";
const STORAGE_EARLY_DELIVERY_TIME_END_TIME =  "17:00:00";




const DISCOUNT_TYPE_GIFTCARD = "GIFT_CARD";
const DISCOUNT_TYPE_REFERRAL = "REFERRAL";
const DISCOUNT_TYPE_COUPON = "COUPON";

const DISCOUNT_VALUE_TYPE_DOLLAR = "DOLLAR";
const DISCOUNT_VALUE_TYPE_PERCENT = "PERCENT";


const customerPrimaryInformationComponentDisplayProperties = {
    viewProfile:{
        headerTitle: 'Primary Informaion',
        currentAddressTitle:'Primary Information on File',
        stepTitle:'',
        step:'',
        displayHeader:false,
        
    },
}


const customerAddressComponentDisplayProperties = {
    existingUserSignUp:{
        student:{
            laundry:{
                headerTitle: 'Signup for Laundry - Student',
                currentAddressTitle:'Address Information on File',
                stepTitle:'Pickup/Delivery Address Information',
                step:'Step 3',
            },
            storage:{
                headerTitle: 'Signup for Storage - Student',
                currentAddressTitle:'Pickup Address Information (Campus Address)',
                stepTitle:'Pickup Address Information',
                step:'Step 2',
            }
        },
        residential:{
            laundry:{
                headerTitle: 'Signup for Laundry - Residential',
                currentAddressTitle:'Address Information on File',
                stepTitle:'Pickup/Delivery Address Information',
                step:'Step 3',
            },
            
        }
    },
}

const deliveryAddressComponentDisplayProperties = {
    existingUserSignUp:{
        student:{
            storage:{
                headerTitle: 'Signup for Storage - Student',
                currentAddressTitle:'Delivery Address Information',
                stepTitle:'Delivery Address Information',
                step:'Step 3',
            }
        }
    },
}

const paymentInformationComponentDisplayProperties = {
    existingUserSignUp:{
        student:{
            laundry:{
                headerTitle: 'Signup for Laundry - Student',
                stepTitle:'Update Credit Card Information',
                step:'Step 3',
            },
            storage:{
                headerTitle: 'Signup for Storage - Student',
                stepTitle:'Update Credit Card Information',
                step:'Step 2',
            },
            supplies:{
                headerTitle: 'Purchase Supplies',
                stepTitle:'Update Credit Card Information',
                step:'Step 2',
            }
        },
        residential:{
            laundry:{
                headerTitle: 'Signup for Laundry - Residential',
                stepTitle:'Update Credit Card Information',
                step:'Step 3',
            },
            supplies:{
                headerTitle: 'Purchase Supplies',
                stepTitle:'Update Credit Card Information',
                step:'Step 2',
            }
        }
    },
    viewProfile:{
        headerTitle: 'Payment Informaion',
        currentAddressTitle:'Payment Information on File',
        stepTitle:'',
        step:'',
        displayHeader:false,
    }
}

export default {
    DORMITORY,
    NON_DORMITORY,
    PICKUP,
    DELIVERY,
    DISCOUNT_TYPE_COUPON,
    DISCOUNT_TYPE_GIFTCARD,
    DISCOUNT_TYPE_REFERRAL,
    DISCOUNT_VALUE_TYPE_DOLLAR,
    DISCOUNT_VALUE_TYPE_PERCENT,
    FINAL_PROCESSING_ERROR_TYPE_SIGNUP,
    LAUNDRY_RECURRING_SCHEDULE,
    LAUNDRY_ONETIME_SCHEDULE,
    BY_THE_POUND,
    BY_THE_POUND_LAUNDRY_PACKAGE_ID,
    BY_THE_POUND_DESCRIPTION,
    SEMESTER_PLAN,
    CUSTOMER_TYPE_STUDENT,
    CUSTOMER_TYPE_RESIDENTIAL,
    CUSTOMER_TYPE_COMMERCIAL,
    CUSTOMER_TYPE_STUDENT_TEXT,
    CUSTOMER_TYPE_RESIDENTIAL_TEXT,
    CUSTOMER_TYPE_COMMERCIAL_TEXT,
    COMMERCIAL_CUSTOMER_TYPE_SCHOOL,
    COMMERCIAL_CUSTOMER_TYPE_BY_THE_POUND,
    STORAGE_ITEMS_FOR_PICKUP_MORE_ITEMS,
    STORAGE_ITEMS_FOR_PICKUP_LESS_ITEMS,
    STORAGE_ITEMS_FOR_PICKUP_LARGE_ITEMS_YES,
    STORAGE_ITEMS_FOR_PICKUP_LARGE_ITEMS_NO,
    STORAGE_DIMENSION_PRICE_TYPE_LONGEST_SIDE,
    STORAGE_DIMENSION_PRICE_TYPE_SECOND_LONGEST_SIDE,
    STORAGE_DIMENSION_PRICE_TYPE_DEFAULT,
    STORAGE_EARLY_DELIVERY_TIME_ID,
    STORAGE_EARLY_DELIVERY_TIME_START_TIME,
    STORAGE_EARLY_DELIVERY_TIME_END_TIME,
    customerAddressComponentDisplayProperties,
    customerPrimaryInformationComponentDisplayProperties,
    deliveryAddressComponentDisplayProperties,
    paymentInformationComponentDisplayProperties,
    urlFacebook,
    urlInstagram,
    urlTwitter,
}