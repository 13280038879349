import React,{Component} from "react";
import accounting from "accounting"
import ReactDatetime from "react-datetime";
import moment from "moment";

import auth from "../../services/authService";

import customToast from "../../components/Common/CustomToast";

import ShortNavbar from "../../components/Navbars/ShortNavbar";
import SuppliesPageHeader from "../../components/Headers/SuppliesPageHeader";
import FooterBlack from "../../components/Footers/FooterBlack";


import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Container,
    Badge,
    Button,
    ButtonGroup,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Nav,
    NavLink,
    NavItem,
    TabContent,
    TabPane,
    Table,
    Media,
    UncontrolledTooltip
} from "reactstrap";

class ShoppingCart extends Component{
    
    constructor(){
        super();
        this.state = {
            shippingDate:"",
        }
    }

    componentDidMount(){
        this.mapModelToView();
    }

    

    handleShippingDate = (value)=>{
        const {handleShoppingCartShippingDate} = this.props;
        
        this.setState({
            shippingDate:value
        })
        handleShoppingCartShippingDate(value);
    }

    proceedToCheckout = (e)=>{
        e.preventDefault();

        const shoppingCart = {...this.props.globalApp.shoppingCart}
        const {cartTotal,supplies,university} = shoppingCart;
        const {shippingDate} = supplies;
        const {minimum_supply_cart_total:minimumShoppingCartAmount} = university;
        const {subTotal} = cartTotal;
        if(subTotal < minimumShoppingCartAmount){
            customToast.error(`Please enter a minimum amount of ${accounting.formatMoney(minimumShoppingCartAmount)} to proceed.`)
            return;
        }

        if(shippingDate === ""){
            customToast.error(`Please select a delivery date.`)
            return;
        }

        this.props.history.push("/shoppingCart/supplies/checkout");
        
        return;
    }

    mapModelToView(){
        const {shoppingCart}=  this.props.globalApp;
        const {shippingDate} = this.state;
        const {shippingDate:shippingDateFromCart} = shoppingCart.supplies;
        
        if(!moment(shippingDate).isSame(moment(shippingDateFromCart))){
            
            this.setState({
                shippingDate:shippingDateFromCart
            })
        }
       
    }

    render(){
        const {onUpdateOfItem,onRemoveOfItem} = this.props;
        const {shoppingCart}=  this.props.globalApp;
        const {
            postalCodeShippingStartDateToBlock:shippingDateBlockStartDate,
            postalCodeShippingEndDateToBlock:shippingDateBlockEndDate,
        } = shoppingCart;
        const {salesTaxPercent,subTotal,totalTax,total} = shoppingCart.cartTotal;
        const {shippingDate} = shoppingCart.supplies;
        const products = [...shoppingCart.supplies.items];
        const updateDefaultQuantity = 1;

        return(
            <>
                <ShortNavbar {...this.props}/>
                <SuppliesPageHeader />  
                <div className="main">
                    <div id="sectionArea" className="section section-gray" style={{paddingTop:"0px"}}>
                        <Container id="mainContentArea">
                            <Row>
                                <Col lg="10">
                                    <h3 className="title">Your Shopping Cart</h3>
                                </Col>
                            </Row> 
                            
                            {/* Desktop view */}
                            <div className="d-none d-sm-block">
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col className="ml-auto mr-auto">
                                                    <Table className="table-shopping" responsive>
                                                        <thead className="">
                                                        <tr className="">
                                                            <th className="text-center border-top-0" />
                                                            <th className="text-right border-top-0">Price</th>
                                                            <th className="text-right border-top-0">Quantity</th>
                                                            <th className="text-right border-top-0 pr-4">Total</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {   (products.length === 0) && 
                                                            <tr>
                                                                <td colSpan="4" className="text-center"><strong>No items in cart</strong></td>
                                                            </tr>
                                                        }

                                                        {
                                                            products.length > 0 &&
                                                            products.map(p=>{
                                                                const {
                                                                    title,
                                                                    description,
                                                                    price,
                                                                    id,
                                                                    quantity,
                                                                    imagePath,
                                                                    isBundle,
                                                                } = p;
                                                            
                                                                const key = (isBundle === true) ? `bundle-${id}`:`indiviudal-${id}`; 
                                                                return(
                                                                    <tr key={key}>
                                                                        <td className="td-product">
                                                                            <div className="img-container">
                                                                                <img
                                                                                alt="..."
                                                                                src={require(`assets/mylazybones${imagePath}`)}
                                                                                />
                                                                            
                                                                            </div>
                                                                            {p.title}
                                                                        </td>
                                                                        <td className="td-price">
                                                                            {accounting.formatMoney(price)}
                                                                        </td>
                                                                        <td className="td-number td-quantity">
                                                                        {`${quantity} `}
                                                                        <ButtonGroup>
                                                                            <Button
                                                                                className="btn-border btn-round"
                                                                                color="default"
                                                                                size="sm"
                                                                                onClick={e=>{
                                                                                    e.preventDefault();
                                                                                    return onRemoveOfItem(p,updateDefaultQuantity,"subtract")
                                                                                }}
                                                                            >
                                                                            -
                                                                            </Button>
                                                                            <Button
                                                                                className="btn-border btn-round"
                                                                                color="default"
                                                                                size="sm"
                                                                                onClick={e=>{
                                                                                    e.preventDefault();
                                                                                    return onUpdateOfItem(p,updateDefaultQuantity,"add")
                                                                                }}
                                                                            >
                                                                            +
                                                                            </Button>
                                                                        </ButtonGroup>
                                                                        </td>
                                                                        <td className="td-number pr-4">
                                                                         {accounting.formatMoney(price * quantity)}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }


                                                        <tr>
                                                            <td colSpan="2" />
                                                            <td className="td-total">Subtotal</td>
                                                            <td className="td-total pr-4">
                                                            {accounting.formatMoney(subTotal)}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colSpan="2" />
                                                            <td className="td-total">Sales Tax ({`${accounting.toFixed(salesTaxPercent, 2)}%`})</td>
                                                            <td className="td-total pr-4">
                                                            {accounting.formatMoney(totalTax)}
                                                            </td>
                                                        </tr>
                                                        
                                                        
                                                        <tr>
                                                            <td colSpan="2" />
                                                            <td className="td-total">Total</td>
                                                            <td className="td-total pr-4">
                                                            {accounting.formatMoney(total)}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colSpan="3" className="td-total">Select Delivery Date</td>
                                                            <td className="td-total pr-4">
                                                                <FormGroup>
                                                                    <InputGroup 
                                                                        name="shippingDate"
                                                                        className="date" 
                                                                        id="shippingDate"
                                                                        
                                                                    >
                                                                        <ReactDatetime
                                                                            value={shippingDate} 
                                                                            timeFormat={false}
                                                                            closeOnSelect={true}
                                                                            onChange={this.handleShippingDate}
                                                                            inputProps={{
                                                                                className: "form-control",
                                                                                placeholder: "Delivery Date",
                                                                            }}

                                                                            isValidDate={currentDate=>{
                                                                                let enableDate = false;
                                                                                enableDate = (moment.utc(currentDate).isAfter(moment.utc())) ? true:false;
                                                                                if(enableDate && shippingDateBlockStartDate && shippingDateBlockEndDate){
                                                                                    enableDate = 
                                                                                        (moment.utc(currentDate).isBefore(moment.utc(shippingDateBlockStartDate), 'day')
                                                                                         || 
                                                                                         moment.utc(currentDate).isAfter(moment.utc(shippingDateBlockEndDate), 'day')
                                                                                        ) ?  true:false;

                                                                                    //Disable next day
                                                                                    if(currentDate.isSame(moment().add(1,'days'),'day')){
                                                                                        enableDate = false;
                                                                                    }
                                                                                }
                                                                                return enableDate;
                                                                            }}
                                                                        />
                                                                        <InputGroupAddon addonType="append">
                                                                        <InputGroupText>
                                                                            <span className="glyphicon glyphicon-calendar">
                                                                            <i className="fa fa-calendar" />
                                                                            </span>
                                                                        </InputGroupText>
                                                                        </InputGroupAddon>
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </td>
                                                        </tr>

                                                        <tr className="tr-actions">
                                                            <td colSpan="2" />
                                                            <td className="text-right pr-4" colSpan="2">
                                                            <Button 
                                                                color="danger" 
                                                                size="lg" 
                                                                type="button"
                                                                onClick={this.proceedToCheckout}
                                                            >
                                                                Proceed to Checkout <i className="fa fa-chevron-right" />
                                                            </Button>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </Table>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                
                                    </Col>
                                </Row>
                            </div>
                            {/* Desktop view */}

                            {/* Mobile view */}
                            <div className="d-block d-sm-none">
                                {   (products.length === 0) && 
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardBody className="">
                                                    <p className="text-center">
                                                        <strong>No items in cart</strong>
                                                    </p>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                }

                                {
                                    products.length > 0 &&
                                    products.map(p=>{
                                        const {
                                            title,
                                            description,
                                            price,
                                            id,
                                            quantity,
                                            imagePath,
                                            isBundle,
                                        } = p;
                                    
                                        const key = (isBundle === true) ? `bundle-${id}`:`indiviudal-${id}`; 
                                        
                                        return(
                                            <Row key={key}>
                                                <Col>
                                                    <Card>
                                                        <CardBody className="">
                                                            <Row>
                                                                <Col xs="6" className="text-center">
                                                                    <img
                                                                    alt="..."
                                                                    className="img-rounded img-responsive"
                                                                    src={require(`assets/mylazybones${imagePath}`)}
                                                                    />
                                                                    <CardTitle tag="h5" className="text-danger">{title}</CardTitle>
                                                                    
                                                                </Col>
                                                                <Col xs="6">
                                                                    <Row>
                                                                        <Col xs="6"><p className="price">Unit Price</p></Col>
                                                                        <Col xs="6"><p className="price">{accounting.formatMoney(price)}</p></Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs="6"><p className="price">Qty</p></Col>
                                                                        <Col xs="6" className="text-center"><p className="price">{quantity}</p></Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs="6"><p className="price">Price</p></Col>
                                                                        <Col xs="6"><p className="price">{accounting.formatMoney(price * quantity)}</p></Col>
                                                                    </Row>
                                                                    
                                                                    <Row>
                                                                        <Col xs="6"><p className="price"></p></Col> 
                                                                        <Col xs="6" className="">
                                                                            <ButtonGroup>
                                                                                <Button
                                                                                className="btn-border btn-round"
                                                                                color="default"
                                                                                size="sm"
                                                                                onClick={e=>{
                                                                                    e.preventDefault();
                                                                                    return onUpdateOfItem(p,updateDefaultQuantity,"subtract")
                                                                                }}
                                                                                >
                                                                                -
                                                                                </Button>
                                                                                <Button
                                                                                className="btn-border btn-round"
                                                                                color="default"
                                                                                size="sm"
                                                                                onClick={e=>{
                                                                                    e.preventDefault();
                                                                                    return onUpdateOfItem(p,updateDefaultQuantity,"add")
                                                                                }}
                                                                                >
                                                                                +
                                                                                </Button>
                                                                            </ButtonGroup>
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        );
                                    })
                                }
                                
                                

                                <Row>
                                    <Col>
                                        <Card>
                                            <CardBody className="">
                                                <Row>
                                                    <Col xs="8"><p className="price text-right"><strong>Sub-Total</strong></p></Col>
                                                    <Col xs="4"><p className="price">{accounting.formatMoney(subTotal)}</p></Col>
                                                </Row>     
                                                        
                                                <Row>
                                                    <Col xs="8"><p className="price text-right"><strong>Sales Tax ({`${accounting.toFixed(salesTaxPercent, 2)}%`})</strong></p></Col>
                                                    <Col xs="4"><p className="price">{accounting.formatMoney(totalTax)}</p></Col>
                                                </Row>
                                                
                                                <hr/>
                                                
                                                <Row>
                                                    <Col xs="8"><p className="price text-right text-danger"><strong>Total</strong></p></Col>
                                                    <Col xs="4"><p className="price text-danger"><strong>{accounting.formatMoney(total)}</strong></p></Col>
                                                </Row>
                                                        
                                                        
                                                        
                                                    
                                                    
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Card>
                                            <CardBody className="">
                                                <CardTitle tag="h5" className="border-bottom">
                                                    Select Delivery Date
                                                </CardTitle>
                                                <div className="mt-2">
                                                    <FormGroup>
                                                        <InputGroup 
                                                            name="shippingDateMobile"
                                                            className="date" 
                                                            id="shippingDateMobile"
                                                            
                                                        >
                                                            <ReactDatetime
                                                                value={shippingDate} 
                                                                timeFormat={false}
                                                                closeOnSelect={true}
                                                                onChange={this.handleShippingDate}
                                                                inputProps={{
                                                                    className: "form-control",
                                                                    placeholder: "Delivery Date",
                                                                }}

                                                                isValidDate={currentDate=>{
                                                                    let enableDate = false;
                                                                    enableDate = (moment.utc(currentDate).isAfter(moment.utc())) ? true:false;
                                                                    if(enableDate && shippingDateBlockStartDate && shippingDateBlockEndDate){
                                                                        enableDate = 
                                                                            (moment.utc(currentDate).isBefore(moment.utc(shippingDateBlockStartDate), 'day')
                                                                                || 
                                                                                moment.utc(currentDate).isAfter(moment.utc(shippingDateBlockEndDate), 'day')
                                                                            ) ?  true:false;

                                                                        //Disable next day
                                                                        if(currentDate.isSame(moment().add(1,'days'),'day')){
                                                                            enableDate = false;
                                                                        }
                                                                    }
                                                                    return enableDate;
                                                                }}
                                                            />
                                                            <InputGroupAddon addonType="append">
                                                            <InputGroupText>
                                                                <span className="glyphicon glyphicon-calendar">
                                                                <i className="fa fa-calendar" />
                                                                </span>
                                                            </InputGroupText>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                                {/* Checkout Footer */}
                                <div className="mobile-cart-footer fixed-bottom border bg-light bx-0 py-2 px-3" style={{height:"60px"}}>
                                    <div className="d-flex flex-row-reverse bd-highlight">
                                        <div className="p-2 bd-highlight">
                                                <Button
                                                    className="btn btn-sm"
                                                    color="info"
                                                    onClick={this.proceedToCheckout}
                                                    outline
                                                >
                                                    Proceed to Checkout
                                                </Button>
                                        </div>
                                    </div>
                                    <Row  className="d-none">
                                        <Col xs="5" sm="9">


                                        </Col>
                                        
                                        <Col xs="7" sm="2" className="mt-1 text-center " >
                                            <div>
                                                <Button
                                                    className="btn btn-sm"
                                                    color="info"
                                                    onClick={this.proceedToCheckout}
                                                    outline
                                                >
                                                    Proceed to Checkout
                                                </Button>
                                            </div>  
                                        </Col>
                                    </Row>
                                        
                                </div>
                                {/* End of Checkout Footer */}
                            </div>
                            {/* End of Mobile view */}
                            
                        </Container>
                    </div>
                </div>
                <FooterBlack />
            </>
        );
    }
}

export default ShoppingCart;