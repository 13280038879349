import React, {Component} from "react";
import _ from "lodash"

// reactstrap components
import {
    Button,
    Container,
    Card,
    CardBody,
    CardFooter,
    CardImg,
    CardImgOverlay,
    CardLink,
    CardTitle,
    CardSubtitle,
    CardText,
    ListGroupItem,
    ListGroup,
    Row,
    Col,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    FormText,
    UncontrolledTooltip
    
  } from "reactstrap";

class ResidentialPricingAndInsurance extends Component{
    tooltipDescription = {
        "by-the-pound":"No plan is required. Whether you just want to try us out or just don't like commitments we'll pick up and deliver your laundry for one low price by the pound.",
        "semester-plan":"These plans include a laundry pick up once a week for the duration of the semester. They are the best for keeping you organized and clean on a regular schedule."
      }
  
    tooltipPlacement= {
        "by-the-pound":"left",
        "semester-plan":"right"
    }

    constructor(){
        super();
        this.state = {
            data:{
                laundryType:"",//{id,type} by the pound or semester
                pricePlan:"",    
                insurance:"", 
            },
            errors:{}        
        }
    }

    componentDidMount(){
        //console.log(this.props);
        document.getElementById("sectionArea").scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });
        this.mapResidentialModelToView();
    }

    componentDidUpdate(prevProps){
        const residentialCity = this.props.user.residentialCity;
        const residentialCityPrev = prevProps.user.residentialCity;
        if(residentialCity !== residentialCityPrev)
            this.mapResidentialModelToView();
    }

    handleSelectInsurancePlan = (i)=>{
        console.log(i); 
        const data = {...this.state.data};
        data.insurance = i;
        this.setState({data});   
    }

    handleSelectLaundryPlan = (l)=>{
        console.log(l)
        const laundryType = _.pick(l,['id','type']);
        const data = {...this.state.data}
        data.laundryType = laundryType;
        data.pricePlan = "";
        this.setState({data});

    }

    handleSelectPricePlan = (p)=>{
        const data = {...this.state.data}
        data.pricePlan = p;
        this.setState({data});
    }

    handleSubmit = (e)=>{
        e.preventDefault();
        console.log('Ready to save the data');
        const {onClickOfNextButtonFromPricingAndInsuranceInformation} = this.props;
        const pricingAndInsuranceInformation = {...this.state.data};
        onClickOfNextButtonFromPricingAndInsuranceInformation(pricingAndInsuranceInformation);
        return;

    }

    mapResidentialModelToView = ()=>{
        const {laundryType,pricePlan} = this.props.user.laundry.residential 
        let {insurance} = this.props.user.laundry.residential 
        const insuranceList = this.props.user.residentialCity.insurance;
        
        //Set default insurance if not selected
        const defaultInsurance = _.find(insuranceList,{'type':'Standard'});
        insurance = (!insurance) ? defaultInsurance:insurance;
        //console.log(insurance);

        const data = {
            laundryType,
            pricePlan,
            insurance 
        };
        if(!_.isEqual(this.state.data,data))
           this.setState({data});
    }

    render(){
        const {onClickOfBackButtonFromPricingAndInsuranceInformation,user}  = this.props;
        const {laundryType:selectedLaundryType,pricePlan:selectedPricePlan,insurance:selectedInsurance} = this.state.data;

        const laundryTypeList = user.residentialCity.pricing;
        const insuranceList = user.residentialCity.insurance;
        const priceList = selectedLaundryType && _.find(laundryTypeList,{'id':selectedLaundryType.id}).price;
        
        return(
            
            <Container id="mainContentArea">
                <Row className="title-row justify-content-between">
                    <Col md="6">
                        <h3 className="shop">Signup for Laundry - Residential</h3>
                    </Col>
                    <Col md="5">
                        <div className="text-md-right d-none">
                            <span className="text-muted">Step 2</span>
                            <Button color="link">
                                <i className="fa fa-shopping-cart" /> Select Plan and Insurance
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row className="title-body-row">
                    <Col md="12">
                        <Card>
                            <CardBody>
                                <Form>
                                    {   laundryTypeList && 
                                        <div>
                                            <h6 className="card-category text-left">Select Plan</h6>
                                            <hr/> 
                                            
                                            <FormGroup row className="justify-content-center">
                                                {
                                                    laundryTypeList.map(l=>{
                                                        return(
                                                            <Col key={l.id} sm={{ size: 6}} xs={{size:12}}> 
                                                                <Button
                                                                    className="btn-round mr-1"
                                                                    color="danger"
                                                                    outline={l.id != selectedLaundryType.id}
                                                                    block
                                                                    type="button"
                                                                    id={`btn_${l.type}`}
                                                                    onClick={()=>{this.handleSelectLaundryPlan(l)}}
                                                                >
                                                                    {l.description}
                                                                </Button>
                                                                {
                                                                //Noticed a slowdown when we use it in mobile view    
                                                                false &&     
                                                                <UncontrolledTooltip
                                                                    delay={0}
                                                                    placement={this.tooltipPlacement[l.type]}
                                                                    target={`btn_${l.type}`}
                                                                >
                                                                {this.tooltipDescription[l.type]}
                                                                </UncontrolledTooltip>
                                                                }
                                                            </Col>
                                                        )
                                                    }) 
                                                }
                                            </FormGroup>
                                            
                                        </div> 
                                    }
                                    
                                    {
                                        selectedLaundryType &&
                                        <div>
                                        <h6 className="card-category text-left">Select Price Plan</h6>
                                        <hr/> 
                                        <Row className="justify-content-center">
                                            {
                                                priceList.map(p=>{
                                                    
                                                    const selectedAttributes = (p.id === selectedPricePlan.id) ? {"data-color":"orange"}:{};
                                                    return(
                                                        <Col key={p.id} md="3" sm="6">
                                                            <Card className="card-pricing"  {...selectedAttributes}>
                                                                <CardBody>
                                                                    <CardTitle tag="h1">${p.price}</CardTitle>
                                                                    <ul>
                                                                        <li>
                                                                        {p.name}
                                                                        </li>
                                                                        <li>
                                                                        ${p.pricePerPound} per pound
                                                                        </li>
                                                                        <li>&nbsp;</li>
                                                                    </ul>
                                                                    <Button
                                                                        className="btn-round mr-1"
                                                                        color="success"
                                                                        outline
                                                                        type="button"
                                                                        onClick={()=>{this.handleSelectPricePlan(p)}}
                                                                        
                                                                    >
                                                                        Select
                                                                    </Button>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    );
                                                })
                                            }
                                            
                                        </Row>
                                        
                                        </div>

                                    } 

                                    {
                                        selectedPricePlan &&
                                        <div>
                                        <h6 className="card-category text-left">Select Insurance</h6>
                                        <hr/> 
                                        <Row className="justify-content-center">
                                            {
                                                insuranceList.map(i=>{
                                                    const selectedAttributes = (i.id === selectedInsurance.id) ? {"data-color":"orange"}:{};
                                                    return(
                                                        <Col key={i.id} md="3" sm="6">
                                                            <Card className="card-pricing"  {...selectedAttributes}>
                                                                <CardBody>
                                                                    <CardTitle tag="h5">{i.type}</CardTitle>
                                                                    <ul>
                                                                        <li>
                                                                        <h5>{i.price * 100}¢ per pound</h5>
                                                                        </li>
                                                                        <li>
                                                                            {`Reimbursement at a flat rate of ${i.insuranceLimitPerPount}/pound or per the '${i.type.toLowerCase()}' column of our garment pricing list.`}  
                                                                        </li>
                                                                        <li>&nbsp;</li>
                                                                    </ul>
                                                                    <Button
                                                                        className="btn-round mr-1"
                                                                        color="success"
                                                                        outline={(i.id != selectedInsurance.id)}
                                                                        type="button"
                                                                        onClick={()=>{this.handleSelectInsurancePlan(i)}}
                                                                        
                                                                    >
                                                                        Select
                                                                    </Button>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    );
                                                })
                                            }
                                            
                                        </Row>
                                        
                                        </div>

                                    }

                                    <FormGroup row className="mt-5 justify-content-between">
                                        <Col xs={{ size: 2}}>
                                            <Button 
                                            className="btn-rounded btn-danger"
                                            onClick={()=>onClickOfBackButtonFromPricingAndInsuranceInformation()}
                                            >
                                            Back
                                            </Button>
                                        </Col>
                                        {
                                            //disabled = !selectedLaundryType && !selectedPricePlan && true
                                        }
                                        <Col xs={{ size: 6}} sm={{ size: 4}} className="text-right" >
                                            <Button 
                                            disabled={(!selectedLaundryType || !selectedPricePlan) && true}
                                            className="btn-rounded btn-danger"
                                            onClick={this.handleSubmit}
                                            >
                                            Save & Proceed
                                            </Button>
                                        </Col>
                                    </FormGroup>   
                                        

                                </Form>
                            </CardBody>
                        </Card> 
                    </Col>
                </Row>       
            </Container>
        );     
    }

}

export default ResidentialPricingAndInsurance;