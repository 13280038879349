import React,{Component} from "react";
import _ from "lodash";
import Select from "react-select";
import moment from "moment";
import accounting from "accounting";

import auth from "../../../../services/authService";
import BaseFrom from "../../../BaseClass/Form";

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Media,
    Container,
    Row,
    Col
} from "reactstrap";


class ResidentialPricing extends BaseFrom {

    constructor(){
        super();
        this.state = {
            cityList:"",
            cityDropDownList:[],
            data:{
                selectedCityDropdownObject:"",
                laundryType:"",//{id,type} by the pound or semester
                pricePlan:"",
            },
            errors:{},
        }
    }

    componentDidMount(){
        this.mapModelToView();
    }

    componentDidUpdate(prevProps){
        const { listOfResidentialCitiesByStateForLaundry } = this.props;
        const { listOfResidentialCitiesByStateForLaundry:listOfResidentialCitiesByStateForLaundryPrevProps } = prevProps;
        if(listOfResidentialCitiesByStateForLaundryPrevProps === "" && !_.isEmpty(listOfResidentialCitiesByStateForLaundry)){
            this.mapModelToView();
        }
    }

    getLaundryTypeList = ()=>{
        const { cityList,data, } = this.state;
        const { selectedCityDropdownObject } = data;
        if(!_.isEmpty(selectedCityDropdownObject)){
            const selectedCityId = selectedCityDropdownObject.value;
            return _.find(cityList,{"id":selectedCityId}).pricing;
        }

        return [];
        
    }

    handleSelectCity = (value,dataAttribute)=>{
        this.handleDropdownChange(value,dataAttribute);
        this.setState({
            data:{
                [dataAttribute]:value,
                laundryType:"",
                
            }
        })
        
    }

    handleSelectLaundryPlan = (l)=>{
        //console.log(l)
        const laundryType = _.pick(l,['id','type']);
        const data = {...this.state.data}
        data.laundryType = laundryType;
        data.pricePlan = "";
        this.setState({data});

    }

    mapModelToView = ()=>{
        const { listOfResidentialCitiesByStateForLaundry } = this.props;
        //console.log(listOfResidentialCitiesByStateForLaundry);
        if(!_.isEmpty(listOfResidentialCitiesByStateForLaundry)){
            const setOfCities= _.map(listOfResidentialCitiesByStateForLaundry,'cities');
            
            const cityList = _.flattenDeep(setOfCities);
            const cityDropDownList = cityList.map(s=>{
                return {value:s.id,label:`${s.name} & sorrounding areas`}
            })
            
            this.setState({
                cityDropDownList,
                cityList,
            })

        }
    
    }

    render(){
        const { data, cityDropDownList } = this.state;
        const {selectedCityDropdownObject,laundryType:selectedLaundryType} = data;

        const laundryTypeList  = this.getLaundryTypeList();
        
        const priceList = selectedLaundryType && _.find(laundryTypeList,{'id':selectedLaundryType.id}).price;
        
        return(
            <>
                <div className="mb-4">
                    <FormGroup>
                        <h6 className="card-category text-left">Select City</h6>
                        <Select
                            className="react-select react-select-default"
                            classNamePrefix="react-select"
                            name="defaultSelect"
                            value={selectedCityDropdownObject}
                            onChange={value => this.handleSelectCity(value,"selectedCityDropdownObject")}
                            options={cityDropDownList}
                            placeholder=""
                        />
                    </FormGroup>
                </div>

                {   !_.isEmpty(selectedCityDropdownObject) && 
                    <div className="mt-2">
                        <h6 className="card-category text-left">Select Plan</h6>
                        <hr/> 
                        
                        <FormGroup row className="justify-content-center">
                            {
                                laundryTypeList.map(l=>{
                                    return(
                                        <Col key={l.id} sm={{ size: 6}} xs={{size:12}}> 
                                            <Button
                                                className="btn-round mr-1"
                                                color="danger"
                                                outline={l.id != selectedLaundryType.id}
                                                block
                                                type="button"
                                                id={`btn_${l.type}`}
                                                onClick={()=>{this.handleSelectLaundryPlan(l)}}
                                            >
                                                {l.description}
                                            </Button>
                                            
                                        </Col>
                                    )
                                }) 
                            }
                        </FormGroup>
                        
                    </div> 
                }

                {
                    selectedLaundryType &&
                    <div className="mt-2">
                        <h6 className="card-category text-left">Select Price Plan</h6>
                        <hr/> 
                        <Row className="justify-content-center">
                            {
                                priceList.map(p=>{
                                    
                                    const selectedAttributes = {};
                                    return(
                                        <Col key={p.id} md="3" sm="6">
                                            <Card 
                                                className="card-pricing"  
                                                {...selectedAttributes}
                                            >
                                                <CardBody>
                                                    <CardTitle tag="h1">${p.price}</CardTitle>
                                                    <ul>
                                                        <li>
                                                        {p.name}
                                                        </li>
                                                        <li>
                                                        ${p.pricePerPound} per pound
                                                        </li>
                                                        <li>&nbsp;</li>
                                                    </ul>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    );
                                })
                            }
                            
                        </Row>

                        <div className="d-flex justify-content-center">
                            {
                                !auth.getCurrentUser() && 
                                <Button
                                    className="btn-round mr-1"
                                    color="danger"
                                    type="button"
                                    onClick={()=>this.props.history.push("/signup/laundry/residential/select-city")}
                                >
                                    Ready to Sign-up
                                </Button>
                            }
                            
                        </div>
                    
                    </div>

                } 
            </>
        )
    }
}

export default ResidentialPricing;