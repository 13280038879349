import _ from "lodash";

const UsaStates = require('usa-states').UsaStates;
const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));



const stateOption = [
    { value: "", label: " Choose State", isDisabled: true },
];


const stateList = new UsaStates().states;
stateList.forEach(s => {
    const state = {
        value:s.abbreviation,
        label:s.name
    }
    stateOption.push(state);
});

const countryOption = [
    { value: "", label: " Choose State", isDisabled: true },
];

_.forIn(countries.getNames("en"), function(countryName, countryCode) {
    const country = {
        value:countryCode,
        label:countryName
    };
    countryOption.push(country);
});


export function getUSStateList(){
    return stateOption;
}


export function getCountryList(){
    return countryOption;
}


