import React from "react";
import _ from "lodash";

// core components
import ShortNavbar from "../../components/Navbars/ShortNavbar.js";
import SignupPageHeader from "../../components/Headers/SignupPageHeader";
import FooterWhite from "../../components/Footers/FooterWhite";

import StorageInformation from "../../components/Consumer/StorageInformation"

import auth from "../../services/authService";

// reactstrap components
import {
    Button,
    Container,
    Row,
    Col
  } from "reactstrap";

  

  const StorageDetails = (props)=>{
    let userType;
    ({userType} = props.match.params);

    let isStudent = (userType === "student") ? true:false;
    let signUpUrl = `/signup/storage/student/select-school`;
    
    return(
      <>
        <ShortNavbar {...props} />
        <SignupPageHeader />
        <div className="main">
            <div className="section section-nude-gray"> 
            <Container id="storageInformationArea">
                <Row className="title-row justify-content-between">
                    <Col xs="6" md="6">
                    <h3 className="shop">Storage - {`${_.startCase(userType)}`}</h3>
                    </Col>
                    <Col xs="6" md="6">
                    <div className="text-right">
                        {
                            !auth.getCurrentUser() &&
                            <Button
                                className="btn-round mr-1"
                                color="danger"
                                type="button"
                                onClick={()=>props.history.push(signUpUrl)}
                            >
                                Sign-up
                            </Button>
                        }
                    </div>
                    </Col>
                </Row>
                <div id="description-areas">
                    <StorageInformation
                        {...props}
                    />
                </div>
            {/* info areas */}
            </Container>
          </div>
         </div> 
        
        <FooterWhite />
      </>
    );



}

export default StorageDetails;