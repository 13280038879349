import React,{Component} from "react";
import _ from "lodash";
import moment from "moment";
import customToast from '../../../../Common/CustomToast'

import ResidentialService from "services/residentialService";
import StudentService from "services/studentService";
import LaundryService from "services/laundryService";

import ResidentialCustomerAccountLaundrySchedulingModal from "./ResidentialCustomerAccountLaundrySchedulingModal";
import ResidentialCustomerAccountCancelUpcomingPickupModal from "./ResidentialCustomerAccountCancelUpcomingPickupModal";
import ResidentialCustomerAccountLaundryCancelRecurringScheduleModal from "./ResidentialCustomerAccountLaundryCancelRecurringScheduleModal";
import ResidentialCustomerAccountLaundryModifyRecurringScheduleModal from "./ResidentialCustomerAccountLaundryModifyRecurringScheduleModal";
import ResidentialCustomerAccountLaundryModifyOneTimeUpcomingPickupModal from "./ResidentialCustomerAccountLaundryModifyOneTimeUpcomingPickupModal";
import ResidentialCustomerAccountLaundryUpcomingPickupSpecialRequest from "./ResidentialCustomerAccountLaundryUpcomingPickupSpecialRequest";
import ResidentialCustomerAccountLaundryModifyDeliveryWaiverAndDriverInstructions from "./ResidentialCustomerAccountLaundryModifyDeliveryWaiverAndDriverInstructions";

import GarmentPricingListModal from "../../../LaundryInformation/GarmentInsurance/GarmentInsuranceModal";

import ProcessingModal from "../../../../Common/ProcessingModal";

import UserNotificationWidget from "../Common/Notifications/UserNotifications";


// reactstrap components
import {
    Alert,
    Button,
    Container,
    Card,
    CardBody,
    CardFooter,
    CardImg,
    CardImgOverlay,
    CardLink,
    CardTitle,
    CardSubtitle,
    CardText,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    ListGroupItem,
    ListGroup,
    Row,
    Col,
    Form, 
    FormGroup, 
    Label,
    Modal, 
    Nav,
    Input, 
    FormText,
    UncontrolledTooltip
    
  } from "reactstrap";

class ResidentialCustomerAccountLaundryDetail extends Component{
    

    constructor(){
        super();
        this.state = {
            displayDoublePoundModalFlag:false,
            displayGarmentPricingModalFlag:false,
            displayCancelRecurringScheduleModalFlag:false,
            displayModifyRecurringScheduleModalFlag:false,
            displayModifyOneTimeUpcomingPickupModalFlag:false,
            displayModifyDeliveryWaiverAndDriverInstructionsModalFlag:false,
            displaySpecialRequestForUpcomingPickupModalFlag:false,
            scheduleModalDisplayFlag:false,
            processingModalDisplayFlag:false,
            cancelUpcomingModalDisplayFlag:false,
            setScheduleFlag:true,
            completePendingActionFlag:false,
        }
    }

    componentDidMount(){
        //If schedule is false then set schedle flag is true
        const setScheduleFlag = this.isCustomerNextPickupDeliveryScheduleAvailable();
        this.setState({setScheduleFlag});
    }

    componentDidUpdate(){
        const setScheduleFlag = !this.isCustomerNextPickupDeliveryScheduleAvailable();
        const previousState = {...this.state};
        if(previousState.setScheduleFlag != setScheduleFlag)
          this.setState({setScheduleFlag});
    }

    completePendingActions = ()=>{
        const myAccountState  = {...this.state};
        const {completePendingActionFlag} = this.state;
        myAccountState.completePendingActionFlag = !completePendingActionFlag;
        this.setState(myAccountState);
    }

    /*
      Residential Customer
         - One time plan
             - has a recurring schedule then don't display it

    */
   displayAsapPickupButton = ()=>{
        let displayAsapPickupButton = false;
        
        const {user,laundry} = this.props;
        

        //If student does not live in a dorm 
        if(!_.isEmpty(laundry)){
            //Check if the student has a recurring plan
            const {laundryOrder} = laundry;
            
            //Check if semester has started
            const hasRecurringPlan = this.doesCustomerHaveRecurringLaundrySchedule(laundryOrder);
            const hasOneTimePlan = false;
            //If semester has started and has a recurring plan
            if(hasRecurringPlan){
                    displayAsapPickupButton = false;
            }
            else if(hasOneTimePlan){
                    //PENDING
                    displayAsapPickupButton = true;
            }
        } 

        return displayAsapPickupButton;
    }

    /* This will focus on cancelling only the upcoming pickup
    Residential Customer (Not in dorm)
        - If has a recurring plan then display if it's before the pickup and delivery day
        - If has a one time plan then we display if it's been scheduled and is before the pickup and delivery day
    */
    displayCancelUpcomingPickupButton = ()=>{
        let displayCancelUpcomingPickupButton = false;
        
        const {user,laundry} = this.props;
        
        //If student does not live in a dorm
        if(this.isCustomerNextPickupDeliveryScheduleAvailable()){
            //Check if the customer has a recurring plan
            const {laundryOrder,nextPickupDeliverySchedule} = laundry;
            const hasRecurringPlan = this.doesCustomerHaveRecurringLaundrySchedule(laundryOrder);
            const now = moment();
                
            //If student has a recurring plan
            if(hasRecurringPlan){
                const nextPickupDate = moment(nextPickupDeliverySchedule.pickup.date);
                const nextDeliveryDate = moment(nextPickupDeliverySchedule.delivery.date);
                //Only display the change upcoming after the next delivery date
                if(now.isSame(nextPickupDate,'day') || now.isSame(nextDeliveryDate,'day')){
                    return false;
                }
                else
                    return true;

            }
            //End if recurring plan

            //One time plan and has a pickup scheduled - This is only for student who does not live in a dorm
            if(!hasRecurringPlan){
                const nextPickupDate = moment(nextPickupDeliverySchedule.pickup.date);
                const nextDeliveryDate = moment(nextPickupDeliverySchedule.delivery.date);
                //Only display the change upcoming after the next delivery date
                if(now.isSame(nextPickupDate,'day') || now.isSame(nextDeliveryDate,'day')){
                    return false;
                }
                else
                    return true;
            }

            //displayModifyPickupScheduleButton = true;
        }
        
        return displayCancelUpcomingPickupButton;
    }

    /*
    If they have a scheduled pickup then display this button as they might want to make update instructions
    */
    displayDeliveryWaiverAndDriverInstructionButton = ()=>{
        let displayDeliveryWaiverAndDriverInstructionButton = false;

        displayDeliveryWaiverAndDriverInstructionButton = this.isCustomerNextPickupDeliveryScheduleAvailable();
        
        return displayDeliveryWaiverAndDriverInstructionButton;
    }   

    /*
    If you have an upcoming pickup then display it until one day before the actual pickup
    */
    displayMakeSpecialRequestForUpcomingPickupButton = ()=>{
        let displayButton = false;

        const {user,laundry} = this.props;
        

        if(this.isCustomerNextPickupDeliveryScheduleAvailable()){
            //Check if the student has a recurring plan
            const {laundryOrder,nextPickupDeliverySchedule} = laundry;
            const hasRecurringPlan = this.doesCustomerHaveRecurringLaundrySchedule(laundryOrder);
            const now = moment();
                
            //If residential customer has a recurring plan
            if(hasRecurringPlan){
                const nextPickupDate = moment(nextPickupDeliverySchedule.pickup.date);
                const nextDeliveryDate = moment(nextPickupDeliverySchedule.delivery.date);
                //Only display the change upcoming after the next delivery date
                if(now.isSame(nextPickupDate,'day') || now.isSame(nextDeliveryDate,'day')){
                    return false;
                }
                else
                    return true;

            }
            //End if recurring plan

            //One time plan and has a pickup scheduled
            if(!hasRecurringPlan){
                const nextPickupDate = moment(nextPickupDeliverySchedule.pickup.date);
                const nextDeliveryDate = moment(nextPickupDeliverySchedule.delivery.date);
                //Only display the change upcoming after the next delivery date
                if(now.isSame(nextPickupDate,'day') || now.isSame(nextDeliveryDate,'day')){
                    return false;
                }
                else
                    return true;
            }

            
        }


        return displayButton;
    }

    /*
    Residential Customer
        - Only available if they have setup a recurring plan   
    */
    displayModifyPickupScheduleButton = ()=>{
        let displayModifyPickupScheduleButton = false;
        const {user,laundry} = this.props;
        
        //If student does not live in a dorm
        if(!_.isEmpty(laundry)){
            //Check if the student has a recurring plan
            const {laundryOrder} = laundry;
            const hasRecurringPlan = this.doesCustomerHaveRecurringLaundrySchedule(laundryOrder);
            
            //Iterate through laundryOrderDetails to see if there has been a pickup for this week 

            displayModifyPickupScheduleButton = hasRecurringPlan && true;
        } 

        return displayModifyPickupScheduleButton;
    }

    /*
   Residential Customer
        - If has a recurring plan then don't display it
        - If has a one time plan then we don't display it as they should cancel it and then get "Set Schedule" to redo it
        
        
    */
    displayModifyUpcomingPickupButton = ()=>{
        let displayModifyPickupScheduleButton = false;
        
        const {user,laundry} = this.props;
        
        if(this.isCustomerNextPickupDeliveryScheduleAvailable()){
            //Check if the student has a recurring plan
            const {laundryOrder} = laundry;
            const hasRecurringPlan = this.doesCustomerHaveRecurringLaundrySchedule(laundryOrder);
            const now = moment();
                    
            //If student has a recurring plan
            if(hasRecurringPlan){
                return false;
            }
            //End if recurring plan

            //One time plan and has a pickup scheduled
            if(!hasRecurringPlan){
                //If has a one time plan then we don't display it as they should cancel it and 
                //Then they can change it one day before the scheduled date
                const {nextPickupDeliverySchedule} = laundry;
                const nextPickupDate = moment(nextPickupDeliverySchedule.pickup.date);
                if(now.isBefore(nextPickupDate,'day')){
                    return true;
                }   
                return false
            }

            //displayModifyPickupScheduleButton = true;
        }

        return displayModifyPickupScheduleButton;
    }

    /*
    If there is an upcoming schedule then display the button as they might want to make a notification change
    */
    displayUpdateEmailAndTextReminderButton = ()=>{
        let displayUpdateEmailAndTextReminderButton = false;
        displayUpdateEmailAndTextReminderButton = this.isCustomerNextPickupDeliveryScheduleAvailable();
        return displayUpdateEmailAndTextReminderButton;
    }

    /*
      if laundry order has the pickup day and delivery day set 
    */
    doesCustomerHaveRecurringLaundrySchedule = (laundryOrder)=>{
        let hasRecurringPlan = false;
        const {
            delivery_dayname,
            delivery_starttime,
            delivery_endtime,
            pickup_dayname,
            pickup_starttime,
            pickup_endtime,
        }  = laundryOrder
        
        hasRecurringPlan = pickup_dayname && pickup_endtime && true;

        return hasRecurringPlan

    }

    handleCancelRecurringSchedule = async ()=>{
        console.log("handleCancelRecurringSchedule");
        let myAccountState = {...this.state}
        let formData = {};
        const {laundry,user,handleSettingLaundryNextPickupDeliverySchedule,handleUpdateOfUserAndProductProperties} = this.props;
        
        myAccountState.processingModalDisplayFlag = true;
        myAccountState.displayCancelRecurringScheduleModalFlag = false;
        this.setState(myAccountState);
        
        formData = {
            laundry,
            user
        };
        const {status:processingStatus,message,nextPickupDeliverySchedule} = await ResidentialService.cancelLaundryRecurringSchedule(formData);
        
        await handleUpdateOfUserAndProductProperties();
        handleSettingLaundryNextPickupDeliverySchedule(nextPickupDeliverySchedule);
        myAccountState.processingModalDisplayFlag = false;
        this.setState(myAccountState);
        if(processingStatus === true)
            customToast.success(message); 
        else if(processingStatus === false)
            customToast.error(message); 
    }

    handleCancelUpcomingPickup = async ()=>{
        let myAccountState = {...this.state}
        let formData = {};
        const {laundry,user,handleSettingLaundryNextPickupDeliverySchedule,handleUpdateOfUserAndProductProperties} = this.props;
        
        myAccountState.processingModalDisplayFlag = true;
        myAccountState.cancelUpcomingModalDisplayFlag = false;
        this.setState(myAccountState);
        
        formData = {
            laundry,
            user
        };
        const {status:processingStatus,message,nextPickupDeliverySchedule} = await ResidentialService.cancelLaundryUpcomingPickup(formData);
        
        await handleUpdateOfUserAndProductProperties();
        handleSettingLaundryNextPickupDeliverySchedule(nextPickupDeliverySchedule);
        myAccountState.processingModalDisplayFlag = false;
        this.setState(myAccountState);
        if(processingStatus === true)
            customToast.success(message); 
        else if(processingStatus === false)
            customToast.error(message); 
            
    }

    handleGetMeOnThisWeeksLaundryPickupSchedule = async ()=>{
        let myAccountState = {...this.state}
        let formData = {};
        const {laundry,user,handleSettingLaundryNextPickupDeliverySchedule,handleUpdateOfUserAndProductProperties} = this.props;
        
        myAccountState.processingModalDisplayFlag = true;
        this.setState(myAccountState);
        
        formData = {
            laundry,
            user
        };
        const {status:processingStatus,message,nextPickupDeliverySchedule} = await ResidentialService.getMeOnThisWeeksLaundryPickupSchedule(formData);
        
        await handleUpdateOfUserAndProductProperties();
        handleSettingLaundryNextPickupDeliverySchedule(nextPickupDeliverySchedule);
        myAccountState.processingModalDisplayFlag = false;
        this.setState(myAccountState);
        if(processingStatus === true)
            customToast.success(message); 
        else if(processingStatus === false)
            customToast.error(message); 
            
    }

    //This is saving of the modified weekly/recurring schedule from the modal form
    handleSaveModifiedRecurringSchedule = async (modalFormData) =>{
        try{
            console.log(modalFormData);
            const myAccountState  = {...this.state};
            const {laundry,user,handleSettingLaundryNextPickupDeliverySchedule,handleUpdateOfUserAndProductProperties} = this.props;
            
            //Display processing modal
            myAccountState.processingModalDisplayFlag = true;
            this.setState(myAccountState);

            //Send form data to server
            let formData = modalFormData;
            formData.user = user;
            formData.laundry = laundry;
            //console.log(formData);
            
            const {status:processingStatus,message,nextPickupDeliverySchedule} = await ResidentialService.saveLaundryModifiedRecurringSchedule(formData);
            const {processingModalDisplayFlag} = this.state;
            if( processingStatus === false){
                myAccountState.processingModalDisplayFlag = !processingModalDisplayFlag;
                this.setState(myAccountState);
                return customToast.error(message); 
            }

            if(!_.isEmpty(nextPickupDeliverySchedule) && processingStatus === true){
                await handleUpdateOfUserAndProductProperties();
                handleSettingLaundryNextPickupDeliverySchedule(nextPickupDeliverySchedule);
            }

            myAccountState.processingModalDisplayFlag = !processingModalDisplayFlag;
            myAccountState.displayModifyRecurringScheduleModalFlag = false;
            this.setState(myAccountState);

            if(!_.isEmpty(nextPickupDeliverySchedule) && processingStatus === true)
                customToast.success(message); 
            
            
            

        }
        catch(ex){
            console.log(ex);
        }
        
    }

    handleSaveDeliveryWaiverAndDriverInstructions = async (modalFormData) =>{
        try{
        
            const myAccountState  = {...this.state};
            const {laundry,user,handleUpdateOfUserAndProductProperties} = this.props;
        
        
            //Display processing modal
            myAccountState.processingModalDisplayFlag = true;
            this.setState(myAccountState);

            //Send form data to server
            let formData = modalFormData;
            formData.user = user;
            formData.laundry = laundry;
            console.log(formData);
            
            const {status:processingStatus,message} = await ResidentialService.saveLaundryDeliveryWaiverAndDriverInstructions(formData);
            if(processingStatus === true){
                await handleUpdateOfUserAndProductProperties();
            }

            const {processingModalDisplayFlag} = this.state;
            myAccountState.processingModalDisplayFlag = !processingModalDisplayFlag;
            myAccountState.displayModifyDeliveryWaiverAndDriverInstructionsModalFlag = false;
            this.setState(myAccountState);

            if(processingStatus === true)
                customToast.success(message); 
            else
                customToast.error(message); 

            
       }
       catch(ex){
        console.log(ex);
        }  
    }

    handleSaveModifyOneTimeUpcomingPickup = async (modalFormData) =>{
        try{
            console.log(modalFormData);
            const myAccountState  = {...this.state};
            const {laundry,user,handleSettingLaundryNextPickupDeliverySchedule,handleUpdateOfUserAndProductProperties} = this.props;
            
            //Display processing modal
            myAccountState.processingModalDisplayFlag = true;
            this.setState(myAccountState);

            //Send form data to server
            let formData = modalFormData;
            formData.user = user;
            formData.laundry = laundry;
            //console.log(formData);
            
            const {status:processingStatus,message,nextPickupDeliverySchedule} = await ResidentialService.saveLaundryModifiedOneTimeUpcomingPickup(formData);
            const {processingModalDisplayFlag} = this.state;
            if( processingStatus === false){
                myAccountState.processingModalDisplayFlag = !processingModalDisplayFlag;
                this.setState(myAccountState);
                return customToast.error(message); 
            }

            if(!_.isEmpty(nextPickupDeliverySchedule) && processingStatus === true){
                await handleUpdateOfUserAndProductProperties();
                handleSettingLaundryNextPickupDeliverySchedule(nextPickupDeliverySchedule);
            }

            myAccountState.processingModalDisplayFlag = !processingModalDisplayFlag;
            myAccountState.displayModifyOneTimeUpcomingPickupModalFlag = false;
            this.setState(myAccountState);

            if(!_.isEmpty(nextPickupDeliverySchedule) && processingStatus === true)
                customToast.success(message); 
            

        }
        catch(ex){
            console.log(ex);
        }
        
    }

    handleSaveSpecialRequestForUpcomingPickupModalFlag = async (modalFormData) =>{
        try{
         
             const myAccountState  = {...this.state};
             const {laundry,user,handleUpdateOfUserAndProductProperties} = this.props;
         
         
             //Display processing modal
             myAccountState.processingModalDisplayFlag = true;
             this.setState(myAccountState);
 
             //Send form data to server
             let formData = modalFormData;
             formData.user = user;
             formData.laundry = laundry;
             console.log(formData);
             
             const {status:processingStatus,message} = await ResidentialService.saveLaundryCustomerNotesForUpcomingPickup(formData);
             if(processingStatus === true){
                 await handleUpdateOfUserAndProductProperties();
             }
 
             const {processingModalDisplayFlag} = this.state;
             myAccountState.processingModalDisplayFlag = !processingModalDisplayFlag;
             myAccountState.displaySpecialRequestForUpcomingPickupModalFlag = false;
             this.setState(myAccountState);
 
             if(processingStatus === true)
                 customToast.success(message); 
             else
                 customToast.error(message); 
 
             
        }
        catch(ex){
         console.log(ex);
         }   
    }

    //This is saving of the schedule for the first time
    handleSetSchedule = async (modalFormData)=>{
        try{
            const myAccountState  = {...this.state};
            const {laundry,user,handleSettingLaundryNextPickupDeliverySchedule,handleUpdateOfUserAndProductProperties} = this.props;
        
        
            //Display processing modal
            myAccountState.processingModalDisplayFlag = true;
            this.setState(myAccountState);

            //Send form data to server
            let formData = modalFormData;
            formData.user = user;
            formData.laundry = laundry;
            console.log(formData);
            const {status,nextPickupDeliverySchedule,message} = await ResidentialService.saveLaundrySchedule(formData);
            const {setScheduleFlag,processingModalDisplayFlag} = this.state;
                
            if(status === true && !_.isEmpty(nextPickupDeliverySchedule)){
                await handleUpdateOfUserAndProductProperties();
                handleSettingLaundryNextPickupDeliverySchedule(nextPickupDeliverySchedule);

                myAccountState.processingModalDisplayFlag = !processingModalDisplayFlag;
                myAccountState.setScheduleFlag = !setScheduleFlag;
                myAccountState.scheduleModalDisplayFlag = false;
                this.setState(myAccountState);
            }
            else{
                myAccountState.processingModalDisplayFlag = !processingModalDisplayFlag;
                customToast.error(message); 
                this.setState(myAccountState);
            }
            
        }
        catch(ex){
            console.log(ex);
        }
        
    }

    isCustomerNextPickupDeliveryScheduleAvailable = ()=>{
        let isCustomerNextPickupDeliveryScheduleAvailable  = false;
        if(this.props.laundry){
             const {nextPickupDeliverySchedule} = this.props.laundry;
             isCustomerNextPickupDeliveryScheduleAvailable =  !_.isEmpty(nextPickupDeliverySchedule) && true;
        }
        return isCustomerNextPickupDeliveryScheduleAvailable;
    }

    setCancelUpcomingPickupModalFlag = (flag)=>{
        this.setState({
            cancelUpcomingModalDisplayFlag:flag    
        })
    }

    setDisplayCancelRecurringScheduleModalFlag = (flag)=>{
        this.setState({
            displayCancelRecurringScheduleModalFlag:flag    
        })
    }

    setGarmentPricingModalFlag = (flag)=>{
        this.setState({
            displayGarmentPricingModalFlag:flag
        })
    }
    

    setDisplayModifyDeliveryWaiverAndDriverInstructionsModalFlag = (flag)=>{
        this.setState({
            displayModifyDeliveryWaiverAndDriverInstructionsModalFlag:flag    
        })
    }

    setDisplayModifyOneTimeUpcomingPickupModalFlag = (flag)=>{
        this.setState({
            displayModifyOneTimeUpcomingPickupModalFlag:flag    
        })
    }

    

    setDisplayModifyRecurringScheduleModalFlag = (flag)=>{
        this.setState({
            displayModifyRecurringScheduleModalFlag:flag    
        })
    }

    setDisplaySpecialRequestForUpcomingPickupModalFlag = (flag)=>{
        this.setState({
            displaySpecialRequestForUpcomingPickupModalFlag:flag    
        })
    }

    setScheduleModalDisplayFlag = (flag)=>{
        this.setState({
            scheduleModalDisplayFlag:flag    
        })
    }

    

    render(){
        const {
            displayFlag,
            displaySignupLaundryPlan,
            displayWelcomeModal,
            handleOnCloseOfWelcomeModal,
            handleOnClickOfEmailAndTextReminderLink,
            handleOnClickOfAccountActivityButton,
            isEligibleForSupplies,
            laundry,
            listOfOneTimePickupDates,
            user,
            userNotifications,
        } = this.props;

        const {
            allowedToGetBackToCurrentWeekSchedule
        } = laundry || ""


        //console.log(laundry);
        const {
            completePendingActionFlag,
            cancelUpcomingModalDisplayFlag,
            displayCancelRecurringScheduleModalFlag,
            displayGarmentPricingModalFlag,
            displayModifyDeliveryWaiverAndDriverInstructionsModalFlag,
            displayModifyRecurringScheduleModalFlag,
            displayModifyOneTimeUpcomingPickupModalFlag,
            displaySpecialRequestForUpcomingPickupModalFlag,
            processingModalDisplayFlag,
            scheduleModalDisplayFlag,
            setScheduleFlag,
            
        }  = this.state;  

        const laundryPlanEndDate = (laundry) ? new moment(laundry.laundryOrder.date_end).format("MMM Do"):false;
        console.log(laundryPlanEndDate);

        const displayCSS = (displayFlag) ? "":"d-none";

        
        let displayModifyPickupScheduleButton = this.displayModifyPickupScheduleButton();
        let displayAsapPickupButton = this.displayAsapPickupButton();
        
        let displayModifyUpcomingPickupButton = this.displayModifyUpcomingPickupButton();
        let displayCancelUpcomingPickupButton = this.displayCancelUpcomingPickupButton();
        let displayMakeSpecialRequestForUpcomingPickupButton = this.displayMakeSpecialRequestForUpcomingPickupButton();
        let displayDeliveryWaiverAndDriverInstructionButton = this.displayDeliveryWaiverAndDriverInstructionButton();
        let displayUpdateEmailAndTextReminderButton = this.displayUpdateEmailAndTextReminderButton();
        

        return(
            <>
                <Row className={displayCSS}>
                    <Col>
                        <Card>
                            <CardBody>
                                <div>
                                    <div className="d-flex bd-highlight mb-3">
                                        <div className="mr-auto p-2 bd-highlight mt-2">
                                            <h6 className="card-category">
                                                <span className="text-left">
                                                Laundry Service: Details
                                                </span>
                                            </h6>
                                        </div>
                                        {   
                                            userNotifications &&  
                                            <div className="bd-highlight d-none d-lg-block">
                                                <UserNotificationWidget
                                                    {...this.props}
                                                />
                                            </div>
                                        }
                                        <div className="p-2 bd-highlight">
                                            {   displaySignupLaundryPlan && 
                                                <Button
                                                    className="btn-round mr-1"
                                                    color="danger"
                                                    type="button"
                                                    size="sm"
                                                    onClick={(e)=>{e.preventDefault(); this.props.history.push("/existing-user/signup/residential/laundry/0/0/select-plan")}}
                                                >
                                                    <i className="nc-icon nc-bell-55 mr-1" />
                                                    {`Sign-up for Laundry Services`}
                                                </Button>
                                            }
                                            
                                        </div>
                                    </div>
                                    <hr/>
                                    
                                    {
                                    completePendingActionFlag &&
                                    <Row>
                                        <Col xs="12">
                                            <div className="mlb-alert mlb-alert-primary" role="alert">
                                                <Row>
                                                    <Col xs="1" sm="1" className="fs-42 text-danger">
                                                            <i className="fa fa-exclamation-triangle fa-6" aria-hidden="true"></i>    
                                                    </Col>
                                                    <Col xs="7" sm="6">
                                                    <ul>
                                                        Following action needs to be taken:  
                                                        <li>Profile Information (Home address, Billing Address)</li> 
                                                    </ul> 
                                                    </Col>
                                                    <Col xs="4" sm="5">
                                                            <Button
                                                            color="danger"
                                                            outline
                                                            size="sm"
                                                            type="button"
                                                            className="mr-1"
                                                            onClick={this.completePendingActions}
                                                        >
                                                                Setup my plan
                                                        </Button>
                                                    </Col> 
                                                    
                                                </Row>
                                                
                                            </div>
                                        </Col>
                                    </Row>
                                    }
                                    {
                                        !displaySignupLaundryPlan && 
                                        <> 
                                        <Row>
                                            <Col md="4" className="br-solid">
                                                <Row>
                                                    <Col xs="9" sm="9" md="12">
                                                        <Row className="mt-2">
                                                            <Col xs="6" sm="6" md="6">
                                                                Plan
                                                            </Col>
                                                            <Col xs="6" sm="6" md="6">
                                                            {laundry && `${laundry.laundryPackageName}`}
                                                            </Col>
                                                        </Row>
            
                                                        <Row className="mt-2">
                                                            <Col xs="6" sm="6" md="6">
                                                                Insurance
                                                            </Col>
                                                            <Col xs="6" sm="6" md="6" className="align-text-top">
                                                                <Row>
                                                                    <Col xs="12" style={{paddingLeft:"0px"}}>
                                                                        <Button className="text-danger" color="link" style={{paddingTop:"0px",paddingLeft:"13px"}} onClick={()=>this.setGarmentPricingModalFlag(true)}>
                                                                            {laundry && `${laundry.insurance}`}   <i className="fa fa-info-circle mr-1" />  
                                                                        </Button>
                                                                        
                                                                    </Col>
                                                                </Row>
                                                                
                                                                
                                                            </Col>
                                                        </Row>
            
                                                        <Row>
                                                            <Col className="text-left">
                                                                <Button
                                                                    className="btn-link"
                                                                    color="info"
                                                                    size="sm"
                                                                    type="button"
                                                                    style={{paddingLeft:"0px"}}
                                                                >
                                                                    <i className="fa fa-book" aria-hidden="true"></i>
                                                                    Download Brochure
                                                                </Button>
                                                            </Col>
                                                        </Row>
            
                                                        <Row>
                                                            <Col className="text-left">
                                                                <Button
                                                                    className="btn-link"
                                                                    color="info"
                                                                    size="sm"
                                                                    type="button"
                                                                    style={{paddingLeft:"0px"}}
                                                                    onClick={handleOnClickOfAccountActivityButton}
                                                                >
                                                                    <i className="fa fa-table" aria-hidden="true"></i>
                                                                    View Billing Activity
                                                                </Button>
                                                            </Col>
                                                        </Row>
            
                                                        
                                                    </Col>
                                                    <Col xs="3" sm="3" className="align-middle d-block d-md-none">
                                                        <img  
                                                            className="img-rounded img-responsive"
                                                            src={require("../../../../../assets/mylazybones/img/laundry_package.jpg")}
                                                            
                                                        />
                                                    </Col>
                                                </Row>
                                                    
                                                
                                            </Col>
                                            
                                            
                                            <Col md="4" className="br-solid">
                                                <div className="d-block d-md-none">
                                                    <hr/>
                                                </div>
                                                <div>
                                                    <h6>
                                                        Your Delivery/Pickup schedule
                                                    </h6>
                                                </div>
                                                {
                                                setScheduleFlag &&    
                                                <div className="mt-2">
                                                    <Button
                                                            color="info"
                                                            outline
                                                            size="sm"
                                                            type="button"
                                                            className="mr-1"
                                                            onClick={()=>this.setScheduleModalDisplayFlag(true)}
                                                    >
                                                        <i className="fa fa-calendar" aria-hidden="true"></i>
                                                        Set Schedule
                                                    </Button>
                                                </div>
                                                }
                                                {
                                                !setScheduleFlag &&    
                                                <div className="mt-2">
                                                    <Row className="mt-2">
                                                        <Col xs="4" sm="6" md="4">
                                                            Next pick-up
                                                        </Col>
                                                        <Col xs="8" sm="6" md="8">
                                                            {(laundry && !_.isEmpty(laundry.nextPickupDeliverySchedule.pickup)) ? `${laundry.nextPickupDeliverySchedule.pickup.displayDate} ${laundry.nextPickupDeliverySchedule.pickup.startTime} - ${laundry.nextPickupDeliverySchedule.pickup.endTime}`:""}
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-2">
                                                        <Col xs="4" sm="6" md="4">
                                                            Next delivery
                                                        </Col>
                                                        <Col xs="8" sm="6" md="8">
                                                            {(laundry && !_.isEmpty(laundry.nextPickupDeliverySchedule.delivery)) ? `${laundry.nextPickupDeliverySchedule.delivery.displayDate} ${laundry.nextPickupDeliverySchedule.delivery.startTime} - ${laundry.nextPickupDeliverySchedule.delivery.endTime}`:""}
                                                        </Col>
                                                    </Row>
                                                    
                                                </div>
                                                } 
                                                
                                                {/*This is only to handle one time pickups changes, recurring upcoming cannot be changed  */}
                                                {
                                                    displayModifyUpcomingPickupButton &&
                                                    <div className="mt-2">
                                                        <Row className="mt-2">
                                                            <Col xs="12" sm="10" md="10">
                                                            <Button
                                                                color="info"
                                                                outline
                                                                size="sm"
                                                                type="button"
                                                                className="mr-1 btn-block"
                                                                onClick={()=>{this.setDisplayModifyOneTimeUpcomingPickupModalFlag(true)}}    
                                                            >
                                                                <i className="fa fa-pencil" aria-hidden="true"></i>
                                                                Change Upcoming Pickup
                                                            </Button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                }

                                                {
                                                    displayCancelUpcomingPickupButton &&
                                                    <div className="mt-2">
                                                        <Row className="mt-2">
                                                            <Col xs="12" sm="10" md="10">
                                                                <Button
                                                                    color="info"
                                                                    outline
                                                                    size="sm"
                                                                    type="button"
                                                                    className="mr-1 btn-block"
                                                                    onClick={()=>{this.setCancelUpcomingPickupModalFlag(true)}}
                                                                >
                                                                    <i className="fa fa-ban" aria-hidden="true"></i>
                                                                    Cancel Pickup
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    
                                                }

                                                {
                                                    allowedToGetBackToCurrentWeekSchedule && 
                                                    <div className="mt-2">
                                                        <Row className="mt-2">
                                                            <Col xs="12" sm="10" md="10">
                                                                <Button
                                                                    color="info"
                                                                    outline
                                                                    size="sm"
                                                                    type="button"
                                                                    className="mr-1 btn-block"
                                                                    onClick={()=>{this.handleGetMeOnThisWeeksLaundryPickupSchedule()}}
                                                                >
                                                                    <i className="fa fa-calendar" aria-hidden="true"></i>
                                                                    Get me on this weeks pickup
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                }

                                                {
                                                    displayMakeSpecialRequestForUpcomingPickupButton &&
                                                    <div className="mt-2">
                                                        <Row className="mt-2">
                                                            <Col xs="12" sm="10" md="10">
                                                                <Button
                                                                    color="info"
                                                                    outline
                                                                    size="sm"
                                                                    type="button"
                                                                    className="mr-1 btn-block"
                                                                    onClick={()=>{this.setDisplaySpecialRequestForUpcomingPickupModalFlag(true)}}
                                                                >
                                                                    <i className="fa fa-comment" aria-hidden="true"></i>
                                                                    Laundry/Dryclean Requests
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    
                                                }                   
                                            </Col>

                                            <Col md="3" className="align-middle d-none d-md-block">
                                                <img  
                                                    className="img-rounded img-responsive"
                                                    src={require("../../../../../assets/mylazybones/img/laundry_package.jpg")}
                                                    
                                                />
                                                    
                                            
                            
                                            </Col>
                                        </Row>
            
                                        <div className="d-none d-md-block">
                                            <hr/>
                                        </div>
            
                                        <Row>
                                            <Col md="4" className="br-solid">
                                                <div className="d-block d-md-none">
                                                    <hr/>
                                                </div>
                                                <div>
                                                    <h6>
                                                        {displayModifyPickupScheduleButton && displayAsapPickupButton && "Make a Change?"}
                                                    </h6>
                                                </div>
                                                <div className="mt-2">
                                                    {
                                                        displayModifyPickupScheduleButton && 
                                                        <>
                                                        <Row>
                                                            <Col className="mb-2">
                                                                <Button
                                                                    color="info"
                                                                    outline
                                                                    size="sm"
                                                                    type="button"
                                                                    className="mr-1"
                                                                    onClick = {()=>this.setDisplayModifyRecurringScheduleModalFlag(true)}
                                                                >
                                                                    <i className="fa fa-calendar" aria-hidden="true"></i>
                                                                    Modify Weekly Schedule
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="mb-2">
                                                                <Button
                                                                    color="info"
                                                                    outline
                                                                    size="sm"
                                                                    type="button"
                                                                    className="mr-1"
                                                                    onClick = {()=>this.setDisplayCancelRecurringScheduleModalFlag(true)}
                                                                >
                                                                    <i className="fa fa-calendar" aria-hidden="true"></i>
                                                                    Cancel Weekly Schedule
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        </>
                                                    }

                                                    
                                                    {
                                                        displayAsapPickupButton && 
                                                        <Row>
                                                            <Col className="mb-2">
                                                                <Button
                                                                    color="info"
                                                                    outline
                                                                    size="sm"
                                                                    type="button"
                                                                    className="mr-1"
                                                                >
                                                                    <i className="fa fa-bolt" aria-hidden="true"></i>
                                                                    ASAP Pick Up
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    
                                                    
                                                    
                                                    
                                                </div>     
                                                
                                            </Col>
                                            
                                            
                                            <Col md="4" className="br-solid">
                                                <div className="d-block d-md-none">
                                                    <hr/>
                                                </div>
                                                {
                                                    (
                                                        displayDeliveryWaiverAndDriverInstructionButton || 
                                                        displayUpdateEmailAndTextReminderButton 
                                                    ) && 
                                                
                                                        <div>
                                                            <h6>
                                                                Requests
                                                            </h6>
                                                        </div>
                                                }
                                                
                                                <div className="mt-2">
                                                    {
                                                        displayDeliveryWaiverAndDriverInstructionButton && 
                                                        <Row>
                                                            <Col>
                                                                <Button
                                                                    className="btn-link mr-1"
                                                                    color="info"
                                                                    onClick={e => this.setDisplayModifyDeliveryWaiverAndDriverInstructionsModalFlag(true)}
                                                                    style={{textTransform:"none",paddingLeft:"0px",textAlign:"left"}}
                                                                >
                                                                    <i className="fa fa-caret-right" aria-hidden="true"></i>
                                                                    Update Delivery Waiver & Driver Instructions
                                                                </Button> 
                                                            </Col>
                                                        </Row>
                                                    }
                                                    
                                                    {
                                                        displayUpdateEmailAndTextReminderButton && 
                                                        <Row>
                                                            <Col>
                                                                <Button
                                                                    className="btn-link mr-1"
                                                                    color="info"
                                                                    onClick={e => handleOnClickOfEmailAndTextReminderLink(true)}
                                                                    style={{textTransform:"none",paddingLeft:"0px",textAlign:"left"}}
                                                                >
                                                                    <i className="fa fa-caret-right" aria-hidden="true"></i>
                                                                    Update Email or Text reminders
                                                                </Button> 
                                                            </Col>
                                                        </Row>    
                                                    }
                                                    
                                                    
                                                    
                                                </div>                    
                                                    
                                                
                                                
                                            </Col>
            
                                            <Col md="4" className="bl-solid">
                                                <div className="d-block d-md-none">
                                                    <hr/>
                                                </div>
                                                
                                                {
                                                    isEligibleForSupplies && 
                                                    <>
                                                        <div>
                                                            <h6>
                                                                Additional Services
                                                            </h6>
                                                        </div>
                                                        <div className="mt-2 ">
                                                            <Row>
                                                                <Col xs="2" md="3">
                                                                    <img
                                                                        alt="..."
                                                                        className="img-responsive"
                                                                        src={require("assets/mylazybones/img/order_packing_supplies.jpg")}
                                                                        style={{width:"50px",height:"50px"}}
                                                                    />
                                                                </Col>
                                                                <Col xs="8">
                                                                    <Button
                                                                        className="btn-link mr-1"
                                                                        color="info"
                                                                        onClick={e => this.props.history.push("/supplies")}
                                                                        style={{textTransform:"none",paddingLeft:"0px",textAlign:"left"}}
                                                                    >
                                                                        Order packing supplies
                                                                    </Button> 
                                                                </Col>
                    
                                                            </Row>
                                                            
                                                            
                                                            
                                                        </div> 
                                                    </> 
                                                }
                                            
                            
                                            </Col>
                                        </Row>
                                        </>
                                    }
                                    {
                                        displaySignupLaundryPlan && 
                                        <div className="min-height-sm">
                                            <Row>
                                                <Col xs="12" md="9">
                                                    <div className="mlb-alert mlb-alert-primary" role="alert">
                                                        <Row>
                                                            <Col xs="1" sm="1" className="fs-42 text-danger d-none d-sm-block">
                                                                    <i className="fa fa-exclamation-triangle fa-6" aria-hidden="true"></i>    
                                                            </Col>
                                                            <Col>
                                                            <strong>You currently don't have an laundry plan with us. If this is an error then please contact Lazybones and we will address the issue.</strong>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                                <Col xs="12" md="3" >
                                                    <img  
                                                        className="img-rounded img-responsive"
                                                        src={require("../../../../../assets/mylazybones/img/MyAccount/Laundry/Laundry_Myaccount_Landing.jpg")}
                                                        
                                                    />
                                                </Col>
                                            </Row>
                                            
                                        </div>
                                        
                                    }

                                </div> 
        
                            
                                
                            </CardBody>
                        </Card>
                    </Col> 
                </Row>

                <Modal isOpen={displayWelcomeModal} toggle={() => handleOnCloseOfWelcomeModal(false)}>
                  <div className="modal-header no-border-header">
                    <button
                      className="close"
                      type="button"
                      onClick={() => handleOnCloseOfWelcomeModal(false)}
                    >
                      ×
                    </button>
                    <h4 className="modal-title animated bounce text-success modal-myaccount-header-animation" id="myModalLabel">
                      <i className="fa fa-thumbs-o-up" aria-hidden="true"></i> Congratulations!
                    </h4>
                    
                  </div>
                  <div className="modal-body">
                    
                    <p>
                    You have successfully created your Lazybones Laundry Account. <br/>Next, please click <Button
                        className="mr-1"
                        color="link"
                        onClick={(e) =>{
                            handleOnCloseOfWelcomeModal(false)
                            return this.setScheduleModalDisplayFlag(true);
                        }}
                      >
                        'Set Schedule'
                      </Button> to schedule your laundry pickup/delivery day. 
                      
                    </p>  
                    
                  </div>
                  <div className="modal-footer">
                    <Button
                      className="btn-link"
                      color="primary"
                      type="button"
                      onClick={() => handleOnCloseOfWelcomeModal(false)}
                    >
                      Close
                    </Button>
                  </div>
                </Modal>

                {/* Set Processing modal */}
                <ProcessingModal
                    displayModal={processingModalDisplayFlag}
                    handleCloseOfModalWindow={this.handleCloseOfProcessingModalWindow}
                />
                {/* End of Processing modal */}

                {/* Set Garment Insurance modal */}
                <GarmentPricingListModal
                    modalDisplayFlag={displayGarmentPricingModalFlag}
                    handleModalDisplay={this.setGarmentPricingModalFlag}
                />
                {/* End of Garment Insurance modal */}
            
                {/* Set Schedule modal */}
                <ResidentialCustomerAccountLaundrySchedulingModal
                    scheduleModalDisplay = {scheduleModalDisplayFlag} 
                    handleScheduleModalDisplay = {this.setScheduleModalDisplayFlag}
                    handleSetSchedule = {this.handleSetSchedule}
                    listOfOneTimePickupDates={listOfOneTimePickupDates}
                    laundryOrder = {laundry}
                    user={user}
                />
                {/* End of Schedule modal */}
                
                {/* Modify cancel recurring schedule modal */}
                <ResidentialCustomerAccountLaundryCancelRecurringScheduleModal
                    modalDisplayFlag = {displayCancelRecurringScheduleModalFlag}
                    handleModalDisplayFlag = {this.setDisplayCancelRecurringScheduleModalFlag}
                    handleSaveModifyRecurringSchedule = {this.handleCancelRecurringSchedule}
                    laundryOrder = {laundry}
                    user={user}
                />
                
                {/* Modify recurring schedule modal */}
                <ResidentialCustomerAccountLaundryModifyRecurringScheduleModal
                    modalDisplayFlag = {displayModifyRecurringScheduleModalFlag}
                    handleModalDisplayFlag = {this.setDisplayModifyRecurringScheduleModalFlag}
                    handleSaveModifyRecurringSchedule = {this.handleSaveModifiedRecurringSchedule}
                    laundryOrder = {laundry}
                    user={user}
                />

                {/* Modify one time upcoming pickup modal */}
                <ResidentialCustomerAccountLaundryModifyOneTimeUpcomingPickupModal
                    modalDisplayFlag = {displayModifyOneTimeUpcomingPickupModalFlag}
                    handleModalDisplayFlag = {this.setDisplayModifyOneTimeUpcomingPickupModalFlag}
                    handleSaveOperation = {this.handleSaveModifyOneTimeUpcomingPickup}
                    laundryOrder = {laundry}
                    user={user}
                />
                

                {/*Cancel upcoming pickup modal */}
                <ResidentialCustomerAccountCancelUpcomingPickupModal
                    modalDisplayFlag={cancelUpcomingModalDisplayFlag}
                    handleModalDisplayFlag = {this.setCancelUpcomingPickupModalFlag}
                    handleCancelUpcomingPickup={this.handleCancelUpcomingPickup}
                    laundryOrder = {laundry}
                    user={user}
                />

                {/* Make special requests for Upcoming pickup */}
                <ResidentialCustomerAccountLaundryUpcomingPickupSpecialRequest
                    modalDisplayFlag = {displaySpecialRequestForUpcomingPickupModalFlag}
                    handleModalDisplayFlag = {this.setDisplaySpecialRequestForUpcomingPickupModalFlag}
                    handleSaveOperation = {this.handleSaveSpecialRequestForUpcomingPickupModalFlag}
                    laundryOrder = {laundry}
                    user={user}
                />

                {/* Modify Delivery Waiver and Driver Instructions */}
                <ResidentialCustomerAccountLaundryModifyDeliveryWaiverAndDriverInstructions
                    modalDisplayFlag = {displayModifyDeliveryWaiverAndDriverInstructionsModalFlag}
                    handleModalDisplayFlag = {this.setDisplayModifyDeliveryWaiverAndDriverInstructionsModalFlag}
                    handleSaveOperation = {this.handleSaveDeliveryWaiverAndDriverInstructions}
                    laundryOrder = {laundry}
                    user={user}
                />
                                            
            </>
            
        );
    }

}



export default ResidentialCustomerAccountLaundryDetail;