import React,{Component} from "react";
import Select from "react-select";
import moment from "moment";
import ReactDatetime from "react-datetime";
import _ from "lodash";
import laundryService from "services/laundryService"

// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";




// reactstrap components
import {
    Button,
    Row,
    Col,
    FormGroup, 
    Modal, 
  } from "reactstrap";

class StudentAccountLaundryModifyRecurringScheduleModal extends Component{
    recurringPickupDayOptions = [
        { value: "", label: " Select Day", isDisabled: true },
        
    ];

    recurringDeliveryDayOptions = [
        { value: "", label: " Select Day", isDisabled: true },
        
        
    ];

    constructor(){
        super();
        this.state = {
            data:{
                pickUpType:"recurring", //recurring, one-time, one-time-asap
            
                recurringPickupDay:"",
                recurringPickupDayId:"",
                
                recurringDeliveryDay:"",
                recurringDeliveryDayId:"",
                
                recurringPickupTimeslotList:[],
                recurringPickUpTime:"",  
                recurringPickUpTimeId:"",

                recurringDeliveryTimeslotList:[],
                recurringDeliveryTime:"",
                recurringDeliveryTimeId:"",

                
            },
            errors:{},
            
            
        }
    }

    getRecurringWeeklyScheduleDisplayText = (laundryOrder)=>{
        let displayText = "";

        const {laundryOrder:laundryOrderDb} = laundryOrder;
        const now = moment();
        const {
            pickup_dayname:pickupDayNumerical,
            pickup_starttime:pickupStartTime,
            pickup_endtime:pickupEndTime,
            delivery_dayname:deliveryDayNumerical,
            delivery_starttime:deliveryStartTime,
            delivery_endtime:deliveryEndTime,
        } = laundryOrderDb;
        
        if(pickupDayNumerical && deliveryDayNumerical){
            const pickupDay = laundryService.getPickupDeliveryDay(pickupDayNumerical);
            const deliveryDay = laundryService.getPickupDeliveryDay(deliveryDayNumerical);

            const pickupStartTimeMoment = moment(`${now.format('YYYY-MM-DD')} ${pickupStartTime}`);
            const pickupEndTimeMoment = moment(`${now.format('YYYY-MM-DD')} ${pickupEndTime}`);
            const deliveryStartTimeMoment = moment(`${now.format('YYYY-MM-DD')} ${deliveryStartTime}`);
            const deliveryEndTimeMoment = moment(`${now.format('YYYY-MM-DD')} ${deliveryEndTime}`);

            
            displayText = <p>
                <strong>Pickup Day and Time:</strong> {`Every ${pickupDay} between ${pickupStartTimeMoment.format('ha')} - ${pickupEndTimeMoment.format('ha')}`}.<br/>
                <strong>Delivery Day and Time:</strong> {`Every ${deliveryDay} between ${deliveryStartTimeMoment.format('ha')} - ${deliveryEndTimeMoment.format('ha')}`}
            </p>
        }
        
        
        
        

        return displayText;

    }

    handleChange = ({currentTarget:input})=>{
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[input.name] = input.value;
        this.setState({data,errors});
        
    }

    setRecurringDeliveryDay = selectedValue=>{
        const data = {...this.state.data}
        data.recurringDeliveryDay = selectedValue;
        data.recurringDeliveryDayId = selectedValue.value;

        //get the timeslots based on the selected day
        const { laundryOrder } = this.props
        if(laundryOrder){
            const {laundrySchedules} = laundryOrder;
            const deliveryDayRecordId = selectedValue.value;
            const deliveryDay = selectedValue.label;
            const deliveryTimeslotList = laundryService.generateLaundryDeliveryTimeslotDropdownList(deliveryDay,deliveryDayRecordId,laundrySchedules);
            //console.log(deliveryTimeslotList);
            data.recurringDeliveryTimeslotList = deliveryTimeslotList;
        } 


        this.setState({data})   
    }

    setRecurringPickupDay = selectedValue=>{
        const data = {...this.state.data}
        data.recurringPickupDay = selectedValue;
        data.recurringPickupDayId = selectedValue.value;
        data.recurringDeliveryDay = "";
        data.recurringDeliveryDayId = "";
        data.recurringDeliveryTimeslotList = [];
        data.recurringDeliveryTime = "";
        data.recurringDeliveryTimeId = "";

        //get the timeslots based on the selected day
        const { laundryOrder } = this.props
        if(laundryOrder){
            const {laundrySchedules} = laundryOrder;
            const pickupDayRecordId = selectedValue.value;
            const pickupDay = selectedValue.label;
            const pickupTimeslotList = laundryService.generateLaundryPickupTimeslotDropdownList(pickupDay,pickupDayRecordId,laundrySchedules);
            //console.log(pickupTimeslotList);
            data.recurringPickupTimeslotList = pickupTimeslotList;
        } 

        this.setState({data})   
    }

    
    setRecurringDeliveryTime = (timeslotObject)=>{
        const data = {...this.state.data}
        data.recurringDeliveryTime = timeslotObject.formattedTime;
        data.recurringDeliveryTimeId = timeslotObject.id
        this.setState({data}) 
    }

    
    setRecurringPickupTime = (timeslotObject)=>{
        const data = {...this.state.data}
        data.recurringPickUpTime = timeslotObject.formattedTime;
        data.recurringPickUpTimeId = timeslotObject.id
        this.setState({data}) 
    }

    /*  Send state data to the parent laundry detail */
    saveSchedule = ()=>{
        const data = {...this.state.data}
        this.props.handleSaveModifyRecurringSchedule(data);
        return;
    }

    render(){
        let recurringPickupDayOptions = this.recurringPickupDayOptions;
        let recurringDeliveryDayOptions = this.recurringDeliveryDayOptions;
        

        const { 
            pickUpType,
            recurringDeliveryDay,
            recurringDeliveryTime,
            recurringDeliveryTimeId,
            recurringDeliveryTimeslotList,
            recurringPickupDay,
            recurringPickUpTime,
            recurringPickUpTimeId,
            recurringPickupTimeslotList,
                
        } = this.state.data
        
        const { 
                handleModalDisplayFlag,
                modalDisplayFlag,
                laundryOrder,
                user 
        } = this.props;

        let recurringWeeklyScheduleDisplayText = ""
        if(laundryOrder){
            const {laundrySchedules} = laundryOrder;
            recurringPickupDayOptions = laundryService.generateLaundryPickupDropdownListFromZoneScheduleLaundryRecords(laundrySchedules);
            
            const selectedPickupDayObject = (!_.isEmpty(recurringPickupDay)) ? recurringPickupDay:{};
            recurringDeliveryDayOptions = laundryService.generateLaundryDeliveryDropdownListFromZoneScheduleLaundryRecords(laundrySchedules,selectedPickupDayObject);
            
            recurringWeeklyScheduleDisplayText = this.getRecurringWeeklyScheduleDisplayText(laundryOrder);

        }

        const displayRecurringSettingPropertiesAreaCSS = pickUpType === "recurring" ? "":"d-none";
        
        let [displayFooter] = ["d-none"];
        if(pickUpType === "recurring"){
            displayFooter  = (
                recurringDeliveryDay && 
                recurringDeliveryTime && 
                recurringPickupDay && 
                recurringPickUpTime) ? "":"d-none";

        }


        
        return(
            <Modal size="lg" isOpen={modalDisplayFlag} toggle={() =>handleModalDisplayFlag(false)}>
                <div className="modal-header">
                    <button
                        className="close"
                        type="button"
                        onClick={() =>handleModalDisplayFlag(false)}
                    >
                        <span>×</span>
                    </button>
                    <h5
                        className="modal-title text-center"
                        id="exampleModalLabel"
                    >
                        Modify Weekly Pickup/Delivery Schedule
                    </h5>
                </div>
                <div className="modal-body">
                    {/* Recurring Properties */}                    
                    <Row>
                        <Col>
                            <h6>Your current weekly schedule:</h6>
                            {recurringWeeklyScheduleDisplayText}
                        </Col>
                    </Row>
                    <Row className={displayRecurringSettingPropertiesAreaCSS}>
                        <Col>
                            <hr/>
                            <h6>Select new recurring pickup date & time?</h6>
                            <Row>
                                <Col md="6">
                                    <Row>
                                        <Col sm="4">
                                            Pickup Day
                                        </Col>
                                        <Col sm="8">
                                            <FormGroup>
                                                <Select
                                                    className="react-select react-select-default"
                                                    classNamePrefix="react-select"
                                                    name="defaultSelect"
                                                    value={recurringPickupDay}
                                                    onChange={value => this.setRecurringPickupDay(value)}
                                                    options={recurringPickupDayOptions}
                                                    placeholder="Select"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="6" className={(recurringPickupDay) ?"":"d-none"}>
                                    <Row>
                                        <Col sm="4">
                                            Delivery Day
                                        </Col>
                                        <Col sm="8">
                                            <FormGroup>
                                                <Select
                                                    className="react-select react-select-default"
                                                    classNamePrefix="react-select"
                                                    name="defaultSelect"
                                                    value={recurringDeliveryDay}
                                                    onChange={value => this.setRecurringDeliveryDay(value)}
                                                    options={recurringDeliveryDayOptions}
                                                    placeholder="Select"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col md="6"  className={(recurringPickupDay && recurringPickupTimeslotList && recurringPickupTimeslotList.length) ? "":"d-none"}>
                                    <Row>
                                        <Col sm="4">
                                            Pickup Time
                                        </Col>
                                        <Col sm="8" md="8" lg="8">
                                            {
                                                recurringPickupTimeslotList.map((timeslot)=>{
                                                    return(
                                                        <Row key={`pickup_${timeslot.id}`}>
                                                            <Col className="mb-2">
                                                                <Button
                                                                    className="btn-round mr-1"
                                                                    color="danger"
                                                                    outline = {recurringPickUpTimeId === timeslot.id ? false:true}
                                                                    block
                                                                    type="button"
                                                                    onClick={()=>this.setRecurringPickupTime(timeslot)}
                                                                >
                                                                    {`${timeslot.formattedTime}`}
                                                                </Button>
                                                            
                                                            </Col>
                                                        </Row> 
                                                    )
                                                }) 
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="6" className={(recurringDeliveryDay && recurringDeliveryTimeslotList && recurringDeliveryTimeslotList.length) ? "" : "d-none"}>
                                    <hr className="d-block d-md-none" />
                                    <Row>
                                        <Col sm="4">
                                            Delivery Time
                                        </Col>
                                        <Col sm="8" md="8" lg="8">
                                            {
                                                recurringDeliveryTimeslotList.map((timeslot)=>{
                                                    return(
                                                        <Row key={`delivery_${timeslot.id}`}>
                                                            <Col className="mb-2">
                                                                <Button
                                                                    className="btn-round mr-1"
                                                                    color="danger"
                                                                    outline = {recurringDeliveryTimeId === timeslot.id ? false:true}
                                                                    block
                                                                    type="button"
                                                                    onClick={()=>this.setRecurringDeliveryTime(timeslot)}
                                                                >
                                                                    {`${timeslot.formattedTime}`}
                                                                </Button>
                                                            
                                                            </Col>
                                                        </Row> 
                                                    )
                                                }) 
                                            }
                                        </Col>
                                    </Row>                                        
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* End of Recurring Properties */}
                </div>
                <div className={`${displayFooter} modal-footer`}>
                    <div className="left-side">
                        <Button
                        className="btn-link"
                        color="default"
                        type="button"
                        onClick={() => handleModalDisplayFlag(false)}
                        >
                        Cancel
                        </Button>
                    </div>
                    <div className="divider" />
                    <div className="right-side">
                        <Button className="btn-link" color="danger" type="button" onClick={()=>this.saveSchedule()}>
                            Save & Schedule
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
};

export default StudentAccountLaundryModifyRecurringScheduleModal;