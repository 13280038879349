import React from "react";
import _ from "lodash";
import {Elements} from 'react-stripe-elements';


// reactstrap components
import {
    Button,
    Container,
    Row,
    Col,
    FormGroup, 
    Modal, 
} from "reactstrap";


// core components
import MyAccountNavbar from "../../../../Navbars/MyAccountNavbar";
import FooterBlack from "../../../../Footers/FooterBlack";

//Signup components
import SelectPricingAndInsurance from "./SignupSteps/ResidentialPricingAndInsurance"
import PaymentInformation from "../../Common/PaymentInformation"
import CustomerAddressInformation from "../../Common/CustomerAddressInformation";
import ReviewScreen from "./SignupSteps/ReviewScreen";

const proceedToPaymentInformation = user=>{
    let proceed  = false;
  
    const { laundry } = user; 
    const {residential:laundryProperties} = laundry; 
    const {insurance,laundryType,pricePlan} = laundryProperties;
    proceed  = (
      insurance !== "" && laundryType !== "" && pricePlan !== "" 
    ) ? true:false;
       
    return proceed;
}

const proceedToAddressInformationStep = user=>{
    let proceed  = false;

    const {updatePaymentInformationFlag,existingPaymentInformationOnFile,paymentInformation} = user;
    const {isExpired} = existingPaymentInformationOnFile;
    const {creditCardStripeToken} = paymentInformation

    if(proceedToPaymentInformation(user) && updatePaymentInformationFlag === false){
        proceed  = true;
    }
    else if(proceedToPaymentInformation(user) &&  updatePaymentInformationFlag === true && creditCardStripeToken !== ""){
        proceed  = true;
    }

    return proceed;
}

const proceedToReviewScreen = user=>{
    let proceed  = false;

    const {laundry,updatePrimaryAddressFlag} = user;
    const {pickupAndDeliveryAddress} = laundry;
    
    if( proceedToPaymentInformation(user) && 
        proceedToAddressInformationStep(user) && 
        updatePrimaryAddressFlag === false){
        proceed  = true;
    }
    else if(
        proceedToPaymentInformation(user) && 
        proceedToAddressInformationStep(user) && updatePrimaryAddressFlag === true){
            const {isDormitory,dormitory,addressLine1,city,state,postalCode} = pickupAndDeliveryAddress;
            if(isDormitory === true && !_.isEmpty(dormitory)){
                proceed  = true;
            }
            else if(isDormitory === false && addressLine1 !== "" && city !== "" && postalCode != ""){
                proceed  = true;
            }
    }
    
    return proceed;
}

const validateToRenderComponent = (props)=>{
    const {accountType, product, school,step } = props.match.params;
    let proceed = false;
  
    const {user} = props;
    
    switch(step){
      case "select-plan":                     
              proceed = true;
              break;
      case "payment-info":         
              proceed = proceedToPaymentInformation(user);
              break;
      case "address-info":           
              proceed = proceedToAddressInformationStep(user);
              break;
      case "review-screen":                     
              proceed = proceedToReviewScreen(user);
              break;
      default:    
              proceed = false;
    }
  
    return proceed;
}

function renderComponent(props){
    const {accountType, product, schoolId, semesterId, step } = props.match.params;
    const {user} = props;
    let component = <SelectPricingAndInsurance  {...props}  />
  
    switch(step){
      case "select-plan":                     
              component= <SelectPricingAndInsurance {...props}  />;
              break;
      case "payment-info":         
              component= <Elements><PaymentInformation {...props} /></Elements>;
              break;
      case "address-info":           
              component= <CustomerAddressInformation {...props}  />;
              break;
      case "review-screen":                     
              component= <ReviewScreen {...props}  />;
              break;
      default:    
              component= <SelectPricingAndInsurance {...props}  />;
    }
    return component;
}
 

function SignupLaundryResidential(props) {
    const {accountType, product, schoolId, semesterId, step } = props.match.params;
        
    React.useEffect(() => {
        const proceed = validateToRenderComponent(props)
        if(!proceed){
            return props.history.push(`/existing-user/signup/${accountType}/${product}/${schoolId}/${semesterId}/select-plan`)
        }

        document.body.classList.add("product-page");
        return function cleanup() {
          document.body.classList.remove("product-page");
        };
    });
    //console.log(props);
    return (
        <>
        <MyAccountNavbar {...props} />
        <div className="main">
            <div id="sectionArea" className="section section-gray min-height-lg" style={{paddingTop:"100px"}}>
                {renderComponent(props)}
            </div>
        </div>
        <FooterBlack />
        </>
    );
}

export default SignupLaundryResidential;
