import React,{Component} from "react";
import _ from "lodash";
import dateFormat from "dateformat";
import moment from "moment";
import accounting from "accounting";
import Joi from "@hapi/joi";

import ProcessingModal from "./ProcessingModal";
import ApplicationConstant from "components/Common/ApplicationConstants";
import applicationConstantUtility from "../../../../utils/applicationConstants";
import FormErrorWidget from "../../../Common/FormErrorWidget";
import {scrollTop} from '../../../../utils/helperUtils';
import {renderError} from '../../../../utils/errorUtils';
import customToast from '../../../Common/CustomToast'

import GiftCardRedemptionModal from "../../GiftCard/GiftCardRedemptionModal"
import ByThePoundPricingModal from "../../LaundryInformation/Modals/ByThePoundPricingModal"

import UserService from "../../../../services/userService";


// reactstrap components
import {
    Badge,
    Button,
    Container,
    Card,
    CardBody,
    CustomInput,
    Row,
    Col,
    FormGroup, 
    Input,
    
} from "reactstrap";

class ResidentialReviewScreen extends Component{

    validationSchema = Joi.object({
      agreeTermsAndConditions:Joi.boolean().valid(true).truthy('true').falsy('false').sensitive().label('Terms and Conditions'),

    })
    
    validationCouponCodeSchema = Joi.object({
      couponCode:Joi.string().required().label('Coupon Code'), 
      couponType:Joi.allow(""),
      couponBalance:Joi.allow(""),
      amountToBeUsedForRedemption:Joi.allow(""),
    })

    errorMessages = {
      "agreeTermsAndConditions":{
          "any.only":"Please accept the terms and conditions",
      },
    }
    
    /*
    validationSchema = Joi.object({
      couponCode:Joi.string().required().label('Coupon Code'), 
      couponType:Joi.allow(""),
      couponBalance:Joi.allow(""),
      amountToBeUsedForRedemption:Joi.allow(""),
    }) 

    errorMessages = {}
    */

    constructor(){
        super();
        this.state = {
          byThePoundPricingModalDisplayFlag:false,
          openProcessingModalFlag:false,
          couponProcessingFlag:false,
          giftCardRedemptionModalDisplayFlag:false,
          data:{
            couponCode:"",
            couponType:"",
            giftCardBalance:"",
            giftCardAmountToBeUsedForRedemption:"", //This is basically the amount a user enters or it uses the balance
            agreeTermsAndConditions:false,
          },
          errors:{}
        }
    }

    componentDidMount(){
        scrollTop('sectionArea');
    }

    /*
      For some reason the page scrolls to the product area and user cannot see the errors
      I am just setting a timer to it scrolls back to the top if the serverError props gets updated
    */
    componentDidUpdate(prevProps){
        const {serverErrors} = this.props;
        const {serverErrors:serverErrorsPrev} =prevProps;
        if(!_.isEqual(serverErrors,serverErrorsPrev)){
          setTimeout(()=>{
            scrollTop('sectionArea');
          },1000)
        }
    }

   
    closeProcessingModalWindow = ()=>{
      const openProcessingModalFlag  = false;
      this.setState({openProcessingModalFlag});
    }

    displayRedemptionTextInModal = ()=>{
      return(
      <p>We will apply the amount you would like to redeem towards your next laundry order</p>
      )
    }

    displayTotalAmount = ()=>{
          const {user} = this.props;
          const product = "laundry";
          const userType = user[product].type; //student, residential
          //console.log(ApplicationConstant.SEMESTER_PLAN);
          const {totalCost} = user.laundry;
          const {residential:laundryProperties} = user.laundry;
          const isSemesterPlan = (laundryProperties.laundryType.id === ApplicationConstant.SEMESTER_PLAN) ? true:false;
          
          
          if(_.has(totalCost, ['finalTotal'])){
            //By the pound setup
            let displayObject = {};
            
            if(totalCost.finalTotal <= 0){
              //it means discount has been applied
              displayObject.displayLabel = "Total amount";
              displayObject.displayAmount = accounting.formatMoney(totalCost.subTotal);
            } 
            else{
              displayObject.displayLabel = "Total amount";
              displayObject.displayAmount  = accounting.formatMoney(totalCost.finalTotal);
              
            }

            const labelDisplayStyle = (!isSemesterPlan) ? {paddingTop:"7px"}:""
        

            return    <Row className="mt-2">
                        <Col xs="4" style={labelDisplayStyle}>
                          {displayObject.displayLabel}
                        </Col>
                        <Col xs="8">
                          {displayObject.displayAmount}  
                          {
                            !isSemesterPlan &&
                            <Button 
                              className="text-danger" color="link" style={{paddingTop:"5px",paddingLeft:"10px"}} 
                              onClick={()=>this.setByThePoundPricingModalDisplayFlag(true)}>
                                <i className="fa fa-info-circle mr-1" />  
                            </Button>
                          }
                        </Col>
                      </Row>

          }
          else
            return "";
          
    }

    displayDiscountApplied = ()=>{
          const {user} = this.props;
          const product = "laundry";
          const userType = user[product].type; //student, residential
          //console.log(ApplicationConstant.SEMESTER_PLAN);
          const {totalCost,discount} = user.laundry;
          
          let discountDisplayLabel = "";
          
          if(discount.voucherType === "GIFT_CARD")
            discountDisplayLabel = "Gift card entered"
          

          return <>
                <Row className="mt-2">
                    <Col xs="4">
                      Discounts Applied
                    </Col>
                    <Col xs="8">
                      {(_.has(totalCost, ['discountAmount'])) ? accounting.formatMoney(totalCost.discountAmount):"$ 0.00"}  
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col xs="4">
                      {discountDisplayLabel}
                    </Col>
                    <Col xs="8">
                      {
                        <>
                        {discount.discountCode} {" "}
                        <Badge color="success" pill>
                          Valid
                        </Badge>
                        </> 
                      }  
                    </Col>
                </Row>
                
                </>
    }


    getPickupDeliveryAddress = ()=>{
      const {user} = this.props;
      const product = "laundry";
      const userType = user[product].type; //student, residential
      const {residential:laundryProperties,pickupAndDeliveryAddress} = user.laundry;
      const {streetNumber,addressLine1, apartmentNumber,city,state,postalCode} = pickupAndDeliveryAddress;
      let address = "";
      address = <span>{`${streetNumber}, ${addressLine1} ${apartmentNumber}`} <br/> {`${city}, ${state} - ${postalCode}`}</span>;
      
      return address;
   }


   handleChange = ({currentTarget:input})=>{
    const errors = {...this.state.errors};
    const data = {...this.state.data};
    data[input.name] = input.value;
    this.setState({data,errors});
   }

    handleCheckbox= ()=>{
        const data  = {...this.state.data};
        data.agreeTermsAndConditions = !data.agreeTermsAndConditions
        this.setState({data})
    }  


   handleEditButton = (step)=>{
      const { handleRedirectToReviewScreen } = this.props;
      handleRedirectToReviewScreen(true);
      let url = `/signup/laundry/residential/${step}`; 
      this.props.history.push(url);
   }

   handleSubmit = ()=>{

      const errors =  this.validate(
        _.pick({...this.state.data}, ['agreeTermsAndConditions']),
        this.validationSchema
      );
      this.setState({errors: errors || {}});
      if(errors){scrollTop('sectionArea'); return;} 

      const {onClickOfPurchaseButtonFromReviewScreen} = this.props; 
      this.openProcessingModalWindow();
      onClickOfPurchaseButtonFromReviewScreen();
      return;
    
   }

    handleGiftCardCreditFromRedemptionModal = ({giftCardAmountToBeUsedForRedemption})=>{
        const {onClickOfApplyCouponButtonFromReviewScreen} = this.props;
        const data  = {
          ...this.state.data,
          giftCardAmountToBeUsedForRedemption,
        }
        
        this.setGiftCardRedemptionModalDisplayFlag(false);
        this.setState({data})
        return onClickOfApplyCouponButtonFromReviewScreen(data);
    }
    
    /*
      Validate if a coupon code is entered
    */
    handleApplyCoupon = async (e)=>{
        try{
            e.preventDefault(); 
            const product = "laundry";
            const {onClickOfApplyCouponButtonFromReviewScreen,user} = this.props;
            const userType = user[product].type; //student, residential
            const {totalCost} = user.laundry;
            const {residential:laundryProperties} = user.laundry;
            const isSemesterPlan = (laundryProperties.laundryType.id === ApplicationConstant.SEMESTER_PLAN) ? true:false;
          

            //client side errors will be caught in validate(), server side data error will be caught via try/catch
            const errors =  this.validate(
              _.omit({...this.state.data}, ['agreeTermsAndConditions']),
              this.validationCouponCodeSchema
            );
            this.setState({errors: errors || {}});
            if(errors){scrollTop('sectionArea'); return;} 

            this.setState({couponProcessingFlag:true});
            
            let formDataObject = {
              ...this.state.data,
              product,
              productType:laundryProperties.laundryType, 
              userType:userType,
              residential:{
                universityId:user.residentialCity.id,
                campusId:null,
              }
            };

            /* Send data to server */
                const {
                  status:processingStatus,
                  message,
                  couponCodeReturnObject} = await UserService.validateCouponCode(formDataObject);
                
                this.setState({couponProcessingFlag:false});
                if(processingStatus === true){
                  const {couponType,giftCardBalance,couponDetails} = couponCodeReturnObject;
                  const data = {
                    ...this.state.data,
                    couponType,
                    giftCardBalance,
                    couponDetails,
                  }
                  
                  if(couponType === applicationConstantUtility.DISCOUNT_TYPE_GIFTCARD){
                    if(!isSemesterPlan){
                      //By the pound plan 
                      //We need to have them enter a amount in a modal window that they would like to 
                      //We ask the user how much they want to redeem in order to updateamountToBeUsedForRedemption
                      
                      //Launch modal -- PENDING
                      this.setState({data});
                      this.setGiftCardRedemptionModalDisplayFlag(true);
                    }
                    else{
                      //Semester plan - RESIDENTIAL CUSTOMER DON'T HAVE A SEMESTER PLAN SO IT WILL NEVER GET HERE
                      data.giftCardAmountToBeUsedForRedemption = giftCardBalance;
                      this.setState({data});
                      return onClickOfApplyCouponButtonFromReviewScreen(data);
                    }

                  }
                  else if(couponType === applicationConstantUtility.DISCOUNT_TYPE_COUPON){
                    this.setState({data});
                    return onClickOfApplyCouponButtonFromReviewScreen(data);
                  }

                  //Need to update signup construct if valid  
                    

                }
                else
                    customToast.error(message);
            /* End of Send data to server */
            
            
            return;
        }catch(ex){
            //console.log(ex);
            this.setState({couponProcessingFlag:false});
            /*
            400 signals validation errors from the server
            renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop('sectionArea'); return;}
            }
            else
            renderError(ex);
        }
            

    }

    isCouponCodeEntered = ()=>{

      const {user} = this.props;
      const product = "laundry";
      const userType = user[product].type; //student, residential
      //console.log(ApplicationConstant.SEMESTER_PLAN);
      const {totalCost,discount} = user.laundry;
      return (discount.discountCode !== "") ? true:false;
         

    }

   openProcessingModalWindow = ()=>{
    const openProcessingModalFlag  = true;
    this.setState({openProcessingModalFlag});
   }

   setByThePoundPricingModalDisplayFlag = (flag)=>{
      this.setState({
        byThePoundPricingModalDisplayFlag:flag    
      })
   }

   setGiftCardRedemptionModalDisplayFlag = (flag)=>{
    this.setState({
        giftCardRedemptionModalDisplayFlag:flag    
    })
   }

   validate=  (data,validationSchema)=>{
        const options = {abortEarly:false,allowUnknown:true};
        //console.log(this.state.data);
        console.log(this.validationSchema.validate(this.state.data,options))
        let {error:errorListFromJoi} = validationSchema.validate(data,options);
        console.log(errorListFromJoi);
        if( errorListFromJoi === undefined) return null;

        const errorList = {};
        if(errorListFromJoi){
            errorListFromJoi.details.map(
                function(errorObject){
                    //console.log(errorObject);
                    if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                        const key = errorObject.context.key;
                        const joiType = errorObject.type;
                        //console.log(this.errorMessages);
                        if(_.has(this.errorMessages, [key, joiType]))
                            return errorList[errorObject.path[0]] = this.errorMessages[key][joiType];
                        else
                            return errorList[errorObject.path[0]] = errorObject.message;
                    }
                    else
                      return errorList[errorObject.path[0]] = errorObject.message;
                }.bind(this)
            );
        }
        
        const errors = {...errorList} 
        
        return Object.keys(errors).length > 0 ? errors:null;
   }

   render(){
       const {onClickOfBackButtonFromReviewScreen,user,serverErrors} = this.props;
       const {byThePoundPricingModalDisplayFlag,couponProcessingFlag,giftCardRedemptionModalDisplayFlag,data,errors} = this.state;
       const {agreeTermsAndConditions,giftCardBalance} = data;
       
       const {paymentInformation} = user;
       const product = "laundry";
       const userType = user[product].type; //student, residential
       const {totalCost} = user.laundry;
       const {residential:laundryProperties} = user.laundry;
       const isSemesterPlan = (laundryProperties.laundryType.id === ApplicationConstant.SEMESTER_PLAN) ? true:false;
       const pickupDeliveryAddress = this.getPickupDeliveryAddress();
       const byThePoundPriceAttribeDisplayFlag = false;

       return(
            <>
            <Container id="mainContentArea">
                <Row className="title-row justify-content-between">
                    <Col md="4">
                      <h3 className="shop">Signup for Laundry</h3>
                    </Col>
                    <Col  md="5">
                      <div className="text-md-right d-none">
                          <span className="text-muted">Step 5</span>
                          <Button color="link">
                          <i className="fa fa-shopping-cart" /> Review your selection
                          </Button>
                      </div>
                    </Col>
                </Row> 
                <Row className="title-body-row">
                    <Col md="7" className="order-first">
                        <Card className="card-plain bg-white p-2 rounded-lg">
                            <CardBody>
                                {/*Error display */}
                                {!_.isEmpty(errors )&&
                                    <FormErrorWidget
                                        errors={errors}
                                    />
                                }
                                {/* End of Error display */}
                                
                                {/*Server Error display */}
                                {!_.isEmpty(serverErrors) &&
                                    <FormErrorWidget
                                        errors={serverErrors}
                                    />
                                }
                                {/* End of Server Error display */}
                                <div>
                                    <h6 className="card-category">
                                        <span className="text-left">
                                          Primary Information
                                        </span>
                                        <span className="float-right text-right cursor text-info"
                                          onClick={()=>this.handleEditButton("enter-residential-customer-information")}
                                        >
                                          <i className="fa fa-edit"></i> Edit
                                        </span>
                                    </h6>
                                    <hr/>

                                    <Row>
                                      <Col xs="3">
                                        Name
                                      </Col>
                                      <Col xs="8">
                                        {`${user.firstName} ${user.lastName}`}
                                      </Col>
                                    </Row>

                                    <Row className="mt-2">
                                      <Col xs="3">
                                        Email
                                      </Col>
                                      <Col xs="8">
                                        {`${user.email}`}
                                      </Col>
                                    </Row>

                                    <Row className="mt-2">
                                      <Col xs="3">
                                        Cell Phone
                                      </Col>
                                      <Col xs="8">
                                        {`${user.cellPhone}`}
                                      </Col>
                                    </Row>

                                    <Row className="mt-2">
                                      <Col xs="3">
                                        Home Phone
                                      </Col>
                                      <Col xs="8">
                                        {`${user.homePhone}`}
                                      </Col>
                                    </Row>
                                    
                                </div>
                            </CardBody>
                        </Card>
                        
                    </Col>
                    <Col md="5" className="order-last">
                        <Card className="card-plain bg-white p-2 rounded-lg">
                            <CardBody>
                                <div>
                                     <h6 className="card-category">
                                        <span className="text-left">
                                          Login Information
                                        </span>
                                        <span className="float-right text-right cursor text-info"
                                          onClick={()=>this.handleEditButton("enter-login-payment-information")}
                                        >
                                          <i className="fa fa-edit"></i> Edit
                                        </span>
                                    </h6>
                                    <hr/>
                                    <Row>
                                      <Col xs="4">
                                        Username
                                      </Col>
                                      <Col xs="8">
                                        {`${user.username}`}
                                      </Col>
                                    </Row>
                                    <Row  className="mt-2">
                                      <Col xs="4">
                                        Password
                                      </Col>
                                      <Col xs="8">
                                        xxxxxxxxx
                                      </Col>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>    
                </Row>

                <Row className="title-body-row">
                    <Col md="7" className="order-last order-sm-last order-md-first">
                        <Card className="card-plain bg-white p-2 rounded-lg">
                            <CardBody>
                                <div>
                                    <h6 className="card-category">
                                        <span className="text-left">
                                          Your Product
                                        </span>
                                        <span className="float-right text-right cursor text-info"
                                          onClick={()=>this.handleEditButton("select-pricing-and-insurance")}
                                        >
                                          <i className="fa fa-edit"></i> Edit
                                        </span>
                                    </h6>
                                    <hr/>
                                    <Row className="mt-2">
                                      <Col xs="4">
                                        Plan
                                      </Col>
                                      <Col xs="8">
                                        {
                                          (isSemesterPlan) ? `Semester Plan (${laundryProperties.pricePlan.name})`:`By the Pound (${accounting.formatMoney(laundryProperties.pricePlan.price)} per pound)`
                                        }
                                      </Col>
                                    </Row>


                                    { //By the Block
                                      byThePoundPriceAttribeDisplayFlag && !isSemesterPlan && 
                                      <Row className="mt-2">
                                        <Col xs="4">
                                          Amount
                                        </Col>
                                        <Col xs="2">
                                          {accounting.formatMoney(ApplicationConstant.BY_THE_POUND_SIGNUP_MINIMUMCHARGE)} 
                                        </Col>
                                        <Col xs="6">
                                        {byThePoundPriceAttribeDisplayFlag && `(The amount will be used as a credit towards your first order)`}
                                        </Col>
                                      </Row>
                                    }

                                    <Row className="mt-2">
                                        <Col xs="4">
                                          Insurance
                                        </Col>
                                        <Col xs="8">
                                          {`${laundryProperties.insurance.type} Package`}
                                        </Col>
                                    </Row>

                                    {
                                      isSemesterPlan && 
                                      <Row className="mt-2">
                                          <Col xs="4">
                                            Tax
                                          </Col>
                                          <Col xs="8">
                                            {(_.has(totalCost, ['tax'])) ? accounting.formatMoney(totalCost.tax):"$ 0.00"} 
                                          </Col>
                                      </Row>
                                    }
                                    
                                    {
                                      this.isCouponCodeEntered() && 
                                      this.displayDiscountApplied()  
                                      
                                    }               

                                    { !this.isCouponCodeEntered() &&  
                                      <Row className="mt-2">
                                          <Col xs="4">
                                            Enter Coupon or Gift Card Code
                                          </Col>
                                          <Col xs="4">
                                              <Input 
                                                  type="text" 
                                                  name="couponCode" 
                                                  id="couponCode" 
                                                  placeholder="" 
                                                  value={this.state.data.couponCode} 
                                                  onChange={this.handleChange}
                                                  autoComplete="off"
                                              />
                                          </Col>
                                          <Col xs="4" className="mt-1">
                                              <Button className="btn-round" color="default" size="sm"
                                                onClick={this.handleApplyCoupon}
                                              >
                                                {
                                                  couponProcessingFlag &&
                                                  <div className="uil-reload-css reload-small mr-1">
                                                    <div />
                                                  </div>
                                                }
                                                
                                                Apply
                                              </Button>
                                          </Col>
                                      </Row>
                                    } 

                                    { 
                                      this.displayTotalAmount()   
                                    }        

                                    

                                   
                                </div> 

                                <div className="mt-3">
                                    <h6 className="card-category">
                                        <span className="text-left">
                                          Payment Information
                                        </span>
                                        <span className="float-right text-right cursor text-info"
                                          onClick={()=>this.handleEditButton("enter-login-payment-information")}
                                        >
                                          <i className="fa fa-edit"></i> Edit
                                        </span>
                                    </h6>
                                    <hr/>
                                    <Row className="mt-2">
                                      <Col xs="4">
                                        Credit Card Holder
                                      </Col>
                                      <Col xs="8">
                                        {paymentInformation.creditCardName}
                                      </Col>
                                    </Row>

                                    <Row className="mt-2">
                                      <Col xs="4">
                                        Credit Card Number
                                      </Col>
                                      <Col xs="8">
                                        xxxxxxxxxxx - {!_.isEmpty(paymentInformation.creditCardStripeToken.card) &&  paymentInformation.creditCardStripeToken.card.last4}
                                      </Col>
                                    </Row>

                                    <Row className="mt-2">
                                      <Col xs="4">
                                        Expiration Month - Year
                                      </Col>
                                      <Col xs="8">
                                        {!_.isEmpty(paymentInformation.creditCardStripeToken.card) &&  moment(`${paymentInformation.creditCardStripeToken.card.exp_year}-${paymentInformation.creditCardStripeToken.card.exp_month}-1`).format('MMMM')} - {!_.isEmpty(paymentInformation.creditCardStripeToken.card) &&  paymentInformation.creditCardStripeToken.card.exp_year}
                                      </Col>
                                    </Row>

                                    {
                                      _.has(totalCost, ['finalTotal']) && totalCost.finalTotal > 0 && 
                                      <Row className="mt-2">
                                        <Col xs="4">
                                          Amount be charged
                                        </Col>
                                        <Col xs="8">
                                          {(_.has(totalCost, ['finalTotal'])) ? accounting.formatMoney(totalCost.finalTotal):"$ 0.00"} 
                                        </Col>
                                      </Row>
                                    }

                                    <Row className="mt-4">
                                        <Col xs="1" className="pt-1">
                                          <CustomInput
                                              type="checkbox"
                                              id="agreeTermsAndConditions"
                                              name="agreeTermsAndConditions"
                                              label=""
                                              value={agreeTermsAndConditions}
                                              onChange={()=>this.handleCheckbox()}
                                              checked={agreeTermsAndConditions}
                                          />
                                        </Col>
                                        <Col className="pl-0">
                                            I agree to the {' '}
                                            <a 
                                                href={`${process.env.REACT_APP_API_URL}/users/terms-and-conditions`}
                                                className="btn-link btn btn-info btn-sm pl-0"
                                                target="_blank"
                                            >
                                                Terms and Conditions
                                            </a>
                                        </Col>
                                    </Row>

                                    
                                </div>     

                                <FormGroup row className="mt-5  justify-content-between">
                                    <Col xs={{ size: 2}}>
                                        <Button 
                                        className="btn-rounded btn-danger"
                                        onClick={()=>onClickOfBackButtonFromReviewScreen()}
                                        >
                                        Back
                                        </Button>
                                    </Col>
                                    <Col xs={{ size: 6}} sm={{ size: 4}} className="text-right">
                                        <Button 
                                        disabled={false}
                                        className="btn-rounded btn-danger"
                                        onClick={this.handleSubmit}
                                        >
                                        Proceed to Purchase
                                        </Button>
                                    </Col>
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="5" className="order-first order-sm-first order-md-last">
                        <Card className="card-plain bg-white p-2 rounded-lg">
                            <CardBody>
                                <div>
                                     <h6 className="card-category">
                                        <span className="text-left">
                                          Pickup/Delivery Address
                                        </span>
                                        <span className="float-right text-right cursor text-info"
                                          onClick={()=>this.handleEditButton("enter-residential-customer-information")}
                                        >
                                          <i className="fa fa-edit"></i> Edit
                                        </span>
                                    </h6>
                                    <hr/>
                                    <Row>
                                      <Col xs="12">
                                        {pickupDeliveryAddress}
                                      </Col>
                                      
                                    </Row>
                                    
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>       
            </Container>

            {/* Gift Card Redemption Generator Modal */}
            {
                <GiftCardRedemptionModal
                    modalName="giftCardRedemptionModal"
                    divAreaToScrollForError="giftCardRedemptionModal"
                    modalDisplayFlag={giftCardRedemptionModalDisplayFlag}
                    handleModalDisplay={this.setGiftCardRedemptionModalDisplayFlag}
                    handleSaveOperation = {this.handleGiftCardCreditFromRedemptionModal}
                    giftCardBalance={giftCardBalance}
                    displayRedemptionText={this.displayRedemptionTextInModal()}
                    
                />
            }

            {
              <ByThePoundPricingModal
                modalDisplayFlag={byThePoundPricingModalDisplayFlag}
                handleModalDisplay={this.setByThePoundPricingModalDisplayFlag}
              />
            }
            </>
       )
   }

}

export default ResidentialReviewScreen;