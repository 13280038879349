import React,{Component} from "react";
import _ from "lodash";
import Joi from "@hapi/joi";
import {scrollTop} from '../../../../../../../utils/helperUtils';

import FormErrorWidget from "../../../../../../Common/FormErrorWidget";
import {renderError} from '../../../../../../../utils/errorUtils';

// reactstrap components
import {
    Button,
    Row,
    Col,
    Table,
    FormGroup, 
} from "reactstrap";


class DeliveryItemSelectionStep2 extends Component{
    divAreaToScrollForError = "deliveryAddressSelectionStep2"

    validationSchema = Joi.object({
        listOfItemsSelectedForDelivery:Joi.array().min(1).label("List of items"),
    }) ;

    errorMessages = {
        "listOfItemsSelectedForDelivery":{
            "array.min":"Please select Check all button to select all items for delivery",
        },
    }

    constructor(){
        super();
        this.state ={
            data:{
                listOfItemsSelectedForDelivery:[],
            },
            error:{},
        }

    }

    componentDidMount(){
        try{
            this.mapModelToView();
        }
        catch(ex){
            console.log(`Component did mount on Laundry Student Info ${ex}`);
        }
        
    }

    getStorageItemTableRow = ()=>{
        const {listOfStorageItems} = this.props;
        const {listOfItemsSelectedForDelivery} = this.state.data;
        if(listOfStorageItems.length > 0){
            return listOfStorageItems.map(s=>{
                const {storage_item_id:storageItemId,barcode,description} = s;
                const itemTaggedForDelivery = (_.find(listOfItemsSelectedForDelivery,{'storage_item_id':storageItemId}) !== undefined) ? true:false;
                
                return <tr key={storageItemId}>
                            <td>{description}</td>
                            <td>{barcode}</td>
                            <td className="text-center">
                                {
                                    itemTaggedForDelivery &&
                                    <span className="text-success">
                                        <i className="fa fa-check-circle-o fa-2" aria-hidden="true"></i>
                                    </span>

                                } 
                                <Button
                                    className="btn-round mr-1 d-none"
                                    color="danger"
                                    outline = {itemTaggedForDelivery ? false:true}
                                    block
                                    type="button"
                                    onClick={(e)=>{this.handleClickOfItemSelection(s)}}
                                >
                                    {itemTaggedForDelivery ? 'Selected':'Select'}
                                </Button>
                            </td>
                        </tr>;
            })
        }
        else{
            return  <tr>
                        <td colSpan="3">
                            No items available
                        </td>
                    </tr>
        }
        
    }

    handleClickOfItemSelection = (storageItem)=>{
        const {storage_item_id:storageItemId} = storageItem;
                
        const data = {...this.state.data};
        let listOfItemsSelectedForDelivery = [...data.listOfItemsSelectedForDelivery];
        const itemTaggedForDelivery = (_.find(listOfItemsSelectedForDelivery,{'storage_item_id':storageItemId}) !== undefined) ? true:false;
        if(itemTaggedForDelivery){
            //remove it from the list
            data.listOfItemsSelectedForDelivery = _.reject(listOfItemsSelectedForDelivery,{'storage_item_id':storageItemId});
        }
        else{
            //add it to the list
            listOfItemsSelectedForDelivery.push(storageItem);
            data.listOfItemsSelectedForDelivery = listOfItemsSelectedForDelivery;
        }

        this.setState({data});
    }

    handleSelectAllItems = ()=>{
        const {listOfStorageItems} = this.props;
        const data = {...this.state.data};
        data.listOfItemsSelectedForDelivery = listOfStorageItems;
        this.setState({data});
    }

    handleSubmit = (e)=>{
        try{
             e.preventDefault();  

             const errors = this.validate();
             this.setState({errors: errors || {}});
             if(errors){ scrollTop(this.divAreaToScrollForError); return;}
            
             const {handleSubmit} = this.props;  
             const formData = [...this.state.data.listOfItemsSelectedForDelivery];
             handleSubmit(formData);
             return;
             
         }catch(ex){
             renderError(ex);
         } 
 
    }

    handleUnselectAllItems = ()=>{
        const data = {...this.state.data};
        data.listOfItemsSelectedForDelivery = [];
        this.setState({data});
    }

    mapModelToView = ()=>{
        const {listOfItemsSelectedForDelivery} = this.props;
        const data = {...this.state.data}
        data.listOfItemsSelectedForDelivery = listOfItemsSelectedForDelivery
        //console.log(deliveryAddress);
        this.setState({data});
    }

    parseErrors = (validationErrorList)=>{
        const validationErrors = {};
        if(validationErrorList){
            console.log(validationErrorList.details);
            validationErrorList.details.map(
                function(errorObject){
                    //console.log(errorObject);
                    if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                        const key = errorObject.context.key;
                        const joiType = errorObject.type;
                        //console.log(this.errorMessages);
                        if(_.has(this.errorMessages, [key, joiType]))
                            return validationErrors[errorObject.path[0]] = this.errorMessages[key][joiType];
                        else
                            return validationErrors[errorObject.path[0]] = errorObject.message;
                    }
                    else if(_.has(errorObject, ['context', 'peers']) &&  _.has(errorObject, ['type'])){
                        const joiType = errorObject.type;
                        if(joiType === "object.missing")
                            return validationErrors[errorObject.path[0]] = this.errorMessages.minimumFieldsMessage.message;
                    }
                    else
                        return validationErrors[errorObject.path[0]] = errorObject.message;
                }.bind(this)
            );
        }

        return validationErrors
    }

    validate = ()=>{
        try{
            let data  = {...this.state.data}, 
                errorList = null;

            const options = {abortEarly:false,allowUnknown:true};
                  
            ({error:errorList} = this.validationSchema.validate(data,options));
            //console.log(errorList);
            const primaryErrors = this.parseErrors(errorList);

            
            const errors = {...primaryErrors} 
        
            //console.log(errors);
            return Object.keys(errors).length > 0 ? errors:null;
        }catch(ex){
            console.log(ex);
        }

    }

    render(){
        const {handleBack,listOfStorageItems} = this.props;
        const {data,errors} = this.state;
        const {listOfItemsSelectedForDelivery} =  data;

        const storageItemRow = this.getStorageItemTableRow();
        
        return(
            <div id="deliveryAddressSelectionStep2">
                {/*Error display */}
                {!_.isEmpty(errors) &&
                    <FormErrorWidget
                        errors={errors}
                    />
                }
                {/* End of Error display */}

                <Row>
                    <Col>
                        <h6>Select which items you would like delivered:</h6>
                        <Table responsive striped className="section-gray">
                            <thead>
                                <tr>
                                    <th className="table-header-font-strong">Item Description</th>
                                    <th className="table-header-font-strong">Item Code</th>
                                    <th className="table-header-font-strong">
                                        {
                                            listOfStorageItems.length && 
                                            listOfItemsSelectedForDelivery.length !== listOfStorageItems.length && 
                                            <Button
                                                className="btn-sm btn-round mr-1"
                                                color="danger"
                                                outline = {true}
                                                block
                                                type="button"
                                                onClick={(e)=>{this.handleSelectAllItems()}}
                                            >
                                                Check All
                                            </Button>
                                        }

                                        {
                                            listOfStorageItems.length > 0 && 
                                            listOfItemsSelectedForDelivery.length > 0  &&
                                            listOfItemsSelectedForDelivery.length === listOfStorageItems.length && 
                                            <Button
                                                className="btn-sm btn-round mr-1"
                                                color="danger"
                                                outline = {true}
                                                block
                                                type="button"
                                                onClick={(e)=>{this.handleUnselectAllItems()}}
                                            >
                                                Uncheck All
                                            </Button>
                                        }   
                                        
                                    </th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {storageItemRow}
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                <FormGroup row  className="mt-3 justify-content-between">
                    <Col xs={{ size: 2}}>
                        <Button 
                            className="btn-rounded btn-danger"
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                    </Col>
                    <Col xs={{ size: 6}} sm={{ size: 6}} className="text-right">
                        <Button 
                            className="btn-rounded btn-danger"
                            onClick={this.handleSubmit}
                        >
                            Save & Continue
                        </Button>
                    </Col>
                </FormGroup>
            </div>
        )
    }

}

export default DeliveryItemSelectionStep2;




