import React,{Component} from "react";
import _ from "lodash";
import {saveAs} from "file-saver"
import applicationConstants from "../../../../../utils/applicationConstants"
import customToast from '../../../../Common/CustomToast'
import {renderError} from '../../../../../utils/errorUtils';
import {scrollTop} from '../../../../../utils/helperUtils';

import StudentService from "../../../../../services/studentService";

import StorageDeliveryNotAllowedDueToBalanceDueModal from "../Common/Financials/BalanceDueModal"

import PickupScheduleModal from "./Storage/PickupScheduleModal";
import PickupScheduleModifyModal from "./Storage/PickupScheduleModifyModal";
import PickupScheduleCancelModal from "./Storage/PickupScheduleCancelModal";

import DeliveryScheduleModal from "./Storage/DeliveryScheduleModal";
import DeliveryScheduleModifyModal from "./Storage/DeliveryScheduleModal";
import DeliveryScheduleCancelModal from "./Storage/DeliveryScheduleCancelModal";

import LabelGeneratorModal from "./Storage/LabelGeneratorModal";
import PriceEstimatorModal from "./Storage/PriceEstimatorModal";

import ShipWithUsModal from "./Storage/ShipWithUsModal";
import ShipToUsModal from "./Storage/ShipToUsModal";

import StorageInsuranceModal from "../../../StorageInformation/Modals/StorageInsuranceModal"

import StorageItemsInWarehouse from "./Storage/StorageItemsInWarehouse";

import ProcessingModal from "../../../../Common/ProcessingModal";

import UserNotificationWidget from "../Common/Notifications/UserNotifications";

// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardBody,
    Row,
    Col,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    UncontrolledCollapse,
    Modal,
    NavLink,
  } from "reactstrap";
import { custom } from "@hapi/joi";


class StudentAccountStorageDetail extends Component{
    constructor(){
        super();
        this.state = {
            pickupScheduleModalDisplayFlag:false,
            pickupScheduleModifyModalDisplayFlag:false,
            pickupScheduleCancelModalDisplayFlag:false,
            deliveryNotAllowedModalDisplayFlag:false,
            deliveryScheduleModalDisplayFlag:false,
            deliveryScheduleModifyModalDisplayFlag:false,
            deliveryScheduleCancelModalDisplayFlag:false,
            shipToUsModalDisplayFlag:false,
            shipWithUsModalDisplayFlag:false,
            storageInsuranceModalDisplayFlag:false,
            labelGeneratorModalDisplayFlag:false,
            priceEstimateModalDisplayFlag:false,
            setPickupScheduleFlag:true,
            setDeliveryScheduleFlag:true,
            completePendingActionFlag:false,
            processingModalDisplayFlag:false,

        }
    }

    componentDidMount(){
        //If schedule is false then set schedle flag is true
        const setPickupScheduleFlag = this.isStudentNextPickupScheduleAvailable();
        const setDeliveryScheduleFlag = this.isStudentNextDeliveryScheduleAvailable();
        
        this.setState({
            setPickupScheduleFlag,
            setDeliveryScheduleFlag,
        });
    }
    
    /*
        pickup schedule flag should be displayed:
           - If there is no pickups scheduled
           - or pickup schedule item is there by marked complete
    */

    componentDidUpdate(){
        let setPickupScheduleFlag = true;
        if( this.isStudentNextPickupScheduleAvailable() &&
            !this.isStudentNextPickupScheduleAvailableAndMarkedComplete()
        ){
            setPickupScheduleFlag = false;
        }


        const setDeliveryScheduleFlag = (this.isStudentNextDeliveryScheduleAvailable()) ? false:true;;
        
        const previousState = {...this.state};
        if(
            (previousState.setPickupScheduleFlag != setPickupScheduleFlag) ||
            (previousState.setDeliveryScheduleFlag != setDeliveryScheduleFlag)
        ){
            this.setState({
                setPickupScheduleFlag,
                setDeliveryScheduleFlag,
            });
        }
    }

    completePendingActions = ()=>{
        const myAccountState  = {...this.state};
        const {completePendingActionFlag} = this.state;
        myAccountState.completePendingActionFlag = !completePendingActionFlag;
        this.setState(myAccountState);
    }

    /* This is when an existing user has a storage plan and it checks if there is an upcoming storage plan for renewal */
    displayRenewStoragePlan = ()=>{
        let renewStoragePlanSemester  = false;
        const { user,storage,getRegistrationForUpcomingStorageSeason } = this.props;
        const semesterDetailsForUpcomingStorageSeason = getRegistrationForUpcomingStorageSeason();
        if(!_.isEmpty(user) && !_.isEmpty(storage) && !_.isEmpty(semesterDetailsForUpcomingStorageSeason)){
            const {semester_id: currentSemesterId} = storage.semester;
            renewStoragePlanSemester = (currentSemesterId != semesterDetailsForUpcomingStorageSeason.id) ? semesterDetailsForUpcomingStorageSeason:false;
        }
        return renewStoragePlanSemester;
    }

    getDisplayDeliveryCancelOrModifyButton = (
        displaySetDeliveryScheduleButtonFlag,
        displayDeliveryScheduleDetailAreaFlag)=>
    {
        
        let displayCancelOrModifyButton  = false;   
        
        if(!displaySetDeliveryScheduleButtonFlag && 
            displayDeliveryScheduleDetailAreaFlag
        ){
            displayCancelOrModifyButton = true;
            
            const { storage } = this.props;
            if(!_.isEmpty(storage)){
                const {nextPickupDeliverySchedule} = storage;
                if(!_.isEmpty(nextPickupDeliverySchedule) && nextPickupDeliverySchedule.delivery){
                    const {routeLocked} = nextPickupDeliverySchedule.delivery;
                    displayCancelOrModifyButton = routeLocked ?  false:true;
                }
            }

        }

        return displayCancelOrModifyButton;
    }

    /*
        If schedule has been set with if the route is locked then don't display the buttons
    */
    getDisplayPickupCancelOrModifyButton = (setPickupScheduleFlag)=>{
        let displayPickupCancelOrModifyButton  = false;

        if(!setPickupScheduleFlag){
            displayPickupCancelOrModifyButton = true;

            const { storage } = this.props;
            if(!_.isEmpty(storage)){
                const {nextPickupDeliverySchedule} = storage;
                if(!_.isEmpty(nextPickupDeliverySchedule) && nextPickupDeliverySchedule.pickup){
                    const {routeLocked} = nextPickupDeliverySchedule.pickup;
                    displayPickupCancelOrModifyButton = routeLocked ?  false:true;
                }
            }

        }
        return displayPickupCancelOrModifyButton;
    }

    /*
    Conditions to display the button:
     - This gets displayed if storage items are in the warehouse
     - Delivery date and time options are available
     - If they have not scheduled a delivery (if it's their first time scheduling it)
     - if all previous delivery schedule is marked complete 
    */
    getDisplayDeliveryScheduleButtonFlag = ()=>{
        let  displayDeliveryScheduleButtonFlag = false;
        if(
            this.isStorageItemsInTheWarehouseAndNotDelivered() &&
            this.isStorageDeliveryDateAndTimeslotListAvailable() && 
            (
                !this.isStudentNextDeliveryScheduleAvailable() ||
                (this.isStudentNextDeliveryScheduleAvailable() && this.isStudentNextDeliveryScheduleAvailableAndMarkedComplete())
            )
        )
        displayDeliveryScheduleButtonFlag = true;
        
        return displayDeliveryScheduleButtonFlag;
    }

    /*
      This gets displayed when delivery is still to be marked complete
    */
    getDisplayDeliveryScheduleDetailAreaFlag = ()=>{
        let  displayDeliveryScheduleAreaFlag = false;

        const isStudentNextDeliveryScheduleAvailable = this.isStudentNextDeliveryScheduleAvailable();
        if(isStudentNextDeliveryScheduleAvailable){
            const {storage} = this.props;
            const {delivery:deliveryObject} = storage.nextPickupDeliverySchedule;
            const {markedCompleted} = deliveryObject
            displayDeliveryScheduleAreaFlag = (markedCompleted === false) ? true:false;
        }
        return displayDeliveryScheduleAreaFlag;
    }

    getDisplaySetPickupScheduleLabel = ()=>{
        let displaySetPickupScheduleLabel = "Schedule Pickup";

        const {storage} = this.props;

        if( storage &&
            storage.nextPickupDeliverySchedule &&
            storage.nextPickupDeliverySchedule.pickupsDoneInThePast &&
            storage.nextPickupDeliverySchedule.pickupsDoneInThePast === true
        ){
            displaySetPickupScheduleLabel = "Schedule Additional Pickup";
        }

        return displaySetPickupScheduleLabel;
    }

    getDeliveryAddress = ()=>{
        let address = "";
        const {storage} = this.props;
        if(storage && !_.isEmpty(storage.storageOrder)){

            const {storageOrder,nextPickupDeliverySchedule} = storage;
            const {delivery} = nextPickupDeliverySchedule;

            /*
                If there is address saved at the storage schedule then we return that as that takes precedence
                THis was done as we have FileMaker saving it over there and was not handled
            */
            if(delivery && delivery.storageSchedule){
                const {storageSchedule} = delivery;
                if(storageSchedule){
                    const {pickup_aptnum:deliveryApartmentNumber,pickup_streetname:deliveryStreetName,pickup_streetnum:deliveryStreetNumber} = storageSchedule;
                    if(deliveryStreetName && deliveryStreetNumber){
                        if(deliveryApartmentNumber)
                            address = <span>{`${deliveryStreetNumber}, ${deliveryStreetName} Apt: ${deliveryApartmentNumber}`} </span>;
                        else
                            address = <span>{`${deliveryStreetNumber}, ${deliveryStreetName}`} </span>;
                            
                        return address;
                    }
                }
            }
            
            
            const {
                sched_streetnum:streetNumber,
                sched_streetname:addressLine1,
                sched_aptnum:apartmentNumber,
                sched_city:city,
                sched_zip:postalCode,
            } = storageOrder

            if(apartmentNumber)
                address = <span>{`${streetNumber}, ${addressLine1} Apt: ${apartmentNumber}`} <br/> {`${city} - ${postalCode}`}</span>;
            else
                address = <span>{`${streetNumber}, ${addressLine1}`} <br/> {`${city} - ${postalCode}`}</span>;
                
        }

        return address;
        
    }

    getDeliveryDateAndTime = ()=>{
        let deliveryDate = "";
        const {storage} = this.props;
        if(storage && storage.nextPickupDeliverySchedule && storage.nextPickupDeliverySchedule.delivery){
            const {delivery:deliveryObject} = storage.nextPickupDeliverySchedule;
            const {displayDate,startTime,endTime} = deliveryObject;
            deliveryDate = `${displayDate} ${startTime} - ${endTime}`;
        }
        
        return deliveryDate ;

    }

    getPickupAddress = ()=>{
        let address = "";
        const {user} = this.props;
        
        if(!_.isEmpty(user)){
            
            const {dorm_non_dorm:isDormitory,apartment:apartmentNumber, street:addressLine1,home:streetNumber,school_city:city,school_state:state,school_zip:postalCode} = user;
            const [dormitoryName,roomNumber] = [addressLine1,streetNumber];
            
            if(isDormitory === applicationConstants.NON_DORMITORY){
                if(apartmentNumber)
                    address = <span>{`${streetNumber}, ${addressLine1} Apt: ${apartmentNumber}`} <br/> {`${city}, ${state} - ${postalCode}`}</span>;
                else
                    address = <span>{`${streetNumber}, ${addressLine1}`} <br/> {`${city}, ${state} - ${postalCode}`}</span>;
                
            }else
              address = <span>{`${dormitoryName}, ${roomNumber}`} <br/> {`${city}, ${state} - ${postalCode}`}</span>;
        }
        
        return address;
    }

    getPickupDate = ()=>{
        let pickupDate = "";
        const {storage} = this.props;
        if(storage && storage.nextPickupDeliverySchedule && storage.nextPickupDeliverySchedule.pickup){
            const {pickup:pickupObject} = storage.nextPickupDeliverySchedule;
            const {displayDate,startTime,endTime} = pickupObject;
            pickupDate = `${displayDate} ${startTime} - ${endTime}`;
        }
        
        return pickupDate ;
    }

    handleCloseOfProcessingModalWindow = (flag)=>{
        const myAccountState = {...this.state}
        myAccountState.processingModalDisplayFlag = flag;
        this.setState(myAccountState);
    }

    handleGenerateStorageLabels = async (formData)=>{
        try{
            const {storage,user,handleUpdateOfUserAndProductProperties,handleSettingNextPickupDeliverySchedule} = this.props;
            const {storageOrder} = storage;    
            let storageObject = _.pick(storageOrder,['semester_id','storage_order_id']);
            formData.storage = storageObject;
            formData.user = user;

            let myAccountState  = {...this.state};
            myAccountState.processingModalDisplayFlag = true;
            myAccountState.labelGeneratorModalDisplayFlag = false;
            myAccountState.shipToUsModalDisplayFlag = false;
            this.setState(myAccountState);
        
            const blobData = await StudentService.generateBarcodeLabelsForStorage(formData);
            const pdfBlob = new Blob([blobData], {type : 'application/pdf'})
            
            await handleUpdateOfUserAndProductProperties();
            myAccountState  = {...this.state};
            myAccountState.processingModalDisplayFlag = false;
            this.setState(myAccountState);
            
            saveAs(pdfBlob,"BarcodeLabel.pdf"); 

            customToast.success('Labels have been downloaded successfully'); 
        } 
        catch(ex){
            /*
              400 signals validation errors from the server
              renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop('sectionArea'); return;}
            }
            else
                renderError(ex);
        }      
    }

    handleGenerateStorageLabelsForShipWithUs = async (formData)=>{
        let myAccountState  = {...this.state};
        
        try{
            const {storage,user,handleUpdateOfUserAndProductProperties,handleSettingNextPickupDeliverySchedule} = this.props;
            const {storageOrder} = storage;    
            let storageObject = _.pick(storageOrder,['semester_id','storage_order_id']);
            formData.storage = storageObject;
            formData.user = user;

            
            myAccountState.processingModalDisplayFlag = true;
            myAccountState.shipWithUsModalDisplayFlag = false;
            this.setState(myAccountState);
        
            const blobData = await StudentService.generateBarcodeLabelsForStorage(formData);
            
            const pdfBlob = new Blob([blobData], {type : 'application/pdf'})
            await handleUpdateOfUserAndProductProperties();
            myAccountState  = {...this.state};
            myAccountState.processingModalDisplayFlag = false;
            this.setState(myAccountState);
            saveAs(pdfBlob,"BarcodeLabel.pdf"); 
            customToast.success('Labels have been downloaded successfully'); 
        } 
        catch(ex){
            /*
              400 signals validation errors from the server
              renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                myAccountState  = {...this.state};
                myAccountState.processingModalDisplayFlag = false;
                this.setState(myAccountState);
                
                customToast.error("We encountered an issue generating a Barcode. Please email/phone us or try again.")
                //const errors = ex.response.data;
                //this.setState({errors: errors || {}});
                //if(errors){ scrollTop('sectionArea'); return;}
            }
            else
                renderError(ex);
        }      
    }
 

    handleSetDeliverySchedule = async (formData)=>{
        //const myAccountState  = {...this.state};
        //const {setDeliveryScheduleFlag} = this.state;
        //myAccountState.setDeliveryScheduleFlag = !setDeliveryScheduleFlag;
        //this.setState(myAccountState);

        try{
            //console.log(formData);
            const {storage,user,handleUpdateOfUserAndProductProperties,handleSettingNextPickupDeliverySchedule} = this.props;
            formData.user = user;
            
            const {storageOrder} = storage;    
            let storageObject = _.pick(storageOrder,['semester_id','storage_order_id']);
            formData.storage = storageObject;

            let formDataObject = _.omit(formData,['listOfStorageItems']);
            
            const myAccountState  = {...this.state};
            myAccountState.processingModalDisplayFlag = true;
            myAccountState.deliveryScheduleModalDisplayFlag = false;
            this.setState(myAccountState);
        
            const {status:processingStatus,message,nextPickupDeliverySchedule} = await StudentService.saveStudentStorageDeliverySchedule(formDataObject);
            myAccountState.processingModalDisplayFlag = false;
            this.setState(myAccountState);
            if(!_.isEmpty(nextPickupDeliverySchedule) && processingStatus === true){
                //Need to display the pickup schedule  
                await handleUpdateOfUserAndProductProperties();
                handleSettingNextPickupDeliverySchedule(nextPickupDeliverySchedule);
                customToast.success(message); 
            }
            else if(processingStatus === false){
                myAccountState.deliveryScheduleModalDisplayFlag = true;
                this.setState(myAccountState);
                customToast.error(message); 
            }
               
            
        }
        catch(ex){
            /*
              400 signals validation errors from the server
              renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop('sectionArea'); return;}
            }
            else
                renderError(ex);
        } 
    }

    handleSetDeliveryModifySchedule = async (formData)=>{
        try{
            //console.log(formData);
            const {storage,user,handleUpdateOfUserAndProductProperties,handleSettingNextPickupDeliverySchedule} = this.props;
            formData.user = user;
            formData.storage = storage;
            
            let formDataObject = _.omit(formData,['listOfStorageItems']);
            
            const myAccountState  = {...this.state};
            myAccountState.processingModalDisplayFlag = true;
            myAccountState.deliveryScheduleModifyModalDisplayFlag = false;
            this.setState(myAccountState);
        
            const {status:processingStatus,message,nextPickupDeliverySchedule} = await StudentService.saveStudentStorageDeliveryModifySchedule(formDataObject);
            myAccountState.processingModalDisplayFlag = false;
            this.setState(myAccountState);
            if(!_.isEmpty(nextPickupDeliverySchedule) && processingStatus === true){
                //Need to display the pickup schedule  
                await handleUpdateOfUserAndProductProperties();
                handleSettingNextPickupDeliverySchedule(nextPickupDeliverySchedule);
                customToast.success(message); 
            }
            else if(processingStatus === false){
                myAccountState.deliveryScheduleModifyModalDisplayFlag = true;
                this.setState(myAccountState);
                customToast.error(message); 
            }
               
            
        }
        catch(ex){
            /*
              400 signals validation errors from the server
              renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop('sectionArea'); return;}
            }
            else
                renderError(ex);
        } 
    }

    handleSetDeliveryCancelSchedule = async ()=>{
        try{
            //console.log(formData);
            const {storage,user,handleUpdateOfUserAndProductProperties,handleSettingNextPickupDeliverySchedule} = this.props;
            
            let formDataObject = {};
            
            const {storageOrder,nextPickupDeliverySchedule:nextPickupDeliveryScheduleData} = storage;    
            let storageObject = _.pick(storageOrder,['semester_id','storage_order_id']);
            let userObject = _.pick(user,['user_id','zone_id']);
            
            formDataObject.storage = storageObject;
            formDataObject.delivery = nextPickupDeliveryScheduleData.delivery;
            formDataObject.user = userObject;


            const myAccountState  = {...this.state};
            myAccountState.processingModalDisplayFlag = true;
            myAccountState.deliveryScheduleCancelModalDisplayFlag = false;
            this.setState(myAccountState);
        
            const {status:processingStatus,message,nextPickupDeliverySchedule} = await StudentService.saveStudentStorageDeliveryCancelSchedule(formDataObject);
            myAccountState.processingModalDisplayFlag = false;
            this.setState(myAccountState);
            if(!_.isEmpty(nextPickupDeliverySchedule) && processingStatus === true){
                //Need to display the pickup schedule  
                await handleUpdateOfUserAndProductProperties();
                handleSettingNextPickupDeliverySchedule(nextPickupDeliverySchedule);
                customToast.success(message); 
            }
            else if(processingStatus === false){
                myAccountState.deliveryScheduleCancelModalDisplayFlag = true;
                this.setState(myAccountState);
                customToast.error(message); 
            }
               
            
        }
        catch(ex){
            /*
              400 signals validation errors from the server
              renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop('sectionArea'); return;}
            }
            else
                renderError(ex);
        } 
    }
    
    //save pickup schedule
    handleSetPickupSchedule = async (formData)=>{
        try{
            console.log(formData);
            const {storage,user,handleUpdateOfUserAndProductProperties,handleSettingNextPickupDeliverySchedule} = this.props;
            formData.user = user;
            formData.storage = storage;
            
        
            const myAccountState  = {...this.state};
            
            myAccountState.processingModalDisplayFlag = true;
            myAccountState.pickupScheduleModalDisplayFlag = false;
            this.setState(myAccountState);
        
            const {status:processingStatus,message,nextPickupDeliverySchedule} = await StudentService.saveStudentStoragePickupSchedule(formData);
            myAccountState.processingModalDisplayFlag = false;
            this.setState(myAccountState);
            if(!_.isEmpty(nextPickupDeliverySchedule) && processingStatus === true){
                //Need to display the pickup schedule  
                await handleUpdateOfUserAndProductProperties();
                handleSettingNextPickupDeliverySchedule(nextPickupDeliverySchedule);
                customToast.success(message); 
            }
            else if(processingStatus === false){
                myAccountState.pickupScheduleModalDisplayFlag = true;
                this.setState(myAccountState);
                customToast.error(message); 
            }
               
            
        }
        catch(ex){
            /*
              400 signals validation errors from the server
              renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop('sectionArea'); return;}
            }
            else
                renderError(ex);
        }  
    }

    //modify pickup schedule
    handleSetPickupModifySchedule = async (formData)=>{
        try{
            console.log(formData);
            const {storage,user,handleUpdateOfUserAndProductProperties,handleSettingNextPickupDeliverySchedule} = this.props;
            formData.user = user;
            formData.storage = storage;
            
        
            const myAccountState  = {...this.state};
            
            myAccountState.processingModalDisplayFlag = true;
            myAccountState.pickupScheduleModifyModalDisplayFlag = false;
            this.setState(myAccountState);
        
            const {status:processingStatus,message,nextPickupDeliverySchedule} = await StudentService.saveStudentStoragePickupModifySchedule(formData);
            myAccountState.processingModalDisplayFlag = false;
            this.setState(myAccountState);
            if(!_.isEmpty(nextPickupDeliverySchedule) && processingStatus === true){
                //Need to display the pickup schedule  
                await handleUpdateOfUserAndProductProperties();
                handleSettingNextPickupDeliverySchedule(nextPickupDeliverySchedule);
                customToast.success(message); 
            }
            else if(processingStatus === false){
                myAccountState.pickupScheduleModifyModalDisplayFlag = true;
                this.setState(myAccountState);
                customToast.error(message); 
            }
               
            
        }
        catch(ex){
            /*
              400 signals validation errors from the server
              renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop('sectionArea'); return;}
            }
            else
                renderError(ex);
        }  
    }

    handleSetPickupCancelSchedule = async ()=>{
        try{
            //console.log(formData);
            const {storage,user,handleUpdateOfUserAndProductProperties,handleSettingNextPickupDeliverySchedule} = this.props;
            let formDataObject = {};
            
            const {storageOrder,nextPickupDeliverySchedule:nextPickupDeliveryScheduleData} = storage;    
            let storageObject = _.pick(storageOrder,['semester_id','storage_order_id']);
            let userObject = _.pick(user,['user_id','zone_id']);
            
            formDataObject.storage = storageObject;
            formDataObject.pickup = nextPickupDeliveryScheduleData.pickup;
            formDataObject.user = userObject;

            
            const myAccountState  = {...this.state};
            myAccountState.processingModalDisplayFlag = true;
            myAccountState.pickupScheduleCancelModalDisplayFlag = false;
            this.setState(myAccountState);
        
            const {status:processingStatus,message,nextPickupDeliverySchedule} = await StudentService.saveStudentStoragePickupCancelSchedule(formDataObject);
            myAccountState.processingModalDisplayFlag = false;
            this.setState(myAccountState);
            if(!_.isEmpty(nextPickupDeliverySchedule) && processingStatus === true){
                //Need to display the pickup schedule  
                await handleUpdateOfUserAndProductProperties();
                handleSettingNextPickupDeliverySchedule(nextPickupDeliverySchedule);
                customToast.success(message); 
            }
            else if(processingStatus === false){
                myAccountState.pickupScheduleCancelModalDisplayFlag = true;
                this.setState(myAccountState);
                customToast.error(message); 
            }
               
            
        }
        catch(ex){
            /*
              400 signals validation errors from the server
              renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop('sectionArea'); return;}
            }
            else
                renderError(ex);
        } 
    }

    isStudentNextDeliveryScheduleAvailable = ()=>{
        let isStudentNextDeliveryScheduleAvailable  = false;
        const {storage} = this.props
        if(_.isObject(storage)){
             const {nextPickupDeliverySchedule} = storage;
             isStudentNextDeliveryScheduleAvailable =  (_.isEmpty(nextPickupDeliverySchedule) || (!_.isEmpty(nextPickupDeliverySchedule) && _.isEmpty(nextPickupDeliverySchedule.delivery))) ? false:true;
        }
        return isStudentNextDeliveryScheduleAvailable;
    }

    isStudentNextDeliveryScheduleAvailableAndMarkedComplete = ()=>{
        let isStudentNextDeliveryScheduleAvailableAndMarkedComplete  = false;
        const isStudentNextDeliveryScheduleAvailable = this.isStudentNextDeliveryScheduleAvailable()

        if(isStudentNextDeliveryScheduleAvailable){
            const {storage} = this.props;
            const {delivery:deliveryObject} = storage.nextPickupDeliverySchedule;
            const {markedCompleted} = deliveryObject
            isStudentNextDeliveryScheduleAvailableAndMarkedComplete = markedCompleted;
        }

        return isStudentNextDeliveryScheduleAvailableAndMarkedComplete;
    }

    isStorageDeliveryDateAndTimeslotListAvailable = ()=>{
        let isStorageDeliveryDateAndTimeslotListAvailable = false;
        const {storage} = this.props
        if(_.isObject(storage)){
            const {storagePickupDeliveryDateList} = storage;
            isStorageDeliveryDateAndTimeslotListAvailable = !_.isEmpty(storagePickupDeliveryDateList) && !_.isEmpty(storagePickupDeliveryDateList.deliveryDates) && true;
        }

        return isStorageDeliveryDateAndTimeslotListAvailable;
    }

    /*
      true: if pickup object is availabke
      false:if pickup object is not available 
    */
    isStudentNextPickupScheduleAvailable = ()=>{
        let isStudentNextPickupScheduleAvailable  = false;
        const {storage} = this.props
        if(_.isObject(storage)){
             const {nextPickupDeliverySchedule} = storage;
             isStudentNextPickupScheduleAvailable =  (_.isEmpty(nextPickupDeliverySchedule) || (!_.isEmpty(nextPickupDeliverySchedule) && _.isEmpty(nextPickupDeliverySchedule.pickup))) ? false:true;
        }
        return isStudentNextPickupScheduleAvailable;
    }

    isStudentNextPickupScheduleAvailableAndMarkedComplete = ()=>{
        let isStudentNextPickupScheduleAvailableAndMarkedComplete = false;

        const isStudentNextPickupScheduleAvailable = this.isStudentNextPickupScheduleAvailable()

        if(isStudentNextPickupScheduleAvailable){
            const {storage} = this.props;
            const {pickup:pickupObject} = storage.nextPickupDeliverySchedule;
            const {markedCompleted} = pickupObject
            isStudentNextPickupScheduleAvailableAndMarkedComplete = markedCompleted;
        }

        return isStudentNextPickupScheduleAvailableAndMarkedComplete;

    }

    isStorageItemsInTheWarehouseAndNotDelivered = ()=>{
        let storageItemsInTheWarehouseAndNotDelivered = false;
        const {storage} = this.props
        if(_.isObject(storage)){
            const {nextPickupDeliverySchedule} = storage;
            storageItemsInTheWarehouseAndNotDelivered =  (
                !_.isEmpty(nextPickupDeliverySchedule) && 
                nextPickupDeliverySchedule.storageItemsInTheWarehouseAndNotDelivered === true
            ) ? true:false;

        }

        return storageItemsInTheWarehouseAndNotDelivered;
    }

    isValidOptionalViewPresent = (optionalView)=>{
        if(optionalView === "storage-items") return true;

        return false;
    }

    
    setDeliveryNotAllowedModalDisplayFlag = (flag)=>{
        this.setState({
            deliveryNotAllowedModalDisplayFlag:flag    
        })
    }

    setDeliveryScheduleModalDisplayFlag = (flag)=>{
        const {accountCurrentBalance} = this.props
        if(flag === true){
            //Display modal but only if there is no balance due
            if(accountCurrentBalance > 0){
                return this.setDeliveryNotAllowedModalDisplayFlag(true)
            }
        }
        
        this.setState({
            deliveryScheduleModalDisplayFlag:flag    
        })
    
    }

    setDeliveryScheduleModifyModalDisplayFlag = (flag)=>{
        this.setState({
            deliveryScheduleModifyModalDisplayFlag:flag    
        })
    }
    

    setDeliveryScheduleCancelModalDisplayFlag = (flag)=>{
        this.setState({
            deliveryScheduleCancelModalDisplayFlag:flag    
        })
    }

    setLabelGeneratorModalDisplayFlag = (flag)=>{
        this.setState({
            labelGeneratorModalDisplayFlag:flag
        })
    }

    setPickupScheduleModalDisplayFlag = (flag)=>{
        this.setState({
            pickupScheduleModalDisplayFlag:flag    
        })
    }

    setPickupScheduleCancelModalDisplayFlag = (flag)=>{
        this.setState({
            pickupScheduleCancelModalDisplayFlag:flag    
        })
    }

    setPickupScheduleModifyModalDisplayFlag = (flag)=>{
        this.setState({
            pickupScheduleModifyModalDisplayFlag:flag    
        })
    }

    setPriceEstimateModalDisplayFlag = (flag)=>{
        this.setState({
            priceEstimateModalDisplayFlag:flag    
        })
    }

    setShipToUsModalDisplayFlag = (flag)=>{
        this.setState({
            shipToUsModalDisplayFlag:flag
        })
    }

    setShipWithUsModalDisplayFlag = (flag)=>{
        this.setState({
            shipWithUsModalDisplayFlag:flag
        })
    }

    setStorageInsuranceModalDisplayFlag = (flag)=>{
        this.setState({
            storageInsuranceModalDisplayFlag:flag
        })
    }

    getUniversityRecord = ()=>{
        let universityRecord = null;
        const {
            storage,
            listOfSchoolsByRegionForStorage,
            user,
        } = this.props;

        
        if(listOfSchoolsByRegionForStorage.length > 0 && user){
            const {university_id:universityId} = user;
            
            
            const listOfSchools = _.map(listOfSchoolsByRegionForStorage, 'schools');
            //const listOfUniv = _.map(listOfSchools, 'university');
            //console.log(listOfSchools);
            const listOfUniv = _.flatten(listOfSchools)
            //console.log(listOfUniv);
            
            if(_.find(listOfUniv,{'universityId':universityId}) !== undefined)
                universityRecord = _.find(listOfUniv,{'universityId':universityId}).university;
            //console.log(school);
        }

        return universityRecord;
    }

    render(){
        const {
            accountCurrentBalance,
            displayFlag,
            displayRenewLaundryPlan:displayRenewLaundryPlanInformation,
            displayRenewStoragePlan:displayRenewStoragePlanInformation,
            displayWelcomeModal,
            getRegistrationForUpcomingLaundrySeason,
            handleOnClickOfSignupLaundryButton,
            handleOnClickOfReserveStorageButton,
            handleOnClickOfAccountActivityButton,
            handleOnCloseOfWelcomeModal,
            history,
            storage,
            user,
            match,
            listOfSchoolsByRegionForStorage,
            userNotifications,
        } = this.props;

        const universityRecord = this.getUniversityRecord();


        const {optionalView} = match.params;
        const isValidOptionalViewPresent = this.isValidOptionalViewPresent(optionalView)
        const displayStorageDetailLandingArea = (isValidOptionalViewPresent === false) ? true:false;
        
        const {
            completePendingActionFlag,
            pickupScheduleModalDisplayFlag,
            pickupScheduleModifyModalDisplayFlag,
            pickupScheduleCancelModalDisplayFlag,
            deliveryNotAllowedModalDisplayFlag,
            deliveryScheduleModalDisplayFlag,
            deliveryScheduleModifyModalDisplayFlag,
            deliveryScheduleCancelModalDisplayFlag,
            labelGeneratorModalDisplayFlag,
            priceEstimateModalDisplayFlag,
            shipToUsModalDisplayFlag,
            shipWithUsModalDisplayFlag,
            storageInsuranceModalDisplayFlag,
            setPickupScheduleFlag,
            setDeliveryScheduleFlag,
            processingModalDisplayFlag,
        }  = this.state;  

        const displayCSS = (displayFlag) ? "":"d-none";
        const pickupAddress = this.getPickupAddress();
        const pickupDate = this.getPickupDate();
        const deliveryDateAndTime = this.getDeliveryDateAndTime();
        const deliveryAddress = this.getDeliveryAddress();
        const displayNonEssentialItems = false;

        const displaySetDeliveryScheduleButtonFlag = this.getDisplayDeliveryScheduleButtonFlag();
        const displayDeliveryScheduleDetailAreaFlag = this.getDisplayDeliveryScheduleDetailAreaFlag(); 
        const displaySetPickupScheduleLabel = this.getDisplaySetPickupScheduleLabel();
        const displayStorageItemsInTheWarehouseAndNotDeliveredLinkFlag =  this.isStorageItemsInTheWarehouseAndNotDelivered();
        
        const displayCancelPickupOrModifyStoragePickupFlag = this.getDisplayPickupCancelOrModifyButton(setPickupScheduleFlag);
        const displayCancelDeliveryOrModifyStorageDeliveryFlag = this.getDisplayDeliveryCancelOrModifyButton(displaySetDeliveryScheduleButtonFlag,displayDeliveryScheduleDetailAreaFlag);

        const isStorageDetailAvailable  = (!_.isEmpty(storage)) ?  true:false;

        //This is when an existing user has a laundry plan and it checks if there is an upcoming laundry plan for renewal
        let displayRenewStoragePlanSemester  = displayRenewStoragePlanInformation;
        let displayRenewStoragePlanButton  = (!_.isEmpty(displayRenewStoragePlanSemester)) ? true:false;
        

        const upcomingLaundrySeason = displayRenewLaundryPlanInformation;
        const isUpcomingLaundrySeasonAvailable = (!_.isEmpty(upcomingLaundrySeason)) ? true:false;
        const isBalanceDue = (accountCurrentBalance > 0) ? true:false

        return(
            <>
            {   
                displayStorageDetailLandingArea && 
                <Row className={displayCSS}>
                    <Col>
                        <Card>
                            <CardBody>
                                    <div>
                                        <div className="d-flex bd-highlight mb-3">
                                            <div className="mr-auto p-2 bd-highlight mt-2">
                                                
                                                        <h6 className="card-category">
                                                            <span className="text-left">
                                                            Storage Service: Details
                                                            </span>
                                                        </h6>
                                                    
                                                    
                                                
                                            </div>
                                            {   
                                                userNotifications &&  
                                                <div className="bd-highlight d-none d-lg-block">
                                                    <UserNotificationWidget
                                                        {...this.props}
                                                    />
                                                </div>
                                            }

                                            <div className="p-2 bd-highlight">
                                                {   displayRenewStoragePlanButton && 
                                                    <Button
                                                        className="btn-round mr-1"
                                                        color="danger"
                                                        type="button"
                                                        size="sm"
                                                        onClick={handleOnClickOfReserveStorageButton}
                                                    >
                                                        <i className="nc-icon nc-bell-55 mr-1" />
                                                        {`Reserve for ${displayRenewStoragePlanSemester.semester}`}
                                                    </Button>
                                                }
                                                
                                            </div>
                                    </div>
                                    <hr/>

                                    {
                                    completePendingActionFlag &&
                                    <Row>
                                        <Col xs="12">
                                            <div className="mlb-alert mlb-alert-primary" role="alert">
                                                <Row>
                                                    <Col xs="1" sm="1" className="fs-42 text-danger">
                                                            <i className="fa fa-exclamation-triangle fa-6" aria-hidden="true"></i>    
                                                    </Col>
                                                    <Col xs="7" sm="6">
                                                    <ul>
                                                        Following action needs to be taken:  
                                                        <li>
                                                            Your Student ID and Campus Address
                                                        </li> 
                                                        <li>Profile Information (Home address, Billing Address)</li> 
                                                        <li>Schedule Pickup and Delivery</li>  
                                                    </ul> 
                                                    </Col>
                                                    <Col xs="4" sm="5">
                                                            <Button
                                                            color="danger"
                                                            outline
                                                            size="sm"
                                                            type="button"
                                                            className="mr-1"
                                                            onClick={this.completePendingActions}
                                                        >
                                                                Setup my plan
                                                        </Button>
                                                    </Col> 
                                                    
                                                </Row>
                                                
                                            </div>
                                        </Col>
                                    </Row>
                                    }

                                    {
                                        isStorageDetailAvailable && 
                                        <>
                                        <Row>
                                            <Col md="4" className="br-solid">
                                                <Row>
                                                    <Col xs="9" sm="9" md="12">
                                                        <Row>
                                                            <Col xs="6" sm="6" md="4">
                                                                <strong>Semester</strong>
                                                            </Col>
                                                            <Col xs="6" sm="6" md="8">
                                                                {storage && storage.storagePackageName}
                                                            </Col>
                                                        </Row>

                                                        <Row  className="mt-2">
                                                            <Col xs="6" sm="6" md="4">
                                                                <strong>Plan Date</strong>
                                                            </Col>
                                                            <Col xs="6" sm="6" md="8">
                                                                {storage && storage.storagePackageDateDisplay}
                                                            </Col>
                                                        </Row>

                                                        <Row className="mt-2">
                                                            <Col className="text-left">
                                                                <a 
                                                                    href="#"
                                                                    className="btn-link btn btn-info btn-sm pl-0"
                                                                    onClick={(e)=>{ e.preventDefault(); return this.setStorageInsuranceModalDisplayFlag(true);}}
                                                                >
                                                                    <i className="fa fa-book" aria-hidden="true"></i>
                                                                    Insurance Information
                                                                </a>
                                                               
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col className="text-left">
                                                                <a 
                                                                    href={`${process.env.REACT_APP_API_URL}/storage/welcomePacket`}
                                                                    className="btn-link btn btn-info btn-sm pl-0"
                                                                >
                                                                    <i className="fa fa-book" aria-hidden="true"></i>
                                                                    STORAGE WELCOME PACKET 
                                                                </a>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col className="text-left">
                                                                <Button
                                                                    className="btn-link"
                                                                    color="info"
                                                                    size="sm"
                                                                    type="button"
                                                                    style={{paddingLeft:"0px"}}
                                                                    onClick={handleOnClickOfAccountActivityButton}
                                                                >
                                                                    <i className="fa fa-table" aria-hidden="true"></i>
                                                                    Billing Details
                                                                </Button>
                                                            </Col>
                                                        </Row>

                                                        {   
                                                            <Row>
                                                                <Col className="text-left">
                                                                    <Button
                                                                        className="btn-link"
                                                                        color="info"
                                                                        size="sm"
                                                                        type="button"
                                                                        style={{paddingLeft:"0px"}}
                                                                        onClick={e=>{
                                                                            e.preventDefault();
                                                                            return this.props.history.push("/myaccount/student/storage/storage-items");
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-archive" aria-hidden="true"></i>
                                                                        Your Storage Items 
                                                                    </Button>
                                                                </Col>
                                                            </Row>  
                                                        }
                                                        

                                                        
                                                    </Col>
                                                    <Col xs="3" sm="3" className="align-middle d-block d-md-none">
                                                        <img  
                                                            className="img-rounded img-responsive"
                                                            src={require("../../../../../assets/mylazybones/img/MyAccount/Storage/storage_package.jpg")}
                                                            
                                                        />
                                                    </Col>
                                                </Row>
                                                    
                                                
                                            </Col>
                                            
                                            
                                            <Col md="5" className="br-solid">
                                                <div className="d-block d-md-none">
                                                    <hr/>
                                                </div>
                                                <div>
                                                    <h6>
                                                        Your Pickup schedule
                                                    </h6>
                                                </div>
                                                <Row className="mt-2">
                                                    <Col xs="4" sm="6" md="4">
                                                            <strong>
                                                            Address
                                                            </strong>
                                                    </Col>
                                                    <Col xs="8" sm="6" md="8">
                                                        {pickupAddress}
                                                    </Col>
                                                            
                                                </Row>
                                                {
                                                setPickupScheduleFlag && 
                                                <Row className="mt-2">
                                                        <Col xs="4" sm="6" md="4">
                                                            
                                                        </Col>   
                                                        <Col xs="8" sm="6" md="8">
                                                            <div className="mt-2">
                                                                <Button
                                                                        color="info"
                                                                        outline
                                                                        size="sm"
                                                                        type="button"
                                                                        className="mr-1"
                                                                        onClick={()=>this.setPickupScheduleModalDisplayFlag(true)}
                                                                >
                                                                    <i className="fa fa-calendar" aria-hidden="true"></i>
                                                                    {displaySetPickupScheduleLabel}
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                </Row>
                                                }
                                                {
                                                !setPickupScheduleFlag &&    
                                                <div className="mt-2">
                                                    <Row className="mt-2">
                                                        <Col xs="4" sm="6" md="4">
                                                            <strong>Date</strong>
                                                        </Col>
                                                        <Col xs="8" sm="6" md="8">
                                                            {pickupDate}
                                                        </Col>
                                                    </Row>
                                                    
                                                    

                                                    <Row className="mt-2">
                                                        <Col xs="4" sm="6" md="4">
                                                            
                                                        </Col>
                                                        <Col xs="8" sm="6" md="8">
                                                            
                                                            {
                                                                displayCancelPickupOrModifyStoragePickupFlag && 
                                                                <>
                                                                    <div className="mt-2">
                                                                            <Button
                                                                                color="info"
                                                                                outline
                                                                                block
                                                                                size="sm"
                                                                                type="button"
                                                                                className="mr-1"
                                                                                onClick={()=>this.setPickupScheduleModifyModalDisplayFlag(true)}
                                                                            >
                                                                                <i className="fa fa-calendar" aria-hidden="true"></i>
                                                                                Modify Pickup
                                                                            </Button>
                                                                    </div>
                                                                    <div className="mt-2">
                                                                            <Button
                                                                                color="info"
                                                                                outline
                                                                                block
                                                                                size="sm"
                                                                                type="button"
                                                                                className="mr-1"
                                                                                onClick={()=>this.setPickupScheduleCancelModalDisplayFlag(true)}
                                                                            >
                                                                                <i className="fa fa-calendar" aria-hidden="true"></i>
                                                                                Cancel Pickup
                                                                            </Button>
                                                                    </div>
                                                                </>
                                                            }   
                                                            <div className="mt-2">
                                                                <Button
                                                                    color="info"
                                                                    outline
                                                                    block
                                                                    size="sm"
                                                                    type="button"
                                                                    className="mr-1"
                                                                    onClick={()=>this.setLabelGeneratorModalDisplayFlag(true)}
                                                                >
                                                                    <i className="fa fa-barcode" aria-hidden="true"></i>
                                                                    Print barcodes labels
                                                                </Button>
                                                                
                                                            </div>

                                                            
                                                        </Col>
                                                            
                                                    </Row>
                                                </div>
                                                } 

                                                {
                                                <div className="mt-3">
                                                    <h6>
                                                        Your Delivery schedule
                                                    </h6>
                                                </div>
                                                }

                                                {
                                                displaySetDeliveryScheduleButtonFlag &&    
                                                <div className="my-2">
                                                    <Button
                                                            color="info"
                                                            outline
                                                            size="sm"
                                                            type="button"
                                                            className="mr-1"
                                                            onClick={()=>this.setDeliveryScheduleModalDisplayFlag(true)}
                                                    >
                                                        <i className="fa fa-calendar" aria-hidden="true"></i>
                                                        Set Schedule
                                                    </Button>
                                                </div>
                                                }
                                                {
                                                !displaySetDeliveryScheduleButtonFlag && 
                                                !displayDeliveryScheduleDetailAreaFlag &&     
                                                <div className="mt-2">
                                                    <Row className="mt-2">
                                                        <Col xs="8" sm="6" md="10">
                                                            Delivery options will be made available either once items are picked up or delivery schedule is available
                                                        </Col>
                                                    </Row>
                                                    
                                                </div>
                                                }

                                                {
                                                !displaySetDeliveryScheduleButtonFlag && 
                                                displayDeliveryScheduleDetailAreaFlag &&     
                                                <div className="mt-2">
                                                    <Row className="mt-2">
                                                        <Col xs="4" sm="6" md="4">
                                                            <strong>Delivery Date</strong>
                                                        </Col>
                                                        <Col xs="8" sm="6" md="8">
                                                            {deliveryDateAndTime}
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-2">
                                                        <Col xs="4" sm="6" md="4">
                                                            <strong>
                                                                Address 
                                                            </strong>
                                                        </Col>
                                                        <Col xs="8" sm="6" md="8">
                                                            {deliveryAddress}
                                                        </Col>
                                                            
                                                    </Row>
                                                    
                                                    {
                                                    displayCancelDeliveryOrModifyStorageDeliveryFlag && 
                                                    <Row>
                                                        <Col xs="4" sm="6" md="4">
                                                            
                                                        </Col>
                                                        <Col xs="8" sm="6" md="8">
                                                            <div className="mt-2">
                                                                    <Button
                                                                        color="info"
                                                                        outline
                                                                        block
                                                                        size="sm"
                                                                        type="button"
                                                                        className="mr-1"
                                                                        onClick={()=>this.setDeliveryScheduleModifyModalDisplayFlag(true)}
                                                                    >
                                                                        <i className="fa fa-calendar" aria-hidden="true"></i>
                                                                        Modify Delivery
                                                                    </Button>
                                                            </div>
                                                            
                                                            <div className="mt-2">
                                                                    <Button
                                                                        color="info"
                                                                        outline
                                                                        block
                                                                        size="sm"
                                                                        type="button"
                                                                        className="mr-1"
                                                                        onClick={()=>this.setDeliveryScheduleCancelModalDisplayFlag(true)}
                                                                    >
                                                                        <i className="fa fa-calendar" aria-hidden="true"></i>
                                                                        Cancel Delivery
                                                                    </Button>
                                                            </div>

                                                        </Col>    
                                                        
                                                    </Row>
                                                    }
                                                    
                                                    
                                                </div>
                                                }

                                                                
                                            
                                            </Col>

                                            <Col md="2" className="align-middle d-none d-md-block">
                                                <img  
                                                    className="img-rounded img-responsive"
                                                    src={require("../../../../../assets/mylazybones/img/MyAccount/Storage/storage_package.jpg")}
                                                    
                                                />
                                                    
                                            
                            
                                            </Col>
                                        </Row>

                                        <div className="d-none d-md-block">
                                            <hr/>
                                        </div>

                                        <Row>
                                            <Col md="4" className="br-solid">
                                                <div>
                                                    <h6>
                                                        Storage Services
                                                    </h6>
                                                </div>
                                                <Row className="mt-2">
                                                    <Col className="text-left">
                                                        <Button
                                                            className="btn-link"
                                                            color="info"
                                                            size="sm"
                                                            type="button"
                                                            style={{paddingLeft:"0px"}}
                                                            onClick={()=>this.setPriceEstimateModalDisplayFlag(true)}
                                                        >
                                                            <i className="fa fa-calendar" aria-hidden="true"></i>
                                                            Price Estimator
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="text-left">
                                                        <Button
                                                            className="btn-link"
                                                            color="info"
                                                            size="sm"
                                                            type="button"
                                                            style={{paddingLeft:"0px"}}
                                                            onClick={()=>this.setLabelGeneratorModalDisplayFlag(true)}
                                                        >
                                                            <i className="fa fa-barcode" aria-hidden="true"></i>
                                                            Storage barcodes labels
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            
                                            
                                            <Col md="5" className="br-solid">
                                                <div className="d-block d-md-none">
                                                    <hr/>
                                                </div>
                                                <div>
                                                    <h6>
                                                    Shipping Services  
                                                    </h6>
                                                
                                                </div>
                                                <div className="mt-2">
                                                    <Row>
                                                        <Col className="text-left">
                                                            <Button
                                                                className="btn-link"
                                                                color="info"
                                                                size="sm"
                                                                type="button"
                                                                style={{paddingLeft:"0px"}}
                                                                onClick={()=>this.setShipWithUsModalDisplayFlag(true)}
                                                                
                                                            >
                                                                <i className="fa fa-truck" aria-hidden="true"></i>
                                                                Ship with Lazybones
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="text-left">
                                                            <Button
                                                                className="btn-link"
                                                                color="info"
                                                                size="sm"
                                                                type="button"
                                                                style={{paddingLeft:"0px"}}
                                                                onClick={()=>this.setShipToUsModalDisplayFlag(true)}
                                                            >
                                                                <i className="fa fa-truck" aria-hidden="true"></i>
                                                                Ship to Lazybones
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </div>                    
                                            </Col>

                                            <Col md="3" className="">
                                                <div className="d-block d-md-none">
                                                    <hr/>
                                                </div>
                                                <div>
                                                    <h6>
                                                        Other Services
                                                    </h6>
                                                </div>
                                                <div className="mt-2 ">
                                                    <Row>
                                                        <Col xs="2" md="3">
                                                            <img
                                                                alt="..."
                                                                className="img-responsive"
                                                                src={require("assets/mylazybones/img/MyAccount/Supplies/supplies_landing.jpg")}
                                                                style={{width:"50px",height:"50px"}}
                                                            />
                                                        </Col>
                                                        <Col xs="8">
                                                            <Button
                                                                className="btn-link mr-1"
                                                                color="info"
                                                                onClick={e => {e.preventDefault();
                                                                    return this.props.history.push("/supplies");
                                                                }}
                                                                style={{textTransform:"none",paddingLeft:"0px",textAlign:"left"}}
                                                            >
                                                                Order packing supplies
                                                            </Button> 
                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col xs="2" md="3">
                                                            <img
                                                                alt="..."
                                                                className="img-responsive"
                                                                src={require("assets/mylazybones/img/MyAccount/Laundry/Laundry_Myaccount_Landing.jpg")}
                                                                style={{width:"50px",height:"50px"}}
                                                            />
                                                        </Col>
                                                        <Col xs="8">
                                                            <Button
                                                                className="btn-link mr-1"
                                                                color="info"
                                                                onClick={e => this.props.history.push('/myaccount/student/laundry')}
                                                                style={{textTransform:"none",paddingLeft:"0px",textAlign:"left"}}
                                                            >
                                                                View Laundry Service
                                                            </Button> 
                                                        </Col>
                                                    </Row>
                                                    
                                                    
                                                </div> 
                                                    
                                            
                            
                                            </Col>
                                        </Row>
                                        </>
                                    }

                                    {
                                        !isStorageDetailAvailable &&
                                        <div className="min-height-sm">
                                            <Row>
                                                <Col xs="9">
                                                    <div className="mlb-alert mlb-alert-primary" role="alert">
                                                        <Row>
                                                            <Col xs="1" sm="1" className="fs-42 text-danger d-none d-sm-block">
                                                                    <i className="fa fa-exclamation-triangle fa-6" aria-hidden="true"></i>    
                                                            </Col>
                                                            <Col>
                                                            <strong>You currently don't have an storage plan with us or it has expired. If this is an error then please contact Lazybones and we will address the issue.</strong>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                                <Col xs="2" >
                                                    <img  
                                                        className="img-rounded img-responsive"
                                                        src={require("../../../../../assets/mylazybones/img/MyAccount/Storage/storage_package.jpg")}
                                                        
                                                    />
                                                </Col>
                                            </Row>
                                            <div>
                                                <hr/>
                                            </div>
                                            <Row>
                                                <Col xs="12" md="6">
                                                    <div>
                                                        <h6>
                                                            Other Services
                                                        </h6>
                                                    </div>
                                                    <div className="mt-2 ">
                                                        <Row>
                                                            <Col xs="2" md="3">
                                                                <img
                                                                    alt="..."
                                                                    className="img-responsive"
                                                                    src={require("assets/mylazybones/img/MyAccount/Supplies/supplies_landing.jpg")}
                                                                    style={{width:"50px",height:"50px"}}
                                                                />
                                                            </Col>
                                                            <Col xs="8">
                                                                <Button
                                                                    className="btn-link mr-1"
                                                                    color="info"
                                                                    onClick={e => {e.preventDefault();
                                                                        return this.props.history.push("/supplies");
                                                                    }}
                                                                    style={{textTransform:"none",paddingLeft:"0px",textAlign:"left"}}
                                                                >
                                                                    Order packing supplies
                                                                </Button> 
                                                            </Col>

                                                        </Row>
                                                        {
                                                            <Row className="mt-2">
                                                                <Col xs="2" md="3">
                                                                    <img
                                                                        alt="..."
                                                                        className="img-responsive"
                                                                        src={require("assets/mylazybones/img/MyAccount/Laundry/Laundry_Myaccount_Landing.jpg")}
                                                                        style={{width:"50px",height:"50px"}}
                                                                    />
                                                                </Col>
                                                                <Col xs="8">
                                                                    <Button
                                                                        className="btn-link mr-1"
                                                                        color="info"
                                                                        href="#pablo"
                                                                        onClick={(e)=>this.props.history.push("/myaccount/student/laundry")}
                                                                        style={{textTransform:"none",paddingLeft:"0px",textAlign:"left"}}
                                                                    >
                                                                        View Laundry Service
                                                                    </Button> 
                                                                </Col>
                                                            </Row>
                                                        }
                                                        
                                                        
                                                        
                                                    </div> 
                                                        
                                                
                                
                                                </Col>
                                            </Row>
                                            
                                        </div>
                                        
                                        
                                    }
                                </div> 

                            
                                
                            </CardBody>
                        </Card>
                    </Col> 
                </Row>
            }

            {
                !displayStorageDetailLandingArea && optionalView === "storage-items" && 
                <Row className={displayCSS}>
                    <Col>
                        <Card>
                            <CardBody>
                                    <div>
                                        <div className="d-flex justify-content-between bd-highlight mb-3">
                                            <div className="p-2 bd-highlight mt-2">
                                                <h6 className="card-category">
                                                    <span className="text-left">
                                                    Storage: Your Items currently in our Warehouse (<a 
                                                        href="#"
                                                        className="btn-link btn btn-info btn-sm pl-0"
                                                        onClick={(e)=>{ e.preventDefault(); return this.setStorageInsuranceModalDisplayFlag(true);}}
                                                    >
                                                        View Insurance Information
                                                    </a>)
                                                    </span>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <StorageItemsInWarehouse 
                                            {...this.props}
                                    />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }


            <Modal isOpen={displayWelcomeModal} toggle={() => handleOnCloseOfWelcomeModal(false)}>
                  <div className="modal-header no-border-header">
                    <button
                      className="close"
                      type="button"
                      onClick={() => handleOnCloseOfWelcomeModal(false)}
                    >
                      ×
                    </button>
                    <h4 className="modal-title animated bounce text-success modal-myaccount-header-animation" id="myModalLabel">
                      <i className="fa fa-thumbs-o-up" aria-hidden="true"></i> Congratulations!
                    </h4>
                    
                  </div>
                  <div className="modal-body">
                    
                    <p>
                    You have successfully created your Lazybones Storage Account. <br/> Next, please click <Button
                        className="mr-1"
                        color="link"
                        onClick={(e) =>{
                            handleOnCloseOfWelcomeModal(false);
                            return this.setPickupScheduleModalDisplayFlag(true)
                        }}
                      >
                        'Schedule Pickup'
                      </Button> to schedule your storage pickup day and time. If you do not wish to schedule your pickup at this time, please be sure to log back in and schedule it whenever you are ready. 
                      
                    </p>  
                   
                    
                    
                  </div>
                  <div className="modal-footer">
                    <Button
                      className="btn-link"
                      color="primary"
                      type="button"
                      onClick={() => handleOnCloseOfWelcomeModal(false)}
                    >
                      Close
                    </Button>
                  </div>
            </Modal>

                            
                

            {/* Set Processing modal */}
            <ProcessingModal
                displayModal={processingModalDisplayFlag}
                handleCloseOfModalWindow={this.handleCloseOfProcessingModalWindow}
            />
            {/* End of Processing modal */}

            {/* Set Storage Insurance modal */}
            <StorageInsuranceModal
                modalDisplayFlag={storageInsuranceModalDisplayFlag}
                handleModalDisplay={this.setStorageInsuranceModalDisplayFlag}
            />
            {/* End of Storage Insurance modal */}
                

            {/* Set Pickup Schedule modal */}
            {
                storage && pickupScheduleModalDisplayFlag && 
                <PickupScheduleModal
                    scheduleModalDisplay = {pickupScheduleModalDisplayFlag} 
                    handleScheduleModalDisplay = {this.setPickupScheduleModalDisplayFlag}
                    handleSaveOperation = {this.handleSetPickupSchedule}
                    storageOrder = {storage}
                    user={user}
                />
            }
            
            {/* End of Schedule modal */}

            {/* Set Modify Pickup Schedule modal */}
            {
                storage && pickupScheduleModifyModalDisplayFlag && 
                <PickupScheduleModifyModal
                    scheduleModalDisplay = {pickupScheduleModifyModalDisplayFlag} 
                    handleScheduleModalDisplay = {this.setPickupScheduleModifyModalDisplayFlag}
                    handleSaveOperation = {this.handleSetPickupModifySchedule}
                    storageOrder = {storage}
                    user={user}
                />
            }
            
            {/* End of Modify Pickup Schedule modal */}

            {/* Set Cancel Pickup Schedule modal */}
            {
                storage &&
                <PickupScheduleCancelModal
                    scheduleModalDisplay = {pickupScheduleCancelModalDisplayFlag} 
                    handleScheduleModalDisplay = {this.setPickupScheduleCancelModalDisplayFlag}
                    handleSaveOperation = {this.handleSetPickupCancelSchedule}
                    storageOrder = {storage}
                    user={user}
                />
            }
            {/* End of Cancel Pickup Schedule modal */}

            {/* Delivery Scheduling Modal */}
            {   storage && deliveryScheduleModalDisplayFlag && 
                <DeliveryScheduleModal
                    modalName="deliveryScheduleModal"
                    modifyFlag={deliveryScheduleModifyModalDisplayFlag}
                    modalDisplayFlag={deliveryScheduleModalDisplayFlag}
                    handleModalDisplay={this.setDeliveryScheduleModalDisplayFlag}
                    handleSaveOperation = {this.handleSetDeliverySchedule}
                    storageOrder = {storage}
                    user={user}
                />
            }
            {/* End of Delivery Scheduling Modal */}

            {/* Delivery Scheduling Modify Modal */}
            {   storage && deliveryScheduleModifyModalDisplayFlag && 
                <DeliveryScheduleModifyModal
                    modalName="deliveryScheduleModifyModal"
                    modifyFlag={deliveryScheduleModifyModalDisplayFlag}
                    modalDisplayFlag={deliveryScheduleModifyModalDisplayFlag}
                    handleModalDisplay={this.setDeliveryScheduleModifyModalDisplayFlag}
                    handleSaveOperation = {this.handleSetDeliveryModifySchedule}
                    storageOrder = {storage}
                    user={user}
                />
            }
            {/* End of Delivery Scheduling Modal */}

            {/* Delivery Scheduling Cancel Modal */}
            {   storage &&
                <DeliveryScheduleCancelModal
                    modalDisplayFlag={deliveryScheduleCancelModalDisplayFlag}
                    handleModalDisplay={this.setDeliveryScheduleCancelModalDisplayFlag}
                    handleSaveOperation = {this.handleSetDeliveryCancelSchedule}
                    storageOrder = {storage}
                    user={user}
                />
            }
            {/* End of Delivery Scheduling Cancel Modal */}

            {/* Balance due Modal to Prohibit storage delivery*/}
            {
                storage && 
                <StorageDeliveryNotAllowedDueToBalanceDueModal 
                    displayModal={deliveryNotAllowedModalDisplayFlag}
                    handleCloseOfModalWindow={this.setDeliveryNotAllowedModalDisplayFlag}
                    messageAttributes={{
                        type:"storage-delivery-allowed",
                        accountBalance:accountCurrentBalance
                    }}
                    history={history}
                />
            }

            {/* Label Generator Modal */}
            {
                <LabelGeneratorModal
                    modalDisplayFlag={labelGeneratorModalDisplayFlag}
                    handleModalDisplay={this.setLabelGeneratorModalDisplayFlag}
                    handleSaveOperation = {this.handleGenerateStorageLabels}
                    handleShipWithUsModalDisplay={this.setShipWithUsModalDisplayFlag}
                
                />
            }

            {/* Price Estimator Modal */}
            {
                storage &&
                <PriceEstimatorModal
                    modalDisplayFlag={priceEstimateModalDisplayFlag}
                    handleModalDisplay={this.setPriceEstimateModalDisplayFlag}
                    storageOrder = {storage}
                    user={user}
                />
            }    
            {/* End of Price Estimator Modal */}

            <ShipToUsModal
                modalDisplayFlag={shipToUsModalDisplayFlag}
                handleModalDisplay={this.setShipToUsModalDisplayFlag}
                handleSaveOperation = {this.handleGenerateStorageLabels}
                universityRecord = {universityRecord}
            />

            <ShipWithUsModal
                modalDisplayFlag={shipWithUsModalDisplayFlag}
                handleModalDisplay={this.setShipWithUsModalDisplayFlag}
                handleSaveOperation = {this.handleGenerateStorageLabelsForShipWithUs}
                handleLaunchSchedulePickupModal = {(isStorageDetailAvailable && setPickupScheduleFlag) ? this.setPickupScheduleModalDisplayFlag:this.setPickupScheduleModifyModalDisplayFlag}
                isStorageDetailAvailable = {isStorageDetailAvailable}
                setPickupScheduleFlag = {setPickupScheduleFlag}
            />

            </>
        );
    }
}


export default StudentAccountStorageDetail;