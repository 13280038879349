import React,{Component} from "react";
import {Link} from "react-router-dom";
import _ from "lodash";
import Joi from "@hapi/joi";

import UserService from "../../services/userService";
import auth from "../../services/authService";

import BaseForm from "../BaseClass/Form"


import FormErrorWidget from "../Common/FormErrorWidget";
import SuccessMessageWidget from "../Common/SuccessMessageWidget";
import customToast from '../Common/CustomToast';
import {scrollTop}     from '../../utils/helperUtils';
import {renderError} from '../../utils/errorUtils';


import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Badge,
    Button,
    ButtonGroup,
    Form,
    FormGroup,
    Input,
    Label,
    Media
} from "reactstrap";

class ResetPasswordForm extends BaseForm{
    divAreaToScrollForError = "sectionArea"

    validationSchema = Joi.object({
        password:Joi.string().min(8).required().pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^ ]{8,}$/).label('Password'),
        retypePassword:Joi.string().required().valid(Joi.ref('password')).label('Confirm Password'),
        
    });

    errorMessages = {
        "password":{
            "string.pattern.base":"New Password must contain at least one uppercase letter, one lowercase letter and one number",
        },
        "retypePassword":{
            "any.only":"Confirm Password should match the Password"
        }
    }
    constructor(){
        super();

        this.state = {
            ...this.state,
            data:{
                password:"",  
                retypePassword:"",
            },
            errors:{

            }
        }
    }

    submitLocal = async (e)=>{
        try{
            const {password} = this.state.data;
            let {status:processingStatus,message,token} = await UserService.resetPassword({password});
            
            if(processingStatus === true){
                auth.setJwtOnResetPassword(token);
                const {state} = this.props.location;
                //console.log(state);
                this.props.history.push(state.from.pathname);
            }
            else{
                const errors = {message};
                this.setState({errors: errors || {}}); 
            }
        }
        catch(ex){
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop(this.divAreaToScrollForError); return;}
            }
            else
                renderError(ex);
        }
    }

    validateLocal= ()=>{
        
    }

    render(){
        const {data,errors,success} = this.state;
        const {
            password,
            retypePassword,
        } =  data;

        return(
            <>
                <Card className="card-plain bg-white p-2 rounded-lg">
                    <CardBody>
                        <Row>
                            <Col>
                                
                                {/*Error display */}
                                {!_.isEmpty(errors) &&
                                    <FormErrorWidget
                                    errors={errors}
                                    />
                                }
                                {/* End of Error display */}

                                {/*Success info display */}
                                {!_.isEmpty(success) &&
                                    <SuccessMessageWidget
                                    success={success}
                                    />
                                }
                                {/* End of Success info display */}
                                
                                <Form> 
                                    <h6 className="card-category text-left">Forgot Password</h6>
                                    <hr/>
                                    <p className="mb-4">
                                        Please enter your new password below. Your new password must have a minimum of 8 characters, 1 number and 1 uppercase letter.
                                    </p>
                                    <FormGroup row>
                                        <Label for="password"  sm={4} md={2}>Password</Label>
                                        <Col sm={4}>
                                            <Input 
                                                type="password" 
                                                name="password" 
                                                id="password" 
                                                placeholder="Password" 
                                                value={password} 
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label for="password" sm={4} md={2}>Confirm Password</Label>
                                        <Col sm={4}>
                                            <Input 
                                                type="password" 
                                                name="retypePassword" 
                                                id="retypePassword" 
                                                placeholder="Confirm Password" 
                                                value={retypePassword} 
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                    
                                    <FormGroup row className="">
                                        <Label for="loginButton" sm={4} md={1}></Label>
                                        
                                        <Col xs={{ size: 6}} sm={{ size: 4}} className="text-left">
                                            <Button 
                                                type="submit"
                                                id="loginButton"
                                                disabled={false}
                                                className="btn-rounded btn-danger"
                                                onClick={this.submitForm}
                                            >
                                            Change Password
                                            </Button>
                                        </Col>
                                    </FormGroup>

                                    
                                </Form>     
                            </Col>
                        </Row>  
                    </CardBody>
                </Card>
                
            </>
        );
    }
}

export default ResetPasswordForm;