/*
 This gets called from student account to sign for storage or laundry
 Residential will never be using this as they only get access to laundry

 accountType: student
 product: laundry,storage
 school
 step

 High level architecture:
 - This fill will handle processing of each screen and redirect to the next
 - Child component will handle it's own processing before it passes to the parent routine
*/
import React,{Component} from "react";
import {Link} from "react-router-dom";
import {StripeProvider} from 'react-stripe-elements';
import _ from "lodash";
import accounting from "accounting";
import moment from "moment";

import SignupLaundryResidential from "../../components/Consumer/ExistingUserSignup/Residential/Laundry/SignupLaundryResidential";
import RenewLaundryStudent from "../../components/Consumer/ExistingUserSignup/Student/Laundry/RenewLaundryStudent";
import RenewStorageStudent from "../../components/Consumer/ExistingUserSignup/Student/Storage/RenewStorageStudent";

import ResidentialService from "../../services/residentialService";
import SchoolService  from "../../services/schoolService";
import StudentService from "../../services/studentService";
import UserService    from "../../services/userService";
import auth           from "../../services/authService";
import LaundryService from "../../services/laundryService";


import ApplicationConstant        from "../../components/Common/ApplicationConstants";
import ProcessingModal            from "../../components/Common/ProcessingModal"
import applicationConstantUtility from "../../utils/applicationConstants" //This is used everywhere instead of the above one but this page uses the above one

import {renderError}              from '../../utils/errorUtils';
import {scrollTop}                from '../../utils/helperUtils';
import customToast                from '../../components/Common/CustomToast';

class ExistingUserSignUp extends Component{

    constructor(){
        super();  
        
        this.state = {
            listOfSchoolsByRegionForLaundry:"",
            listOfSchoolsByRegionForStorage:"",
            listOfResidentialCitiesByStateForLaundry:"",
            processingModalDisplayFlag:false,
            existingUser:{
                stripeMerchantAccountUniverityId:1,
                updatePrimaryAddressFlag:false,
                updatePaymentInformationFlag:false,
                primaryInformation:"", //Logged in users information 
                existingPaymentInformationOnFile:"",
                selectedProducts:[],//laundry,storage,supplies
                school:"", //Object{schooName,schoolId,semesterlist}
                residentialCity:"",
                paymentInformation:{
                    appliedPaymentType:"",//Gift Card or Credit Card 
                    creditCardStripeToken:"", 
                    creditCardName:"",  
                    creditCardNumber:"",
                    creditCardExpirationMonth:"",
                    creditCardExpirationYear:"",
                    creditCardCCV:"",
                    billingAddress:{
                          addressLine1:"",
                          addressLine2:"",
                          city:"",
                          state:"",
                          postalCode:"",
                          country:"US",
                    },
                    creditCardResponse:{
                          transactionId:"",
                    },
                },
                laundry:{
                    type:"", //student,residential,commercial
                    discount:{
                        discountCode:"",
                        discountType:"", //percent and dollar
                        discountValue:"", //discount value 0.05 or 5.00
                        voucherType:"", //Gift card or Coupon
                    }, 
                    totalCost:{
                        subtotal:"",
                        discountAmount:"", //this is basically a credit 
                        tax:"",
                        finalTotal:"",
                    }, 
                    pickupAndDeliveryAddress:{
                        zoneId:"",
                        streetNumber:"", //Non dormitory street number
                        addressLine1:"", //Street name
                        addressLine2:"",
                        apartmentNumber:"", //Non dormitory apartment number
                        city:"",
                        state:"",
                        postalCode:"",
                        country:"USA",
                        dormitory:{
                            id:"",
                            name:"",
                            roomNumber:""
                        },
                        isDormitory:"",
                    },
                    student:{
                        semester:"",    //{id,semester}
                        laundryType:"", //{id,type}
                        pricePlan:"",
                        insurance:"",
                    },
                    residential:{
                        laundryType:"", //{id,type}
                        pricePlan:"",  
                        insurance:"",  

                    },
                    
                },
                storage:{
                    type:"", //student,residential
                    discount:{
                        discountCode:"",
                        discountType:"", //percent and dollar
                        discountValue:"", //discount value 0.05 or 5.00
                        voucherType:"", //Gift card or Coupon
                    },
                    totalCost:{
                        subtotal:"",
                        discountAmount:"",  //this is basically a credit 
                        tax:"",
                        finalTotal:"",
                    },
                    pickUpAddress:{
                        isDormitory:"",
                        dormitory:{
                            id:"",
                            name:"",
                            roomNumber:""
                        },
                        zoneId:"",
                        streetNumber:"", //Non dormitory street number
                        apartmentNumber:"", //Non dormitory apartment number
                        addressLine1:"",
                        addressLine2:"",
                        city:"",
                        state:"",
                        postalCode:"",
                        country:"USA", 

                    },
                    deliveryAddress:{
                        isSameAsPickupAddress:"",
                        deliverToNewAddress:"",
                        deliveryAddressLater:"",
                        isDormitory:"",
                        dormitory:{
                            id:"",
                            name:"",
                            roomNumber:""
                        },
                        zoneId:"",
                        streetNumber:"", //Non dormitory street number
                        apartmentNumber:"", //Non dormitory apartment number
                        addressLine1:"",
                        addressLine2:"",
                        city:"",
                        state:"",
                        postalCode:"",
                        country:"USA", 
                    },
                    student:{
                        semester:"",    //{id,semester}
                        pricingProperties:"", //{storageDeposit, salesTax}
                    },
                },
                processingSteps:{
                    laundry:{
                        student:{
                            paymentProcessedFlag:false,
                            createAccountFlag:false,
                            emailNotificationFlag:false,
                            loggingYouInFlag:false, 
                        },
                        residential:{
                            paymentProcessedFlag:false,
                            createAccountFlag:false,
                            emailNotificationFlag:false,
                            loggingYouInFlag:false, 
                        }
                    },
                    storage:{
                        student:{
                            paymentProcessedFlag:false,
                            createAccountFlag:false,
                            emailNotificationFlag:false,
                            loggingYouInFlag:false, 
                        },
                    },
                } 
            },
            serverErrors:{},
        }
    }

    async componentDidMount(){
        try{
            const props = this.props;

            //console.log(this.state);
            const {accountType, product, schoolId,semesterId:semesterIdString,step } = this.props.match.params;
            const semesterId = parseInt(semesterIdString);

            let listOfSchoolsByRegionForLaundry,listOfSchoolsByRegionForStorage,listOfResidentialCitiesByStateForLaundry;
            if(accountType ==="student"){
                (listOfSchoolsByRegionForLaundry = await SchoolService.getListOfSchoolsByRegionForLaundry());
                (listOfSchoolsByRegionForStorage = await SchoolService.getListOfSchoolsByRegionForStorage());
            }
            const user = await auth.getUserRecord();
            const {user_id:userId,university_id:universityId} = user;
            //UserService.getFormattedUserAddress(user);

            //Get existing card information
                let existingPaymentInformationOnFile = "",stripeMerchantAccountUniverityId=1;
                stripeMerchantAccountUniverityId = universityId;
                const {status:processingStatus,message,returnObject:cardInformation} = await UserService.getCreditCardInfo({userId});
                if(processingStatus === true){
                    existingPaymentInformationOnFile = cardInformation;
                }
            //End of Get existing card information

            

            //Setting up laundry residential registration workflow
            if(accountType === "residential" && product === "laundry"){
                listOfResidentialCitiesByStateForLaundry = await ResidentialService.getListOfResidentialCitiesByState();
                const selectedProducts = [...this.state.existingUser.selectedProducts,product]
                
                let selectedCity = "";
                if(listOfResidentialCitiesByStateForLaundry){
                    listOfResidentialCitiesByStateForLaundry.forEach(region=>{
                        const {cities} = region;
                        const city =_.find(cities,{'universityId':universityId})
                        if(city){
                            selectedCity = _.pick(city,['id','name','pricing','insurance','universityId','university'])
                        }
                    })
                }

                const laundry = {
                    ...this.state.existingUser.laundry,  
                    type:accountType,
                }

                this.setState({
                    listOfResidentialCitiesByStateForLaundry,
                    existingUser:{
                        ...this.state.existingUser,
                        stripeMerchantAccountUniverityId,
                        primaryInformation:user,
                        existingPaymentInformationOnFile,
                        residentialCity:selectedCity,
                        selectedProducts,
                        laundry,
                    }
                })
                
            }
            
            //Setting up the laundry student registration workflow
            if(accountType ==="student" && product === 'laundry' && !_.isEmpty(user) && !_.isEmpty(listOfSchoolsByRegionForLaundry)){
                const selectedProducts = [...this.state.existingUser.selectedProducts,product]
                const laundry = {
                    ...this.state.existingUser.laundry,  
                    type:accountType,
                }

                const {campus_id:campusId} = user;
                let schoolObj = "";
                let region = _.find(listOfSchoolsByRegionForLaundry, {schools: [{id: campusId}] });
                if(!_.isEmpty(region)){
                    const school = _.find(region.schools, {'id':campusId});
                    schoolObj = _.pick(school,['id','name','universityId','university']);
                    
                    const semesterList = _.filter(school.semesters,{'id':semesterId});
                    schoolObj.semesters = semesterList
                    
                }
                
                this.setState({
                    listOfSchoolsByRegionForLaundry,
                    existingUser:{
                        ...this.state.existingUser,
                        stripeMerchantAccountUniverityId,
                        primaryInformation:user,
                        existingPaymentInformationOnFile,
                        school:schoolObj,
                        selectedProducts,
                        laundry,
                    }
                })
            }
            //End of setting up the laundry registration workflow

            //Setting up the storage registration workflow
            if(accountType ==="student" &&  product === 'storage' && !_.isEmpty(user) && !_.isEmpty(listOfSchoolsByRegionForStorage)){
                const selectedProducts = [...this.state.existingUser.selectedProducts,product]
                const storage = {
                    ...this.state.existingUser.storage,  
                    type:accountType,
                }

                const {campus_id:campusId} = user;
                let schoolObj = "";
                let region = _.find(listOfSchoolsByRegionForStorage, {schools: [{id: campusId}] });
                if(!_.isEmpty(region)){
                    const school = _.find(region.schools, {'id':campusId});
                    schoolObj = _.pick(school,['id','name','universityId','university']);
                    
                    const semesterList = _.filter(school.semesters,{'id':semesterId});
                    schoolObj.semesters = semesterList
                    
                }
                
                this.setState({
                    listOfSchoolsByRegionForStorage,
                    existingUser:{
                        ...this.state.existingUser,
                        stripeMerchantAccountUniverityId,
                        primaryInformation:user,
                        existingPaymentInformationOnFile,
                        school:schoolObj,
                        selectedProducts,
                        storage,
                    }
                });
            }
            //End of setting up the storage registration workflow


        }
        catch(ex){
            renderError(ex);
        } 

    }

    /*
     subtotal: Plan + insurance
      
     GiftCard: We subtract the gift card value to the final amount
     Coupon: We update the subtotal which affects the tax and final amount

   */
   calculateTotalCostLaundry(laundryType,pricePlanProperties,insuranceProperties,taxPercentage,discountProperties){
        let totalCost = {};
        const isSemesterPlan = (laundryType.id === ApplicationConstant.SEMESTER_PLAN) ? true:false;
        
        let [subTotal,
            tax,
            discountAmount,
            discountPercent,
            finalTotal,
            originalTotalWithoutDiscount] = [0,0,0,0,0,{}];

        if(isSemesterPlan){
            subTotal = parseFloat(accounting.toFixed((pricePlanProperties.price + insuranceProperties.totalPrice),2));
            tax = parseFloat(accounting.toFixed((subTotal * (taxPercentage/100)),2));
            finalTotal  = parseFloat(accounting.toFixed((subTotal + tax),2));
            originalTotalWithoutDiscount = {
                subTotal,
                tax,
                finalTotal,
            }

            if(discountProperties.voucherType === applicationConstantUtility.DISCOUNT_TYPE_GIFTCARD){
                if(discountProperties.discountType === applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR){
                discountAmount = (isNaN(discountProperties.discountValue)) ? 0:discountProperties.discountValue;
                discountAmount = parseFloat(accounting.toFixed(discountAmount,2));
                }
                finalTotal  = subTotal + tax - discountAmount;
                finalTotal = parseFloat(accounting.toFixed(finalTotal,2));
            }
            else if(discountProperties.voucherType === applicationConstantUtility.DISCOUNT_TYPE_COUPON){    
                if(discountProperties.discountType === applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR){
                    discountAmount = (isNaN(discountProperties.discountValue)) ? 0:discountProperties.discountValue;
                    discountAmount = parseFloat(accounting.toFixed(discountAmount,2));
                    subTotal = subTotal - discountAmount;
                }
                else if(discountProperties.discountType === applicationConstantUtility.DISCOUNT_VALUE_TYPE_PERCENT){
                    discountPercent = (isNaN(discountProperties.discountValue)) ? 0:discountProperties.discountValue;
                    discountAmount = parseFloat(accounting.toFixed(subTotal * (discountPercent/100),2));
                    subTotal = subTotal - discountAmount;
                }
                subTotal = parseFloat(accounting.toFixed(subTotal,2));
                tax = parseFloat(accounting.toFixed((subTotal * (taxPercentage/100)),2));
                finalTotal  = subTotal + tax;
                finalTotal = parseFloat(accounting.toFixed(finalTotal,2));
            }
        }
        else{
        //By the block 
        if(ApplicationConstant.BY_THE_POUND_SIGNUP_MINIMUMCHARGE > 0){
                subTotal = ApplicationConstant.BY_THE_POUND_SIGNUP_MINIMUMCHARGE;
                tax = (ApplicationConstant.COMPUTE_TAX_FOR_BY_THE_POUND === true ) ? parseFloat(accounting.toFixed((subTotal * (taxPercentage/100)),2)):0;
                discountAmount = parseFloat(accounting.toFixed((isNaN(discountProperties.discountValue)) ? 0:discountProperties.discountValue,2));
                finalTotal  = subTotal + tax - discountAmount;
                finalTotal = parseFloat(accounting.toFixed(finalTotal,2));
        }
        else{
                //This is to handle $0 on By the pound plan
                if(discountProperties.discountType === applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR){
                    discountAmount = parseFloat(accounting.toFixed((isNaN(discountProperties.discountValue)) ? 0:discountProperties.discountValue,2));
                    finalTotal  = subTotal + tax - discountAmount;
                    finalTotal = parseFloat(accounting.toFixed(finalTotal,2));
                }
        }
        }

        totalCost = {
            subTotal,
            discountAmount,
            tax,
            finalTotal,
            originalTotalWithoutDiscount,
        }
        

        return totalCost;
    }

    /*
    GiftCard: We subtract the gift card value to the final amount
     Coupon: We update the subtotal which affects the tax and final amount

   */
   calculateTotalCostStorage(priceProperties,taxPercentage,discountProperties){
        let totalCost = {};

        let [subTotal,
            tax,
            discountAmount,
            discountPercent,
            finalTotal,
            originalTotalWithoutDiscount] = [0,0,0,0,0,{}];

        subTotal = parseFloat(accounting.toFixed((priceProperties.storageDeposit),2));
        finalTotal  = parseFloat(accounting.toFixed((subTotal + tax),2));
        originalTotalWithoutDiscount = {
            subTotal,
            tax,
            finalTotal,
        }

        if(discountProperties.voucherType === applicationConstantUtility.DISCOUNT_TYPE_GIFTCARD){
            if(discountProperties.discountType === applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR){
                discountAmount = (isNaN(discountProperties.discountValue)) ? 0:discountProperties.discountValue;
                discountAmount = parseFloat(accounting.toFixed(discountAmount,2));
                
            }
        }
        else if(discountProperties.voucherType === applicationConstantUtility.DISCOUNT_TYPE_COUPON){
            //We are not going to apply the discounts to the totals but apply it to the first invoice
        }
        
        //tax = parseFloat(accounting.toFixed((subTotal * (taxPercentage/100)),2));
        finalTotal  = subTotal + tax - discountAmount;
        finalTotal = parseFloat(accounting.toFixed(finalTotal,2));    
        
        totalCost = {
            subTotal,
            discountAmount,
            tax,
            finalTotal,
            originalTotalWithoutDiscount,
        }
        
        return totalCost;
    }

    getCustomerAddressComponentDisplayProperties = ()=>{
        const {accountType, product} = this.props.match.params;
        const componentIsUsedFor = "existingUserSignUp";
        return applicationConstantUtility.customerAddressComponentDisplayProperties[componentIsUsedFor][accountType][product];
        
    }

    getPaymentInformationComponentDisplayProperties = ()=>{
        const {accountType, product} = this.props.match.params;
        const componentIsUsedFor = "existingUserSignUp";
        return applicationConstantUtility.paymentInformationComponentDisplayProperties[componentIsUsedFor][accountType][product];
        
    }

    handleCouponCodeProcessLaundryResidential = (formData)=>{
        //console.log(formData);
        const {couponCode,couponType,giftCardBalance,giftCardAmountToBeUsedForRedemption,couponDetails}  = formData;
        const user = {...this.state.existingUser}
        
        let discountProperties = {};
        if(couponType === applicationConstantUtility.DISCOUNT_TYPE_GIFTCARD){
            discountProperties = {
                ...user.laundry.discount,
                discountCode:couponCode,
                discountType:applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR, 
                discountValue:giftCardAmountToBeUsedForRedemption, 
                voucherType:couponType, 
            }
        }
        else if(couponType === applicationConstantUtility.DISCOUNT_TYPE_COUPON){
            const {couponDiscountType,couponDiscountValue} = couponDetails
            if(couponDiscountType === applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR){
                discountProperties = {
                    ...user.laundry.discount,
                    discountCode:couponCode,
                    discountType:applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR, 
                    discountValue:couponDiscountValue, 
                    voucherType:couponType, 
                } 
            }
            else if(couponDiscountType === applicationConstantUtility.DISCOUNT_VALUE_TYPE_PERCENT){
                discountProperties = {
                    ...user.laundry.discount,
                    discountCode:couponCode,
                    discountType:applicationConstantUtility.DISCOUNT_VALUE_TYPE_PERCENT, 
                    discountValue:couponDiscountValue, 
                    voucherType:couponType, 
                } 
            }

        }
        
        const {pricePlan,insurance,laundryType} = user.laundry.residential;
        const {laundry_tax:taxPercentage} =  user.residentialCity.university;
        
        
        const totalCost = this.calculateTotalCostLaundry(laundryType,pricePlan,insurance,taxPercentage,discountProperties);
        
        user.laundry.discount = discountProperties;
        user.laundry.totalCost = totalCost;
        this.setState({existingUser:user})

    }

    handleCouponCodeProcessLaundryStudent = (formData)=>{
        //console.log(formData);
        const {couponCode,couponType,giftCardBalance,giftCardAmountToBeUsedForRedemption,couponDetails}  = formData;
        const user = {...this.state.existingUser}
        
        let discountProperties = {};
        if(couponType === applicationConstantUtility.DISCOUNT_TYPE_GIFTCARD){
            discountProperties = {
                ...user.laundry.discount,
                discountCode:couponCode,
                discountType:applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR, 
                discountValue:giftCardAmountToBeUsedForRedemption, 
                voucherType:couponType, 
            }
        }
        else if(couponType === applicationConstantUtility.DISCOUNT_TYPE_COUPON){
            const {couponDiscountType,couponDiscountValue} = couponDetails
            if(couponDiscountType === applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR){
                discountProperties = {
                    ...user.laundry.discount,
                    discountCode:couponCode,
                    discountType:applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR, 
                    discountValue:couponDiscountValue, 
                    voucherType:couponType, 
                } 
            }
            else if(couponDiscountType === applicationConstantUtility.DISCOUNT_VALUE_TYPE_PERCENT){
                discountProperties = {
                    ...user.laundry.discount,
                    discountCode:couponCode,
                    discountType:applicationConstantUtility.DISCOUNT_VALUE_TYPE_PERCENT, 
                    discountValue:couponDiscountValue, 
                    voucherType:couponType, 
                } 
            }

        }
        
        const {pricePlan,insurance,laundryType} = user.laundry.student;
        const {laundry_tax:taxPercentage} =  user.school.university;
        
        
        const totalCost = this.calculateTotalCostLaundry(laundryType,pricePlan,insurance,taxPercentage,discountProperties);
        
        user.laundry.discount = discountProperties;
        user.laundry.totalCost = totalCost;
        this.setState({existingUser:user})

    }

    handleCouponCodeProcessStorageStudent = (formData)=>{
        const {couponCode,couponType,giftCardBalance,giftCardAmountToBeUsedForRedemption,couponDetails}  = formData;
        const user = {...this.state.existingUser}

        let discountProperties = {};
        if(couponType === applicationConstantUtility.DISCOUNT_TYPE_GIFTCARD){
            discountProperties = {
                ...user.storage.discount,
                discountCode:couponCode,
                discountType:applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR, 
                discountValue:giftCardAmountToBeUsedForRedemption, 
                voucherType:couponType, 
            }
        }
        else if(couponType === applicationConstantUtility.DISCOUNT_TYPE_COUPON){
            const {couponDiscountType,couponDiscountValue} = couponDetails
            if(couponDiscountType === applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR){
                discountProperties = {
                    ...user.storage.discount,
                    discountCode:couponCode,
                    discountType:applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR, 
                    discountValue:couponDiscountValue, 
                    voucherType:couponType, 
                } 
            }
            else if(couponDiscountType === applicationConstantUtility.DISCOUNT_VALUE_TYPE_PERCENT){
                discountProperties = {
                    ...user.storage.discount,
                    discountCode:couponCode,
                    discountType:applicationConstantUtility.DISCOUNT_VALUE_TYPE_PERCENT, 
                    discountValue:couponDiscountValue, 
                    voucherType:couponType, 
                } 
            }

        }

        const {pricingProperties} = user.storage.student;
        const {salesTax:taxPercentage} =  pricingProperties;
        
        
        const totalCost = this.calculateTotalCostStorage(pricingProperties,taxPercentage,discountProperties);
        user.storage.discount = discountProperties;
        user.storage.totalCost = totalCost;
        this.setState({existingUser:user})
    
       
    }

    handleResidentialLaundryBackOnCustomerAddressInformation = ()=>{
        const {accountType, product, schoolId,semesterId } = this.props.match.params;
        const step = "payment-info";
        
        this.props.history.push(`/existing-user/signup/${accountType}/${product}/${schoolId}/${semesterId}/${step}`); 
    }

    handleResidentialLaundryBackOnPaymentInformation = (formData)=>{
        const {accountType, product, schoolId,semesterId } = this.props.match.params;
        const step = "select-plan";
        
        this.props.history.push(`/existing-user/signup/${accountType}/${product}/${schoolId}/${semesterId}/${step}`); 
    }

    handleResidentialLaundryBackOnReviewScreen = ()=>{
        const {accountType, product, schoolId,semesterId } = this.props.match.params;
        const step = "address-info";
        
        this.props.history.push(`/existing-user/signup/${accountType}/${product}/${schoolId}/${semesterId}/${step}`); 
    }

    handleResidentialLaundrySaveOnCustomerAddressInformation = (formData)=>{
        try{
            const {accountType, product, schoolId,semesterId } = this.props.match.params;
            const step = "review-screen";

            const {
                updatePrimaryAddressFlag,
                pickUpAddress:pickUpAddressFormData,
            } = formData;

            const user = {
                ...this.state.existingUser,
                updatePrimaryAddressFlag,
            }
            
              
            const pickupAndDeliveryAddress = {
                isDormitory: (pickUpAddressFormData.isDormitory === "Yes")? true:false,
                dormitory:{
                    id:(pickUpAddressFormData.isDormitory === "Yes") ? pickUpAddressFormData.dormitoryName.value:"",
                    name:(pickUpAddressFormData.isDormitory === "Yes") ? pickUpAddressFormData.dormitoryName.label:"",
                    roomNumber:(pickUpAddressFormData.isDormitory === "Yes") ? pickUpAddressFormData.dormitoryRoomNumber:""
                },
                streetNumber:(pickUpAddressFormData.isDormitory !== "Yes") ? pickUpAddressFormData.streetNumber:"",
                addressLine1:(pickUpAddressFormData.isDormitory !== "Yes") ? pickUpAddressFormData.addressLine1:"",
                addressLine2:(pickUpAddressFormData.isDormitory !== "Yes") ?pickUpAddressFormData.addressLine2:"",
                apartmentNumber:(pickUpAddressFormData.isDormitory !== "Yes") ?pickUpAddressFormData.apartmentNumber:"",
                city:pickUpAddressFormData.city,
                state:(_.has(pickUpAddressFormData,['state','value'])) ? pickUpAddressFormData.state.value:"",
                postalCode:pickUpAddressFormData.postalCode,
                country:"USA", 
            };

            const laundry = {
                ...user.laundry,
                pickupAndDeliveryAddress
            } 
            user.laundry = laundry;
            
            this.setState({
                existingUser:user,
            })
            
            this.props.history.push(`/existing-user/signup/${accountType}/${product}/${schoolId}/${semesterId}/${step}`);
        }
        catch(ex){
            console.log("Error handleSaveLoginAndPaymentInformation",ex);
        } 
    }

    handleResidentialLaundrySavePricingAndInsuranceInformation = formData=>{
        const {accountType, product, schoolId,semesterId } = this.props.match.params;
        const step = "payment-info";

        const user = {...this.state.existingUser}
        if(user.laundry.type === "residential"){
            const {laundryType,pricePlan,insurance} = formData;
            const discountProperties = {...user.laundry.discount}
            //Update the total
            const {laundry_tax:taxPercentage} =  user.residentialCity.university;
            const totalCost = this.calculateTotalCostLaundry(laundryType,pricePlan,insurance,taxPercentage,discountProperties);
            //End of updating total

            const residentialLaundryPricingAndInsuranceInformation = {
                laundryType, 
                pricePlan,
                insurance
            }
            
            user.laundry[user.laundry.type] = residentialLaundryPricingAndInsuranceInformation;
            user.laundry.totalCost = totalCost;
        }
        this.setState({existingUser:user});
        this.props.history.push(`/existing-user/signup/${accountType}/${product}/${schoolId}/${semesterId}/${step}`);  
    }

    handleResidentialLaundrySaveOnPaymentInformation = (formData)=>{
        const {accountType, product, schoolId,semesterId } = this.props.match.params;
        const step = "address-info";

        try{
            const user = {...this.state.existingUser}
            const {billingAddress,updatePaymentInformationFlag} = formData;
            if(updatePaymentInformationFlag === true){
                billingAddress.state = billingAddress.state.value;
                billingAddress.country = billingAddress.country.value;
                user.updatePaymentInformationFlag = updatePaymentInformationFlag;
                user.paymentInformation = {
                    creditCardStripeToken:formData.stripeToken,
                    creditCardName:formData.creditCardName,  
                    billingAddress
                }
            }

            this.setState({existingUser:user});
            this.props.history.push(`/existing-user/signup/${accountType}/${product}/${schoolId}/${semesterId}/${step}`); 
        }
        catch(ex){
            console.log("Error handleSaveLoginAndPaymentInformation",ex);
        }

       
    }

    handleResidentialLaundryPurchaseOnReviewScreen = async ()=>{
        try{
            console.log('handleResidentialLaundryPurchaseOnReviewScreen');
            //Build payload
            const formData = {...this.state.existingUser};
            
            this.setState({
                processingModalDisplayFlag:true,
            })

            const {status:processingStatus,message,errorType} = await ResidentialService.saveLaundryExistingUserSignup(formData);
            
            if(processingStatus === true){
                //customToast.success(message); 
                return this.props.history.replace("/myaccount/residential/laundry?welcome=true");  
            }
            else if(processingStatus === false){
                //customToast.error(message);
                //Handle errors from the server
                const {accountType, product, schoolId, semesterId, step } = this.props.match.params;
    
                let [errors,errorMessage] = [{},""];
                if(errorType === "credit-card"){
                    errorMessage = <>
                       {`${message} Click `}<Link to={`/existing-user/signup/${accountType}/${product}/${schoolId}/${semesterId}/payment-info`}>here</Link>{` to re-enter or update payment informtion.`}
                    </>
                }
                else if(errorType === applicationConstantUtility.FINAL_PROCESSING_ERROR_TYPE_SIGNUP){
                    return this.props.history.replace("/signup/manual-processing/confirmation/laundry")
                }
                else{
                    errorMessage = message; 
                } 
                
                errors = {message:errorMessage};
                this.setState({serverErrors: errors || {}});
            }

            this.setState({
                processingModalDisplayFlag:false
            })

            
        } 
        catch(ex){
            this.setState({
                processingModalDisplayFlag:false
            })

            /*
              400 signals validation errors from the server
              renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop('sectionArea'); return;}
            }
            else
                renderError(ex);
        }  
    }

    

    handleStudentLaundryBackOnCustomerAddressInformation = ()=>{
        const {accountType, product, schoolId,semesterId,changePlanFlag } = this.props.match.params;
        const step = "payment-info";
        
        const changePlanFlagUrlParameter = changePlanFlag || "";
        this.props.history.push(`/existing-user/signup/${accountType}/${product}/${schoolId}/${semesterId}/${step}/${changePlanFlagUrlParameter}`); 
    }

    handleStudentLaundryBackOnPaymentInformation = (formData)=>{
        const {accountType, product, schoolId,semesterId,changePlanFlag } = this.props.match.params;
        const step = "select-plan";
        
        const changePlanFlagUrlParameter = changePlanFlag || "";
        this.props.history.push(`/existing-user/signup/${accountType}/${product}/${schoolId}/${semesterId}/${step}/${changePlanFlagUrlParameter}`); 
    }

    handleStudentLaundryBackOnReviewScreen = ()=>{
        const {accountType, product, schoolId,semesterId,changePlanFlag } = this.props.match.params;
        const step = "address-info";
        
        const changePlanFlagUrlParameter = changePlanFlag || "";
        this.props.history.push(`/existing-user/signup/${accountType}/${product}/${schoolId}/${semesterId}/${step}/${changePlanFlagUrlParameter}`); 
    }

    handleStudentLaundryPurchaseOnReviewScreen = async ()=>{
        try{
            const {changePlanFlag } = this.props.match.params;
            
            console.log('handleStudentLaundryPurchaseOnReviewScreen');
            //Build payload
            const formData = {
                ...this.state.existingUser,
                laundry:{
                    changeToSemesterPlan: (changePlanFlag === LaundryService.CHANGE_TO_SEMESTER_PLAN) ? true:false,
                    ...this.state.existingUser.laundry,
                }
            };
            
            this.setState({
                processingModalDisplayFlag:true,
            })

            const {status:processingStatus,message,errorType} = 
                    (changePlanFlag === LaundryService.CHANGE_TO_SEMESTER_PLAN) ? 
                    await StudentService.saveStudentLaundryChangeFromByThePoundToSemesterPlan(formData):
                    await StudentService.saveStudentLaundryRenewPlan(formData);
                    
            if(processingStatus === true){
                //customToast.success(message); 
                return this.props.history.replace("/myaccount/student/laundry?welcome=true");  
            }
            else if(processingStatus === false){
                //customToast.error(message);
                //Handle errors from the server
                const {accountType, product, schoolId, semesterId, step } = this.props.match.params;
    
                let [errors,errorMessage] = [{},""];
                if(errorType === "credit-card"){
                    errorMessage = <>
                       {`${message} Click `}<Link to={`/existing-user/signup/${accountType}/${product}/${schoolId}/${semesterId}/payment-info`}>here</Link>{` to re-enter or update payment informtion.`}
                    </>
                }
                else if(errorType === applicationConstantUtility.FINAL_PROCESSING_ERROR_TYPE_SIGNUP){
                    return this.props.history.replace("/signup/manual-processing/confirmation/laundry")
                }
                else{
                    errorMessage = message; 
                } 
                
                errors = {message:errorMessage};
                this.setState({serverErrors: errors || {}});
            }

            this.setState({
                processingModalDisplayFlag:false
            })

            
        } 
        catch(ex){
            /*
              400 signals validation errors from the server
              renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop('sectionArea'); return;}
            }
            else
                renderError(ex);
        }  
    }

    handleStudentLaundrySaveOnCustomerAddressInformation = (formData)=>{
        try{
            const {accountType, product, schoolId,semesterId,changePlanFlag } = this.props.match.params;
            const step = "review-screen";

            const {
                updatePrimaryAddressFlag,
                pickUpAddress:pickUpAddressFormData,
            } = formData;

            const user = {
                ...this.state.existingUser,
                updatePrimaryAddressFlag,
            }
            
              
            const pickupAndDeliveryAddress = {
                isDormitory: (pickUpAddressFormData.isDormitory === "Yes")? true:false,
                dormitory:{
                    id:(pickUpAddressFormData.isDormitory === "Yes") ? pickUpAddressFormData.dormitoryName.value:"",
                    name:(pickUpAddressFormData.isDormitory === "Yes") ? pickUpAddressFormData.dormitoryName.label:"",
                    roomNumber:(pickUpAddressFormData.isDormitory === "Yes") ? pickUpAddressFormData.dormitoryRoomNumber:""
                },
                streetNumber:(pickUpAddressFormData.isDormitory !== "Yes") ? pickUpAddressFormData.streetNumber:"",
                addressLine1:(pickUpAddressFormData.isDormitory !== "Yes") ? pickUpAddressFormData.addressLine1:"",
                addressLine2:(pickUpAddressFormData.isDormitory !== "Yes") ?pickUpAddressFormData.addressLine2:"",
                apartmentNumber:(pickUpAddressFormData.isDormitory !== "Yes") ?pickUpAddressFormData.apartmentNumber:"",
                city:pickUpAddressFormData.city,
                state:(_.has(pickUpAddressFormData,['state','value'])) ? pickUpAddressFormData.state.value:"",
                postalCode:pickUpAddressFormData.postalCode,
                country:"USA", 
            };

            const laundry = {
                ...user.laundry,
                pickupAndDeliveryAddress
            } 
            user.laundry = laundry;
            
            this.setState({
                existingUser:user,
            })
            
            const changePlanFlagUrlParameter = changePlanFlag || "";
            this.props.history.push(`/existing-user/signup/${accountType}/${product}/${schoolId}/${semesterId}/${step}/${changePlanFlagUrlParameter}`);
        }
        catch(ex){
            console.log("Error handleSaveLoginAndPaymentInformation",ex);
        } 
    }

    handleStudentLaundrySaveOnPaymentInformation = (formData)=>{
        const {accountType, product, schoolId,semesterId,changePlanFlag } = this.props.match.params;
        const step = "address-info";

        try{
            const user = {...this.state.existingUser}
            const {billingAddress,updatePaymentInformationFlag} = formData;
            if(updatePaymentInformationFlag === true){
                billingAddress.state = billingAddress.state.value;
                billingAddress.country = billingAddress.country.value;
                user.updatePaymentInformationFlag = updatePaymentInformationFlag;
                user.paymentInformation = {
                    creditCardStripeToken:formData.stripeToken,
                    creditCardName:formData.creditCardName,  
                    billingAddress
                }
            }

            this.setState({existingUser:user});
            
            const changePlanFlagUrlParameter = changePlanFlag || "";
            this.props.history.push(`/existing-user/signup/${accountType}/${product}/${schoolId}/${semesterId}/${step}/${changePlanFlagUrlParameter}`); 
        }
        catch(ex){
            console.log("Error handleSaveLoginAndPaymentInformation",ex);
        }

       
    }

    handleStudentLaundrySaveOnSemesterAndPricingInformation = (formData)=>{
        const {accountType, product, schoolId,semesterId,changePlanFlag } = this.props.match.params;
        const step = "payment-info";

        const user = {...this.state.existingUser}
        if(user.laundry.type === "student"){
            const {semester,laundryType,pricePlan,insurance} = formData;
            const discountProperties = {...user.laundry.discount}
            //Update the total
            const {laundry_tax:taxPercentage} =  user.school.university;
            const totalCost = this.calculateTotalCostLaundry(laundryType,pricePlan,insurance,taxPercentage,discountProperties);
            //End of updating total

            const studentLaundrySemesterAndPricing = {
                semester,
                laundryType, 
                pricePlan,
                insurance,

            }
            
            user.laundry[user.laundry.type] = studentLaundrySemesterAndPricing;
            user.laundry.totalCost = totalCost;
        }
        this.setState({existingUser:user});

        const changePlanFlagUrlParameter = changePlanFlag || "";
        this.props.history.push(`/existing-user/signup/${accountType}/${product}/${schoolId}/${semesterId}/${step}/${changePlanFlagUrlParameter}`);  
    }

    handleStudentStorageBackOnCustomerAddressInformation = ()=>{
        const {accountType, product, schoolId,semesterId } = this.props.match.params;
        const step = "select-plan";
        
        this.props.history.push(`/existing-user/signup/${accountType}/${product}/${schoolId}/${semesterId}/${step}`); 
    }

    handleStudentStorageBackOnDeliveryAddressInformation = ()=>{
        const {accountType, product, schoolId,semesterId } = this.props.match.params;
        const step = "update-campus-address";
        
        this.props.history.push(`/existing-user/signup/${accountType}/${product}/${schoolId}/${semesterId}/${step}`); 
    }

    handleStudentStorageBackOnPaymentInformation = ()=>{
        const {accountType, product, schoolId,semesterId } = this.props.match.params;
        const step = "set-delivery-address";
        
        this.props.history.push(`/existing-user/signup/${accountType}/${product}/${schoolId}/${semesterId}/${step}`); 
    }

    handleStudentStorageBackOnReviewScreen = ()=>{
        const {accountType, product, schoolId,semesterId } = this.props.match.params;
        const step = "payment-info";
        
        this.props.history.push(`/existing-user/signup/${accountType}/${product}/${schoolId}/${semesterId}/${step}`); 

    }

    handleStudentStoragePurchaseOnReviewScreen = async ()=>{
        try{
            console.log('handleStudentStoragePurchaseOnReviewScreen');
            //Build payload
            const formData = {...this.state.existingUser};
            
            this.setState({
                processingModalDisplayFlag:true,
            })

            const {status:processingStatus,message,errorType} = await StudentService.saveStudentStorageRenewPlan(formData);
            if(processingStatus === true){
                //customToast.success(message); 
                return this.props.history.replace("/myaccount/student/storage?welcome=true"); 
            }
            else if(processingStatus === false){
                const {accountType, product, schoolId, semesterId, step } = this.props.match.params;
    
                let [errors,errorMessage] = [{},""];
                if(errorType === "credit-card"){
                    errorMessage = <>
                       {`${message} Click `}<Link to={`/existing-user/signup/${accountType}/${product}/${schoolId}/${semesterId}/payment-info`}>here</Link>{` to re-enter or update payment informtion.`}
                    </>
                }
                else if(errorType === applicationConstantUtility.FINAL_PROCESSING_ERROR_TYPE_SIGNUP){
                    return this.props.history.replace("/signup/manual-processing/confirmation/storage")
                }
                else{
                    errorMessage = message; 
                } 
                
                errors = {message:errorMessage};
                this.setState({serverErrors: errors || {}});
            }

            this.setState({
                processingModalDisplayFlag:false
            })
            
        } 
        catch(ex){
            /*
              400 signals validation errors from the server
              renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop('sectionArea'); return;}
            }
            else
                renderError(ex);
        } 
    }

    handleStudentStorageSaveOnCustomerAddressInformation = (formData)=>{
        try{
            const {accountType, product, schoolId,semesterId } = this.props.match.params;
            const step = "set-delivery-address";

            const {
                updatePrimaryAddressFlag,
                pickUpAddress:pickUpAddressFormData,
            } = formData;

            const user = {
                ...this.state.existingUser,
                updatePrimaryAddressFlag,
            }

            const pickUpAddress = {
                isDormitory: (pickUpAddressFormData.isDormitory === "Yes")? true:false,
                dormitory:{
                    id:(pickUpAddressFormData.isDormitory === "Yes") ? pickUpAddressFormData.dormitoryName.value:"",
                    name:(pickUpAddressFormData.isDormitory === "Yes") ? pickUpAddressFormData.dormitoryName.label:"",
                    roomNumber:(pickUpAddressFormData.isDormitory === "Yes") ? pickUpAddressFormData.dormitoryRoomNumber:""
                },
                streetNumber:(pickUpAddressFormData.isDormitory !== "Yes") ? pickUpAddressFormData.streetNumber:"",
                addressLine1:(pickUpAddressFormData.isDormitory !== "Yes") ? pickUpAddressFormData.addressLine1:"",
                addressLine2:(pickUpAddressFormData.isDormitory !== "Yes") ?pickUpAddressFormData.addressLine2:"",
                apartmentNumber:(pickUpAddressFormData.isDormitory !== "Yes") ?pickUpAddressFormData.apartmentNumber:"",
                city:pickUpAddressFormData.city,
                state:(_.has(pickUpAddressFormData,['state','value'])) ? pickUpAddressFormData.state.value:"",
                postalCode:pickUpAddressFormData.postalCode,
                country:"USA", 
            };

            const storage = {
                ...user.storage,
                pickUpAddress
            } 
            user.storage = storage;
            
            this.setState({
                existingUser:user,
            })

            this.props.history.push(`/existing-user/signup/${accountType}/${product}/${schoolId}/${semesterId}/${step}`);
        }
        catch(ex){
            console.log("Error handleSaveLoginAndPaymentInformation",ex);
        } 
    }

    handleStudentStorageSaveOnDeliveryAddressInformation = (formData)=>{
        try{
            const {accountType, product, schoolId,semesterId } = this.props.match.params;
            const step = "payment-info";

            const {
                deliveryAddress:deliveryAddressFormData,
            } = formData;

            const {
                deliverToYourCurrentAddress, 
                deliverToNewAddress,
                deliveryAddressLater,
                    
            } = deliveryAddressFormData

            const user = {
                ...this.state.existingUser,
            }

            let deliveryAddress = null;
            deliveryAddress = {
                ...user.storage.deliveryAddress,
                isSameAsPickupAddress:false,
                deliveryAddressLater:false,
                deliverToNewAddress:false,
            }

            //Same as delivery address
            if(deliverToYourCurrentAddress === true){
                deliveryAddress.isSameAsPickupAddress = true;
            }
            if(deliveryAddressLater === true){
                deliveryAddress.deliveryAddressLater = true;
            }
            if(deliverToNewAddress === true){
                deliveryAddress = {
                    isSameAsPickupAddress:false,
                    deliveryAddressLater:false,
                    deliverToNewAddress:true,
                    isDormitory: (deliveryAddressFormData.isDormitory === "Yes")? true:false,
                    dormitory:{
                        id:(deliveryAddressFormData.isDormitory === "Yes") ? deliveryAddressFormData.dormitoryName.value:"",
                        name:(deliveryAddressFormData.isDormitory === "Yes") ? deliveryAddressFormData.dormitoryName.label:"",
                        roomNumber:(deliveryAddressFormData.isDormitory === "Yes") ? deliveryAddressFormData.dormitoryRoomNumber:""
                    },
                    streetNumber:(deliveryAddressFormData.isDormitory !== "Yes") ? deliveryAddressFormData.streetNumber:"",
                    addressLine1:(deliveryAddressFormData.isDormitory !== "Yes") ? deliveryAddressFormData.addressLine1:"",
                    addressLine2:(deliveryAddressFormData.isDormitory !== "Yes") ? deliveryAddressFormData.addressLine2:"",
                    apartmentNumber:(deliveryAddressFormData.isDormitory !== "Yes") ?deliveryAddressFormData.apartmentNumber:"",
                    city:deliveryAddressFormData.city,
                    state:(_.has(deliveryAddressFormData,['state','value'])) ? deliveryAddressFormData.state.value:"",
                    postalCode:deliveryAddressFormData.postalCode,
                    country:"USA", 
                };
            }
            
            const storage = {
                ...user.storage,
                deliveryAddress
            } 
            
            user.storage = storage;
            
            this.setState({
                existingUser:user,
            })
            
            this.props.history.push(`/existing-user/signup/${accountType}/${product}/${schoolId}/${semesterId}/${step}`);
        }
        catch(ex){
            console.log("Error handleSaveLoginAndPaymentInformation",ex);
        } 
    }

    handleStudentStorageSaveOnPaymentInformation = (formData)=>{
        const {accountType, product, schoolId,semesterId } = this.props.match.params;
        const step = "review-screen";

        try{
            const user = {...this.state.existingUser}
            const {billingAddress,updatePaymentInformationFlag} = formData;
            if(updatePaymentInformationFlag === true){
                billingAddress.state = billingAddress.state.value;
                billingAddress.country = billingAddress.country.value;
                user.updatePaymentInformationFlag = updatePaymentInformationFlag;
                user.paymentInformation = {
                    creditCardStripeToken:formData.stripeToken,
                    creditCardName:formData.creditCardName,  
                    billingAddress
                }
            }

            this.setState({existingUser:user});
            this.props.history.push(`/existing-user/signup/${accountType}/${product}/${schoolId}/${semesterId}/${step}`); 
        }
        catch(ex){
            console.log("Error handleSaveLoginAndPaymentInformation",ex);
        }

       
    }

    handleStudentStorageSaveOnSemesterAndPricingInformation = (formData)=>{
        const {accountType, product, schoolId,semesterId } = this.props.match.params;
        const step = "update-campus-address";
        const user = {...this.state.existingUser}
        if(user.storage.type === "student"){
            const {semester,pricingProperties} = formData;
            const discountProperties = {...user.storage.discount}
            const taxPercentage = pricingProperties.salesTax;
            const totalCost = this.calculateTotalCostStorage(pricingProperties,taxPercentage,discountProperties);
            
            const studentStorageSemesterAndPricing = {
                semester,
                pricingProperties,
            };

            user.storage[user.storage.type] =  studentStorageSemesterAndPricing;
            user.storage.totalCost = totalCost;
        }

        this.setState({existingUser:user});
        this.props.history.push(`/existing-user/signup/${accountType}/${product}/${schoolId}/${semesterId}/${step}`); 
    }

    setProcessingModalDisplayFlag = flag=>{
        this.setState({
            processingModalDisplayFlag:flag,
        })
    }

    
    render(){
        const props = this.props;
        const {accountType, product, school,step } = this.props.match.params;
        //console.log(product);
        const {existingUser:user, processingModalDisplayFlag,serverErrors,} = this.state;

        const stripeMerchantAccountUniverityId = user.stripeMerchantAccountUniverityId;
        const customerAddressComponentDisplayProperties = this.getCustomerAddressComponentDisplayProperties();
        const paymentInformationComponentDisplayProperties = this.getPaymentInformationComponentDisplayProperties();
        
        return(
            <StripeProvider key={stripeMerchantAccountUniverityId} apiKey={process.env[`REACT_APP_STRIPE_PRODUCTION_KEY_UNIVERSITY_ID_${stripeMerchantAccountUniverityId}`]}>  
                <React.Fragment>
                    {   accountType === "residential" &&
                        product === "laundry" && 
                        <SignupLaundryResidential
                            user={user}
                            customerAddressComponentDisplayProperties = {customerAddressComponentDisplayProperties}
                            paymentInformationComponentDisplayProperties = {paymentInformationComponentDisplayProperties}
                            onClickOfNextButtonFromPricingAndInsuranceInformation = {this.handleResidentialLaundrySavePricingAndInsuranceInformation}
                            onClickOfBackButtonFromPaymentInformation = {this.handleResidentialLaundryBackOnPaymentInformation}
                            onClickOfNextButtonFromPaymentInformation = {this.handleResidentialLaundrySaveOnPaymentInformation}
                            onClickOfBackButtonFromCustomerAddressInformation = {this.handleResidentialLaundryBackOnCustomerAddressInformation}
                            onClickOfNextButtonFromCustomerAddressInformation = {this.handleResidentialLaundrySaveOnCustomerAddressInformation}
                            onClickOfBackButtonFromReviewScreen = {this.handleResidentialLaundryBackOnReviewScreen}
                            onClickOfPurchaseButtonFromReviewScreen = {this.handleResidentialLaundryPurchaseOnReviewScreen}
                            onClickOfApplyCouponButtonFromReviewScreen = {this.handleCouponCodeProcessLaundryResidential}
                            handleDisplayOfProcessingModal = {this.setProcessingModalDisplayFlag}
                            serverErrors={serverErrors}
                            {...props}
                        />
                    }

                    {   accountType === "student" &&
                        product === "laundry" && 
                        <RenewLaundryStudent
                            user={user}
                            customerAddressComponentDisplayProperties = {customerAddressComponentDisplayProperties}
                            paymentInformationComponentDisplayProperties = {paymentInformationComponentDisplayProperties}
                            onClickOfNextButtonFromSemesterAndPricingInformation = {this.handleStudentLaundrySaveOnSemesterAndPricingInformation}
                            onClickOfBackButtonFromPaymentInformation = {this.handleStudentLaundryBackOnPaymentInformation}
                            onClickOfNextButtonFromPaymentInformation = {this.handleStudentLaundrySaveOnPaymentInformation}
                            onClickOfBackButtonFromCustomerAddressInformation = {this.handleStudentLaundryBackOnCustomerAddressInformation}
                            onClickOfNextButtonFromCustomerAddressInformation = {this.handleStudentLaundrySaveOnCustomerAddressInformation}
                            onClickOfBackButtonFromReviewScreen = {this.handleStudentLaundryBackOnReviewScreen}
                            onClickOfPurchaseButtonFromReviewScreen = {this.handleStudentLaundryPurchaseOnReviewScreen}
                            onClickOfApplyCouponButtonFromReviewScreen = {this.handleCouponCodeProcessLaundryStudent}
                            handleDisplayOfProcessingModal = {this.setProcessingModalDisplayFlag}
                            serverErrors={serverErrors}
                            {...props}
                        />
                    }

                    {   accountType === "student" &&
                        product === "storage" && 
                        <RenewStorageStudent
                            user={user}
                            customerAddressComponentDisplayProperties = {customerAddressComponentDisplayProperties}
                            paymentInformationComponentDisplayProperties = {paymentInformationComponentDisplayProperties}
                            onClickOfNextButtonFromSemesterAndPricingInformation = {this.handleStudentStorageSaveOnSemesterAndPricingInformation}
                            onClickOfBackButtonFromCustomerAddressInformation = {this.handleStudentStorageBackOnCustomerAddressInformation}
                            onClickOfNextButtonFromCustomerAddressInformation = {this.handleStudentStorageSaveOnCustomerAddressInformation}
                            onClickOfBackButtonFromDeliveryAddressInformation = {this.handleStudentStorageBackOnDeliveryAddressInformation}
                            onClickOfNextButtonFromDeliveryAddressInformation = {this.handleStudentStorageSaveOnDeliveryAddressInformation}
                            onClickOfBackButtonFromPaymentInformation = {this.handleStudentStorageBackOnPaymentInformation}
                            onClickOfNextButtonFromPaymentInformation = {this.handleStudentStorageSaveOnPaymentInformation}
                            onClickOfBackButtonFromReviewScreen = {this.handleStudentStorageBackOnReviewScreen}
                            onClickOfPurchaseButtonFromReviewScreen = {this.handleStudentStoragePurchaseOnReviewScreen}
                            onClickOfApplyCouponButtonFromReviewScreen = {this.handleCouponCodeProcessStorageStudent}
                            handleDisplayOfProcessingModal = {this.setProcessingModalDisplayFlag}
                            serverErrors={serverErrors}
                            {...props}
                        /> 
                    }

                    {/* Set Processing modal */}
                        <ProcessingModal
                            displayModal={processingModalDisplayFlag}
                            handleCloseOfModalWindow={this.setProcessingModalDisplayFlag}
                        />
                    {/* End of Processing modal */}
                    
                </React.Fragment>
            </StripeProvider>
        )
    }
}

export default ExistingUserSignUp;