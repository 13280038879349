import React,{Component} from "react";
import _ from "lodash";
import Select from "react-select";
import {getUSStateList} from "services/dropDownListsService";
import SchoolService from "services/schoolService";
import http from "services/httpService";

import Joi from "@hapi/joi";

import customToast from '../../../Common/CustomToast';
import {renderError} from 'utils/errorUtils';


// reactstrap components
import {
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    
} from "reactstrap";

import StudentLaundryRegistrationStepsDisplayWidget from "../Common/StudentLaundryRegistrationStepsDisplayWidget";
import ResidentialLaundryRegistrationStepsDisplayWidget from "../Common/ResidentialLaundryRegistrationStepsDisplayWidget";
import FormErrorWidget from "components/Common/FormErrorWidget";

const apiUserEndpoint = "/users";

const scrollTop = ()=>{
    document.getElementById("sectionArea").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
    });
};

class  StudentInformation extends Component{

    /*
    validationSchema = Joi.object({
        firstName: Joi.string().required().label('First Name'),
        lastName:Joi.string().required().label('Last Name'),
        email:Joi.string().email({tlds:false}).required().label('Email'),
        cellPhone:Joi.string().required().label('Cell Phone'),
        homePhone:Joi.string().allow(),
        pickUpAddress:Joi.object({
            isDormitory:Joi.string(),
            dormitoryName:Joi.string(),  
            dormitoryRoomNumber:Joi.string(),
            addressLine1:Joi.string(),
            addressLine2:Joi.string(),
            city:Joi.string().required().label('City'),
            state:Joi.string(),
            postalCode:Joi.string(),
            country:Joi.string(), 
        })   
    });
    */

   userSchema = Joi.object({
        firstName: Joi.string().required().label('First Name'),
        lastName:Joi.string().required().label('Last Name'),
        email:Joi.string().email({tlds:false}).required().label('Email'),
        cellPhone:Joi.string().required().label('Cell Phone'),
        homePhone:Joi.string().required().label('Home Phone'),
        studentId:Joi.any().allow(""),
        //pickUpAddress:Joi.any().optional(),
        
        /*
        pickUpAddress:Joi.object().keys({
                isDormitory:Joi.string(),
                dormitoryName: Joi.alternatives().conditional(
                    'isDormitory', { 
                        is: 'Yes', 
                        then: Joi.object().keys({
                            value:Joi.number().required(), 
                            label:Joi.any().optional(),
                        }), 
                        otherwise: Joi.any().optional()
                    }
                ),
                dormitoryRoomNumber: Joi.alternatives().conditional(
                    'isDormitory', { 
                        is: 'Yes', 
                        then: Joi.string().required(), 
                        otherwise: Joi.any().optional()
                    }
                ),
                addressLine1: Joi.alternatives().conditional(
                    'isDormitory', { is: 'No', then: Joi.string().required(),otherwise: Joi.any().optional() }
                ),
                addressLine2: Joi.any().optional(),
                city:Joi.string().required().label('City'),
                state:Joi.object().keys({
                    value:Joi.string().required(), 
                    label:Joi.any().optional(),
                }),
                postalCode:Joi.string().required().label('Postal Code'),
                country:Joi.string().required().label('Country'), 
        }) 
        */ 
        
        
    });

    pickupAddressSchema = Joi.object({
        isDormitory:Joi.string(),
        dormitoryName: Joi.alternatives().conditional(
            'isDormitory', { 
                is: 'Yes', 
                then: Joi.object().keys({
                    value:Joi.number().required(), 
                    label:Joi.any().optional(),
                }), 
                otherwise: Joi.any().optional()
            }
        ),
        dormitoryRoomNumber: Joi.alternatives().conditional(
            'isDormitory', { 
                is: 'Yes', 
                then: Joi.string().required(), 
                otherwise: Joi.any().optional()
            }
        ),
        streetNumber: Joi.alternatives().conditional(
            'isDormitory', { is: 'No', then: Joi.string().required().label('Street Number'),otherwise: Joi.any().optional() }
        ),
        addressLine1: Joi.alternatives().conditional(
            'isDormitory', { is: 'No', then: Joi.string().required().label('Address Line 1'),otherwise: Joi.any().optional() }
        ),
        addressLine2: Joi.any().optional(),
        apartmentNumber:Joi.any().optional(),
        zoneId: Joi.any().optional(),
        city:Joi.string().required().label('City'),
        state:Joi.object().keys({
            value:Joi.string().required(), 
            label:Joi.any().optional(),
        }),
        postalCode:Joi.string().required().label('Postal Code'),
        country:Joi.string().required().label('Country'), 
    }) ;

    /*Validation custom messages to map with Job error object */
    errorMessages = {
        
    }

    constructor(){
        super()
        this.state = {
            stateList:[{ value: "", label: "", isDisabled: true }],
            dormitoryList:[{ value: "", label: "", isDisabled: true }],
            dormitoryObjectList:"",
            data:{
                firstName:"",
                lastName:"",
                email:"",
                studentId:"",
                cellPhone:"",
                homePhone:"",
                pickUpAddress:{
                    isDormitory:"No",
                    dormitoryName:"",  //{value:"", label:""} this corresponds to the react-select value setup
                    dormitoryRoomNumber:"",
                    streetNumber:"", //Non dormitory street number
                    addressLine1:"",
                    addressLine2:"",
                    apartmentNumber:"", //Non dormitory apartment number
                    zoneId:"",
                    city:"",
                    state:"",
                    postalCode:"",
                    country:"USA", 

                },
            },
            errors:{}
        }
    }

    

    async componentDidMount(){
        
        try{
            console.log("StudentInformation Component did mount");
            document.getElementById("sectionArea").scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
            });
    
            
            const stateList = getUSStateList();
            //console.log(stateList);
            const {school} = this.props.user;
            //console.log(school);
            const dormitoryList = await SchoolService.getDropdownListOfDormitoryBySchool(school);
            const dormitoryObjectList = await SchoolService.getListOfDormitoryBySchool(school);
            
            this.setState({
                stateList,
                dormitoryList,
                dormitoryObjectList,
            });

            this.mapStudentModelToView();
        }
        catch(ex){
            console.log(`Component did mount on Laundry Student Info ${ex}`);
        }
        
        
        
    }

    componentDidUpdate(){
      //console.log("StudentInformation Component did update");
      //this.mapStudentModelToView();
    }

    handleChange = ({currentTarget:input})=>{
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[input.name] = input.value;
        this.setState({data,errors});
        
    }

    handleChangeAddress = (e,addressType)=>{
        const {name,value} = e.currentTarget;
        //console.log(`${name} : ${value}`);
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        
        if(name === "isDormitoryPickUpAddress")
            address.isDormitory = value 
        else
            address[name] = value;

        data[addressType] = address
        

        this.setState({data});
     }

    handleSubmit = async (e)=>{
       try{
           e.preventDefault();  

            //client side errors will be caught in validae(), server side data error will be caught via try/catch
            const errors = await this.validate();
            this.setState({errors: errors || {}});
            if(errors){scrollTop(); return;} 
            
            const {onClickOfNextButtonFromStudentInformation} = this.props;  
            console.log("Handle submit of student information");
            //console.log(this.state);
            const studentInformation = {...this.state.data};
            onClickOfNextButtonFromStudentInformation(studentInformation);
            return;
       }catch(ex){
            /*
              400 signals validation errors from the server
              renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop(); return;}
            }
            else
              renderError(ex);
       }
       
    }

    mapStudentModelToView = ()=>{
        /*
        const {firstName,lastName,email,cellPhone} = this.props.user;
        const data = {firstName,lastName,email,cellPhone};
        console.log(data);
        if(!_.isEqual(this.state.data,data))
           this.setState({data});
        */
        const stateList = getUSStateList();

        const {user} = this.props;
        const {firstName,lastName,email,cellPhone,homePhone,studentId,school} = user;
        
        //Get the city and state to plugin default values
        const {state:schoolDefinedState, web_city:cityDefinedState} = school.university;
        
        const {pickupAndDeliveryAddress:pickUpAddressUser} = user.laundry;


        //Set pickup address
        const pickUpAddress = {...pickUpAddressUser};
        if(pickUpAddress.isDormitory){
            pickUpAddress.isDormitory = "Yes";
            pickUpAddress.dormitoryName={
                value:pickUpAddress.dormitory.id,
                label:pickUpAddress.dormitory.name,
            };
            pickUpAddress.dormitoryRoomNumber = pickUpAddress.dormitory.roomNumber;
        }
        else{
            pickUpAddress.isDormitory = "No";
            pickUpAddress.dormitoryName="";
            pickUpAddress.dormitoryRoomNumber = "";
        }

        pickUpAddress.state = (pickUpAddressUser.state) ?
          _.find(stateList,{'value':pickUpAddressUser.state}) : _.find(stateList,{'value':schoolDefinedState});

        pickUpAddress.city = (pickUpAddressUser.city) ? pickUpAddressUser.city:cityDefinedState;
        //Doing this to prevent the react controlled state from going uncontrolled
        pickUpAddress.city = pickUpAddress.city || "";

        delete pickUpAddress.dormitory;
        //End of Set pickup address

        const data = {firstName,lastName,email,cellPhone,homePhone,studentId,pickUpAddress};
        
        //console.log(data);
        if(!_.isEqual(this.state.data,data))
            this.setState({data});
        
    }

    setAddressDormitory = (valueObject,addressType)=>{
        const {dormitoryObjectList,stateList} = this.state; 
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        address.dormitoryName = valueObject;

        //Get the dorm object by selected dorm name to populate the city state zip
        const selectedDormitoryObject = _.find(dormitoryObjectList,{"id":valueObject.value});
        //console.log(selectedDormitoryObject);
        
        const selectedStateObject = _.find(stateList,{"value":selectedDormitoryObject.state});
        
        address.city = selectedDormitoryObject.city;
        address.state = selectedStateObject;
        address.postalCode = selectedDormitoryObject.zip;
        
        data[addressType] = address;
        this.setState({data});
    }

    setAddressState = (value,addressType)=>{
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        address.state = value;
        data[addressType] = address;

        this.setState({data});

    }

    validate= async ()=>{
        
            const options = {abortEarly:false,allowUnknown:true};
            //console.log(this.state.data);
            const {email} = this.state.data
            let {error:primaryInformationError} = this.userSchema.validate(this.state.data,options);
            let {error:pickUpAddressError} = this.pickupAddressSchema.validate(this.state.data.pickUpAddress,options);
            
            //Validate if email exists in the system
            if(!primaryInformationError){
                const {data:returnStatus} = await http.post(`${apiUserEndpoint}/validateByEmail`,{email});
                if(returnStatus.status === false){
                    primaryInformationError = {
                        details:[
                            {message:"User with email address exist in the system",
                            path:["emailAddressExist"],
                            }
                        ]
                    }; 
                }
            }

            //console.log(primaryInformationError);
            //console.log(pickUpAddressError);
            

            if(primaryInformationError === undefined  && pickUpAddressError === undefined) return null;


            const primaryInformationErrors = {};
            if(primaryInformationError){
                primaryInformationError.details.map(
                    function(errorObject){
                        //console.log(errorObject);
                        if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                            const key = errorObject.context.key;
                            const joiType = errorObject.type;
                            //console.log(this.errorMessages);
                            if(_.has(this.errorMessages, [key, joiType]))
                                return primaryInformationErrors[errorObject.path[0]] = this.errorMessages[key][joiType];
                            else
                                return primaryInformationErrors[errorObject.path[0]] = errorObject.message;
                        }
                        else
                          return primaryInformationErrors[errorObject.path[0]] = errorObject.message;
                    }.bind(this)
                );
            }
            
            const pickUpAddressErrors = {};
            if(pickUpAddressError){
                pickUpAddressError.details.map(
                    function(errorObject){
                        //console.log(errorObject);
                        if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                            const key = errorObject.context.key;
                            const joiType = errorObject.type;
                            //console.log(this.errorMessages);
                            if(_.has(this.errorMessages, [key, joiType]))
                                return pickUpAddressErrors[errorObject.path[0]] = this.errorMessages[key][joiType];
                            else
                                return pickUpAddressErrors[errorObject.path[0]] = errorObject.message;
                        }
                        else
                          return pickUpAddressErrors[errorObject.path[0]] = errorObject.message;
                    }.bind(this)
                );
            }
            
            const errors = {...primaryInformationErrors,...pickUpAddressErrors} 
        
            console.log(errors);
            return Object.keys(errors).length > 0 ? errors:null;
        
        
        
        
    }

    render(){
        const {
            listOfSchoolsByRegion,
            onClickOfBackButtonFromStudentInformation,
            onClickOfNextButtonFromStudentInformation,
            user
        } = this.props;
        const {type:laundryType} = user.laundry;

        const {data,errors} = this.state;
        const {pickUpAddress} = data;
        
        const pickUpAddressIsDormitoryCheckedYes = (pickUpAddress.isDormitory === "Yes") ? {defaultChecked:true}:{};
        const pickUpAddressIsDormitoryCheckedNo = (pickUpAddress.isDormitory === "No") ? {defaultChecked:true}:{};
        
       
        return(
            <Container id="mainContentArea">
            <Row className="title-row justify-content-between">
                <Col md="6">
                <h3 className="shop">Signup for Laundry - Student</h3>
                </Col>
                <Col md="4">
                    <div className="text-md-right d-none">
                        <span className="text-muted">Step 2</span>
                        <Button color="link">
                        <i className="fa fa-shopping-cart" /> Enter your information
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row className="title-body-row">
            <Col md="8">
                    <Card>
                        <CardBody>
                            {/*Error display */}
                            {!_.isEmpty(errors) &&
                                <FormErrorWidget
                                  errors={errors}
                                />
                            }
                            {/* End of Error display */}
                            <Form>
                                <FormGroup row>
                                <Label for="firsName" sm={2}>Name</Label>
                                <Col xs={6} sm={5}>
                                    <Input 
                                      type="text" 
                                      name="firstName" 
                                      id="firstName" 
                                      placeholder="First Name" 
                                      value={this.state.data.firstName} 
                                      onChange={this.handleChange}
                                    />
                                </Col>
                                <Col xs={6} sm={5}>
                                    <Input 
                                       type="text" 
                                       name="lastName" 
                                       id="firstName" 
                                       placeholder="Last Name" 
                                       value={this.state.data.lastName} 
                                       onChange={this.handleChange}
                                    />
                                </Col>
                                </FormGroup>
                                
                                <FormGroup row>
                                <Label for="email" sm={2}>Email</Label>
                                <Col sm={10}>
                                    <Input 
                                        type="email" 
                                        name="email" 
                                        id="email" 
                                        placeholder="Email address" 
                                        value={this.state.data.email} 
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                </FormGroup>

                                <FormGroup row>
                                <Label for="cellPhone" sm={2}>Cell Phone</Label>
                                <Col sm={10}>
                                    <Input 
                                        type="text" 
                                        name="cellPhone" 
                                        id="cellPhone" 
                                        placeholder="" 
                                        value={this.state.data.cellPhone} 
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                </FormGroup>

                                <FormGroup row>
                                <Label for="cellPhone" sm={2}>Home Phone</Label>
                                <Col sm={10}>
                                    <Input 
                                        type="text" 
                                        name="homePhone" 
                                        id="homePhone" 
                                        placeholder="" 
                                        maxLength="20"
                                        value={this.state.data.homePhone} 
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                </FormGroup>

                                <FormGroup row>
                                <Label for="studentId" sm={2}>Student ID</Label>
                                <Col sm={10}>
                                    <Input 
                                        type="text" 
                                        name="studentId" 
                                        id="studentId" 
                                        maxLength="16"
                                        placeholder="" 
                                        value={this.state.data.studentId} 
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                </FormGroup>

                                <h6 className="card-category text-left mt-2">Campus (Pickup) Address</h6>
                                <hr/>

                                <FormGroup row>
                                    <Label for="" xs={6} sm={4}>Do you live in a dorm?</Label>
                                    <Col xs={2} sm={1} className="mt-1">
                                        <div className="form-check-radio">
                                            <Label check>
                                                <Input
                                                    {...pickUpAddressIsDormitoryCheckedYes}
                                                    defaultValue="Yes"
                                                    id="pickUpAddressIsDormitory1"
                                                    name="isDormitoryPickUpAddress"
                                                    type="radio"
                                                    onChange={e=>{this.handleChangeAddress(e,"pickUpAddress")}}
                                                />
                                                Yes
                                                <span className="form-check-sign" />
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col xs={2} sm={1} className="mt-1 ml-2">
                                        <div className="form-check-radio">
                                            <Label check>
                                                <Input
                                                    {...pickUpAddressIsDormitoryCheckedNo}
                                                    defaultValue="No"
                                                    id="pickUpAddressIsDormitory2"
                                                    name="isDormitoryPickUpAddress"
                                                    type="radio"
                                                    onChange={e=>{this.handleChangeAddress(e,"pickUpAddress")}}
                                                />
                                                No
                                                <span className="form-check-sign" />
                                            </Label>
                                        </div>
                                    </Col>
                                </FormGroup>

                                {
                                    (pickUpAddress.isDormitory === "Yes") &&    
                                    <FormGroup row>
                                        <Label for="pickUpDormitory" sm={2}>Dorm Name</Label>
                                        <Col xs={10} sm={4}>
                                            <FormGroup>
                                                <Select
                                                    className="react-select react-select-default"
                                                    classNamePrefix="react-select"
                                                    name="pickUpAddressDormitory"
                                                    id="pickUpAddresDormitory"
                                                    value={this.state.data.pickUpAddress.dormitoryName}
                                                    onChange={value => this.setAddressDormitory(value,"pickUpAddress")}
                                                    options={this.state.dormitoryList}
                                                    placeholder="Dorm"
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Label for="addressLine1" sm={2}>Room No.</Label>
                                        <Col xs={10} sm={4}>
                                            <Input 
                                            type="text" 
                                            name="dormitoryRoomNumber" 
                                            id="dormitoryRoomNumber" 
                                            placeholder="Room No"
                                            maxLength="10" 
                                            value={this.state.data.pickUpAddress.dormitoryRoomNumber} 
                                            onChange={e=>{this.handleChangeAddress(e,"pickUpAddress")}}
                                            />
                                            
                                        </Col>
                                    </FormGroup>
                                }

                                {
                                    (pickUpAddress.isDormitory !== "Yes") &&   
                                    <FormGroup row>
                                        <Label for="streetNumber" sm={2}>Street #</Label>
                                        <Col sm={4}>
                                            <Input 
                                            type="text" 
                                            name="streetNumber" 
                                            id="streetNumber" 
                                            placeholder="Street #" 
                                            maxLength="6"
                                            value={this.state.data.pickUpAddress.streetNumber} 
                                            onChange={e=>{this.handleChangeAddress(e,"pickUpAddress")}}
                                            />
                                        </Col>

                                        <Label for="apartmentNumber" sm={2}>Apt #</Label>
                                        <Col sm={4}>
                                            <Input 
                                            type="text" 
                                            name="apartmentNumber" 
                                            id="apartmentNumber" 
                                            placeholder="Apt Number" 
                                            maxLength="10"
                                            value={this.state.data.pickUpAddress.apartmentNumber} 
                                            onChange={e=>{this.handleChangeAddress(e,"pickUpAddress")}}
                                            />
                                        </Col>
                                        
                                    </FormGroup>
                                }
                                
                                {
                                    (pickUpAddress.isDormitory !== "Yes") &&   
                                    <FormGroup row>
                                        <Label for="addressLine1" sm={2}>Street Name</Label>
                                        <Col sm={10}>
                                            <Input 
                                            type="text" 
                                            name="addressLine1" 
                                            id="addressLine1" 
                                            placeholder="Street Name" 
                                            value={this.state.data.pickUpAddress.addressLine1} 
                                            onChange={e=>{this.handleChangeAddress(e,"pickUpAddress")}}
                                            />
                                        </Col>
                                        
                                    </FormGroup>
                                }

                                

                                {
                                    (pickUpAddress.isDormitory !== "Yes") &&  
                                    <FormGroup row className="d-none">
                                        <Label for="addressLine2" sm={2}>Address Line 2</Label>
                                        <Col sm={10}>
                                            <Input 
                                            type="text" 
                                            name="addressLine2" 
                                            id="addressLine2" 
                                            placeholder="Address Line 2" 
                                            value={this.state.data.pickUpAddress.addressLine2} 
                                            onChange={e=>{this.handleChangeAddress(e,"pickUpAddress")}}
                                            />
                                        </Col>
                                        
                                    </FormGroup>
                                }    
                                
                                
                                <FormGroup row>
                                    <Label for="city" sm={2}></Label>
                                    <Col xs={12} sm={3}>
                                        <Input 
                                        type="text" 
                                        name="city" 
                                        id="city" 
                                        placeholder="City" 
                                        disabled= {(pickUpAddress.isDormitory === "Yes") ? true:false}
                                        value={this.state.data.pickUpAddress.city} 
                                        onChange={e=>{this.handleChangeAddress(e,"pickUpAddress")}}
                                        />
                                    </Col>
                                    <Label for="state" xs={12} sm={2} className="d-block d-sm-none"></Label>
                                    <Col xs={12} sm={4}>
                                        <FormGroup>
                                            <Select
                                                className="react-select react-select-default"
                                                classNamePrefix="react-select"
                                                name="state"
                                                id="state"
                                                isDisabled={true}
                                                value={this.state.data.pickUpAddress.state}
                                                onChange={value => this.setAddressState(value,"pickUpAddress")}
                                                options={this.state.stateList}
                                                placeholder="State"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={3}>
                                        <Input 
                                        type="text" 
                                        name="postalCode" 
                                        id="postalCode" 
                                        placeholder="Zip" 
                                        maxLength="5"
                                        value={this.state.data.pickUpAddress.postalCode} 
                                        onChange={e=>{this.handleChangeAddress(e,"pickUpAddress")}}
                                        />
                                    </Col>
                                </FormGroup>
                                
                                <FormGroup row  className="mt-5 justify-content-between">
                                <Col xs={{ size: 2}}>
                                    <Button 
                                      className="btn-rounded btn-danger"
                                      onClick={()=>onClickOfBackButtonFromStudentInformation()}
                                    >
                                        Back
                                    </Button>
                                </Col>
                                <Col xs={{ size: 6}} sm={{ size: 4}} className="text-right">
                                    <Button 
                                       type="submit"
                                       className="btn-rounded btn-danger"
                                       onClick={this.handleSubmit}
                                    >
                                       Save & Proceed
                                    </Button>
                                </Col>
                                </FormGroup>
                            </Form>                      
                        </CardBody>
                    </Card> 
            </Col>
            <Col md="4" className="d-none d-md-block">
                        {
                            laundryType === "student" && <StudentLaundryRegistrationStepsDisplayWidget />
                        }
                        {
                            laundryType === "residential" && <ResidentialLaundryRegistrationStepsDisplayWidget />
                        }
                        
            </Col>    
            
            </Row>    
            
                        
            
        </Container>      
        );
    }
} 
  


export default StudentInformation