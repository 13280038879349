import React,{Component} from "react";
import moment from "moment";
import Select from "react-select";
import ReCAPTCHA from "react-google-recaptcha";
import _ from "lodash";
import Joi from "@hapi/joi";
import ReactDatetime from "react-datetime";

import {Elements,injectStripe} from 'react-stripe-elements';

import FormErrorWidget from "../../Common/FormErrorWidget";
import {scrollTop} from '../../../utils/helperUtils';
import {renderError} from '../../../utils/errorUtils';
import customToast from '../../Common/CustomToast'

import ProcessingModal from "../../Common/ProcessingModal";

import StripePaymentWidget from "../../Common/StripePaymentWidget";

import UserService from "../../../services/userService";


// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Media,
    NavItem,
    NavLink,
    Nav,
    Container,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Label,
    Row,
    Col,
    Input, 
  } from "reactstrap";

class PurchaseForm extends Component{
    defaultStripeUniversityId = 1
    divAreaToScrollForError = "description-areas"
    minimumAmountToPurchase  = 20;

    validationSchema = Joi.object({
        stripeMerchantAccountUniverityId: Joi.number().required().label('Merchant account ID'),
        universityId: Joi.any().optional(),
        amount: Joi.number().min(this.minimumAmountToPurchase).required().label('Gift Card Amount'),
        purchaserName: Joi.string().required().label('Purchaser Name'),
        purchaserEmail:Joi.string().email({tlds:false}).required().label('Purchaser Email'),
        giftRecipientName: Joi.string().required().label('Recipients Name'),
        giftRecipientEmail:Joi.string().email({tlds:false}).required().label('Recipients Email'),
        giftRecipientCustomNote:Joi.any().optional(),
        giftCertificateShippingDate:Joi.date().required().label('Enter a date the Giftee receives the Gift certificate'),
        isStudent:Joi.any().optional(),
        school:Joi.any().optional(),
        creditCardName:Joi.string().required().label('Credit Card Holders Name'),
        recaptchaResponse:Joi.string().required().label("Recaptcha"),
        
    });

    billingAddressSchema = Joi.object({
        addressLine1: Joi.string().required().label('Billing Address Line 1'),
        addressLine2: Joi.any().optional(),
        city:Joi.string().required().label('City'),
        state:Joi.object().keys({
            value:Joi.string().required(), 
            label:Joi.any().optional(),
        }),
        postalCode:Joi.string().required().label('Postal Code'),
        country:Joi.object().keys({
            value:Joi.string().required(), 
            label:Joi.any().optional(),
        }), 
    }) ;

    /*Validation custom messages to map with Job error object */
    errorMessages = {
        "state":{
            "object.base":"Billing State is not allowed to be empty",
        },
        "country":{
            "object.base":"Billing Country is not allowed to be empty",
        },
    }

    constructor(){
        super()
        this.state={
            processingModalDisplayFlag:false,
            schoolDbList:"", //This holds the campus data from the database
            schoolList:[{ value: "", label: "", isDisabled: true }],
            data:{
                stripeMerchantAccountUniverityId:this.defaultStripeUniversityId,
                universityId:"",
                recaptchaResponse:"",
                amount:"",
                purchaserName:"",
                purchaserEmail:"",
                giftRecipientName:"",
                giftRecipientEmail:"",
                giftRecipientCustomNote:"",
                giftCertificateShippingDate:"",
                isStudent:"",
                school:"",
                creditCardName:"",
                billingAddress:{
                    addressLine1:"",
                    addressLine2:"",
                    city:"",
                    state:"",
                    postalCode:"",
                    country:{label:"United States of America",value:"US"},
                },
            },
            errors:{},
        }
    }

    async componentDidMount(){
        try{
            const {
                schoolOption,
                listOfSchools,
            } = await UserService.getListOfSchoolsForGiftCard();

            this.setState({
                schoolDbList:listOfSchools,
                schoolList:schoolOption,
            })
        }
        catch(ex){
            /*
            400 signals validation errors from the server
            renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop('description-areas'); return;}
            }
            else
                renderError(ex);
        } 
    }
    
    componentDidUpdate(prevProps,prevState){
        const {formData} = this.props;
        const {data:formDataState} = prevState;
        if(formDataState.universityId !== formData.universityId){
            this.setState({
                data:formData
            })
        }
    }

    handleChange = ({currentTarget:input})=>{
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[input.name] = input.value;
        this.setState({data,errors});
        
    }

    handleChangeAddress = (e,addressType)=>{
        const {name,value} = e.currentTarget;
        //console.log(`${name} : ${value}`);
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        
        address[name] = value;
        data[addressType] = address;
        this.setState({data});
        
    }

    handleChangeRecatcha = (value)=> {
        console.log("Captcha value:", value);
        const data  = {...this.state.data};
        data.recaptchaResponse = value;
        this.setState({data})
    }

    handleSelectSchool =  schoolOption => {
        const {handleChangeOfUniversity} = this.props
        const {schoolDbList} = this.state;
        const data = {...this.state.data};
        const isStudent = (schoolOption.value !== "" && schoolOption.value !== "none") ? true:false;
        data.isStudent =  isStudent;
        data.school = schoolOption;
        
        /*Get the universityId from the campus list */
        if(isStudent){
            const selectedSchoolObject  = _.find(schoolDbList,{"campus_id":schoolOption.value});
            data.school = schoolOption;
            data.universityId = selectedSchoolObject.university_id;
            data.stripeMerchantAccountUniverityId = selectedSchoolObject.university_id; 
            
            /*
              On change of the university I am sending the form data we need to swap out the stripe provider key
              I am copying the data back to the state using the componentDidUpdate();
            */  
            handleChangeOfUniversity(selectedSchoolObject.university_id,data);
        }
        else{
            data.universityId = this.defaultStripeUniversityId;
            data.stripeMerchantAccountUniverityId = this.defaultStripeUniversityId;
            
            /*
              On change of the university I am sending the form data we need to swap out the stripe provider key
              I am copying the data back to the state using the componentDidUpdate();
            */
            handleChangeOfUniversity(this.defaultStripeUniversityId,data)
        }
        
        //this.setState({ data });
    }

    handleShippingDate = (value)=>{
       this.setState({
           data:{
               ...this.state.data,
               giftCertificateShippingDate:moment(value).format(`MM/DD/YYYY`),
           }
       })
    }

    handleSubmit = async (e)=>{
        e.preventDefault();
        let errors = {};
        try{
            //console.log(this.state.data);
            
            const {stripeMerchantAccountUniverityId} = this.props;
            this.setState({errors});

            errors = this.validate();
            if(errors){
                console.log(errors);
                this.setState({errors});
                scrollTop(this.divAreaToScrollForError); return;
            } 
            

            this.setProcessingModalDisplayFlag(true);
            const formDataObject = {...this.state.data};
            
            //bind the strip univserity id
            formDataObject.stripeMerchantAccountUniverityId = stripeMerchantAccountUniverityId;

            const  {addressLine1:address_line1,
                addressLine2:address_line2,
                city:address_city,
                state,
                postalCode:address_zip,
                country} = formDataObject.billingAddress;
        

            /*Validate data */

            /*Endn of Validate data */

            
            /*Create stripe token */
                let stripeResult = "";
                //console.log(this.props.stripe);
                if (this.props.stripe) {
                    stripeResult = await this.props.stripe.createToken(
                        {
                            type: 'card', 
                            name: formDataObject.creditCardName,
                            address_line1,
                            address_line2,
                            address_city,
                            address_state:state.value,
                            address_zip,
                            address_country:country.value,
                        }
                    );
                    //console.log(stripeResult);
                    if(stripeResult.error){
                        errors = (!_.isEmpty(errors)) ? errors : {};
                        errors["invalidCard"] = stripeResult.error.message;
                    }
                    else
                        formDataObject.creditCardStripeToken = stripeResult.token
                } 
                //console.log(errors);
                this.setState({errors: errors || {}});
                if(!_.isEmpty(errors)){
                    this.setProcessingModalDisplayFlag(false);
                    scrollTop(this.divAreaToScrollForError)
                    return;
                }
            /* End of stripe token creation setup */
            
            /* Send data to server */
                const {status,message,errorType} = await UserService.purchaseGiftCard(formDataObject);
                
                this.setProcessingModalDisplayFlag(false);
                if(status === true){
                    //Clear the form data
                    
                    //We need to redirect user to a confirmation page
                    this.props.history.replace(`/gift-cards/confirmation`); 
                }
                else{
                    //Handle errors from the server
                    let [errorMessage] = [""];
                    errorMessage = message; 
                     
                    
                    errors = {message:errorMessage};
                    this.setState({errors: errors || {}});
                    setTimeout(()=>{
                        scrollTop(this.divAreaToScrollForError)
                    },1000)
                    //return;
                }
            /* End of Send data to server */
            
        }
        catch(ex){
            this.setProcessingModalDisplayFlag(false);
            /*
            400 signals validation errors from the server
            renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop(this.divAreaToScrollForError); return;}
            }
            else
                renderError(ex);
        }  
        
    }

    setAddressState = (value,addressType)=>{
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        address.state = value;
        data[addressType] = address;

        this.setState({data});

    }


    setCountry = (value)=>{
        const addressType = "billingAddress";
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        address.country = value;
        data[addressType] = address;

        this.setState({data});

    }

    setProcessingModalDisplayFlag = flag=>{
        this.setState({processingModalDisplayFlag:flag})
    }

    validate=  ()=>{
            
        const options = {abortEarly:false,allowUnknown:true};
        //console.log(this.state.data);
        //console.log(this.validationSchema.validate(this.state.data,options))
        let {error:errorListFromJoi} = this.validationSchema.validate(this.state.data,options);
        //console.log(errorListFromJoi.details);
        if( errorListFromJoi === undefined) return null;

        const errorList = {};
        if(errorListFromJoi){
            errorListFromJoi.details.map(
                function(errorObject){
                    //console.log(errorObject);
                    if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                        const key = errorObject.context.key;
                        const joiType = errorObject.type;
                        //console.log(this.errorMessages);
                        if(_.has(this.errorMessages, [key, joiType]))
                            return errorList[errorObject.path[0]] = this.errorMessages[key][joiType];
                        else
                            return errorList[errorObject.path[0]] = errorObject.message;
                    }
                    else
                    return errorList[errorObject.path[0]] = errorObject.message;
                }.bind(this)
            );
        }

        let {error:billingAddressError} = this.billingAddressSchema.validate(this.state.data.billingAddress,options);
        
        const billingAddressErrors = {};
        if(billingAddressError){
            billingAddressError.details.map(
                function(errorObject){
                    const addressKey = "billingAddressAddress";
                    const addressLabel = "Billing Address: ";
                    //console.log(errorObject);
                    if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                        const key = errorObject.context.key;
                        const joiType = errorObject.type;
                        //console.log(this.errorMessages);
                        if(_.has(this.errorMessages, [key, joiType]))
                            return billingAddressErrors[`${addressKey}.${errorObject.path[0]}`] = addressLabel + this.errorMessages[key][joiType];
                        else
                            return billingAddressErrors[`${addressKey}.${errorObject.path[0]}`] = addressLabel + errorObject.message;
                    }
                    else
                    return billingAddressErrors[`${addressKey}.${errorObject.path[0]}`] = addressLabel + errorObject.message;
                }.bind(this)
            );
        }
        
        const errors = {...errorList,...billingAddressErrors} 
        //console.log(errors);
        return Object.keys(errors).length > 0 ? errors:null;
    }

    render(){
        const {
            processingModalDisplayFlag,
            schoolList,
            data,
            errors
        } = this.state;
        
        const {
            amount,
            purchaserName,
            purchaserEmail,
            giftRecipientName,
            giftRecipientEmail,
            giftRecipientCustomNote,
            giftCertificateShippingDate,
        } = data;

        return(
            <>
                {/*Error display */}
                {!_.isEmpty(errors) &&
                    <FormErrorWidget
                        errors={errors}
                    />
                }
                {/* End of Error display */}

                <Form>
                    <h6 className="card-category text-left">Amount of this Gift Voucher</h6>
                    <hr/>

                    <FormGroup row>
                        <Label for="amount" sm={2}>Amount *</Label>
                        <Col sm={4}>
                            <Input 
                                type="text" 
                                name="amount" 
                                id="amount" 
                                placeholder="" 
                                value={amount} 
                                onChange={this.handleChange}
                            />
                        </Col>
                    </FormGroup>
                    

                    <h6 className="card-category text-left">Gift Giver Info (you)</h6>
                    <hr/>
                    <Row>
                        <Col xs="12" md="10">
                            <FormGroup>
                                <label>Your name (as you'd like it to appear on the Gift Certificate): *</label>
                                <Input
                                    className="border-input"
                                    placeholder="Name"
                                    type="text"
                                    name="purchaserName" 
                                    id="purchaserName" 
                                    value={purchaserName} 
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" md="10">
                            <FormGroup>
                                <label>Your email address: *</label>
                                <Input
                                    className="border-input"
                                    placeholder="Email"
                                    type="email"
                                    name="purchaserEmail" 
                                    id="purchaserEmail" 
                                    value={purchaserEmail} 
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <h6 className="card-category text-left">Gift Receiver Info (the lucky someone)</h6>
                    <hr/>
                    
                    <Row>
                        <Col xs="12" md="10">
                            <FormGroup>
                                <label>Name of person receiving the gift: *</label>
                                <Input
                                    className="border-input"
                                    placeholder="Name"
                                    type="text"
                                    name="giftRecipientName" 
                                    id="giftRecipientName" 
                                    value={giftRecipientName} 
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12" md="10">
                            <FormGroup>
                                <label>
                                    Email address of Gift Receiver: *
                                </label>
                                <Input
                                    className="border-input"
                                    placeholder="Email"
                                    type="email"
                                    name="giftRecipientEmail" 
                                    id="giftRecipientEmail" 
                                    value={giftRecipientEmail} 
                                    onChange={this.handleChange}
                                />
                                <small>
                                    <span>(leave blank if you do not want us to email the gift receiver directly)</span>
                                </small>
                                
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12" md="10">
                        <FormGroup>
                            <label>
                                Select a date for the giftee to receive the gift via email: *
                            </label>
                            <InputGroup 
                                name="giftCertificateShippingDate"
                                className="date" 
                                id="giftCertificateShippingDate"
                                
                            >
                                <ReactDatetime
                                    value={giftCertificateShippingDate} 
                                    timeFormat={false}
                                    closeOnSelect={true}
                                    onChange={this.handleShippingDate}
                                    inputProps={{
                                        className: "form-control",
                                        placeholder: "",
                                        autoComplete:"off",
                                    }}

                                    isValidDate={currentDate=>{
                                        let enableDate = true;
                                        enableDate = (moment(currentDate).isSameOrAfter(moment(),'day')) ? true:false;
                                        return enableDate;
                                    }}
                                />
                                <InputGroupAddon addonType="append">
                                <InputGroupText>
                                    <span className="glyphicon glyphicon-calendar">
                                    <i className="fa fa-calendar" />
                                    </span>
                                </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12" md="10">
                            <FormGroup>
                                <label>
                                    Select a school if the gift recipient is a student (If applicable): 
                                </label>
                                <Select
                                    className="react-select react-select-default"
                                    classNamePrefix="react-select"
                                    name="schoolId"
                                    id="schoolId"
                                    value={this.state.data.school}
                                    onChange={value => this.handleSelectSchool(value)}
                                    options={schoolList}
                                    placeholder="Select School"
                                />
                                                
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12" md="10">
                            <FormGroup>
                                <label>Message (optional):</label>
                                <Input
                                className="textarea-limited"
                                type="textarea"
                                maxLength="200"
                                rows="3"
                                name="giftRecipientCustomNote" 
                                id="giftRecipientCustomNote" 
                                value={giftRecipientCustomNote} 
                                onChange={this.handleChange}
                                />
                                <h5>
                                <small>
                                    <span
                                    className="pull-right"
                                    id="textarea-limited-message"
                                    >
                                    200 characters
                                    </span>
                                </small>
                                </h5>
                            </FormGroup>
                        </Col>
                    </Row>


                    
                        <StripePaymentWidget
                        data={data}
                        handleChange={this.handleChange}
                        handleChangeAddress = {this.handleChangeAddress}
                        setAddressState={this.setAddressState}
                        setCountry={this.setCountry}
                        />
                    
                    <Row>
                        <Col xs="12" md="10">
                            <ReCAPTCHA
                                sitekey={process.env[`REACT_APP_GOOGLE_RECAPTCHA_CLIENT_KEY`]}
                                onChange={this.handleChangeRecatcha}
                            />
                        </Col>
                    </Row>

                    <FormGroup row  className="mt-5 justify-content-between">
                        <Col xs={{ size: 2}}>
                            
                        </Col>
                        <Col xs={{ size: 6}} sm={{ size: 4}} className="text-right">
                            <Button 
                                type="submit"
                                className="btn-round btn btn-danger"
                                onClick={this.handleSubmit}
                            >
                                Order my Gift!
                            </Button>
                        </Col>
                    </FormGroup>
                    
                </Form>

                {/* Set Processing modal */}
                <ProcessingModal
                    displayModal={processingModalDisplayFlag}
                    handleCloseOfModalWindow={this.setProcessingModalDisplayFlag}
                />
                {/* End of Processing modal */}

            </>
        )
    }
}  

export default injectStripe(PurchaseForm);