/*
Reusable component
- Final save gets triggered in the parent component
*/
import React,{Component} from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Joi from "@hapi/joi"
import Select from "react-select";


import http             from "../../../../../../services/httpService";
import {getUSStateList} from "../../../../../../services/dropDownListsService";
import {getCountryList} from "../../../../../../services/dropDownListsService";
import UserService      from "../../../../../../services/userService";
import SchoolService      from "../../../../../../services/schoolService";

import BaseForm from "../../../../../BaseClass/Form"
import FormErrorWidget from "../../../../../Common/FormErrorWidget";

import {scrollTop}     from '../../../../../../utils/helperUtils';
import {renderError}   from '../../../../../../utils/errorUtils';
import customToast     from '../../../../../Common/CustomToast';


// reactstrap components
import {
    Badge,
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Form,
    FormGroup, 
    Label, 
    Input, 
    InputGroupAddon,
    InputGroupText,
    InputGroup,
} from "reactstrap";

class CustomerPrimaryInformation extends BaseForm{
    divAreaToScrollForError = "description-areas"

    validationSchema = Joi.object({
        updateInformationFlag: Joi.any().optional(),
        firstName:Joi.string().required().label('First Name'),
        lastName:Joi.string().required().label('Last Name'), 
        cellPhone:Joi.string().required().label('Cell Phone'),
        email:Joi.string().required().label('Email Address'),
    }) ;

    errorMessages = {}
    
    constructor(){
        super();
        this.state = {
            data:{
                updateInformationFlag:false,
                firstName:"",
                lastName:"",  
                cellPhone:"",
                email:"", 
            },
            errors:{},
        }
    }

    componentDidMount(){
        this.mapModelToView();
    }

    componentDidUpdate(prevProps){
        const {user} = this.props;
        const {user:previousUser} = prevProps;
        if(!_.isEqual(previousUser.primaryInformation,user.primaryInformation)){
            this.mapModelToView();
        }
            
        
    }

    getPrimaryInformationForDisplay = ()=>{
        let displayAddress  = "";
        const {user} = this.props;
        const {primaryInformation} = user;
        const {
            firstName,
            lastName,
            cellPhone,
            email,
        } = primaryInformation;

        if(primaryInformation !== ""){
            return <>
               <div className="mb-2">
                Name: 
                <span className="ml-2">{`${firstName} ${lastName}`}</span>
               </div> 
               <div  className="mb-2">
                Email: 
                <span  className="ml-2">{`${email}`}</span>
               </div> 
               <div  className="mb-2">
                CellPhone: 
                <span  className="ml-2">{`${cellPhone}`}</span>
               </div> 
            </>
        }

        return displayAddress
    }

    mapModelToView = async ()=>{
        
        try{
            const {user} = this.props;
            const {primaryInformation} = user;
            const {
                firstName,
                lastName,  
                cellPhone,
                email, 
            } = primaryInformation
            
            const data = {
                ...this.state.data,
                updateInformationFlag:false,
                firstName,
                lastName,  
                cellPhone,
                email,
            }

            this.setState({
                data,
            });
        }
        catch(ex){
            console.log(`Component did mount  ${ex}`);
        }

          
        
    }

    
    setUpdateInformationFlag = (updateInformationFlag)=>{
        this.setState({
            data:{
                ...this.state.data,
                updateInformationFlag,
            }
        })
    }

    //This gets executed once the parent submission process is executed
    submitLocal = async ()=>{
        const {
            handleOnSaveOfCustomerInformation,
            handleDisplayOfProcessingModal,
            user,
        } = this.props;

        const {credentialInformation,primaryInformation} = user;
        const {username:usernameDb,} = credentialInformation;
        const { email:emailDb } = primaryInformation
        
        //v1.2.19: If users email and username matches then we update the username if they do update email
        const updateUsernameFlag = (usernameDb  === emailDb ) ? true:false

        try{
            
            handleDisplayOfProcessingModal(true);
            const formData = {
                ...this.state.data,
                username: (updateUsernameFlag === true) ? this.state.data.email:usernameDb,
            };
            
            const {status,message,errorObject} = await UserService.viewProfileSavePrimaryInformation(formData);

            if(status === true){
                handleDisplayOfProcessingModal(false);
                
                this.setState({
                    data:{
                        ...this.state.data,
                        updateInformationFlag:false,
                    } 
                })
                
                customToast.success(message); 
                //Go back to the parent view for information reload
                return handleOnSaveOfCustomerInformation();
            }
        } 
        catch(ex){
            handleDisplayOfProcessingModal(false);
            /*
            400 signals validation errors from the server
            renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop(this.divAreaToScrollForError); return;}
            }
            else
                renderError(ex);
        } 

        
    }

    validateLocal= ()=>{
        //This is anything specific to the form like async validations
        
    }

    render(){
        const {
            user,
            customerPrimaryInfoDisplayProperties,
            displaySaveAndBackControlsForStepProcess,
            displaySaveAndCancelControlForNonStepProcess
        } = this.props;

        
        const { 
            currentAddressTitle, 
            displayHeader,
            headerTitle,
            stepTitle,
            step, 
        } = customerPrimaryInfoDisplayProperties;

        
        const {dormitoryList,data,errors,stateList,} = this.state;
        const currentPrimaryInformation = this.getPrimaryInformationForDisplay();

        const   {
            updateInformationFlag,
        } = data;

        
        return(
            <Container id="mainContentArea">
                {
                    displayHeader && 
                    <Row className="title-row justify-content-between">
                        <Col lg="5">
                            <h3 className="shop">{headerTitle}</h3>
                        </Col>
                        <Col lg="6">
                            <div className="text-lg-right">
                                <span className="text-muted">{step}</span>
                                <Button color="link">
                                <i className="fa fa-shopping-cart" /> {stepTitle}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                }
                 
                <Row className="title-body-row">
                    <Col>
                        <Card className="card-plain bg-white p-2 rounded-lg">
                            <CardBody>
                                {/*Error display */}
                                {!_.isEmpty(errors) &&
                                    <FormErrorWidget
                                    errors={errors}
                                    />
                                }
                                {/* End of Error display */}

                                
                                <div className="mt-2 mb-4">
                                    <h6 className="card-category">
                                        <span className="text-left">
                                        {currentAddressTitle}
                                        </span>
                                    </h6>
                                    <hr/>
                                    <Row className="mt-2">
                                        <Col xs="8">
                                            {currentPrimaryInformation}
                                        </Col>
                                    </Row>

                                    {
                                        !updateInformationFlag && 
                                        <Row className="mt-2">
                                            <Col xs="8">
                                                <Button 
                                                    className="btn-round mr-1"
                                                    color="default"
                                                    outline
                                                    size="sm"
                                                    onClick={()=>this.setUpdateInformationFlag(true)}
                                                >
                                                Update Primary Information
                                                </Button>
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            
                                
                                {
                                    updateInformationFlag && 
                                    <Row>
                                        <Col>
                                            <h6>Update your primary information:</h6>

                                            <div className="mt-2">
                                                <FormGroup row>
                                                    <Label for="firsName" sm={2}>Name</Label>
                                                    <Col xs={6} sm={5}>
                                                        <Input 
                                                        type="text" 
                                                        name="firstName" 
                                                        id="firstName" 
                                                        placeholder="First Name" 
                                                        value={this.state.data.firstName} 
                                                        onChange={this.handleChange}
                                                        readOnly={true}
                                                        />
                                                    </Col>
                                                    <Col xs={6} sm={5}>
                                                        <Input 
                                                        type="text" 
                                                        name="lastName" 
                                                        id="firstName" 
                                                        placeholder="Last Name" 
                                                        value={this.state.data.lastName} 
                                                        onChange={this.handleChange}
                                                        readOnly={true}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                
                                                <FormGroup row>
                                                    <Label for="email" sm={2}>Email</Label>
                                                    <Col sm={10}>
                                                        <Input 
                                                            type="email" 
                                                            name="email" 
                                                            id="email" 
                                                            placeholder="Email address" 
                                                            value={this.state.data.email} 
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup row>
                                                    <Label for="cellPhone" sm={2}>Cell Phone</Label>
                                                    <Col sm={10}>
                                                        <Input 
                                                            type="text" 
                                                            name="cellPhone" 
                                                            id="cellPhone" 
                                                            placeholder="" 
                                                            value={this.state.data.cellPhone} 
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </div>
                                            

                                        </Col>
                                    </Row>  
                                
                            
                            
                                }
                                
                                
                                {/* This is used for step process like sign ups */}
                                {
                                    displaySaveAndBackControlsForStepProcess && 
                                    <FormGroup row className="mt-5 justify-content-between">
                                        <Col xs={{ size: 2 }}>
                                            <Button 
                                            className="btn-rounded btn-danger"
                                            onClick={()=>this.setUpdateInformationFlag(false)}
                                            >
                                            Back
                                            </Button>  
                                        </Col>
                                        <Col xs={{ size: 6}} sm={{ size: 4}} className="text-right">
                                            <Button 
                                            className="btn-rounded btn-danger"
                                            onClick={this.submitForm}
                                            >
                                            Save & Proceed
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                }

                                {/* This is used for updates to be handled on the same page */}
                                {
                                    displaySaveAndCancelControlForNonStepProcess && updateInformationFlag &&
                                    <FormGroup row className="mt-5 justify-content-between">
                                        <Col xs={{ size: 2 }}>
                                            <Button 
                                            className="btn-rounded btn-danger"
                                            onClick={()=>this.setUpdateInformationFlag(false)}
                                            >
                                            Cancel
                                            </Button>  
                                        </Col>
                                        <Col xs={{ size: 6}} sm={{ size: 4}} className="text-right">
                                            <Button 
                                            className="btn-rounded btn-danger"
                                            onClick={this.submitForm}
                                            >
                                            Save
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                }
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }


}

CustomerPrimaryInformation.propTypes = {
    user: PropTypes.object.isRequired,
    customerPrimaryInfoDisplayProperties: PropTypes.object.isRequired,
    displaySaveAndBackControlsForStepProcess:PropTypes.bool,
    displaySaveAndCancelControlForNonStepProcess:PropTypes.bool,
    
}

CustomerPrimaryInformation.defaultProps = {
    displaySaveAndBackControlsForStepProcess:false,     //This is used for step process like sign ups
    displaySaveAndCancelControlForNonStepProcess:true, //This is used for updates to be handled on the same page
}


export default CustomerPrimaryInformation;

