/*!

=========================================================
* Paper Kit PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-pro-react
* Copyright 2019 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route,Router, Redirect, Switch } from "react-router-dom";
//import ReactGA from 'react-ga';
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';
import { createBrowserHistory } from 'history';


import logger from './services/logService';
import auth from './services/authService';

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
import "assets/css/paperkit-dropdown-fix.css";
import "assets/css/mylazybones.css";
import "react-toastify/dist/ReactToastify.css";
//import {StripeProvider} from 'react-stripe-elements';



// pages
import MyApp from "./App";
// others

//Logger init
logger.init();

//Facebook Pixel setup
const pixelId = '738057776895273';
const advancedMatching = {}; 
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init(pixelId, advancedMatching, options);


//Google Analytics setup
const trackingId = process.env['REACT_APP_GA4_MEASUREMENT_ID']; 
ReactGA.initialize(trackingId);

const currentUser = auth.getCurrentUser();
if(currentUser !== null){
  const {userId} = currentUser;
  ReactGA.set({userId})
}

const history = createBrowserHistory();
history.listen(location => {
  //ReactGA.set({ page: location.pathname }); // Update the user's current page
  //ReactGA.pageview(location.pathname); // Record a pageview for the given page
  ReactPixel.pageView(); 
});

const App = () => (
  <Router history={history}>
    <MyApp />
  </Router>
);

ReactDOM.render(<App />, document.getElementById('root'));


//console.log(process.env.REACT_APP_API_URL);
//console.log(process.env.REACT_APP_STRIPE_PRODUCTION_KEY_UNIVERSITY_ID_1);
//console.log(process.env.HTTPS);
/*
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
*/
