import React,{Component} from "react";
import _ from "lodash";
import Select from "react-select";
import {getUSStateList} from "../../../../services/dropDownListsService";
import http from "services/httpService";

import Joi from "@hapi/joi";

import {renderError} from 'utils/errorUtils';
import {scrollTop} from 'utils/helperUtils';
import FormErrorWidget from "components/Common/FormErrorWidget";


// reactstrap components
import {
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    
  } from "reactstrap";


const apiUserEndpoint = "/users";

class ResidentialCustomerInformation extends Component{
    
    userSchema = Joi.object({
        firstName: Joi.string().required().label('First Name'),
        lastName:Joi.string().required().label('Last Name'),
        email:Joi.string().email({tlds:false}).required().label('Email'),
        cellPhone:Joi.string().required().label('Cell Phone'),
        homePhone:Joi.string().required().label('Home Phone'),
    });

    pickupAddressSchema = Joi.object({
        streetNumber: Joi.string().required().label('Street Number'),
        addressLine1: Joi.string().required().label('Address Line 1'),
        addressLine2: Joi.any().optional(),
        apartmentNumber:Joi.any().optional(),
        zoneId: Joi.any().optional(),
        city:Joi.string().required().label('City'),
        state:Joi.object().keys({
            value:Joi.string().required(), 
            label:Joi.any().optional(),
        }),
        postalCode:Joi.string().required().label('Postal Code'),
        country:Joi.string().required().label('Country'), 
    }) ;

    /*Validation custom messages to map with Job error object */
    errorMessages = {
        
    }

    constructor(){
        super();
        this.state = {
            stateList:[{ value: "", label: "", isDisabled: true }],
            data:{
                firstName:"",
                lastName:"",
                email:"",
                cellPhone:"",
                homePhone:"",
                address:{
                    streetNumber:"", //Non dormitory street number
                    apartmentNumber:"", //Non dormitory apartment number
                    addressLine1:"",
                    addressLine2:"",
                    zoneId:"",
                    city:"",
                    state:"",
                    postalCode:"",
                    country:"USA",
                }
            },
            errors:{

            }
        }
    }

    componentDidMount(){
        //console.log("ResidentialCustomerInformation Component did mount");
        document.getElementById("sectionArea").scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });

        this.mapModelToView();
        //console.log(getUSStateList());
        const stateList = getUSStateList();
        this.setState({stateList});

    }

    handleChange = (e)=>{
       const {name,value} = e.currentTarget;
       //console.log(`${name} : ${value}`);
       const data = {...this.state.data}
       data[name] = value;
       this.setState({data});
    }

    handleChangeAddress = (e)=>{
        const {name,value} = e.currentTarget;
        //console.log(`${name} : ${value}`);
        const data = {...this.state.data};
        const address = {...this.state.data.address}
        address[name] = value;
        data.address = address
        this.setState({data});
     }

    handleSubmit = async (e)=>{
        e.preventDefault();
        const {launchProcessingModal} = this.props;
            
        try{
            //client side errors will be caught in validae(), server side data error will be caught via try/catch
            //launchProcessingModal(true);
            const errors = await this.validate();
            //launchProcessingModal(false);
            this.setState({errors: errors || {}});
            if(errors){scrollTop("sectionArea"); return;} 


            const {data} = this.state;
            const {onClickOfNextButtonFromCustomerInformation} = this.props;
            onClickOfNextButtonFromCustomerInformation(data);
            return;
            
            

        }catch(ex){
            /*
              400 signals validation errors from the server
              renderError() basically will display server side error other than code 400
            */
            launchProcessingModal(false);
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop("sectionArea"); return;}
            }
            else
              renderError(ex);
        }

        
    }

    mapModelToView = ()=>{
        const stateList = getUSStateList();
        const {user} = this.props
        const {firstName,lastName,email,cellPhone,homePhone,residentialCity} = user;

        //Get the city and state to plugin default values
        let residentialDefinedState = "";
        if(residentialCity)
            ({state:residentialDefinedState} = residentialCity.university);
        

        const {pickupAndDeliveryAddress:pickUpAddressUser} = user.laundry;

        //Set pickup address
        const address = {...pickUpAddressUser};
        address.isDormitory = "";
        address.state = (address.state) ?
          _.find(stateList,{'value':address.state}) : _.find(stateList,{'value':residentialDefinedState});

        delete address.dormitory;
        

        const data = {firstName,lastName,email,cellPhone,homePhone,address};
        //console.log(data);
        if(!_.isEqual(this.state.data,data))
            this.setState({data});
        
    }

    setAddressState = (value)=>{
        const data = {...this.state.data};
        const address = {...this.state.data.address}
        address.state = value;
        data.address = address;
        this.setState({data});

    }

    validate= async ()=>{
        
        const options = {abortEarly:false,allowUnknown:true};
        //console.log(this.state.data);
        const {email} = this.state.data
        let {error:primaryInformationError} = this.userSchema.validate(this.state.data,options);
        let {error:pickUpAddressError} = this.pickupAddressSchema.validate(this.state.data.address,options);
        
        //Validate if email exists in the system
        if(!primaryInformationError){
            const {data:returnStatus} = await http.post(`${apiUserEndpoint}/validateByEmail`,{email});
            if(returnStatus.status === false){
                primaryInformationError = {
                    details:[
                        {message:"User with email address exist in the system",
                        path:["emailAddressExist"],
                        }
                    ]
                }; 
            }
        }

        console.log(primaryInformationError);
        console.log(pickUpAddressError);
        

        if(primaryInformationError === undefined  && pickUpAddressError === undefined) return null;


        const primaryInformationErrors = {};
        if(primaryInformationError){
            primaryInformationError.details.map(
                function(errorObject){
                    //console.log(errorObject);
                    if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                        const key = errorObject.context.key;
                        const joiType = errorObject.type;
                        //console.log(this.errorMessages);
                        if(_.has(this.errorMessages, [key, joiType]))
                            return primaryInformationErrors[errorObject.path[0]] = this.errorMessages[key][joiType];
                        else
                            return primaryInformationErrors[errorObject.path[0]] = errorObject.message;
                    }
                    else
                      return primaryInformationErrors[errorObject.path[0]] = errorObject.message;
                }.bind(this)
            );
        }
        
        const pickUpAddressErrors = {};
        if(pickUpAddressError){
            pickUpAddressError.details.map(
                function(errorObject){
                    //console.log(errorObject);
                    if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                        const key = errorObject.context.key;
                        const joiType = errorObject.type;
                        //console.log(this.errorMessages);
                        if(_.has(this.errorMessages, [key, joiType]))
                            return pickUpAddressErrors[errorObject.path[0]] = this.errorMessages[key][joiType];
                        else
                            return pickUpAddressErrors[errorObject.path[0]] = errorObject.message;
                    }
                    else
                      return pickUpAddressErrors[errorObject.path[0]] = errorObject.message;
                }.bind(this)
            );
        }
        
        const errors = {...primaryInformationErrors,...pickUpAddressErrors} 
    
        console.log(errors);
        return Object.keys(errors).length > 0 ? errors:null;
    
    
    
    
    }


    render(){
        const {onClickOfBackButtonFromCustomerInformation} = this.props;
        const {data,errors} = this.state;

        return(
            <Container id="mainContentArea">
                <Row className="title-row justify-content-between">
                    <Col lg="6">
                        <h3 className="shop">Signup for Laundry - Residential</h3>
                    </Col>
                    <Col lg="6">
                        <div className="text-lg-right d-none">
                            <span className="text-muted">Step 3</span>
                            <Button color="link">
                                <i className="fa fa-shopping-cart" /> Enter Profile Information
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row className="title-body-row">
                    <Col md="8">
                        <Card>
                            <CardBody>
                                {/*Error display */}
                                {!_.isEmpty(errors) &&
                                    <FormErrorWidget
                                    errors={errors}
                                    />
                                }
                                {/* End of Error display */}
                                <Form>
                                    <h6 className="card-category text-left">Enter Primary Information</h6>
                                    <hr/>

                                    <FormGroup row>
                                        <Label for="firsName" sm={2}>Name</Label>
                                        <Col xs={6} sm={5}>
                                            <Input 
                                            type="text" 
                                            name="firstName" 
                                            id="firstName" 
                                            placeholder="First Name" 
                                            value={this.state.data.firstName} 
                                            onChange={this.handleChange}
                                            />
                                        </Col>
                                        <Col xs={6} sm={5}>
                                            <Input 
                                            type="text" 
                                            name="lastName" 
                                            id="firstName" 
                                            placeholder="Last Name" 
                                            value={this.state.data.lastName} 
                                            onChange={this.handleChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                    
                                    <FormGroup row>
                                        <Label for="email" sm={2}>Email</Label>
                                        <Col sm={7}>
                                            <Input 
                                                type="email" 
                                                name="email" 
                                                id="email" 
                                                placeholder="Email address" 
                                                value={this.state.data.email} 
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </FormGroup>

                                    
                                    <FormGroup row>
                                        <Label for="cellPhone" sm={2}>Cell Phone</Label>
                                        <Col sm={7}>
                                            <Input 
                                                type="text" 
                                                name="cellPhone" 
                                                id="cellPhone" 
                                                placeholder="" 
                                                value={this.state.data.cellPhone} 
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label for="homePhone" sm={2}>Home Phone</Label>
                                        <Col sm={7}>
                                            <Input 
                                                type="text" 
                                                name="homePhone" 
                                                id="homePhone" 
                                                placeholder="" 
                                                maxLength="20"
                                                value={this.state.data.homePhone} 
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </FormGroup>

                                    <h6 className="card-category text-left mt-2">Pickup/Delivery Address</h6>
                                    <hr/>

                                    <FormGroup row>
                                        <Label for="streetNumber" sm={2}>Street #</Label>
                                        <Col sm={4}>
                                            <Input 
                                            type="text" 
                                            name="streetNumber" 
                                            id="streetNumber" 
                                            placeholder="Street #" 
                                            maxLength="6"
                                            value={this.state.data.address.streetNumber} 
                                            onChange={this.handleChangeAddress}
                                            />
                                        </Col>

                                        <Label for="apartmentNumber" sm={2}>Apt #</Label>
                                        <Col sm={4}>
                                            <Input 
                                            type="text" 
                                            name="apartmentNumber" 
                                            id="apartmentNumber" 
                                            placeholder="Apt Number" 
                                            maxLength="10"
                                            value={this.state.data.address.apartmentNumber} 
                                            onChange={this.handleChangeAddress}
                                            />
                                        </Col>
                                        
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label for="addressLine1" sm={2}>Street Name</Label>
                                        <Col sm={10}>
                                            <Input 
                                            type="text" 
                                            name="addressLine1" 
                                            id="addressLine1" 
                                            placeholder="Address Line 1" 
                                            value={this.state.data.address.addressLine1} 
                                            onChange={this.handleChangeAddress}
                                            />
                                        </Col>
                                        
                                    </FormGroup>

                                    <FormGroup row className="d-none">
                                        <Label for="addressLine2" sm={2}>Address Line 2</Label>
                                        <Col sm={10}>
                                            <Input 
                                            type="text" 
                                            name="addressLine2" 
                                            id="addressLine2" 
                                            placeholder="Address Line 2" 
                                            value={this.state.data.address.addressLine2} 
                                            onChange={this.handleChangeAddress}
                                            />
                                        </Col>
                                        
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label for="city" sm={2}></Label>
                                        <Col xs={12} sm={3}>
                                            <Input 
                                            type="text" 
                                            name="city" 
                                            id="city" 
                                            placeholder="City" 
                                            value={this.state.data.address.city} 
                                            onChange={this.handleChangeAddress}
                                            />
                                        </Col>
                                        <Label for="state" xs={12} sm={2} className="d-block d-sm-none"></Label>
                                        <Col xs={12} sm={4}>
                                            <FormGroup>
                                                <Select
                                                    className="react-select react-select-default"
                                                    classNamePrefix="react-select"
                                                    name="state"
                                                    id="state"
                                                    isDisabled={false}
                                                    value={this.state.data.address.state}
                                                    onChange={value => this.setAddressState(value)}
                                                    options={this.state.stateList}
                                                    placeholder="State"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            <Input 
                                            type="text" 
                                            name="postalCode" 
                                            id="postalCode" 
                                            placeholder="Zip" 
                                            maxLength="5"
                                            value={this.state.data.address.postalCode} 
                                            onChange={this.handleChangeAddress}
                                            />
                                        </Col>
                                    </FormGroup>

                                    
                                    

                                    <hr/>

                                    
                                    <FormGroup row className="mt-5 justify-content-between">
                                        <Col xs={{ size: 2 }}>
                                            <Button 
                                            className="btn-rounded btn-danger"
                                            onClick={()=>onClickOfBackButtonFromCustomerInformation()}
                                            >
                                            Back
                                            </Button>
                                        </Col>
                                        <Col xs={{ size: 6}} sm={{ size: 4}} className="text-right">
                                            <Button 
                                            type="submit"
                                            className="btn-rounded btn-danger"
                                            onClick={this.handleSubmit}
                                            >
                                            Save & Proceed
                                            </Button>
                                        </Col>
                                    </FormGroup> 

                                    

                                </Form>
                            </CardBody>
                        </Card> 
                    </Col>
                </Row>    
            </Container>
        );       
    }
}

export default ResidentialCustomerInformation;