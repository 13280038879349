import React from "react";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import accounting from "accounting";

import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Badge,
    Button,
    ButtonGroup,
    Input,
    Nav,
    NavLink,
    NavItem,
    TabContent,
    TabPane,
    Table,
    Media,
    UncontrolledTooltip
} from "reactstrap";

const SupplyBundleItemCardMobile = (props)=>{
    const quantity = 1;
    
    const {product,handleAddToCart,editMode} = props;
    const {
        title,
        description,
        price,
        id,
        imagePath,
    } = product;

    
    
    return(
        <>
            <Card className="card-plain bg-white p-2 rounded-lg">
                <CardBody className="">
                    <Row>
                        <Col>
                            <CardTitle tag="h4" className="text-danger text-center">{title}</CardTitle>
                            <div className="text-center">
                                <img
                                alt="..."
                                className="img-rounded img-responsive"
                                src={require(`../../../../../assets/mylazybones${imagePath}`)}
                                />
                            </div>
                            <p className="card-description  fw-strong">
                            {ReactHtmlParser(description)}
                            </p>
                            <p className="mr-1 mt-4">
                            <strong>Price: {accounting.formatMoney(price)}</strong>
                            </p>
                        </Col>
                        

                    </Row>
                    <Row>
                        <Col>
                            <CardFooter className="text-center ">
                                {   editMode && 
                                    <Button
                                        className="btn btn-sm mr-1 mt-4 align-text-bottom"
                                        color="danger"
                                        href="#pablo"
                                        onClick={(e)=>handleAddToCart(e,product,quantity)}
                                        outline
                                        >
                                            Add to Cart
                                    </Button>
                                }
                                
                            </CardFooter>
                        </Col>
                    </Row>

                </CardBody>
            </Card>
        </>
    );
}

export default SupplyBundleItemCardMobile;
