const SEMESTER_PLAN = 2;
const BY_THE_POUND_PLAN = 1;
const BY_THE_POUND_SIGNUP_MINIMUM_CHARGE = 5.00;
const COMPUTE_TAX_FOR_BY_THE_POUND = false;

class ApplicationConstant {
    static get COMPUTE_TAX_FOR_BY_THE_POUND(){
        return  COMPUTE_TAX_FOR_BY_THE_POUND;
    }

    static get BY_THE_POUND_PLAN() {
        return BY_THE_POUND_PLAN;
    }

    static get BY_THE_POUND_SIGNUP_MINIMUMCHARGE(){
        return BY_THE_POUND_SIGNUP_MINIMUM_CHARGE;
    }    

    static get SEMESTER_PLAN() {
        return SEMESTER_PLAN;
    }

  
  
}

export default ApplicationConstant;

