import React from "react";
import {Link} from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import MainNavbar from '../../components/Navbars/MainNavbar';
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";
import FooterWhite from "../../components/Footers/FooterWhite.js";
import ContactUs from "../../components/Consumer/ContactUs"

import laundryIcon from "../../assets/mylazybones/img/LandingPage/icons/laundry_icon.svg";
import storageIcon from "../../assets/mylazybones/img/LandingPage/icons/storage_icon.svg";
import suppliesIcon from "../../assets/mylazybones/img/LandingPage/icons/supplies_icon.svg";
import giftCardIcon from "../../assets/mylazybones/img/LandingPage/icons/giftcard_icon.svg";



function LandingPage(props) {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <MainNavbar {...props} /> 
      <LandingPageHeader {...props} />
      <div className="wrapper">
        <div className="section section-feature cd-section  landing-section pb-0">
          <div className="features-3">
              <Container>
                  <Row>
                    <Col md="6">
                      <Row>
                        <Container>
                          <h2 className="title">Lazybones Services to Make Your Life Easier</h2>
                        </Container>
                      </Row>
                      <Row>
                        <Col md="6">
                          <div className="info info-horizontal">
                            <div className="icon icon-success">
                              <img
                                  style={{width:"35px",height:"35px",objectFit:"contain"}}
                                  src={laundryIcon}
                              />
                            </div>
                            <div className="description">
                              <h4 className="info-title">Laundry</h4>
                              <p>
                                Student and residential laundry service, picked up and delivered to your door step.
                              </p>
                              <p>
                               See More:<br/>
                               <Link to="/laundry/how-it-works/student" className="btn-link btn btn-success">Students</Link>
                               <Link to="/laundry/how-it-works/residential" className="btn-link btn btn-success">Residential</Link>
                               <Link to="/laundry/how-it-works/commercial" className="btn-link btn btn-success">Commercial</Link>
                              
                              </p>
                            </div>
                          </div>
                          
                        </Col>
                        <Col md="6">
                          
                          <div className="info info-horizontal">
                            <div className="icon icon-warning">
                              <img
                                  style={{width:"35px",height:"35px",objectFit:"contain"}}
                                  src={storageIcon}
                              />
                            </div>
                            <div className="description">
                              <h4 className="info-title">Storage</h4>
                              <p>
                                You pack it, we store it. Safe, reliable storage with no monthly fees. We also provide convenient shipping services as well.
                              </p>
                              <Link to="/storage/how-it-works/student" className="btn-link btn btn-warning">See more</Link>
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <img
                                  style={{width:"35px",height:"35px",objectFit:"contain"}}
                                  src={suppliesIcon}
                              />
                            </div>
                            <div className="description">
                              <h4 className="info-title">Supplies</h4>
                              <p>
                                The moving supplies you need, boxes, tape, bubble wrap and more, delivered to your door.
                              </p>
                              <Link to="/supplies/view-catalog" className="btn-link btn btn-danger">See more</Link>
                            </div>
                          </div>
                          
                        </Col>
                        <Col md="6">
                          
                          <div className="info info-horizontal">
                            <div className="icon icon-primary">
                              <img
                                  style={{width:"35px",height:"35px",objectFit:"contain"}}
                                  src={giftCardIcon}
                              />
                            </div>
                            <div className="description">
                              <h4 className="info-title">Gift Cards</h4>
                              <p>
                                Give the gift of cleanliness all semester long. 
                              </p>
                              <Link to="/gift-cards/" className="btn-link btn btn-primary">See more</Link>
                            </div>
                          </div>
                        </Col>
                      
                        
                      </Row>
                      
                    </Col>
                    <Col className="ml-auto d-none d-sm-block" md="5">
                      <div className="iphone-container">
                        <img
                          alt="..."
                          src={require("assets/mylazybones/img/LandingPage/laundry-stacked-clothes.jpg")}
                        />
                      </div>
                    </Col>
                  </Row>
              </Container>
          </div>  
        </div>
        <div className="section section-dark text-center landing-section d-none">
          <Container>
            <h2 className="title">Some of our customer reviews</h2>
            <Row>
              <Col md="4">
                <Card className=" card-plain">
                  <CardBody>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">John Doe</CardTitle>
                        <h6 className="card-category text-muted">
                          Student at UW Madison
                        </h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      Teamwork is so important that it is virtually impossible
                      for you to reach the heights of your capabilities or make
                      the money that you want without becoming very good at it.
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className="btn-link btn-just-icon mr-1"
                      color="twitter"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button
                      className="btn-link btn-just-icon mr-1"
                      color="dribbble"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-dribbble" />
                    </Button>
                    <Button
                      className="btn-link btn-just-icon"
                      color="linkedin"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-linkedin" />
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className=" card-plain">
                  
                  <CardBody>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Sophie West</CardTitle>
                        <h6 className="card-category text-muted">Student as Syracuse</h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      A group becomes a team when each member is sure enough of
                      himself and his contribution to praise the skill of the
                      others. No one can whistle a symphony. It takes an
                      orchestra to play it.
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className="btn-link btn-just-icon mr-1"
                      color="twitter"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button
                      className="btn-link btn-just-icon mr-1"
                      color="dribbble"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-dribbble" />
                    </Button>
                    <Button
                      className="btn-link btn-just-icon"
                      color="linkedin"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-linkedin" />
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className=" card-plain">
                  
                  <CardBody>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Robert Orben</CardTitle>
                        <h6 className="card-category text-muted">CEO of CleanExpress</h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      The strength of the team is each individual member. The
                      strength of each member is the team. If you can laugh
                      together, you can work together, silence isn’t golden,
                      it’s deadly.
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className="btn-link btn-just-icon mr-1"
                      color="twitter"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button
                      className="btn-link btn-just-icon mr-1"
                      color="dribbble"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-dribbble" />
                    </Button>
                    <Button
                      className="btn-link btn-just-icon"
                      color="linkedin"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-linkedin" />
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section landing-section pt-0">
            <ContactUs />
        </div>
      </div>
      <FooterWhite />
    </>
  );
}

export default LandingPage;
