import React from "react"
import Select from "react-select";
import {getUSStateList} from "services/dropDownListsService";
import {getCountryList} from "services/dropDownListsService";


import {CardElement,
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    injectStripe} from 'react-stripe-elements';

// reactstrap components
import {
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    InputGroupAddon,
    InputGroupText,
    InputGroup,
} from "reactstrap";

const stripeInputFieldStyling = {
    style: {
      base: {
        fontWeight:'100',
        fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',  
        '::placeholder': {
            color: '#999',
            fontWeight:'100',
        }
      }
    },
};

const stateList = getUSStateList();
const countryList = getCountryList();
        
const StripePaymentWidget = (props)=>{
    const {data,handleChange,handleChangeAddress,setAddressState,setCountry} = props;
    const {creditCardName,billingAddress} = data;

    return(
        <>
        <div className="mt-5">
            <h6 className="card-category text-left">
                Credit Card Information 
                <i className="fa fa-cc-amex fa-3 ml-1 " aria-hidden="true"></i>
                <i className="fa fa-cc-mastercard fa-3 ml-1 " aria-hidden="true"></i>
                <i className="fa fa-cc-visa fa-3 ml-1 " aria-hidden="true"></i>
                <i className="fa fa-cc-discover fa-3 ml-1 " aria-hidden="true"></i>
                <i className="fa fa-cc-jcb fa-3 ml-1 " aria-hidden="true"></i>
            </h6>
            
            <hr/>

            <FormGroup row>
                <Label for="creditCardName" sm={4}>Name on Credit Card</Label>
                <Col sm={6}>
                    <Input 
                        type="text" 
                        name="creditCardName" 
                        id="creditCardName" 
                        placeholder="Name on Credit Card" 
                        value={creditCardName} 
                        onChange={handleChange}
                    />
                </Col>
            </FormGroup>

            <FormGroup row>
                    <Label for="card-number" sm={4}>Credit Card Number</Label>
                    <Col id="card-number" sm={6}>
                        <CardNumberElement 
                            {...stripeInputFieldStyling}
                        />
                    </Col>
            </FormGroup>
            
            <FormGroup row>
                    <Label for="card-expiration-date" sm={4}>Expiration Date</Label>
                    <Col id="card-expiration-date" sm={6}>
                        <CardExpiryElement 
                            {...stripeInputFieldStyling}
                        />
                    </Col>
            </FormGroup> 
            
            <FormGroup row>
                    <Label for="card-cvc" sm={4}>CVC</Label>
                    <Col id="card-cvc" sm={6}>
                        <CardCVCElement 
                            {...stripeInputFieldStyling}
                        />
                    </Col>
            </FormGroup> 
        </div>

        <div>
        <h6 className="card-category text-left mt-2">Billing Address</h6>
            <hr/>

            <FormGroup row>
                <Label for="addressLine1" sm={2}>Address Line 1</Label>
                <Col sm={10}>
                    <Input 
                    type="text" 
                    name="addressLine1" 
                    id="addressLine1" 
                    placeholder="Address Line 1" 
                    value={billingAddress.addressLine1} 
                    onChange={e=>{handleChangeAddress(e,"billingAddress")}}
                    />
                </Col>
                
            </FormGroup>
            
            
            <FormGroup row>
                <Label for="addressLine2" sm={2}>Address Line 2</Label>
                <Col sm={10}>
                    <Input 
                    type="text" 
                    name="addressLine2" 
                    id="addressLine2" 
                    placeholder="Address Line 2" 
                    value={billingAddress.addressLine2} 
                    onChange={e=>{handleChangeAddress(e,"billingAddress")}}
                    />
                </Col>
                
            </FormGroup>
            
            
            
            <FormGroup row>
                <Label for="city" sm={2}></Label>
                <Col xs={12} sm={3}>
                    <Input 
                    type="text" 
                    name="city" 
                    id="city" 
                    placeholder="City" 
                    value={billingAddress.city} 
                    onChange={e=>{handleChangeAddress(e,"billingAddress")}}
                    />
                </Col>
                <Label for="state" xs={12} sm={2} className="d-block d-sm-none"></Label>
                <Col xs={12} sm={4}>
                    <FormGroup>
                        <Select
                            className="react-select react-select-default"
                            classNamePrefix="react-select"
                            name="state"
                            id="state"
                            value={billingAddress.state}
                            onChange={value => setAddressState(value,"billingAddress")}
                            options={stateList}
                            placeholder="State"
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} sm={3}>
                    <Input 
                    type="text" 
                    name="postalCode" 
                    id="postalCode" 
                    placeholder="Zip" 
                    value={billingAddress.postalCode} 
                    onChange={e=>{handleChangeAddress(e,"billingAddress")}}
                    />
                </Col>
            </FormGroup>

            <FormGroup row>
                <Label for="country" sm={2}>Country</Label>
                <Col sm={10}>
                    <Select
                        className="react-select react-select-default"
                        classNamePrefix="react-select"
                        name="country"
                        id="country"
                        value={billingAddress.country}
                        onChange={value => setCountry(value)}
                        options={countryList}
                        placeholder="Country"
                    />
                </Col>
                
            </FormGroup>
        </div> 
        </>
    )
}

export default StripePaymentWidget;