import React from "react"
import _ from "lodash"

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Media,
    Container,
    Row,
    Col
} from "reactstrap";

import step1ImageStudent from "../../../../assets/mylazybones/img/Laundry_HowItWorks/how-it-works-step-1-student.jpg";
import step1ImageResidential from "../../../../assets/mylazybones/img/Laundry_HowItWorks/how-it-works-step-1-residential.jpg";
import step2Image from "../../../../assets/mylazybones/img/Laundry_HowItWorks/how-it-works-step-2.jpg";
import step3Image from "../../../../assets/mylazybones/img/Laundry_HowItWorks/how-it-works-step-3.jpg";
import step4Image from "../../../../assets/mylazybones/img/Laundry_HowItWorks/how-it-works-step-4.jpg";



const HowItWorks = (props)=>{
    const {isStudent,isResidential} = props;
    
    const step1Image = (isResidential === true)? step1ImageResidential:step1ImageStudent

    return(
        <>
        <div className="how-it-works">
                                
            <Row>
                <Col className="ml-auto mr-auto text-center">
                    <h5 className="description">
                      We have 25+ years of experience delivering top quality laundry & dry cleaning services to discerning college students who love their clothes.
                    </h5>
                </Col>
            </Row>
            <div className="space-top" />
            { isStudent && 
                <>
                <Row>
                    <Col className="ml-auto" md="5">
                    <Card
                        data-background="image"
                        style={{
                        backgroundImage:
                            `url(${step1Image})`
                        }}
                    >
                        
                    </Card>
                    </Col>
                    <Col className="mr-auto" md="5">
                    <Card className="card-plain">
                        <CardBody>
                            <CardTitle tag="h2" className="text-center">
                                Step 1 
                            </CardTitle> 
                            <CardTitle tag="h3" className="how-it-works-step-title">
                                Set up your laundry service
                            </CardTitle>
                            <ul className="card-description">
                                <li>Sign up for laundry service online or call us at 877-215-2105</li>
                                <li>Select a semester laundry plan or pay by the pound </li>  
                                <li>Tell us where & when to pick up your laundry</li>  
                                <li>Give us your laundry special requests</li>  
                                
                            </ul> 
                            <CardFooter />
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
                <br />
                <hr />
                <br />
                <Row>
                    <Col className="ml-auto order-12 order-md-1" md="5">
                    <Card className="card-plain">
                        <CardBody>
                            <CardTitle tag="h2" className="text-center">
                                Step 2 
                            </CardTitle> 
                            <CardTitle tag="h3" className="how-it-works-step-title">
                            We pick up your laundry & dry cleaning
                            </CardTitle>
                            <ul className="card-description">
                                <li>Place your dirty laundry in a draw string laundry bag</li>  
                                <li>Write your name on your bag with permanent marker</li>  
                                <li>We will attach a permanent tag to your bag after your first pick up</li>  
                                
                            </ul> 
                            <CardFooter />
                        </CardBody>
                    </Card>
                    </Col>
                    <Col className="mr-auto order-1 order-md-12" md="5">
                    <Card
                        data-background="image"
                        style={{
                            backgroundImage:
                            `url(${step2Image})`
                        }}
                    >
                        
                    </Card>
                    </Col>
                </Row>
                
                <br />
                <hr />
                <br />

                <Row>
                    <Col className="ml-auto " md="5">
                    <Card
                        data-background="image"
                        style={{
                            backgroundImage:
                            `url(${step3Image})`
                        }}
                    >
                        
                    </Card>
                    </Col>
                    <Col className="mr-auto" md="5">
                    <Card className="card-plain">
                        <CardBody>
                            <CardTitle tag="h2" className="text-center">
                                Step 3
                            </CardTitle> 
                            <CardTitle tag="h3" className="how-it-works-step-title">
                                Laundry done your way
                            </CardTitle>
                            <ul className="card-description">
                                <li>We separate lights and darks</li>  
                                <li>Follow your special requests</li>  
                                <li>Use state of the art high efficiency equipment</li>  
                            </ul> 
                            <CardFooter />
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
                <br />
                <hr />
                <br />
                <Row>
                    <Col className="ml-auto order-12 order-md-1" md="5">
                    <Card className="card-plain">
                        <CardBody>
                            <CardTitle tag="h2" className="text-center">
                                Step 4
                            </CardTitle> 
                            <CardTitle tag="h3" className="how-it-works-step-title">
                                Next day delivery
                            </CardTitle>
                            <ul className="card-description">
                                <li>Your cleaned and neatly packaged clothes are back to you the next day</li>  
                            </ul> 
                            <CardFooter />
                        </CardBody>
                    </Card>
                    </Col>
                    <Col className="mr-auto order-1 order-md-12" md="5">
                    <Card
                        data-background="image"
                        style={{
                            backgroundImage:
                            `url(${step4Image})`
                        }}
                    >
                        
                    </Card>
                    </Col>
                </Row>
                </>
            }

            { isResidential && 
                <>
                <Row>
                    <Col className="ml-auto" md="5">
                    <Card
                        data-background="image"
                        style={{
                        backgroundImage:
                            `url(${step1Image})`
                        }}
                    >
                        
                    </Card>
                    </Col>
                    <Col className="mr-auto" md="5">
                    <Card className="card-plain">
                        <CardBody>
                            <CardTitle tag="h2" className="text-center">
                                Step 1 
                            </CardTitle> 
                            <CardTitle tag="h3" className="how-it-works-step-title">
                                Set up your laundry service
                            </CardTitle>
                            <ul className="card-description">
                                <li>Sign up for laundry service online or call us at 877-215-2105</li>
                                <li>Select the pay by the pound option</li>  
                                <li>Tell us where & when to pick up your laundry</li>  
                                <li>Give us your laundry special requests</li>  
                                
                            </ul> 
                            <CardFooter />
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
                <br />
                <hr />
                <br />
                <Row>
                    <Col className="ml-auto order-12 order-md-1" md="5">
                    <Card className="card-plain">
                        <CardBody>
                            <CardTitle tag="h2" className="text-center">
                                Step 2 
                            </CardTitle> 
                            <CardTitle tag="h3" className="how-it-works-step-title">
                            We pick up your laundry & dry cleaning
                            </CardTitle>
                            <ul className="card-description">
                                <li>Place your dirty laundry in a draw string laundry bag</li>  
                                <li>Write your name on your bag with permanent marker</li>  
                                <li>We will attach a permanent tag to your bag after your first pick up</li>  
                                
                            </ul> 
                            <CardFooter />
                        </CardBody>
                    </Card>
                    </Col>
                    <Col className="mr-auto order-1 order-md-12" md="5">
                    <Card
                        data-background="image"
                        style={{
                            backgroundImage:
                            `url(${step2Image})`
                        }}
                    >
                        
                    </Card>
                    </Col>
                </Row>
                
                <br />
                <hr />
                <br />

                <Row>
                    <Col className="ml-auto" md="5">
                    <Card
                        data-background="image"
                        style={{
                            backgroundImage:
                            `url(${step3Image})`
                        }}
                    >
                        
                    </Card>
                    </Col>
                    <Col className="mr-auto" md="5">
                    <Card className="card-plain">
                        <CardBody>
                            <CardTitle tag="h2" className="text-center">
                                Step 3
                            </CardTitle> 
                            <CardTitle tag="h3" className="how-it-works-step-title">
                                Laundry done your way
                            </CardTitle>
                            <ul className="card-description">
                                <li>We separate lights and darks</li>  
                                <li>Follow your special requests</li>  
                                <li>Use state of the art high efficiency equipment</li>  
                            </ul> 
                            <CardFooter />
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
                <br />
                <hr />
                <br />
                <Row>
                    <Col className="ml-auto order-12 order-md-1" md="5">
                    <Card className="card-plain">
                        <CardBody>
                            <CardTitle tag="h2" className="text-center">
                                Step 4
                            </CardTitle> 
                            <CardTitle tag="h3" className="how-it-works-step-title">
                                Next day delivery
                            </CardTitle>
                            <ul className="card-description">
                                <li>Your cleaned and neatly packaged clothes are back to you the next day</li>  
                            </ul> 
                            <CardFooter />
                        </CardBody>
                    </Card>
                    </Col>
                    <Col className="mr-auto order-1 order-md-12" md="5">
                    <Card
                        data-background="image"
                        style={{
                            backgroundImage:
                            `url(${step4Image})`
                        }}
                    >
                        
                    </Card>
                    </Col>
                </Row>
                </>
            }
        </div>
        </>
    )
}

export default HowItWorks;

