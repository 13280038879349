import React,{Component} from "react";
import Select from "react-select";
import moment from "moment";
import Datetime from "react-datetime";
import ReactDatetime from "react-datetime";
import _ from "lodash";
import laundryService from "services/laundryService"

// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";

// reactstrap components
import {
    Button,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    FormGroup, 
    Modal, 
    Input, 
    UncontrolledTooltip,
    
  } from "reactstrap";

import FormErrorWidget from "../../../../Common/FormErrorWidget";
import {scrollTop} from '../../../../../utils/helperUtils';
import {renderError} from '../../../../../utils/errorUtils';
import customToast from '../../../../Common/CustomToast'

import applicationConstants from '../../../../../utils/applicationConstants';
  

class StudentAccountLaundrySchedulingModal extends Component{
    recurringPickupDayOptions = [
        { value: "", label: " Select Day", isDisabled: true },
        
    ];

    recurringDeliveryDayOptions = [
        { value: "", label: " Select Day", isDisabled: true },
        
        
    ];

    constructor(){
        super();
        this.state = {
            isUserLivingInDormitory:false,
            data:{
                pickUpType:"", //recurring, one-time, one-time-asap
            
                oneTimePickupDay:"",
                oneTimePickUpTime:"",
                oneTimePickUpTimeId:"",
                oneTimePickupTimeslotList:[],
                
                oneTimeDeliveryDay:"",
                oneTimeDeliveryTime:"",
                oneTimeDeliveryTimeId:"",
                oneTimeDeliveryTimeslotList:[],

                recurringPickupDay:"",
                recurringPickupDayId:"",
                
                recurringDeliveryDay:"",
                recurringDeliveryDayId:"",
                
                recurringPickupTimeslotList:[],
                recurringPickUpTime:"",  
                recurringPickUpTimeId:"",

                recurringDeliveryTimeslotList:[],
                recurringDeliveryTime:"",
                recurringDeliveryTimeId:"",

                asapPickupDay:"",
                asapPickUpTime:"",
                asapPickUpTimeId:"",
                asapPickupTimeslotList:[],
                

                deliveryPreference:"",
                deliveryInstructions:"",
                laundrySpecialRequest:"",
                
                enrollForTextNotification:true,
                enrollForEmailNotification:true,
            },
            errors:{},
            
            
        }
    }

    componentDidMount(){
        const { user } = this.props
        
        
        /*
            If a student lives in a dorm then we set the delivery preference to true where LB can leave laundry at the dorm
        */
        if(!_.isEmpty(user)){
          const {dorm_non_dorm:isDormitoryOrNonDormitory} = user;
          if(isDormitoryOrNonDormitory === applicationConstants.DORMITORY){
            this.setState({
                isUserLivingInDormitory:true,
                data:{
                    ...this.state.data,
                    deliveryPreference:true,
                }
            })
          }
          
        }
    }

    componentDidUpdate(prevProps){
        const data = {...this.state.data};
        const { laundryOrder,user } = this.props
        const {user:userPrev } = prevProps
        if( data.asapPickupDay === "" && 
            laundryOrder && 
            laundryOrder.laundryOneTimeDateList && 
            laundryOrder.laundryOneTimeDateList.pickupDates.length)
        {
            this.setNextAsapDateAndTimeslotForPickup();
        }

        /*
            If a student lives in a dorm then we set the delivery preference to true where LB can leave laundry at the dorm
        */
        if(_.isEqual(user,userPrev) === false){
            const {dorm_non_dorm:isDormitoryOrNonDormitory} = user;
            if(isDormitoryOrNonDormitory === applicationConstants.DORMITORY){
                this.setState({
                    isUserLivingInDormitory:true,
                    data:{
                        ...this.state.data,
                        deliveryPreference:true,
                    }
                })
            }
        }
    }

    getAsapPickupAvailabilityText = ()=>{
        let text = "";

        const data = {...this.state.data}
        const {asapPickupDay,asapPickUpTime} = data;

        if(asapPickupDay && asapPickUpTime){
            const now = moment();
            const day = now.isSame(asapPickupDay,'day') ? "today" : `on ${asapPickupDay.format('dddd')}`;
            text = `(We have a truck in your area ${day} between ${asapPickUpTime})`;
        }

        return text;
    }

    getRecursiveNextAsapDateAndTimeslotForPickup = (pickupDates,laundrySchedules,initCounter)=>{
        const asapPickupDay = moment(pickupDates[initCounter]);
        const pickupTimeslotList = laundryService.generateLaundryOneTimePickupTimeslotDropdownList(asapPickupDay,laundrySchedules);
        if(pickupTimeslotList.length > 0){
            const asapPickupTimeObject = pickupTimeslotList[0];
            return {
                asapPickupDay,
                asapPickupTimeObject,
                pickupTimeslotList
            };   
        } 
        else{
            initCounter++;
            if(initCounter < pickupDates.length){
              const returnObject = this.getRecursiveNextAsapDateAndTimeslotForPickup(pickupDates,laundrySchedules,initCounter);
              return returnObject;
            }  
            else
              return {};
        } 
    }

    handleChange = ({currentTarget:input})=>{
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[input.name] = input.value;
        this.setState({data,errors});
        
    }
    
    handleEmailNofificationSwitch = (elem, value)=>{
        console.log('handleSwitch. elem:', elem);
        console.log('name:', elem.props.name);
        console.log('new state:', value);
        
        const data = {...this.state.data}
        data.enrollForEmailNotification = value;
        this.setState({data});

    }

    handleOneTimeDeliveryDay = (value)=>{
        const data = {...this.state.data}
        data.oneTimeDeliveryDay = value;

        const { laundryOrder } = this.props
        if(laundryOrder){
            const {laundrySchedules} = laundryOrder;
            const deliveryDay = value;
            const deliveryTimeslotList = laundryService.generateLaundryOneTimeDeliveryTimeslotDropdownList(deliveryDay,laundrySchedules);
            data.oneTimeDeliveryTimeslotList = deliveryTimeslotList;
        }

        this.setState({data});
    }

    handleOneTimePickupDay = (value)=>{
        const data = {...this.state.data}
        data.oneTimePickupDay = value;
        data.oneTimePickUpTime = "";
        data.oneTimePickUpTimeId = "";
        data.oneTimeDeliveryDay = "";
        
        const { laundryOrder } = this.props
        if(laundryOrder){
            const {laundrySchedules} = laundryOrder;
            const pickupDay = value;
            const pickupTimeslotList = laundryService.generateLaundryOneTimePickupTimeslotDropdownList(pickupDay,laundrySchedules);
            //console.log(pickupTimeslotList);
            data.oneTimePickupTimeslotList = pickupTimeslotList;
        }
        
        this.setState({data});
    }

    handleTextNofificationSwitch = (elem, value)=>{
        console.log('handleSwitch. elem:', elem);
        console.log('name:', elem.props.name);
        console.log('new state:', value);

        const data = {...this.state.data}
        data.enrollForTextNotification = value;
        this.setState({data});
    }

    

    setNextAsapDateAndTimeslotForPickup= ()=>{
        const { laundryOrder } = this.props
        if(laundryOrder && laundryOrder.laundryOneTimeDateList && laundryOrder.laundryOneTimeDateList.pickupDates.length){
            const {laundryOneTimeDateList,laundrySchedules} = laundryOrder;
            const {pickupDates} = laundryOneTimeDateList;
            const asapObject = this.getRecursiveNextAsapDateAndTimeslotForPickup(pickupDates,laundrySchedules,0);
            if(!_.isEmpty(asapObject)){
                const {
                    asapPickupDay,
                    asapPickupTimeObject,
                    pickupTimeslotList
                } = asapObject;  

                const data = {...this.state.data}

                data.asapPickupDay = asapPickupDay;
                data.asapPickUpTime = asapPickupTimeObject.formattedTime;
                data.asapPickUpTimeId = asapPickupTimeObject.id;
                data.asapPickupTimeslotList = pickupTimeslotList;
                this.setState({data});

            }

            /*
            const asapPickupDay = moment(laundryOneTimeDateList.pickupDates[0]);
            const pickupTimeslotList = laundryService.generateLaundryOneTimePickupTimeslotDropdownList(asapPickupDay,laundrySchedules);
            let asapPickupTimeObject;
            if(pickupTimeslotList.length){
                asapPickupTimeObject = pickupTimeslotList[0];
                const data = {...this.state.data}

                data.asapPickupDay = asapPickupDay;
                data.asapPickUpTime = asapPickupTimeObject.formattedTime;
                data.asapPickUpTimeId = asapPickupTimeObject.id;
                data.asapPickupTimeslotList = pickupTimeslotList;
                this.setState({data});
            }
            */
        }
    }

    setDeliveryPreference = (e,waiverFlag)=>{
       e.preventDefault();
       const data = {...this.state.data};
       data.deliveryPreference = waiverFlag;
       this.setState({data});
    }

    setPickupType = (pickupType)=>{
        const data = {...this.state.data}
        const { 
            asapPickupDay,
            asapPickUpTime,
            asapPickUpTimeId,
            asapPickupTimeslotList} = data;

        data.pickUpType = pickupType;
        data.oneTimePickUpTime = "";
        data.oneTimePickupDay = "";
        data.oneTimePickUpTimeId = "";
        data.oneTimePickupTimeslotList= [];
        
        data.oneTimeDeliveryDay = "";
        data.oneTimeDeliveryTime = "";
        data.oneTimeDeliveryTimeId = "";
        data.oneTimeDeliveryTimeslotList = [];

        data.recurringPickupDay = "";
        data.recurringDeliveryDay = "";
        data.recurringPickUpTime = "";
        data.recurringDeliveryTime = "";

                
        
        if(pickupType === "one-time-asap"){
            data.oneTimePickupDay = asapPickupDay
            data.oneTimePickUpTime = asapPickUpTime;;
            data.oneTimePickUpTimeId = asapPickUpTimeId;
            data.oneTimePickupTimeslotList= asapPickupTimeslotList;
        }

        this.setState({data})  
    }

    setOneTimeDeliveryTime = (timeslotObject)=>{
        const data = {...this.state.data}
        data.oneTimeDeliveryTime = timeslotObject.formattedTime;
        data.oneTimeDeliveryTimeId = timeslotObject.id
        this.setState({data}) 
    }
    
    setOneTimePickupTime = (timeslotObject)=>{
        const data = {...this.state.data}
        data.oneTimePickUpTime = timeslotObject.formattedTime;
        data.oneTimePickUpTimeId = timeslotObject.id
        this.setState({data}) 
    }

    setRecurringDeliveryDay = selectedValue=>{
        const data = {...this.state.data}
        data.recurringDeliveryDay = selectedValue;
        data.recurringDeliveryDayId = selectedValue.value;

        //get the timeslots based on the selected day
        const { laundryOrder } = this.props
        if(laundryOrder){
            const {laundrySchedules} = laundryOrder;
            const deliveryDayRecordId = selectedValue.value;
            const deliveryDay = selectedValue.label;
            const deliveryTimeslotList = laundryService.generateLaundryDeliveryTimeslotDropdownList(deliveryDay,deliveryDayRecordId,laundrySchedules);
            //console.log(deliveryTimeslotList);
            data.recurringDeliveryTimeslotList = deliveryTimeslotList;
        } 


        this.setState({data})   
    }

    setRecurringPickupDay = selectedValue=>{
        const data = {...this.state.data}
        data.recurringPickupDay = selectedValue;
        data.recurringPickupDayId = selectedValue.value;
        data.recurringDeliveryDay = "";
        data.recurringDeliveryDayId = "";
        data.recurringDeliveryTimeslotList = [];
        data.recurringDeliveryTime = "";
        data.recurringDeliveryTimeId = "";

        //get the timeslots based on the selected day
        const { laundryOrder } = this.props
        if(laundryOrder){
            const {laundrySchedules} = laundryOrder;
            const pickupDayRecordId = selectedValue.value;
            const pickupDay = selectedValue.label;
            const pickupTimeslotList = laundryService.generateLaundryPickupTimeslotDropdownList(pickupDay,pickupDayRecordId,laundrySchedules);
            //console.log(pickupTimeslotList);
            data.recurringPickupTimeslotList = pickupTimeslotList;
            
        } 

        this.setState({data})   
    }

    
    setRecurringDeliveryTime = (timeslotObject)=>{
        const data = {...this.state.data}
        data.recurringDeliveryTime = timeslotObject.formattedTime;
        data.recurringDeliveryTimeId = timeslotObject.id
        this.setState({data}) 
    }

    
    setRecurringPickupTime = (timeslotObject)=>{
        const data = {...this.state.data}
        data.recurringPickUpTime = timeslotObject.formattedTime;
        data.recurringPickUpTimeId = timeslotObject.id
        this.setState({data}) 
    }

    /*  Send state data to the parent laundry detail */
    saveSchedule = ()=>{
        
        const errors = this.validateLocal();
        if(!_.isEmpty(errors)){
            this.setState({errors});
            scrollTop("studentLaundrySchedulingModal");
            return
        }

        const data = {...this.state.data}
        this.props.handleSetSchedule(data);
        return;
    }

    validateLocal = ()=>{
        const {data} = this.state;
        let errors  = "";

        const {pickUpType,deliveryPreference} = data;

        /* Plan based validation */
        if(pickUpType === "recurring" && deliveryPreference === ""){
            errors = {
                deliveryPreference:"Please select delivery preference.",
            }
        }

        /* One-time pick up/delivery:*/
        if(pickUpType === "one-time" && deliveryPreference === ""){
            errors = {
                deliveryPreference:"Please select delivery preference.",
            }
        }

        /*One-time assap */
        if(pickUpType === "one-time-asap" && deliveryPreference === ""){
            errors = {
                deliveryPreference:"Please select delivery preference.",
            }
        }

        return errors;
    }

    render(){
        let recurringPickupDayOptions = this.recurringPickupDayOptions;
        let recurringDeliveryDayOptions = this.recurringDeliveryDayOptions;
        let oneTimePickupScheduleButtonDisplayCSS = "mt-2 d-none" , oneTimePickupAsapScheduleButtonDisplayCSS = "mt-2 d-none"; 
        const {isUserLivingInDormitory,data,errors} = this.state
        const { 
            pickUpType,
            oneTimePickupDay,
            oneTimePickUpTime,
            oneTimePickUpTimeId,
            oneTimePickupTimeslotList,
            oneTimeDeliveryDay,
            oneTimeDeliveryTime, 
            oneTimeDeliveryTimeId,
            oneTimeDeliveryTimeslotList,
            recurringDeliveryDay,
            recurringDeliveryTime,
            recurringDeliveryTimeId,
            recurringDeliveryTimeslotList,
            recurringPickupDay,
            recurringPickUpTime,
            recurringPickUpTimeId,
            recurringPickupTimeslotList,
            enrollForEmailNotification,
            enrollForTextNotification,
            deliveryPreference,
            deliveryInstructions,
            laundrySpecialRequest,
                
        } = data
        
        const { 
                handleScheduleModalDisplay,
                //listOfOneTimePickupDates,
                scheduleModalDisplay,
                laundryOrder,
                user 
        } = this.props

        let listOfOneTimePickupDates = [],listOfOneTimeDeliveryDates = [];
        
        const displayAsapPickupAvailabilityText = this.getAsapPickupAvailabilityText();

        if(laundryOrder){
            const {laundryOneTimeDateList, laundrySchedules, laundryPlanType} = laundryOrder;
            //console.log(laundryOneTimeDateList);
            
            listOfOneTimePickupDates = laundryOneTimeDateList.pickupDates.map(d => moment.utc(d));
            listOfOneTimeDeliveryDates = laundryOneTimeDateList.deliveryDates.map(d => moment.utc(d));
            //console.log('listOfOneTimePickupDates');
            //console.log(listOfOneTimePickupDates);
            if(oneTimePickupDay){
                //Filter out delivery dates to only the delivery date for that day
                listOfOneTimeDeliveryDates = [oneTimePickupDay.clone().add(1,'days')];
            }
            
            recurringPickupDayOptions = laundryService.generateLaundryPickupDropdownListFromZoneScheduleLaundryRecords(laundrySchedules);
            
            const selectedPickupDayObject = (!_.isEmpty(recurringPickupDay)) ? recurringPickupDay:{};
            recurringDeliveryDayOptions = laundryService.generateLaundryDeliveryDropdownListFromZoneScheduleLaundryRecords(laundrySchedules,selectedPickupDayObject);
            
            if(laundryPlanType === laundryService.BY_THE_POUND)
              oneTimePickupScheduleButtonDisplayCSS = oneTimePickupAsapScheduleButtonDisplayCSS = "mt-2";
        }
             
        

        const displayRecurringSettingPropertiesAreaCSS = pickUpType === "recurring" ? "":"d-none";
        const displayOneTimeSettingPropertiesAreaCSS = (pickUpType === "one-time" || pickUpType === "one-time-asap") ? "":"d-none";
        
        let [deliveryInstructionsDisplayCSS,
             driverInstructionsDisplayCSS,
             specialLaundryInstructionsDisplayCSS, 
             textAndEmailInstructionsDisplayCSS,displaySubmit] = ["d-none","d-none","d-none","d-none","d-none"];
        
        if(pickUpType === "recurring"){
            deliveryInstructionsDisplayCSS  = (recurringDeliveryDay && 
                                              recurringDeliveryTime && 
                                              recurringPickupDay && 
                                              recurringPickUpTime) ? "":"d-none";

            displaySubmit = driverInstructionsDisplayCSS = specialLaundryInstructionsDisplayCSS = textAndEmailInstructionsDisplayCSS = deliveryInstructionsDisplayCSS;
            
            //Hide delivery preference for students living in dormitory
            if(isUserLivingInDormitory === true)
                deliveryInstructionsDisplayCSS  ="d-none"
        }

        if(pickUpType === "one-time" || pickUpType === "one-time-asap"){
            deliveryInstructionsDisplayCSS  = ( oneTimeDeliveryDay && 
                                                oneTimeDeliveryTime && 
                                                oneTimePickupDay && 
                                                oneTimePickUpTime) ? "":"d-none";

            displaySubmit = driverInstructionsDisplayCSS = specialLaundryInstructionsDisplayCSS = textAndEmailInstructionsDisplayCSS = deliveryInstructionsDisplayCSS;

        }


        return(
                <Modal id="studentLaundrySchedulingModal" size="lg" isOpen={scheduleModalDisplay} toggle={() => handleScheduleModalDisplay(false)}>
                    <div className="modal-header">
                    <button
                        className="close"
                        type="button"
                        onClick={() => handleScheduleModalDisplay(false)}
                    >
                        <span>×</span>
                    </button>
                    <h5
                        className="modal-title text-center"
                        id="exampleModalLabel"
                    >
                        Schedule Laundry Pickup/Delivery
                    </h5>
                    </div>
                    <div className="modal-body">
                        {/*Error display */}
                            {!_.isEmpty(errors) &&
                                <FormErrorWidget
                                    errors={errors}
                                />
                            }
                        {/* End of Error display */}
                        
                        {/*Select pickup type recurring, one-time, asap */}
                        <Row>
                            <Col>
                                <h6>What type of pick up would you like to schedule?</h6>
                                <Row>
                                <Col xs="7">
                                Recurring weekly pick up/delivery:	
                                </Col>  
                                <Col xs="5" sm="4" md="4" lg="2">
                                        <Button
                                            className="btn-round mr-1"
                                            color="danger"
                                            outline = {(pickUpType === "recurring") ? false:true}
                                            block
                                            type="button"
                                            onClick={()=>this.setPickupType("recurring")}
                                            
                                        >
                                            {(pickUpType === "recurring") ? "Selected":"Select"}
                                        </Button>
                                </Col>  
                                </Row>


                                <Row className={oneTimePickupScheduleButtonDisplayCSS}>
                                <Col xs="7">
                                One-time pick up/delivery:	
                                </Col>  
                                <Col xs="5" sm="4" md="4" lg="2">
                                        <Button
                                            className="btn-round mr-1"
                                            color="danger"
                                            outline = {(pickUpType === "one-time") ? false:true}
                                            block
                                            type="button"
                                            onClick={()=>this.setPickupType("one-time")}
                                        >
                                            {(pickUpType === "one-time") ? "Selected":"Select"}
                                        </Button>
                                </Col>  
                                </Row>

                                <Row className={oneTimePickupAsapScheduleButtonDisplayCSS}>
                                <Col xs="7">
                                Get on the schedule as soon as possible:		
                                </Col>  
                                <Col  xs="5" sm="4" md="4" lg="2">
                                        <Button
                                            className="btn-round mr-1"
                                            color="danger"
                                            outline = {(pickUpType === "one-time-asap") ? false:true}
                                            block
                                            type="button"
                                            onClick={()=>this.setPickupType("one-time-asap")}
                                        >
                                            {(pickUpType === "one-time-asap") ? "Selected":"Select"}
                                        </Button>
                                </Col>
                                <Col xs="6" md="6" lg="3">
                                    <p className="text-danger"><strong>{displayAsapPickupAvailabilityText}</strong></p>
                                </Col>  
                                </Row>
                                

                            </Col>
                        </Row>
                        {/*End of Select pickup type recurring, one-time, asap */}

                        {/* Recurring Properties */}                    
                        <Row className={displayRecurringSettingPropertiesAreaCSS}>
                            <Col>
                                <hr/>
                                <h6>Select recurring pickup date & time?</h6>
                                <Row>
                                    <Col md="6">
                                        <Row>
                                            <Col sm="4">
                                                Pickup Day
                                            </Col>
                                            <Col sm="8">
                                                <FormGroup>
                                                    <Select
                                                        className="react-select react-select-default"
                                                        classNamePrefix="react-select"
                                                        name="defaultSelect"
                                                        value={recurringPickupDay}
                                                        onChange={value => this.setRecurringPickupDay(value)}
                                                        options={recurringPickupDayOptions}
                                                        placeholder="Select"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md="6" className={(recurringPickupDay) ?"":"d-none"}>
                                        <Row>
                                            <Col sm="4">
                                                Delivery Day
                                            </Col>
                                            <Col sm="8">
                                                <FormGroup>
                                                    <Select
                                                        className="react-select react-select-default"
                                                        classNamePrefix="react-select"
                                                        name="defaultSelect"
                                                        value={recurringDeliveryDay}
                                                        onChange={value => this.setRecurringDeliveryDay(value)}
                                                        options={recurringDeliveryDayOptions}
                                                        placeholder="Select"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="6"  className={(recurringPickupDay && recurringPickupTimeslotList && recurringPickupTimeslotList.length) ? "":"d-none"}>
                                        <Row>
                                            <Col sm="4">
                                                Pickup Time
                                            </Col>
                                            <Col sm="8" md="8" lg="8">
                                                {
                                                   recurringPickupTimeslotList.map((timeslot)=>{
                                                       return(
                                                            <Row key={`pickup_${timeslot.id}`}>
                                                                <Col className="mb-2">
                                                                    <Button
                                                                        className="btn-round mr-1"
                                                                        color="danger"
                                                                        outline = {recurringPickUpTimeId === timeslot.id ? false:true}
                                                                        block
                                                                        type="button"
                                                                        onClick={()=>this.setRecurringPickupTime(timeslot)}
                                                                    >
                                                                        {`${timeslot.formattedTime}`}
                                                                    </Button>
                                                                
                                                                </Col>
                                                            </Row> 
                                                       )
                                                   }) 
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md="6" className={(recurringDeliveryDay && recurringDeliveryTimeslotList && recurringDeliveryTimeslotList.length) ? "" : "d-none"}>
                                        <hr className="d-block d-md-none" />
                                        <Row>
                                            <Col sm="4">
                                                Delivery Time
                                            </Col>
                                            <Col sm="8" md="8" lg="8">
                                                {
                                                   recurringDeliveryTimeslotList.map((timeslot)=>{
                                                       return(
                                                            <Row key={`delivery_${timeslot.id}`}>
                                                                <Col className="mb-2">
                                                                    <Button
                                                                        className="btn-round mr-1"
                                                                        color="danger"
                                                                        outline = {recurringDeliveryTimeId === timeslot.id ? false:true}
                                                                        block
                                                                        type="button"
                                                                        onClick={()=>this.setRecurringDeliveryTime(timeslot)}
                                                                    >
                                                                        {`${timeslot.formattedTime}`}
                                                                    </Button>
                                                                
                                                                </Col>
                                                            </Row> 
                                                       )
                                                   }) 
                                                }
                                            </Col>
                                        </Row>                                        
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {/* End of Recurring Properties */}

                        {/* One-time pickup & Delivery */}
                        <Row className={displayOneTimeSettingPropertiesAreaCSS}>
                            <Col>
                                <hr/>
                                <h6>Select one-time pickup/delivery date & time?</h6>
                                <Row>
                                    <Col md="6">
                                        <Row>
                                            <Col sm="4" className="mt-2">
                                                Pickup Day
                                            </Col>
                                            <Col sm="8">
                                                <FormGroup>
                                                    <InputGroup 
                                                        name="oneTimePickUpDatetimePicker"
                                                        className="date" 
                                                        id="oneTimePickUpDatetimePicker"
                                                        
                                                    >   
                                                        <ReactDatetime
                                                            value={oneTimePickupDay} 
                                                            timeFormat={false}
                                                            closeOnSelect={true}
                                                            onChange={this.handleOneTimePickupDay}
                                                            inputProps={{
                                                                className: "form-control",
                                                                placeholder: "Pickup Day",
                                                            }}
                                                            renderDay={( props, currentDate, selectedDate)=>{
                                                                /*
                                                                console.log(props);
                                                                console.log(currentDate);
                                                                console.log(selectedDate);
                                                                console.log(this.props);
                                                                */
                                                                //console.log(currentDate);
                                                                
                                                                if(listOfOneTimePickupDates.length){
                                                                    let highLightDate = false;
                                                                     
                                                                    listOfOneTimePickupDates.forEach(d=>{
                                                                        if(moment.utc(currentDate).isSame(d,'day') && moment.utc().isSameOrBefore(moment.utc(currentDate),'day'))
                                                                            highLightDate = true;
                                                                     })
     
                                                                     if(highLightDate)
                                                                        props.className += " rdtActive color-white fw-strong";
                                                                     
                                                                }
                                                                
                                                                
                                                                return <td {...props}>{currentDate.date() }</td>;
                                                            }}

                                                            isValidDate={currentDate=>{
                                                                let enableDate = false;
                                                                listOfOneTimePickupDates.forEach(d=>{
                                                                    if(moment.utc(currentDate).isSame(d,'day') && moment.utc().isSameOrBefore(moment.utc(currentDate),'day'))
                                                                       enableDate = true;
                                                                })
                                                                return enableDate;
                                                            }}
                                                        />
                                                        <InputGroupAddon addonType="append">
                                                        <InputGroupText>
                                                            <span className="glyphicon glyphicon-calendar">
                                                            <i className="fa fa-calendar" />
                                                            </span>
                                                        </InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </FormGroup> 
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md="6" className={(oneTimePickupDay && oneTimePickUpTime) ? "":"d-none"}>
                                        <Row>
                                            <Col sm="4"  className="mt-2">
                                                Delivery Day
                                            </Col>
                                            <Col sm="8">
                                                <FormGroup>
                                                    <InputGroup 
                                                        name="oneTimeDeliveryDatetimePicker"
                                                        className="date" 
                                                        id="oneTimeDeliveryDatetimePicker"
                                                    >
                                                        <ReactDatetime
                                                            value={oneTimeDeliveryDay} 
                                                            timeFormat={false}
                                                            closeOnSelect={true}
                                                            onChange={this.handleOneTimeDeliveryDay}
                                                            inputProps={{
                                                                className: "form-control",
                                                                placeholder: "Delivery Day",
                                                            }}

                                                            renderDay={( props, currentDate, selectedDate)=>{
                                                                if(listOfOneTimeDeliveryDates.length){
                                                                    let highLightDate = false;
                                                                
                                                                    listOfOneTimeDeliveryDates.forEach(d=>{
                                                                        if(moment.utc(currentDate).isSame(d,'day'))
                                                                            highLightDate = true;
                                                                     })
     
                                                                     if(highLightDate)
                                                                        props.className += " rdtActive color-white fw-strong";
                                                                     
                                                                }
                                                                
                                                                
                                                                return <td {...props}>{currentDate.date() }</td>;
                                                            }}

                                                            isValidDate={currentDate=>{
                                                                let enableDate = false;
                                                                listOfOneTimeDeliveryDates.forEach(d=>{
                                                                    if( moment.utc(currentDate).isSame(d,'day'))
                                                                       enableDate = true;
                                                                })
                                                                return enableDate;
                                                            }}
                                                        />
                                                        <InputGroupAddon addonType="append">
                                                        <InputGroupText>
                                                            <span className="glyphicon glyphicon-calendar">
                                                            <i className="fa fa-calendar" />
                                                            </span>
                                                        </InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </FormGroup> 
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="6"  className={(oneTimePickupDay) ? "":"d-none"}>
                                        <Row>
                                            <Col sm="4">
                                                Pickup Time
                                            </Col>
                                            <Col sm="8" md="8" lg="8">
                                                {  (oneTimePickupTimeslotList.length) ? 
                                                   oneTimePickupTimeslotList.map((timeslot)=>{
                                                       return(
                                                            <Row key={`pickup_${timeslot.id}`}>
                                                                <Col className="mb-2">
                                                                    <Button
                                                                        className="btn-round mr-1"
                                                                        color="danger"
                                                                        outline = {oneTimePickUpTimeId === timeslot.id ? false:true}
                                                                        block
                                                                        type="button"
                                                                        onClick={()=>this.setOneTimePickupTime(timeslot)}
                                                                    >
                                                                        {`${timeslot.formattedTime}`}
                                                                    </Button>
                                                                </Col>
                                                            </Row> 
                                                       )
                                                   }) 
                                                   :
                                                   "No timeslots available for the selected date"
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md="6" className={(oneTimeDeliveryDay) ? "" : "d-none"}>
                                        <hr className="d-block d-md-none" />
                                        <Row>
                                            <Col sm="4">
                                                Delivery Time
                                            </Col>
                                            <Col sm="8" md="8" lg="8">
                                                {
                                                   oneTimeDeliveryTimeslotList.map((timeslot)=>{
                                                       return(
                                                            <Row key={`delivery_${timeslot.id}`}>
                                                                <Col className="mb-2">
                                                                    <Button
                                                                        className="btn-round mr-1"
                                                                        color="danger"
                                                                        outline = {oneTimeDeliveryTimeId === timeslot.id ? false:true}
                                                                        block
                                                                        type="button"
                                                                        onClick={()=>this.setOneTimeDeliveryTime(timeslot)}
                                                                    >
                                                                        {`${timeslot.formattedTime}`}
                                                                    </Button>
                                                                </Col>
                                                            </Row> 
                                                       )
                                                   }) 
                                                }
                                                
                                                
                                            </Col>
                                        </Row>                                        
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {/* End of One-time pickup & Delivery */}  

                        {/* Delivery Instructions*/}
                        <Row className={deliveryInstructionsDisplayCSS}>
                            <Col>
                                <hr/>
                                <h6>Delivery Preference:</h6>
                                <Row>
                                    <Col xs="7" lg="10">
                                        I give my permission to leave my laundry and accept responsibility for the package once it is delivered.	
                                    </Col>  
                                    <Col xs="4" sm="4" md="4" lg="2">
                                        <Button
                                            className="btn-round mr-1"
                                            color="danger"
                                            outline = {(deliveryPreference === true) ? false:true}
                                            block
                                            type="button"
                                            onClick={(e)=>{this.setDeliveryPreference(e,true)}}
                                            
                                        >
                                            Select
                                        </Button>
                                    </Col>  
                                </Row>
                                <Row className="mt-2">
                                    <Col xs="7" lg="10">
                                        I would prefer being present with my laundry, do not leave my laundry unattended.	
                                    </Col>  
                                    <Col xs="4" sm="4" md="4" lg="2">
                                        <Button
                                            className="btn-round mr-1"
                                            color="danger"
                                            outline = {(deliveryPreference === false) ? false:true}
                                            block
                                            type="button"
                                            onClick={(e)=>{this.setDeliveryPreference(e,false)}}
                                        >
                                            Select
                                        </Button>
                                    </Col>  
                                </Row>
                                
                            </Col>
                        </Row>
                        {/* End of Delivery Instructions */}

                        {/* Driver Instructions*/}
                        <Row className={driverInstructionsDisplayCSS}>
                            <Col>
                                <hr/>
                                <h6>Driver Instructions:</h6>
                                <Row>
                                    <Col>
                                        <p>
                                        Please list any details you would like our driver to have prior to pickup/delivery each week. These instructions will remain valid for our driver until you delete or change them (ie. leave package outside my door this week.)
                                        </p>
                                        <Input
                                            className="border-input"
                                            placeholder=""
                                            rows="3"
                                            type="textarea"
                                            maxLength="200"
                                            name="deliveryInstructions"
                                            value={deliveryInstructions}
                                            onChange = {this.handleChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {/* End of Driver Instructions */}

                        {/* Laundry Special Instructions*/}
                        <Row className={`${specialLaundryInstructionsDisplayCSS} d-none`}>
                            <Col>
                                <hr/>
                                <h6>Enter laundry special requests:</h6>
                                <Row>
                                    <Col>
                                        <Input
                                            className="border-input"
                                            placeholder=""
                                            rows="3"
                                            type="textarea"
                                            maxLength="200"
                                            name="laundrySpecialRequest"
                                            value={laundrySpecialRequest}
                                            onChange = {this.handleChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {/* End of Laundry Special Instructions */}

                        {/* Text and Email Notification */}
                        <Row className={textAndEmailInstructionsDisplayCSS}>
                            <Col>
                                <hr/>
                                <h6>Text Notification Setting:</h6>
                                <Row>
                                    <Col lg="6">
                                       <Row className="mb-2">
                                           <Col xs="4">
                                              Cellphone:
                                           </Col>
                                           <Col xs="8">
                                                {user && user.cell}
                                                <i id="icon_info_cellPhone" className="fa fa-info-circle mr-1 text-info d-none" />  
                                           </Col>
                                           <UncontrolledTooltip
                                                delay={0}
                                                placement="right"
                                                target={`icon_info_cellPhone`}
                                                className="d-none"
                                            >
                                            We will have a modal to display info saying that we send text to your primary phone. Please update your cellphone in your profile section. 
                                            </UncontrolledTooltip>
                                       </Row>
                                       <Row>
                                           <Col xs="4">
                                              Notification:
                                           </Col>
                                           <Col xs="8">
                                                <Switch
                                                    onColor="success"
                                                    offColor="success"
                                                    value={enrollForTextNotification}
                                                    onChange={this.handleTextNofificationSwitch}
                                                />
                                           </Col>
                                       </Row>
                                    </Col>
                                    <Col lg="6" className="d-none">
                                        <hr className="d-block d-md-none" />
                                        
                                        <Row className="mb-2">
                                            <Col xs="4">
                                                Email:
                                            </Col>
                                            <Col xs="8">
                                                {user && user.email}
                                                <i id="icon_info_email" className="fa fa-info-circle mr-1 text-info d-none" /> 
                                                <UncontrolledTooltip
                                                delay={0}
                                                placement="right"
                                                target={`icon_info_email`}
                                                className="d-none"
                                                >
                                                We will have a modal to display info saying that we send email to your primary email address used for login. Please update your email in your profile section. 
                                                </UncontrolledTooltip> 
                                            </Col> 
                                        </Row>
                                        <Row>
                                           <Col xs="4">
                                              Email Notification:
                                           </Col>
                                           <Col xs="8">
                                                <Switch
                                                    onColor="success"
                                                    offColor="success"
                                                    value={enrollForEmailNotification}
                                                    onChange = {this.handleEmailNofificationSwitch}
                                                    disabled={true}
                                                />
                                            </Col>
                                       </Row>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col><strong>I agree to allow Lazybones to send text notifications. Message and data rates may apply. To opt out, switch it to off.</strong></Col>
                                </Row>
                            </Col>
                        </Row>

                        {/* End of Text and Email Notification */}

                        

                    
                    </div>
                    
                    <div className={`${displaySubmit} modal-footer`}>
                        <div className="left-side">
                            <Button
                            className="btn-link"
                            color="default"
                            type="button"
                            onClick={() => handleScheduleModalDisplay(false)}
                            >
                            Cancel
                            </Button>
                        </div>
                        <div className="divider" />
                        <div className="right-side">
                            <Button className="btn-link" color="danger" type="button" onClick={()=>this.saveSchedule()}>
                                Save & Schedule
                            </Button>
                        </div>
                    </div>
                </Modal>
        );
    }
}

export default StudentAccountLaundrySchedulingModal;