import React from "react";
import moment from "moment";
import _ from "lodash";

// reactstrap components
import {
    Button,
    Row,
    Col,
    FormGroup, 
    Modal, 
} from "reactstrap";

const getUpcomingPickupDisplayText = (laundryOrder)=>{
    let displayText = "";

    if(!_.isEmpty(laundryOrder) && !_.isEmpty(laundryOrder.nextPickupDeliverySchedule.pickup)){
        const {pickup,delivery} = laundryOrder.nextPickupDeliverySchedule;
        const pickupDate = moment(pickup.date);
        const deliveryDate = moment(delivery.date);
       
        displayText = <p>
        <strong>Pickup Day and Time:</strong> {`${pickupDate.format('dddd (MMM Do)')} between ${pickup.startTime} - ${pickup.endTime}`}.<br/>
        <strong>Delivery Day and Time:</strong> {`${deliveryDate.format('dddd (MMM Do)')} between ${delivery.startTime} - ${delivery.endTime}`}
       </p>
        
    }

    return displayText;
}

const ResidentialCustomerAccountCancelUpcomingPickupModal = (props)=>{
    const {handleCancelUpcomingPickup,handleModalDisplayFlag,laundryOrder,modalDisplayFlag} = props;
    const upcomingPickupDisplayText = getUpcomingPickupDisplayText(laundryOrder);

    return(
        <Modal size="lg" isOpen={modalDisplayFlag} toggle={() =>{handleModalDisplayFlag(false)}}>
                <div className="modal-header">
                    <button
                        className="close"
                        type="button"
                        onClick={() =>{handleModalDisplayFlag(false)}}
                    >
                        <span>×</span>
                    </button>
                    <h5
                        className="modal-title text-center"
                        id="exampleModalLabel"
                    >
                        Cancel Upcoming Pickup/Delivery Schedule
                    </h5>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col>
                            <h6>Are you sure you want to cancel your upcoming pickup?</h6>
                            {upcomingPickupDisplayText}
                        </Col>
                    </Row>
                    
                </div>
                <div className={`modal-footer`}>
                    <div className="left-side">
                        <Button
                        className="btn-link"
                        color="default"
                        type="button"
                        onClick={() => {handleModalDisplayFlag(false)}}
                        >
                        No
                        </Button>
                    </div>
                    <div className="divider" />
                    <div className="right-side">
                        <Button className="btn-link" color="danger" type="button" onClick={()=>{handleCancelUpcomingPickup()}}>
                            Yes, Cancel upcoming pickup
                        </Button>
                    </div>
                </div>
        </Modal>
    );
}

export default ResidentialCustomerAccountCancelUpcomingPickupModal;