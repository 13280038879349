import React,{Component} from "react";
import _ from "lodash";

// reactstrap components
import {
    Button,
    Row,
    Col,
    Modal, 
    Input, 
    
  } from "reactstrap";

class ResidentialCustomerAccountLaundryModifyDeliveryWaiverAndDriverInstructions extends Component{
    

    constructor(){
        super();
        this.state = {
            data:{
                deliveryPreference:"",
                deliveryInstructions:"",
            },
            errors:{},
            
            
        }
    }

    componentDidUpdate(prevProps,prevState){
        this.mapModelToView(prevProps,prevState);
    }

    closeWindow = ()=>{
        
        const { 
            handleModalDisplayFlag,
            user 
        } = this.props
        
        const {laundry_waiver:laundryWaiver,delivery_instructions:deliveryInstructions} = user;
        let deliveryPreference = ""
        if(laundryWaiver === 1)
            deliveryPreference = true;
        else if(laundryWaiver === 0)
            deliveryPreference = false;
            
        const data = {
            deliveryPreference,
            deliveryInstructions
        };
        this.setState({data})
        
        handleModalDisplayFlag(false);
    }

    handleChange = ({currentTarget:input})=>{
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[input.name] = input.value;
        this.setState({data,errors});
        
    }

    mapModelToView = (prevProps)=>{
        
        /*
            If props have changed then compare it with previous props and then only update it
        */
       if((this.props.user &&  !prevProps.user)
            ||
            (this.props.user && 
            prevProps.user && 
            !_.isEqual(this.props.user,prevProps.user)
            )
        ){
            const {laundry_waiver:laundryWaiver,delivery_instructions:deliveryInstructions} = this.props.user;
            let deliveryPreference = ""
            if(laundryWaiver === 1)
                deliveryPreference = true;
            else if(laundryWaiver === 0)
                deliveryPreference = false;
            
            const data = {
                deliveryPreference,
                deliveryInstructions
            };
            this.setState({data})
        }
    }
    
    /*  Send state data to the parent laundry detail */
    saveForm = ()=>{
        const data = {...this.state.data}
        this.props.handleSaveOperation(data);
        return;
    }

    setDeliveryPreference = (e,waiverFlag)=>{
        e.preventDefault();
        const data = {...this.state.data};
        data.deliveryPreference = waiverFlag;
        this.setState({data});
     }

    render(){
        const { 
            deliveryPreference,
            deliveryInstructions,
        } = this.state.data
        
        
        const { 
            handleModalDisplayFlag,
            modalDisplayFlag,
            laundryOrder,
            user 
        } = this.props


        return(
                <Modal size="lg" isOpen={modalDisplayFlag} toggle={() => this.closeWindow()}>
                    <div className="modal-header">
                    <button
                        className="close"
                        type="button"
                        onClick={() => this.closeWindow()}
                    >
                        <span>×</span>
                    </button>
                    <h5
                        className="modal-title text-center"
                        id="exampleModalLabel"
                    >
                      Update Delivery Waiver and Driver Instructions
                    </h5>
                    </div>
                    <div className="modal-body">
                        
                        {/* Delivery Instructions*/}
                        <Row>
                            <Col>
                                <h6>Delivery Preference:</h6>
                                <Row>
                                    <Col xs="7" lg="10">
                                        I give my permission to leave my laundry and accept responsibility for the package once it is delivered.	
                                    </Col>  
                                    <Col xs="4" sm="4" md="4" lg="2">
                                        <Button
                                            className="btn-round mr-1"
                                            color="danger"
                                            outline = {(deliveryPreference === true) ? false:true}
                                            block
                                            type="button"
                                            onClick={(e)=>{this.setDeliveryPreference(e,true)}}
                                            
                                        >
                                            {(deliveryPreference === true) ? "Selected":"Select"}
                                        </Button>
                                    </Col>  
                                </Row>
                                <Row className="mt-2">
                                    <Col xs="7" lg="10">
                                        I would prefer being present with my laundry, do not leave my laundry unattended.	
                                    </Col>  
                                    <Col xs="4" sm="4" md="4" lg="2">
                                        <Button
                                            className="btn-round mr-1"
                                            color="danger"
                                            outline = {(deliveryPreference === false) ? false:true}
                                            block
                                            type="button"
                                            onClick={(e)=>{this.setDeliveryPreference(e,false)}}
                                        >
                                            {(deliveryPreference === false) ? "Selected":"Select"}
                                        </Button>
                                    </Col>  
                                </Row>
                                
                            </Col>
                        </Row>
                        {/* End of Delivery Instructions */}

                        {/* Driver Instructions*/}
                        <Row>
                            <Col>
                                <hr/>
                                <h6>Drive Instructions for Delivery:</h6>
                                <Row>
                                    <Col>
                                        <p>
                                          Driver Instructions: [Please enter any instructions you have for us regarding your delivery (this info will go directly to our drivers) and check the appropriate box for how you want us to handle your delivery.]  
                                        </p>
                                        <Input
                                            className="border-input"
                                            placeholder=""
                                            rows="3"
                                            type="textarea"
                                            maxlength="200"
                                            name="deliveryInstructions"
                                            value={deliveryInstructions}
                                            onChange = {this.handleChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {/* End of Driver Instructions */}

                        

                        

                    
                    </div>
                    
                    <div className={`modal-footer`}>
                        <div className="left-side">
                            <Button
                            className="btn-link"
                            color="default"
                            type="button"
                            onClick={() => this.closeWindow()}
                            >
                            Cancel
                            </Button>
                        </div>
                        <div className="divider" />
                        <div className="right-side">
                            <Button className="btn-link" color="danger" type="button" onClick={()=>this.saveForm()}>
                                Save Changes
                            </Button>
                        </div>
                    </div>
                </Modal>
        );
    }
}

export default ResidentialCustomerAccountLaundryModifyDeliveryWaiverAndDriverInstructions;