
import _ from "lodash";
import http from "./httpService";

import {renderError} from 'utils/errorUtils';

const apiLaundryEndpoint = "/laundrySettings";
const apiCommonSettingEndpoint = "/commonSettings";
const apiUsersEndpoint = "/users";


const listOfResidentialCitiesByState = [
    {
        id:1,
        name:"Colorado",
        statePrefix:"CO",
        cities:[
           {
                id:1,
                name:"Boulder",
                icon:"nc-delivery-fast",
                colorIcon:"icon-warning",
                schoolImage:"assets/mylazybones/img/uw_madison.jpg",
                schoolFlagImage:"assets/mylazybones/img/uw_madison_flag.png",
                pricing:[
                    {
                          id:1,
                          type:"by-the-pound",
                          description:"By the Pound",
                          price: [
                                {
                                      id:41,
                                      price:1.99,
                                      pricePerPound: 1.99,
                                      name:"By Pound",
                                      description:"By Pound"
                                }
                          ]
                                
                                
                    },
                ],
                insurance:[
                    {
                        id:1,
                        type:"Standard",
                        price: 0.00     
                    },
                    {
                        id:2,
                        type:"Plus",
                        price: 20.00     
                    },
                    {
                        id:3,
                        type:"Premium",
                        price: 40.00     
                    },

                ]
                
           },
           {
                id:2,
                name:"Denver",
                icon:"nc-credit-card",
                colorIcon:"icon-danger",
                schoolImage:"assets/mylazybones/img/placeholder_img",
                schoolFlagImage:"assets/mylazybones/img/placeholder_img",
                pricing:[
                    {
                          id:2,
                          type:"by-the-pound",
                          description:"By the Pound",
                          price: [
                                {
                                      id:42,
                                      price:2.99,
                                      pricePerPound: 2.99,
                                      name:"By Pound",
                                      description:"By Pound"
                                }
                          ]
                                
                                
                    },
                ],
                insurance:[
                    {
                        id:1,
                        type:"Standard",
                        price: 0.00     
                    },
                    {
                        id:4,
                        type:"Plus",
                        price: 40.00     
                    },
                    {
                        id:5,
                        type:"Premium",
                        price: 50.00     
                    },

                ]

          },
             
        ]

    },

    {
        id:2,
        name:"Illinois",
        statePrefix:"IL",
        cities:[
           {
                id:3,
                name:"Chicago",
                icon:"nc-delivery-fast",
                colorIcon:"icon-warning",
                schoolImage:"assets/mylazybones/img/placeholder_img",
                schoolFlagImage:"assets/mylazybones/img/placeholder_img",
                pricing:[
                    {
                          id:3,
                          type:"by-the-pound",
                          description:"By the Pound",
                          price: [
                                {
                                      id:43,
                                      price:3.99,
                                      pricePerPound: 3.99,
                                      name:"By Pound",
                                      description:"By Pound"
                                }
                          ]
                                
                                
                    },
                ],
                insurance:[
                    {
                        id:1,
                        type:"Standard",
                        price: 0.00     
                    },
                    {
                        id:6,
                        type:"Plus",
                        price: 40.00     
                    },
                    {
                        id:7,
                        type:"Premium",
                        price: 50.00     
                    },

                ]
                
           },
           {
                id:4,
                name:"Des Plaines",
                icon:"nc-credit-card",
                colorIcon:"icon-danger",
                schoolImage:"assets/mylazybones/img/placeholder_img",
                schoolFlagImage:"assets/mylazybones/img/placeholder_img",
                pricing:[
                    {
                          id:4,
                          type:"by-the-pound",
                          description:"By the Pound",
                          price: [
                                {
                                      id:44,
                                      price:4.99,
                                      pricePerPound: 2.99,
                                      name:"By Pound",
                                      description:"By Pound"
                                }
                          ]
                                
                                
                    },
                ],
                insurance:[
                    {
                        id:1,
                        type:"Standard",
                        price: 0.00     
                    },
                    {
                        id:8,
                        type:"Plus",
                        price: 40.00     
                    },
                    {
                        id:9,
                        type:"Premium",
                        price: 50.00     
                    },

                ]

          },
             
        ]

    }
    

      

];


async function cancelLaundryUpcomingPickup(formData){
    try{
        const {data:result} = await http.post(`${apiUsersEndpoint}/residential/laundry/cancelUpcomingPickup`,formData);
        return result;

    }catch(ex){
        console.log(ex);
    }
}


async function cancelLaundryRecurringSchedule(formData){
    try{
        const {data:result} = await http.post(`${apiUsersEndpoint}/residential/laundry/cancelRecurringSchedule`,formData);
        return result;

    }catch(ex){
        console.log(ex);
    }
}

async function getListOfResidentialCitiesByState(){
   try{
        const {data:listOfResidentialCitiesByState} = await http.get(`${apiLaundryEndpoint}/getListOfResidentialCitiesByStateForLaundry`);
        console.log(listOfResidentialCitiesByState);
        return listOfResidentialCitiesByState;
        //return listOfResidentialCitiesByState.filter(s => s);
    }catch(ex){
        renderError(ex);
    }

}

async function getMeOnThisWeeksLaundryPickupSchedule(formData){
    try{
        const {data:result} = await http.post(`${apiUsersEndpoint}/residential/laundry/getMeOnThisWeeksLaundryPickupSchedule`,formData);
        return result;

    }catch(ex){
        console.log(ex);
    }
}

async function getMyAccountProductList(){
    try{
          const {data:productList} = await http.get(`${apiUsersEndpoint}/myaccount/residential/getProducts`);
          //console.log(listOfDormitoryBySchools);

          return productList;

    }catch(ex){
       console.log(ex);
    }
}

async function saveLaundryCustomerNotesForUpcomingPickup(formData){
    try{
        const {data:returnData} = await http.post(`${apiUsersEndpoint}/residential/laundry/saveLaundryCustomerNotesForUpcomingPickup`,formData);
        return returnData;

    }catch(ex){
        console.log(ex);
    }
}

async function saveLaundryDeliveryWaiverAndDriverInstructions(formData){
    try{
        const {data:returnData} = await http.post(`${apiUsersEndpoint}/residential/laundry/saveLaundryDeliveryWaiverAndDriverInstructions`,formData);
        return returnData;

    }catch(ex){
        console.log(ex);
    }
}

async function saveLaundryModifiedRecurringSchedule(formData){
    try{
        const {data:scheduleResponse} = await http.post(`${apiUsersEndpoint}/residential/laundry/saveLaundryModifiedRecurringSchedule`,formData);
        return scheduleResponse;

    }catch(ex){
        console.log(ex);
    }
}

async function saveLaundrySchedule(formData){
    try{
        const {data:scheduleResponse} = await http.post(`${apiUsersEndpoint}/residential/laundry/saveResidentialLaundrySchedule`,formData);
        return scheduleResponse;

    }catch(ex){
        console.log(ex);
    }
}

async function saveLaundryModifiedOneTimeUpcomingPickup(formData){
    try{
        const {data:scheduleResponse} = await http.post(`${apiUsersEndpoint}/residential/laundry/saveLaundryModifiedOneTimeUpcomingPickup`,formData);
        return scheduleResponse;

    }catch(ex){
        console.log(ex);
    }
}

async function saveLaundryExistingUserSignup(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/residential/laundry/saveResidentialLaundryExistingUserSignup`,formData);
    return returnData;
}



export default {
    cancelLaundryRecurringSchedule,
    cancelLaundryUpcomingPickup,
    getListOfResidentialCitiesByState,
    getMeOnThisWeeksLaundryPickupSchedule,
    getMyAccountProductList,
    saveLaundrySchedule,
    saveLaundryCustomerNotesForUpcomingPickup,
    saveLaundryDeliveryWaiverAndDriverInstructions,
    saveLaundryModifiedRecurringSchedule,
    saveLaundryModifiedOneTimeUpcomingPickup,
    saveLaundryExistingUserSignup,
}

