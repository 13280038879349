/*
Save messages as read in localstorage
*/

import _ from "lodash";
import http from "./httpService";
import moment from "moment"

const apiEndpoint = "/commonSettings";
const localStorageMessageKey = 'messages';


const getMessagesFromLocalCache = ()=>{
    let messages = localStorage.getItem(localStorageMessageKey)
    if(messages){
        messages = JSON.parse(messages)
    }else
        messages = []

    return messages
}

const getMessagesFromServer = async ()=>{
    const {data:messages} = await http.get(`${apiEndpoint}/getMessages`);
    return messages;   
}

//Clean up application local storage
const removeExpiredMessagesFromLocalCache = ()=>{
    let messages = getMessagesFromLocalCache()
    const nonExpiredMessages = messages.filter(message=>{
        const {expirationDate} = message
        return moment(expirationDate).isSameOrAfter(moment(),'day')
    })

    localStorage.setItem(localStorageMessageKey,JSON.stringify(nonExpiredMessages))
}


const saveMessageToLocalCache = ({notificationId,expirationDate})=>{
   let messages = getMessagesFromLocalCache()
   const updatedMessageList  = [...messages,{notificationId,expirationDate}]
   localStorage.setItem(localStorageMessageKey,JSON.stringify(updatedMessageList))
}

export default {
    getMessagesFromLocalCache,
    getMessagesFromServer,
    removeExpiredMessagesFromLocalCache,
    saveMessageToLocalCache,
}