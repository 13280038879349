import React,{Component} from "react";
import Select from "react-select";
import _ from "lodash";
import Joi from "@hapi/joi"

import CreditCardHelper from "../../../../helpers/CreditCardHelper";
import {scrollTop} from '../../../../utils/helperUtils';


import {getUSStateList} from "services/dropDownListsService";
import {getCountryList} from "services/dropDownListsService";
import SchoolService from "services/schoolService";
import SuppliesService from "services/suppliesService";

import FormErrorWidget from "components/Common/FormErrorWidget";



import {
    Button,
    Container,
    Card,
    CardBody,
    CardFooter,
    CardImg,
    CardImgOverlay,
    CardLink,
    CardTitle,
    CardSubtitle,
    CardText,
    ListGroupItem,
    ListGroup,
    Row,
    Col,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    FormText
    
} from "reactstrap";

import {
    CardElement,
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    injectStripe
} from 'react-stripe-elements';

const stripeInputFieldStyling = {
    style: {
        base: {
        fontWeight:'100',
        fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',  
        '::placeholder': {
            color: '#999',
            fontWeight:'100',
        }
        }
    },
};
    

class SuppliesCheckoutDeliveryAndPaymentLoggedInUser extends Component{
    validationSchema = Joi.object({
        creditCardName:Joi.string().required().label('Credit Card Holders Name'),
    });

    billingAddressSchema = Joi.object({
        addressLine1: Joi.string().required().label('Billing Address Line 1'),
        addressLine2: Joi.any().optional(),
        city:Joi.string().required().label('City'),
        state:Joi.object().keys({
            value:Joi.string().required(), 
            label:Joi.any().optional(),
        }),
        postalCode:Joi.string().required().label('Postal Code'),
        country:Joi.object().keys({
            value:Joi.string().required(), 
            label:Joi.any().optional(),
        }), 
    }) ;

    /*Validation custom messages to map with Job error object */
    errorMessages = {
       
    }

    constructor(){
        super();

        this.state = {
            stateList:[{ value: "", label: "", isDisabled: true }],
            countryList:[{ value: "", label: "", isDisabled: true }],
            schoolList:"",
            dormitoryList:[{ value: "", label: "", isDisabled: true }],
            data:{
                isStudent:"",
                school:"",
                deliveryAddress:{
                    isDormitory:"",
                    dormitoryName:"", //{value:"", label:""} this corresponds to the react-select value setup
                    dormitoryRoomNumber:"",
                    addressLine1:"",
                    addressLine2:"",
                    city:"",
                    state:"",
                    postalCode:"",
                    country:"USA", 
                },
                billingAddress:{
                    addressLine1:"",
                    addressLine2:"",
                    city:"",
                    state:"",
                    postalCode:"",
                    country:"",
                },

                password:"",    
                confirmPassword:"", 
                creditCardName:"",
                creditCardNumber:"",
                creditCardExpirationMonth:"",
                creditCardExpirationMonthDropDownDisplay:"",
                creditCardExpirationYear:"",
                creditCardExpirationYearDropDownDisplay:"",
                creditCardCCV:"",
            },
            errors:{}
        } 
    }

    componentDidMount(){
        this.mapModelToView();
        document.getElementById("sectionArea").scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });
    }

    handleChange = ({currentTarget:input})=>{
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[input.name] = input.value;
        this.setState({data,errors});
        
    }

    handleChangeAddress = (e,addressType)=>{
        const {name,value} = e.currentTarget;
        //console.log(`${name} : ${value}`);
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        
        if(name === "isDormitoryDeliveryAddress")
            address.isDormitory = value 
        else
            address[name] = value;

        data[addressType] = address;

        this.setState({data});
        
    }

    handleSelectDropDown = (selectedValueObject,name,dropDownDisplayInput)=>{
        const value = selectedValueObject.value;
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[name] = value;
        data[dropDownDisplayInput] = selectedValueObject;
        this.setState({data,errors});
    }

    handleSubmit = async (e)=>{
        e.preventDefault();
        try{
            const {onClickOfNextButtonFromDeliveryAddressAndPaymentInformation} = this.props;
            const loginAndPaymentFormData = {...this.state.data};
            const  {addressLine1:address_line1,
                    addressLine2:address_line2,
                    city:address_city,
                    state,
                    postalCode:address_zip,
                    country} = loginAndPaymentFormData.billingAddress;
            
            let errors = await this.validate();
            
            let stripeResult = "";
            if (this.props.stripe) {
                stripeResult = await this.props.stripe.createToken(
                    {
                        type: 'card', 
                        name: loginAndPaymentFormData.creditCardName,
                        address_line1,
                        address_line2,
                        address_city,
                        address_state:state.value,
                        address_zip,
                        address_country:country.value,
                    }
                );
                console.log(stripeResult);
                if(stripeResult.error){
                    errors = (!_.isEmpty(errors)) ? errors : {};
                    errors["invalidCard"] = stripeResult.error.message;
                }
                else
                    loginAndPaymentFormData.stripeToken = stripeResult.token
            } 
            this.setState({errors: errors || {}});
            if(errors){
                scrollTop('sectionArea')
                return;
            } 

            onClickOfNextButtonFromDeliveryAddressAndPaymentInformation(loginAndPaymentFormData);
            return;
        }
        catch(ex){
            console.log(ex);
        }
        
        
    }

    mapModelToView = ()=>{
        const stateList = getUSStateList();
        const countryList = getCountryList();
          
        const {user} = this.props;
        const { creditCardName,  
                billingAddress
        } = user.paymentInformation;
        
        
        const billingAddressObject = {...billingAddress};

        if(billingAddressObject.state){
            billingAddressObject.state = _.find(stateList,{'value':billingAddressObject.state});
        }

        if(billingAddressObject.country){
            billingAddressObject.country = _.find(countryList,{'value':billingAddressObject.country});
        }
        
        const data = {
            creditCardName,
            billingAddress:billingAddressObject,
        }

        this.setState(
            {
                stateList,
                countryList,
                data,
            }
        );
    }

    setAddressState = (value,addressType)=>{
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        address.state = value;
        data[addressType] = address;

        this.setState({data});

    }

    setCountry = (value)=>{
        const addressType = "billingAddress";
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        address.country = value;
        data[addressType] = address;

        this.setState({data});

    }

    validate=  ()=>{
        try{
            const options = {abortEarly:false,allowUnknown:true};
            //console.log(this.state.data);
            let {error:formErrorList} = this.validationSchema.validate(this.state.data,options);
            let {error:billingAddressErrorList} = this.billingAddressSchema.validate(this.state.data.billingAddress,options);

            
            if(formErrorList === undefined && billingAddressErrorList === undefined) return null;


            const formErrors = {};
            if(formErrorList){
                formErrorList.details.map(function(errorObject){
                    //console.log(errorObject);
                    if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                        const key = errorObject.context.key;
                        const joiType = errorObject.type;
                        //console.log(this.errorMessages);
                        if(_.has(this.errorMessages, [key, joiType]))
                            return formErrors[errorObject.path[0]] = this.errorMessages[key][joiType];
                        else
                            return formErrors[errorObject.path[0]] = errorObject.message;
                    }
                    else
                      return formErrors[errorObject.path[0]] = errorObject.message;
                }.bind(this));
            }

            const billingAddressErrors = {};
            if(billingAddressErrorList){
                billingAddressErrorList.details.map(
                    function(errorObject){
                        //console.log(errorObject);
                        if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                            const key = errorObject.context.key;
                            const joiType = errorObject.type;
                            //console.log(this.errorMessages);
                            if(_.has(this.errorMessages, [key, joiType]))
                                return billingAddressErrors[errorObject.path[0]] = this.errorMessages[key][joiType];
                            else
                                return billingAddressErrors[errorObject.path[0]] = errorObject.message;
                        }
                        else
                          return billingAddressErrors[errorObject.path[0]] = errorObject.message;
                    }.bind(this)
                );
            }
            
            const errors = {...formErrors,...billingAddressErrors} 
            
            return Object.keys(errors).length > 0 ? errors:null;
        }catch(ex){
            console.log(ex);
        }
    }


    render(){
        const {data,errors} = this.state;
        const {
            creditCardName,
        } = data;
        
        return(
            <div className="main">
                    <div id="sectionArea" className="section section-gray">
                        <Container id="mainContentArea">
                            <Row className="title-row justify-content-between">
                                <Col lg="5">
                                    <h3 className="shop">Checkout </h3>
                                </Col>
                                <Col lg="6">
                                    <div className="text-lg-right">
                                        <span className="text-muted">Step:</span>
                                        <Button color="link">
                                        <i className="fa fa-shopping-cart" /> Delivery Address and Credit Card Information
                                        </Button>
                                    </div>
                                </Col>
                            </Row> 
                            <Row className="title-body-row">
                                <Col md="8">
                                    <Card>
                                        <CardBody>
                                            {/*Error display */}
                                            {!_.isEmpty(errors) &&
                                                <FormErrorWidget
                                                errors={errors}
                                                />
                                            }
                                            {/* End of Error display */}
                                            <Form>
                                                <div className="mt-5">
                                                    <h6 className="card-category text-left">
                                                        Payment Information 
                                                        <i className="fa fa-cc-amex fa-3 ml-1 " aria-hidden="true"></i>
                                                        <i className="fa fa-cc-mastercard fa-3 ml-1 " aria-hidden="true"></i>
                                                        <i className="fa fa-cc-visa fa-3 ml-1 " aria-hidden="true"></i>
                                                        <i className="fa fa-cc-discover fa-3 ml-1 " aria-hidden="true"></i>
                                                        <i className="fa fa-cc-jcb fa-3 ml-1 " aria-hidden="true"></i>
                                                    </h6>
                                                    <hr/>

                                                    <FormGroup row>
                                                        <Label for="creditCardName" sm={4}>Name on Credit Card</Label>
                                                        <Col sm={6}>
                                                            <Input 
                                                                type="text" 
                                                                name="creditCardName" 
                                                                id="creditCardName" 
                                                                placeholder="Name on Credit Card" 
                                                                value={creditCardName} 
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                    </FormGroup>

                                                    <FormGroup row>
                                                            <Label for="card-number" sm={4}>Credit Card Number</Label>
                                                            <Col id="card-number" sm={6}>
                                                                <CardNumberElement 
                                                                    {...stripeInputFieldStyling}
                                                                />
                                                            </Col>
                                                    </FormGroup>
                                                    
                                                    <FormGroup row>
                                                            <Label for="card-expiration-date" sm={4}>Expiration Date</Label>
                                                            <Col id="card-expiration-date" sm={6}>
                                                                <CardExpiryElement 
                                                                    {...stripeInputFieldStyling}
                                                                />
                                                            </Col>
                                                    </FormGroup> 
                                                    
                                                    <FormGroup row>
                                                            <Label for="card-cvc" sm={4}>CVC</Label>
                                                            <Col id="card-cvc" sm={6}>
                                                                <CardCVCElement 
                                                                    {...stripeInputFieldStyling}
                                                                />
                                                            </Col>
                                                    </FormGroup> 

                                                    <h6 className="card-category text-left mt-2">Billing Address</h6>
                                                    <hr/>

                                                    <FormGroup row>
                                                        <Label for="addressLine1" sm={2}>Address Line 1</Label>
                                                        <Col sm={10}>
                                                            <Input 
                                                            type="text" 
                                                            name="addressLine1" 
                                                            id="addressLine1" 
                                                            placeholder="Address Line 1" 
                                                            value={this.state.data.billingAddress.addressLine1} 
                                                            onChange={e=>{this.handleChangeAddress(e,"billingAddress")}}
                                                            />
                                                        </Col>
                                                        
                                                    </FormGroup>
                                                    
                                                    
                                                    <FormGroup row>
                                                        <Label for="addressLine2" sm={2}>Address Line 2</Label>
                                                        <Col sm={10}>
                                                            <Input 
                                                            type="text" 
                                                            name="addressLine2" 
                                                            id="addressLine2" 
                                                            placeholder="Address Line 2" 
                                                            value={this.state.data.billingAddress.addressLine2} 
                                                            onChange={e=>{this.handleChangeAddress(e,"billingAddress")}}
                                                            />
                                                        </Col>
                                                        
                                                    </FormGroup>
                                                    
                                                    
                                                    
                                                    <FormGroup row>
                                                        <Label for="city" sm={2}></Label>
                                                        <Col xs={12} sm={3}>
                                                            <Input 
                                                            type="text" 
                                                            name="city" 
                                                            id="city" 
                                                            placeholder="City" 
                                                            value={this.state.data.billingAddress.city} 
                                                            onChange={e=>{this.handleChangeAddress(e,"billingAddress")}}
                                                            />
                                                        </Col>
                                                        <Label for="state" xs={12} sm={2} className="d-block d-sm-none"></Label>
                                                        <Col xs={12} sm={4}>
                                                            <FormGroup>
                                                                <Select
                                                                    className="react-select react-select-default"
                                                                    classNamePrefix="react-select"
                                                                    name="state"
                                                                    id="state"
                                                                    value={this.state.data.billingAddress.state}
                                                                    onChange={value => this.setAddressState(value,"billingAddress")}
                                                                    options={this.state.stateList}
                                                                    placeholder="State"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={12} sm={3}>
                                                            <Input 
                                                            type="text" 
                                                            name="postalCode" 
                                                            id="postalCode" 
                                                            placeholder="Zip" 
                                                            value={this.state.data.billingAddress.postalCode} 
                                                            onChange={e=>{this.handleChangeAddress(e,"billingAddress")}}
                                                            />
                                                        </Col>
                                                    </FormGroup>

                                                    <FormGroup row>
                                                        <Label for="country" sm={2}>Country</Label>
                                                        <Col sm={10}>
                                                            <Select
                                                                className="react-select react-select-default"
                                                                classNamePrefix="react-select"
                                                                name="country"
                                                                id="country"
                                                                value={this.state.data.billingAddress.country}
                                                                onChange={value => this.setCountry(value)}
                                                                options={this.state.countryList}
                                                                placeholder="Country"
                                                            />
                                                        </Col>
                                                        
                                                    </FormGroup>
                                                </div> 

                                                <div>
                                                    <FormGroup row  className="mt-5 justify-content-between">
                                                        <Col xs={{ size: 2}}>
                                                            
                                                        </Col>
                                                        <Col xs={{ size: 6}} sm={{ size: 4}} className="text-right">
                                                            <Button 
                                                            className="btn-rounded btn-danger"
                                                            onClick={this.handleSubmit}
                                                            >
                                                            Save & Proceed
                                                            </Button>
                                                        </Col>
                                                    </FormGroup>
                                                </div>
                                                


                                            </Form>
                                        </CardBody>
                                    </Card> 
                                </Col>
                                
                            </Row>
                        </Container>
                    </div>
            </div>
        
        );
    }
}

export default injectStripe(SuppliesCheckoutDeliveryAndPaymentLoggedInUser);