import React, {Component} from "react";
import _ from "lodash";
import {Elements} from 'react-stripe-elements';


// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Media,
    NavItem,
    NavLink,
    Nav,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    TabContent,
    TabPane,
    Container,
    Row,
    Col
} from "reactstrap";

import CustomerPrimaryInformation from "../Common/CustomerPrimaryInformation";
import CredentialInformation from "../Common/CredentialInformation";
import StudentCampusAddressInformation from "../Common/StudentCampusAddressInformation";
import StudentHomeAddressInformation from  "../Common/StudentHomeAddressInformation";
import PaymentInformation from "../Common/PaymentInformation";
import ResidentialAddressInformation from "../Common/ResidentialAddressInformation";

import applicationConstants from "../../../../../../utils/applicationConstants";

const contentDropdown = [
{ value: "how-it-works", label: "How it works" },
{ value: "pricing", label: "Pricing" },
{ value: "faq", label: "FAQ`s" },

];

const faqDropdown = [
{ value: "laundry-care", label: "Laundry Care" },
{ value: "plans", label: "Plans" },
{ value: "pricing-faq", label: "Pricing" },
{ value: "pickup-delivery", label: "Pickup & Delivery" },
{ value: "dry-cleaning", label: "Dry Cleaning" },

];

const ProfileDesktopView = (props)=>{
        let action,actionType;
        ([action] = ["primary-info"]);

        const {
            match,
            user,
            customerPrimaryInfoDisplayProperties,
        } = props;
        
        const {
            customerRole,
            userDb:userDatabaseInformation,
        } = user;

        if(!_.isEmpty(match.params )){
            ({widgetView:actionType} = match.params);
            action  = (actionType !== undefined) ? actionType:action;
        }
        
        const isStudent = (customerRole === applicationConstants.CUSTOMER_TYPE_STUDENT_TEXT) ? true:false;
        const isResidential = (customerRole === applicationConstants.CUSTOMER_TYPE_RESIDENTIAL_TEXT) ? true:false;

        const [contentDisplayArea,setContentDisplayArea] = React.useState("how-it-works");
        const [faqDisplayArea,setFaqDisplayArea] = React.useState("laundry-care");
        
        let viewTab = "1";
    
        const [hTabs, setHTabs] = React.useState("1");
        const [vTabs,setVTabs] = React.useState(viewTab);
        const [pills, setPills] = React.useState("1");

        switch(action){
            case "primary-info":viewTab = "1";
                                break;
            case "update-credentials": viewTab = "2";
                                break;
            case "service-address":  viewTab = "3";
                                 break;
            case "home-address":  viewTab = "4";
                                     break;
            case "payment-info":  viewTab = "5";
                                  break;
    
        }
        
        /*THIS IS WHERE I UDPATE THE COMPONENT */
        if(vTabs !== viewTab){
            setVTabs(viewTab)
        }

        const viewPage = (view)=>{
            return props.history.push(`/myaccount/view-profile/${view}`)
        }
    
    
        const handleContentDisplayArea = (dropDownSelection)=>{
            const selectedValue = dropDownSelection.value;
            setContentDisplayArea(selectedValue);
        }
    
        const handleFaqDisplayArea = (dropDownSelection)=>{
            const selectedValue = dropDownSelection.value;
            setFaqDisplayArea(selectedValue);
        }
     
     
        //console.log(contentDisplayArea);
        
        const contentDisplayAreaSelectionValue = _.find(contentDropdown,{'value':contentDisplayArea});
        const howItWorksActiveCss = (contentDisplayArea === "how-it-works") ? "d-block": "d-none";
        const pricingActiveCss = (contentDisplayArea === "pricing") ? "d-block": "d-none";
        const faqActiveCss = (contentDisplayArea === "faq") ? "d-block": "d-none";
        
        const faqDisplayAreaSelectionValue = _.find(faqDropdown,{'value':faqDisplayArea});
        const laundryCareActiveCss = (faqDisplayArea === "laundry-care") ? "d-block": "d-none";
        const plansActiveCss = (faqDisplayArea === "plans") ? "d-block": "d-none";
        const pricingFaqActiveCss = (faqDisplayArea === "pricing-faq") ? "d-block": "d-none";
        const pickupDeliveryActiveCss = (faqDisplayArea === "pickup-delivery") ? "d-block": "d-none";
        const dryCleaningActiveCss = (faqDisplayArea === "dry-cleaning") ? "d-block": "d-none";
        
        
        return(
            <>
                <Row>
                    <Col md="12" sm="12">
                        <Row>
                            <Col md="3" sm="3" xs="4" className="d-none d-md-block">
                                <div className="nav-tabs-navigation">
                                <div className="nav-tabs-wrapper">
                                    <Nav
                                    className="flex-column nav-stacked"
                                    role="tablist"
                                    tabs
                                    >
                                    <NavItem>
                                        <NavLink
                                        className={vTabs === "1" ? "active" : "cursor"}
                                        onClick={() => {
                                            return viewPage("primary-info");
                                        }}
                                        >
                                        Primary Information
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                        className={vTabs === "2" ? "active" : "cursor"}
                                        onClick={() => {
                                            return viewPage("update-credentials");
                                        }}
                                        >
                                        Username & Password
                                        </NavLink>
                                    </NavItem>
                                    
                                    {
                                    /* Student     - update just primary address field 
                                    */
                                    }
                                    {   isStudent && 
                                        <NavItem>
                                            <NavLink
                                            className={vTabs === "3" ? "active" : "cursor"}
                                            onClick={() => {
                                                return viewPage("service-address");
                                            }}
                                            >
                                            Campus (Pick-up) address
                                            </NavLink>
                                        </NavItem>
                                    }

                                    {
                                    /* Residential     - update just primary address & home field 
                                    */
                                    }
                                    {   isResidential && 
                                        <NavItem>
                                            <NavLink
                                            className={vTabs === "3" ? "active" : "cursor"}
                                            onClick={() => {
                                                return viewPage("service-address");
                                            }}
                                            >
                                            Primary address
                                            </NavLink>
                                        </NavItem>
                                    }           

                                    {
                                    /* Student     - update just address home address field 
                                    */
                                    }
                                    {       
                                        (isStudent) && 
                                        <NavItem>
                                            <NavLink
                                            className={vTabs === "4" ? "active" : "cursor"}
                                            onClick={() => {
                                                return viewPage("home-address");
                                            }}
                                            >
                                                Home address
                                            </NavLink>
                                        </NavItem>
                                    }

                                    {       
                                        (isStudent || isResidential) && 
                                        <NavItem>
                                            <NavLink
                                            className={vTabs === "5" ? "active" : "cursor"}
                                            onClick={() => {
                                                return viewPage("payment-info");
                                            }}
                                            >
                                            Credit Card Information
                                            </NavLink>
                                        </NavItem>
}
                                    </Nav>
                                </div>
                                </div>
                            </Col>
                            <Col md="9" sm="9" xs="8" className="d-none d-md-block">
                                {/* Tab panes */}
                                <TabContent activeTab={"vTabs" + vTabs}>
                                <TabPane tabId="vTabs1">
                                    <div>
                                        <CustomerPrimaryInformation  
                                           displaySaveAndBackControlsForStepProcess={false}
                                           displaySaveAndCancelControlForNonStepProcess={true}
                                           {...props}
                                        />
                                    </div>
                                </TabPane>
                                <TabPane tabId="vTabs2">
                                    <div>
                                        <CredentialInformation  
                                           {...props}
                                        />
                                    </div>
                                </TabPane>
                                
                                {   
                                    isStudent && 
                                    <TabPane tabId="vTabs3">
                                        <div>
                                            <StudentCampusAddressInformation
                                                {...props}
                                                user={{primaryInformation:userDatabaseInformation}}
                                            />
                                        </div>
                                    </TabPane>
                                }

                                {   
                                    isResidential && 
                                    <TabPane tabId="vTabs3">
                                        <div>
                                            <ResidentialAddressInformation
                                                {...props}
                                                user={{primaryInformation:userDatabaseInformation}}
                                            />
                                        </div>
                                    </TabPane>
                                }
                                
                                {   
                                    isStudent && 
                                    <TabPane tabId="vTabs4">
                                        <div>
                                            <StudentHomeAddressInformation
                                                {...props}
                                                user={{primaryInformation:userDatabaseInformation}}
                                            />
                                        </div>
                                    </TabPane>
                                }
                                {   (isStudent || isResidential) && 
                                    <TabPane tabId="vTabs5">
                                        <div>
                                            
                                            <Elements>
                                                <PaymentInformation
                                                    {...props}
                                                />
                                            </Elements>
                                        </div>
                                    </TabPane>
                                }
                                    
                                </TabContent>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
}


export default ProfileDesktopView