import _ from "lodash";

import http from "./httpService";
import customToast from "../components/Common/CustomToast";

const apiUsersEndpoint = "/users";
const apiStorageEndpoint = "/storage";

/*
  Returns:
  {
      status:true;
      message:""
      nextPickupDeliverySchedule:{} //This would be blank if for students with One time other display next pickup
  }
*/
async function cancelLaundryUpcomingPickup(formData){
    try{
        const {data:result} = await http.post(`${apiUsersEndpoint}/student/laundry/cancelUpcomingPickup`,formData);
        return result;

    }catch(ex){
        console.log(ex);
    }
}

async function generateBarcodeLabelsForStorage(formData){

    const response = await http.post(
        `${apiUsersEndpoint}/student/storage/generateBarcodeLabel`,
        formData,
        {responseType:'blob'}
    );
    const {data:result} = response;
    return result;
}

async function getMeOnThisWeeksLaundryPickupSchedule(formData){
    try{
        const {data:result} = await http.post(`${apiUsersEndpoint}/student/laundry/getMeOnThisWeeksLaundryPickupSchedule`,formData);
        return result;

    }catch(ex){
        console.log(ex);
    }
}



async function getMyAccountStudentProductList(){
    try{
          const {data:productList} = await http.get(`${apiUsersEndpoint}/myaccount/student/getProducts`);
          //console.log(listOfDormitoryBySchools);
          return productList;
    }catch(ex){
       console.log(ex);
    }
}

async function getStorageItemsForDeliverySchedule(storageOrderId){
    try{
        const {data:returnData} = await http.get(`${apiUsersEndpoint}/student/storage/getStorageItemsForDeliverySchedule/${storageOrderId}`);
        return returnData;
    }catch(ex){
        console.log(ex);
    }
}

async function getStorageItemsToModifyDelivery(storageScheduleId){
    try{
        const {data:returnData} = await http.get(`${apiUsersEndpoint}/student/storage/getStorageItemsToModifyDelivery/${storageScheduleId}`);
        return returnData;
    }catch(ex){
        console.log(ex);
    }
}

async function getStorageItemPriceList(formData){
    try{
        const {data:returnData} = await http.post(`${apiStorageEndpoint}/storage/getItemPriceList`,formData);
        return returnData;
    }catch(ex){
        console.log(ex);
    }
}

async function getStorageDeliveryScheduleByAddress(formData){
    try{
        const {data:returnData} = await http.post(`${apiStorageEndpoint}/getStorageDeliveryScheduleByAddress`,formData);
        return returnData;
    }catch(ex){
        console.log(ex);
    }
}

async function getStorageEarlyDeliveryDatePropertiesByZone(formData){
    try{
        const {data:returnData} = await http.post(`${apiStorageEndpoint}/getStorageEarlyDeliveryDatePropertiesByZone`,formData);
        return returnData;
    }catch(ex){
        console.log(ex);
    }
}

async function purchasedLaundryPlanForSemester(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/myaccount/student/purchasedLaundryPlanForSemester`,formData);
    return returnData;
}

async function purchasedStoragePlanForSemester(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/myaccount/student/purchasedStoragePlanForSemester`,formData);
    return returnData;
}

async function saveStudentLaundrySchedule(formData){
    try{
        const {data:scheduleResponse} = await http.post(`${apiUsersEndpoint}/student/laundry/saveStudentLaundrySchedule`,formData);
        return scheduleResponse;

    }catch(ex){
        console.log(ex);
    }
}

async function saveStudentLaundryCustomerNotesForUpcomingPickup(formData){
    try{
        const {data:returnData} = await http.post(`${apiUsersEndpoint}/student/laundry/saveLaundryCustomerNotesForUpcomingPickup`,formData);
        return returnData;

    }catch(ex){
        console.log(ex);
    }
}


async function saveStudentLaundryDeliveryWaiverAndDriverInstructions(formData){
    try{
        const {data:returnData} = await http.post(`${apiUsersEndpoint}/student/laundry/saveLaundryDeliveryWaiverAndDriverInstructions`,formData);
        return returnData;

    }catch(ex){
        console.log(ex);
    }
}

async function saveStudentLaundryModifiedRecurringSchedule(formData){
    try{
        const {data:scheduleResponse} = await http.post(`${apiUsersEndpoint}/student/laundry/saveStudentLaundryModifiedRecurringSchedule`,formData);
        return scheduleResponse;

    }catch(ex){
        console.log(ex);
    }
}

async function saveStudentLaundryModifiedOneTimeUpcomingPickup(formData){
    try{
        const {data:scheduleResponse} = await http.post(`${apiUsersEndpoint}/student/laundry/saveStudentLaundryModifiedOneTimeUpcomingPickup`,formData);
        return scheduleResponse;

    }catch(ex){
        console.log(ex);
    }
}

async function saveStudentLaundryChangeFromByThePoundToSemesterPlan(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/student/laundry/signupLaundryStudentChangeFromByThePoundToSemesterPlanForExistingCustomer`,formData);
    return returnData;
}


async function saveStudentLaundryRenewPlan(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/student/laundry/signupLaundryStudentRenewPlanForExistingCustomer`,formData);
    return returnData;
}

async function saveStudentStoragePickupSchedule(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/student/storage/savePickupSchedule`,formData);
    return returnData;
}

async function saveStudentStoragePickupCancelSchedule(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/student/storage/pickupScheduleCancel`,formData);
    return returnData;
}

async function saveStudentStoragePickupModifySchedule(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/student/storage/pickupScheduleModify`,formData);
    return returnData;
}

async function saveStudentStorageDeliverySchedule(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/student/storage/saveDeliverySchedule`,formData);
    return returnData;
}

async function saveStudentStorageDeliveryCancelSchedule(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/student/storage/deliveryScheduleCancel`,formData);
    return returnData;
}

async function saveStudentStorageDeliveryModifySchedule(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/student/storage/deliveryScheduleModify`,formData);
    return returnData;
}

async function saveStudentStorageRenewPlan(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/student/laundry/signupStorageStudentRenewPlanForExistingCustomer`,formData);
    return returnData;
}


export default {
    cancelLaundryUpcomingPickup,
    generateBarcodeLabelsForStorage,
    getMeOnThisWeeksLaundryPickupSchedule,
    getMyAccountStudentProductList,
    getStorageItemsForDeliverySchedule,
    getStorageItemsToModifyDelivery,
    getStorageItemPriceList,
    getStorageDeliveryScheduleByAddress,
    getStorageEarlyDeliveryDatePropertiesByZone,
    purchasedLaundryPlanForSemester,
    purchasedStoragePlanForSemester,
    saveStudentLaundrySchedule,
    saveStudentLaundryCustomerNotesForUpcomingPickup,
    saveStudentLaundryDeliveryWaiverAndDriverInstructions,
    saveStudentLaundryModifiedRecurringSchedule,
    saveStudentLaundryModifiedOneTimeUpcomingPickup,
    saveStudentLaundryChangeFromByThePoundToSemesterPlan,
    saveStudentLaundryRenewPlan,
    saveStudentStoragePickupSchedule,
    saveStudentStoragePickupCancelSchedule,
    saveStudentStoragePickupModifySchedule,
    saveStudentStorageDeliverySchedule,
    saveStudentStorageDeliveryCancelSchedule,
    saveStudentStorageDeliveryModifySchedule,
    saveStudentStorageRenewPlan,

}