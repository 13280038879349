/*
Reusable component:
const {
    updatePaymentInformationFlag
    onClickOfBackButtonFromPaymentInformation,
    onClickOfNextButtonFromPaymentInformation
    user
} = this.props;
*/

import React,{Component} from "react";
import _ from "lodash";
import Joi from "@hapi/joi"
import Select from "react-select";


import {CardElement,
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    injectStripe} from 'react-stripe-elements';



import {getUSStateList} from "../../../../../../services/dropDownListsService";
import {getCountryList} from "../../../../../../services/dropDownListsService";

import UserService      from "../../../../../../services/userService";

import BaseForm from "../../../../../BaseClass/Form"
import FormErrorWidget from "../../../../../Common/FormErrorWidget";
import CreditCardHelper from "../../../../../../helpers/CreditCardHelper";

import {scrollTop}     from '../../../../../../utils/helperUtils';
import {renderError}   from '../../../../../../utils/errorUtils';
import customToast     from '../../../../../Common/CustomToast';

// reactstrap components
import {
    Badge,
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Form,
    FormGroup, 
    Label, 
    Input, 
    InputGroupAddon,
    InputGroupText,
    InputGroup,
} from "reactstrap";

class PaymentInformation extends BaseForm{
    divAreaToScrollForError = "description-areas"

    validationSchema = Joi.object({
        creditCardName:Joi.alternatives().conditional(
            'updatePaymentInformationFlag',
             { 
                is: true, 
                then: Joi.string().required().label('Credit Card Holder Name'), 
                otherwise: Joi.any().optional() 
            }
        ),
        updatePaymentInformationFlag: Joi.boolean(),
    });

    billingAddressSchema = Joi.object({
        addressLine1: Joi.string().required().label('Billing Address Line 1'),
        addressLine2: Joi.any().optional(),
        city:Joi.string().required().label('City'),
        state:Joi.object().keys({
            value:Joi.string().required(), 
            label:Joi.any().optional(),
        }),
        postalCode:Joi.string().required().label('Postal Code'),
        country:Joi.object().keys({
            value:Joi.string().required(), 
            label:Joi.any().optional(),
        }), 
    }) ;

    errorMessages = {}
    
    constructor(){
        super();
        this.state = {
            stateList:[{ value: "", label: "", isDisabled: true }],
            countryList:[{ value: "", label: "", isDisabled: true }],
            data:{
                updatePaymentInformationFlag:false,
                creditCardName:"",
                billingAddress:{
                    addressLine1:"",
                    addressLine2:"",
                    city:"",
                    state:"",
                    postalCode:"",
                    country:"",
                }
            },
            errors:{},
        }
    }

    componentDidMount(){
        this.mapModelToView();
    }

    componentDidUpdate(prevProps){
        const {user} = this.props;
        const {user:previousUser} = prevProps;
        if(!_.isEqual(previousUser,user)){
            this.mapModelToView();
        }
    }

    /*
     Display the payment widget for user to enter cc info:
       If there is no existing payment information
       If they already started entering the card name holder and stripe info

    */
    displayPaymentInformationArea = (props)=>{
        const {user} = props; 
        const {existingPaymentInformationOnFile,paymentInformation} = user;
        const {
            creditCardName
        } = user.paymentInformation

        if(existingPaymentInformationOnFile === "")
            return true;
        else if(!_.isEmpty(paymentInformation) && creditCardName !== "")
           return true;
        else 
           return false;
        
    }

    getDisplayCurrentCreditCardInformationFlag = ()=>{
        let returnFlag = false;
        const {user} = this.props; 
        returnFlag = (!_.isEmpty(user.existingPaymentInformationOnFile)) ? true:false;
        
        return returnFlag;
    }

    mapModelToView = ()=>{
        const stateList = getUSStateList();
        const countryList = getCountryList();
        
          
        this.setState(
            {
                stateList,
                countryList,
            }
        );


        const   {updatePaymentInformationFlag:displayPaymentInformationFlag,} = this.state.data;
        const displayCurrentCreditCardInformationFlag = this.getDisplayCurrentCreditCardInformationFlag();
        if(!displayPaymentInformationFlag && !displayCurrentCreditCardInformationFlag)
           this.setDisplayPaymentInformation(true)
   
    }

    setDisplayPaymentInformation = (updatePaymentInformationFlag)=>{
        this.setState({
            data:{
                ...this.state.data,
                updatePaymentInformationFlag
            }
        })
    }

    //This gets executed once the parent submission process is executed
    submitLocal = async ()=>{
        const {
            handleOnSaveOfCustomerInformation,
            handleDisplayOfProcessingModal,
        } = this.props;
        
        try{
            
            handleDisplayOfProcessingModal(true);
            
            const {updatePaymentInformationFlag} = this.state.data;
        
            const loginAndPaymentFormData = {...this.state.data};
            const  {
                addressLine1:address_line1,
                addressLine2:address_line2,
                city:address_city,
                state,
                postalCode:address_zip,
                country
            } = loginAndPaymentFormData.billingAddress;

            let errors = {};
            let stripeResult = "";
            if (this.props.stripe && updatePaymentInformationFlag === true) {
                stripeResult = await this.props.stripe.createToken(
                    {
                        type: 'card', 
                        name: loginAndPaymentFormData.creditCardName,
                        address_line1,
                        address_line2,
                        address_city,
                        address_state:state.value,
                        address_zip,
                        address_country:country.value,
                    }
                );
                //console.log(stripeResult);
                if(stripeResult.error){
                    errors = (!_.isEmpty(errors)) ? errors : {};
                    errors["invalidCard"] = stripeResult.error.message;
                }
                else
                    loginAndPaymentFormData.stripeToken = stripeResult.token
            } 
            this.setState({errors: errors || {}});
            if(!_.isEmpty(errors)){
                scrollTop(this.divAreaToScrollForError)
                return;
            } 

            let {billingAddress:billingAddressFormData} = this.state.data;

            const billingAddress = {
                ...billingAddressFormData,
                state:(_.has(billingAddressFormData,['state','value'])) ? billingAddressFormData.state.value:"",
                country:(_.has(billingAddressFormData,['country','value'])) ? billingAddressFormData.country.value:"",
            };
            
            const {status,message,errorObject} = await UserService.saveCustomerCreditCardInformation({
                ...loginAndPaymentFormData,
                billingAddress,
            });

            if(status === true){
                handleDisplayOfProcessingModal(false);

                //Reset form object
                let addressFormData = {...this.state.data.billingAddress}
                _.forIn(addressFormData,(v,k)=>addressFormData[k]="");
               
                
                this.setState({
                    data:{
                        ...this.state.data,
                        updatePaymentInformationFlag:false,
                        creditCardName:"",
                        billingAddress:addressFormData,
                    } 
                })
                
                customToast.success(message); 
                //Go back to the parent view for information reload
                return handleOnSaveOfCustomerInformation();
            }
        } 
        catch(ex){
            handleDisplayOfProcessingModal(false);
            /*
            400 signals validation errors from the server
            renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop(this.divAreaToScrollForError); return;}
            }
            else
                renderError(ex);
        } 
        
    }

    validateLocal= ()=>{
        try{
            const {updatePaymentInformationFlag} = this.state.data;
            if(updatePaymentInformationFlag === false) return null;

            const options = {abortEarly:false,allowUnknown:true};
            //console.log(this.state.data);
            let {error:billingAddressErrorList} = this.billingAddressSchema.validate(this.state.data.billingAddress,options);
            
            if(billingAddressErrorList === undefined) return null;

            const billingAddressErrors = {};
            if(billingAddressErrorList){
                billingAddressErrorList.details.map(
                    function(errorObject){
                        //console.log(errorObject);
                        if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                            const key = errorObject.context.key;
                            const joiType = errorObject.type;
                            //console.log(this.errorMessages);
                            if(_.has(this.errorMessages, [key, joiType]))
                                return billingAddressErrors[errorObject.path[0]] = this.errorMessages[key][joiType];
                            else
                                return billingAddressErrors[errorObject.path[0]] = errorObject.message;
                        }
                        else
                          return billingAddressErrors[errorObject.path[0]] = errorObject.message;
                    }.bind(this)
                );
            }
            
            const errors = {...billingAddressErrors} 
            
            return Object.keys(errors).length > 0 ? errors:null;
        }catch(ex){
            console.log(ex);
        }
        
    }

    render(){
        const {paymentInformationComponentDisplayProperties,user} = this.props;

        const { 
            headerTitle,
            stepTitle,
            step, 
        } = paymentInformationComponentDisplayProperties;


        const {
            cardExpirationDate,
            cardNumber,
            cardType,
            isExpired
        }  = user.existingPaymentInformationOnFile;
       
        const {data,errors} = this.state;
        const   {
            creditCardName,
            updatePaymentInformationFlag:displayPaymentInformationFlag,
        } = data;

        const stripeInputFieldStyling = CreditCardHelper.stripeInputFieldStyling;
        const displayCurrentCreditCardInformationFlag = this.getDisplayCurrentCreditCardInformationFlag();
   
   
       
        return(
            <Container id="mainContentArea">
                <Row className="title-body-row">
                    <Col>
                        <Card className="card-plain bg-white p-2 rounded-lg">
                            <CardBody>
                                {/*Error display */}
                                {!_.isEmpty(errors) &&
                                    <FormErrorWidget
                                    errors={errors}
                                    />
                                }
                                {/* End of Error display */}

                                {
                                    displayCurrentCreditCardInformationFlag && 
                                    <div className="mt-2 mb-4">
                                        <h6 className="card-category">
                                            <span className="text-left">
                                            Payment Information on file
                                            </span>
                                        </h6>
                                        <hr/>
                                        <Row className="mt-2">
                                        <Col xs="4">
                                            Credit Card Number
                                        </Col>
                                        <Col xs="8">
                                            {`${cardType} - ${cardNumber}`}
                                        </Col>
                                        </Row>

                                        <Row className="mt-2">
                                        <Col xs="4">
                                            Expiration Month - Year
                                        </Col>
                                        <Col xs="8">
                                            {cardExpirationDate} {" "}
                                            {isExpired && 
                                             <Badge color="danger" pill>
                                                Expired
                                             </Badge>
                                            }
                                        </Col>
                                        </Row>

                                        {
                                            !displayPaymentInformationFlag && 
                                            <Row className="mt-2">
                                                <Col xs="4">
                                                </Col>
                                                <Col xs="8">
                                                    <Button 
                                                        className="btn-round mr-1"
                                                        color="default"
                                                        outline
                                                        size="sm"
                                                        onClick={()=>this.setDisplayPaymentInformation(true)}
                                                    >
                                                    Update Credit Card
                                                    </Button>
                                                </Col>
                                            </Row>
                                        }
                                        

                                    </div>
                                }

                                {
                                    !displayPaymentInformationFlag && !displayCurrentCreditCardInformationFlag &&
                                    <Row className="mt-2 text-left">
                                        <Col xs="8">
                                            <Button 
                                                className="btn-round mr-1"
                                                color="default"
                                                outline
                                                size="sm"
                                                onClick={()=>this.setDisplayPaymentInformation(true)}
                                            >
                                            Enter New Credit Card Information
                                            </Button>
                                        </Col>
                                    </Row>
                                }
                                

                                {
                                    displayPaymentInformationFlag && 
                                    <div className="mt-2">
                                            <h6 className="card-category text-left">
                                                Enter Credit Card Information 
                                                <i className="fa fa-cc-amex fa-3 ml-1 " aria-hidden="true"></i>
                                                <i className="fa fa-cc-mastercard fa-3 ml-1 " aria-hidden="true"></i>
                                                <i className="fa fa-cc-visa fa-3 ml-1 " aria-hidden="true"></i>
                                                <i className="fa fa-cc-discover fa-3 ml-1 " aria-hidden="true"></i>
                                                <i className="fa fa-cc-jcb fa-3 ml-1 " aria-hidden="true"></i>
                                            </h6>
                                            
                                            <hr/>

                                            <FormGroup row>
                                                <Label for="creditCardName" sm={4}>Name on Credit Card</Label>
                                                <Col sm={6}>
                                                    <Input 
                                                        type="text" 
                                                        name="creditCardName" 
                                                        id="creditCardName" 
                                                        placeholder="Name on Credit Card" 
                                                        value={creditCardName} 
                                                        onChange={this.handleChange}
                                                    />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                    <Label for="card-number" sm={4}>Credit Card Number</Label>
                                                    <Col id="card-number" sm={6}>
                                                        <CardNumberElement 
                                                            {...stripeInputFieldStyling}
                                                        />
                                                    </Col>
                                            </FormGroup>
                                            
                                            <FormGroup row>
                                                    <Label for="card-expiration-date" sm={4}>Expiration Date</Label>
                                                    <Col id="card-expiration-date" sm={6}>
                                                        <CardExpiryElement 
                                                            {...stripeInputFieldStyling}
                                                        />
                                                    </Col>
                                            </FormGroup> 
                                            
                                            <FormGroup row>
                                                    <Label for="card-cvc" sm={4}>CVC</Label>
                                                    <Col id="card-cvc" sm={6}>
                                                        <CardCVCElement 
                                                            {...stripeInputFieldStyling}
                                                        />
                                                    </Col>
                                            </FormGroup> 
                                    </div>
                                }

                                {
                                    displayPaymentInformationFlag && 
                                    <div>
                                        <h6 className="card-category text-left mt-2">Billing Address</h6>
                                        <hr/>

                                        <FormGroup row>
                                            <Label for="addressLine1" sm={2}>Address Line 1</Label>
                                            <Col sm={10}>
                                                <Input 
                                                type="text" 
                                                name="addressLine1" 
                                                id="addressLine1" 
                                                placeholder="Address Line 1" 
                                                value={this.state.data.billingAddress.addressLine1} 
                                                onChange={e=>{this.handleChangeNestedAttribute(e,"billingAddress")}}
                                                />
                                            </Col>
                                            
                                        </FormGroup>
                                        
                                        
                                        <FormGroup row>
                                            <Label for="addressLine2" sm={2}>Address Line 2</Label>
                                            <Col sm={10}>
                                                <Input 
                                                type="text" 
                                                name="addressLine2" 
                                                id="addressLine2" 
                                                placeholder="Address Line 2" 
                                                value={this.state.data.billingAddress.addressLine2} 
                                                onChange={e=>{this.handleChangeNestedAttribute(e,"billingAddress")}}
                                                />
                                            </Col>
                                            
                                        </FormGroup>
                                        
                                        
                                        
                                        <FormGroup row>
                                            <Label for="city" sm={2}></Label>
                                            <Col xs={12} sm={3}>
                                                <Input 
                                                type="text" 
                                                name="city" 
                                                id="city" 
                                                placeholder="City" 
                                                value={this.state.data.billingAddress.city} 
                                                onChange={e=>{this.handleChangeNestedAttribute(e,"billingAddress")}}
                                                />
                                            </Col>
                                            <Label for="state" xs={12} sm={2} className="d-block d-sm-none"></Label>
                                            <Col xs={12} sm={4}>
                                                <FormGroup>
                                                    <Select
                                                        className="react-select react-select-default"
                                                        classNamePrefix="react-select"
                                                        name="state"
                                                        id="state"
                                                        value={this.state.data.billingAddress.state}
                                                        onChange={value => this.handleDropdownChangeNestedAttribute(value,"state","billingAddress",)}
                                                        options={this.state.stateList}
                                                        placeholder="State"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} sm={3}>
                                                <Input 
                                                type="text" 
                                                name="postalCode" 
                                                id="postalCode" 
                                                placeholder="Zip" 
                                                value={this.state.data.billingAddress.postalCode} 
                                                onChange={e=>{this.handleChangeNestedAttribute(e,"billingAddress")}}
                                                />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                            <Label for="country" sm={2}>Country</Label>
                                            <Col sm={10}>
                                                <Select
                                                    className="react-select react-select-default"
                                                    classNamePrefix="react-select"
                                                    name="country"
                                                    id="country"
                                                    value={this.state.data.billingAddress.country}
                                                    onChange={value => this.handleDropdownChangeNestedAttribute(value,"country","billingAddress")}
                                                    options={this.state.countryList}
                                                    placeholder="Country"
                                                    autoComplete="off"
                                                />
                                            </Col>
                                            
                                        </FormGroup>

                                        <FormGroup row className="mt-5 justify-content-between">
                                            <Col xs={{ size: 2 }}>
                                                <Button 
                                                className="btn-rounded btn-danger"
                                                onClick={()=>this.setDisplayPaymentInformation(false)}
                                                >
                                                Cancel
                                                </Button>
                                            </Col>
                                            <Col xs={{ size: 6}} sm={{ size: 4}} className="text-right">
                                                <Button 
                                                className="btn-rounded btn-danger"
                                                onClick={this.submitForm}
                                                >
                                                Save
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </div>
                                    }

                               
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }


}

export default injectStripe(PaymentInformation);

