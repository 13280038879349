import React from "react";
import {Link} from "react-router-dom"

import {
    Media,
    
} from "reactstrap";

const studentData = [
    {
        heading:"How do I sign up for storage services?",
        body:<p>
        Reserving storage services with Lazybones is easy! Just create an account on our website (<Link to="/">mylazybones.com</Link>), then make your storage reservation. 
Or, you can always call one of our friendly customer service representatives and have them reserve your storage services for you (877-215-2105). 
       </p>
    },
    {
        heading:"Do I need to know when I want my storage items picked up in order to sign up?",
        body:<p>
        No, you can reserve space for a storage pickup by paying a $35 deposit (either by phone or on the website). Then, log back in or call us later once you know the exact date you would like the pick-up done. Pick-ups must be scheduled at least three days in advance. HOWEVER, pick-up times go fast, so the sooner you schedule your pick-up date, the more likely you will get the time slot you want. 
        </p>
    },
    {
        heading:"If I cancel my storage reservation before I choose a pickup time, can I get my money back?",
        body:<p>
            Yes, always, no questions asked.
        </p>,
    },
    {
    heading:"Can Lazybones wash the items I give you to store?",
    body:<p>
        Yes! We can either "Wash and Store" or "Dry Clean and Store" your items for additional fee.
    </p>,
    },
    {
        heading:"My roommate and I are both going to need storage. Should we set up two storage accounts or do we only need one?",
        body:<p>
        We recommend that you set up two storage accounts, one for each roommate. This makes keeping track of the items easier for us, and will prevent any billing disputes that might come up.
        </p>,
    },
    {
        heading:"What are your customer service hours during storage season?",
        body:<p>
        Our customer service hours during storage season are 9 AM to 5 PM EST <br/>Monday-Friday, and 9 AM to 3 PM EST on Saturdays and Sundays. 
        </p>,
    },
    {
        heading:"Why do I need barcode labels?",
        body:<p>
        This is our way to make sure your items stay with your account. Items without our barcodes are subject to a labeling charge. Just print the labels out onto ordinary paper, cut them apart with scissors, and use scotch tape or packaging tape to apply one label to each of your items. Be sure to use a different barcode label for each item.
        </p>,
    },
    {
        heading:"Where do I get the barcode labels?",
        body:<p>
        You can log onto our website using your username and password and print out as many unique bar code labels as you like. Just print the labels out onto ordinary paper, cut them apart with scissors, and use scotch tape or packaging tape to apply one label to each of your items.
        </p>,
    },
    
];

const StorageSigningUp = ()=>{
    const data = studentData;
    let ctr = 1; 
    return(
        <>
            {
                data.map(({heading,body})=>
                    <Media key={ctr++}>
                        <Media body>
                        <Media heading>{heading}</Media>
                        {body}
                        </Media>
                    </Media>
                )
            }
        </>
    );
}

export default StorageSigningUp;
