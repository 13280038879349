import React from "react";

import ReCAPTCHA from "react-google-recaptcha";
import _ from "lodash";
import Joi from "@hapi/joi";

import FormErrorWidget from "../../Common/FormErrorWidget";
import {scrollTop} from '../../../utils/helperUtils';
import {renderError} from '../../../utils/errorUtils';
import customToast from '../../Common/CustomToast'

import ProcessingModal from "../../Common/ProcessingModal";

import BaseForm from "../../BaseClass/Form"


import UserService from "../../../services/userService";

import {
    Alert,
    Container,
    Button,
    Label,
    Row,
    Col,
    Form,
    FormGroup, 
    Modal, 
    Input, 
} from "reactstrap";

class ContactUs extends BaseForm{
    divAreaToScrollForError = "contactUs";

    validationSchema = Joi.object({
        name: Joi.string().required().label('Contact Us - Name'),
        email:Joi.string().email({tlds:false}).required().label('Contact Us - Email'),
        message: Joi.string().required().label('Contact Us - Message'),
        recaptchaResponse:Joi.string().required().label("Recaptcha"),
        
         
    }) 
  
    errorMessages = {}

    constructor(){
        super();
        this.state = {
            processingModalDisplayFlag:false,
            successNotifications:false,
            data:{
                name:"",
                email:"",
                message:"",
                recaptchaResponse:"",
            },
            errors:{

            }
        }
    }

    handleChangeRecaptcha = (value)=> {
        console.log("Captcha value:", value);
        const data  = {...this.state.data};
        data.recaptchaResponse = value;
        this.setState({data})
    }

    //This gets executed once the parent submission process is executed
    submitLocal = async ()=>{
        const formData = this.state.data;
        let errors = {};
        try{
            this.setState({errors});
            this.setProcessingModalDisplayFlag(true);

            const formDataObject = {...this.state.data};

            /* Send data to server */
                const {status,message} = await UserService.processContactUsForm(formDataObject);
                    
                this.setProcessingModalDisplayFlag(false);
                if(status === true){
                    //Clear the form data
                    this.setState({
                        successNotifications:true,
                        data:{
                            name:"",
                            email:"",
                            message:"",
                            recaptchaResponse:"",
                        },
                    })
                    
                }
                else{
                    //Handle errors from the server
                    let [errorMessage] = [""];
                    errorMessage = message; 
                    
                    
                    errors = {message:errorMessage};
                    this.setState({errors: errors || {}});
                    setTimeout(()=>{
                        scrollTop(this.divAreaToScrollForError)
                    },1000)
                    //return;
                }
            /* End of Send data to server */


        }
        catch(ex){
            this.setProcessingModalDisplayFlag(false);
            /*
            400 signals validation errors from the server
            renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop(this.divAreaToScrollForError); return;}
            }
            else
                renderError(ex);
        } 
    }

    validateLocal= ()=>{
        //This is anything specific to the form like async validations
        
    }

    setProcessingModalDisplayFlag = flag=>{
        this.setState({processingModalDisplayFlag:flag})
    }

    setSuccessNotificationDisplayFlag = flag=>{
        this.setState({successNotifications:flag})
    }

    render(){
        const {
            processingModalDisplayFlag,
            successNotifications,
            data,
            errors
        } = this.state;

        const {
            name,
            email,
            message,
        } = data;

        return(
            <Container>
                <Row>
                <Col id="contactUs" className="ml-auto mr-auto" md="8">
                    <h2 className="text-center">Contact Us</h2>
                    
                    <Form className="contact-form">
                        {/*Error display */}
                        {!_.isEmpty(errors) &&
                            <FormErrorWidget
                                errors={errors}
                            />
                        }
                        {/* End of Error display */}

                        {   
                            successNotifications && 
                            <Alert
                                className="alert-with-icon"
                                color="success"
                                isOpen={successNotifications}
                            >
                                <Container>
                                    <div className="alert-wrapper">
                                        <button
                                        type="button"
                                        className="close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                        onClick={() => this.setSuccessNotificationDisplayFlag(false)}
                                        >
                                        <i className="nc-icon nc-simple-remove" />
                                        </button>
                                        <div className="message">
                                        <i className="nc-icon nc-bell-55" /> {"   "}
                                        We have received your request and will process it and get back you in the next 2 business days
                                        </div>
                                    </div>
                                </Container>
                            </Alert>
                        }
                        
                        <Row>
                            <Col md="6">
                                <label>Name</label>
                                <Input
                                    placeholder="Name"
                                    type="text"
                                    name="name" 
                                    id="name" 
                                    value={name} 
                                    onChange={this.handleChange}
                                />
                            </Col>
                            <Col md="6">
                                <label>Email</label>
                                <Input
                                    placeholder="Email"
                                    type="email"
                                    name="email" 
                                    id="email" 
                                    value={email} 
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                        
                        <label>Message</label>
                        <Input
                            placeholder="Let us know if you have a question"
                            type="textarea"
                            rows="4"
                            name="message"
                            id="message"
                            value={message} 
                            onChange={this.handleChange}
                        />

                        <Row className="mt-4">
                            <Col className="offset-md-4" md="4">
                                <ReCAPTCHA
                                    sitekey={process.env[`REACT_APP_GOOGLE_RECAPTCHA_CLIENT_KEY`]}
                                    onChange={this.handleChangeRecaptcha}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col className="offset-md-4" md="4">
                            <Button type="submit" className="btn-fill" color="danger" size="lg"
                                onClick={this.submitForm}
                            >
                                Send Message
                            </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                </Row>
                
                {/* Set Processing modal */}
                <ProcessingModal
                    displayModal={processingModalDisplayFlag}
                    handleCloseOfModalWindow={this.setProcessingModalDisplayFlag}
                />
                {/* End of Processing modal */}
            </Container>
            
        );

    }
}

export default ContactUs

