import React,{Component} from "react";
import _ from "lodash";
import Select from "react-select";
import {getUSStateList} from "services/dropDownListsService";
import SchoolService from "services/schoolService";
import http from "services/httpService";
import {renderError} from 'utils/errorUtils';
import Joi from "@hapi/joi";


// reactstrap components
import {
    Button,
    Container,
    Card,
    CardBody,
    CardFooter,
    CardImg,
    CardImgOverlay,
    CardLink,
    CardTitle,
    CardSubtitle,
    CardText,
    ListGroupItem,
    ListGroup,
    Row,
    Col,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    FormText
    
} from "reactstrap";

import StudentLaundryRegistrationStepsDisplayWidget from "../Common/StudentLaundryRegistrationStepsDisplayWidget";
import FormErrorWidget from "components/Common/FormErrorWidget";

const scrollTop = ()=>{
    document.getElementById("sectionArea").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
    });
};


const apiUserEndpoint = "/users";

class  StudentInformation extends Component{

    userSchema = Joi.object({
        firstName: Joi.string().required().label('First Name'),
        lastName:Joi.string().required().label('Last Name'),
        email:Joi.string().email({tlds:false}).required().label('Email'),
        cellPhone:Joi.string().required().label('Cell Phone'),
        homePhone:Joi.string().required().label('Home Phone'),
        studentId:Joi.any().allow(""),
        //pickUpAddress:Joi.any().optional(),
        
        /*
        pickUpAddress:Joi.object().keys({
                isDormitory:Joi.string(),
                dormitoryName: Joi.alternatives().conditional(
                    'isDormitory', { 
                        is: 'Yes', 
                        then: Joi.object().keys({
                            value:Joi.number().required(), 
                            label:Joi.any().optional(),
                        }), 
                        otherwise: Joi.any().optional()
                    }
                ),
                dormitoryRoomNumber: Joi.alternatives().conditional(
                    'isDormitory', { 
                        is: 'Yes', 
                        then: Joi.string().required(), 
                        otherwise: Joi.any().optional()
                    }
                ),
                addressLine1: Joi.alternatives().conditional(
                    'isDormitory', { is: 'No', then: Joi.string().required(),otherwise: Joi.any().optional() }
                ),
                addressLine2: Joi.any().optional(),
                city:Joi.string().required().label('City'),
                state:Joi.object().keys({
                    value:Joi.string().required(), 
                    label:Joi.any().optional(),
                }),
                postalCode:Joi.string().required().label('Postal Code'),
                country:Joi.string().required().label('Country'), 
        }) 
        */ 
        
        
    });

    pickupAddressSchema = Joi.object({
        isDormitory:Joi.string(),
        dormitoryName: Joi.alternatives().conditional(
            'isDormitory', { 
                is: 'Yes', 
                then: Joi.object().keys({
                    value:Joi.number().required(), 
                    label:Joi.any().optional(),
                }), 
                otherwise: Joi.any().optional()
            }
        ),
        dormitoryRoomNumber: Joi.alternatives().conditional(
            'isDormitory', { 
                is: 'Yes', 
                then: Joi.string().required(), 
                otherwise: Joi.any().optional()
            }
        ).label('Room Number'),
        streetNumber: Joi.alternatives().conditional(
            'isDormitory', { is: 'No', then: Joi.string().required().label('Street Number'),otherwise: Joi.any().optional() }
        ),
        addressLine1: Joi.alternatives().conditional(
            'isDormitory', { is: 'No', then: Joi.string().required().label('Address Line 1'),otherwise: Joi.any().optional() }
        ),
        addressLine2: Joi.any().optional(),
        apartmentNumber:Joi.any().optional(),
        zoneId: Joi.any().optional(),
        city:Joi.string().required().label('City'),
        state:Joi.object().keys({
            value:Joi.string().required(), 
            label:Joi.any().optional(),
        }),
        postalCode:Joi.string().required().label('Postal Code'),
        country:Joi.string().required().label('Country'), 
    }) ;

    deliveryAddressSchema = Joi.object({
        isDormitory:Joi.string(),
        dormitoryName: Joi.alternatives().conditional(
            'isDormitory', { 
                is: 'Yes', 
                then: Joi.object().keys({
                    value:Joi.number().required(), 
                    label:Joi.any().optional(),
                }), 
                otherwise: Joi.any().optional()
            }
        ),
        dormitoryRoomNumber: Joi.alternatives().conditional(
            'isDormitory', { 
                is: 'Yes', 
                then: Joi.string().required(), 
                otherwise: Joi.any().optional()
            }
        ).label('Room Number'),
        streetNumber: Joi.alternatives().conditional(
            'isDormitory', { is: 'No', then: Joi.string().required().label('Street Number'),otherwise: Joi.any().optional() }
        ),
        addressLine1: Joi.alternatives().conditional(
            'isDormitory', { is: 'No', then: Joi.string().required().label('Address Line 1'),otherwise: Joi.any().optional() }
        ),
        addressLine2: Joi.any().optional(),
        apartmentNumber:Joi.any().optional(),
        zoneId: Joi.any().optional(),
        city:Joi.string().required().label('City'),
        state:Joi.object().keys({
            value:Joi.string().required(), 
            label:Joi.any().optional(),
        }),
        postalCode:Joi.string().required().label('Postal Code'),
        country:Joi.string().required().label('Country'), 
    }) ;

    errorMessages = {
        "dormitoryName":{
            "object.base":"Please select a dormitory",
        },
    }
    constructor(){
        super()
        this.state = {
            stateList:[{ value: "", label: "", isDisabled: true }],
            dormitoryList:[{ value: "", label: "", isDisabled: true }],
            dormitoryObjectList:"",
            data:{
                firstName:"",
                lastName:"",
                email:"",
                studentId:"",
                cellPhone:"",
                homePhone:"",
                pickUpAddress:{
                    isDormitory:"",
                    dormitoryName:"",  //{value:"", label:""} this corresponds to the react-select value setup
                    dormitoryRoomNumber:"",
                    streetNumber:"", //Non dormitory street number
                    addressLine1:"",
                    addressLine2:"",
                    apartmentNumber:"", //Non dormitory apartment number
                    zoneId:"",
                    city:"",
                    state:"",
                    postalCode:"",
                    country:"USA", 

                },
                deliveryAddress:{
                    isSameAsPickupAddress:"",
                    isDormitory:"",
                    dormitoryName:"", //{value:"", label:""} this corresponds to the react-select value setup
                    dormitoryRoomNumber:"",
                    streetNumber:"",
                    addressLine1:"",
                    addressLine2:"",
                    apartmentNumber:"",
                    zoneId:"",
                    city:"",
                    state:"",
                    postalCode:"",
                    country:"USA", 
                }
            },
            
            errors:{}
        }
    }

    async componentDidMount(){
        try{
            console.log("StudentInformation Component did mount");
            document.getElementById("sectionArea").scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
            });

            const stateList = getUSStateList();
            const {school} = this.props.user;

            const dormitoryList = await SchoolService.getDropdownListOfDormitoryBySchool(school);
            const dormitoryObjectList = await SchoolService.getListOfDormitoryBySchool(school);
            
            this.setState({
                stateList,
                dormitoryList,
                dormitoryObjectList,
            });
            
            this.mapStudentModelToView();
        }
        catch(ex){
            console.log(`Component did mount on Laundry Student Info ${ex}`);
        }
        
    }

    componentDidUpdate(){
      //console.log("StudentInformation Component did update");
      //this.mapStudentModelToView();
    }

    handleChange = ({currentTarget:input})=>{
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[input.name] = input.value;
        this.setState({data,errors});
        
    }

    handleDeliveryAddressBasicInputChange = (e)=>{
        const addressType = "deliveryAddress";
        const {name,value} = e.currentTarget;
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}

        address[name] = value;

        data[addressType] = address
        this.setState({data});

    }

    handleDeliveryAddressCheckboxDoYouLiveInDorm = e=>{
        const addressType = "deliveryAddress";
        const {name,value} = e.currentTarget;
        
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}

        address.isDormitory = value;

        data[addressType] = address
        this.setState({data});

    }

    handleIsDeliverySameAsPickupCheckbox = e=>{
        //debugger
        const addressType = "deliveryAddress";
        const {name,value} = e.currentTarget;
        
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}

        address.isSameAsPickupAddress = value;

        data[addressType] = address
        this.setState({data});

        this.handleAddressDataIfDeliverySameAsPickup(value);
    }

    //This routine updates the delivery data if pickup and delivery is same
    handleAddressDataIfDeliverySameAsPickup = (value)=>{
        const isSameAsPickupAddress = value;
        //debugger;
        if(isSameAsPickupAddress === "Yes"){
            
            const deliveryAddress = {
                ...this.state.data.pickUpAddress,
                isSameAsPickupAddress:isSameAsPickupAddress,
            }
            this.setState({
                data:{
                    ...this.state.data,
                    deliveryAddress
                }
            })
        }
        else{
            const deliveryAddress = {
                ...this.state.data.deliveryAddress,
                isSameAsPickupAddress:isSameAsPickupAddress,
            }
            this.setState({
                data:{
                    ...this.state.data,
                    deliveryAddress
                }
            })
        }
    }

    handlePickupAddressBasicInputChange = (e)=>{
        const addressType = "pickUpAddress";
        const {name,value} = e.currentTarget;
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}

        address[name] = value;
        
        data[addressType] = address;

        //Update delivery address if same as pickup
        const {isSameAsPickupAddress} = this.state.data.deliveryAddress;
        if(isSameAsPickupAddress === "Yes"){
          const deliveryAddress = {
            ...address,
            isSameAsPickupAddress,
          }
          data['deliveryAddress'] = deliveryAddress
        }

        this.setState({data});
    }

    handlePickupAddressCheckboxDoYouLiveInDorm = e=>{
        const addressType = "pickUpAddress";
        const {name,value} = e.currentTarget;
        
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}

        address.isDormitory = value;

        data[addressType] = address
        
        //Update delivery address if same as pickup
        const {isSameAsPickupAddress} = this.state.data.deliveryAddress;
        if(isSameAsPickupAddress === "Yes"){
          const deliveryAddress = {
            ...this.state.data.pickUpAddress,
            isDormitory:value,
            isSameAsPickupAddress,
          }
          data['deliveryAddress'] = deliveryAddress
        }
        
        this.setState({data});
        

        
    }

    handleSubmit = async (e)=>{
       try{
            e.preventDefault();  

            //client side errors will be caught in validae(), server side data error will be caught via try/catch
            const errors = await this.validate();
            this.setState({errors: errors || {}});
            if(errors){scrollTop(); return;} 
            
            const {onClickOfNextButtonFromStudentInformation} = this.props;  
            console.log("Handle submit of student information");
            //console.log(this.state);
            const studentInformation = {...this.state.data};
            onClickOfNextButtonFromStudentInformation(studentInformation);
            return;
        }catch(ex){
            /*
            400 signals validation errors from the server
            renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop(); return;}
            }
            else
            renderError(ex);
        } 

    }

    mapStudentModelToView = ()=>{
        const {user} = this.props;
        const {firstName,lastName,email,cellPhone,homePhone,studentId,school} = user;
        const {pickUpAddress:pickUpAddressUser,deliveryAddress:deliveryAddressUser} = user.storage;
        const stateList = getUSStateList();

        //Get the city and state to plugin default values
        const {state:schoolDefinedState, web_city:cityDefinedState} = school.university;
        
        //Set pickup address
        const pickUpAddress = {...pickUpAddressUser};
        if(pickUpAddress.isDormitory){
            pickUpAddress.isDormitory = "Yes";
            pickUpAddress.dormitoryName={
                value:pickUpAddress.dormitory.id,
                label:pickUpAddress.dormitory.name,
            };
            pickUpAddress.dormitoryRoomNumber = pickUpAddress.dormitory.roomNumber;
        }
        else{
            pickUpAddress.isDormitory = "No";
            pickUpAddress.dormitoryName="";
            pickUpAddress.dormitoryRoomNumber = "";
        }

        pickUpAddress.state = (pickUpAddressUser.state) ?
          _.find(stateList,{'value':pickUpAddressUser.state}) : _.find(stateList,{'value':schoolDefinedState});

        delete pickUpAddress.dormitory;
        //End of Set pickup address

        //Set delivery address
        const deliveryAddress = {...deliveryAddressUser};
        deliveryAddress.isSameAsPickupAddress = (deliveryAddressUser.isSameAsPickupAddress === true) ? "Yes":"No";
        if(deliveryAddress.isDormitory){
            deliveryAddress.isDormitory = "Yes";
            deliveryAddress.dormitoryName={
                value:deliveryAddress.dormitory.id,
                label:deliveryAddress.dormitory.name,
            };
            deliveryAddress.dormitoryRoomNumber = deliveryAddress.dormitory.roomNumber;
        }
        else{
            deliveryAddress.isDormitory="No";
            deliveryAddress.dormitoryName = "";
            deliveryAddress.dormitoryRoomNumber = "";
        }

        deliveryAddress.state = (deliveryAddressUser.state) ?
          _.find(stateList,{'value':deliveryAddressUser.state}) : _.find(stateList,{'value':schoolDefinedState});

        
        delete deliveryAddress.dormitory;

        //End of Set delivery address

        
        const data = {firstName,lastName,email,cellPhone,homePhone,studentId,pickUpAddress,deliveryAddress};
        
        //console.log(data);
        if(!_.isEqual(this.state.data,data))
           this.setState({data});
    }

    setAddressDormitory = (valueObject,addressType)=>{
        const {dormitoryObjectList,stateList} = this.state; 
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        address.dormitoryName = valueObject;

        //Get the dorm object by selected dorm name to populate the city state zip
        const selectedDormitoryObject = _.find(dormitoryObjectList,{"id":valueObject.value});
        //console.log(selectedDormitoryObject);
        
        const selectedStateObject = _.find(stateList,{"value":selectedDormitoryObject.state});
        
        address.city = selectedDormitoryObject.city;
        address.state = selectedStateObject;
        address.postalCode = selectedDormitoryObject.zip;
        
        data[addressType] = address;

        if(addressType === "pickUpAddress" && data.deliveryAddress.isSameAsPickupAddress === "Yes"){
            data.deliveryAddress = {
                ...data.deliveryAddress,
                dormitoryName: valueObject,
            }
        }
        this.setState({data});
    }

    setAddressState = (value,addressType)=>{
        const data = {...this.state.data};
        const address = {...this.state.data[addressType]}
        address.state = value;
        data[addressType] = address;

        /*
          This updates delivery address state if pickup and delivery address are same 
          and the user is updating pickup address after delivery address radio is set
        */
       if(addressType === "pickUpAddress" && data.deliveryAddress.isSameAsPickupAddress === "Yes"){
            //data.deliveryAddress.state = value;
            const stateList = getUSStateList();
            data.deliveryAddress = {
                ...data.deliveryAddress,
                state: _.find(stateList,{'value':value}),
            }
        }

        this.setState({data});

    }

    validate= async ()=>{
        const options = {abortEarly:false,allowUnknown:true};
        //console.log(this.state.data);
        const {email} = this.state.data
        let {error:primaryInformationError} = this.userSchema.validate(this.state.data,options);
        let {error:pickUpAddressError} = this.pickupAddressSchema.validate(this.state.data.pickUpAddress,options);
        let {error:deliveryAddressError} = this.deliveryAddressSchema.validate(this.state.data.deliveryAddress,options);
        
        //Validate if email exists in the system
        if(!primaryInformationError){
            const {data:returnStatus} = await http.post(`${apiUserEndpoint}/validateByEmail`,{email});
            if(returnStatus.status === false){
                primaryInformationError = {
                    details:[
                        {message:"User with email address exist in the system",
                        path:["emailAddressExist"],
                        }
                    ]
                }; 
            }
        }
        //console.log(primaryInformationError);
        //console.log(pickUpAddressError);
        //console.log(deliveryAddressError);
        
        if( primaryInformationError === undefined  && 
            pickUpAddressError === undefined && 
            deliveryAddressError === undefined) return null;

        const primaryInformationErrors = {};
        if(primaryInformationError){
            primaryInformationError.details.map(
                function(errorObject){
                    //console.log(errorObject);
                    if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                        const key = errorObject.context.key;
                        const joiType = errorObject.type;
                        //console.log(this.errorMessages);
                        if(_.has(this.errorMessages, [key, joiType]))
                            return primaryInformationErrors[errorObject.path[0]] = this.errorMessages[key][joiType];
                        else
                            return primaryInformationErrors[errorObject.path[0]] = errorObject.message;
                    }
                    else
                      return primaryInformationErrors[errorObject.path[0]] = errorObject.message;
                }.bind(this)
            );
        }
        
        const pickUpAddressErrors = {};
        if(pickUpAddressError){
            pickUpAddressError.details.map(
                function(errorObject){
                    const addressKey = "pickUpAddress";
                    const addressLabel = "Pickup Address: ";
                    //console.log(errorObject);
                    if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                        const key = errorObject.context.key;
                        const joiType = errorObject.type;
                        //console.log(this.errorMessages);
                        if(_.has(this.errorMessages, [key, joiType]))
                            return pickUpAddressErrors[`${addressKey}.${errorObject.path[0]}`] = addressLabel + this.errorMessages[key][joiType];
                        else
                            return pickUpAddressErrors[`${addressKey}.${errorObject.path[0]}`] = addressLabel + errorObject.message;
                    }
                    else
                      return pickUpAddressErrors[`${addressKey}.${errorObject.path[0]}`] = addressLabel + errorObject.message;
                }.bind(this)
            );
        }

        const deliveryAddressErrors = {};
        if(deliveryAddressError){
            deliveryAddressError.details.map(
                function(errorObject){
                    const addressKey = "deliveryAddress";
                    const addressLabel = "Delivery Address: ";
                    //console.log(errorObject);
                    if(_.has(errorObject, ['context', 'key']) && _.has(errorObject, ['type'])){
                        const key = errorObject.context.key;
                        const joiType = errorObject.type;
                        //console.log(this.errorMessages);
                        if(_.has(this.errorMessages, [key, joiType]))
                            return deliveryAddressErrors[`${addressKey}.${errorObject.path[0]}`] = addressLabel + this.errorMessages[key][joiType];
                        else
                            return deliveryAddressErrors[`${addressKey}.${errorObject.path[0]}`] = addressLabel + errorObject.message;
                    }
                    else
                      return deliveryAddressErrors[`${addressKey}.${errorObject.path[0]}`] = addressLabel + errorObject.message;
                }.bind(this)
            );
        }
        
        const errors = {...primaryInformationErrors,...pickUpAddressErrors} 
        //const errors = {...primaryInformationErrors,...pickUpAddressErrors,...deliveryAddressErrors} 
    
        console.log(errors);
        return Object.keys(errors).length > 0 ? errors:null;
    }

    render(){
        const {
            listOfSchoolsByRegion,
            onClickOfBackButtonFromStudentInformation,
            onClickOfNextButtonFromStudentInformation,
            user
        } = this.props;
        const {type:storageType} = user.storage;
        const {data,errors} = this.state;
        const {pickUpAddress,deliveryAddress} = data;
        
        const pickUpAddressIsDormitoryCheckedYes = (pickUpAddress.isDormitory === "Yes") ? {defaultChecked:true}:{};
        const pickUpAddressIsDormitoryCheckedNo = (pickUpAddress.isDormitory === "No") ? {defaultChecked:true}:{};
        
        const deliveryAddressIsDormitoryCheckedYes = (deliveryAddress.isDormitory === "Yes") ? {defaultChecked:true}:{};
        const deliveryAddressIsDormitoryCheckedNo = (deliveryAddress.isDormitory === "No") ? {defaultChecked:true}:{};
        console.log('deliveryAddressIsDormitoryCheckedYes',deliveryAddressIsDormitoryCheckedYes);
        console.log('deliveryAddressIsDormitoryCheckedNo',deliveryAddressIsDormitoryCheckedNo);
        
        const deliveryAddressIsDormitoryCSS = (deliveryAddress.isSameAsPickupAddress === "Yes") ? "form-check-radio  disabled":"form-check-radio";
        
        
        const deliveryAddressSameAsPickUpAddressCheckedYes = (deliveryAddress.isSameAsPickupAddress === "Yes") ? {defaultChecked:true}:{};
        const deliveryAddressSameAsPickUpAddressCheckedNo = (deliveryAddress.isSameAsPickupAddress === "No") ? {defaultChecked:true}:{};
        

        const deliveryAddressInputGenericProperties = (deliveryAddress.isSameAsPickupAddress === "Yes") ? {disabled:true}:{};
        

        return(
            <Container id="mainContentArea">
            <Row className="title-row justify-content-between">
                <Col md="6">
                <h3 className="shop">Signup for Storage - Student</h3>
                </Col>
                <Col md="4">
                    <div className="text-md-right d-none">
                        <span className="text-muted">Step 3</span>
                        <Button color="link">
                        <i className="fa fa-shopping-cart" /> Enter your information
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row className="title-body-row">
            <Col md="8">
                    <Card>
                        <CardBody>
                            {/*Error display */}
                            {!_.isEmpty(errors) &&
                                <FormErrorWidget
                                  errors={errors}
                                />
                            }
                            {/* End of Error display */}
                            <Form>
                                <FormGroup row>
                                <Label for="firsName" sm={2}>Name</Label>
                                <Col xs={6} sm={5}>
                                    <Input 
                                      type="text" 
                                      name="firstName" 
                                      id="firstName" 
                                      placeholder="First Name" 
                                      value={this.state.data.firstName} 
                                      onChange={this.handleChange}
                                    />
                                </Col>
                                <Col xs={6} sm={5}>
                                    <Input 
                                       type="text" 
                                       name="lastName" 
                                       id="firstName" 
                                       placeholder="Last Name" 
                                       value={this.state.data.lastName} 
                                       onChange={this.handleChange}
                                    />
                                </Col>
                                </FormGroup>
                                
                                <FormGroup row>
                                <Label for="email" sm={2}>Email</Label>
                                <Col sm={10}>
                                    <Input 
                                        type="email" 
                                        name="email" 
                                        id="email" 
                                        placeholder="Email address" 
                                        value={this.state.data.email} 
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                </FormGroup>

                                <FormGroup row>
                                <Label for="cellPhone" sm={2}>Cell Phone</Label>
                                <Col sm={10}>
                                    <Input 
                                        type="text" 
                                        name="cellPhone" 
                                        id="cellPhone" 
                                        placeholder="" 
                                        value={this.state.data.cellPhone} 
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                </FormGroup>

                                <FormGroup row>
                                <Label for="cellPhone" sm={2}>Home Phone</Label>
                                <Col sm={10}>
                                    <Input 
                                        type="text" 
                                        name="homePhone" 
                                        id="homePhone" 
                                        placeholder="" 
                                        maxLength="20"
                                        value={this.state.data.homePhone} 
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                </FormGroup>

                                <FormGroup row>
                                <Label for="studentId" sm={2}>Student ID</Label>
                                <Col sm={10}>
                                    <Input 
                                        type="text" 
                                        name="studentId" 
                                        id="studentId" 
                                        placeholder="" 
                                        maxLength="16"
                                        value={this.state.data.studentId} 
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                </FormGroup>

                                <h6 className="card-category text-left mt-2">Campus (Pickup) Address</h6>
                                <hr/>

                                <FormGroup row>
                                    <Label for="" xs={6} sm={4}>Do you live in a dorm?</Label>
                                    <Col xs={2} sm={1} className="mt-1">
                                        <div className="form-check-radio">
                                            <Label check>
                                                <Input
                                                    {...pickUpAddressIsDormitoryCheckedYes}
                                                    defaultValue="Yes"
                                                    id="pickUpAddressIsDormitory1"
                                                    name="isDormitoryPickUpAddress"
                                                    type="radio"
                                                    onChange={e=>{this.handlePickupAddressCheckboxDoYouLiveInDorm(e)}}
                                                />
                                                Yes
                                                <span className="form-check-sign" />
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col xs={2} sm={1} className="mt-1 ml-2">
                                        <div className="form-check-radio">
                                            <Label check>
                                                <Input
                                                    {...pickUpAddressIsDormitoryCheckedNo}
                                                    defaultValue="No"
                                                    id="pickUpAddressIsDormitory2"
                                                    name="isDormitoryPickUpAddress"
                                                    type="radio"
                                                    onChange={e=>{this.handlePickupAddressCheckboxDoYouLiveInDorm(e)}}
                                                />
                                                No
                                                <span className="form-check-sign" />
                                            </Label>
                                        </div>
                                    </Col>
                                </FormGroup>

                                {
                                    (pickUpAddress.isDormitory === "Yes") &&    
                                    <FormGroup row>
                                        <Label for="pickUpDormitory" sm={2}>Dorm Name</Label>
                                        <Col xs={10} sm={4}>
                                            <FormGroup>
                                                <Select
                                                    className="react-select react-select-default"
                                                    classNamePrefix="react-select"
                                                    name="pickUpAddressDormitory"
                                                    id="pickUpAddresDormitory"
                                                    value={this.state.data.pickUpAddress.dormitoryName}
                                                    onChange={value => this.setAddressDormitory(value,"pickUpAddress")}
                                                    options={this.state.dormitoryList}
                                                    placeholder="Dorm"
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Label for="addressLine1" sm={2}>Room No.</Label>
                                        <Col xs={10} sm={4}>
                                            <Input 
                                            type="text" 
                                            name="dormitoryRoomNumber" 
                                            id="dormitoryRoomNumber" 
                                            placeholder="Room No" 
                                            maxLength="10"
                                            value={this.state.data.pickUpAddress.dormitoryRoomNumber} 
                                            onChange={e=>{this.handlePickupAddressBasicInputChange(e)}}
                                            />
                                            
                                        </Col>
                                    </FormGroup>
                                }
                                
                                {
                                    (pickUpAddress.isDormitory !== "Yes") &&   
                                    <FormGroup row>
                                        <Label for="streetNumber" sm={2}>Street #</Label>
                                        <Col sm={4}>
                                            <Input 
                                            type="text" 
                                            name="streetNumber" 
                                            id="streetNumber" 
                                            placeholder="Street #" 
                                            maxLength="6"
                                            value={this.state.data.pickUpAddress.streetNumber} 
                                            onChange={e=>{this.handlePickupAddressBasicInputChange(e)}}
                                            />
                                        </Col>

                                        <Label for="apartmentNumber" sm={2}>Apt #</Label>
                                        <Col sm={4}>
                                            <Input 
                                            type="text" 
                                            name="apartmentNumber" 
                                            id="apartmentNumber" 
                                            placeholder="Apt Number" 
                                            maxLength="10"
                                            value={this.state.data.pickUpAddress.apartmentNumber} 
                                            onChange={e=>{this.handlePickupAddressBasicInputChange(e)}}
                                            />
                                        </Col>
                                        
                                    </FormGroup>
                                }

                                {
                                    (pickUpAddress.isDormitory !== "Yes") &&   
                                    <FormGroup row>
                                        <Label for="addressLine1" sm={2}>Street Name</Label>
                                        <Col sm={10}>
                                            <Input 
                                            type="text" 
                                            name="addressLine1" 
                                            id="addressLine1" 
                                            placeholder="Street Name" 
                                            value={this.state.data.pickUpAddress.addressLine1} 
                                            onChange={e=>{this.handlePickupAddressBasicInputChange(e)}}
                                            />
                                        </Col>
                                        
                                    </FormGroup>
                                }
                                {
                                    (pickUpAddress.isDormitory !== "Yes") &&  
                                    <FormGroup row className="d-none">
                                        <Label for="addressLine2" sm={2}>Address Line 2</Label>
                                        <Col sm={10}>
                                            <Input 
                                            type="text" 
                                            name="addressLine2" 
                                            id="addressLine2" 
                                            placeholder="Address Line 2" 
                                            value={this.state.data.pickUpAddress.addressLine2} 
                                            onChange={e=>{this.handlePickupAddressBasicInputChange(e)}}
                                            />
                                        </Col>
                                        
                                    </FormGroup>
                                }    
                                
                                
                                <FormGroup row>
                                    <Label for="city" sm={2}></Label>
                                    <Col xs={12} sm={3}>
                                        <Input 
                                        type="text" 
                                        name="city" 
                                        id="city" 
                                        placeholder="City" 
                                        value={this.state.data.pickUpAddress.city} 
                                        onChange={e=>{this.handlePickupAddressBasicInputChange(e)}}
                                        />
                                    </Col>
                                    <Label for="state" xs={12} sm={2} className="d-block d-sm-none"></Label>
                                    <Col xs={12} sm={4}>
                                        <FormGroup>
                                            <Select
                                                className="react-select react-select-default"
                                                classNamePrefix="react-select"
                                                name="state"
                                                id="state"
                                                isDisabled={true}
                                                value={this.state.data.pickUpAddress.state}
                                                onChange={value => this.setAddressState(value,"pickUpAddress")}
                                                options={this.state.stateList}
                                                placeholder="State"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={3}>
                                        <Input 
                                        type="text" 
                                        name="postalCode" 
                                        id="postalCode" 
                                        placeholder="Zip" 
                                        maxLength="5"
                                        value={this.state.data.pickUpAddress.postalCode} 
                                        onChange={e=>{this.handlePickupAddressBasicInputChange(e)}}
                                        />
                                    </Col>
                                </FormGroup>

                                <h6 className="card-category text-left mt-2">Delivery Address <br/> (If you do not know your delivery address, skip this section)</h6>
                                <hr/>

                                <FormGroup row>
                                    <Label for="" xs={6} sm={4}>Is your delivery address the same as above</Label>
                                    <Col xs={2} sm={1} className="mt-1">
                                        <div className="form-check-radio">
                                            <Label check>
                                                <Input
                                                    {...deliveryAddressSameAsPickUpAddressCheckedYes}
                                                    defaultValue="Yes"
                                                    id="isSameAsPickupAddressYes"
                                                    name="isSameAsPickupAddress"
                                                    type="radio"
                                                    onChange={e=>{this.handleIsDeliverySameAsPickupCheckbox(e)}}
                                                />
                                                Yes
                                                <span className="form-check-sign" />
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col xs={2} sm={1} className="mt-1  ml-2">
                                        <div className="form-check-radio">
                                            <Label check>
                                                <Input
                                                    {...deliveryAddressSameAsPickUpAddressCheckedNo}
                                                    defaultValue="No"
                                                    id="isSameAsPickupAddressNo"
                                                    name="isSameAsPickupAddress"
                                                    type="radio"
                                                    onChange={e=>{this.handleIsDeliverySameAsPickupCheckbox(e)}}
                                                />
                                                No
                                                <span className="form-check-sign" />
                                            </Label>
                                        </div>
                                    </Col>
                    
                                </FormGroup>
                                

                                <FormGroup row>
                                    <Label for="" xs={6} sm={4}>Moving to a dorm?</Label>
                                    <Col xs={2} sm={1}  className="mt-1">
                                        <div className={deliveryAddressIsDormitoryCSS}>
                                            <Label check>
                                                <Input
                                                    checked={deliveryAddress.isDormitory === "Yes" ? true:false}
                                                    value="Yes"
                                                    id="deliveryAddressIsDormitory1"
                                                    name="isDormitoryDeliveryAddress"
                                                    type="radio"
                                                    onChange={e=>{this.handleDeliveryAddressCheckboxDoYouLiveInDorm(e)}}
                                                    {...deliveryAddressInputGenericProperties}
                                                />
                                                Yes
                                                <span className="form-check-sign" />
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col xs={2} sm={1} className="ml-2 mt-1">
                                        <div className={deliveryAddressIsDormitoryCSS}>
                                            <Label check>
                                                <Input
                                                    checked={deliveryAddress.isDormitory === "No" ? true:false}
                                                    value="No"
                                                    id="deliveryAddressIsDormitory2"
                                                    name="isDormitoryDeliveryAddress"
                                                    type="radio"
                                                    onChange={e=>{this.handleDeliveryAddressCheckboxDoYouLiveInDorm(e)}}
                                                    {...deliveryAddressInputGenericProperties}
                                                />
                                                No
                                                <span className="form-check-sign" />
                                            </Label>
                                        </div>
                                    </Col>
                                    
                                </FormGroup>

                                {
                                    (deliveryAddress.isDormitory === "Yes") &&    
                                    <FormGroup row>
                                        <Label for="pickUpDormitory" sm={2}>Dorm Name</Label>
                                        <Col xs={10} sm={4}>
                                            <FormGroup>
                                                <Select
                                                    className="react-select react-select-default"
                                                    classNamePrefix="react-select"
                                                    name="dormitoryRoomNumber"
                                                    id="dormitoryRoomNumber"
                                                    isDisabled={(deliveryAddress.isSameAsPickupAddress === "Yes") ? true:false}
                                                    value={deliveryAddress.dormitoryName}
                                                    onChange={value => this.setAddressDormitory(value,"deliveryAddress")}
                                                    options={this.state.dormitoryList}
                                                    placeholder="Dorm"
                                                    {...deliveryAddressInputGenericProperties}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Label for="addressLine1" sm={2}>Room No.</Label>
                                        <Col xs={10} sm={4}>
                                            <Input 
                                            type="text" 
                                            name="dormitoryRoomNumber" 
                                            id="dormitoryRoomNumber" 
                                            placeholder="Room No" 
                                            maxLength="10"
                                            value={this.state.data.deliveryAddress.dormitoryRoomNumber} 
                                            onChange={e=>{this.handleDeliveryAddressBasicInputChange(e)}}
                                            {...deliveryAddressInputGenericProperties}
                                            />
                                        </Col>
                                    </FormGroup>
                                }

                                {
                                    (deliveryAddress.isDormitory !== "Yes") &&   
                                    <FormGroup row>
                                        <Label for="streetNumber" sm={2}>Street #</Label>
                                        <Col sm={4}>
                                            <Input 
                                            type="text" 
                                            name="streetNumber" 
                                            id="streetNumber" 
                                            placeholder="Street #" 
                                            maxLength="6"
                                            value={this.state.data.deliveryAddress.streetNumber} 
                                            onChange={e=>{this.handleDeliveryAddressBasicInputChange(e)}}
                                            />
                                        </Col>

                                        <Label for="apartmentNumber" sm={2}>Apt #</Label>
                                        <Col sm={4}>
                                            <Input 
                                            type="text" 
                                            name="apartmentNumber" 
                                            id="apartmentNumber" 
                                            placeholder="Apt Number" 
                                            maxLength="10"
                                            value={this.state.data.deliveryAddress.apartmentNumber} 
                                            onChange={e=>{this.handleDeliveryAddressBasicInputChange(e)}}
                                            />
                                        </Col>
                                        
                                    </FormGroup>
                                }               
                                
                                {
                                    (deliveryAddress.isDormitory !== "Yes") &&   
                                    <FormGroup row>
                                        <Label for="addressLine1" sm={2}>Street Name</Label>
                                        <Col sm={10}>
                                            <Input 
                                            type="text" 
                                            name="addressLine1" 
                                            id="addressLine1" 
                                            placeholder="Street Name" 
                                            value={this.state.data.deliveryAddress.addressLine1} 
                                            onChange={e=>{this.handleDeliveryAddressBasicInputChange(e)}}
                                            {...deliveryAddressInputGenericProperties}
                                            />
                                        </Col>
                                        
                                    </FormGroup>
                                }
                                {
                                    (deliveryAddress.isDormitory !== "Yes") &&  
                                    <FormGroup row className="d-none">
                                        <Label for="addressLine2" sm={2}>Address Line 2</Label>
                                        <Col sm={10}>
                                            <Input 
                                            type="text" 
                                            name="addressLine2" 
                                            id="addressLine2" 
                                            placeholder="Address Line 2" 
                                            value={this.state.data.deliveryAddress.addressLine2} 
                                            onChange={e=>{this.handleDeliveryAddressBasicInputChange(e)}}
                                            {...deliveryAddressInputGenericProperties}
                                            />
                                        </Col>
                                        
                                    </FormGroup>
                                }    
                                
                                
                                <FormGroup row>
                                    <Label for="city" sm={2}></Label>
                                    <Col xs={12} sm={3}>
                                        <Input 
                                        type="text" 
                                        name="city" 
                                        id="city" 
                                        placeholder="City" 
                                        value={this.state.data.deliveryAddress.city} 
                                        onChange={e=>{this.handleDeliveryAddressBasicInputChange(e)}}
                                        {...deliveryAddressInputGenericProperties}
                                        />
                                    </Col>
                                    <Label for="state" xs={12} sm={2} className="d-block d-sm-none"></Label>
                                    <Col xs={12} sm={4}>
                                        <FormGroup>
                                            <Select
                                                className="react-select react-select-default"
                                                classNamePrefix="react-select"
                                                name="state"
                                                id="state"
                                                isDisabled={true}
                                                value={this.state.data.deliveryAddress.state}
                                                onChange={value => this.setAddressState(value,"deliveryAddress")}
                                                options={this.state.stateList}
                                                placeholder="State"
                                                {...deliveryAddressInputGenericProperties}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={3}>
                                        <Input 
                                        type="text" 
                                        name="postalCode" 
                                        id="postalCode" 
                                        placeholder="Zip" 
                                        maxLength="5"
                                        value={this.state.data.deliveryAddress.postalCode} 
                                        onChange={e=>{this.handleDeliveryAddressBasicInputChange(e)}}
                                        {...deliveryAddressInputGenericProperties}
                                        />
                                    </Col>
                                </FormGroup>


                                
                                <FormGroup row  className="mt-5 justify-content-between">
                                <Col xs={{ size: 2}}>
                                    <Button 
                                      className="btn-rounded btn-danger"
                                      onClick={()=>onClickOfBackButtonFromStudentInformation()}
                                    >
                                        Back
                                    </Button>
                                </Col>
                                <Col xs={{ size: 6}} sm={{ size: 4}} className="text-right">
                                    <Button 
                                       className="btn-rounded btn-danger"
                                       onClick={this.handleSubmit}
                                    >
                                       Save & Proceed
                                    </Button>
                                </Col>
                                </FormGroup>
                            </Form>                      
                        </CardBody>
                    </Card> 
            </Col>
            <Col md="4" className="d-none d-md-block">
                        {
                            storageType === "student" && <StudentLaundryRegistrationStepsDisplayWidget />
                        }
                        
                        
            </Col>    
            
            </Row>    
            
                        
            
        </Container>      
        );
    }
} 
  


export default StudentInformation