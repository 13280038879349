import React,{Component} from "react";
import Joi from "@hapi/joi"
import _ from "lodash";
import moment from "moment";
import ReactDatetime from "react-datetime";
import accounting from "accounting";


import auth from "../../../../../../services/authService";
import commercialService from "../../../../../../services/commercialService";

import BaseForm from "../../../../../BaseClass/Form"
import BaseTable from "../../../../../BaseClass/Table"

import ProcessingModal from "../../../../../Common/ProcessingModal";
import {renderError}   from '../../../../../../utils/errorUtils';
import FormErrorWidget from "../../../../../Common/FormErrorWidget";
import {scrollTop}     from '../../../../../../utils/helperUtils';
import customToast     from '../../../../../Common/CustomToast';



import {
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,  
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Table,

} from "reactstrap";

class SalesReport extends BaseForm{
    divAreaToScrollForError = "sectionArea"

    validationSchema = Joi.object({
        startDate: Joi.date().less(Joi.ref('endDate')).required().label('Start Date'),
        endDate: Joi.date().required().label('End Date'),
        universityId: Joi.any().optional(),
        campusId: Joi.any().optional(),
        resultSet: Joi.any().optional(),
        /*
        creditCardName:Joi.alternatives().conditional(
            'updatePaymentInformationFlag',
             { 
                is: true, 
                then: Joi.string().required().label('Credit Card Holder Name'), 
                otherwise: Joi.any().optional() 
            }
        ),
        updatePaymentInformationFlag: Joi.boolean(),
        */
    });

    errorMessages = {
        "startDate":{
            "date.less":"Start date needs to be less than the End date",
        },
    }

    
    constructor(){
        super();
        this.state = {
            processingModalDisplayFlag:false,
            data:{
                universityId:"",
                campusId:"",
                startDate:moment().subtract(1,'month').format(`MM/DD/YYYY`),
                endDate:moment().format(`MM/DD/YYYY`),
                resultSet:"",
            },
            errors:{},
        }
    }

    componentDidMount(){
        const user = auth.getCurrentUser();
        if(!_.isEmpty(user)){
            const { universityId,campusId } = user;
            this.setState({
                data:{
                    ...this.state.data, 
                    universityId,
                    campusId,
                }
            })
        }        
    }

    backToLandingPage = (e)=>{
        e.preventDefault();
        this.props.history.push("/myaccount/commercial")
    }

    handleCalendarDate = (dateMoment,inputName)=>{
        const data = {...this.state.data}
        data[inputName] = moment(dateMoment).format(`MM/DD/YYYY`);
        this.setState({data});
    }

    setProcessingModalDisplayFlag = (flag)=>{
        this.setState({
            processingModalDisplayFlag:flag
        });
    }
    
    submitLocal = async ()=>{
        try{
            //Build payload
            const formData = {...this.state.data};
            this.setState({
                data:{
                    ...this.state.data,
                    resultSet:"",
                }
            })

            this.setProcessingModalDisplayFlag(true);

            const {status:processingStatus,message,salesData} = await commercialService.getSalesReportData(formData);
            if(processingStatus === true && salesData.length){
                //customToast.success(message);
                
                const resultSet = {
                    tableHeader:"Sales Report",
                    tableColumn:[
                        { 
                            key:"1",
                            name:"Customer",
                            classDefinition:"text-left",
                            styleDefinition:null,
                            getFieldData: data=>`${data.customer_name} [${data.user_id}]`,
                            defaultDataValue:"",
                            formattedFieldGetter:(fieldData,defaultDataValue)=>{
                                return <strong>{fieldData}</strong>;
                            }
                        },
                        { 
                            key:"2",
                            name:"",
                            classDefinition:"text-right",
                            styleDefinition:{width:"15%"},
                            getFieldData:"",
                            defaultDataValue:"Laundry:",
                            formattedFieldGetter:(fieldData,defaultDataValue)=>{
                                return <strong>{(fieldData) ? fieldData:defaultDataValue}</strong>;
                            }
                        },
                        { 
                            key:"3",
                            name:"Amount",
                            classDefinition:"text-right",
                            styleDefinition:{width:"15%"},
                            getFieldData: data=>data.sales,
                            defaultDataValue:"",
                            formattedFieldGetter:(fieldData,defaultDataValue)=>{
                                return <strong>{accounting.formatMoney(fieldData)}</strong>;
                            }
                        },    
                    ],
                    tableData:salesData,
                    tableFooterDisplayFlag:true,
                }

                const salesTotal = _.reduce(salesData,(sum,n)=>{
                    return sum += n.sales;
                },0)

                //console.log('salesTotal',accounting.toFixed(salesTotal,2));

                resultSet.tableFooter = <tr>
                                            <td/>
                                            <td className="td-total">Total</td>
                                            <td className="td-price">
                                                {accounting.formatMoney(salesTotal)}
                                            </td>
                                        </tr>

                //console.log(resultSet);

                this.setState({
                    data:{
                        ...this.state.data,
                        resultSet,
                    }
                })

            }
            else if(processingStatus === false){
                customToast.error(message); 
            }

            this.setProcessingModalDisplayFlag(false);
            
        } 
        catch(ex){
            /*
              400 signals validation errors from the server
              renderError() basically will display server side error other than code 400
            */
            this.setProcessingModalDisplayFlag(false);
            
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop('sectionArea'); return;}
            }
            else
                renderError(ex);
        } 
    }

    validateLocal= ()=>{

    }

    render(){
        const {data,errors,processingModalDisplayFlag} = this.state;
        const { startDate,endDate,resultSet } = data;
        const displayFlag = false;
        return(
            <>
                <div className="border-bottom mb-4">
                    <div className="d-flex justify-content-between bd-highlight mb-3">
                        <div className="pl-0 py-2 bd-highlight mt-2">
                            <h6 className="card-category">
                                <span className="text-left">
                                Sales Report:
                                </span>
                            </h6>
                        </div>
                        <div className="p-2 bd-highlight">
                        </div>
                    </div>
                </div>
                
                <div>
                    {/*Error display */}
                    {   !_.isEmpty(errors) &&
                        <FormErrorWidget
                            errors={errors}
                        />
                    }
                    {/* End of Error display */}
                </div>

                {/* Search widget */}
                <div>
                    
                <Form>
                        <Row>
                            <Col xs="6" md="4" sm="4">
                                <FormGroup>
                                    <label>Start Date</label>
                                    <InputGroup 
                                        name="startDate"
                                        className="date" 
                                        id="startDate"
                                        
                                    >
                                        <ReactDatetime
                                            value={moment(startDate)} 
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            name="startDate"
                                            onChange={(dateMoment)=>this.handleCalendarDate(dateMoment,"startDate")}
                                            inputProps={{
                                                className: "form-control",
                                                placeholder: "Start Date",
                                            }}
                                            
                                            isValidDate={current=>current.isSameOrBefore(moment())}
                                        />
                                        <InputGroupAddon addonType="append">
                                            <InputGroupText>
                                                <span className="glyphicon glyphicon-calendar">
                                                <i className="fa fa-calendar" />
                                                </span>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col xs="6" md="4" sm="4">
                                <FormGroup>
                                    <label>End Date</label>
                                    <InputGroup 
                                        name="endDate"
                                        className="date" 
                                        id="endDate"
                                        
                                    >
                                        <ReactDatetime
                                            value={moment(endDate)} 
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            name="endDate"
                                            onChange={(dateMoment)=>this.handleCalendarDate(dateMoment,"endDate")}
                                            inputProps={{
                                                className: "form-control",
                                                placeholder: "End Date",
                                            }}
                                            isValidDate={current=>current.isSameOrBefore(moment())}
                                        />
                                        <InputGroupAddon addonType="append">
                                            <InputGroupText>
                                                <span className="glyphicon glyphicon-calendar">
                                                <i className="fa fa-calendar" />
                                                </span>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col xs="6" md="2" sm="2">
                                <Button
                                    className="btn-just-icon mt-lg d-none d-sm-block"
                                    color="default"
                                    onClick={this.submitForm}
                                >
                                    <i className="fa fa-search" />
                                </Button>
                                <Button
                                    className="btn-just-icon mt-xs-0 d-block d-sm-none"
                                    color="default"
                                    onClick={this.submitForm}
                                >
                                    <i className="fa fa-search" />
                                </Button>

                            </Col>
                        </Row>

                </Form>
                </div>
                {/* End of Search widget */}

                {/* Result table view */}

                <div>
                    {   resultSet && 
                        <BaseTable
                            tableProperties={resultSet}
                        />
                    }
                    
                </div>
                {/* End of Result table view */}

                <FormGroup row  className="mt-5 justify-content-between">
                    <Col xs={{ size: 2}}>
                        <Button 
                            className="btn-rounded btn-danger"
                            onClick={this.backToLandingPage}
                        >
                            Back
                        </Button>
                    </Col>
                    
                </FormGroup>
                
                {/* Set Processing modal */}
                <ProcessingModal
                    displayModal={processingModalDisplayFlag}
                    handleCloseOfModalWindow={this.setProcessingModalDisplayFlag}
                />
                {/* End of Processing modal */}

            </>
        )
    }
}

export default SalesReport