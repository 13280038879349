import React,{ useState }  from "react";
import accounting from "accounting";
import ReactDatetime from "react-datetime";
import moment from "moment";
import customToast from '../../../Common/CustomToast'



import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Badge,
    Button,
    ButtonGroup,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Nav,
    NavLink,
    NavItem,
    TabContent,
    TabPane,
    Table,
    Media,
    UncontrolledTooltip
} from "reactstrap";


function ShoppingCartWidget(props){
   const {editMode,handleShoppingCartShippingDate,handleShoppingCartValidateCouponCode,onClickViewCart,onClickProceedToCheckout,onRemoveOfItem,onUpdateOfItem} = props;
   const {shoppingCart}=  props.globalApp;
   const {
       couponProcessingFlag,
       cartTotal,
       discount,
       postalCodeShippingStartDateToBlock:shippingDateBlockStartDate,
       postalCodeShippingEndDateToBlock:shippingDateBlockEndDate,
       supplies,
       university
    } = shoppingCart;
   const {minimum_supply_cart_total:minimumShoppingCartAmount} = university;
   const {discountCode}  = discount;
   const {salesTaxPercent,subTotal,totalTax,total} = cartTotal;
   const {shippingDate:shippingDateFromCart} = supplies;
   const products = [...shoppingCart.supplies.items];
   const updateDefaultQuantity = 1;

   const [couponCode, setCouponCode] = useState(discountCode);
   
   const [shippingDate, setShippingDate] = useState(shippingDateFromCart);
   
   const handleApplyCoupon = async (e)=>{
        e.preventDefault();
        if(total === 0) {
            customToast.error("Please add items to cart");
            return;
        }

        if(couponCode == ""){
            customToast.error("Please enter a gift card code");
            return;
        }
           
        let formDataObject = {
            couponCode,
            product:"supplies",
            productType:null, 
            userType:null,
        };

        await handleShoppingCartValidateCouponCode(formDataObject);
        setCouponCode("");
        //customToast.success("Gift card applied!!!")
   }

   const handleInputChange = ({currentTarget:input})=>{
        setCouponCode(input.value)
   }  

   const handleShippingDate = (value)=>{
        handleShoppingCartShippingDate(value);
        setShippingDate(value);
   }

   const getMinumumAmountForSupplyOrderDisplay = ()=>{
       let displayText = "";
       
       if(minimumShoppingCartAmount > 0)
            displayText = ` [Min Purchase: ${accounting.formatMoney(minimumShoppingCartAmount)}]`;

       return displayText;
   }




   return(
       <>
            <Card className="sticky-top card-plain bg-white p-2 rounded-lg">
                <CardBody>
                    <h6 className="card-category">
                        <span className="text-left">
                            <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                            {" "} Your Cart
                            {" "} {getMinumumAmountForSupplyOrderDisplay()}
                        </span>
                    </h6>
                    <div className="mt-2">
                        <Table responsive>
                            <tbody>
                            {(products.length === 0) && 
                            <tr>
                                <td colSpan="5" className="text-center"><strong>No items in cart</strong></td>
                            </tr>
                            }
                            {
                                products.length > 0 &&
                                products.map(p=>{
                                    const key = (p.isBundle === true) ? `bundle-${p.id}`:`indiviudal-${p.id}`; 
                                    return(
                                        <tr key={key}>
                                            <td className="text-center px-0">{p.title}</td>
                                            <td>{` ${p.quantity}`}</td>
                                            <td  className="px-0">
                                                {
                                                    editMode && 
                                                    <ButtonGroup>
                                                        <Button
                                                        className="btn-border btn-round"
                                                        color="default"
                                                        size="sm"
                                                        onClick={e=>{
                                                            e.preventDefault();
                                                            return onUpdateOfItem(p,updateDefaultQuantity,"subtract")
                                                        }}
                                                        >
                                                        -
                                                        </Button>
                                                        <Button
                                                        className="btn-border btn-round"
                                                        color="default"
                                                        size="sm"
                                                        onClick={e=>{
                                                            e.preventDefault();
                                                            return onUpdateOfItem(p,updateDefaultQuantity,"add")
                                                        }}
                                                        >
                                                        +
                                                        </Button>
                                                    </ButtonGroup> 
                                                }
                                                
                                            </td>
                                            <td className="text-right pr-0">{accounting.formatMoney(p.price * p.quantity)}</td>
                                            <td className="td-actions text-right px-0">
                                                {
                                                    editMode && 
                                                    <Button
                                                        className="btn-link"
                                                        color="danger"
                                                        data-toggle="tooltip"
                                                        size="sm"
                                                        onClick={e=>{
                                                            e.preventDefault();
                                                            return onRemoveOfItem(p)
                                                        }}
                                                    >
                                                        <i className="fa fa-times" />
                                                    </Button>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            
                            <tr>
                                <td colSpan="3" className="text-right">Subtotal</td>
                                <td className="text-right pr-0">{accounting.formatMoney(subTotal)}</td>
                                <td className="td-actions text-right">
                                    
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="3" className="text-right">Sales Tax ({`${accounting.toFixed(salesTaxPercent, 2)}%`})</td>
                                <td className="text-right pr-0">{accounting.formatMoney(totalTax)}</td>
                                <td className="td-actions text-right">
                                    
                                </td>
                            </tr>
                            {
                                discount.discountValue > 0 && 
                                <tr>
                                    <td colSpan="3" className="text-right">Gift Card Applied</td>
                                    <td className="text-right pr-0">{accounting.formatMoney(discount.discountValue * -1)}</td>
                                    <td className="td-actions text-right">
                                        
                                    </td>
                                </tr>

                            }
                            <tr>
                                <td colSpan="3" className="text-right">Total</td>
                                <td className="text-right pr-0"><strong>{accounting.formatMoney(total)}</strong></td>
                                <td className="td-actions text-right">
                                    
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>

                    {
                        discount.discountValue === 0 &&
                        <>
                            <h6 className="card-category">
                                <span className="text-left">
                                    Enter Gift Card
                                </span>
                            </h6>

                            <div className="d-flex flex-row bd-highlight mt-2">
                                <div className="bd-highlight">
                                    <Input 
                                        type="text" 
                                        name="couponCode" 
                                        id="couponCode" 
                                        placeholder="" 
                                        value={couponCode} 
                                        onChange={handleInputChange}
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="pl-2 bd-highlight">
                                    <Button className="btn-round" color="default" size="sm"
                                        onClick={handleApplyCoupon}
                                    >
                                        {   couponProcessingFlag && 
                                            <div className="uil-reload-css reload-small mr-1">
                                            <div />
                                            </div>
                                        }
                                        {
                                            !couponProcessingFlag && `Apply`
                                        }
                                    </Button>
                                </div>
                            </div>
                        </>
                    }


                    
                    <h6 className="card-category mt-4">
                        <span className="text-left">
                            Delivery Date
                        </span>
                    </h6>
                    
                    <div className="mt-2">
                        <FormGroup>
                            <InputGroup 
                                name="shippingDate"
                                className="date" 
                                id="shippingDate"
                                
                            >
                                <ReactDatetime
                                    value={shippingDate} 
                                    timeFormat={false}
                                    closeOnSelect={true}
                                    onChange={handleShippingDate}
                                    inputProps={{
                                        className: "form-control",
                                        placeholder: "Delivery Date",
                                    }}

                                    isValidDate={currentDate=>{
                                        let enableDate = false;
                                        enableDate = (moment.utc(currentDate).isAfter(moment.utc())) ? true:false;
                                        if(enableDate && shippingDateBlockStartDate && shippingDateBlockEndDate){
                                            enableDate = 
                                                (moment.utc(currentDate).isBefore(moment.utc(shippingDateBlockStartDate), 'day')
                                                 || 
                                                 moment.utc(currentDate).isAfter(moment.utc(shippingDateBlockEndDate), 'day')
                                                ) ?  true:false;

                                            //Disable next day
                                            if(currentDate.isSame(moment().add(1,'days'),'day')){
                                                enableDate = false;
                                            }
                                        }
                                        return enableDate;
                                    }}
                                />
                                <InputGroupAddon addonType="append">
                                <InputGroupText>
                                    <span className="glyphicon glyphicon-calendar">
                                    <i className="fa fa-calendar" />
                                    </span>
                                </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </div>
                    
                    
                    <CardFooter className="text-center">
                        <Button
                        className="btn mr-1"
                        color="info"
                        onClick={e=>onClickViewCart(e)}
                        outline
                        block
                        >
                        <i className="fa fa-shopping-cart" /> View Cart
                        </Button>
                        {
                            editMode && 
                            <Button
                                className="btn mr-1"
                                color="info"
                                href="#pablo"
                                onClick={e=>onClickProceedToCheckout(e)}
                                outline
                                block
                            >
                                 <i className="fa fa-shopping-cart" /> Proceed to Checkout
                            </Button>
                        }
                        
                    </CardFooter>
                </CardBody>
            </Card>        
       </>
   );
}

export default ShoppingCartWidget;