import React from "react"
import Select from "react-select";
import ReCAPTCHA from "react-google-recaptcha";
import _ from "lodash";
import Joi from "@hapi/joi";


import ShortNavbar from "../../components/Navbars/ShortNavbar.js";
import SignupPageHeader from "../../components/Headers/SignupPageHeader";
import FooterWhite from "../../components/Footers/FooterWhite";

import BaseForm from "../../components/BaseClass/Form"

import GiftCardImage from "../../assets/mylazybones/img/GiftCards/GiftCard_Landing.jpg"

import FormErrorWidget from "../../components/Common/FormErrorWidget";
import SuccessMessageWidget from "../../components/Common/SuccessMessageWidget";

import ProcessingModal from "../../components/Common/ProcessingModal";

import UserService from "../../services/userService";
import {renderError} from '../../utils/errorUtils';
import {scrollTop} from '../../utils/helperUtils';




// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Media,
    NavItem,
    NavLink,
    Nav,
    Container,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Label,
    Row,
    Col,
    Input, 
  } from "reactstrap";

class Hiring extends BaseForm{

    divAreaToScrollForError = "description-areas"

    errorMessages = {}

    validationSchema = Joi.object({
        name: Joi.string().required().label('Name'),
        email:Joi.string().email({tlds:false}).required().label('Email'),
        cellPhone:Joi.string().required().label('Cell Phone'),
        location:Joi.object().keys({
            value:Joi.string().required(), 
            label:Joi.any().optional(),
        }),
        message: Joi.any().optional(),
        recaptchaResponse:Joi.string().required().label("Recaptcha"),
        
    }) ;

    errorMessages = {
        "location":{
            "object.base":"Location is not allowed to be empty",
        },
        
    }

    locationList = [
        { value: "", label: "", isDisabled: true },
        { value: "Madison, WI", label: "Madison, WI", },
        { value: "Syracuse, NY", label: "Syracuse, NY", },
        { value: "Newark, DE", label: "Newark, DE", },
        { value: "Boulder, CO", label: "Boulder, CO", },
    ]

    constructor(){
        super()
        this.state = {
            processingModalDisplayFlag:false,
            data:{
                name:"",
                email:"",
                cellPhone:"",
                location:"",
                message:"",
                recaptchaResponse:"",
            },
            errors:{},
            success:{},
            
        }
    }

    handleChangeRecatcha = (value)=> {
        const data  = {...this.state.data};
        data.recaptchaResponse = value;
        this.setState({data})
    }

    handleSelectLocation =  locationOption => {
        const data = {...this.state.data};
        data.location = locationOption;
        this.setState({data})
    }

    setProcessingModalDisplayFlag = flag=>{
        this.setState({processingModalDisplayFlag:flag})
    }

    //This gets executed once the parent submission process is executed
    submitLocal = async ()=>{
        console.log('submit local');
        
        try{
            /* Send data to server */
            const formData = this.state.data;
            this.setProcessingModalDisplayFlag(true);

            const {status,message} = await UserService.hiringRequest(formData);
            if(status === true){
                this.setProcessingModalDisplayFlag(false); 

                const successMessage = <p>
                  We have successfully received your details and will be contacting you soon.
                </p>
                
                this.setState(
                    {
                        processingModalDisplayFlag:false,
                        data:{
                            name:"",
                            email:"",
                            cellPhone:"",
                            location:"",
                            message:"",
                            recaptchaResponse:"",
                        },
                        errors:{},
                        success: {successMessage},
                    }
                ); 
            }
            else{
                this.setProcessingModalDisplayFlag(false); 
                this.setState({
                    errors:{message}
                })
            }
                
        }
        catch(ex){
            console.log(ex);
            this.setProcessingModalDisplayFlag(false);
            /*
              400 signals validation errors from the server
              renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                this.setState({errors: errors || {}});
                if(errors){ scrollTop(this.divAreaToScrollForError); return;}
            }
            else
                renderError(ex);
        }
        
    }

    validateLocal= ()=>{
        
    }

    render(){
        const {data,errors,processingModalDisplayFlag,success} = this.state
        const {name,email,cellPhone,location,message} = data;
        
        return(
            <>
                <ShortNavbar {...this.props} />
                <SignupPageHeader />
                <div className="main">
                    <div className="section section-nude-gray"> 
                        <Container>
                            <div id="description-areas">
                                <Row>
                                    <Col xs="12" md="8">
                                        <h2 className="shop">Join our team!</h2>
                                        <div className="mb-4">
                                            <p className="mt-2">
                                            Lazybones is always looking for new and dedicated staff to work with. We are looking for laundry technicians, folders, delivery drivers and temporary moving and storage employees.
                                            If interested please complete the form below and we will get in touch with you. 
                                            </p>
                                        </div>

                                        {/*Error display */}
                                        {!_.isEmpty(errors) &&
                                            <FormErrorWidget
                                            errors={errors}
                                            />
                                        }
                                        {/* End of Error display */}

                                        {/*Success info display */}
                                        {!_.isEmpty(success) &&
                                            <SuccessMessageWidget
                                            success={success}
                                            />
                                        }
                                        {/* End of Success info display */}

                                        <Row>
                                            <Col xs="12" md="10">
                                                <FormGroup>
                                                    <label>Name: *</label>
                                                    <Input
                                                        className="border-input"
                                                        placeholder="Name"
                                                        type="text"
                                                        name="name" 
                                                        id="name" 
                                                        value={name} 
                                                        onChange={this.handleChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs="12" md="10">
                                                <FormGroup>
                                                    <label>Your email address: *</label>
                                                    <Input
                                                        className="border-input"
                                                        placeholder="Email"
                                                        type="email"
                                                        name="email" 
                                                        id="email" 
                                                        value={email} 
                                                        onChange={this.handleChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs="12" md="10">
                                                <FormGroup>
                                                    <label>Cell Phone: *</label>
                                                    <Input
                                                        className="border-input"
                                                        placeholder="Cell Phone"
                                                        type="text"
                                                        name="cellPhone" 
                                                        id="cellPhone" 
                                                        value={cellPhone} 
                                                        onChange={this.handleChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs="12" md="10">
                                                <FormGroup>
                                                    <label>
                                                        Select Location: 
                                                    </label>
                                                    <Select
                                                        className="react-select react-select-default"
                                                        classNamePrefix="react-select"
                                                        name="location"
                                                        id="location"
                                                        value={location}
                                                        onChange={value => this.handleSelectLocation(value)}
                                                        options={this.locationList}
                                                        placeholder="Select Location"
                                                    />
                                                                    
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs="12" md="10">
                                                <FormGroup>
                                                    <label>Message (optional):</label>
                                                    <Input
                                                    className="textarea-limited"
                                                    type="textarea"
                                                    maxLength="200"
                                                    rows="3"
                                                    name="message" 
                                                    id="message" 
                                                    value={message} 
                                                    onChange={this.handleChange}
                                                    />
                                                    <h5>
                                                    <small>
                                                        <span
                                                        className="pull-right"
                                                        id="textarea-limited-message"
                                                        >
                                                        200 characters
                                                        </span>
                                                    </small>
                                                    </h5>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs="12" md="10">
                                                <ReCAPTCHA
                                                    sitekey={process.env[`REACT_APP_GOOGLE_RECAPTCHA_CLIENT_KEY`]}
                                                    onChange={this.handleChangeRecatcha}
                                                />
                                            </Col>
                                        </Row>

                                        <FormGroup row  className="mt-5 justify-content-between">
                                            <Col xs={{ size: 2}}>
                                                
                                            </Col>
                                            <Col xs={{ size: 6}} sm={{ size: 4}} className="text-right">
                                                <Button 
                                                    type="submit"
                                                    className="btn-round btn btn-danger"
                                                    onClick={this.submitForm}
                                                >
                                                    Submit Request
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                        
                                    </Col>
                                    <Col md="4" className="d-none">
                                        <img
                                            alt="..."
                                            className="img-rounded img-responsive"
                                            src={GiftCardImage}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                </div>
                <FooterWhite />
                {/* Set Processing modal */}
                <ProcessingModal
                    displayModal={processingModalDisplayFlag}
                    handleCloseOfModalWindow={this.setProcessingModalDisplayFlag}
                />
                {/* End of Processing modal */}
            </>
        )
    }
}

export default Hiring;