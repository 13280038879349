import React,{Component} from "react";
import _ from "lodash";

// core components
import ShortNavbar from "../../components/Navbars/ShortNavbar";
import GeneralPageHeader from "../../components/Headers/GeneralPageHeader";
import FooterWhite from "../../components/Footers/FooterWhite";

import {
    Alert,
    Button,
    Container,
    Card,
    CardBody,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Row,
    Col,
    Media
    
} from "reactstrap";

const privacyData = [
    {
        heading:"What Information Do We Collect?",
        body:<p>
        When you use our services or visit our Web site, you may provide us with two types of information: personal information you knowingly choose to disclose that is collected on an individual basis and Web site use information collected on an aggregate basis as you and others browse our Web site.
        </p>
    },
    {
        heading:"Personal Information You Choose to Provide",
        body:<>
            <p>
              <u>Registration Information</u><br/>
              You will provide us information about yourself when you register for an account with Lazybones (TM). During registration, you will be required to provide us with your first and last names, a valid e-mail address, your home address and billing information. However, we will request optional additional information which will be used to fill in fields that exist in your account profile. This information is held in confidence and will not be disclosed to any outside resource for any reason, except where such disclosure is required by law.
            </p>
            <br/>
            <p>
              <u>Email Information</u><br/>
              If you choose to correspond with us through email, we may retain the content of your email messages together with your email address and our responses for internal reference purposes only.
            </p>
        </>,
    },
    {
        heading:"Web Site Use Information",
        body:<p>
        Similar to other commercial Web sites, our Web site utilizes a standard technology called "cookies" (see explanation below, "What Are Cookies?") and Web server logs to collect information about how our Web site is used. Information gathered through cookies and Web server logs may include the date and time of visits, the pages viewed, time spent at our Web site, and non-personal actions taken while using our services. This information is collected for the sole purpose of improving our services and helping us serve you better. This information is not disclosed to any outside resource for any reason, except where such disclosure is required by law.
        </p>,
    },
    {
        heading:"How Do We Use the Information That You Provide to Us?",
        body:<p>
        Broadly speaking, we use personal information for the purposes of providing you with the tools necessary to properly use our services, administer technical support when it is requested, and provide you with a personalized experience while using our services. We will never sell or provide to any 3rd party your personal or non-personal information, except where such disclosure is required by law.
        </p>,
    },
    {
        heading:"What Are Cookies?",
        body:<p>
        A cookie is a very small text document, which often includes an anonymous unique identifier. When you visit a Web site, that site's computer asks your computer for permission to store this file in a part of your hard drive specifically designated for cookies. Each Web site can send its own cookie to your browser if your browser's preferences allow it, but (to protect your privacy) your browser only permits a Web site to access the cookies it has already sent to you, not the cookies sent to you by other sites.
        </p>,
    },
    {
        heading:"How Do We Use Information We Collect from Cookies?",
        body:<p>
        The information collected from cookies is used solely for session purposes in order to maintain a seamless experience throughout your use of our services.
        </p>,
    },
    {
        heading:"Sharing Information with Third Parties",
        body:<p>
        We do not disclose any of your personal information to any third parties for any purpose. All non-personal information collected by our services is used strictly for internal purposes to provide you with a better service environment, and personal information collected is never observed directly by anyone, including internal personnel, for any reason, unless express permission is granted by you, or where such disclosure is required by law.
        </p>,
    },
    {
        heading:"Notice of New Services and Changes",
        body:<p>
        Occasionally, we may also use the information we collect to notify you about important changes to our Web site, new services and special offers we think you will find valuable. As our customer, you will be given the opportunity to notify us of your desire not to receive these offers by sending us an email request at <a href="mailto:/wecare@mylazybones.com" target="_blank">wecare@mylazybones.com</a> or calling 1-877-215-2105.
        </p>,
    },
    {
        heading:"How Do We Protect Your Information?",
        body:<p>
        Proper measures have been taken to ensure a secure, encrypted and protected environment within our services, databases and interfaces accessed through our services.
        </p>,
    },
    {
        heading:"How Do We Secure Information Transmissions?",
        body:<p>
        Email is not recognized as a secure medium of communication. For this reason, we request that you do not send private information to us by email. All information transferred to our servers through our services will be transmitted securely via Secure Sockets Layer (SSL) encryption. Please contact <a href="mailto:/wecare@mylazybones.com" target="_blank">wecare@mylazybones.com</a> or call 1-877-215-2105 if you have any questions or concerns.
        </p>,
    },
    {
        heading:"How Can You Access and Correct Your Information?",
        body:<p>
        You have detailed access and the ability to edit the vast majority of your personal information through your account online, and you may also request access to all your personally identifiable information that we collect online and maintain in our database by emailing <a href="mailto:/wecare@mylazybones.com" target="_blank">wecare@mylazybones.com</a> or contacting us at 1-877-215-2105.
        </p>,
    },
    {
        heading:"Certain Disclosures",
        body:<p>
        We may disclose your personal information if required to do so by law or subpoena or if we believe that such action is necessary to (a) conform to the law or comply with legal process served on us; (b) protect and defend our rights and property, the Site, and the users of the Site; or (c) act under circumstances to protect the safety of users of the Site, or us.
        </p>,
    },
    {
        heading:"What About Other Web Sites Linked to Our Web Site?",
        body:<p>
        We are not responsible for the practices employed by Web sites linked to or from our Web site nor the information or content contained therein. Often links to other Web sites are provided solely as pointers to information on topics that may be useful to the users of our Web site. Please remember that when you use a link to go from our Web site to another Web site, our Privacy Policy is no longer in effect. Your browsing and interaction on any other Web site, including Web sites which have a link on our Web site, is subject to that Web site's own rules and policies. Please read over those rules and policies before proceeding carefully.
        </p>,
    },
    {
        heading:"Web Site Use Information",
        body:<p>
        <u>Your Consent</u><br/>
        By using our Web site, you consent to our collection and use of your personal information as described in this Privacy Policy. If we change our privacy policies and procedures, we will post those changes on our Web site to keep you aware of what information we collect, how we use it and under what circumstances we may disclose it.
        </p>,
    },
];

class Privacy extends Component{
   
    

    render(){

        const data = privacyData;
    
        let ctr = 1;
    
        return(
            <>
                <ShortNavbar {...this.props} />
                <GeneralPageHeader />   
                <div className="main">
                    <div className="section section-nude-gray"> 
                       <Container id="mainContentArea">
                            <Row className="title-row justify-content-between">
                                <Col xs="6" md="6">
                                <h3 className="shop">Privacy Policy</h3>
                                </Col>
                                
                            </Row>  
                            <Row>
                                <Col>
                                    <p>
                                        Our Privacy Policy is designed to assist you in understanding how we collect and use the personal information you provide to us and upload to our servers and to assist you in making informed decisions when using our site and our products and services.
                                    </p>
                                    {
                                        data.map(({heading,body})=>
                                            <Media key={ctr++}>
                                                <Media body>
                                                <Media heading>{heading}</Media>
                                                {body}
                                                </Media>
                                            </Media>
                                        )
                                    }
                                </Col>
                            </Row>
                        </Container>    
                    </div>
                </div>
                <FooterWhite />
            </>
        )
    }

}

export default Privacy;