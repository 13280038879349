import React,{useState,useEffect,Component} from "react";
import queryString from "query-string";
import Select from "react-select";
import moment from "moment";
import _ from "lodash";
import customToast from '../../../../Common/CustomToast'


import StudentService from "services/studentService";
import ResidentService from "services/residentialService";
import UserService from "services/userService";
import auth from "services/authService";


import MyAccountNavbar from "../../../../Navbars/MyAccountNavbar";

import ResidentialCustomerAccountLaundryDetail from "./ResidentialCustomerAccountLaundryDetail";
import ResidentialCustomerAccountSuppliesDetail from "./ResidentialCustomerAccountSuppliesDetail"

import AccountActivityView from "../Common/Financials/AccountActivity";

import CustomerEmailAndTextReminderModal from "../../../../Common/CustomerEmailAndTextReminderModal";
import ProcessingModal from "../../../../Common/ProcessingModal";

import FooterBlack from "../../../../Footers/FooterBlack.js";

// reactstrap components
import {
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    FormGroup, 
    Label,
    Modal, 
} from "reactstrap";


/*
We will be passing down to the student account laundry detail 
user primary info 
<Residential Customer Account:>
JWT token
- user id

Component did mount:
- get packages

------

<ResidentialCustomerAccountLaundryDetail
    no properties needed
/>
state:{
   userLaundryProperties:{
       
   }

}

Component did mount:
- we get all the information related to laundry and map it topush it to state
- If any update happens then we send it to server and update the state here itself    

updateLaundry{
   we send the info to database
   and update state

}



*/

class ResidentialCustomerAccount extends Component{
    viewOptions = [
        { value: "", label: " Select View", isDisabled: true },
        { value: "laundry", label: "Laundry Plan" },
        { value: "account-activity", label: "Billing Details" },
    ];

    viewOptionsWithSupplies = [
      { value: "", label: " Select View", isDisabled: true },
      { value: "laundry", label: "Laundry Plan" },
      { value: "supplies", label: "Supplies" },
      { value: "account-activity", label: "Billing Details" },
    ];

    listOfOneTimePickupDates = [moment(),
                                moment().add(7, 'days'),
                                moment().subtract(3, 'days'),
                                moment().add(1, 'month')];


    constructor(){
        super();
        this.state = {
            userNotifications:"",
            displayEmailAndTextReminderSettingModalFlag:false,
            displayWelcomeModal:false,
            displaySignupLaundryPlan:false,
            processingModalDisplayFlag:false,
            welcomeMessageFlag:false,
            alertDanger: true,
            defaultView:null,
            previousDefaultView:null,
            products:null, //{laundry:{...}, storage:{...}, supplies:{...}}
            user:null,
        }
    }

    async componentDidMount(){
      await this.mapModelToView();
      await this.getAccountNotifications();
    }


    async componentDidUpdate(prevProps){
      const {product} = this.props.match.params;
      const {product:prevProduct}   = prevProps.match.params;

      if(product != prevProduct){
          let previousDefaultView  = this.state.defaultView;
          let defaultView =  { value: "Laundry", label: "Laundry Plan" };
          if(product === "supplies" && this.isEligibleForSupplies()){
            defaultView =  { value: "Supplies", label: "Supplies" };
          }
          else if(product === "account-activity"){
            defaultView =  { value: "Billing", label: "Billing Details" };
          }

          //product if null then it needs find the default view
          if(!product){
              let processingModalDisplayFlag = true;
              this.setState({ processingModalDisplayFlag});
              const products = await ResidentService.getMyAccountProductList();
              if(!_.has(products,'laundry') && _.has(products,'supplies') && this.isEligibleForSupplies()){
                defaultView =  { value: "Supplies", label: "Supplies" };
              }
              const displaySignupLaundryPlan = (_.has(products,'laundry')) ? false:true;
            
              processingModalDisplayFlag = false;
              this.setState({
                defaultView,
                displaySignupLaundryPlan,
                previousDefaultView,
                processingModalDisplayFlag,
              });
          }
          else{
              this.setState({
                defaultView,
                previousDefaultView,
              });
          }
          
      }

    }

    async getAccountNotifications(){
      try{
        const {status,message,returnObject:notifications} = await UserService.getAccountNotifications();
        if(notifications !== "")
          this.setState({
            userNotifications:notifications
          })  
      } 
      catch(ex){

      }
    }

    async mapModelToView(){
        const {location} = this.props;
        const {accountType,product} = this.props.match.params;
        

        const queryStringParameters = queryString.parse(location.search);
        
        let welcomeMessageFlag = false;
        let displayWelcomeModal = false;
        let processingModalDisplayFlag = true;
        if(queryStringParameters.welcome === "true")
            welcomeMessageFlag = true;
        
        
        if(welcomeMessageFlag === true)  
            displayWelcomeModal = true; 
        
        let defaultView =  { value: "Laundry", label: "Laundry Plan" };
        if(product === "supplies" && this.isEligibleForSupplies()){
          defaultView =  { value: "Supplies", label: "Supplies" };
        }
        else if(product === "account-activity"){
          defaultView =  { value: "Billing", label: "Billing Details" };
        }  
        let previousDefaultView  = defaultView;
        this.setState({
            welcomeMessageFlag,
            displayWelcomeModal,
            defaultView,
            previousDefaultView,
            processingModalDisplayFlag,
        });

        try{
          
          const products = await ResidentService.getMyAccountProductList();
          const user = await auth.getUserRecord();
          let displaySignupLaundryPlan = false;
          //Default view is laundry but if laundy is null and there is storage or supplies then that becomes the default
          if(product === null || product === undefined){
            if(!_.has(products,'laundry') && _.has(products,'supplies') && this.isEligibleForSupplies()){
                defaultView =  { value: "Supplies", label: "Supplies" };
            } 
          }
          
          displaySignupLaundryPlan = (_.has(products,'laundry')) ? false:true;
          processingModalDisplayFlag = false;

          this.setState({
            displaySignupLaundryPlan,
            products,
            user,
            defaultView,
            processingModalDisplayFlag,
          });

          if(welcomeMessageFlag === true){
            this.props.history.replace(`/myaccount/${accountType}/${product}`); //  I want the welcome message modal to display only once 
          }
        }catch(ex){
          console.log(ex);
        }
        
    }

    



    handleCloseOfProcessingModalWindow = (flag)=>{
      const myAccountState = {...this.state}
      myAccountState.processingModalDisplayFlag = flag;
      this.setState(myAccountState);
    }

    handleOnClickOfAccountActivityButton = (e)=>{
      e.preventDefault();
      /*
      this.setState({
        defaultView: { value: "Billing", label: "Billing Details" },
      })
      */
      this.props.history.push("/myaccount/residential/account-activity")
    }

    handleOnClickBackButtonFromAccountActivityButton = ()=>{
      //this.setDefaultView(this.state.previousDefaultView);
      //return this.props.history.push("/myaccount/residential");
      return this.props.history.goBack();
    }

    handleSaveEmailAndTextReminderSetting = async (modalFormData)=>{
        try{
          //console.log(modalFormData);
          let myAccountState  = {...this.state};
          const {user} = this.state;
          
          //Display processing modal
          myAccountState.processingModalDisplayFlag = true;
          this.setState(myAccountState);

          //Send form data to server
          let formData = modalFormData;
          formData.user = user;
          //console.log(formData);
          
          const {status:processingStatus,message} = await UserService.saveCustomerEmailAndTextNotificationSetting(formData);
          
          const {processingModalDisplayFlag} = this.state;
          
          if(processingStatus === true){
            await this.updateUserAndProductProperties();
          }

          myAccountState  = {...this.state};
          myAccountState.processingModalDisplayFlag = !processingModalDisplayFlag;
          myAccountState.displayEmailAndTextReminderSettingModalFlag = false;
          this.setState(myAccountState);

          

          if(processingStatus === true)
              customToast.success(message); 
          else
              customToast.error(message); 
          
          

        }
        catch(ex){
            console.log(ex);
        }
    }

    isEligibleForSupplies = ()=>{
      const {supplies} = this.props.globalApp.shoppingCart;
      const {provideSuppliesToLoggedInUser} = supplies
      return (provideSuppliesToLoggedInUser === false) ? false:true;

    }

    setLaundryNextPickupDeliverySchedule = (nextPickupDeliveryScheduleObject)=>{
      const products = {...this.state.products};
      const laundry = {...products.laundry}
      laundry.nextPickupDeliverySchedule  = nextPickupDeliveryScheduleObject;
      products.laundry = laundry
      this.setState({products});
    }

    

    setAlertDanger = (flag)=>{
       const state = {...this.state}
       state.alertDanger = flag;
       this.setState(state)
    }

    setDefaultView = valueObject=>{
        const state = {...this.state}
        if(valueObject.value.toLowerCase() !== state.defaultView.value.toLowerCase()){
          state.defaultView = valueObject;
          this.setState(state)   

          const {value:product} = valueObject;
          return this.props.history.push(`/myaccount/residential/${product}`)
        }

    }

    setDisplayWelcomeModal = (flag)=>{
        const state = {...this.state}
        state.displayWelcomeModal = flag;
        this.setState(state)
    }

    setDisplayEmailAndTextReminderSettingModalFlag = (flag)=>{
      this.setState({
          displayEmailAndTextReminderSettingModalFlag:flag    
      })
    }

    updateUserAndProductProperties = async ()=>{
        try{
          const products = await ResidentService.getMyAccountProductList();
          const user = await auth.getUserRecord()
          //console.log(user);
          this.setState({
            products,
            user
          });
        }catch{

        }
    }

    

    render(){
        
        const {
          defaultView,
          displaySignupLaundryPlan,
          displayEmailAndTextReminderSettingModalFlag,
          displayWelcomeModal,
          processingModalDisplayFlag,
          products,
          user,
          userNotifications,
        } = this.state;

        let storageProperties = null;
        let laundryProperties = products && products.laundry && products.laundry;
        let suppliesProperties = products && products.supplies && products.supplies;

        const  isEligibleForSupplies = this.isEligibleForSupplies();
        //let {laundry,storage} = products
        
        
        const listOfOneTimePickupDates = this.listOfOneTimePickupDates;
        
        const displayViewBillingFlag  = (defaultView &&  defaultView.value === "Billing") ? true:false;
        const displayViewLaundryFlag = (defaultView &&  defaultView.value === "Laundry") ? true:false;
        const displayViewStorageFlag = (defaultView &&  defaultView.value === "Storage") ? true:false;
        const displayViewSuppliesFlag = (defaultView &&  defaultView.value === "Supplies") ? true:false;
        
        let viewOptions = this.viewOptionsWithSupplies;
        if(isEligibleForSupplies !== true){
          viewOptions = this.viewOptions;
        }

        const displayWelcomeModalLaundry = (displayViewLaundryFlag === true) ? displayWelcomeModal:false;
        const displayWelcomeModalStorage = (displayViewStorageFlag === true) ? displayWelcomeModal:false;
        const displayWelcomeModalSupplies = (displayViewSuppliesFlag === true) ? displayWelcomeModal:false;

        
        return(
            <>
                <MyAccountNavbar {...this.props} />
                <div className="main">
                    <div className="section section-gray">
                        <Container>
                            <Row>
                                <Col xs="12" md="8" className="mt-2 ">
                                  <h3>My Account:</h3>
                                </Col>
                                <Col xs="12" md="1" className="mt-4 d-none d-sm-block ">
                                  <p className="mt-2 text-left text-md-right"><strong>View:</strong></p>
                                </Col>
                                <Col  xs="12" md="3" className="mt-4  text-left text-md-right">
                                     
                                      <FormGroup>
                                        
                                        <Select
                                            className="react-select react-select-default text-left"
                                            classNamePrefix="react-select"
                                            name="defaultSelect"
                                            value={defaultView}
                                            onChange={value => this.setDefaultView(value)}
                                            options={viewOptions}
                                            placeholder="Select View"
                                        />
                                    </FormGroup> 
                                </Col>
                            </Row>
                            
                            <Row className="title-body-row mt-1">
                                <Col md="12">
                                        {
                                            displayViewBillingFlag &&
                                            <Card>
                                              <CardBody>
                                                <AccountActivityView 
                                                    onClickOfBackButton={this.handleOnClickBackButtonFromAccountActivityButton}
                                                    {...this.props}
                                                />
                                              </CardBody>
                                            </Card>
                                        }

                                        <ResidentialCustomerAccountLaundryDetail
                                          displayFlag={displayViewLaundryFlag}
                                          displaySignupLaundryPlan={displaySignupLaundryPlan}
                                          displayWelcomeModal={displayWelcomeModalLaundry}
                                          listOfOneTimePickupDates={listOfOneTimePickupDates}
                                          laundry={laundryProperties}
                                          isEligibleForSupplies = {isEligibleForSupplies}
                                          user={user}
                                          handleSettingLaundryNextPickupDeliverySchedule = {this.setLaundryNextPickupDeliverySchedule}
                                          handleUpdateOfUserAndProductProperties = {this.updateUserAndProductProperties}
                                          handleOnClickOfEmailAndTextReminderLink = {this.setDisplayEmailAndTextReminderSettingModalFlag}
                                          handleOnClickOfAccountActivityButton = {this.handleOnClickOfAccountActivityButton}
                                          handleOnCloseOfWelcomeModal = {this.setDisplayWelcomeModal}
                                          userNotifications={userNotifications}
                                          {...this.props}
                                        />

                                        <ResidentialCustomerAccountSuppliesDetail
                                          displayFlag={displayViewSuppliesFlag}
                                          displayWelcomeModal={displayWelcomeModalSupplies}
                                          handleOnCloseOfWelcomeModal = {this.setDisplayWelcomeModal}
                                          supplies={suppliesProperties}
                                          user={user}
                                          userNotifications={userNotifications}
                                          {...this.props}
                                        />      
                                </Col>
                            </Row> 
                        </Container>
                    </div>
                </div>
                <FooterBlack />

                {/* Make special requests for Upcoming pickup */}
                <CustomerEmailAndTextReminderModal
                    modalDisplayFlag = {displayEmailAndTextReminderSettingModalFlag}
                    handleModalDisplayFlag = {this.setDisplayEmailAndTextReminderSettingModalFlag}
                    handleSaveOperation = {this.handleSaveEmailAndTextReminderSetting}
                    user={user}
                />

                {/* Set Processing modal */}
                <ProcessingModal
                    displayModal={processingModalDisplayFlag}
                    handleCloseOfModalWindow={this.handleCloseOfProcessingModalWindow}
                />
                {/* End of Processing modal */}

    
                
            </>
       );    
    }
}


  

export default ResidentialCustomerAccount;
