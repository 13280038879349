import React from "react";

// reactstrap components
import {
    Card,
    CardBody,
} from "reactstrap";

const StudentLaundryRegistrationStepsDisplayWidget = ()=>{
    return(
        <Card className="card-pricing d-none">
            <CardBody>
            <h6 className="card-category text-left">Registration Steps</h6>
            
            <ul className="text-left">
                <li>
                <i aria-hidden={true} className="fa fa-circle-o text-success"></i>
                Select School
                </li>
                <li>
                <i aria-hidden={true} className="fa fa-circle-o text-success"></i>
                Student Information
                </li>
                <li>
                <i aria-hidden={true} className="fa fa-circle-o text-success"></i>
                Semester and Pricing
                </li>
                <li>
                <i aria-hidden={true} className="fa fa-circle-o text-success"></i>
                Login and Payment information
                </li>
                <li>
                <i aria-hidden={true} className="fa fa-circle-o text-success"></i>
                Review
                </li>
            </ul>
            
            </CardBody>
        </Card>
    );
}

export default StudentLaundryRegistrationStepsDisplayWidget;