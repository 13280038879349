import React from "react";
import Select from "react-select";
import _ from "lodash";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Media,
    NavItem,
    NavLink,
    Nav,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    TabContent,
    TabPane,
    Container,
    Row,
    Col
  } from "reactstrap";
  
  import HowItWorks from "../Common/HowItWorks";
  import HowItWorksCommercial from "../Common/HowItWorksCommercial";
  import StudentPricing from "../Common/StudentPricing";
  import ResidentialPricing from "../Common/ResidentialPricing";
  import Faq from "../Common/FaqMobile";


  const MobileView = (props)=>{
    let userType,action,actionType;
    ([userType,action] = ["student","how-it-works"]);

    const {contentDropdown,faqDropdown,match} = props;

    if(!_.isEmpty(match.params )){
        ({userType,action:actionType} = match.params);
        action  = (actionType !== undefined) ? actionType:action;
    }
    
    const [contentDisplayArea,setContentDisplayArea] = React.useState(action);
    const [faqDisplayArea,setFaqDisplayArea] = React.useState("laundry-care");
    
    /*THIS IS WHERE I UDPATE THE COMPONENT */
    if(contentDisplayArea !== action){
        setContentDisplayArea(action)
    }

    const contentDisplayAreaSelectionValue = _.find(contentDropdown,{'value':action});

    
    const handleContentDisplayArea = (viewObject)=>{
        //const selectedValue = dropDownSelection.value;
        //setContentDisplayArea(selectedValue);
        return props.history.push(`/laundry/how-it-works/${userType}/${viewObject.value}`)
    }

    const handleFaqDisplayArea = (dropDownSelection)=>{
        const selectedValue = dropDownSelection.value;
        setFaqDisplayArea(selectedValue);
    }

    //console.log(contentDisplayArea);
    
    

    const howItWorksDisplayFlag = (contentDisplayArea === "how-it-works") ? true: false;
    const pricingDisplayFlag = (contentDisplayArea === "pricing") ? true: false;
    const faqDisplayFlag = (contentDisplayArea === "faq") ? true: false;
    
    
    const howItWorksActiveCss = (contentDisplayArea === "how-it-works") ? "d-block": "d-none";
    const pricingActiveCss = (contentDisplayArea === "pricing") ? "d-block": "d-none";
    const faqActiveCss = (contentDisplayArea === "faq") ? "d-block": "d-none";
    
    const faqDisplayAreaSelectionValue = _.find(faqDropdown,{'value':faqDisplayArea});
    const laundryCareActiveCss = (faqDisplayArea === "laundry-care") ? "d-block": "d-none";
    const plansActiveCss = (faqDisplayArea === "plans") ? "d-block": "d-none";
    const pricingFaqActiveCss = (faqDisplayArea === "pricing-faq") ? "d-block": "d-none";
    const pickupDeliveryActiveCss = (faqDisplayArea === "pickup-delivery") ? "d-block": "d-none";
    const dryCleaningActiveCss = (faqDisplayArea === "dry-cleaning") ? "d-block": "d-none";
    
    const isStudent = (userType === "student") ? true:false;
    const isResidential = (userType === "residential") ? true:false;
    const isCommercial = (userType === "commercial") ? true:false;

    
    return(
         <>
            <Row>
                <Col md="12" sm="12">
                    
                    <Row>
                    <Col xs="12" sm="12" className="d-none">
                        <FormGroup>
                            <Select
                                className="react-select react-select-default"
                                classNamePrefix="react-select"
                                name="defaultSelect"
                                value={contentDisplayAreaSelectionValue}
                                onChange={value => handleContentDisplayArea(value)}
                                options={contentDropdown}
                                placeholder=""
                            />
                        </FormGroup>
                    </Col>

                    <Col md="10" sm="12" xs="12" className="d-block d-md-none">
                        
                        {
                            (howItWorksDisplayFlag && isCommercial === true) &&  
                            <HowItWorksCommercial />
                        }
                        
                        {
                            (howItWorksDisplayFlag && !isCommercial) &&  
                            <HowItWorks 
                                isStudent={isStudent}
                                isResidential={isResidential}
                            />
                        }

                        {
                            pricingDisplayFlag && isStudent && 
                            <StudentPricing 
                                {...props}
                            />
                        }

                        {
                            pricingDisplayFlag && isResidential && 
                            <ResidentialPricing 
                                {...props}
                            />
                        }

                        {
                            faqDisplayFlag && 
                            <Faq
                                {...props}
                                isStudent={isStudent}
                                isResidential={isResidential}
                            />
                        }
                    </Col>

                    </Row>
                </Col>
            </Row>
        </>
    );



}

export default MobileView;