import React,{useState,useEffect,Component} from "react";
import queryString from "query-string";
import Select from "react-select";
import moment from "moment";
import _ from "lodash";
import customToast from '../../../../Common/CustomToast'

import SchoolService   from "../../../../../services/schoolService";
import StudentService  from "../../../../../services/studentService";
import UserService     from "../../../../../services/userService";
import SuppliesService from "../../../../../services/suppliesService";
import auth            from "../../../../../services/authService";
import LaundryService  from "../../../../../services/laundryService";



import MyAccountNavbar from "../../../../Navbars/MyAccountNavbar";
import StudentAccountLaundryDetail from "./StudentAccountLaundryDetail";
import StudentAccountStorageDetail from "./StudentAccountStorageDetail";
import StudentAccountSuppliesDetail from "./StudentAccountSuppliesDetail"

import AccountActivityView from "../Common/Financials/AccountActivity";

import CustomerEmailAndTextReminderModal from "../../../../Common/CustomerEmailAndTextReminderModal";
import ProcessingModal from "../../../../Common/ProcessingModal";


import FooterBlack from "../../../../Footers/FooterBlack.js";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    Container,
    Row,
    Col,
    FormGroup, 
    Modal, 
} from "reactstrap";


/*
We will be passing down to the student account laundry detail 
user primary info 
<Student Account:>
JWT token
- user id

Component did mount:
- get packages

------

<StudentAccountLaundryDetail
    no properties needed
/>
state:{
   userLaundryProperties:{
       
   }

}

Component did mount:
- we get all the information related to laundry and map it topush it to state
- If any update happens then we send it to server and update the state here itself    

updateLaundry{
   we send the info to database
   and update state

}

--------

<StudentAccountStorageDetail
    no properties needed
/>
state:{
   userLaundryProperties:{
       
   }

}

Component did mount:
- we get all the information related to laundry and map it topush it to state
- If any update happens then we send it to server and update the state here itself    

updateLaundry{
   we send the info to database
   and update state

}

*/

class StudentAccount extends Component{
    /*
    viewOptions = [
        { value: "", label: " Select View", isDisabled: true },
        { value: "Laundry", label: "Laundry Plan" },
        { value: "Storage", label: "Storage Plan" },
        { value: "Supplies", label: "Supplies" },
        { value: "Billing", label: "Billing Details" },
    ];
    */

    viewOptions = [
      { value: "", label: " Select View", isDisabled: true },
      { value: "laundry", label: "Laundry Service" },
      { value: "storage", label: "Storage Service" },
      { value: "supplies", label: "Supplies" },
      { value: "account-activity", label: "Billing Details" },
    ];

    listOfOneTimePickupDates = [moment(),
                                moment().add(7, 'days'),
                                moment().subtract(3, 'days'),
                                moment().add(1, 'month')];

    
    /*
    previousDefaultView: Using this to track previous view anytime you switch views for the go back buttons
    */
    constructor(){
        super();
        this.state = {
            accountCurrentBalance:0,
            userNotifications:"",
            listOfSchoolsByRegionForLaundry:"",
            listOfSchoolsByRegionForStorage:"",
            displayRenewLaundryPlan:"",
            displayRenewStoragePlan:"",
            displayEmailAndTextReminderSettingModalFlag:false,
            displayLoadingModal:false,
            displayWelcomeModal:false,
            processingModalDisplayFlag:false,
            welcomeMessageFlag:false,
            alertDanger: true,
            defaultView:null,
            previousDefaultView:null, 
            products:null, //{laundry:{...}, storage:{...}, supplies:{...}}
            user:null,
        }
    }

    /*
      Need to set students laundry and storage properties here
    */
    
    async componentDidMount(){
        await this.mapModelToView();
        await this.getAccountNotifications();
        this.getAccountBalance()
    }

    /*
       We are doing a component update if the url changes
    */
    async componentDidUpdate(prevProps){
      const {product} = this.props.match.params;
      const {product:prevProduct}   = prevProps.match.params;

      if(product != prevProduct){
          let previousDefaultView  = this.state.defaultView; 
          let defaultView =  { value: "Laundry", label: "Laundry Service" };
          if(product === "storage")
            defaultView =  { value: "Storage", label: "Storage Service" };
          else if(product === "supplies"){
            defaultView =  { value: "Supplies", label: "Supplies" };
          } 
          else if(product === "account-activity"){
            defaultView =  { value: "Billing", label: "Billing Details" };
          } 

          //product if null then it needs find the default view
          if(!product){
              let displayLoadingModal = true;
              this.setState({ displayLoadingModal});
              const products = await StudentService.getMyAccountStudentProductList();
              if(!_.has(products,'laundry') && (_.has(products,'storage') || _.has(products,'supplies'))){
                if(_.has(products,'storage')){
                  defaultView =  { value: "Storage", label: "Storage Service" };
                }
                else if(_.has(products,'supplies')){
                  defaultView =  { value: "Supplies", label: "Supplies" };
                }
              }
              displayLoadingModal = false;
              this.setState({
                defaultView,
                previousDefaultView,
                displayLoadingModal,
              });
          }
          else{
              this.setState({
                defaultView,
                previousDefaultView,
              });
          }
          
      }

    }

    async getAccountBalance(){
      try{
        const {currentBalance:accountCurrentBalance} =  await UserService.getAccountCurrentBalance()
        this.setState({accountCurrentBalance})
      }
      catch(ex){  
        console.log(ex)
      }
    }

    async getAccountNotifications(){
      try{
        const {status,message,returnObject:notifications} = await UserService.getAccountNotifications();
        if(notifications !== "")
          this.setState({
            userNotifications:notifications
          })  
      } 
      catch(ex){

      }
    }


    async mapModelToView(){
        const {location} = this.props;
        const {accountType,product} = this.props.match.params;
        //console.log(product);
        const queryStringParameters = queryString.parse(location.search);
        
        
        let welcomeMessageFlag = false;
        let [displayWelcomeModal,displayLoadingModal] = [false,false];
        if(queryStringParameters.welcome === "true")
            welcomeMessageFlag = true;
        
        if(welcomeMessageFlag === true)  
            displayWelcomeModal = true; 

        if(displayWelcomeModal != true){
          displayLoadingModal = true;
        }    
        
        let defaultView =  { value: "Laundry", label: "Laundry Service" };
        if(product === "storage")
           defaultView =  { value: "Storage", label: "Storage Service" };
        else if(product === "supplies"){
           defaultView =  { value: "Supplies", label: "Supplies" };
        } 
        else if(product === "account-activity"){
          defaultView =  { value: "Billing", label: "Billing Details" };
        } 
        let previousDefaultView  = defaultView;
        
        this.setState({
            welcomeMessageFlag,
            displayLoadingModal,
            displayWelcomeModal,
            defaultView,
            previousDefaultView,
        });

        try{
          const products = await StudentService.getMyAccountStudentProductList();
          //const products = null;
          const user = await auth.getUserRecord();
          let { listOfSchoolsByRegionForLaundry, listOfSchoolsByRegionForStorage } = this.state;

          if(!listOfSchoolsByRegionForLaundry){
              listOfSchoolsByRegionForLaundry = await SchoolService.getListOfSchoolsByRegionForLaundry();
          }

          if(!listOfSchoolsByRegionForStorage){
            listOfSchoolsByRegionForStorage = await SchoolService.getListOfSchoolsByRegionForStorage();
          }

          //Default view is laundry but if laundy is null and there is storage or supplies then that becomes the default
          if(product === null || product === undefined){
            if(!_.has(products,'laundry') && (_.has(products,'storage') || _.has(products,'supplies'))){
              if(_.has(products,'storage')){
                defaultView =  { value: "Storage", label: "Storage Service" };
              }
              else if(_.has(products,'supplies')){
                defaultView =  { value: "Supplies", label: "Supplies" };
              }
            }
          }

          this.setState({
            listOfSchoolsByRegionForLaundry,
            listOfSchoolsByRegionForStorage,
            products,
            user,
            defaultView
          });

          const displayRenewLaundryPlan = await this.getRegistrationForUpcomingLaundrySeason();
          const displayRenewStoragePlan = await this.getRegistrationForUpcomingStorageSeason();
          
          displayLoadingModal = false;
          this.setState({
            displayLoadingModal,
            displayRenewLaundryPlan,
            displayRenewStoragePlan,
            });
          
          if(welcomeMessageFlag === true){
            this.props.history.replace(`/myaccount/${accountType}/${product}`); //  I want the welcome message modal to display only once 
          }
        }catch(ex){

        }
    }

    getDisplayConvertFromByThePoundToSemesterPlan = ()=>{
      let convertToSemesterPlan = false;

      const {products} = this.state;
      if(products && products.laundry){
        const {laundryPlanType} = products.laundry
        convertToSemesterPlan = (laundryPlanType === LaundryService.BY_THE_POUND) ? true:false;
      }

      return convertToSemesterPlan
    }
    
    /*
      - This will return storage semester object for upcoming season
    */
    getRegistrationForUpcomingStorageSeason = async ()=>{
      let upcomingSeasonDetails = false;
      const {listOfSchoolsByRegionForStorage, user} = this.state
      if(!_.isEmpty(listOfSchoolsByRegionForStorage) && !_.isEmpty(user)){
        const {campus_id:campusId}  = user;
        let region = _.find(listOfSchoolsByRegionForStorage, {schools: [{id: campusId}] });
        
        if(!_.isEmpty(region)){
          
          let school = _.find(region.schools, {'id': campusId });
          //console.log(school);
          
          const { semesters:semesterList } = school;
          let renderSemestersAvailable = true;
          renderSemestersAvailable = (semesterList.length > 0) ? true:false;
          
          //Check if the whole list of Semesters has already expired
          if(renderSemestersAvailable){
              const upcomingSemesterForSignup = _.find(semesterList, (s)=>{
                  const acceptStorage = s.acceptingStorage
                  const startDateSellingSeason = moment(s.startDateForDisplayingOnWeb);
                  const endDateSellingSeason = moment(s.endDateForDisplayingOnWeb);
                  const now = moment();

                  return (
                    acceptStorage && 
                    now.isSameOrAfter(startDateSellingSeason) && 
                    now.isSameOrBefore(endDateSellingSeason)
                  );
              });

              //Check is user has purchased the storage product
              let hasStudentSignedUpForTheUpcomingSemester = false;
              if(!_.isEmpty(upcomingSemesterForSignup)){
                const {status} = await StudentService.purchasedStoragePlanForSemester({semesterId:upcomingSemesterForSignup.id})
                hasStudentSignedUpForTheUpcomingSemester = status;
              }

              upcomingSeasonDetails = (!_.isEmpty(upcomingSemesterForSignup) && hasStudentSignedUpForTheUpcomingSemester === false) ? upcomingSemesterForSignup:false;
          }
          
          

        }
        
      }

      return upcomingSeasonDetails;
    }

    /* This will return laundry semester object for upcoming season */
    getRegistrationForUpcomingLaundrySeason = async ()=>{
        let upcomingSeasonDetails  = false;
        const {listOfSchoolsByRegionForLaundry,products,user} = this.state;
        
        if(!_.isEmpty(listOfSchoolsByRegionForLaundry) && !_.isEmpty(user)){
          //Get the current laundry product if already signed up for one
          const currentSignedUpLaundryProductSemester = (products && products.laundry && products.laundry.semester) ?  products.laundry.semester : null;
          
          const {campus_id:campusId}  = user;
          let region = _.find(listOfSchoolsByRegionForLaundry, {schools: [{id: campusId}] });
          
          if(!_.isEmpty(region)){
            let school = _.find(region.schools, {'id': campusId });
            //console.log(school);
            
            const { semesters:semesterList } = school;
            let renderSemestersAvailable = true;
            renderSemestersAvailable = (semesterList.length > 0) ? true:false;
            
            //Check if the whole list of Semesters has already expired
            if(renderSemestersAvailable){
                /*
                   We want to display the renew button:
                   Student who has other services but no laundry:
                      - Display the ongoing semester

                   Student has an ongoing semester and we have activated next semester
                      - Display the next semster 

                   Student has signed up for next semester then we don't display the current ongoing semester
                */

                
                //If the student has ongoing laundry: THis will get the upcoming/ongoing semesters which the student has not signed up for
                //If the student has no laundry product then it will give the ongoing semester
                const upcomingSemesterForSignup = _.find(semesterList,s=>{
                  if(currentSignedUpLaundryProductSemester){
                    
                    if(s.id !== currentSignedUpLaundryProductSemester.semester_id && moment().isSameOrBefore(moment(s.endDateDb)))
                      return true;
                    else
                      return false;  
                  }
                  else
                   return moment().isSameOrBefore(moment(s.endDateDb))
                }) 
                //console.log(upcomingSemesterForSignup);

                //Check if the user has a current product and if the product has for the next semester
                if(currentSignedUpLaundryProductSemester && !_.isEmpty(upcomingSemesterForSignup)){
                  const isCurrentSignedUpLaundryProductSemesterInFuture = moment(currentSignedUpLaundryProductSemester.start_date).isAfter(
                    moment(upcomingSemesterForSignup.endDateDb),'days'
                  );
                  
                  //
                    //If we have 2 next semester available for sign up and the user has signed up 
                    //for the next semester then don't display the renew button
                  //
                  if(isCurrentSignedUpLaundryProductSemesterInFuture === true)
                     return upcomingSeasonDetails
                }
                

                
                //Check is user has purchased the laundry product
                let hasStudentSignedUpForTheUpcomingSemester = false;
                if(!_.isEmpty(upcomingSemesterForSignup)){
                  const {status} = await StudentService.purchasedLaundryPlanForSemester({semesterId:upcomingSemesterForSignup.id})
                  hasStudentSignedUpForTheUpcomingSemester = status;
                }

                upcomingSeasonDetails = (!_.isEmpty(upcomingSemesterForSignup) && hasStudentSignedUpForTheUpcomingSemester === false) ? upcomingSemesterForSignup:false;
            }
          }
          
        }
        return upcomingSeasonDetails;
    }

    handleCloseOfProcessingModalWindow = (flag)=>{
        const myAccountState = {...this.state}
        myAccountState.processingModalDisplayFlag = flag;
        this.setState(myAccountState);
    }

    handleOnClickOfAccountActivityButton = (e)=>{
      e.preventDefault();
      /*
      this.setState({
        previousDefaultView:this.state.defaultView,
        defaultView: { value: "Billing", label: "Billing Details" },
      })
      */
      this.props.history.push("/myaccount/student/account-activity")
    }

    handleOnClickBackButtonFromAccountActivityButton = ()=>{
      //this.setDefaultView(this.state.previousDefaultView);
      return this.props.history.goBack();
    }

    handleSaveEmailAndTextReminderSetting = async (modalFormData)=>{
        try{
          //console.log(modalFormData);
          let myAccountState  = {...this.state};
          const {user} = this.state;
          
          //Display processing modal
          myAccountState.processingModalDisplayFlag = true;
          this.setState(myAccountState);

          //Send form data to server
          let formData = modalFormData;
          formData.user = user;
          //console.log(formData);
          
          const {status:processingStatus,message} = await UserService.saveCustomerEmailAndTextNotificationSetting(formData);
          if(processingStatus === false){
            customToast.error(message);
          }

          const {processingModalDisplayFlag} = this.state;
          
          if(processingStatus === true){
            await this.updateUserAndProductProperties();
          }

          myAccountState  = {...this.state};
          myAccountState.processingModalDisplayFlag = !processingModalDisplayFlag;
          myAccountState.displayEmailAndTextReminderSettingModalFlag = false;
          this.setState(myAccountState);

          

          if(processingStatus === true)
              customToast.success(message); 
          
          
          

        }
        catch(ex){
            console.log(ex);
        }
    }

    /*
        Redirect user to /existing-user/signup/laundry/student/{schoolId}
    */
    handleReserveStorageButton = async (e)=>{
        e.preventDefault();
        const { user } = this.state;
        
        if(!_.isEmpty(user)){
          const {id:semesterId} = await this.getRegistrationForUpcomingStorageSeason();
          const {campus_id:schoolId} = user;
          this.props.history.push(`/existing-user/signup/student/storage/${schoolId}/${semesterId}/select-plan`);  
          return;
        }
        
    }

    handleSignupLaundryButton = (e)=>{
      e.preventDefault();
      const { user } = this.state;
      
      if(!_.isEmpty(user)){
        const {id:semesterId} = this.getRegistrationForUpcomingLaundrySeason();
        const {campus_id:schoolId} = user;
        this.props.history.push(`/existing-user/signup/student/laundry/${schoolId}/${semesterId}/select-plan`);  
        return;
      }
      
    }

    
    setLaundryNextPickupDeliverySchedule = (nextPickupDeliveryScheduleObject)=>{
      const products = {...this.state.products};
      const laundry = {...products.laundry}
      laundry.nextPickupDeliverySchedule  = nextPickupDeliveryScheduleObject;
      products.laundry = laundry
      this.setState({products});
    }

    setStorageNextPickupDeliverySchedule = (nextPickupDeliveryScheduleObject)=>{
      const products = {...this.state.products};
      const storage = {...products.storage}
      storage.nextPickupDeliverySchedule  = nextPickupDeliveryScheduleObject;
      products.storage = storage
      this.setState({products});
    }

    setAlertDanger = (flag)=>{
       const state = {...this.state}
       state.alertDanger = flag;
       this.setState(state)
    }

    setDefaultView = valueObject=>{
        
        const state = {...this.state}
        if(valueObject.value.toLowerCase() !== state.defaultView.value.toLowerCase()){
          state.previousDefaultView = state.defaultView;
          state.defaultView = valueObject;
          this.setState(state)   
          
          const {value:product} = valueObject;
          return this.props.history.push(`/myaccount/student/${product}`)
        }
    }

    setDisplayWelcomeModal = (flag)=>{
        const state = {...this.state}
        state.displayWelcomeModal = flag;
        this.setState(state)
    }

    setDisplayEmailAndTextReminderSettingModalFlag = (flag)=>{
      this.setState({
          displayEmailAndTextReminderSettingModalFlag:flag    
      })
    }

    updateUserAndProductProperties = async ()=>{
        try{
          const products = await StudentService.getMyAccountStudentProductList();
          const user = await auth.getUserRecord()
          //console.log(user);
          this.setState({
            products,
            user
          });
        }catch{

        }
    }

    

    render(){
        const {
          accountCurrentBalance,
          defaultView,
          displayEmailAndTextReminderSettingModalFlag,
          displayRenewLaundryPlan,
          displayRenewStoragePlan,
          displayLoadingModal,
          displayWelcomeModal,
          listOfSchoolsByRegionForLaundry, 
          listOfSchoolsByRegionForStorage,
          processingModalDisplayFlag,
          products,
          user,
          userNotifications,
        } = this.state;

        let laundryProperties = products && products.laundry && products.laundry;
        let storageProperties = products && products.storage && products.storage;
        let suppliesProperties = products && products.supplies && products.supplies;
        
        //let {laundry,storage} = products
        
        //console.log('products',products);
        //console.log('displayLoadingModal',displayLoadingModal);
        
        const listOfOneTimePickupDates = this.listOfOneTimePickupDates;
        
        const displayViewBillingFlag  = (defaultView &&  defaultView.value === "Billing") ? true:false;
        const displayViewLaundryFlag = (defaultView &&  defaultView.value === "Laundry") ? true:false;
        const displayViewStorageFlag = (defaultView &&  defaultView.value === "Storage") ? true:false;
        const displayViewSuppliesFlag = (defaultView &&  defaultView.value === "Supplies") ? true:false;
        
        const displayWelcomeModalLaundry = (displayViewLaundryFlag === true) ? displayWelcomeModal:false;
        const displayWelcomeModalStorage = (displayViewStorageFlag === true) ? displayWelcomeModal:false;
        const displayWelcomeModalSupplies = (displayViewSuppliesFlag === true) ? displayWelcomeModal:false;
        
        const displayConvertFromByThePoundToSemesterPlan = this.getDisplayConvertFromByThePoundToSemesterPlan();

        return(
            <>
                <MyAccountNavbar 
                  {...this.props} 
                />
                <div className="main">
                    <div id="sectionArea" className="section section-gray">
                        <Container>
                            <Row className="title-row">
                                <Col xs="4" sm="4" md="4" lg="3" className="d-none d-md-block">
                                <h4>My Account:</h4>
                                </Col>
                                <Col className="text-md-right" xs="3" sm="4" md="4" lg="6">
                                   <p className="mt-4 fw-strong">View:</p>
                                </Col>
                                <Col className="mt-3" xs="8" sm="4" md="4" lg="3">
                                    
                                    <FormGroup>
                                        <Select
                                            className="react-select react-select-default"
                                            classNamePrefix="react-select"
                                            name="defaultSelect"
                                            value={defaultView}
                                            onChange={value => this.setDefaultView(value)}
                                            options={this.viewOptions}
                                            placeholder="Select View"
                                        />
                                    </FormGroup>
                                </Col>
                                
                            </Row>
                            <Row className="title-body-row mt-1">
                                <Col md="12">
                                    {
                                        displayViewBillingFlag &&
                                        <Card>
                                          <CardBody>
                                            <AccountActivityView 
                                                onClickOfBackButton={this.handleOnClickBackButtonFromAccountActivityButton}
                                                {...this.props}
                                            />
                                          </CardBody>
                                        </Card>
                                    }
                                    
                                    {
                                       <StudentAccountLaundryDetail
                                          displayFlag={displayViewLaundryFlag}
                                          displayRenewLaundryPlan={displayRenewLaundryPlan}
                                          displayRenewStoragePlan={displayRenewStoragePlan}
                                          displayConvertFromByThePoundToSemesterPlan = {displayConvertFromByThePoundToSemesterPlan}
                                          displayWelcomeModal={displayWelcomeModalLaundry}
                                          listOfOneTimePickupDates={listOfOneTimePickupDates}
                                          laundry={laundryProperties}
                                          user={user}
                                          getRegistrationForUpcomingLaundrySeason = {this.getRegistrationForUpcomingLaundrySeason}
                                          getRegistrationForUpcomingStorageSeason = {this.getRegistrationForUpcomingStorageSeason}
                                          handleSettingLaundryNextPickupDeliverySchedule = {this.setLaundryNextPickupDeliverySchedule}
                                          handleUpdateOfUserAndProductProperties = {this.updateUserAndProductProperties}
                                          handleOnClickOfEmailAndTextReminderLink = {this.setDisplayEmailAndTextReminderSettingModalFlag}
                                          handleOnClickOfReserveStorageButton = {this.handleReserveStorageButton}
                                          handleOnClickOfSignupLaundryButton = {this.handleSignupLaundryButton}
                                          handleOnClickOfAccountActivityButton = {this.handleOnClickOfAccountActivityButton}
                                          handleOnCloseOfWelcomeModal = {this.setDisplayWelcomeModal}
                                          userNotifications={userNotifications}
                                          
                                          {...this.props}
                                        />
                                    }
                                    {
                                       <StudentAccountStorageDetail
                                            accountCurrentBalance={accountCurrentBalance}
                                            displayFlag={displayViewStorageFlag}
                                            displayRenewLaundryPlan={displayRenewLaundryPlan}
                                            displayRenewStoragePlan={displayRenewStoragePlan}
                                            displayWelcomeModal={displayWelcomeModalStorage}
                                            storage = {storageProperties}
                                            user={user}
                                            getRegistrationForUpcomingLaundrySeason = {this.getRegistrationForUpcomingLaundrySeason}
                                            getRegistrationForUpcomingStorageSeason = {this.getRegistrationForUpcomingStorageSeason}
                                            handleUpdateOfUserAndProductProperties = {this.updateUserAndProductProperties}
                                            handleSettingNextPickupDeliverySchedule = {this.setStorageNextPickupDeliverySchedule}
                                            handleOnClickOfReserveStorageButton = {this.handleReserveStorageButton}
                                            handleOnClickOfSignupLaundryButton = {this.handleSignupLaundryButton}
                                            handleOnClickOfAccountActivityButton = {this.handleOnClickOfAccountActivityButton}
                                            handleOnCloseOfWelcomeModal = {this.setDisplayWelcomeModal}
                                            listOfSchoolsByRegionForStorage = {listOfSchoolsByRegionForStorage}
                                            userNotifications={userNotifications}
                                            {...this.props}
                                        />
                                    }
                                    {
                                       <StudentAccountSuppliesDetail
                                          displayFlag={displayViewSuppliesFlag}
                                          displayRenewLaundryPlan={displayRenewLaundryPlan}
                                          displayRenewStoragePlan={displayRenewStoragePlan}
                                          displayWelcomeModal={displayWelcomeModalSupplies}
                                          supplies={suppliesProperties}
                                          user={user}
                                          getRegistrationForUpcomingLaundrySeason = {this.getRegistrationForUpcomingLaundrySeason}
                                          getRegistrationForUpcomingStorageSeason = {this.getRegistrationForUpcomingStorageSeason}
                                          handleOnClickOfReserveStorageButton = {this.handleReserveStorageButton}
                                          handleOnClickOfSignupLaundryButton = {this.handleSignupLaundryButton}
                                          handleOnCloseOfWelcomeModal = {this.setDisplayWelcomeModal}
                                          userNotifications={userNotifications}
                                          {...this.props}
                                        />
                                    }   
                                        
                                        
                                </Col>
                            </Row> 
                        </Container>
                    </div>
                </div>
                <FooterBlack />

                {/* Make special requests for Upcoming pickup */}
                <CustomerEmailAndTextReminderModal
                    modalDisplayFlag = {displayEmailAndTextReminderSettingModalFlag}
                    handleModalDisplayFlag = {this.setDisplayEmailAndTextReminderSettingModalFlag}
                    handleSaveOperation = {this.handleSaveEmailAndTextReminderSetting}
                    user={user}
                />

                {/* Set Processing modal */}
                <ProcessingModal
                    displayModal={processingModalDisplayFlag}
                    handleCloseOfModalWindow={this.handleCloseOfProcessingModalWindow}
                />
                {/* End of Processing modal */}

    
                {
                  displayLoadingModal &&
                  <Modal isOpen={displayLoadingModal} toggle={() => this.setDisplayWelcomeModal(false)}>
                    <div className="modal-header no-border-header">
                      <button
                        className="close"
                        type="button"
                        onClick={() => this.setDisplayWelcomeModal(false)}
                      >
                        ×
                      </button>
                      <h4 className="modal-title text-success modal-myaccount-header-animation" id="myAccountLoadingLabel">
                        <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> Loading...Please wait
                      </h4>
                      
                    </div>
                    <div className="modal-body">
                      
                      
                    </div>
                    
                  </Modal>
                }
                  
            </>

            
       );    
    }
}


  

export default StudentAccount;
