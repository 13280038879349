import React from "react"

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Media,
    NavItem,
    NavLink,
    Nav,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    TabContent,
    TabPane,
    Container,
    Row,
    Col
} from "reactstrap";

import LaundryCare from "./FaqContent/LaundryCare";
import LaundryPlan from "./FaqContent/Plan";
import LaundryPricing from "./FaqContent/Pricing";
import LaundryPickupDelivery from "./FaqContent/PickupDelivery";
import DryCleaning from "./FaqContent/DryCleaning";


const FaqDesktop = (props)=>{
    const {isStudent} = props;
    const [pills, setPills] = React.useState("1");

    return(
        <>
            <Row>
                <Col>
                    <Nav className="nav-pills-danger" pills>
                        <NavItem>
                        <NavLink
                            className={pills === "1" ? "active" : ""}
                            onClick={() => {
                            setPills("1");
                            }}
                        >
                            Laundry Care
                        </NavLink>
                        </NavItem>
                        {
                        isStudent && 
                        <NavItem>
                        <NavLink
                            className={pills === "2" ? "active" : ""}
                            onClick={() => {
                            setPills("2");
                            }}
                        >
                            Plans
                        </NavLink>
                        </NavItem>
                        }
                        <NavItem>
                        <NavLink
                            className={pills === "4" ? "active" : ""}
                            onClick={() => {
                            setPills("4");
                            }}
                        >
                            Pick up & Delivery
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink
                            className={pills === "5" ? "active" : ""}
                            onClick={() => {
                            setPills("5");
                            }}
                        >
                            Dry Cleaning
                        </NavLink>
                        </NavItem>
                    </Nav>
                    {/* Pill panes */}
                
                    <TabContent className="mt-3" activeTab={"pills" + pills}>
                        <TabPane tabId="pills1">
                            <Row>
                                <Col> 
                                    <LaundryCare {...props} />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="pills2">
                            <Row>
                                <Col> 
                                    <LaundryPlan {...props} />
                                </Col>
                            </Row>
                        </TabPane>
                        
                        <TabPane tabId="pills4">
                            <Row>
                                <Col> 
                                    <LaundryPickupDelivery {...props} />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="pills5">
                        <   Row>
                                <Col> 
                                    <DryCleaning {...props} />
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        
        </>
    )
}

export default FaqDesktop;

