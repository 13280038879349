import React from "react";

import {
    Media,
    
} from "reactstrap";

const studentData = [
    {
        heading:"How many items can a customer store?",
        body:<p>
        Customers can store as many items as they wish, but we charge by the item. There is no maximum number of items, but there is a $100 minimum for any storage order.
        </p>
    },
    {
        heading:"Do you pickup and deliver storage off-campus?",
        body:<p>
            Yes. Lazybones can pickup your storage whether you live in a dorm or an off-campus apartment. If you live more than 5 miles from a campus we service, we may not be able to provide storage services to you. Call us or email us with your address to find out.
         </p>,
    },
    {
        heading:"What days of the week do you do storage pickups during move out week?",
        body:<p>
            We do storage pick-ups seven days a week during peak move out times, which are generally the week of finals and the week after.
        </p>,
    },
    {
        heading:"How many days in advance do I need to schedule my pickup?",
        body:<p>
        Customers need to schedule their storage pickup a minimum of 2 days in advance, but we recommend scheduling at least 3 weeks in advance to ensure you get the date and time you want. Pickups scheduled less than 48 hours in advance are subject to additional fees.
        </p>,
    },
    {
        heading:"What should I do if I know I will not be ready for my scheduled pickup?",
        body:<p>
        You should call us immediately, and we will try to reschedule you for a different pick-up window if possible.
        </p>,
    },
    {
        heading:"How long does it typically take for a storage pickup?",
        body:<p>
            Storage pickups typically take 15-20 minutes depending on how many items you have to store and the size of your items. Customers with many items or larger items take longer. Please contact Lazybones Customer service prior to your storage pickup if you have a particularly large storage order.
        </p>,
    },
    {
        heading:"When do you do storage pickups?",
        body:<p>
        Lazybones does storage pickups all year round! 
        </p>,
    },
    {
        heading:"Can I schedule more than one storage pickup date?",
        body:<p>
        Customers can always ask for multiple pickups, however, there is a $100 minimum charge for storing the items for each storage pickup. For instance, if you have us do 3 different pickups, your minimum storage charge is going to be $300.
        </p>,
    },
    {
        heading:"Will Lazybones refund my $35 if I cancel my storage reservation?",
        body:<p>
        Lazybones will refund your $35 reservation fee as long as you cancel your reservation at least 48 hours before your storage pickup date. 
        </p>,
    },
    {
        heading:"What is the time frame for a storage pickup?",
        body:<p>
        We give the customer a three-hour window for storage pickups and deliveries.  The window is necessary for unforeseen issues that tend to occur during moving time such as, blocked vans, traffic, closed roads, or broken elevators. 
        </p>,
    },
    {
        heading:"When should I schedule my storage delivery?",
        body:<p>
            All storage deliveries should be scheduled before August 1st. 
        </p>
    },
    {
        heading:"What if I schedule my storage delivery after August 1st?",
        body:<p>
        If you schedule your storage delivery after August 1st, you must schedule your delivery five days in advance of your desired drop off date. In addition, an automatic, late scheduling fee of $35 will be charged to your credit card. 
        </p>,
    },
    {
        heading:"What is the time frame for storage delivery?",
        body:<p>
         We give the customer a three-hour window for storage pickups and deliveries.  The window is necessary for unforeseen issues that tend to occur during moving time such as, blocked vans, traffic, closed roads, or broken elevators. 
         </p>,
    },
    {
        heading:"What happens if I miss my scheduled storage delivery?",
        body:<p>
            There is a $100 charge if a customer misses their delivery. Do not schedule storage delivery on the day you are flying in because of how common flight delays are.
        </p>,
    },
    {
        heading:"Why isn't the delivery date I want showing up on the website?",
        body:<p>
            Because of University restrictions, there are certain black out days which we cannot schedule deliveries. Our website will not allow you to schedule a delivery on those days.
        </p>,
    },
    
    
];

const PickupDeliveries = ()=>{
    const data = studentData;
    let ctr = 1; 
    return(
        <>
            {
                data.map(({heading,body})=>
                    <Media key={ctr++}>
                        <Media body>
                        <Media heading>{heading}</Media>
                        {body}
                        </Media>
                    </Media>
                )
            }
        </>
    );
}

export default PickupDeliveries;
