import React from "react";
import _ from "lodash";

// reactstrap components
import {
    Badge,
    Button,
    Container,
    Card,
    CardBody,
    CardFooter,
    CardImg,
    CardImgOverlay,
    CardLink,
    CardTitle,
    CardSubtitle,
    CardText,
    ListGroupItem,
    ListGroup,
    Row,
    Col,
    
  } from "reactstrap";
  
function SelectSchool(props){
    const {listOfSchoolsByRegion, user, onClickOfSchool,product} = props;
    const selectedSchool  = user.school;   
    
    React.useEffect(()=>{
     // window.scrollTo(0, 0);
     // document.body.scrollTop = 0;
      document.getElementById("sectionArea").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    });
    
    return(
        <Container id="mainContentArea">
          <Row className="title-row justify-content-between">
            <Col md="6">
              <h3 className="shop">Signup for Storage - Student</h3>
            </Col>
            <Col md="4">
              <div className="text-md-right d-none">
                <span className="text-muted">Step 1</span>
                <Button color="link">
                  <i className="fa fa-shopping-cart" /> Select School
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="title-body-row">
                <Col md="12">
                    {
                        listOfSchoolsByRegion.map(region=>{
                        console.log(region);   
                        return(
                            <div key={region.id}>
                                <hr />
                                    <h3 >
                                      <strong>
                                      {region.name}
                                      </strong>
                                    </h3>
                                <hr />
                                <Row className="card-body-row">
                                {
                                region.schools.map(school =>{
                                        if(!school.displayOnWeb) return;
                                        
                                        const selectedAttributes = (selectedSchool && selectedSchool.id === school.id) ? {"data-color":"orange"}:{};
                                        
                                        /*
                                        let backgroundImage = {
                                          backgroundImage: ""
                                        }
                                        let flagImage = require("assets/mylazybones/img/placeholder_img.png");

                                        if(school.id === 6){
                                           backgroundImage = {
                                            backgroundImage: "url(" +
                                            require("assets/mylazybones/img/uw_madison.jpg") +
                                            ")"
                                          }
  
                                           flagImage = require("assets/mylazybones/img/uw_madison_flag.png");
                                        }

                                        if(school.id === 5){
                                          backgroundImage = {
                                           backgroundImage: "url(" +
                                           require("assets/mylazybones/img/syracuse_univ.jpg") +
                                           ")"
                                         }
 
                                          flagImage = require("assets/mylazybones/img/syracuse_univ_flag.png");
                                       }
                                       */

                                      let backgroundImage = {
                                        backgroundImage: ""
                                      }
                                      let flagImage = require(`assets/mylazybones/img/signup/school/${school.id}.jpg`);
                                      backgroundImage = {
                                        backgroundImage: "url(" +
                                          flagImage + 
                                        ")"
                                      }

                                        
                                       const schoolWithSemestersAcceptingStorage = _.find(school.semesters, { 'acceptingStorage': true });
                                       const handleOnClickSchool =  (schoolWithSemestersAcceptingStorage) ? e => onClickOfSchool(school,product):e=>{};
                                       const cardCss =  schoolWithSemestersAcceptingStorage ? "card-profile cursor mt-0" :  "card-profile mt-0"
                                       
                                       const isSchoolSelected = (selectedSchool && selectedSchool.id === school.id) 
                                          ? true:false;

                                       let displayCardFooter = false;
                                       if(isSchoolSelected === true) displayCardFooter = true;
                                       if(!schoolWithSemestersAcceptingStorage) displayCardFooter = true;
                                       
                                       return(
                                            <Col key={school.id} md="4" sm="4">
                                                <Card className={cardCss} {...selectedAttributes} onClick={handleOnClickSchool}>
                                                  <div
                                                    className="card-cover"
                                                    style={backgroundImage}
                                                  />
                                                  
                                                  <CardBody>
                                                    <CardTitle tag="h4">
                                                      {isSchoolSelected ? <strong>{school.name}</strong>:school.name }
                                                    </CardTitle>
                                                    
                                                    {
                                                      displayCardFooter && 
                                                      <CardFooter className="text-center mt-2">
                                                        { (selectedSchool && selectedSchool.id === school.id) ?
                                                          <Badge className="mr-1" color="success" pill>
                                                            Selected
                                                          </Badge>
                                                          :
                                                          <br/>
                                                        }
                                                        {
                                                          (!schoolWithSemestersAcceptingStorage) ?
                                                          <Badge className="mr-1" color="danger" pill>
                                                            Sold Out
                                                          </Badge>
                                                          :
                                                          <br/>
                                                        }
                                                      </CardFooter>
                                                    }
                                                  </CardBody>
                                                </Card>
                                            </Col>
                                        );     
                                }) 
                                }
                                </Row>
                                

                            </div>      
                        )


                        })    

                    }
                </Col>
                
          </Row>
          
          
        
      </Container>      
    );
}

export default SelectSchool