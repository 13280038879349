import React from "react";
import _ from "lodash"
import Select from "react-select";


import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Media,
    NavItem,
    NavLink,
    Nav,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    TabContent,
    TabPane,
    Container,
    Row,
    Col
  } from "reactstrap";

import StoragePricing from "./FaqContent/Pricing";
import StorageSchedulingPickup from "./FaqContent/SchedulingPickups";
import StorageSchedulingDeliveries from "./FaqContent/SchedulingDeliveries";
import Shipping from "./FaqContent/Shipping";
import StorageSigningUp from "./FaqContent/StorageSigningUp";
import Packing from "./FaqContent/Packing";
import PickupDeliveries from "./FaqContent/PickupDeliveries";



const FaqMobile = (props)=>{
    const {faqDropdown} = props;

    
    const [faqDisplayArea,setFaqDisplayArea] = React.useState("storage-signing-up");

    const handleFaqDisplayArea = (dropDownSelection)=>{
        const selectedValue = dropDownSelection.value;
        setFaqDisplayArea(selectedValue);
    }

    const faqDisplayAreaSelectionValue = _.find(faqDropdown,{'value':faqDisplayArea});
    const storageSignUpActiveCss = (faqDisplayArea === "storage-signing-up") ? "d-block": "d-none";
    const pricingFaqActiveCss = (faqDisplayArea === "pricing-faq") ? "d-block": "d-none";
    const schedulePickupActiveCss = (faqDisplayArea === "scheduling-pickups") ? "d-block": "d-none";
    const scheduleDeliveryActiveCss = (faqDisplayArea === "scheduling-deliveries") ? "d-block": "d-none";
    const schedulePickupDeliveryActiveCss = (faqDisplayArea === "scheduling-pickups-deliveries") ? "d-block": "d-none";
    const packingActiveCss = (faqDisplayArea === "packing-faq") ? "d-block": "d-none";
    const shippingActiveCss = (faqDisplayArea === "shipping") ? "d-block": "d-none";
    

    return(
        <>
            <Row id="faq" className={`faq`}>
                <Col>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Select
                                    className="react-select react-select-default"
                                    classNamePrefix="react-select"
                                    name="defaultFaq"
                                    value={faqDisplayAreaSelectionValue}
                                    onChange={value => handleFaqDisplayArea(value)}
                                    options={faqDropdown}
                                    placeholder=""
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    
                    <Row id="storage-signing-up" className={`storage-signing-up ${storageSignUpActiveCss}`}>
                        <Col> 
                            <StorageSigningUp />
                        </Col>
                    </Row>

                    <Row id="pricing-faq" className={`${pricingFaqActiveCss}`}>
                        <Col>
                            <StoragePricing />
                        </Col>
                    </Row>
                        
                    <Row id="scheduling-pickups" className={`scheduling-pickups ${schedulePickupActiveCss}`}>
                        <Col>
                            <StorageSchedulingPickup />
                        </Col>
                    </Row>
                    <Row id="scheduling-deliveries" className={`scheduling-deliveries ${scheduleDeliveryActiveCss}`}> 
                        <Col>
                            <StorageSchedulingDeliveries />
                        </Col>
                    </Row>
                    <Row id="scheduling-pickups-deliveries" className={`scheduling-pickups-deliveries ${schedulePickupDeliveryActiveCss}`}> 
                        <Col>
                            <PickupDeliveries />
                        </Col>
                    </Row>
                    <Row id="packing-faq" className={`packing-faq ${packingActiveCss}`}>
                        <Col>
                           <Packing />
                        </Col>
                    </Row>
                    
                    <Row id="shipping" className={`shipping ${shippingActiveCss}`}>
                        <Col>
                           <Shipping />
                        </Col>
                    </Row>
                    
                    
                </Col>
            </Row>
            
        
        </>
    )
}

export default FaqMobile;

