/*
const listOfSchoolsByRegion = [
    {
        id:1,
        name:"Wisconsin",
        schools:[
           {
                id:1,
                name:"UW Madison",
                icon:"nc-delivery-fast",
                colorIcon:"icon-warning",
                schoolImage:"assets/mylazybones/img/uw_madison.jpg",
                schoolFlagImage:"assets/mylazybones/img/uw_madison_flag.png",
                semesters:[
                        {
                              id:1,
                              semester: "Fall 2019",
                              startDate: "Aug 15, 2019",
                              endDate: "Dec 25, 2019",
                              pricing:[
                                    {
                                          id:1,
                                          type:"by-the-pound",
                                          description:"By the Pound",
                                          price: [
                                                {
                                                      id:41,
                                                      price:1.99,
                                                      pricePerPound: 1.99,
                                                      name:"By Pound",
                                                      description:"By Pound"
                                                }
                                          ],
                                          insurance:[
                                                {
                                                    id:1,
                                                    type:"Standard",
                                                    price: 0.00     
                                                },
                                                {
                                                    id:4,
                                                    type:"Plus",
                                                    price: 40.00     
                                                },
                                                {
                                                    id:5,
                                                    type:"Premium",
                                                    price: 50.00     
                                                },
                                          ]
                                                
                                                
                                    },
                                    {
                                          id:2,
                                          type:"semester-plan",
                                          description:"Semester Plan",
                                          price:[
                                                {
                                                      id:1,
                                                      price:199.00,
                                                      pricePerPound: 1.24,
                                                      name:"10lbs per week",
                                                      description:"2 small loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 0.00     
                                                            },
                                        
                                                      ]
                                                },
                                                {
                                                      id:2,
                                                      price:299.00,
                                                      pricePerPound: 1.25,
                                                      name:"15lbs per week",
                                                      description:"2 regular loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 10.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 20.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 30.00     
                                                            },
                                        
                                                      ]      
                                                },
                                                {
                                                      id:3,
                                                      price:389.00,
                                                      pricePerPound: 1.22,
                                                      name:"20lbs per week",
                                                      description:"2 large loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 60.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 70.00     
                                                            },
                                        
                                                      ]
                                                },
                                                {
                                                      id:4,
                                                      price:419.00,
                                                      pricePerPound: 1.05,
                                                      name:"25lbs per week",
                                                      description:"3 regular loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 90.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 160.00     
                                                            },
                                        
                                                      ]
                                                },
                                          ]
                                    }
                              ]
                        },
                        {
                              id:2,
                              semester: "Spring 2020",
                              startDate: "Jan 15, 2020",
                              endDate: "Apr 25, 2020",
                              pricing:[
                                    {
                                          id:3,
                                          type:"by-the-pound",
                                          description:"By the Pound",
                                          price: [
                                                {
                                                      id:42,
                                                      price:0,
                                                      pricePerPound: 1.99,
                                                      name:"By Pound",
                                                      description:"By Pound"
                                                }
                                          ],
                                          insurance:[
                                                {
                                                    id:1,
                                                    type:"Standard",
                                                    price: 0.00     
                                                },
                                                {
                                                    id:4,
                                                    type:"Plus",
                                                    price: 40.00     
                                                },
                                                {
                                                    id:5,
                                                    type:"Premium",
                                                    price: 50.00     
                                                },
                                          ]
                                    },
                                    {
                                          id:4,
                                          type:"semester-plan",
                                          description:"Semester Plan",
                                          price:[
                                                {
                                                      id:5,
                                                      price:150.00,
                                                      pricePerPound: 1.24,
                                                      name:"10lbs per week",
                                                      description:"2 small loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 0.00     
                                                            },
                                        
                                                      ]
                                                },
                                                {
                                                      id:6,
                                                      price:259.00,
                                                      pricePerPound: 1.25,
                                                      name:"15lbs per week",
                                                      description:"2 regular loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 40.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 80.00     
                                                            },
                                        
                                                      ]
                                                },
                                                {
                                                      id:7,
                                                      price:359.00,
                                                      pricePerPound: 1.22,
                                                      name:"20lbs per week",
                                                      description:"2 large loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 90.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 160.00     
                                                            },
                                        
                                                      ]
                                                },
                                                {
                                                      id:8,
                                                      price:459.00,
                                                      pricePerPound: 1.05,
                                                      name:"25lbs per week",
                                                      description:"3 regular loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 100.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 200.00     
                                                            },
                                        
                                                      ]
                                                },
                                          ]
                                    }
                              ]
                        },

                  ]
           },
           {
                id:2,
                name:"Luther Prep",
                icon:"nc-credit-card",
                colorIcon:"icon-danger",
                schoolImage:"assets/mylazybones/img/placeholder_img",
                schoolFlagImage:"assets/mylazybones/img/placeholder_img",
                semesters:[
                        {
                              id:3,
                              semester: "Fall 2019",
                              startDate: "Aug 16, 2019",
                              endDate: "Dec 26, 2019",
                              pricing:[
                                    {
                                          id:5,
                                          type:"by-the-pound",
                                          description:"By the Pound",
                                          price: [
                                                {
                                                      id:43,
                                                      price:0,
                                                      pricePerPound: 1.99,
                                                      name:"By Pound",
                                                      description:"By Pound"
                                                }
                                          ]
                                    },
                                    {
                                          id:6,
                                          type:"semester-plan",
                                          description:"Semester Plan",
                                          price:[
                                                {
                                                      id:9,
                                                      price:199.00,
                                                      pricePerPound: 1.24,
                                                      name:"10lbs per week",
                                                      description:"2 small loads"
                                                },
                                                {
                                                      id:10,
                                                      price:299.00,
                                                      pricePerPound: 1.25,
                                                      name:"15lbs per week",
                                                      description:"2 regular loads"
                                                },
                                                {
                                                      id:11,
                                                      price:389.00,
                                                      pricePerPound: 1.22,
                                                      name:"20lbs per week",
                                                      description:"2 large loads"
                                                },
                                                {
                                                      id:12,
                                                      price:419.00,
                                                      pricePerPound: 1.05,
                                                      name:"25lbs per week",
                                                      description:"3 regular loads"
                                                },
                                          ]
                                    }
                              ]
                        },
                        {
                              id:4,
                              semester: "Spring 2020",
                              startDate: "Jan 16, 2020",
                              endDate: "Apr 26, 2020",
                              pricing:[
                                    {
                                          id:7,
                                          type:"by-the-pound",
                                          description:"By the Pound",
                                          price: [
                                                {
                                                      id:44,
                                                      price:0,
                                                      pricePerPound: 1.99,
                                                      name:"By Pound",
                                                      description:"By Pound"
                                                }
                                          ]
                                    },
                                    {
                                          id:8,
                                          type:"semester-plan",
                                          description:"Semester Plan",
                                          price:[
                                                {
                                                      id:13,
                                                      price:199.00,
                                                      pricePerPound: 1.24,
                                                      name:"10lbs per week",
                                                      description:"2 small loads"
                                                },
                                                {
                                                      id:14,
                                                      price:299.00,
                                                      pricePerPound: 1.25,
                                                      name:"15lbs per week",
                                                      description:"2 regular loads"
                                                },
                                                {
                                                      id:15,
                                                      price:389.00,
                                                      pricePerPound: 1.22,
                                                      name:"20lbs per week",
                                                      description:"2 large loads"
                                                },
                                                {
                                                      id:16,
                                                      price:419.00,
                                                      pricePerPound: 1.05,
                                                      name:"25lbs per week",
                                                      description:"3 regular loads"
                                                },
                                          ]
                                    }
                              ]
                        },
                        {
                              id:9,
                              semester: "Spring 2020",
                              startDate: "Jan 17, 2020",
                              endDate: "Apr 27, 2020",
                              pricing:[
                                    {
                                          id:9,
                                          type:"by-the-pound",
                                          description:"By the Pound",
                                          price: [
                                                {
                                                      id:45,
                                                      price:0,
                                                      pricePerPound: 1.99,
                                                      name:"By Pound",
                                                      description:"By Pound"
                                                }
                                          ]
                                    },
                                    {
                                          id:10,
                                          type:"semester-plan",
                                          description:"Semester Plan",
                                          price:[
                                                {
                                                      id:17,
                                                      price:199.00,
                                                      pricePerPound: 1.24,
                                                      name:"10lbs per week",
                                                      description:"2 small loads"
                                                },
                                                {
                                                      id:18,
                                                      price:299.00,
                                                      pricePerPound: 1.25,
                                                      name:"15lbs per week",
                                                      description:"2 regular loads"
                                                },
                                                {
                                                      id:19,
                                                      price:389.00,
                                                      pricePerPound: 1.22,
                                                      name:"20lbs per week",
                                                      description:"2 large loads"
                                                },
                                                {
                                                      id:20,
                                                      price:419.00,
                                                      pricePerPound: 1.05,
                                                      name:"25lbs per week",
                                                      description:"3 regular loads"
                                                },
                                          ]
                                    }
                              ]
                        },

                  ]

          },
             
        ]

    },
    {
      id:2,
      name:"New York",
      schools:[
         {
               id:3,
               name:"Syracuse Univesity",
               icon:"nc-delivery-fast",
               colorIcon:"icon-warning",
               schoolImage:"assets/mylazybones/img/placeholder_img",
               schoolFlagImage:"assets/mylazybones/img/placeholder_img",
               semesters:[
                        {
                              id:5,
                              semester: "Fall 2019",
                              startDate: "Aug 15, 2019",
                              endDate: "Dec 25, 2019",
                              pricing:[
                                    {
                                          id:11,
                                          type:"by-the-pound",
                                          description:"By the Pound",
                                          price: [
                                                {
                                                      id:46,
                                                      price:0,
                                                      pricePerPound: 1.99,
                                                      name:"By Pound",
                                                      description:"By Pound"
                                                }
                                          ],
                                          insurance:[
                                                {
                                                    id:1,
                                                    type:"Standard",
                                                    price: 0.00     
                                                },
                                                {
                                                    id:4,
                                                    type:"Plus",
                                                    price: 90.00     
                                                },
                                                {
                                                    id:5,
                                                    type:"Premium",
                                                    price: 160.00     
                                                },
                            
                                          ]
                                    },
                                    {
                                          id:12,
                                          type:"semester-plan",
                                          description:"Semester Plan",
                                          price:[
                                                {
                                                      id:21,
                                                      price:199.00,
                                                      pricePerPound: 1.24,
                                                      name:"10lbs per week",
                                                      description:"2 small loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 90.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 160.00     
                                                            },
                                        
                                                      ]
                                                },
                                                {
                                                      id:22,
                                                      price:299.00,
                                                      pricePerPound: 1.25,
                                                      name:"15lbs per week",
                                                      description:"2 regular loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 90.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 160.00     
                                                            },
                                        
                                                      ]
                                                },
                                                {
                                                      id:23,
                                                      price:389.00,
                                                      pricePerPound: 1.22,
                                                      name:"20lbs per week",
                                                      description:"2 large loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 90.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 160.00     
                                                            },
                                        
                                                      ]
                                                },
                                                {
                                                      id:24,
                                                      price:419.00,
                                                      pricePerPound: 1.05,
                                                      name:"25lbs per week",
                                                      description:"3 regular loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 90.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 160.00     
                                                            },
                                        
                                                      ]
                                                },
                                          ]
                                    }
                              ]
                        },
                        {
                              id:6,
                              semester: "Spring 2020",
                              startDate: "Jan 13, 2020",
                              endDate: "May 20, 2020",
                              pricing:[
                                    {
                                          id:13,
                                          type:"by-the-pound",
                                          description:"By the Pound",
                                          price: [
                                                {
                                                      id:47,
                                                      price:0,
                                                      pricePerPound: 1.99,
                                                      name:"By Pound",
                                                      description:"By Pound",
                                                      
                                                }
                                          ],
                                          insurance:[
                                                {
                                                    id:1,
                                                    type:"Standard",
                                                    price: 0.00     
                                                },
                                                {
                                                    id:4,
                                                    type:"Plus",
                                                    price: 90.00     
                                                },
                                                {
                                                    id:5,
                                                    type:"Premium",
                                                    price: 160.00     
                                                },
                            
                                          ]
                                    },
                                    {
                                          id:14,
                                          type:"semester-plan",
                                          description:"Semester Plan",
                                          price:[
                                                {
                                                      id:25,
                                                      price:199.00,
                                                      pricePerPound: 1.24,
                                                      name:"10lbs per week",
                                                      description:"2 small loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 90.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 160.00     
                                                            },
                                        
                                                      ]
                                                },
                                                {
                                                      id:26,
                                                      price:299.00,
                                                      pricePerPound: 1.25,
                                                      name:"15lbs per week",
                                                      description:"2 regular loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 90.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 160.00     
                                                            },
                                        
                                                      ]
                                                },
                                                {
                                                      id:27,
                                                      price:389.00,
                                                      pricePerPound: 1.22,
                                                      name:"20lbs per week",
                                                      description:"2 large loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 90.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 160.00     
                                                            },
                                        
                                                      ]
                                                },
                                                {
                                                      id:28,
                                                      price:419.00,
                                                      pricePerPound: 1.05,
                                                      name:"25lbs per week",
                                                      description:"3 regular loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 90.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 160.00     
                                                            },
                                        
                                                      ]
                                                },
                                          ]
                                    }
                              ]
                        },
                        {
                              id:10,
                              semester: "Summer 2020",
                              startDate: "June 15, 2020",
                              endDate: "July 25, 2020",
                              pricing:[
                                    {
                                          id:5,
                                          type:"by-the-pound",
                                          description:"By the Pound",
                                          price: [
                                                {
                                                      id:48,
                                                      price:0,
                                                      pricePerPound: 1.99,
                                                      name:"By Pound",
                                                      description:"By Pound"
                                                }
                                          ],
                                          insurance:[
                                                {
                                                    id:1,
                                                    type:"Standard",
                                                    price: 0.00     
                                                },
                                                {
                                                    id:4,
                                                    type:"Plus",
                                                    price: 90.00     
                                                },
                                                {
                                                    id:5,
                                                    type:"Premium",
                                                    price: 160.00     
                                                },
                            
                                          ]
                                    },
                                    {
                                          id:16,
                                          type:"semester-plan",
                                          description:"Semester Plan",
                                          price:[
                                                {
                                                      id:29,
                                                      price:199.00,
                                                      pricePerPound: 1.24,
                                                      name:"10lbs per week",
                                                      description:"2 small loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 90.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 160.00     
                                                            },
                                        
                                                      ]
                                                },
                                                {
                                                      id:30,
                                                      price:299.00,
                                                      pricePerPound: 1.25,
                                                      name:"15lbs per week",
                                                      description:"2 regular loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 90.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 160.00     
                                                            },
                                        
                                                      ]
                                                },
                                                {
                                                      id:31,
                                                      price:389.00,
                                                      pricePerPound: 1.22,
                                                      name:"20lbs per week",
                                                      description:"2 large loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 90.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 160.00     
                                                            },
                                        
                                                      ]
                                                },
                                                {
                                                      id:32,
                                                      price:419.00,
                                                      pricePerPound: 1.05,
                                                      name:"25lbs per week",
                                                      description:"3 regular loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 90.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 160.00     
                                                            },
                                        
                                                      ]
                                                },
                                          ]
                                    }
                              ]
                        },

                  ]

         },
         {
               id:4,
               name:"Cornell Univesity",
               icon:"nc-credit-card",
               colorIcon:"icon-danger",
               schoolImage:"assets/mylazybones/img/placeholder_img",
               schoolFlagImage:"assets/mylazybones/img/placeholder_img",
               semesters:[
                        {
                              id:7,
                              semester: "Fall 2019",
                              pricing:[
                                    {
                                          id:17,
                                          type:"by-the-pound",
                                          description:"By the Pound",
                                          price: [
                                                {
                                                      id:49,
                                                      price:0,
                                                      pricePerPound: 1.99,
                                                      name:"By Pound",
                                                      description:"By Pound"
                                                }
                                          ],
                                          insurance:[
                                                {
                                                    id:1,
                                                    type:"Standard",
                                                    price: 0.00     
                                                },
                                                {
                                                    id:4,
                                                    type:"Plus",
                                                    price: 90.00     
                                                },
                                                {
                                                    id:5,
                                                    type:"Premium",
                                                    price: 160.00     
                                                },
                            
                                          ]
                                    },
                                    {
                                          id:18,
                                          type:"semester-plan",
                                          description:"Semester Plan",
                                          price:[
                                                {
                                                      id:33,
                                                      price:199.00,
                                                      pricePerPound: 1.24,
                                                      name:"10lbs per week",
                                                      description:"2 small loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 90.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 160.00     
                                                            },
                                        
                                                      ]
                                                },
                                                {
                                                      id:34,
                                                      price:299.00,
                                                      pricePerPound: 1.25,
                                                      name:"15lbs per week",
                                                      description:"2 regular loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 90.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 160.00     
                                                            },
                                        
                                                      ]
                                                },
                                                {
                                                      id:35,
                                                      price:389.00,
                                                      pricePerPound: 1.22,
                                                      name:"20lbs per week",
                                                      description:"2 large loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 90.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 160.00     
                                                            },
                                        
                                                      ]
                                                },
                                                {
                                                      id:36,
                                                      price:419.00,
                                                      pricePerPound: 1.05,
                                                      name:"25lbs per week",
                                                      description:"3 regular loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 90.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 160.00     
                                                            },
                                        
                                                      ]
                                                },
                                          ]
                                    }
                              ]
                        },
                        {
                              id:8,
                              semester: "Winter 2019",
                              pricing:[
                                    {
                                          id:19,
                                          type:"by-the-pound",
                                          description:"By the Pound",
                                          price: [
                                                {
                                                      id:50,
                                                      price:0,
                                                      pricePerPound: 1.99,
                                                      name:"By Pound",
                                                      description:"By Pound",
                                                      
                                                }
                                          ],
                                          insurance:[
                                                {
                                                    id:1,
                                                    type:"Standard",
                                                    price: 0.00     
                                                },
                                                {
                                                    id:4,
                                                    type:"Plus",
                                                    price: 90.00     
                                                },
                                                {
                                                    id:5,
                                                    type:"Premium",
                                                    price: 160.00     
                                                },
                            
                                          ]
                                    },
                                    {
                                          id:20,
                                          type:"semester-plan",
                                          description:"Semester Plan",
                                          price:[
                                                {
                                                      id:37,
                                                      price:199.00,
                                                      pricePerPound: 1.24,
                                                      name:"10lbs per week",
                                                      description:"2 small loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 90.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 160.00     
                                                            },
                                        
                                                      ]
                                                },
                                                {
                                                      id:38,
                                                      price:299.00,
                                                      pricePerPound: 1.25,
                                                      name:"15lbs per week",
                                                      description:"2 regular loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 90.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 160.00     
                                                            },
                                        
                                                      ]
                                                },
                                                {
                                                      id:39,
                                                      price:389.00,
                                                      pricePerPound: 1.22,
                                                      name:"20lbs per week",
                                                      description:"2 large loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 90.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 160.00     
                                                            },
                                        
                                                      ]
                                                },
                                                {
                                                      id:40,
                                                      price:419.00,
                                                      pricePerPound: 1.05,
                                                      name:"25lbs per week",
                                                      description:"3 regular loads",
                                                      insurance:[
                                                            {
                                                                id:1,
                                                                type:"Standard",
                                                                price: 0.00     
                                                            },
                                                            {
                                                                id:4,
                                                                type:"Plus",
                                                                price: 90.00     
                                                            },
                                                            {
                                                                id:5,
                                                                type:"Premium",
                                                                price: 160.00     
                                                            },
                                        
                                                      ]
                                                },
                                          ]
                                    }
                              ]
                        },

                  ]
           
         },

      ]

    }

      

];*/
import _ from "lodash";
import listOfSchoolsByRegionForLaundry from "../data/listOfSchoolsByRegionForLaundry";
import listOfSchoolsByRegionForStorage from "../data/listOfSchoolsByRegionForStorage";
import listOfDormitoryBySchools from "../data/listOfDormitoryBySchools";

import http from "./httpService";
import {renderError} from 'utils/errorUtils';

const apiLaundryEndpoint = "/laundrySettings";
const apiStorageEndpoint = "/storageSettings";
const apiCommonSettingEndpoint = "/commonSettings";


async function getDropdownListOfDormitoryBySchool(school){
      try{
            const {data:listOfDormitoryBySchools} = await http.get(`${apiCommonSettingEndpoint}/getListOfDormitoriesBySchool`);
            //console.log(listOfDormitoryBySchools);

            let dormitoryList = "";
            listOfDormitoryBySchools.forEach(s =>{
                  if(school.id === s.schoolId) dormitoryList = s.dormitories;
            });
            
            let dormitoryOption = [
                  { value: "", label: " Choose Dorm", isDisabled: true },
            ];
      
            if(!_.isEmpty(dormitoryList)){
                  dormitoryList.forEach(d => {
                        const dormitory = {
                              value:d.id,
                              label:d.name
                        }
                        dormitoryOption.push(dormitory);
                  });
             }
            
            return dormitoryOption;

      }catch(ex){
         console.log(ex);
      }

      
      
}

async function getListOfSchoolsByRegionForLaundry(){
      try{
            const {data:listOfSchoolsForLaundry} = await http.get(apiLaundryEndpoint);
            //console.log(listOfSchoolsForLaundry);
            return listOfSchoolsForLaundry;
            //return listOfSchoolsByRegionForLaundry.filter(s => s);
      }catch(ex){
            renderError(ex);
      }
}

async function getListOfSchoolsByRegionForStorage(){
      try{
            const {data:listOfSchoolsForStorage} = await http.get(`${apiStorageEndpoint}/getListOfSchoolsForStorageSignup`);
            //console.log(listOfSchoolsForStorage);
            return listOfSchoolsForStorage;
            //return listOfSchoolsByRegionForLaundry.filter(s => s);
      }catch(ex){
            renderError(ex);
      }
   //return listOfSchoolsByRegionForStorage.filter(s => s);   
}


async function getListOfDormitoryBySchool(school){
      try{
            const {data:listOfDormitoryBySchools} = await http.get(`${apiCommonSettingEndpoint}/getListOfDormitoriesBySchool`);
            //console.log(listOfDormitoryBySchools);

            let dormitoryList = "";
            listOfDormitoryBySchools.forEach(s =>{
                  if(school.id === s.schoolId) dormitoryList = s.dormitories;
            });
      
            return dormitoryList;

      }catch(ex){
         console.log(ex);
      }
}



export default {
      getDropdownListOfDormitoryBySchool,
      getListOfSchoolsByRegionForLaundry,
      getListOfSchoolsByRegionForStorage,
      getListOfDormitoryBySchool,
      
}

