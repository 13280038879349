import React,{Component} from "react";
import _ from "lodash";
import moment from "moment";
import accounting from "accounting";

import customToast from "../../../Common/CustomToast";
import {scrollTop} from '../../../../utils/helperUtils';
import FormErrorWidget from "../../../Common/FormErrorWidget";


import auth from "services/authService";
import applicationConstants from "../../../../utils/applicationConstants"


import ShoppingCartReviewWidget from "../../ShoppingCart/common/ShoppingCartReviewWidget";


// reactstrap components
import {
    Button,
    Container,
    Card,
    CardBody,
    CardFooter,
    CardImg,
    CardImgOverlay,
    CardLink,
    CardTitle,
    CardSubtitle,
    CardText,
    ListGroupItem,
    ListGroup,
    Row,
    Col,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    FormText,
    UncontrolledTooltip
    
  } from "reactstrap";

class SuppliesReviewScreenForLoggedInUser extends Component{
    constructor(){
        super();
        this.state = {
            deliveryAddress:"",
        }
    }

    async componentDidMount(){
        
        //Check to see if users zip code is valid for supplies in order to populate the catalog and app
        if(auth.getCurrentUser()){
            try{
                const user = await auth.getUserRecord();
                const {dorm_non_dorm:isDormitory,apartment:apartmentNumber, street:addressLine1,home:streetNumber,school_city:city,school_state:state,school_zip:postalCode} = user;
                const [dormitoryName,roomNumber] = [addressLine1,streetNumber];
                const deliveryAddress = {
                    isDormitory,
                    dormitoryName,
                    roomNumber,
                    apartmentNumber,
                    addressLine1,
                    streetNumber,
                    city,
                    state,
                    postalCode
                }    
                this.setState({deliveryAddress})
            }
            catch{
                
            }
        }
        //End of Check to see if users zip code is valid for supplies in order to populate the catalog and app
        
    }

    /*
     For some reason the page scrolls to the product area and user cannot see the errors
     I am just setting a timer to it scrolls back to the top if the serverError props gets updated
    */
    componentDidUpdate(prevProps){
        const {serverErrors} = this.props;
        const {serverErrors:serverErrorsPrev} =prevProps;
        if(!_.isEqual(serverErrors,serverErrorsPrev)){
        setTimeout(()=>{
            scrollTop('sectionArea');
        },1000)
        }
    }

    displayPaymentInformation = ()=>{
        const {user} = this.props;
        const {existingPaymentInformationOnFile,updatePaymentInformationFlag,paymentInformation} =  user;
        const {
            cardExpirationDate,
            cardNumber,
            cardType,
            isExpired
        }  = existingPaymentInformationOnFile;
       
        let [cardNumberDisplay,expirationMonthYearDisplay] = ["",""];
        if(updatePaymentInformationFlag === true){
            cardNumberDisplay = `xxxxxxxxxxx - ${ !_.isEmpty(paymentInformation.creditCardStripeToken.card) && paymentInformation.creditCardStripeToken.card.last4}`
            expirationMonthYearDisplay = `${!_.isEmpty(paymentInformation.creditCardStripeToken.card) && moment(`${paymentInformation.creditCardStripeToken.card.exp_year}-${paymentInformation.creditCardStripeToken.card.exp_month}-1`).format('MMMM')} - ${ !_.isEmpty(paymentInformation.creditCardStripeToken.card) && paymentInformation.creditCardStripeToken.card.exp_year}`
        }
        else{
            cardNumberDisplay = `${cardType} - ${cardNumber}`;
            expirationMonthYearDisplay = cardExpirationDate;
        }

        return(
            <>
            {   updatePaymentInformationFlag && 
                <Row className="mt-2">
                    <Col xs="4">
                     Credit Card Holder
                    </Col>
                    <Col xs="8">
                    {paymentInformation.creditCardName}
                    </Col>
                </Row>
            }

            <Row className="mt-2">
                <Col xs="4">
                Credit Card Number
                </Col>
                <Col xs="8">
                    {cardNumberDisplay}
                </Col>
            </Row>

            <Row className="mt-2">
                <Col xs="4">
                Expiration Month - Year
                </Col>
                <Col xs="8">
                    {expirationMonthYearDisplay}
                </Col>
            </Row>
            </>
        )

    }

    getDeliveryAddress = ()=>{
        let address = "";
        
        if(!_.isEmpty(this.state.deliveryAddress)){
            const {
                isDormitory,
                dormitoryName,
                roomNumber,
                apartmentNumber,
                addressLine1,
                streetNumber,
                city,
                state,
                postalCode
            } = this.state.deliveryAddress;

            if(isDormitory === applicationConstants.NON_DORMITORY){
                if(apartmentNumber)
                    address = <span>{`${streetNumber}, ${addressLine1} Apt: ${apartmentNumber}`} <br/> {`${city}, ${state} - ${postalCode}`}</span>;
                else
                    address = <span>{`${streetNumber}, ${addressLine1}`} <br/> {`${city}, ${state} - ${postalCode}`}</span>;
                
            }else
              address = <span>{`${dormitoryName}, ${roomNumber}`} <br/> {`${city}, ${state} - ${postalCode}`}</span>;
        }
        
        return address;
    }

    handleViewCart = (e)=>{
        e.preventDefault();
        this.props.history.push("/shoppingcart");
    }

    handleSubmit = (e)=>{
        e.preventDefault();
        const {onClickOfPurchaseButtonFromReviewScreen} = this.props;
        
        const shoppingCart = {...this.props.globalApp.shoppingCart}
       

        const {cartTotal,supplies,university} = shoppingCart;
        const {shippingDate} = supplies;

        const {minimum_supply_cart_total:minimumShoppingCartAmount} = university;
        const {subTotal} = cartTotal
        if(subTotal < minimumShoppingCartAmount){
            customToast.error(`Please enter a minimum amount of ${accounting.formatMoney(minimumShoppingCartAmount)} to proceed.`)
            return;
        }

        if(shippingDate === ""){
            customToast.error(`Please select a delivery date.`)
            return;
        }
   
        return onClickOfPurchaseButtonFromReviewScreen();
    }

    render(){
        const {
            globalApp,handleShoppingCartRemoveItem,handleShoppingCartUpdateProductQuantity,
            onClickOfBackButtonFromReviewScreen,serverErrors,user
        } = this.props;
        const {shoppingCart}=  globalApp;
        const {shippingDate} = shoppingCart.supplies;
   
        const {paymentInformation} = user;
        const deliveryAddress = this.getDeliveryAddress();
        const {cartTotal} = globalApp.shoppingCart;
        
       
        return(
            <div className="main">
                <div id="sectionArea" className="section section-gray"> 
                    <Container id="mainContentArea">
                        <Row className="title-row justify-content-between">
                            <Col md="4">
                            <h3 className="shop">Supplies</h3>
                            </Col>
                            <Col  md="5">
                            <div className="text-md-right">
                                <span className="text-muted">Step 3</span>
                                <Button color="link">
                                <i className="fa fa-shopping-cart" /> Review your selection
                                </Button>
                            </div>
                            </Col>
                        </Row>

                        <Row className="title-body-row">
                            <Col md="7">
                                <Card>
                                    <CardBody>
                                        {/*Server Error display */}
                                        {!_.isEmpty(serverErrors) &&
                                            <FormErrorWidget
                                                errors={serverErrors}
                                            />
                                        }
                                        {/* End of Server Error display */}
                                        
                                        <div className="mt-3">
                                            <h6 className="card-category">
                                                <span className="text-left">
                                                Payment Information
                                                </span>
                                                <span className="float-right text-right cursor text-info"
                                                    onClick={(e)=>{
                                                        e.preventDefault();
                                                        return this.props.history.push("/shoppingCart/supplies/checkout")
                                                    }}
                                                >
                                                <i className="fa fa-edit"></i> Edit
                                                </span>
                                            </h6>
                                            <hr/>

                                            {this.displayPaymentInformation()}
                                            

                                            <Row className="mt-2">
                                            <Col xs="4">
                                                Amount be charged
                                            </Col>
                                            <Col xs="8">
                                                {(_.has(cartTotal, ['total'])) ? accounting.formatMoney(cartTotal.total):"$ 0.00"} 
                                            </Col>
                                            </Row>

                                            <Row className="mt-2">
                                            <Col xs="4">
                                                Shipping Date
                                            </Col>
                                            <Col xs="8">
                                                {(shippingDate !== "") ? moment(shippingDate).format("MMMM Do YYYY"):""} 
                                            </Col>
                                            </Row>

                                            
                                        </div>     

                                        <FormGroup row className="mt-5  justify-content-between">
                                            <Col xs={{ size: 2}}>
                                                <Button 
                                                className="btn-rounded btn-danger"
                                                onClick={(e)=>onClickOfBackButtonFromReviewScreen(e)}
                                                >
                                                Back
                                                </Button>
                                            </Col>
                                            <Col xs={{ size: 6}} sm={{ size: 4}} className="text-right">
                                                <Button 
                                                disabled={false}
                                                className="btn-rounded btn-danger"
                                                onClick={this.handleSubmit}
                                                >
                                                Proceed to Purchase
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            
                            </Col>
                            <Col md="5" className="order-first order-sm-first order-md-last">
                                <Card>
                                    <CardBody>
                                        <div>
                                            <h6 className="card-category">
                                                <span className="text-left">
                                                Delivery Address
                                                </span>
                                                <span className="float-right text-right cursor text-info d-none">
                                                <i className="fa fa-edit"></i> Edit
                                                </span>
                                            </h6>
                                            <hr/>
                                            <Row>
                                                <Col xs="12">
                                                    {(deliveryAddress === "") ? "":deliveryAddress}
                                                </Col>
                                            </Row>
                                            
                                        </div>
                                    </CardBody>
                                </Card>

                                <ShoppingCartReviewWidget
                                    {...this.props}
                                    onUpdateOfItem ={handleShoppingCartUpdateProductQuantity}
                                    onRemoveOfItem = {handleShoppingCartRemoveItem}
                                    editMode={true}
                                />
                            </Col>
                        </Row> 
                    </Container>
                </div>
            </div>
        );
    }
}

export default SuppliesReviewScreenForLoggedInUser;