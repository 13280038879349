import React from 'react';
import accounting from "accounting"
import {
    Row,Col,Modal,
} from "reactstrap";
  
/*
Input parameter definitions:
messageAttributes:{
    type:"storage-delivery-allowed"
    accountBalance:<<$value>>
}
*/

function BalanceDueModal(props) {
    const {displayModal,handleCloseOfModalWindow,history,messageAttributes} = props
    const {type} = messageAttributes
    return(
        <Modal
            size="lg"
            isOpen={displayModal}
            toggle={() => handleCloseOfModalWindow(false)}
            className="modal-register"
          >
            
            <div className="modal-header">
                <button
                    className="close text-center"
                    type="button"
                    onClick={() => handleCloseOfModalWindow(false)}
                >
                    <span>×</span>
                </button>
                <h5
                    className="modal-title text-center text-danger mt-3"
                    id="exampleModalLabel"
                >
                    <stromg>Balance Due</stromg>
                </h5>
            </div>

            <div className="modal-body">
                {
                    type === "storage-delivery-allowed" &&
                    <>
                        <Row>
                            <Col>
                                <strong>You have a balance due of {accounting.formatMoney(messageAttributes.accountBalance)}</strong>. Please contact Lazybones by email or phone to clear your dues.
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                * If your credit card has expired then please click 
                                <a 
                                    href="#"
                                    className="btn-link btn btn-danger btn-sm pl-2"
                                    onClick={(e)=>{ 
                                        e.preventDefault(); 
                                        handleCloseOfModalWindow(false); 
                                        return history.push(`/myaccount/view-profile/payment-info`);
                                    }}
                                >here</a> to update your credit card. 
                            </Col>
                        </Row>
                    </>
                }    
            </div>
            <div className="modal-footer no-border-footer" />
        </Modal> 
    );     
}

export default BalanceDueModal;