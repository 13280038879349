import React,{Component} from "react";
import _ from "lodash";
import customToast from '../../../Common/CustomToast'
import {renderError} from '../../../../utils/errorUtils';
import {scrollTop} from '../../../../utils/helperUtils';

import SuppliesService from "../../../../services/suppliesService";



import {
    Alert,
    Button,
    Container,
    Card,
    CardBody,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Row,
    Col,
    
  } from "reactstrap";

import SuppliesImageOrdered from "../../../../assets/mylazybones/img/SuppliesLanding/supplies_ordered.jpg";
import SuppliesImageReceived from "../../../../assets/mylazybones/img/SuppliesLanding/supplies_delivered.jpg"


class SuppliesLandingGuest extends Component{

    constructor(){
        super();
        this.state = {
            data:{
                postalCode:"",
                errorAlertDisplayFlag:false,
            },
            errors:{}
        }
    }

    componentDidMount(){
        const {provideSuppliesToLoggedInUser} = this.props.globalApp.shoppingCart.supplies;
        if(this.props.globalApp.shoppingCart.supplies.provideSuppliesToLoggedInUser === false){
            
            const {provideSuppliesToLoggedInUser} = this.props.globalApp.shoppingCart.supplies;
            if(provideSuppliesToLoggedInUser === false){
                this.handleErrorAlertDisplay(true);
            }
        }
    }

    componentDidUpdate(prevProps){
        const {provideSuppliesToLoggedInUser} = this.props.globalApp.shoppingCart.supplies;
        const {provideSuppliesToLoggedInUser:prevPropsProvideSuppliesToLoggedInUser} = prevProps.globalApp.shoppingCart.supplies;
            
        if(prevPropsProvideSuppliesToLoggedInUser !== provideSuppliesToLoggedInUser){
            if(provideSuppliesToLoggedInUser === false){
                this.handleErrorAlertDisplay(true);
            }
        }

    }

    /*
       Display error component:
       - if search by postal code is invalid or
       - logged in user and their postal code is not in the list of postal codes
    */
    displayErrorComponent = ()=>{
        let returnComponent = null;
        let displayError = false;

        const {data,errors} = this.state;

        const { 
            errorAlertDisplayFlag,
        } = data

        let errorMessage = "Unfortunately we do not provide packing supplies to the specified zipcode.";
        
        if(!_.isEmpty(errors)){
            displayError = true;
        }
        else{
            if(!_.isEmpty(this.props.globalApp.shoppingCart.supplies)){
                const {provideSuppliesToLoggedInUser} = this.props.globalApp.shoppingCart.supplies;
                if(provideSuppliesToLoggedInUser === false){
                    errorMessage = "Unfortunately we do not provide packing supplies to your postal code of your current address on your profile.";
                    displayError = true;
                }
            }   
        }
        
        
        returnComponent = displayError && 
            <Alert
                className="alert-with-icon"
                color="danger"
                isOpen={errorAlertDisplayFlag}
            >
                <Container>
                <div className="alert-wrapper">
                    <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    onClick={()=>this.handleErrorAlertDisplay(false)}
                    >
                    <i className="nc-icon nc-simple-remove" />
                    </button>
                    <div className="message">
                    <i className="nc-icon nc-bell-55" /> {errorMessage}
                    </div>
                </div>
                </Container>
            </Alert>
        
        return returnComponent;
    }

    handleChange = ({currentTarget:input})=>{
        const errors = {...this.state.errors};
        const data = {...this.state.data};
        data[input.name] = input.value;
        this.setState({data,errors});
        
    }

    handleErrorAlertDisplay = (flag)=>{
        const data = {...this.state.data}
        data.errorAlertDisplayFlag = flag;
        this.setState({data});
    }

    handleOrderSupplies = async (e)=>{
        try{
            e.preventDefault();
            const {onClickOfOrderSuppliesByPostalCode} = this.props
            const {postalCode} = this.state.data;
            const formData = {postalCode};
            const zipcodeValidationReturnData = await SuppliesService.validateZipcodeForSupplies(formData);
            
            onClickOfOrderSuppliesByPostalCode(zipcodeValidationReturnData); 
            return;
            
        }
        catch(ex){
            /*
              400 signals validation errors from the server
              renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                console.log(errors);
                this.setState({errors: errors || {}});
                this.handleErrorAlertDisplay(true);
                if(errors){ scrollTop('sectionArea'); return;}
            }
            else
                renderError(ex);
        }        
    }

    render(){
        const {data,errors} = this.state;

        const { 
            postalCode,
            errorAlertDisplayFlag,
        } = data
        
        const errorWidget = this.displayErrorComponent();

        return(
            <>
                <Row>
                    <Col lg="10">
                        <h3 className="title">Packing Supplies</h3>
                    </Col>
                </Row> 

                <Row>
                    <Col className="ml-auto mr-auto order-md-2 order-1" xs="12" md="4">
                        <Row>
                            <Col>
                                {/* Display error widget */}
                                {errorWidget}
                                {/* Display error widget */}
                                
                                <Form className="settings-form">
                                    <FormGroup>
                                        <label>
                                            <strong>
                                                Enter Postal Code to order supplies
                                            </strong>
                                        </label>
                                        <Input
                                        className="border-input"
                                        placeholder="Zip"
                                        type="text"
                                        name="postalCode"
                                        value={postalCode}
                                        onChange = {this.handleChange}
                                        />
                                    </FormGroup>
                                    
                                    <div className="text-center mb-2">
                                        <Button
                                        className="btn-wd btn-round"
                                        color="info"
                                        type="submit"
                                        onClick={this.handleOrderSupplies}
                                        >
                                        Order Supplies
                                        </Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Col>

                    <Col className="ml-auto mr-auto order-md-1 order-2 border-right" xs="12" md="8">
                        <div className="mb-4">
                        <p>
                            Lazybones sells all the moving supplies you need, right to your doorstep! We have what you need; boxes, tape, bubble wrap, even mattress covers. You don't need to be a Lazybones storage or shipping customer to purchase our supplies! Just type in your zip code to see if we offer delivery to your area. Delivery is free!

                        </p>
                        </div>
                        <Card className="card-plain card-blog">
                        <Row>
                            <Col md="5">
                            <div className="card-image">
                                <img
                                alt="..."
                                className="img"
                                src={SuppliesImageOrdered}
                                />
                            </div>
                            </Col>
                            <Col md="7">
                            <CardBody>
                                <CardTitle tag="h3">
                                    Order online
                                </CardTitle>
                                <p className="card-description text-dark">
                                Order your supplies online or call us at <br/>877-215-2105.
                                </p>
                                
                            </CardBody>
                            </Col>
                        </Row>
                        </Card>
                        <Card className="card-plain card-blog">
                        <Row>
                            <Col md="7">
                            <CardBody>
                                <CardTitle tag="h3">
                                    Delivered to your doorstep
                                </CardTitle>
                                <p className="card-description text-dark">
                                Delivery generally takes 1-3 business days and unfortunately we cannot guarantee exact days for delivery. All sales of packing supplies are final.
                                </p>
                            </CardBody>
                            </Col>
                            <Col md="5">
                            <div className="card-image">
                                <img
                                alt="..."
                                className="img"
                                src={SuppliesImageReceived}
                                />
                            </div>
                            </Col>
                        </Row>
                        </Card>
                    </Col>

                    
                </Row>
            </>
        );
    }
}

export default SuppliesLandingGuest