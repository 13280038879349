import _ from "lodash";

import http from "./httpService";
import applicationConstant from "../utils/applicationConstants";

const apiUsersEndpoint = "/users";

async function getAccountHistoryReportData(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/financial/getAccountHistoryReportData`,formData);
    return returnData;
}

async function getAccountCurrentBalance(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/financial/getAccountCurrentBalance`,formData);
    return returnData;
}

async function getAccountNotifications(formData){
    const {data:returnData} = await http.get(`${apiUsersEndpoint}/getAccountNotifications`,formData);
    return returnData;
}

async function getCreditCardInfo(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/getCreditCardInfo`,formData);
    return returnData;
}

function getFormattedUserAddress(userRecord){
    let [formattedAddress,completeStreetAddress] = ["",""];
    const {
        customer_type:customerType,
        dorm_non_dorm:dormitoryOrNonDormitory,
        apartment:apartmentNumber,
        school_city:city,
        school_state:state,
        school_zip:zip,
        street:addressLine1,
        home,
    } = userRecord;

    if(customerType === applicationConstant.CUSTOMER_TYPE_STUDENT){
        if(dormitoryOrNonDormitory === applicationConstant.DORMITORY){
            formattedAddress = {
                line1:`${addressLine1} ${home}`,
                line2:`${city}, ${state} - ${zip}`,
            }

        }
        else if(dormitoryOrNonDormitory ===  applicationConstant.NON_DORMITORY){
            completeStreetAddress = `${home} ${addressLine1}`;
            if(apartmentNumber)
                completeStreetAddress = `${completeStreetAddress}, Apt:${apartmentNumber}`;
               
            formattedAddress = {
                line1:completeStreetAddress,
                line2:`${city}, ${state} - ${zip}`,
            }
        }
    }
    else if(customerType === applicationConstant.CUSTOMER_TYPE_RESIDENTIAL){
            completeStreetAddress = `${home} ${addressLine1}`;
            if(apartmentNumber)
                completeStreetAddress = `${completeStreetAddress}, Apt:${apartmentNumber}`;
               
            formattedAddress = {
                line1:completeStreetAddress,
                line2:`${city}, ${state} - ${zip}`,
            }
    }

    return formattedAddress;
}

function getFormattedUserHomeAddress(userRecord){
    let [formattedAddress] = [""];

    const {
        home_city:city,
        home_state:state,
        home_zip:zip,
        home_street:addressLine1,
    } = userRecord;

    if(addressLine1 && city && state && zip){
        formattedAddress = {
            line1:`${addressLine1}`,
            line2:`${city}, ${state} - ${zip}`,
        }
    }

    return formattedAddress;
}

async function getListOfSchoolsForGiftCard(){
    let returnData = {};

    const {data:listOfSchools} = await http.get(`${apiUsersEndpoint}/getListOfSchoolsForGiftCard`);
    
    let schoolOption = [
          { value: "", label: "Choose School", isDisabled: false },
          { value: "none", label: "None", isDisabled: false },
    ];

    listOfSchools.forEach(s=>{
            const school = {
                value:s.campus_id,
                label:s.campus_title
            } 
            schoolOption.push(school);    
    });

    returnData = {
        schoolOption,
        listOfSchools,
    }
    
    return returnData;
}

async function hiringRequest(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/hiringRequest`,formData);
    return returnData;
}

async function processContactUsForm(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/contactUs`,formData);
    return returnData;
}

async function purchaseGiftCard(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/gift-cards/purchase`,formData);
    return returnData;
}

async function purchaseGiftCardLoggedInUser(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/gift-cards/purchase/existing-user`,formData);
    return returnData;
}

async function resetPassword(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/resetPassword`,formData);
    return returnData;
}

/*
  Returns:
  {
      status:true;
      message:""
      nextPickupDeliverySchedule:{} //This would be blank if for students with One time other display next pickup
  }
*/
async function saveCustomerEmailAndTextNotificationSetting(formData){
    try{
        const {data:result} = await http.post(`${apiUsersEndpoint}/saveCustomerEmailAndTextNotificationSetting`,formData);
        return result;

    }catch(ex){
        console.log(ex);
    }
}

async function saveCustomerCreditCardInformation(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/customer/save-payment-information`,formData);
    return returnData;
}

async function setTemporaryPassword(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/setTemporaryPassword`,formData);
    return returnData;
}

async function validateByUsername(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/validateByUsername`,formData);
    return returnData;
}

async function validateCouponCode(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/couponCodeValidation`,formData);
    return returnData;
}

async function viewProfileSaveResidentialPrimaryAddress(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/customer-profile/residential-primary-address`,formData);
    return returnData;
}

async function viewProfileSavePrimaryInformation(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/customer-profile/primary-info`,formData);
    return returnData;
}

async function viewProfileSaveCredentials(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/customer-profile/user-credentials`,formData);
    return returnData;
}

async function viewProfileSaveStudentCampusAddress(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/customer-profile/student-campus-address`,formData);
    return returnData;
}



async function viewProfileSaveStudentHomeAddress(formData){
    const {data:returnData} = await http.post(`${apiUsersEndpoint}/customer-profile/student-home-address`,formData);
    return returnData;
}


export default {
    getAccountCurrentBalance,
    getAccountHistoryReportData,
    getAccountNotifications,
    getCreditCardInfo,
    getFormattedUserHomeAddress,
    getFormattedUserAddress,
    getListOfSchoolsForGiftCard,
    hiringRequest,
    processContactUsForm,
    purchaseGiftCard,
    purchaseGiftCardLoggedInUser,
    resetPassword,
    saveCustomerEmailAndTextNotificationSetting,
    saveCustomerCreditCardInformation,
    setTemporaryPassword,
    validateCouponCode,
    validateByUsername,
    viewProfileSaveResidentialPrimaryAddress,
    viewProfileSavePrimaryInformation,
    viewProfileSaveCredentials,
    viewProfileSaveStudentCampusAddress,
    viewProfileSaveStudentHomeAddress,
}