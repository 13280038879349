import React,{Component} from "react";
import _ from "lodash";
import moment from "moment";
import {toast} from 'react-toastify';
import customToast from '../../../../Common/CustomToast'

import StudentService from "../../../../../services/studentService";
import LaundryService from "../../../../../services/laundryService";
import SchoolService  from "../../../../../services/schoolService";

import StudentAccountLaundrySchedulingModal from "./StudentAccountLaundrySchedulingModal";
import StudentAccountCancelUpcomingPickupModal from "./StudentAccountCancelUpcomingPickupModal";
import StudentAccountLaundryModifyRecurringScheduleModal from "./StudentAccountLaundryModifyRecurringScheduleModal";
import StudentAccountLaundryModifyOneTimeUpcomingPickupModal from "./StudentAccountLaundryModifyOneTimeUpcomingPickupModal";
import StudentAccountLaundryUpcomingPickupSpecialRequest from "./StudentAccountLaundryUpcomingPickupSpecialRequest";
import StudentAccountLaundryModifyDeliveryWaiverAndDriverInstructions from "./StudentAccountLaundryModifyDeliveryWaiverAndDriverInstructions";
import StudentAccountLaundryDoublePoundModal from "./StudentAccountLaundryDoublePoundModal";

import GarmentPricingListModal from "../../../LaundryInformation/GarmentInsurance/GarmentInsuranceModal";

import ProcessingModal from "../../../../Common/ProcessingModal";

import UserNotificationWidget from "../Common/Notifications/UserNotifications";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    Row,
    Col,
    Modal,
  } from "reactstrap";

class StudentAccountLaundryDetail extends Component{
    

    constructor(){
        super();
        this.state = {
            listOfSchoolsByRegionForLaundry:"",
            displayDoublePoundModalFlag:false,
            displayGarmentPricingModalFlag:false,
            displayModifyRecurringScheduleModalFlag:false,
            displayModifyOneTimeUpcomingPickupModalFlag:false,
            displayModifyDeliveryWaiverAndDriverInstructionsModalFlag:false,
            displaySpecialRequestForUpcomingPickupModalFlag:false,
            scheduleModalDisplayFlag:false,
            processingModalDisplayFlag:false,
            cancelUpcomingModalDisplayFlag:false,
            setScheduleFlag:false,
            completePendingActionFlag:false,
            
        }
    }

    async componentDidMount(){
        //If schedule is false then set schedle flag is true
        const setScheduleFlag = this.isStudentNextPickupDeliveryScheduleAvailable();

        let { listOfSchoolsByRegionForLaundry, } = this.state;

        if(!listOfSchoolsByRegionForLaundry){
            listOfSchoolsByRegionForLaundry = await SchoolService.getListOfSchoolsByRegionForLaundry();
        } 
        this.setState({
            listOfSchoolsByRegionForLaundry,
            setScheduleFlag
        });
    }

    componentDidUpdate(){
        const setScheduleFlag = (this.isStudentNextPickupDeliveryScheduleAvailable()) ? false:true;
        const previousState = {...this.state};
        if(previousState.setScheduleFlag != setScheduleFlag)
          this.setState({setScheduleFlag});
    }

    completePendingActions = ()=>{
        const myAccountState  = {...this.state};
        const {completePendingActionFlag} = this.state;
        myAccountState.completePendingActionFlag = !completePendingActionFlag;
        this.setState(myAccountState);
    }

    /*
      Semester has to start
      Student (In Dorm)
         - Never display it
      
      Student (Not in Dorm)   
         - Semester plan 
             - has a recurring schedule then don't display it 
         
         - One time plan
             - has a recurring schedule then don't display it

    */
    displayAsapPickupButton = ()=>{
        let displayAsapPickupButton = false;
        
        const {user,laundry} = this.props;
        let studentLivesInDormitory = false;
        let isStudent = true;
        if(user){
            const {dorm_non_dorm,customer_type} = user;
            studentLivesInDormitory = (dorm_non_dorm && dorm_non_dorm === 1) ?  true:false;
            isStudent = (customer_type && customer_type === 1) ? true:false;
            
        }

        //if student lives in a dorm then don't display it
        displayAsapPickupButton = isStudent && studentLivesInDormitory && false;
        
        //If student does not live in a dorm 
        if(isStudent && !studentLivesInDormitory && !_.isEmpty(laundry)){
           //Check if the student has a recurring plan
           const {laundryOrder} = laundry;
           
           //Check if semester has started
           const hasSemesterStarted = this.hasSemesterStarted(laundryOrder);
           const hasRecurringPlan = this.doesStudentHaveRecurringLaundrySchedule(laundryOrder);
           const hasOneTimePlan = false;
           //If semester has started and has a recurring plan
           if(hasSemesterStarted && hasRecurringPlan){
                displayAsapPickupButton = false;
           }
           else if(hasSemesterStarted && hasOneTimePlan){
                //PENDING
                displayAsapPickupButton = true;
           }
        } 

        return displayAsapPickupButton;
    }

    /* This will focus on cancelling only the upcoming pickup
       Student (In Dorm)
         - Never display it

       Student (Not in dorm)
        - If has a recurring plan then display if it's before the pickup and delivery day
        - If has a one time plan then we display if it's been scheduled and is before the pickup and delivery day
    */
    displayCancelUpcomingPickupButton = ()=>{
        let displayCancelUpcomingPickupButton = false;
        
        //displayCancelUpcomingPickupButton = this.isStudentNextPickupDeliveryScheduleAvailable();

        const {user,laundry} = this.props;
        let studentLivesInDormitory = false;
        let isStudent = true;
        if(user){
            const {dorm_non_dorm,customer_type} = user;
            studentLivesInDormitory = (dorm_non_dorm && dorm_non_dorm === 1) ?  true:false;
            isStudent = (customer_type && customer_type === 1) ? true:false;
            
        }

        //if student lives in a dorm then don't display it
        //displayCancelUpcomingPickupButton = isStudent && studentLivesInDormitory && false;


        //If student does not live in a dorm
        if(isStudent && (!studentLivesInDormitory || studentLivesInDormitory) && this.isStudentNextPickupDeliveryScheduleAvailable()){
            //Check if the student has a recurring plan
            const {laundryOrder,nextPickupDeliverySchedule} = laundry;
            const hasRecurringPlan = this.doesStudentHaveRecurringLaundrySchedule(laundryOrder);
            const now = moment();
                
            //If student has a recurring plan
            if(hasRecurringPlan){
                const nextPickupDate = moment(nextPickupDeliverySchedule.pickup.date);
                const nextDeliveryDate = moment(nextPickupDeliverySchedule.delivery.date);
                //Only display the change upcoming after the next delivery date
                if(now.isSame(nextPickupDate,'day') || now.isSame(nextDeliveryDate,'day')){
                    return false;
                }
                else
                    return true;

            }
            //End if recurring plan

            //One time plan and has a pickup scheduled - This is only for student who does not live in a dorm
            if(!hasRecurringPlan && !studentLivesInDormitory){
                const nextPickupDate = moment(nextPickupDeliverySchedule.pickup.date);
                const nextDeliveryDate = moment(nextPickupDeliverySchedule.delivery.date);
                //Only display the change upcoming after the next delivery date
                if(now.isSame(nextPickupDate,'day') || now.isSame(nextDeliveryDate,'day')){
                    return false;
                }
                else
                    return true;
            }

            //displayModifyPickupScheduleButton = true;
        }
        
        return displayCancelUpcomingPickupButton;
    }

    /*
    If they have a scheduled pickup then display this button as they might want to make update instructions
    */
    displayDeliveryWaiverAndDriverInstructionButton = ()=>{
        let displayDeliveryWaiverAndDriverInstructionButton = false;

        displayDeliveryWaiverAndDriverInstructionButton = this.isStudentNextPickupDeliveryScheduleAvailable();
        
        return displayDeliveryWaiverAndDriverInstructionButton;
    }   
    
    /*  This display only if you are on a semester plan */
    displayDoublePoundsForMissedWeekButton = ()=>{
        let displayDoublePoundsForMissedWeekButton = false;
        
        const {user,laundry} = this.props;
        let studentLivesInDormitory = false;
        let isStudent = true;
        if(user){
            const {dorm_non_dorm,customer_type} = user;
            studentLivesInDormitory = (dorm_non_dorm && dorm_non_dorm === 1) ?  true:false;
            isStudent = (customer_type && customer_type === 1) ? true:false;
            
        }
        
        if(isStudent && (!studentLivesInDormitory || studentLivesInDormitory) && this.isStudentNextPickupDeliveryScheduleAvailable()){
            const {laundryOrder} = laundry;
            displayDoublePoundsForMissedWeekButton = LaundryService.isLaundryPackageIdForSemesterPlan(laundryOrder.laundry_package_id)
        }
        return displayDoublePoundsForMissedWeekButton;
    }

    /*
     If you have an upcoming pickup then display it until one day before the actual pickup
     This applies for both dorm student and non-dorm students
    */
    displayMakeSpecialRequestForUpcomingPickupButton = ()=>{
        let displayButton = false;

        const {user,laundry} = this.props;
        let studentLivesInDormitory = false;
        let isStudent = true;
        if(user){
            const {dorm_non_dorm,customer_type} = user;
            studentLivesInDormitory = (dorm_non_dorm && dorm_non_dorm === 1) ?  true:false;
            isStudent = (customer_type && customer_type === 1) ? true:false;
            
        }

        //if student lives in a dorm then don't display it
        //displayButton = isStudent && studentLivesInDormitory && false;


        //If student does not live in a dorm
        if(isStudent && (!studentLivesInDormitory || studentLivesInDormitory) && this.isStudentNextPickupDeliveryScheduleAvailable()){
            //Check if the student has a recurring plan
            const {laundryOrder,nextPickupDeliverySchedule} = laundry;
            const hasRecurringPlan = this.doesStudentHaveRecurringLaundrySchedule(laundryOrder);
            const now = moment();
                
            //If student has a recurring plan
            if(hasRecurringPlan){
                const nextPickupDate = moment(nextPickupDeliverySchedule.pickup.date);
                const nextDeliveryDate = moment(nextPickupDeliverySchedule.delivery.date);
                //Only display the change upcoming after the next delivery date
                if(now.isSame(nextPickupDate,'day') || now.isSame(nextDeliveryDate,'day')){
                    return false;
                }
                else
                    return true;

            }
            //End if recurring plan

            //One time plan and has a pickup scheduled - This is only for student who does not live in a dorm
            if(!hasRecurringPlan && !studentLivesInDormitory){
                const nextPickupDate = moment(nextPickupDeliverySchedule.pickup.date);
                const nextDeliveryDate = moment(nextPickupDeliverySchedule.delivery.date);
                //Only display the change upcoming after the next delivery date
                if(now.isSame(nextPickupDate,'day') || now.isSame(nextDeliveryDate,'day')){
                    return false;
                }
                else
                    return true;
            }

            
        }


        return displayButton;
    }

    /*
      Student (In Dorm)
         - Never display it

      Student (Not in dorm)
        - Semester Plan
        - Only available if they have setup a recurring plan   
    */
    displayModifyPickupScheduleButton = ()=>{
        let displayModifyPickupScheduleButton = false;
        const {user,laundry} = this.props;
        let studentLivesInDormitory = false;
        let isStudent = true;
        if(user){
            const {dorm_non_dorm,customer_type} = user;
            studentLivesInDormitory = (dorm_non_dorm && dorm_non_dorm === 1) ?  true:false;
            isStudent = (customer_type && customer_type === 1) ? true:false;
            
        }

        //if student lives in a dorm then don't display it
        displayModifyPickupScheduleButton = isStudent && studentLivesInDormitory && false;
        
        //If student does not live in a dorm
        if(isStudent && !studentLivesInDormitory && !_.isEmpty(laundry)){
           //Check if the student has a recurring plan
           const {laundryOrder} = laundry;
           const hasRecurringPlan = this.doesStudentHaveRecurringLaundrySchedule(laundryOrder);
           
           //Iterate through laundryOrderDetails to see if there has been a pickup for this week 

           displayModifyPickupScheduleButton = hasRecurringPlan && true;
        } 

        return displayModifyPickupScheduleButton;
    }

    /*
       Student (In Dorm)
         - Never display it

       Student (Not in dorm)
        - If has a recurring plan then don't display it
        - If has a one time plan then we don't display it as they should cancel it and then get "Set Schedule" to redo it
        
        
    */
    displayModifyUpcomingPickupButton = ()=>{
        let displayModifyPickupScheduleButton = false;
        
        const {user,laundry} = this.props;
        let studentLivesInDormitory = false;
        let isStudent = true;
        if(user){
            const {dorm_non_dorm,customer_type} = user;
            studentLivesInDormitory = (dorm_non_dorm && dorm_non_dorm === 1) ?  true:false;
            isStudent = (customer_type && customer_type === 1) ? true:false;
            
        }

        //If student does not live in a dorm
        if(isStudent && !studentLivesInDormitory && this.isStudentNextPickupDeliveryScheduleAvailable()){
            //Check if the student has a recurring plan
            const {laundryOrder} = laundry;
            const hasRecurringPlan = this.doesStudentHaveRecurringLaundrySchedule(laundryOrder);
            const now = moment();
                    
            //If student has a recurring plan
            if(hasRecurringPlan){
                return false;
                /*
                //const now = moment('2020-01-22');
                
                //Iterate through laundryOrderDetails to see if there has been a pickup for this week 
                if(laundryOrder.LaundryOrderDetails.length > 0){
                    const weekNumber = now.week();
                    const laundryOrderDetail = _.find(laundryOrder.LaundryOrderDetails,(e)=>{
                        return (moment(e.date_week_start).week() === weekNumber)
                    });
                    //There has already been a pickup for this week so hide it
                    if(laundryOrderDetail){
                        return false;
                    }
                }
                else{
                    //Cannot modify until we pass the delivery date of this week
                    const {nextPickupDeliverySchedule} = laundry;
                    const nextPickupDate = moment(nextPickupDeliverySchedule.pickup.date);
                    const nextDeliveryDate = moment(nextPickupDeliverySchedule.delivery.date);
                    //Only display the change upcoming after the next delivery date
                    if(now.isSame(nextPickupDate,'day') || now.isSame(nextDeliveryDate,'day')){
                        return false;
                    }
                }
                */
            }
            //End if recurring plan

            //One time plan and has a pickup scheduled
            if(!hasRecurringPlan){
                //If has a one time plan then we don't display it as they should cancel it and 
                //Then they can change it one day before the scheduled date
                const {nextPickupDeliverySchedule} = laundry;
                const nextPickupDate = moment(nextPickupDeliverySchedule.pickup.date);
                if(now.isBefore(nextPickupDate,'day')){
                    return true;
                }   
                return false
            }

            //displayModifyPickupScheduleButton = true;
        }

        return displayModifyPickupScheduleButton;
    }

    /* This is when an existing user has a laundry plan and it checks if there is an upcoming laundry plan for renewal */
    displayRenewLaundryPlan = async ()=>{
       let renewLaundryPlanSemester  = false;
       const { user,laundry } = this.props;
       const {listOfSchoolsByRegionForLaundry} = this.state;
       if(!_.isEmpty(user) && !_.isEmpty(laundry) && !_.isEmpty(listOfSchoolsByRegionForLaundry)){
        const {campus_id:campusId} = user;
        const {semester_id: currentSemesterId} = laundry.semester;
        let region = _.find(listOfSchoolsByRegionForLaundry, {schools: [{id: campusId}] });
        if(!_.isEmpty(region)){
            const school = _.find(region.schools, {'id':campusId});
            if(school !== undefined){
                const {semesters} = school;
                if(semesters.length > 0){
                    const upcomingSemesterForSignup = _.find(semesters,s=>{
                    return (s.id != currentSemesterId && moment().isSameOrBefore(moment(s.startDateDb)))
                    }) 
                    renewLaundryPlanSemester = (!_.isEmpty(upcomingSemesterForSignup)) ? upcomingSemesterForSignup:false;
                }
            }
        }
       }
       return renewLaundryPlanSemester;
       
    }
    
    /*
     If the Student has no laundry package then display sign up button
    */
    displaySignUpForLaundryButton = ()=>{
       let displaySignUpForLaundryButton = false;
       const {laundry} = this.props;
       displaySignUpForLaundryButton = (laundry === undefined) ? true:false;

       return displaySignUpForLaundryButton;
    }
    
    /*
    If there is an upcoming schedule then display the button as they might want to make a notification change
    */
    displayUpdateEmailAndTextReminderButton = ()=>{
        let displayUpdateEmailAndTextReminderButton = false;
        displayUpdateEmailAndTextReminderButton = this.isStudentNextPickupDeliveryScheduleAvailable();
        return displayUpdateEmailAndTextReminderButton;
    }
    
    /*
      if laundry order has the pickup day and delivery day set 
    */
    doesStudentHaveRecurringLaundrySchedule = (laundryOrder)=>{
        let hasRecurringPlan = false;
        const {
            delivery_dayname,
            delivery_starttime,
            delivery_endtime,
            pickup_dayname,
            pickup_starttime,
            pickup_endtime,
        }  = laundryOrder
           
        hasRecurringPlan = pickup_dayname && pickup_endtime && true;

        return hasRecurringPlan

    }

    handleCancelUpcomingPickup = async ()=>{
        let myAccountState = {...this.state}
        let formData = {};
        const {laundry,user,handleSettingLaundryNextPickupDeliverySchedule,handleUpdateOfUserAndProductProperties} = this.props;
        
        myAccountState.processingModalDisplayFlag = true;
        myAccountState.cancelUpcomingModalDisplayFlag = false;
        this.setState(myAccountState);
        
        formData = {
            laundry,
            user
        };
        const {status:processingStatus,message,nextPickupDeliverySchedule} = await StudentService.cancelLaundryUpcomingPickup(formData);
        
        await handleUpdateOfUserAndProductProperties();
        handleSettingLaundryNextPickupDeliverySchedule(nextPickupDeliverySchedule);
        myAccountState.processingModalDisplayFlag = false;
        this.setState(myAccountState);
        if(processingStatus === true)
            customToast.success(message); 
        else if(processingStatus === false)
            customToast.error(message); 
            
    }

    handleCloseOfProcessingModalWindow = (flag)=>{
       const myAccountState = {...this.state}
       myAccountState.processingModalDisplayFlag = flag;
       this.setState(myAccountState);
    }

    /*
        Redirect user to /existing-user/signup/laundry/student/{schoolId}
    */
    handleConvertToSemesterPlanLaundryButton = (semesterId)=>{
        const { user } = this.props;
        const {campus_id:schoolId} = user;
        this.props.history.push(`/existing-user/signup/student/laundry/${schoolId}/${semesterId}/select-plan/change-to-semester-plan`);  
        return;
    }

    handleGetMeOnThisWeeksLaundryPickupSchedule = async ()=>{
        let myAccountState = {...this.state}
        let formData = {};
        const {laundry,user,handleSettingLaundryNextPickupDeliverySchedule,handleUpdateOfUserAndProductProperties} = this.props;
        
        myAccountState.processingModalDisplayFlag = true;
        this.setState(myAccountState);
        
        formData = {
            laundry,
            user
        };
        const {status:processingStatus,message,nextPickupDeliverySchedule} = await StudentService.getMeOnThisWeeksLaundryPickupSchedule(formData);
        
        await handleUpdateOfUserAndProductProperties();
        handleSettingLaundryNextPickupDeliverySchedule(nextPickupDeliverySchedule);
        myAccountState.processingModalDisplayFlag = false;
        this.setState(myAccountState);
        if(processingStatus === true)
            customToast.success(message); 
        else if(processingStatus === false)
            customToast.error(message); 
            
    }

    /*
        Redirect user to /existing-user/signup/laundry/student/{schoolId}
    */
    handleRenewLaundryButton = (semesterId)=>{
        const { user } = this.props;
        const {campus_id:schoolId} = user;
        this.props.history.push(`/existing-user/signup/student/laundry/${schoolId}/${semesterId}/select-plan`);  
        return;
    }

    //This is saving of the modified weekly/recurring schedule from the modal form
    handleSaveModifiedRecurringSchedule = async (modalFormData) =>{
        try{
            console.log(modalFormData);
            const myAccountState  = {...this.state};
            const {laundry,user,handleSettingLaundryNextPickupDeliverySchedule,handleUpdateOfUserAndProductProperties} = this.props;
            
            //Display processing modal
            myAccountState.processingModalDisplayFlag = true;
            this.setState(myAccountState);

            //Send form data to server
            let formData = modalFormData;
            formData.user = user;
            formData.laundry = laundry;
            //console.log(formData);
            
            const {status:processingStatus,message,nextPickupDeliverySchedule} = await StudentService.saveStudentLaundryModifiedRecurringSchedule(formData);
            const {processingModalDisplayFlag} = this.state;
            if( processingStatus === false){
                myAccountState.processingModalDisplayFlag = !processingModalDisplayFlag;
                this.setState(myAccountState);
                return customToast.error(message); 
            }
            
            if(!_.isEmpty(nextPickupDeliverySchedule) && processingStatus === true){
                await handleUpdateOfUserAndProductProperties();
                handleSettingLaundryNextPickupDeliverySchedule(nextPickupDeliverySchedule);
            }

            myAccountState.processingModalDisplayFlag = !processingModalDisplayFlag;
            myAccountState.displayModifyRecurringScheduleModalFlag = false;
            this.setState(myAccountState);

            if(!_.isEmpty(nextPickupDeliverySchedule) && processingStatus === true)
                customToast.success(message); 
            
            

        }
        catch(ex){
            console.log(ex);
        }
        
    }

    handleSaveModifyOneTimeUpcomingPickup = async (modalFormData) =>{
        try{
            console.log(modalFormData);
            const myAccountState  = {...this.state};
            const {laundry,user,handleSettingLaundryNextPickupDeliverySchedule,handleUpdateOfUserAndProductProperties} = this.props;
            
            //Display processing modal
            myAccountState.processingModalDisplayFlag = true;
            this.setState(myAccountState);

            //Send form data to server
            let formData = modalFormData;
            formData.user = user;
            formData.laundry = laundry;
            //console.log(formData);
            
            const {status:processingStatus,message,nextPickupDeliverySchedule} = await StudentService.saveStudentLaundryModifiedOneTimeUpcomingPickup(formData);
            const {processingModalDisplayFlag} = this.state;
            
            if( processingStatus === false){
                myAccountState.processingModalDisplayFlag = !processingModalDisplayFlag;
                return customToast.error(message); 
            }

            if(!_.isEmpty(nextPickupDeliverySchedule) && processingStatus === true){
                await handleUpdateOfUserAndProductProperties();
                handleSettingLaundryNextPickupDeliverySchedule(nextPickupDeliverySchedule);
            }

            myAccountState.processingModalDisplayFlag = !processingModalDisplayFlag;
            myAccountState.displayModifyOneTimeUpcomingPickupModalFlag = false;
            this.setState(myAccountState);

            if(!_.isEmpty(nextPickupDeliverySchedule) && processingStatus === true)
                customToast.success(message); 
            

        }
        catch(ex){
            console.log(ex);
        }
        
    }

    handleSaveDeliveryWaiverAndDriverInstructions = async (modalFormData) =>{
        try{
            const myAccountState  = {...this.state};
            const {laundry,user,handleUpdateOfUserAndProductProperties} = this.props;
        
        
            //Display processing modal
            myAccountState.processingModalDisplayFlag = true;
            this.setState(myAccountState);

            //Send form data to server
            let formData = modalFormData;
            formData.user = user;
            formData.laundry = laundry;
            console.log(formData);
            
            const {status:processingStatus,message} = await StudentService.saveStudentLaundryDeliveryWaiverAndDriverInstructions(formData);
            const {processingModalDisplayFlag} = this.state;
            
            if( processingStatus === false){
                myAccountState.processingModalDisplayFlag = !processingModalDisplayFlag;
                this.setState(myAccountState);
                return customToast.error(message); 
            }

            if(processingStatus === true){
                await handleUpdateOfUserAndProductProperties();
            }

            myAccountState.processingModalDisplayFlag = !processingModalDisplayFlag;
            myAccountState.displayModifyDeliveryWaiverAndDriverInstructionsModalFlag = false;
            this.setState(myAccountState);

            if(processingStatus === true)
                customToast.success(message); 
            

            
       }
       catch(ex){
        console.log(ex);
        }  
    }

    handleSaveSpecialRequestForUpcomingPickupModalFlag = async (modalFormData) =>{
       try{
        
            const myAccountState  = {...this.state};
            const {laundry,user,handleUpdateOfUserAndProductProperties} = this.props;
        
        
            //Display processing modal
            myAccountState.processingModalDisplayFlag = true;
            this.setState(myAccountState);

            //Send form data to server
            let formData = modalFormData;
            formData.user = user;
            formData.laundry = laundry;
            console.log(formData);
            
            const {status:processingStatus,message} = await StudentService.saveStudentLaundryCustomerNotesForUpcomingPickup(formData);
            if(processingStatus === true){
                await handleUpdateOfUserAndProductProperties();
            }

            const {processingModalDisplayFlag} = this.state;
            myAccountState.processingModalDisplayFlag = !processingModalDisplayFlag;
            myAccountState.displaySpecialRequestForUpcomingPickupModalFlag = false;
            this.setState(myAccountState);

            if(processingStatus === true)
                customToast.success(message); 
            else
                customToast.error(message); 

            
       }
       catch(ex){
        console.log(ex);
        }   
    }
    
    //This is saving of the schedule for the first time
    handleSetSchedule = async (modalFormData)=>{
        try{
            const myAccountState  = {...this.state};
            const {laundry,user,handleSettingLaundryNextPickupDeliverySchedule,handleUpdateOfUserAndProductProperties} = this.props;
        
        
            //Display processing modal
            myAccountState.processingModalDisplayFlag = true;
            this.setState(myAccountState);

            //Send form data to server
            let formData = modalFormData;
            formData.user = user;
            formData.laundry = laundry;
            //console.log(formData);
            const {status,nextPickupDeliverySchedule,message} = await StudentService.saveStudentLaundrySchedule(formData);
            //console.log(nextPickupDeliverySchedule);
            const {setScheduleFlag,processingModalDisplayFlag} = this.state;
            if(status === true && !_.isEmpty(nextPickupDeliverySchedule)){
                await handleUpdateOfUserAndProductProperties();
                handleSettingLaundryNextPickupDeliverySchedule(nextPickupDeliverySchedule);

                myAccountState.processingModalDisplayFlag = !processingModalDisplayFlag;
                myAccountState.setScheduleFlag = !setScheduleFlag;
                myAccountState.scheduleModalDisplayFlag = false;
                this.setState(myAccountState);
            }
            else{
                myAccountState.processingModalDisplayFlag = !processingModalDisplayFlag;
                customToast.error(message); 
                this.setState(myAccountState);
            }
            
        }
        catch(ex){
            console.log(ex);
        }
        
    }

    hasSemesterStarted = (laundryOrder)=>{
        const {date_start:semesterDateStart} = laundryOrder;
        return !moment().isBefore(moment(semesterDateStart));
    }

    isCurrentLaundryPackageAvailable = ()=>{
        let isCurrentLaundryPackageAvailable = false;
        const {laundry} = this.props;
        isCurrentLaundryPackageAvailable = (laundry === undefined) ? false:true;
        return isCurrentLaundryPackageAvailable;
    }
    
    isStudentNextPickupDeliveryScheduleAvailable = ()=>{
        let isStudentNextPickupDeliveryScheduleAvailable  = false;
        const {laundry} = this.props
        if(_.isObject(laundry)){
             const {nextPickupDeliverySchedule} = laundry;
             isStudentNextPickupDeliveryScheduleAvailable =  (_.isEmpty(nextPickupDeliverySchedule)) ? false:true;
        }
        return isStudentNextPickupDeliveryScheduleAvailable;
    }

    setCancelUpcomingPickupModalFlag = (flag)=>{
        this.setState({
            cancelUpcomingModalDisplayFlag:flag    
        })
    }

    setDisplayDoublePoundModalFlag = (flag)=>{
        this.setState({
            displayDoublePoundModalFlag:flag    
        })
    }

    setGarmentPricingModalFlag = (flag)=>{
        this.setState({
            displayGarmentPricingModalFlag:flag
        })
    }

    setDisplayModifyDeliveryWaiverAndDriverInstructionsModalFlag = (flag)=>{
        this.setState({
            displayModifyDeliveryWaiverAndDriverInstructionsModalFlag:flag    
        })
    }

    setDisplayModifyOneTimeUpcomingPickupModalFlag = (flag)=>{
        this.setState({
            displayModifyOneTimeUpcomingPickupModalFlag:flag    
        })
    }

    setDisplayModifyRecurringScheduleModalFlag = (flag)=>{
        this.setState({
            displayModifyRecurringScheduleModalFlag:flag    
        })
    }

    setDisplaySpecialRequestForUpcomingPickupModalFlag = (flag)=>{
        this.setState({
            displaySpecialRequestForUpcomingPickupModalFlag:flag    
        })
    }

    setScheduleModalDisplayFlag = (flag)=>{
        this.setState({
            scheduleModalDisplayFlag:flag    
        })
    }

    render(){
        
        const {
            displayFlag,
            displayConvertFromByThePoundToSemesterPlan,
            displayRenewLaundryPlan:displayRenewLaundryPlanInformation,
            displayRenewStoragePlan:displayRenewStoragePlanInformation,
            displayWelcomeModal,
            getRegistrationForUpcomingStorageSeason,
            handleOnClickOfEmailAndTextReminderLink,
            handleOnClickOfReserveStorageButton,
            handleOnClickOfAccountActivityButton,
            handleOnCloseOfWelcomeModal,
            laundry,
            listOfOneTimePickupDates,
            user,
            userNotifications,
        } = this.props;

        const {
            allowedToGetBackToCurrentWeekSchedule,
            semester,
        } = laundry || ""

        const currentSemesterId = (semester) ? semester.semester_id : ""
        
        let studentLivesInDormitory = false;
        let isStudent = true;
        if(user){
            const {dorm_non_dorm,customer_type} = user;
            studentLivesInDormitory = (dorm_non_dorm && dorm_non_dorm === 1) ?  true:false;
            isStudent = (customer_type && customer_type === 1) ? true:false
        }
         
        //console.log(laundry);
        const {
            completePendingActionFlag,
            cancelUpcomingModalDisplayFlag,
            displayDoublePoundModalFlag,
            displayGarmentPricingModalFlag,
            displayModifyDeliveryWaiverAndDriverInstructionsModalFlag,
            displayModifyRecurringScheduleModalFlag,
            displayModifyOneTimeUpcomingPickupModalFlag,
            displaySpecialRequestForUpcomingPickupModalFlag,
            processingModalDisplayFlag,
            scheduleModalDisplayFlag,
            setScheduleFlag,
            
        }  = this.state;  

        const laundryPlanEndDate = (laundry) ? new moment(laundry.laundryOrder.date_end).format("MMM Do"):false;
        //console.log(laundryPlanEndDate);

        //const displayRemainingWeeksForActiveSemester = (laundry && laundry.remainingWeeks) ? true:false;
        const displayRemainingWeeksForActiveSemester = false;

        const displayCSS = (displayFlag) ? "":"d-none";

        let isCurrentLaundryPackageAvailable = this.isCurrentLaundryPackageAvailable();
        
        //This is displayed when an existing user has no laundry plan in the past
        let displaySignUpForLaundryButton = this.displaySignUpForLaundryButton();
        
        let displayModifyPickupScheduleButton = this.displayModifyPickupScheduleButton();
        let displayAsapPickupButton = this.displayAsapPickupButton();
        
        let displayModifyUpcomingPickupButton = this.displayModifyUpcomingPickupButton();
        let displayCancelUpcomingPickupButton = this.displayCancelUpcomingPickupButton();
        let displayMakeSpecialRequestForUpcomingPickupButton = this.displayMakeSpecialRequestForUpcomingPickupButton();
        let displayDeliveryWaiverAndDriverInstructionButton = this.displayDeliveryWaiverAndDriverInstructionButton();
        let displayUpdateEmailAndTextReminderButton = this.displayUpdateEmailAndTextReminderButton();
        let displayDoublePoundsForMissedWeekButton = this.displayDoublePoundsForMissedWeekButton();

        //This is when an existing user has a laundry plan and it checks if there is an upcoming laundry plan for renewal
        //let displayRenewLaundryPlanSemester  = this.displayRenewLaundryPlan();
        let displayRenewLaundryPlanSemester  = displayRenewLaundryPlanInformation;
        let displayRenewLaundryPlanButton  = (!_.isEmpty(displayRenewLaundryPlanSemester)) ? true:false;
        
        //const upcomingStorageSeason = getRegistrationForUpcomingStorageSeason();
        const upcomingStorageSeason = displayRenewStoragePlanInformation;
        const isUpcomingStorageSeasonAvailable = (!_.isEmpty(upcomingStorageSeason)) ? true:false;

        return(
            <>
                <Row className={displayCSS}>
                    <Col>
                        <Card>
                            <CardBody>
                                <div>
                                    <div className="d-flex bd-highlight mb-3">
                                        <div className="mr-auto p-2 bd-highlight mt-2">
                                            <h6 className="card-category">
                                                <span className="text-left">
                                                Laundry Service: Details
                                                </span>
                                            </h6>
                                        </div>
                                        {   
                                            userNotifications &&  
                                            <div className="bd-highlight d-none d-lg-block">
                                                <UserNotificationWidget
                                                    {...this.props}
                                                />
                                            </div>
                                        }
                                        <div className="p-2 bd-highlight">
                                            {   displayRenewLaundryPlanButton && 
                                                <Button
                                                    className="btn-round mr-1"
                                                    color="danger"
                                                    type="button"
                                                    size="sm"
                                                    onClick={(e)=>{e.preventDefault(); this.handleRenewLaundryButton(displayRenewLaundryPlanSemester.id);}}
                                                >
                                                    <i className="nc-icon nc-bell-55 mr-1" />
                                                    {`Sign-up for ${displayRenewLaundryPlanSemester.semester}`}
                                                </Button>
                                            }
                                            
                                        </div>
                                        <div className="p-2 bd-highlight">
                                            {   displayConvertFromByThePoundToSemesterPlan && 
                                                <Button
                                                    className="btn-round mr-1"
                                                    color="danger"
                                                    type="button"
                                                    size="sm"
                                                    onClick={(e)=>{e.preventDefault(); this.handleConvertToSemesterPlanLaundryButton(currentSemesterId);}}
                                                >
                                                    <i className="nc-icon nc-bell-55 mr-1" />
                                                    {`Change to Semester Plan`}
                                                </Button>
                                            }
                                            
                                        </div>
                                    </div>
                                    <hr/>
        
                                    {
                                    completePendingActionFlag &&
                                    <Row>
                                        <Col xs="12">
                                            <div className="mlb-alert mlb-alert-primary" role="alert">
                                                <Row>
                                                    <Col xs="1" sm="1" className="fs-42 text-danger">
                                                            <i className="fa fa-exclamation-triangle fa-6" aria-hidden="true"></i>    
                                                    </Col>
                                                    <Col xs="7" sm="6">
                                                    <ul>
                                                        Following action needs to be taken:  
                                                        <li>
                                                            Your Student ID and Campus Address
                                                        </li> 
                                                        <li>Profile Information (Home address, Billing Address)</li> 
                                                        <li>Schedule Pickup and Delivery</li>  
                                                    </ul> 
                                                    </Col>
                                                    <Col xs="4" sm="5">
                                                            <Button
                                                            color="danger"
                                                            outline
                                                            size="sm"
                                                            type="button"
                                                            className="mr-1"
                                                            onClick={this.completePendingActions}
                                                        >
                                                                Setup my plan
                                                        </Button>
                                                    </Col> 
                                                    
                                                </Row>
                                                
                                            </div>
                                        </Col>
                                    </Row>
                                    }

                                    {   
                                        !displaySignUpForLaundryButton && 
                                        <>
                                            <Row>
                                                <Col md="4" className="br-solid">
                                                    {
                                                        !displaySignUpForLaundryButton && 
                                                        <Row>
                                                            <Col xs="9" sm="9" md="12">
                                                                <Row>
                                                                    <Col xs="6" sm="6" md="6">
                                                                        <strong>Semester</strong>
                                                                    </Col>
                                                                    <Col xs="6" sm="6" md="6">
                                                                        {laundry && `${laundry.season} ${laundry.semesterYear}`}
                                                                    </Col>
                                                                </Row>
                    
                                                                <Row className="mt-2">
                                                                    <Col xs="6" sm="6" md="6">
                                                                        <strong>Plan</strong>
                                                                    </Col>
                                                                    <Col xs="6" sm="6" md="6">
                                                                    {laundry && `${laundry.laundryPackageName}`}
                                                                    </Col>
                                                                </Row>
                                                                    
                                                                {
                                                                    displayRemainingWeeksForActiveSemester && 
                                                                    <Row className="mt-2 d-none">
                                                                        <Col xs="6" sm="6" md="6">
                                                                            <strong>Weeks Remaining</strong>
                                                                        </Col>
                                                                        <Col xs="6" sm="6" md="6">
                                                                            {laundry && `${laundry.remainingWeeks}`}
                                                                        </Col>
                                                                    </Row>  
                                                                }

                                                                {
                                                                    laundryPlanEndDate && 
                                                                    <Row className="mt-2">
                                                                        <Col xs="6" sm="6" md="6">
                                                                            <strong>End Date</strong>
                                                                        </Col>
                                                                        <Col xs="6" sm="6" md="6">
                                                                            {`${laundryPlanEndDate}`}
                                                                        </Col>
                                                                    </Row>  
                                                                }

                                                                <Row className="mt-2">
                                                                    <Col xs="6" sm="6" md="6">
                                                                        <strong>Insurance</strong>
                                                                    </Col>
                                                                    <Col xs="6" sm="6" md="6" className="align-text-top">
                                                                        <Row>
                                                                            <Col xs="12" style={{paddingLeft:"0px"}}>
                                                                                <Button className="text-danger" color="link" style={{paddingTop:"0px",paddingLeft:"13px"}} onClick={()=>this.setGarmentPricingModalFlag(true)}>
                                                                                    {laundry && `${laundry.insurance}`}   <i className="fa fa-info-circle mr-1" />  
                                                                                </Button>
                                                                                
                                                                            </Col>
                                                                        </Row>
                                                                        
                                                                        
                                                                    </Col>
                                                                </Row>
                    
                                                                <Row>
                                                                    <Col className="text-left">
                                                                        <a 
                                                                            href={`${process.env.REACT_APP_API_URL}/laundry/welcomePacket`}
                                                                            className="btn-link btn btn-info btn-sm pl-0"
                                                                        >
                                                                            <i className="fa fa-book" aria-hidden="true"></i>
                                                                            DOWNLOAD LAUNDRY WELCOME PACKET
                                                                        </a>
                                                                    </Col>
                                                                </Row>
                    
                                                                <Row>
                                                                    <Col className="text-left">
                                                                        <Button
                                                                            className="btn-link"
                                                                            color="info"
                                                                            size="sm"
                                                                            type="button"
                                                                            style={{paddingLeft:"0px"}}
                                                                            onClick={handleOnClickOfAccountActivityButton}
                                                                        >
                                                                            <i className="fa fa-table" aria-hidden="true"></i>
                                                                            Billing Details
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                    
                                                                
                                                            </Col>
                                                            <Col xs="3" sm="3" className="align-middle d-block d-md-none">
                                                                <img  
                                                                    className="img-rounded img-responsive"
                                                                    src={require("../../../../../assets/mylazybones/img/MyAccount/Laundry/Laundry_Myaccount_Landing.jpg")}
                                                                    
                                                                />
                                                            </Col>
                                                        </Row>
                                                    }

                                                    {
                                                        
                                                        displaySignUpForLaundryButton && 
                                                        <Row>
                                                            <Col xs="9" sm="9" md="12">
                                                                <Row>
                                                                    <Col className="text-left">
                                                                        <div>
                                                                            <h6>
                                                                                Your Laundry Package Details:
                                                                            </h6>
                                                                        </div>
                                                                        <div className="mt-2">
                                                                            <Button
                                                                                outline
                                                                                color="info"
                                                                                size="sm"
                                                                                type="button"
                                                                                style={{paddingLeft:"0px"}}
                                                                                className="btn-block"
                                                                            >
                                                                                <i className="fa fa-shopping-basket" aria-hidden="true"></i>
                                                                                Signup
                                                                            </Button>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col xs="3" sm="3" className="align-middle d-block d-md-none">
                                                                <img  
                                                                    className="img-rounded img-responsive"
                                                                    src={require("../../../../../assets/mylazybones/img/MyAccount/Laundry/Laundry_Myaccount_Landing.jpg")}
                                                                    
                                                                />
                                                            </Col>
                                                        </Row>

                                                    }
                                                    
                                                        
                                                    
                                                </Col>
                                                
                                                
                                                <Col md="4" className="br-solid">
                                                    <div className="d-block d-md-none">
                                                        <hr/>
                                                    </div>
                                                    {   isCurrentLaundryPackageAvailable && 
                                                        <div>
                                                            <h6>
                                                                Your Delivery/Pickup schedule
                                                            </h6>
                                                        </div>
                                                    }
                                                    {
                                                    setScheduleFlag && isCurrentLaundryPackageAvailable &&   
                                                    <div className="mt-2">
                                                        <Button
                                                                color="info"
                                                                outline
                                                                size="sm"
                                                                type="button"
                                                                className="mr-1"
                                                                onClick={()=>this.setScheduleModalDisplayFlag(true)}
                                                        >
                                                            <i className="fa fa-calendar" aria-hidden="true"></i>
                                                            Set Schedule
                                                        </Button>
                                                    </div>
                                                    }
                                                    {
                                                    !setScheduleFlag && isCurrentLaundryPackageAvailable &&    
                                                    <div className="mt-2">
                                                        <Row className="mt-2">
                                                            <Col xs="4" sm="6" md="4">
                                                                <strong>Next pick-up</strong>
                                                            </Col>
                                                            <Col xs="8" sm="6" md="8">
                                                                {(laundry && !_.isEmpty(laundry.nextPickupDeliverySchedule.pickup)) ? `${laundry.nextPickupDeliverySchedule.pickup.displayDate} ${laundry.nextPickupDeliverySchedule.pickup.startTime} - ${laundry.nextPickupDeliverySchedule.pickup.endTime}`:""}
                                                            </Col>
                                                        </Row>
                                                        <Row className="mt-2">
                                                            <Col xs="4" sm="6" md="4">
                                                                <strong>Next delivery</strong>
                                                            </Col>
                                                            <Col xs="8" sm="6" md="8">
                                                                {(laundry && !_.isEmpty(laundry.nextPickupDeliverySchedule.delivery)) ? `${laundry.nextPickupDeliverySchedule.delivery.displayDate} ${laundry.nextPickupDeliverySchedule.delivery.startTime} - ${laundry.nextPickupDeliverySchedule.delivery.endTime}`:""}
                                                            </Col>
                                                        </Row>
                                                        
                                                    </div>
                                                    } 
                                                    
                                                    {/*This is only to handle one time pickups changes, recurring upcoming cannot be changed  */}
                                                    {
                                                        displayModifyUpcomingPickupButton &&
                                                        <div className="mt-2">
                                                            <Row className="mt-2">
                                                                <Col xs="12" sm="10" md="10">
                                                                <Button
                                                                    color="info"
                                                                    outline
                                                                    size="sm"
                                                                    type="button"
                                                                    className="mr-1 btn-block"
                                                                    onClick={()=>{this.setDisplayModifyOneTimeUpcomingPickupModalFlag(true)}}    
                                                                >
                                                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                                                                    Change Upcoming Pickup
                                                                </Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    }

                                                    {
                                                        displayCancelUpcomingPickupButton &&
                                                        <div className="mt-2">
                                                            <Row className="mt-2">
                                                                <Col xs="12" sm="10" md="10">
                                                                    <Button
                                                                        color="info"
                                                                        outline
                                                                        size="sm"
                                                                        type="button"
                                                                        className="mr-1 btn-block"
                                                                        onClick={()=>{this.setCancelUpcomingPickupModalFlag(true)}}
                                                                    >
                                                                        <i className="fa fa-ban" aria-hidden="true"></i>
                                                                        Cancel Pickup
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        
                                                    }

                                                    {
                                                        allowedToGetBackToCurrentWeekSchedule && 
                                                        <div className="mt-2">
                                                            <Row className="mt-2">
                                                                <Col xs="12" sm="10" md="10">
                                                                    <Button
                                                                        color="info"
                                                                        outline
                                                                        size="sm"
                                                                        type="button"
                                                                        className="mr-1 btn-block"
                                                                        onClick={()=>{this.handleGetMeOnThisWeeksLaundryPickupSchedule()}}
                                                                    >
                                                                        <i className="fa fa-calendar" aria-hidden="true"></i>
                                                                        Get me on this weeks pickup
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    }

                                                    {
                                                        displayMakeSpecialRequestForUpcomingPickupButton &&
                                                        <div className="mt-2">
                                                            <Row className="mt-2">
                                                                <Col xs="12" sm="10" md="10">
                                                                    <Button
                                                                        color="info"
                                                                        outline
                                                                        size="sm"
                                                                        type="button"
                                                                        className="mr-1 btn-block"
                                                                        onClick={()=>{this.setDisplaySpecialRequestForUpcomingPickupModalFlag(true)}}
                                                                    >
                                                                        <i className="fa fa-comment" aria-hidden="true"></i>
                                                                        Laundry/Dryclean Requests
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        
                                                    }                   
                                                </Col>
                
                                                <Col md="3" className="align-middle d-none d-md-block">
                                                    <img  
                                                        className="img-rounded img-responsive"
                                                        src={require("../../../../../assets/mylazybones/img/MyAccount/Laundry/Laundry_Myaccount_Landing.jpg")}
                                                        
                                                    />
                                                        
                                                
                                
                                                </Col>
                                            </Row>
                
                                            <div className="d-none d-md-block">
                                                <hr/>
                                            </div>
                
                                            <Row>
                                                <Col md="4" className="br-solid">
                                                    <div className="d-block d-md-none">
                                                        <hr/>
                                                    </div>
                                                    <div>
                                                        <h6>
                                                            {displayModifyPickupScheduleButton && displayAsapPickupButton && "Make a Change?"}
                                                        </h6>
                                                    </div>
                                                    <div className="mt-2">
                                                        {
                                                            displayModifyPickupScheduleButton && 
                                                            <Row>
                                                                <Col className="mb-2">
                                                                    <Button
                                                                        color="info"
                                                                        outline
                                                                        size="sm"
                                                                        type="button"
                                                                        className="mr-1"
                                                                        onClick = {()=>this.setDisplayModifyRecurringScheduleModalFlag(true)}
                                                                    >
                                                                        <i className="fa fa-calendar" aria-hidden="true"></i>
                                                                        Modify Weekly Schedule
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        }

                                                        
                                                        {
                                                            displayAsapPickupButton && 
                                                            <Row>
                                                                <Col className="mb-2">
                                                                    <Button
                                                                        color="info"
                                                                        outline
                                                                        size="sm"
                                                                        type="button"
                                                                        className="mr-1"
                                                                    >
                                                                        <i className="fa fa-bolt" aria-hidden="true"></i>
                                                                        ASAP Pick Up
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        
                                                        
                                                        
                                                        
                                                    </div>     
                                                    
                                                </Col>
                                                
                                                
                                                <Col md="4" className="br-solid">
                                                    <div className="d-block d-md-none">
                                                        <hr/>
                                                    </div>
                                                    {
                                                        (
                                                            displayDeliveryWaiverAndDriverInstructionButton || 
                                                            displayDoublePoundsForMissedWeekButton || 
                                                            displayUpdateEmailAndTextReminderButton 
                                                        ) && 
                                                    
                                                            <div>
                                                                <h6>
                                                                    Requests
                                                                </h6>
                                                            </div>
                                                    }
                                                    
                                                            <div className="mt-2">
                                                                {
                                                                    displayDeliveryWaiverAndDriverInstructionButton && 
                                                                    <Row>
                                                                        <Col>
                                                                            <Button
                                                                                className="btn-link mr-1"
                                                                                color="info"
                                                                                href="#pablo"
                                                                                onClick={e => this.setDisplayModifyDeliveryWaiverAndDriverInstructionsModalFlag(true)}
                                                                                style={{textTransform:"none",paddingLeft:"0px",textAlign:"left"}}
                                                                            >
                                                                                <i className="fa fa-caret-right" aria-hidden="true"></i>
                                                                                Update Delivery Waiver & Driver Instructions
                                                                            </Button> 
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                                
                                                                {
                                                                    displayUpdateEmailAndTextReminderButton && 
                                                                    <Row>
                                                                        <Col>
                                                                            <Button
                                                                                className="btn-link mr-1"
                                                                                color="info"
                                                                                onClick={e => handleOnClickOfEmailAndTextReminderLink(true)}
                                                                                style={{textTransform:"none",paddingLeft:"0px",textAlign:"left"}}
                                                                            >
                                                                                <i className="fa fa-caret-right" aria-hidden="true"></i>
                                                                                Update Text reminder setting
                                                                            </Button> 
                                                                        </Col>
                                                                    </Row>    
                                                                }
                                                                
                                                                {
                                                                    displayDoublePoundsForMissedWeekButton &&
                                                                    <Row>
                                                                        <Col>
                                                                            <Button
                                                                                className="btn-link mr-1"
                                                                                color="info"
                                                                                href="#pablo"
                                                                                onClick={e => this.setDisplayDoublePoundModalFlag(true)}
                                                                                style={{textTransform:"none",paddingLeft:"0px",textAlign:"left"}}
                                                                            >
                                                                                <i className="fa fa-caret-right" aria-hidden="true"></i>
                                                                                Double pounds for missed week
                                                                            </Button> 
                                                                        </Col>
                                                                    </Row>   
                                                                }
                                                                
                                                            </div>                    
                                                        
                                                    
                                                    
                                                </Col>
                
                                                <Col md="4" className="">
                                                    <div className="d-block d-md-none">
                                                        <hr/>
                                                    </div>
                                                    <div>
                                                        <h6>
                                                            Additional Services
                                                        </h6>
                                                    </div>
                                                    <div className="mt-2 ">
                                                        <Row>
                                                            <Col xs="2" md="3">
                                                                <img
                                                                    alt="..."
                                                                    className="img-responsive"
                                                                    src={require("assets/mylazybones/img/order_packing_supplies.jpg")}
                                                                    style={{width:"50px",height:"50px"}}
                                                                />
                                                            </Col>
                                                            <Col xs="8">
                                                                <Button
                                                                    className="btn-link mr-1"
                                                                    color="info"
                                                                    onClick={e => {e.preventDefault();
                                                                    return this.props.history.push("/supplies");
                                                                    }}
                                                                    style={{textTransform:"none",paddingLeft:"0px",textAlign:"left"}}
                                                                >
                                                                    Order packing supplies
                                                                </Button> 
                                                            </Col>
                
                                                        </Row>

                                                        {
                                                            isUpcomingStorageSeasonAvailable && 
                                                            <>
                                                                <Row>
                                                                    <Col xs="2" md="3">
                                                                        <img
                                                                            alt="..."
                                                                            className="img-responsive"
                                                                            src={require("assets/mylazybones/img/storage_box.jpg")}
                                                                            style={{width:"50px",height:"50px"}}
                                                                        />
                                                                    </Col>
                                                                    <Col xs="8">
                                                                        <Button
                                                                            className="btn-link mr-1"
                                                                            color="info"
                                                                            onClick={(e)=>handleOnClickOfReserveStorageButton(e)}
                                                                            style={{textTransform:"none",paddingLeft:"0px",textAlign:"left"}}
                                                                        >
                                                                            Reserve your storage space
                                                                        </Button> 
                                                                    </Col>
                                                                </Row>     
                                                            </> 
                                                        }

                                                        {   
                                                            !isUpcomingStorageSeasonAvailable && 
                                                            <>
                                                                <Row>
                                                                    <Col xs="2" md="3">
                                                                        <img
                                                                            alt="..."
                                                                            className="img-responsive"
                                                                            src={require("assets/mylazybones/img/storage_box.jpg")}
                                                                            style={{width:"50px",height:"50px"}}
                                                                        />
                                                                    </Col>
                                                                    <Col xs="8">
                                                                        <Button
                                                                            className="btn-link mr-1"
                                                                            color="info"
                                                                            onClick={(e)=>this.props.history.push("/myaccount/student/storage")}
                                                                            style={{textTransform:"none",paddingLeft:"0px",textAlign:"left"}}
                                                                        >
                                                                            View Storage Service
                                                                        </Button> 
                                                                    </Col>
                                                                </Row>     
                                                            </> 
                                                        }
                                                        
                                                        
                                                        
                                                    </div> 
                                                        
                                                
                                
                                                </Col>
                                            </Row>
                                                
                                        </>
                                    }

                                    {
                                        displaySignUpForLaundryButton && 
                                        <div className="min-height-sm">
                                            <Row>
                                                <Col xs="9">
                                                    <div className="mlb-alert mlb-alert-primary" role="alert">
                                                        <Row>
                                                            <Col xs="1" sm="1" className="fs-42 text-danger d-none d-sm-block">
                                                                    <i className="fa fa-exclamation-triangle fa-6" aria-hidden="true"></i>    
                                                            </Col>
                                                            <Col>
                                                            <strong>You currently don't have an laundry plan with us or your plan has expired. If this is an error then please contact Lazybones and we will address the issue.</strong>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                                <Col xs="2" >
                                                    <img  
                                                        className="img-rounded img-responsive"
                                                        src={require("../../../../../assets/mylazybones/img/MyAccount/Laundry/Laundry_Myaccount_Landing.jpg")}
                                                        
                                                    />
                                                </Col>
                                            </Row>
                                            <div>
                                                <hr/>
                                            </div>
                                            <Row>
                                                <Col xs="12" md="6">
                                                    <div>
                                                        <h6>
                                                            Additional Services
                                                        </h6>
                                                    </div>
                                                    <div className="mt-2 ">
                                                        <Row>
                                                            <Col xs="2" md="3">
                                                                <img
                                                                    alt="..."
                                                                    className="img-responsive"
                                                                    src={require("assets/mylazybones/img/MyAccount/Supplies/supplies_landing.jpg")}
                                                                    style={{width:"50px",height:"50px"}}
                                                                />
                                                            </Col>
                                                            <Col xs="8">
                                                                <Button
                                                                    className="btn-link mr-1"
                                                                    color="info"
                                                                    onClick={e => {e.preventDefault();
                                                                    return this.props.history.push("/supplies");
                                                                    }}
                                                                    style={{textTransform:"none",paddingLeft:"0px",textAlign:"left"}}
                                                                >
                                                                    Order packing supplies
                                                                </Button> 
                                                            </Col>
                
                                                        </Row>

                                                        {
                                                            isUpcomingStorageSeasonAvailable && 
                                                            <>
                                                                <Row>
                                                                    <Col xs="2" md="3">
                                                                        <img
                                                                            alt="..."
                                                                            className="img-responsive"
                                                                            src={require("assets/mylazybones/img/MyAccount/Storage/storage_package.jpg")}
                                                                            style={{width:"50px",height:"50px"}}
                                                                        />
                                                                    </Col>
                                                                    <Col xs="8">
                                                                        <Button
                                                                            className="btn-link mr-1"
                                                                            color="info"
                                                                            onClick={(e)=>handleOnClickOfReserveStorageButton(e)}
                                                                            style={{textTransform:"none",paddingLeft:"0px",textAlign:"left"}}
                                                                        >
                                                                            Reserve your storage space
                                                                        </Button> 
                                                                    </Col>
                                                                </Row>     
                                                            </> 
                                                        }

                                                        {   
                                                            !isUpcomingStorageSeasonAvailable && 
                                                            <>
                                                                <Row>
                                                                    <Col xs="2" md="3">
                                                                        <img
                                                                            alt="..."
                                                                            className="img-responsive"
                                                                            src={require("assets/mylazybones/img/storage_box.jpg")}
                                                                            style={{width:"50px",height:"50px"}}
                                                                        />
                                                                    </Col>
                                                                    <Col xs="8">
                                                                        <Button
                                                                            className="btn-link mr-1"
                                                                            color="info"
                                                                            onClick={(e)=>this.props.history.push("/myaccount/student/storage")}
                                                                            style={{textTransform:"none",paddingLeft:"0px",textAlign:"left"}}
                                                                        >
                                                                            View Storage Service
                                                                        </Button> 
                                                                    </Col>
                                                                </Row>     
                                                            </> 
                                                        }
                                                        
                                                        
                                                        
                                                    </div> 
                                                        
                                                
                                
                                                </Col>
                                            </Row>
                                            
                                        </div>
                                        
                                    }

                                </div> 
        
                            
                                
                            </CardBody>
                        </Card>
                    </Col> 
                </Row>

                <Modal isOpen={displayWelcomeModal} toggle={() => handleOnCloseOfWelcomeModal(false)(false)}>
                  <div className="modal-header no-border-header">
                    <button
                      className="close"
                      type="button"
                      onClick={() => handleOnCloseOfWelcomeModal(false)(false)}
                    >
                      ×
                    </button>
                    <h4 className="modal-title animated bounce text-success modal-myaccount-header-animation" id="myModalLabel">
                      <i className="fa fa-thumbs-o-up" aria-hidden="true"></i> Congratulations!
                    </h4>
                    
                  </div>
                  <div className="modal-body">
                    
                    <p>
                    You have successfully created your Lazybones Laundry Account. <br/>Next, please click <Button
                        className="mr-1"
                        color="link"
                        onClick={(e) =>{
                            handleOnCloseOfWelcomeModal(false)
                            return this.setScheduleModalDisplayFlag(true);
                        }}
                      >
                        'Set Schedule'
                      </Button> to schedule your laundry pickup/delivery day for the semester. 
                      
                    </p>  
                    
                  </div>
                  <div className="modal-footer">
                    <Button
                      className="btn-link"
                      color="primary"
                      type="button"
                      onClick={() => handleOnCloseOfWelcomeModal(false)}
                    >
                      Close
                    </Button>
                  </div>
                </Modal>

                {/* Set Processing modal */}
                <ProcessingModal
                    displayModal={processingModalDisplayFlag}
                    handleCloseOfModalWindow={this.handleCloseOfProcessingModalWindow}
                />
                {/* End of Processing modal */}

                {/* Set Garment Insurance modal */}
                <GarmentPricingListModal
                    modalDisplayFlag={displayGarmentPricingModalFlag}
                    handleModalDisplay={this.setGarmentPricingModalFlag}
                />
                {/* End of Garment Insurance modal */}
            
                {/* Set Schedule modal */}
                <StudentAccountLaundrySchedulingModal
                    scheduleModalDisplay = {scheduleModalDisplayFlag} 
                    handleScheduleModalDisplay = {this.setScheduleModalDisplayFlag}
                    handleSetSchedule = {this.handleSetSchedule}
                    listOfOneTimePickupDates={listOfOneTimePickupDates}
                    laundryOrder = {laundry}
                    user={user}
                />
                {/* End of Schedule modal */}

                
                {/* Modify recurring schedule modal */}
                <StudentAccountLaundryModifyRecurringScheduleModal
                    modalDisplayFlag = {displayModifyRecurringScheduleModalFlag}
                    handleModalDisplayFlag = {this.setDisplayModifyRecurringScheduleModalFlag}
                    handleSaveModifyRecurringSchedule = {this.handleSaveModifiedRecurringSchedule}
                    laundryOrder = {laundry}
                    user={user}
                />

                {/* Modify one time upcoming pickup modal */}
                <StudentAccountLaundryModifyOneTimeUpcomingPickupModal
                    modalDisplayFlag = {displayModifyOneTimeUpcomingPickupModalFlag}
                    handleModalDisplayFlag = {this.setDisplayModifyOneTimeUpcomingPickupModalFlag}
                    handleSaveOperation = {this.handleSaveModifyOneTimeUpcomingPickup}
                    laundryOrder = {laundry}
                    user={user}
                />
                

                {/*Cancel upcoming pickup modal */}
                <StudentAccountCancelUpcomingPickupModal
                    modalDisplayFlag={cancelUpcomingModalDisplayFlag}
                    handleModalDisplayFlag = {this.setCancelUpcomingPickupModalFlag}
                    handleCancelUpcomingPickup={this.handleCancelUpcomingPickup}
                    laundryOrder = {laundry}
                    user={user}
                />

                {/* Make special requests for Upcoming pickup */}
                <StudentAccountLaundryUpcomingPickupSpecialRequest
                    modalDisplayFlag = {displaySpecialRequestForUpcomingPickupModalFlag}
                    handleModalDisplayFlag = {this.setDisplaySpecialRequestForUpcomingPickupModalFlag}
                    handleSaveOperation = {this.handleSaveSpecialRequestForUpcomingPickupModalFlag}
                    laundryOrder = {laundry}
                    user={user}
                />

                {/* Modify Delivery Waiver and Driver Instructions */}
                <StudentAccountLaundryModifyDeliveryWaiverAndDriverInstructions
                    modalDisplayFlag = {displayModifyDeliveryWaiverAndDriverInstructionsModalFlag}
                    handleModalDisplayFlag = {this.setDisplayModifyDeliveryWaiverAndDriverInstructionsModalFlag}
                    handleSaveOperation = {this.handleSaveDeliveryWaiverAndDriverInstructions}
                    laundryOrder = {laundry}
                    user={user}
                />

                <StudentAccountLaundryDoublePoundModal
                    modalDisplayFlag = {displayDoublePoundModalFlag}
                    handleModalDisplayFlag = {this.setDisplayDoublePoundModalFlag}
                />

            </>
        );
    }

}



export default StudentAccountLaundryDetail;