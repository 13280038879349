import React from "react";

import {
    Media,
    
} from "reactstrap";

const Plan = ()=>{
    return(
    <>
        <Media>
            <Media body>
            <Media heading>When do the laundry service plans start/end? </Media>
            
            <p>
                For students, laundry service starts the first week of classes and ends the last week of the semester. The sooner you sign up, the sooner your laundry pickups can begin.    
            </p>
            </Media>
        </Media>

        <Media>
            <Media body>
            <Media heading>What if I want to buy a laundry plan after the semester has already started? </Media>
            
            <p>
            You can sign up for a laundry plan at any point during the semester and the plan will be prorated according to your start date.  
            </p>
            </Media>
        </Media>

        <Media>
            <Media body>
            <Media heading>How do I know which laundry plan is right for me?</Media>
            
            <p>
            The typical household washing machine holds 7 or 8 pounds of laundry per load. The chart below can give you an idea of how much laundry fits into each of our plans.
            </p>
            
                <table cellPadding="5px" border="1">
                    <thead>
                        <tr>
                            <th>10lbs Per Week</th>
                            <th>15lbs Per Week</th>
                            <th>20lbs Per Week</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>2 pairs of Jeans</td>
                            <td>3 pairs of Jeans</td>
                            <td>4 pairs of Jeans</td>
                        </tr>
                        <tr>
                            <td>1 button-down shirt</td>
                            <td>2 button-down shirts</td>
                            <td>3 button-down shirts</td>
                        </tr>
                        <tr>
                            <td>1 pair of shorts</td>
                            <td>2 pair of shorts</td>
                            <td>2 pair of shorts</td>
                        </tr>
                        <tr>
                            <td>5 t-shirts</td>
                            <td>5 t-shirts</td>
                            <td>6 t-shirts</td>
                        </tr>
                        <tr>
                            <td>7 pairs of underwear</td>
                            <td>9 pairs of underwear</td>
                            <td>9 pairs of underwear</td>
                        </tr>
                        <tr>
                            <td>1 towel</td>
                            <td>2 towels</td>
                            <td>2 towels </td>
                        </tr>
                        <tr>
                            <td>7 pairs of socks</td>
                            <td>7 pairs of socks</td>
                            <td>8 pairs of socks</td>
                        </tr>
                    </tbody>
                </table>
            

            </Media>
        </Media>
        
        <Media>
            <Media body>
            <Media heading>What if I want laundry service, but don't need a pick up every week?</Media>
            
            <p>
            We offer a pay by the pound option that is perfect for students who don't want a weekly plan. 
            </p>
            </Media>
        </Media>
        <Media>
            <Media body>
            <Media heading>When do non-student laundry plans begin?</Media>
            
            <p>
            For our residential customers, laundry service begins and ends whenever you request it. 
            </p>
            </Media>
        </Media>
        <Media>
            <Media body>
            <Media heading>Are laundry services still available during holidays?</Media>
            
            <p>
            There is no laundry service available, during most observed holidays. Please call or email for specific dates. 
            </p>
            </Media>
        </Media>
        <Media>
            <Media body>
            <Media heading>Is there laundry service during university breaks?</Media>
            
            <p>
            Laundry service during university breaks varies. Please call or email for specific schools. 
            </p>
            </Media>
        </Media>
        
    </>
    );
}

export default Plan;
