import React, {useEffect} from "react";
import _ from "lodash";
import {Elements} from 'react-stripe-elements';


// core components
import ShortNavbar from "../Navbars/ShortNavbar.js";
import SignupPageHeader from "../Headers/SignupPageHeader";
import FooterBlack from "../Footers/FooterBlack.js";

import SelectSchool from "./SignupSteps/Laundry/SelectSchool";
import StudentInformation from "./SignupSteps/Laundry/StudentInformation";
import StudentSemesterAndPricingLaundry from "./SignupSteps/Laundry/StudentSemesterAndPricingLaundry";
import LoginAndPaymentInformation from "./SignupSteps/Common/LoginAndPaymentInformation";
import ReviewScreen from "./SignupSteps/Laundry/ReviewScreen";

const proceedToLoginAndPaymentInformation = user=>{
  let proceed  = false;

  const { laundry } = user; 
  const {student:laundryProperties} = laundry; 
  const {insurance,laundryType,pricePlan,semester} = laundryProperties;
  proceed  = (
    proceedToStudentInformationStep(user) && 
    proceedToSemesterPricingStep(user) &&
    insurance !== "" && laundryType !== "" && pricePlan !== "" && semester !== ""
  ) ? true:false;
     
  return proceed;
}

const proceedToReviewScreen = user=>{
  let proceed  = false;

  const { paymentInformation } = user; 
  const { creditCardStripeToken } = paymentInformation;
  
  proceed  = (
    proceedToStudentInformationStep(user) && 
    proceedToSemesterPricingStep(user) &&
    creditCardStripeToken !== ""
  ) ? true:false;

  return proceed;

}

const proceedToSemesterPricingStep = user=>{
  let proceed  = false;

  const { firstName,lastName,email } = user; 
  proceed  = (proceedToStudentInformationStep(user) && firstName !== "" && lastName !== "" && email !== "") ? true:false;
     
  return proceed;
}

const proceedToStudentInformationStep = user=>{
  let proceed  = false;
  const {school} = user;
  
  proceed  = (!_.isEmpty(school)) ? true:false;

  return proceed;
}

const validateToRenderComponent = (props)=>{
  let proceed = false;

  const {user} = props;
  const {step} = user;
  
  switch(step){
    case "select-school":                     
            proceed = true;
            break;
    case "enter-student-information":         
            proceed = proceedToStudentInformationStep(user);
            break;
    case "select-semester-pricing":           
            proceed = proceedToSemesterPricingStep(user);
            break;
    case "enter-login-payment-information":   
            proceed = proceedToLoginAndPaymentInformation(user);
            break;
    case "review-screen":                     
            proceed = proceedToReviewScreen(user);
            break;
    default:    
            proceed = false;
  }

  return proceed;
}

function renderComponent(props){
  const {user} = props;
  const {step} = user;
  let component = <SelectSchool  {...props}  />

  switch(step){
    case "select-school":                     
            component= <SelectSchool {...props}  />;
            break;
    case "enter-student-information":         
            component= <StudentInformation {...props}  />;
            break;
    case "select-semester-pricing":           
            component= <StudentSemesterAndPricingLaundry {...props}  />;
            break;
    case "enter-login-payment-information":   
            component= <Elements><LoginAndPaymentInformation {...props} /></Elements>;
            break;
    case "review-screen":                     
            component= <ReviewScreen {...props}  />;
            break;
    default:    
            component= <SelectSchool {...props}  />;
  }
  return component;
}

function SignupLaundryStudent(props) {
  const {user} = props;
  
  useEffect(() => {
    const proceed = validateToRenderComponent(props)
    if(!proceed) props.history.push("/signup/laundry/student/select-school")
  });
  
  //console.log(props);
  return (
    <>
      <ShortNavbar {...props} />
      <SignupPageHeader />
      <div className="main">
        <div id="sectionArea" className="section section-gray">
          {renderComponent(props)}
        </div>
        
      </div>
      <FooterBlack />
    </>
  );
}

export default SignupLaundryStudent;
