import React,{Component} from "react";

import ShortNavbar from "../../components/Navbars/ShortNavbar";
import GeneralPageHeader from "../../components/Headers/GeneralPageHeader";
import ForgotPasswordForm from "../../components/Authentication/ForgotPasswordForm";
import FooterBlack from "../../components/Footers/FooterBlack";

import {
    Row,
    Col,
    Container,
    
} from "reactstrap";

class ForgotPassword extends Component{
    constructor(){
        super();

        
    }

    render(){
        return(
            <>
                <ShortNavbar {...this.props}/>
                <GeneralPageHeader />  

                <div className="main">
                    <div id="sectionArea" className="section section-gray" style={{paddingTop:"0px"}}>
                        <Container id="mainContentArea">
                            <Row className="title-row justify-content-between">
                                <Col lg="5">
                                    
                                </Col>
                            </Row> 
                            <Row className="title-body-row mt-2">
                                <Col>
                                    <ForgotPasswordForm 
                                       {...this.props}
                                    />
                                </Col>
                            </Row>
                            
                        </Container>
                    </div>
                </div>
                <FooterBlack />
                
            </>
        );
    }
}

export default ForgotPassword;