import React from "react";

import {
    Media,
    
} from "reactstrap";

const studentData = [
    {
        heading:"Will Lazybones ship my stuff?",
        body:<p>
        Yes!  We will pick up items from your home and ship them anywhere you desire.
        </p>
    },
    {
        heading:"How much does it cost to ship?",
        body:<p>
        Lazybones uses UPS for all shipped items. Ship-only customers will be charged a $35 pickup fee plus a 20% handling fee above the UPS cost to ship. Storage customers are not subject to the $35 pickup fee. Because, shipping is based on the number of items to be shipped and the size, weight and destination of those items, Lazybones cannot give you an exact price for shipping.
        </p>,
    },
    {
        heading:"How can I get an idea of how much my shipping will cost?",
        body:<p>
            You can get a rough estimate of your shipping cost(s) by going to UPS.com and entering specific details about the item(s) you want shipped. Be sure to enter the box dimensions as well as the weight. If you only enter the weight, it will not calculate the price correctly. You must enter the length at its longest, the width at its widest and the height at its tallest point. You will also need to enter your campus zip code. When prompted to enter the box size, you must be sure to add 1” to every side because UPS specs are based on EXTERIOR dimensions. Once you have obtained an estimate from the UPS website, please remember that our prices will also include an additional 20% service charge, and a $35 pickup fee for shipping-only customers.
        </p>,
    },
    {
        heading:"What is the 20% shipping service charge for?",
        body:<p>
        The 20% shipping service charge includes our cost for labor, transport and handling of your items. Please note, shipping costs are separate from storage costs. These are two separate services.
        </p>,
    },
    {
        heading:"How does Lazybones know which items I want shipped versus which items I want stored?",
        body:<p>
        Lazybones will know which items you want stored versus which items you want shipped by the labels you place on your items. All storage and shipping labels can printed directly from our website. Just be careful to place the correct label on the correct item.
        </p>,
    },
    {
        heading:"Can I ship items to Lazybones before I get back to campus?",
        body:<p>
        Yes! You can ship items directly to us to be delivered with the rest of your items in storage. There is a $35 charge per item. The maximum size of each item shipped to us cannot exceed 16,000 cubic inches. Shipments must arrive by August 1.
        </p>,
    },
    
    
];


const Shipping = ()=>{
    const data = studentData;
    let ctr = 1; 
    return(
        <>
            {
                data.map(({heading,body})=>
                    <Media key={ctr++}>
                        <Media body>
                        <Media heading>{heading}</Media>
                        {body}
                        </Media>
                    </Media>
                )
            }
        </>
    );
}

export default Shipping;
