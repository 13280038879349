import React, {Component} from "react";
import _ from "lodash";
import {StripeProvider} from 'react-stripe-elements';



import ProfileDesktopView from "./Views/DesktopView";
import ProfileMobileView from "./Views/MobileView";

import auth from "../../../../../services/authService";
import UserService    from "../../../../../services/userService";

import applicationConstants from "../../../../../utils/applicationConstants";

import ProcessingModal from "../../../../Common/ProcessingModal";



// reactstrap components
import {
    Row,
    Col
  } from "reactstrap";



class ProfileLandingView extends Component{
        
    constructor(){
        super();
        this.state = {
            processingModalDisplayFlag:false,
            user:{
                stripeMerchantAccountUniverityId:1,
                userDb:"",
                customerRole:"",
                existingPaymentInformationOnFile:"",
                primaryInformation:{
                    firstName:"",
                    lastName:"",
                    email:"",
                    cellPhone:"",
                },
                credentialInformation:{
                    username:"",
                    password:"",
                    retypePassword:"",
                }
            }
        }
    }

    async componentDidMount(){
        await this.mapModelToView();
    }

    handleCloseOfProcessingModalWindow = (flag)=>{
        const myAccountState = {...this.state}
        myAccountState.processingModalDisplayFlag = flag;
        this.setState(myAccountState);
    }

    /*
     Save is handled on the display info component
     I will just update the user record 
    */
    handleOnSaveOfCustomerInformation = async ()=>{
        await this.mapModelToView()
    }

    mapModelToView = async ()=>{
        //Disabling this as it's affecting the scroll 
        //this.setProcessingModalDisplayFlag(true);
        const {customerRole} = auth.getCurrentUser()
        const userDb = await auth.getUserRecord();
        const {user_id:userId} = userDb;

        
        
        if(userDb){
            const {
                first_name:firstName,
                last_name:lastName,
                email:email,
                cell:cellPhone,
                username,
                university_id:universityId,

            } = userDb;

            const primaryInformation = {
                firstName,
                lastName,
                email,
                cellPhone,
            }

            const credentialInformation={
                ...this.state.user.credentialInformation,
                username,
            }

            //Get existing card information
                let existingPaymentInformationOnFile = "";
                const {status:processingStatus,message,returnObject:cardInformation} = await UserService.getCreditCardInfo({userId});
                if(processingStatus === true){
                    existingPaymentInformationOnFile = cardInformation;
                }
            //End of Get existing card information
            

            this.setState({
                user:{
                    ...this.state.user,
                    stripeMerchantAccountUniverityId:universityId,
                    userDb,
                    customerRole,
                    existingPaymentInformationOnFile,
                    primaryInformation,
                    credentialInformation,
                }
            })
            //this.setProcessingModalDisplayFlag(false);
        }
        
    }

    setProcessingModalDisplayFlag = (flag)=>{
        const state = {...this.state}
        state.processingModalDisplayFlag = flag;
        this.setState(state)
    }

    render(){
        const {user,processingModalDisplayFlag} = this.state;
        const stripeMerchantAccountUniverityId = user.stripeMerchantAccountUniverityId;

        const customerPrimaryInformationComponentDisplayProperties = applicationConstants.customerPrimaryInformationComponentDisplayProperties.viewProfile
        const paymentInformationComponentDisplayProperties = applicationConstants.paymentInformationComponentDisplayProperties.viewProfile
        const displayCSS = "min-height-500";
        
        return(
            <StripeProvider key={stripeMerchantAccountUniverityId} apiKey={process.env[`REACT_APP_STRIPE_PRODUCTION_KEY_UNIVERSITY_ID_${stripeMerchantAccountUniverityId}`]}>  
                <>
                    <Row className={displayCSS}>
                        <Col md="12" sm="12">
                            <div className="d-none d-md-block">
                                <ProfileDesktopView 
                                user={user}
                                customerPrimaryInfoDisplayProperties={customerPrimaryInformationComponentDisplayProperties}
                                paymentInformationComponentDisplayProperties = {paymentInformationComponentDisplayProperties}
                                handleOnSaveOfCustomerInformation={this.handleOnSaveOfCustomerInformation}
                                handleDisplayOfProcessingModal={this.setProcessingModalDisplayFlag}
                                {...this.props}
                                />
                            </div>
                            <div className="d-block d-md-none">
                                <ProfileMobileView 
                                user={user}
                                customerPrimaryInfoDisplayProperties={customerPrimaryInformationComponentDisplayProperties}
                                paymentInformationComponentDisplayProperties = {paymentInformationComponentDisplayProperties}
                                handleOnSaveOfCustomerInformation={this.handleOnSaveOfCustomerInformation}
                                handleDisplayOfProcessingModal={this.setProcessingModalDisplayFlag}
                                {...this.props}
                                />
                            </div>
                        </Col>
                    </Row>

                    {/* Set Processing modal */}
                    <ProcessingModal
                        displayModal={processingModalDisplayFlag}
                        handleCloseOfModalWindow={this.handleCloseOfProcessingModalWindow}
                    />
                    {/* End of Processing modal */}

                </>
            </StripeProvider>
        )
    }
}


export default ProfileLandingView