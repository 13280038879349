import React from "react"

import moment from "moment";
import _ from "lodash";
import UserService from "../../../../../../services/userService";
import applicationConstants from "../../../../../../utils/applicationConstants"



// reactstrap components
import {
    Button,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Label,
    Row,
    Col,
    FormGroup, 
    Modal, 
    Input, 
  } from "reactstrap";


const getFormattedUserAddress = (user)=>{
    const {line1:addressLine1,line2:addressLine2} = UserService.getFormattedUserAddress(user);
    return <>{addressLine1} <br/> {addressLine2}</>;
}

const getPickupDate = (storage)=>{
    let pickupDate = "";
    if(storage && storage.nextPickupDeliverySchedule && storage.nextPickupDeliverySchedule.pickup){
        const {pickup:pickupObject} = storage.nextPickupDeliverySchedule;
        const {displayDate,startTime,endTime} = pickupObject;
        pickupDate = `${displayDate} ${startTime} - ${endTime}`;
    }
    
    return pickupDate ;
}

const getDoYouHaveLargeItems = (storage)=>{
    let returnText = storage.storageOrder.estimate_quan;

    if(storage && storage.storageOrder){
        
        const {estimate_quan:doYouHaveLargeItems} = storage.storageOrder;
        returnText = storage.storageOrder.estimate_quan;
        /*
        //data length ont he field is set to varchar(10)..it needs to be increased
        if(doYouHaveLargeItems === applicationConstants.STORAGE_ITEMS_FOR_PICKUP_LARGE_ITEMS_YES){
            returnText = "Yes";
        }
        else if(doYouHaveLargeItems === applicationConstants.STORAGE_ITEMS_FOR_PICKUP_LARGE_ITEMS_NO){
            returnText = "No";
        }
        */
        
    }

    return returnText;
}

const getDoYouHaveMoreItems   = (storage)=>{
    let returnText = storage.storageOrder.estimate_items;

    if(storage && storage.storageOrder){
        const {estimate_items:doYouHaveMoreItems} = storage.storageOrder;
        if(doYouHaveMoreItems === applicationConstants.STORAGE_ITEMS_FOR_PICKUP_MORE_ITEMS){
            returnText = "Yes";
        }
        else if(doYouHaveMoreItems === applicationConstants.STORAGE_ITEMS_FOR_PICKUP_LESS_ITEMS){
            returnText = "No";
        }
    }

    return returnText;
}

const getSchedulingNote = (storage)=>{
    let returnText = "";
    if(storage && storage.nextPickupDeliverySchedule && storage.nextPickupDeliverySchedule.pickup){
        const {storageScheduleNote} = storage.nextPickupDeliverySchedule.pickup;
        returnText = storageScheduleNote;
    }
    return returnText;

}

const PickupInformationReview  = (props)=>{
    const { 
        storageOrder:storage,
        user 
    } = props;

    const formattedUserAddress = (!_.isEmpty(user)) ? getFormattedUserAddress(user):"";
    const formattedDateAndTimeForPickup = getPickupDate(storage);
    const doYouHaveMoreItems = getDoYouHaveMoreItems(storage);
    const doYouHaveLargeItems = getDoYouHaveLargeItems(storage);
    const schedulingNote = getSchedulingNote(storage);

    return(
        <>
            <h6>Pickup Location and Date/Time:</h6>
            <Row>
                <Col xs="6">
                    Pickup Address:	
                </Col>  
                <Col xs="6">   
                <p>
                    {formattedUserAddress}
                </p>
                </Col>
            </Row>

            <Row>
                <Col xs="6">
                    Pickup Date & Time:	
                </Col>  
                <Col xs="6">   
                <p>
                    {formattedDateAndTimeForPickup}
                </p>
                </Col>
            </Row>

            <Row>
                <Col>
                    <hr/>
                    <h6>Storage Estimate:</h6>
                    <Row>
                        <Col xs="6" lg="8">
                        Do you expect to have more than 15 total items (including boxes and furniture)?	
                        </Col>  
                        <Col xs="3" sm="3" md="3" lg="2">
                            {doYouHaveMoreItems}
                        </Col> 
                    </Row>
                    
                    <Row className="mt-2">
                        <Col xs="6" lg="8">
                        Will you have any large items like a couch, desk, dresser or mattress?		
                        </Col>  
                        <Col xs="3" sm="3" md="3" lg="2">
                            {doYouHaveLargeItems}
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col>
                    <hr/>
                    <h6>Notes:</h6>
                    <Row>
                        <Col>
                            <p>
                            {schedulingNote}
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default PickupInformationReview;