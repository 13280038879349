import React,{Component} from "react";
import {Route,Switch,Redirect} from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import accounting from "accounting";

//toast notification
import {ToastContainer} from "react-toastify";

// utils
import customToast from './components/Common/CustomToast'
import applicationConstantUtility from "./utils/applicationConstants";

//Context
import {NotificationContextProvider} from "./components/Context/NotificationContext"

//components
import ProtectedRoute from "./components/Common/ProtectedRoute";
import PageSetting from "./components/Common/PageSetting";

//services
import auth from "./services/authService";
import SuppliesService from "./services/suppliesService";
import UserService from "./services/userService";

//pages
import ExistingUserSignUp from "./views/pages/ExistingUserSignUp";
import ForgotPassword from "./views/pages/ForgotPassword";
import GiftCards from "./views/pages/GiftCards";
import Hiring from "./views/pages/Hiring";
import LandingPage from "./views/pages/LandingPage";
import LaundryDetails from "./views/pages/LaundryDetails";
import Login from "./views/pages/Login";
import Logout from "./components/Authentication/Logout";
import MyAccount from "./views/pages/MyAccount"
import Privacy from "./views/pages/Privacy"
import ResetPassword from "./views/pages/ResetPassword";
import SignUp from "./views/pages/SignUp";
import SignUpServerError from "./views/pages/SignUpServerError";
import Supplies from "./views/pages/Supplies";
import ShoppingCart from "./views/pages/ShoppingCart";
import ShoppingCartSuppliesCheckoutForLoggedInUser from "./views/pages/ShoppingCartSuppliesCheckoutForLoggedInUser";
import StorageDetails from "./views/pages/StorageDetails";
import ViewUserProfile from "./views/pages/ViewUserProfile";

const {localStorageShoppingCart} = auth;

class App extends Component{

    shoppingCartEmptyObject = {
        cartTotal:{
            discountAmount:0,
            salesTaxPercent:0,
            subTotal:0,
            totalTax:0,
            total:0,
        },
        supplies:{
           provideSuppliesToLoggedInUser:"",
           provideSuppliesToStudent:true,
           shippingDate:"",
           items:[]
        }
    };
   
    /*
     signupProductPath: supplies
     It gets set on the supplies checkout button if the user is not logged in
     Use this to provide to login form for deciding the create an account 
     should navigate to which signup link 
     Ex signupProductPath = "supplies"  => /signup/supplies

     provideSuppliesToStudent: true or false
     this will be populated once postalCode is set and finds if allowed to provide supplies to
     student type based on zipcode. Used in the signup for where we ask are you a student
    */
    constructor(){
        super();
        this.state = {
            appState:"",
            shoppingCart:{
                loggedInUser:"",
                signupProductPath:"",
                universityId:"",
                university:"",
                zoneId:"",
                postalCode:"",
                postalCodeShippingStartDateToBlock:"",
                postalCodeShippingEndDateToBlock:"",
                city:"",
                state:"",
                couponProcessingFlag:false,
                discount:{
                    discountCode:"",
                    discountType:"", //percent and dollar
                    discountValue:0, //discount value 0.05 or 5.00
                    voucherType:"", //Gift card
                },
                cartTotal:{
                    discountAmount:0,
                    salesTaxPercent:0,
                    subTotal:0,
                    totalTax:0,
                    total:0,
                },
                supplies:{
                   provideSuppliesToLoggedInUser:"",
                   provideSuppliesToStudent:true,
                   shippingDate:"",
                   items:[]
                }
            }
        }

        
    }

    async componentDidMount(){
        
        //Check to see if users zip code is valid for supplies in order to populate the catalog and app
            if(auth.getCurrentUser()){
                try{
                    const user = await auth.getUserRecord();
                    const {school_zip:postalCode,customer_type:customerType} = user;

                    const formData = {postalCode};
                    const zipcodeValidationReturnData = await SuppliesService.validateZipcodeForSupplies(formData);
                    const userObject = {
                        ...zipcodeValidationReturnData,
                        loggedInUser:{customerType},
                    }
                    this.handleShoppingCartOnUserLogin(userObject);  
                }
                catch{
                    const shoppingCart = {...this.state.shoppingCart}
                    const supplies = {...shoppingCart.supplies}
                    supplies.provideSuppliesToLoggedInUser = false;
                    shoppingCart.supplies = supplies;
                    this.setState({shoppingCart})
                }
            }
        //End of Check to see if users zip code is valid for supplies in order to populate the catalog and app
        
    }

    clearShoppingCart = ()=>{
        localStorage.removeItem(localStorageShoppingCart);
        const shoppingCart = {
            ...this.state.shoppingCart,
            cartTotal:{
                discountAmount:0,
                salesTaxPercent:0,
                subTotal:0,
                totalTax:0,
                total:0,
            },
            supplies:{
               provideSuppliesToLoggedInUser:"",
               provideSuppliesToStudent:true,
               shippingDate:"",
               items:[]
            }
        }
        this.setState({
            shoppingCart
        })
    }

    /*
      Supplies pages have functions that are creating the updated this.state.shoppingCart
      Here I just update the state. 

      Need to save it to localstorage and access it when the component mounts
    */
    handleShoppingCart = (shoppingCart)=>{
        //console.log("Update cart from App.js");
        const {supplies} = shoppingCart;
        if(supplies.items.length > 0){
            const {salesTaxPercent} = shoppingCart.cartTotal;
            const {discountValue} = shoppingCart.discount;
            let subTotal = 0;
            let discountAmount = discountValue;
            supplies.items.forEach(i=>subTotal += (i.price * i.quantity));
            let totalTax = Number(accounting.toFixed(subTotal * (salesTaxPercent/100),2));
            let total = subTotal + totalTax;
            
            let originalTotalWithoutDiscount = {};
            if(discountAmount > 0){
                originalTotalWithoutDiscount = {
                    subTotal,
                    totalTax,
                    total:Number(accounting.formatNumber(total,2)),
                }
                total = subTotal + totalTax - discountAmount;
            }
            total = Number(accounting.formatNumber(total,2));

            const cartTotal = {
                ...shoppingCart.cartTotal,
                discountAmount,
                subTotal,
                totalTax,
                total,
                originalTotalWithoutDiscount,
            }
            shoppingCart.cartTotal = cartTotal;
        }
        localStorage.setItem(localStorageShoppingCart,JSON.stringify(shoppingCart));
        this.setState({shoppingCart});
    }


    /*this is when a user login then I check if the zipcode is valid and if so then set the shopping cart object */
    handleShoppingCartOnUserLogin = (data)=>{
        const { 
            university_id:universityId,
            University,
            postal_code:postalCode,
            shipping_start_date_block:postalCodeShippingStartDateToBlock,
            shipping_end_date_block:postalCodeShippingEndDateToBlock,
            loggedInUser
        } = data;

        const {supplies_tax:suppliesTax} = University;
        
        const shoppingCartFromLocalStorage = localStorage.getItem(localStorageShoppingCart) && JSON.parse(localStorage.getItem(localStorageShoppingCart));
        let shoppingCart  = null;
        
        if(!shoppingCartFromLocalStorage || shoppingCartFromLocalStorage.postalCode !== postalCode)
          shoppingCart = {...this.state.shoppingCart,loggedInUser}
        else{
          const {shippingDate} = shoppingCartFromLocalStorage.supplies ;
          shoppingCartFromLocalStorage.supplies.shippingDate = (shippingDate !== "") ? moment(shippingDate):"";
          shoppingCart = {...shoppingCartFromLocalStorage,loggedInUser}
        }
        
        shoppingCart.postalCode = postalCode;
        shoppingCart.universityId = universityId;
        shoppingCart.university = University;
        shoppingCart.postalCodeShippingStartDateToBlock = postalCodeShippingStartDateToBlock;
        shoppingCart.postalCodeShippingEndDateToBlock = postalCodeShippingEndDateToBlock;
        
        
        const cartTotal = {...shoppingCart.cartTotal}
        cartTotal.salesTaxPercent = suppliesTax;
        shoppingCart.cartTotal = cartTotal;

        
        this.handleShoppingCart(shoppingCart);
        
    }

    handleShoppingCartRemoveItem = (product)=>{
        const shoppingCart = {...this.state.shoppingCart}
        const items = [...shoppingCart.supplies.items];
        
        const index = _.findIndex(items, {'id':product.id,'isBundle':product.isBundle})
        if(index !== -1){
            items.splice(index,1)
            shoppingCart.supplies.items = items;
            this.handleShoppingCart(shoppingCart);
            
        }

        const {items:itemsInCart} = this.state.shoppingCart.supplies;
        const numberOfItems = itemsInCart.length;
        if(numberOfItems === 0){
            this.clearShoppingCart();
        }
    }

    handleShoppingCartShippingDate = (shippingDate)=>{
        const shoppingCart = {...this.state.shoppingCart};
        const supplies = {...shoppingCart.supplies};
        supplies.shippingDate  = shippingDate;
        shoppingCart.supplies = supplies;
        this.handleShoppingCart(shoppingCart)
    }

    handleShoppingCartUpdateProductQuantity = (product,quantity=1,action="add")=>{
        const shoppingCart = {...this.state.shoppingCart}
        const items = [...shoppingCart.supplies.items];
        
        const index = _.findIndex(items, {'id':product.id,'isBundle':product.isBundle})
        if(index !== -1){
            let {quantity:currentQuantity} = items[index]
            switch(action){
                case "add":  currentQuantity = currentQuantity + quantity;
                                break;
                case "subtract": currentQuantity = currentQuantity - quantity;
                                break;
            }
            
            if(currentQuantity === 0){
                items.splice(index,1)
            }
            else{
                items[index] = {
                    ...product,
                    quantity:currentQuantity
                }
            }

            shoppingCart.supplies.items = items;
            this.handleShoppingCart(shoppingCart);
            
        }

        const {items:itemsInCart} = this.state.shoppingCart.supplies;
        const numberOfItems = itemsInCart.length;
        if(numberOfItems === 0){
            this.clearShoppingCart();
        }
    }

    handleShoppingCartValidateCouponCode = async (formDataObject)=>{
        try{
            let shoppingCart = {
                ...this.state.shoppingCart,
                couponProcessingFlag:true
            }
            this.setState({shoppingCart});

            const {
                status:processingStatus,
                message,
                couponCodeReturnObject
            } = await UserService.validateCouponCode(formDataObject);
            
            shoppingCart = {
                ...this.state.shoppingCart,
                couponProcessingFlag:false
            }
            this.setState({shoppingCart});

            let discountProperties = {};
            if(processingStatus === true){
                const {couponCode,couponType,giftCardBalance,couponDetails} = couponCodeReturnObject;
                
                const {total} = this.state.shoppingCart.cartTotal
                const giftCardAmountToBeUsedForRedemption = (total <= giftCardBalance ) ? total:giftCardBalance;

                if(couponType === applicationConstantUtility.DISCOUNT_TYPE_GIFTCARD){
                    discountProperties = {
                        ...this.state.shoppingCart.discount,
                        discountCode:couponCode,
                        discountType:applicationConstantUtility.DISCOUNT_VALUE_TYPE_DOLLAR, 
                        discountValue:giftCardAmountToBeUsedForRedemption, 
                        voucherType:couponType, 
                    }

                    shoppingCart = {
                        ...this.state.shoppingCart,
                        discount:discountProperties,
                    }
                    
                    this.handleShoppingCart(shoppingCart);
                    customToast.success("Gift card applied!!!");
                }
            }
            else
                customToast.error(message);
            


            
        }
        catch(ex){
            customToast.error("Encountered a system error while processing request. Please contact Lazybones");
        }
    }

    redirectUserToPasswordResetScreen = ()=>{
        const user = auth.getCurrentUser();
        return (user && user.resetPasswordFlag === true) ? true:false;
        
    }

    getRedirectComponent = (props)=>{
        return  <Redirect 
                    to={
                        {
                        pathname:"/resetPassword",
                        state:{from:props.location}
                        }
                    }
                    {...props}
                />
    }

    

    
    /*
        TODO:
        /myaccount will need to just be /myaccount with no account type in the url 
        we login the user and know the customer type in the JWT 
    */
    render(){
        /*
        if(!auth.getCurrentUser())
            toast.error("User has not signed in"); 
        else
            toast.success("User has signed in");   
        */  
        //console.log(this.props)
        return (
            <React.Fragment>
                <NotificationContextProvider>
                    <ToastContainer /> 
                    <Switch>
                        <Route
                            path="/forgotPassword"
                            render={props => 
                                <PageSetting title="Forgot Password">
                                    <ForgotPassword {...props} globalApp={this.state} />
                                </PageSetting>
                            }
                        />

                        <Route
                            path="/privacy"
                            render={props => 
                                <PageSetting title="Privacy">
                                    <Privacy {...props} globalApp={this.state} />
                                </PageSetting>
                            }
                        />

                        <Route
                            path="/hiring"
                            render={props => 
                                <PageSetting title="Hiring">
                                    <Hiring {...props} globalApp={this.state} />
                                </PageSetting>
                            }
                        />

                        <ProtectedRoute
                            path="/resetPassword"
                            title="Reset Password"
                            render={props =>{
                                    return(
                                        <ResetPassword 
                                            globalApp={this.state} 
                                            {...props} 
                                        />
                                    );
                                }
                            }    
                        />
                    
                        <ProtectedRoute
                            path="/resetPassword"
                            title="Reset Password"
                            render={props =>{
                                    return(
                                        <ResetPassword 
                                            globalApp={this.state} 
                                            {...props} 
                                        />
                                    );
                                }
                            }
                            
                        />

                        <Route
                            path="/gift-cards/:step?"
                            render={props =>{
                                if(this.redirectUserToPasswordResetScreen())
                                    return this.getRedirectComponent(props);
                                else
                                    return <PageSetting title="Gift Card">
                                        <GiftCards {...props} globalApp={this.state} />
                                    </PageSetting>
                            }}
                        />
                        
                        <Route
                            path="/index"
                            render={props =>{
                                if(this.redirectUserToPasswordResetScreen())
                                    return this.getRedirectComponent(props);
                                else
                                    return <PageSetting title="Home Page">
                                        <LandingPage {...props} globalApp={this.state} />
                                    </PageSetting>
                            }}
                        />
                        
                        <Route    
                            path="/laundry/how-it-works/:userType?/:action?"
                            render={props =>{
                                    if(this.redirectUserToPasswordResetScreen())
                                        return this.getRedirectComponent(props);
                                    else
                                        return <PageSetting title="Laundry - How it works">
                                            <LaundryDetails {...props} globalApp={this.state} />
                                        </PageSetting>
                                }
                            }
                        />

                        <Route
                            path="/login"
                            render={props => { 
                                return(<Login 
                                    globalApp={this.state}  
                                    handleShoppingCartOnUserLogin={this.handleShoppingCartOnUserLogin}
                                    {...props} 
                                />)
                            }}
                        />

                        <Route path="/logout" component={Logout} />             
                        
                        <ProtectedRoute
                            path="/myaccount/view-profile/:widgetView?"
                            title="My account - View Profile"
                            render={props =>{
                                    if(this.redirectUserToPasswordResetScreen())
                                        return this.getRedirectComponent(props);
                                    else
                                        return(
                                            <ViewUserProfile 
                                                globalApp={this.state} 
                                                {...props} 
                                            />
                                        );
                                }
                            }
                            
                        />

                        {
                            ///myaccount/:accountType?/:product?/:optional-views? : optional views = storage-items
                        }
                        <ProtectedRoute
                            path="/myaccount/:accountType?/:product?/:optionalView?"
                            title="My account - View Product"
                            render={props =>{
                                    if(this.redirectUserToPasswordResetScreen())
                                        return this.getRedirectComponent(props);
                                    else
                                        return(
                                            <MyAccount 
                                                globalApp={this.state} 
                                                {...props} 
                                            />
                                        );
                                }
                            }
                            
                        />

                        <Route
                            path="/signup/manual-processing/confirmation/:product"
                            render={props =>{ 
                                if(this.redirectUserToPasswordResetScreen())
                                    return this.getRedirectComponent(props);
                                else
                                    return(
                                        <SignUpServerError 
                                            globalApp={this.state}  
                                            {...props}
                                        />
                                    );
                            }
                            }
                        />

                        <Route
                            path="/signup/:product/:productType?/:step?"
                            render={props =>{ 
                                if(this.redirectUserToPasswordResetScreen())
                                    return this.getRedirectComponent(props);
                                else
                                    return(
                                        <PageSetting title="Signup">
                                            <SignUp
                                                globalApp={this.state}  
                                                clearShoppingCart = {this.clearShoppingCart}  
                                                handleShoppingCartRemoveItem={this.handleShoppingCartRemoveItem}  
                                                handleShoppingCartUpdateProductQuantity={this.handleShoppingCartUpdateProductQuantity}
                                                handleShoppingCartShippingDate={this.handleShoppingCartShippingDate}  
                                                handleShoppingCartValidateCouponCode ={this.handleShoppingCartValidateCouponCode}
                                                {...props}
                                            />
                                        </PageSetting>
                                    );
                            }
                            }
                        /> 

                        
                        <ProtectedRoute
                            path="/existing-user/signup/:accountType/:product/:schoolId/:semesterId?/:step?/:changePlanFlag?"
                            title="Existing user signup"
                            render={props =>{
                                    if(this.redirectUserToPasswordResetScreen())
                                        return this.getRedirectComponent(props);
                                    else
                                        return(
                                            <PageSetting title="Existing User - Signup">
                                                <ExistingUserSignUp 
                                                    globalApp={this.state} 
                                                    {...props} 
                                                />
                                            </PageSetting>
                                        );
                                }
                            }
                        />

                        <Route    
                            path="/storage/how-it-works/:userType?/:action?"
                            render={props =>{
                                if(this.redirectUserToPasswordResetScreen())
                                    return this.getRedirectComponent(props);
                                else
                                    return <PageSetting title="Storage - How it works">
                                        <StorageDetails {...props} globalApp={this.state} />
                                    </PageSetting>
                                }
                            }
                        />

                        <Route
                            path="/supplies/:step?"
                            render={props =>{ 
                                if(this.redirectUserToPasswordResetScreen())
                                    return this.getRedirectComponent(props);
                                else
                                    return(
                                    <PageSetting title="Supplies">
                                        <Supplies
                                        globalApp={this.state}
                                        updateShoppingCart={this.handleShoppingCart}
                                        handleShoppingCartRemoveItem={this.handleShoppingCartRemoveItem}  
                                        handleShoppingCartUpdateProductQuantity={this.handleShoppingCartUpdateProductQuantity}
                                        handleShoppingCartShippingDate={this.handleShoppingCartShippingDate}  
                                        handleShoppingCartValidateCouponCode ={this.handleShoppingCartValidateCouponCode}
                                        {...props}
                                        />
                                    </PageSetting>
                                    );
                            }
                            }
                        />

                        <ProtectedRoute
                            path="/shoppingCart/:product/:step"
                            title="Shopping Cart"
                            render={props =>{
                                    if(this.redirectUserToPasswordResetScreen())
                                        return this.getRedirectComponent(props);
                                    else
                                        return(
                                            <PageSetting title="Shopping Cart - Supplies Checkout">
                                                <ShoppingCartSuppliesCheckoutForLoggedInUser 
                                                    globalApp={this.state} 
                                                    clearShoppingCart = {this.clearShoppingCart}  
                                                    updateShoppingCart={this.handleShoppingCart}
                                                    handleShoppingCartRemoveItem={this.handleShoppingCartRemoveItem}  
                                                    handleShoppingCartUpdateProductQuantity={this.handleShoppingCartUpdateProductQuantity}
                                                    handleShoppingCartShippingDate={this.handleShoppingCartShippingDate}
                                                    handleShoppingCartValidateCouponCode ={this.handleShoppingCartValidateCouponCode}
                                                    {...props} 
                                                />
                                            </PageSetting>
                                        );
                                }
                            }
                            
                        />

                        <Route
                            path="/shoppingcart"
                            render={props =>{
                                if(this.redirectUserToPasswordResetScreen())
                                    return this.getRedirectComponent(props);
                                else
                                    return(
                                    <PageSetting title="Shopping Cart">
                                        <ShoppingCart 
                                            {...props} 
                                            globalApp={this.state}    
                                            onRemoveOfItem={this.handleShoppingCartRemoveItem}  
                                            onUpdateOfItem={this.handleShoppingCartUpdateProductQuantity}
                                            handleShoppingCartShippingDate={this.handleShoppingCartShippingDate}  
                                            handleShoppingCartValidateCouponCode ={this.handleShoppingCartValidateCouponCode}
                                        />
                                    </PageSetting>
                                    )
                            }}
                        />

                        <Redirect to="/index" />
                    </Switch>
                </NotificationContextProvider>
            </React.Fragment>
            
        );
    }
}

export default App;
